<p-dialog [(visible)]="isOpen" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'" [styleClass]="'contextMenuModal profileModal'"
  [closable]="false" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" [style]="{'height':'740px','max-width':'1350px', 'width': '90%'}"
  [resizable]="false">
  <p-header>
    <div class="d-flex flex-row align-items-center justify-content-between" style="padding: 20px 27px 0px 24px;">
      <span class="gfid-title" style="font-size: 20px;width: 12em;">USER ADMIN</span>
      <span *ngIf="showGroupName" style="font-size: 13px;color: gray;font-family: 'interstateLight';width: 71em;">{{selectedAdminGroupName}}</span>
      <button type="button" (click)="closeModal()" class="d-flex flex-row close qma-text" style="border:none !important;opacity:1">
        <img src="assets/Inbox/close_icon_u73.svg" style="opacity:1" alt="Close">
        <h6>CLOSE</h6>
      </button>
    </div>
  </p-header>
  <div class="gfid-body admin-gfid-padding">
    <ng-template #customLoadingTemplate>
      <div class="custom-class-loader">
        <div class="spinner-container">
          <svg class="loader" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340 340">
            <circle cx="170" cy="170" r="160" stroke="#021e48" />
            <circle cx="170" cy="170" r="135" stroke="#00bdf2" />
            <circle cx="170" cy="170" r="110" stroke="#021e48" />
            <circle cx="170" cy="170" r="85" stroke="#00bdf2" />
          </svg>
        </div>
      </div>
    </ng-template>
    <ngx-loading [show]="showSpinner" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate">
    </ngx-loading>
    <div class="w-80">
      <p-tabView class="adminview" #profileTabs (onChange)="onTabChange($event)" [styleClass]="'user-admin-tabview'" [scrollable]="false"
        [style]="{'height':'595px','width':'90%','max-width':'1200px','margin-left':'3rem',overflow: 'hidden', 'position': 'relative'}"
        [activeIndex]="actTabIdx">
        <p-tabPanel header="USER DETAILS" class="tab-heading">
          <div class="pr-2">
            <div class="row p-2">
              <div class="col-3">
                <label class="flex-grow">SEARCH by SOEID</label>
              </div>
              <div class="col-9">
                <input type="text" [(ngModel)]="newUserSoeid" #soeId [ngModelOptions]="{standalone: true}" (keydown)="keyDownFunction($event)"
                  placeholder="Search..." class="w-50 search-box" />
                <em class="search-icon fa fa-search" (click)="keyDownFunction($event)"></em>
                <div *ngIf="userNotMatch" class="alert alert-danger w-50" role="alert">
                  {{validationError}}
                </div>
                <div *ngIf="newUserSoeIdValidationFlag" class="alert alert-danger w-50" role="alert">
                  {{blankStringValidationMsg}}
                </div>
              </div>
            </div>
            <div *ngIf="userDetails || userNotFound">
              <div class="row p-2">
                <div class="col-3">
                  <label class="flex-grow label_font">Name:</label>
                </div>
                <div class="col-9">
                  <span *ngIf="userNotFound; else elseBlock1">
                    <input type="text" #name="ngModel" [(ngModel)]="userDetails.userName" required
                      [ngModelOptions]="{standalone: true}" placeholder="Enter Username" class="w-50 search-box" />
                      <div *ngIf="userNotExist" class="alert alert-danger w-50" role="alert">
                        {{validationError}}
                      </div>
                  </span>
                  <ng-template #elseBlock1>
                    <label class="flex-grow label_font">{{userDetails.userName}}</label>
                  </ng-template>
                </div>
              </div>
              <div class="row p-2">
                <div class="col-3">
                  <label class="flex-grow label_font">Email:</label>
                </div>
                <div class="col-9">
                  <span *ngIf="userNotFound; else elseBlock2">
                    <input type="text" #email="ngModel" [(ngModel)]="userDetails.email" required
                      [ngModelOptions]="{standalone: true}" placeholder="Enter Email" class="w-50 search-box" />
                      <div *ngIf="notValidEmail" class="alert alert-danger w-50" role="alert">
                        {{validationError}}
                      </div>
                  </span>
                  <ng-template #elseBlock2>
                    <label class="flex-grow label_font">{{userDetails.email}}</label></ng-template>
                </div>
              </div>
              <div class="row p-2">
                <div class="col-3">
                  <label class="flex-grow label_font">Active:</label>
                </div>
                <div class="col-9">
                  <span *ngIf="userNotFound; else elseBlock3">
                    <p-inputSwitch [(ngModel)]="userDetails.active" [ngModelOptions]="{standalone: true}"
                      [styleClass]="'advance-inputSwitch'">
                    </p-inputSwitch>
                  </span>
                  <ng-template #elseBlock3>
                    <p-inputSwitch [(ngModel)]="userDetails.active" [styleClass]="'advance-inputSwitch'" [disabled]="userNotFound">
                    </p-inputSwitch>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="userDetails" class="p-2">
            <p-pickList [source]="availableAdminRoles" [target]="selectedAdminRoles" sourceHeader="Available Admin Roles" targetHeader="Assigned Admin Roles"
              [responsive]="true" dragdrop="true" [showSourceControls]="false" [showTargetControls]="false">
              <ng-template let-item pTemplate="item">
                <div class="ui-helper-clearfix">
                  <span>{{item.role}}</span>
                </div>
              </ng-template>
            </p-pickList>
          </div>
        </p-tabPanel>
        <p-footer class="fix-footer">
          <div class="d-flex flex-row footer-btn-container"></div>
          <div class="d-flex flex-row flex-wrap bottom-button-holder px-3 pb-2 pt-3 justify-content-end">
            <button type="submit" class="btn save-btn mr-4" (click)="saveChanges()">Submit</button>
            <button class="btn clear-btn" (click)="reset()">Reset</button>
          </div>
        </p-footer>
      </p-tabView>
    </div>
  </div>
  <p-toast></p-toast>
</p-dialog>
<!-- for sucess message -->
<p-dialog [(visible)]="displaySuccess" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'" [styleClass]="'contextMenuModal successModal ModelClose'"
  [closable]="true" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" [style]="{width: '30%'}">
  <p-header>
    <div class="d-flex flex-row align-items-center justify-content-between">
      <h6 class="success-title">Success Confirmation</h6>
    </div>
  </p-header>
  <div class="success-msg">{{successMessage}}</div>
</p-dialog>
<!--for error message-->
<p-dialog [(visible)]="errorMessage" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'" [styleClass]="'contextMenuModal failureModal ModelClose'"
  [closable]="true" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" [style]="{width: '30%'}">
  <p-header>
    <div class="d-flex flex-row align-items-center justify-content-between">
      <h6 class="success-title">Information</h6>
    </div>
  </p-header>
  <div class="success-msg">{{errorMessage}}</div>
</p-dialog>
<div *ngIf="selectedMail">
<div #wrapper class="wrapper d-flex flex-column align-items-between wrapper-symphony" style="">
  <div class="chat-header w-100">
    <div class="pl-2 pr-1 d-flex flex-column h-100 position-relative" style="">
      <div class="d-flex flex-row align-items-center justify-content-between">
        <div class="header-text" style="margin-left: 14px;">{{selectedMail?.inquiry?.subject}}</div>
        <div class="d-flex flex-row justify-content-end users-holder">

          <div class="conv-container mx-1">
            <img src="assets/symphony/symphony_icon.png" alt="Conversation Count" class="symphony-icon">

          </div>
        </div>
      </div>

        <div class="d-flex flex-row align-items-center justify-content-between w-100">
            <div class="d-flex flex-row justify-content-start participant-names  w-100">
                <img class="user-feedback-icon" [src]="'assets/symphony/group-20.svg'"
                (click)="feedback.toggle($event)"
                tooltipStyleClass='rightTooltip p-tooltip-text'
                style="margin-right: 10px;width: 50px !important;
                height: 48px !important;
                margin-left: 4px;">

            <div class="chat-header-container w-100">
              <div class="grp-header header">
                        Group Chat Members:
              </div>
              <div  class="chat-participant-container d-flex justify-content-between">
                  <div class="chat-members-container">
                    <ng-template ngFor let-toRecipients [ngForOf]="allUsersSymphony" let-idx="index">

                      <span *ngIf="idx<5" class="text-right" [ngClass]="{'external': toRecipients.isExternal}" class="chat-participant">
                        {{toRecipients.participantName}};
                      </span>
                      <span *ngIf="idx>5 && idx == allUsersSymphony.length-1">...</span>
                      </ng-template>
                   </div>
              <div class="cheveron-container">
              <img [src]="'assets/symphony/expand_all_icon_u110.svg'" #participantToggle (click)="toggleParticipant()" alt="Expand"
              pTooltip="All Participants" tooltipPosition="left" tooltipStyleClass='leftTooltip p-tooltip-text'
              style="margin-left: 1em;height: 6px;width: 12px;">
              </div>
            </div>
          </div>
            </div>

      </div>

    </div>
  </div>
  <div #chatBody class="chat-body px-2" [ngClass]="bottomBarExpand ? 'expand': ''">
    <div>
        <div *ngIf="displayInfo" class="d-flex flex-row align-items-center justify-content-between info-msg-box pl-2 m-2">
            <div class="">
              <div class="header-info">Information</div>
              <div class="info-txt">Simplified Machine edited email message view. Click on the icon <img src="assets/newMessage/ExpandIcon.png"
              s> for full message</div>
            </div>
            <div class="close-btn" (click)="closeInfo()">
              <img src="assets/Inbox/close_icon_u73.svg">
            </div>
        </div>
      <!-- // C153176-3245 - check for chat bubble should come in left or right -->
      <div class="bubble-holder bubble-holder-symphony my-1 d-flex flex-row" *ngFor="let item of conversationList;let idx = index"
        [ngClass]="(isLoggedInUser(item.modBy) == false)?'bubble-even':'bubble-odd'">

        <div [attr.id]="item.id" class="speech-bubble d-flex flex-column justify-content-between"
        (click)="selectChatBubble(item)" [ngClass]="getChatBubbleSelection(item)">
          <div class="d-flex flex-row justify-content-between">
            <div #contentHolder class="content-holder d-flex" style="justify-content: space-between;">
              <div class="initial-content-holder d-flex">
            <div class="bubble-left">
            <div class="container">
                <img [src]="item.imageUrl ? item.imageUrl : ''"  style="height: 50px;width: 55px">
                <div class="centered" [ngClass]="setCssInitialsByUserId(item)" >{{item.fromInitials}}</div>
            </div>
           </div>
           <div class="bubble-middle" style="widows: 90%;">
             <div class="name-date-container d-flex align-items-center">
           <div class="member-name" style="margin-right: 12px;" [ngClass]="setCssClassByUserId(item)">{{item.displayName}}</div>
           <label class="footer-date">{{item.timestamp | date:'dd MMM hh:mm a'}}</label>
          </div>
           <div [innerHtml]="item.bubbleContent | safeHtml" class="bubble-message-data">
           </div>
           </div>
          </div>
           <div class="bubble-expander d-flex justify-content-end align-items-end">
            <div class="user-feedback-overlay"><!-- C153176-5620: Feedback and full message popup overlap issue  -->
                <img class="mx-2" src='assets/symphony/noun_expand_1440751.png' (click)="showOriginalMsg(item.bubbleContent)"
                pTooltip="Actual Message" tooltipPosition="left" tooltipStyleClass='rightTooltip p-tooltip-text' style="height: 10px !important;width: 10px !important;">
            </div>
         </div>
            </div>
          </div>

          <!-- C153176-4793 - User Feedback Popup - Starts-->
          <!-- // C153176-3245 - check for chat bubble should come in left or right -->
          <p-overlayPanel #feedback [dismissable]="true" [appendTo]="'body'" [styleClass]="(isLoggedInUser(item.modBy) == false)?'user-feedback-overlay-even':'user-feedback-overlay-odd'"
          [showTransitionOptions] = "'20ms ease-out'" [hideTransitionOptions] = "'20ms ease-in'">
          <div>
              <div class="d-flex flex-column">
                <div class="d-flex flex-row justify-content-between align-items-end p-2">
                  <div class="mr-1"><img class="feedback-sec-icon" [src]="'assets/Inbox/info-icon.svg'"></div>
                  <div class="feedback-header-text mr-2">Send Chat Feedback</div>
                  <div class="mr-auto"><img class="user-feedback-icon" [src]="'assets/common/user-feedback.svg'"></div>
                  <div (click)="feedback.hide()" class="close-text"><img class="close-icon" [src]="'assets/Inbox/close_icon_u73.svg'">CLOSE</div>
                </div>
                <div class="d-flex flex-column pl-2">
                  <div class="feedback-text ml-3">{{userFeedbackMsg}}</div>
                  <div class="feedback-text ml-3 pt-1">You see</div>
                  <div class="ml-2">
                    <app-checkbox-list [searchIcon]="false" [name]="'feedbackItem'" [items]="feedbackItems" [listStyle]="{'height':'192px'}"
                      [controlType]="'checkbox'" [search]="false" [resetBehaviour]="true" (onItemSelect)="getSelectedItems($event)"></app-checkbox-list>
                  </div>
                </div>
                <div class="d-flex flex-row flex-wrap bottom-button-holder px-3 pb-2 pt-3 justify-content-end">
                    <button class="btn clear-btn mr-3" (click)="feedback.hide()">Cancel</button>
                    <button type="submit" class="btn search-btn" (click)="onSendReport(item, feedback)">Send Report</button>
                </div>
              </div>
          </div>
        </p-overlayPanel>
        <!-- C153176-4793 - User Feedback Popup - Ends-->
        </div>
      </div>
    </div>
  </div>

  <div class="bottomBar d-flex flex-column align-items-between justify-content-between" #bottomBar [ngClass]="bottomBarExpand ? 'expand': ''" (click)="doExpand(true)"  [formGroup]="chatForm">
    <div class="onExpandHide" style="display: none">
      <div class="d-flex flex-row align-items-start">
        <p-autoComplete (completeMethod)="filterUsers($event)" [minLength]="1" field="text" [multiple]="true" [suggestions]="filteredToUsersMultiple" styleClass="override" class="w-100" [(ngModel)]="toUsersList"
          (onKeyUp)="onKeyUp($event)" formControlName="toUsers">
        </p-autoComplete>
        <!-- C153176-5146: validation -->
        <div *ngIf="chatForm.controls.toUsers.errors && (chatForm.controls.toUsers.dirty)"><!--C153176-5055 'To' validation in chat -->
          <img src="assets/newMessage/error.svg" class="error" title="TO is Required">
      </div>

      </div>
      <div class="d-flex flex-row mobile-form mail-options-panel align-items-start control-group w-100 flex-wrap justify-content-between px-2 py-1">
        <div class="w-20 d-flex flex-row align-items-center request-section flex-wrap">
          <label for="from" >Request Type: </label>
          <p-dropdown class="drop-down" [options]="requestTypes" [styleClass]="'prime-dd-override chat-view-dd'"  formControlName="requestType">
          </p-dropdown>
          <div *ngIf="chatForm.controls.requestType.errors" class="">
            <img src="assets/newMessage/error.svg" class="error" title="RequestType is Required">
          </div>
        </div>
        <div class="w-20 d-flex flex-row align-items-center request-section flex-wrap">
          <label for="from" >Root Cause: </label>
          <p-dropdown class="drop-down" [options]="rootCauses" [styleClass]="'prime-dd-override  chat-view-dd'"  formControlName="rootCause">
          </p-dropdown>
          <div *ngIf="chatForm.controls.rootCause.errors"
          class="">
          <img src="assets/newMessage/error.svg" class="error" title="Root Cause is Required">
          </div>
        </div>
        <div class="w-20 d-flex flex-row align-items-center request-section flex-wrap">
          <label for="from">Tag: </label>
          <p-dropdown class="drop-down" [options]="tags" [styleClass]="'prime-dd-override  chat-view-dd'" formControlName="tagList">
          </p-dropdown>
          <div *ngIf="chatForm.controls.tagList.errors"
          class="">
          <img src="assets/newMessage/error.svg" class="error" title="Tags is Required">
        </div>
        </div>
      </div>
      <!-- C153176-5146: additional input fields -->
      <div class="d-flex flex-row mobile-form mail-options-panel align-items-center control-group w-100 flex-wrap justify-content-between px-2 py-1">
        <div class="w-20 d-flex flex-row align-items-center request-section">
          <label for="from" class="flex-grow">Processing Region: </label>
          <p-dropdown class="drop-down" [options]="processingRegions" formControlName="processingRegion"
          [styleClass]="'override'">
          </p-dropdown>
          <div *ngIf="chatForm.controls.processingRegion.errors" class="">
            <img src="assets/newMessage/error.svg" class="error" title="Processing Region is Required">
          </div>
        </div>
        <div class="w-20 d-flex flex-row align-items-center request-section">
          <label for="from" class="flex-grow">Query Count: </label>
          <input style="width: 60px !important;" type="text" formControlName="queryCount" maxlength="4" (keypress)="isNumber($event)">
        </div>
        <div class="w-20 d-flex flex-row align-items-center request-section1">
          <label for="from" class="flex-grow">Inquiry Source: </label>
          <p-dropdown class="drop-down" [options]="inquirySourceList" formControlName="inquirySource"
          [styleClass]="'override'">
          </p-dropdown>
          <div *ngIf="chatForm.controls.inquirySource.errors" class="">
            <img src="assets/newMessage/error.svg" class="error" title="Inquiry Source is Required">
          </div>
        </div>
        <div class="w-20 d-flex flex-row align-items-center request-section">
                <i class="fa fa-search"></i><a href="#" class="TextGFP" (click)="callGFIDWindow()">GFPID / GFCID Search:</a>
                <!-- C153176-5146 : Issue-4 : Resolve in Chat view needs the 3 mandatory fields. -->
                <!-- <span class="truncate" pTooltip="{{gfpidgfcid}}" tooltipPosition="top" [appendTo]="'body'" title="{{gfpidgfcid}}" tooltipEvent="focus">{{gfpidgfcid}}</span> -->
                <div [innerHTML]="gfpidgfcid"></div>
                <input type="hidden" formControlName="gfcId"  />
                <input type="hidden" formControlName="gfcName" />
                <input type="hidden" formControlName="gfpId"  />
                <input type="hidden" formControlName="gfpName" />
                <div *ngIf="chatForm.controls.gfpId.errors && chatForm.controls.gfcId.errors" class="">
                  <img src="assets/newMessage/error.svg" class="error"><span class="errorMsg"> GFPID/GFCID is mandatory for resolve</span>
               </div>
        </div>
    </div>

      <div #overflowdiv class="w-100 d-flex flex-row align-items-start control-group ellipsis-overflow uploadPanel">
        <div *ngIf="postDataForAttachmentFile" class="pull-left" id="listOffileAttached" >
          <div *ngFor="let attachment of postDataForAttachmentFile" style="display:inline-flex; font-family: Interstate;">
          <div class="elipsis-attachment">
            <span id="{{attachment.id}}"><img src="assets/newMessage/notes.svg" class="p-1 attachment-img">
              <a href="#" title="{{attachment.name}}" class="attachment-font" (click)="downloadAttachment(attachment.id,attachment.name)">{{attachment.name}}</a>
            </span>
            </div>
            <span (click)='deleteAttachedFile(attachment.id)'>
              <img src="assets/newMessage/attachdelete.svg" class="p-1 attchment-cross">
          </span>

          </div>
        </div>

        <input type="hidden" name="attachments">
      </div>
      <a class="d-flex flex-column justify-content-center ellipsis-btn" *ngIf="checkOverFlow(overflowdiv) && !isElipsisShow"
        (click)="showRemainingAttchment($event, attachmentOptions, postDataForAttachmentFile)">
          <img src="assets/newMessage/ellipse.svg" class="p-1 ellipse-size">
        </a>
    </div>



    <div class="editorWrapper">
        <div *ngIf="time | async" >
            <editor-app [elementId]="editorId" [height]="80" [ngClass]="formatFlg ? '': 'hideToolbar'"
            class="my-editor" #editorApp (onLoad)="loadData()" (keyupChangeSymphony)="keyupChangeSymphony($event)"> </editor-app>
          </div>
    </div>


     <div class="actionBar">
      <div class="w-100">
        <div class="d-flex flex-row justify-content-start send-button-div">
          <div class="send-button-img" (click)="sendInquiry($event)">
          <button class="d-flex flex-column button justify-content-center" (click)="sendInquiry($event)">
          </button>
        </div>
        </div>
      </div>
    </div>
  </div>

    <div class="black-overlay" *ngIf="displayFullMsg">
      <div class="content-body">
        <div class="d-flex flex-row align-items-center justify-content-between">
          <h6 class="fullMsg">FULL MESSAGE</h6>
          <button type="button" (click)="hideModal()" class="d-flex flex-row close qma-text">
            <img src="assets/Inbox/close_icon_u73.svg">
            <h6>CLOSE</h6>
          </button>
        </div>
        <div class="pt-2 full-content" [innerHtml]="fullContent | safeHtml"></div>
      </div>
    </div>

    <div class="black-overlay" *ngIf="originalMsg">
      <div class="content-body">
        <div class="d-flex flex-row align-items-center justify-content-between">
          <h6 class="fullMsg">FULL MESSAGE</h6>
          <button type="button" (click)="hideModal()" class="d-flex flex-row close qma-text">
            <img src="assets/Inbox/close_icon_u73.svg">
            <h6>CLOSE</h6>
          </button>
        </div>
        <div class="pt-2 full-content" [innerHtml]="originalContent | safeHtml"></div>
      </div>
    </div>
</div>
</div>
<div *ngIf="showSpinner" class="chat-view-loading">
  <div class="detail-view-container-with-sidebar">
      <ng-template #customLoadingTemplate>
          <div class="custom-class-loader-mail-detail-view">
              <div class="spinner-container">
                  <svg class="loader" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340 340">
                      <circle cx="170" cy="170" r="160" stroke="#021e48" />
                      <circle cx="170" cy="170" r="135" stroke="#00bdf2" />
                      <circle cx="170" cy="170" r="110" stroke="#021e48" />
                      <circle cx="170" cy="170" r="85" stroke="#00bdf2" />
                  </svg>
              </div>
          </div>
      </ng-template>
      <ngx-loading [show]="showSpinner" [config]="{ backdropBorderRadius: '3px',fullScreenBackdrop:false }" [template]="customLoadingTemplate"></ngx-loading>
  </div>
</div>
<p-overlayPanel [style]="{'margin-top': '-2em'}"  #attachmentOptions appendTo="body" styleClass="attachmentDropdownChat">
  <p-listbox [listStyle]="{'max-height':'154px', 'max-width':'121px,', 'overflow-x': 'hidden'}" [options]="attachmentsOptions">
      <ng-template let-attachOpt pTemplate="item">
          <div class="opt-items tooltip-text" style="display:inline-flex" >
            <div class="elipsis-attachment">
                <span class="elipsis-attachment">
                    <span ><img src="assets/newMessage/notes.svg" class="p-1 attachment-img">
                      <a href="#" title="{{attachOpt.name}}" class="attachment-font" (click)="downloadAttachment(attachOpt.id,attachOpt.name)">{{attachOpt.name}}</a>
                    </span>
                  </span>
            </div>
                <span>
                  <a (click)='deleteAttachedFilePanel(attachOpt.id)'>
                  <img src="assets/newMessage/attachdelete.svg" class="p-1 attchment-cross">
                </a>
              </span>
        </div>
      </ng-template>
  </p-listbox>
</p-overlayPanel>

<p-confirmDialog styleClass="confirmationChat" header="Confirm Action" icon="pi pi-exclamation-triangle" width="425" acceptLabel="Yes" rejectLabel="No"></p-confirmDialog>

<!-- C153176-4793 : Chat Feedback Sussess Message-->
<p-dialog [(visible)]="feedbackFlag" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'" [styleClass]="'contextMenuModal successModal ModelClose'"
  [closable]="true" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" [style]="{width: '20%'}">
  <p-header>
    <div class="d-flex flex-row align-items-center justify-content-between">
      <h6 class="success-title">Success Confirmation</h6>
    </div>
  </p-header>
  <div class="success-msg"> Chat feedback is saved successfully!</div>
</p-dialog>


<app-gfid-search [isNewMessage]="true" (addClicked)="getGFID($event)"></app-gfid-search>
<app-global-directory-suggestion *ngIf="displaySuggestion" [displaySuggestion]="displaySuggestion"
[suggestionRecords]="suggestionRecords"
(citiUserEvent)="addSuggestionToRecipient($event)"></app-global-directory-suggestion>

<div *ngIf="!selectedMail" class="detail-view-no-message">
    <div>
        Select an item to read
    </div>
</div>



<div #participantOverlay>
    <div *ngIf="displayParticipant" class="additional-users-overlay pl-2 pr-1 py-1">
      <!-- <div class="d-flex flex-row align-items-center justify-content-center"> -->
       <!--  <div class="additional-users-header">Participants</div> -->
        <!-- C153176-4022 | Show this if participents are more than 6 -->
      <!--   <div *ngIf="allUsersSymphony?.length > 5"><img class="up-chevron" [src]="'assets/core/layout/nav/up_chevron.svg'"></div> -->
      <!-- </div> -->
     <!--  <div> -->
        <ul class="participant-list">
          <li *ngFor="let item of allUsersSymphony;let i = index" class=" d-flex flex-row align-items-center my-1">
            <!-- Show Participent name on title  -->
            <span class="dot"></span>
            <div class="participant-name" title="{{item.displayName}}">
              {{item.displayName}}
              <span *ngIf="item?.isOwner || item?.isCreator">
                ({{item.isOwner ? 'Owner':''}}<span  *ngIf="item?.isOwner && item?.isCreator">,</span>{{item.isCreator ? 'Creator':''}})
              </span>
            </div>
          </li>
        </ul>
     <!--  </div> -->
    </div>
</div>

<p-dialog [(visible)]="isSymphonyServiceError" [styleClass]="'symphony-service-error'"
header= " Symphony services unavailable please try again later"
[style]="{'font-size': '12px !important','font-family':'InterstateLight !important','color': '#002d72 !important','text-transform': 'none !important'}">


 </p-dialog>
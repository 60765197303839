import { Component, OnChanges, OnDestroy, Input, SimpleChanges } from '@angular/core';
import { GlobalDirectoryService } from 'src/app/services/global-directory/global-directory.service';
import { UserDataService } from 'src/app/services/user-data.service';

@Component({
  selector: 'gdir-contact-details',
  templateUrl: './gdir-contact-details.component.html',
  styleUrls: ['./gdir-contact-details.component.scss']
})
export class GdirContactDetailsComponent implements OnChanges, OnDestroy {

  @Input() soeId: string = '';
  @Input() authKey: any =null; // authkey needs to be passed to component to display profile pic.
  basicDetails: string = '';
  workDetails: string = '';
  contactDetails: string = '';
  name: string = '';
  job_title: string = '';
  officer_title: string = '';
  location: string = '';
  work_phone: string = '';
  email_id: string = '';
  isCollapsed: boolean = true;
  profilePicSrc: any=null;
  auId: string;
  contactInfoEndPoint: string;

  constructor(private globalDirectoryService: GlobalDirectoryService, private userDataService: UserDataService) {
  }

  ngOnInit() {
    
    this.auId = this.userDataService.loggedInUserInfo.globalDirectoryConfig.contactInfoConfig.auid;
    this.contactInfoEndPoint = this.userDataService.loggedInUserInfo.globalDirectoryConfig.contactInfoConfig.contactInfoEndPoint;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.soeId && changes.soeId.previousValue != changes.soeId.currentValue && this.soeId !== undefined && this.soeId !== '') {
      this.globalDirectoryService.getDetailsBySoeID(this.soeId, "BASIC", this.auId, this.contactInfoEndPoint).subscribe((data: string) => {
        this.basicDetails = data;
        this.name = this.parseKey(this.basicDetails, "Name");
        this.location = this.parseKey(this.basicDetails, "Location");
        console.log(data);
      }, (error) => {
        console.log(error);
      });

      this.globalDirectoryService.getDetailsBySoeID(this.soeId, "WORK", this.auId, this.contactInfoEndPoint).subscribe((data: string) => {
        this.workDetails = data;
        this.officer_title = this.parseKey(this.workDetails, "Officer_Title");
        this.job_title = this.parseKey(this.workDetails, "Job_Title");
      }, (error) => {
        console.log(error);
      });

      this.globalDirectoryService.getDetailsBySoeID(this.soeId, "CONTACT", this.auId, this.contactInfoEndPoint).subscribe((data: string) => {
        this.contactDetails = data;
        this.work_phone = this.parseKey(this.contactDetails, "Work_Phone");
        this.email_id = this.parseKey(this.contactDetails, "Email_ID");
      }, (error) => {
        console.log(error);
      });
    }
    //this.getProfilePic();
  }
  // responseType is Text, parse XML keys from the text data and get values
  parseKey(textToParse: string, key: string) {
    if (textToParse.indexOf('<' + key + '/>') != -1) return ''; // if XML key doesnt have data it will be self closing e.g. <Name/>
    let subString = textToParse.substr(textToParse.indexOf(key) + key.length + 1, textToParse.length);
    let value = subString.substr(0, subString.indexOf('</' + key + '>'));
    return value;
  }

  ngOnDestroy() {
  }

  toggleContactInfo() {
    this.isCollapsed = !this.isCollapsed;
  }


  getProfilePic() {
    if (this.authKey !== null) {
      this.userDataService.LocalGetLoginUserInfo().subscribe(loginUserInfo => {
        let globalDirectoryConfig = loginUserInfo.globalDirectoryConfig;
        if (globalDirectoryConfig) {
          let CSI = globalDirectoryConfig.csi;
          let AUID = globalDirectoryConfig.profilePictureConfig.auid;
          let AUTHKEY = this.authKey;
          let picSrc = globalDirectoryConfig.profilePictureConfig.profilePicEndPoint;

          this.profilePicSrc = picSrc + "?authkey=" + AUTHKEY + "&CSI=" + CSI + "&AUID=" + AUID;

          console.log('making url ' + this.profilePicSrc);
        }
      });
    }
  }
}

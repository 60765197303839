import { Injectable, OnInit } from '@angular/core';
import { Observable, BehaviorSubject } from "rxjs";
import { UserDataService } from "src/app/services/user-data.service";
import { keyframes } from '@angular/animations';

@Injectable({
  providedIn: 'root'
})
export class RequestCacheService {

  // C153176-4456: cache of opened conversation histories, aged with periodic timer
  private cacheMap: any = {};
  private cacheMapCleanupTimer: any;
  private readonly CACHE_TIMER_INTERVAL: number = 300000; // 5-minute
  private readonly CACHE_TTL_MS = 3600*1000; // 1-hour

  loginUserInfo: any;
  // C153176-4663: primary columns to be populated to conversation history rowData
  private missingAttrs = ['age','ageInHrs','reOpenAge','reOpenAgeInHrs','requestType','requestTypeStr',
    'processingRegion','inquirySource','rootCause','convCount','modDate.$date'];
  private overrideAttrs = ['requestTypeStr'];

  constructor(private userDataService: UserDataService) {
    this.userDataService.LocalGetLoginUserInfo().subscribe(loginUserData => {
      this.loginUserInfo = loginUserData;
    });
  }

  /**
   * C153176-4456: cache look-up
   * @return: cache look-up match result
   */
  lookupCache(request: any, isAllConvContent: any) {
    if (!request) {
      return undefined;
    }
    const key = this.generateKey(request, isAllConvContent);
    if (!key) {
      return undefined;
    }
    let v = this.cacheMap[key];
    return v? v.response : undefined;
  }

  /**
   * C153176-4663: fill properties missing at destination inquiry from source inquiry
   */
  fillInquiryAttributes(srcInquiry, destInquiry) {
    if (!srcInquiry || !destInquiry) {
      return;
    }
    Object.keys(srcInquiry).forEach(key => {
      if (this.missingAttrs.includes(key) && !destInquiry.hasOwnProperty(key) ||
        this.overrideAttrs.includes(key)) {
        destInquiry[key] = srcInquiry[key];
      }
    });
  }

  /**
   * C153176-4456: update cache
   */
  updateInquiryCache(request, isAllConvContent, response) {
    // C170665-524 Performance Issue - While switching an inquiry in the grid
     /*  const key = this.generateKey(request, isAllConvContent);
      if (!key) {
        return;
      }

      // C153176-4663: fill in missing data in inquiry if the 'fromCache' is absent or false
      if (response && !response.fromCache) {
        this.fillInquiryAttributes(request, response.inquiry);
      }
      let v = this.cacheMap[key];
      if (!v) {
          v = {};
          this.cacheMap[key] = v;
      }
      response.fromCache = true;
      v.response = response;
      v.timestamp = Date.now();
      if (this.cacheMapCleanupTimer === undefined) {
          this.cacheMapCleanupTimer = setInterval(()=> {
              this.cleanupCache()
          }, this.CACHE_TIMER_INTERVAL);
      } */
  }

  isValidTimestamp(t, now) {
      return t && ((now - t) < this.CACHE_TTL_MS);
  }

  /**
   * C153176-4456: generate cache keys
   */
  generateKey(data, isAllConvContent) {
    if (!data) {
      return undefined;
    }
    if (isAllConvContent === undefined || isAllConvContent == null) {
      isAllConvContent = false;
    }
    // key1: partial match key, format: inquiryId_grpId
    let key = '' + (data._id ? data._id : data.id);
    // C153176-4660: extend key with 'isAllConvContent'
    key += '_' + isAllConvContent;
    if (data.workflows && data.workflows.length) {
        key += '_' + data.workflows[0].assignedGroupId;
    }
    key += '_' + (data.version ? data.version : (data.modDate? data.modDate.$date : '')); // C153176-5031 null check
    /*
    key += '_' + (data.version ? data.version : ''); // C153176-5120 separate version and modDate in key
    key += '_' + (data.modDate? data.modDate.$date : ''); // C153176-5031 null check
    key += '_' + (data.convCount ? data.convCount : 0); // C153176-5120 add convCount
    */
    key += '_' + (data.readBy && data.readBy.findIndex(r => r === this.loginUserInfo.userId) > -1);
    // C153176-4668: use last action time vs. latest conversation time as part of cache key
    key += this.getLastActionTime(data);
    // C153176-4668: use openUsers as part of cache key
    key += this.getOpenUser(data);
    return key;
  }

  /**
   * C153176-4456: cache clean-up routine
   * C153176-4660: add flag to clear all the entries
   */
  cleanupCache(all = false) {
      const now = Date.now();
      const deletedKeys = Object.keys(this.cacheMap).filter(k => all || (this.cacheMap[k]
         && !this.isValidTimestamp(this.cacheMap[k].timestamp, now)));
      if (deletedKeys) {
          deletedKeys.forEach(k => {
              delete this.cacheMap[k];
              this.cacheMap[k] = undefined;
          });
      }
      if (all) {
        this.cacheMap={};
      }
  }

  /**
   * C153176-6110 : Method to delete specific entry from the inquiry cache.
   * @param id 
   */
  cleanupSpecificInquiryCache(id: any) {
    if (!id) {
      return;
    }
    const deleteKey = Object.keys(this.cacheMap).filter(k => k.includes(id));
    if (deleteKey) {
      deleteKey.forEach(k => {
        console.log(deleteKey);
        delete this.cacheMap[k];
        this.cacheMap[k] = undefined;
      });
    }
  }

  /**
   * C153176-4668: retrieve last action time or latest conversation time from inquiry
   */
  private getLastActionTime(data) {
    if (!data.workflows ||!data.workflows.length) {
      return '';
    }
    let res = 0;
    res = data.workflows.reduce((r, wf) => {
      let t = wf.lastActionTime ? Date.parse(wf.lastActionTime.$date) : 0;
      if (!t) {
          t = wf.latestConversationTime ? Date.parse(wf.latestConversationTime.$date): 0;
      }
      return (t && t > r) ? t : r;
    }, res);
    return '_' + res;
  }

  private getOpenUser(data) {
    let assignedTo = data.openUsers;
    //remove assigned group from assignedTo
    if (assignedTo && assignedTo.includes("[")) {
      return '_' + assignedTo.split("[")[0]
    } if (assignedTo && !assignedTo.includes("[")) {//if assigned to not contains group do nothing
      return '_' + assignedTo;
    }

    //Adding whitespace so will be availale in grp By
    if (!assignedTo) {
      return "";
    }

  }

}

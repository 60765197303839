<p-dialog [(visible)]="display" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'"
  [styleClass]="'contextMenuModal'" [closable]="false" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true"
  [style]="{'max-width':'700px','width':'70%'}">

  <p-header>
    <div class="d-flex flex-row align-items-center justify-content-between mx-2 mt-1">
      <h6 class="reage-title">{{headerText}}</h6>
      <button type="button" (click)="hideModal()" class="d-flex flex-row close qma-text">
        <img src="assets/Inbox/close_icon_u73.svg" alt="Close">
        <h6>CLOSE</h6>
      </button>
    </div>
  </p-header>
  <div class="reage-body mx-2">
    <form>
      <div *ngIf="!isMessage" class="d-flex flex-row align-items-center input-holder mb-4">
        <div class="d-flex flex-row align-items-center mb-4 pb-4">
          <label>{{labelText}}</label>
        </div>
      </div>
      <div *ngIf="isMessage" class="d-flex flex-column">
        <div class="notification-sub-header pb-2">Reason for rejection: <span class="mandatory">*</span></div>
        <div class="d-flex flex-row w-100 align-items-center nominate-holder mb-3">
          <div>
            <textarea cols="8" name="nominateOwner" [(ngModel)]="rejectionReason" rows="6"></textarea>
          </div>
        </div>
        <div *ngIf="isReasonFlag">
          <span class="mandatory">Please enter reason for rejection.</span>
        </div>
      </div>
    </form>
  </div>
  <p-footer *ngIf="isMessage">
    <div class="d-flex flex-row flex-wrap bottom-button-holder px-3 pb-2 pt-3 justify-content-end">
      <button class="btn search-btn mr-4" (click)="onRejectionSubmit()">Submit</button>
      <button type="reset" class="btn clear-btn" (click)="hideModal()">Cancel</button>
    </div>
  </p-footer>
</p-dialog>
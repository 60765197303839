<p-dialog [(visible)]="display" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'"
  [styleClass]="'contextMenuModal'" [closable]="false" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true"
  [style]="{'max-width':'700px','width':'100%'}" [resizable]="false">
  <p-header>
    <div class="d-flex flex-row align-items-center justify-content-between mx-2 mt-1">
      <h6 class="gfid-title">GROUP DETAILS</h6>
      <button type="button" (click)="hideModal()" class="d-flex flex-row close qma-text">
        <img src="assets/Inbox/close_icon_u73.svg" alt="Close">
        <h6>CLOSE</h6>
      </button>
    </div>
  </p-header>
  <div class="gfid-body mx-2">
    <div class="grid-holder mt-4 mb-2">
      <ag-grid-angular  *ngIf="firstLoad" style="width:100%; height:202px;" class="ag-theme-balham inbox-grid gfid-grid group-details"  (suppressHorizontalScroll)="true"
        [gridOptions]="gridOptions" [rowData]="rowData" [columnDefs]="columnDefs" (gridReady)="onGridReady($event)" [rowHeight]="79"
        (rowClicked)='onRowClicked($event)' [modules]="modules">
      </ag-grid-angular>
    </div>
  </div>
</p-dialog>
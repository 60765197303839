import { Component, OnInit,Input  } from '@angular/core';
import { LeftNavComponent } from '../leftnav/leftnav.component';

@Component({
  selector: 'app-symphony-contextmenu',
  templateUrl: './symphony.contextmenu.component.html',
  styleUrls: ['./symphony.contextmenu.component.scss']
})
export class SymphonyContextMenuComponent implements OnInit {

  @Input() x=0;
  @Input() y=0;
  @Input() target:string ="";

  constructor(private parentComponent: LeftNavComponent) {
  }

  ngOnInit() {
  }

  menuItemClick(event,optionClicked) {
      if (event.currentTarget) {
        event.currentTarget.id = this.target;
      } else if (event.srcElement) {
        event.srcElement.id = this.target;
      }
      this.parentComponent.itemSelected(event,optionClicked);
  }
}

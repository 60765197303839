import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UserAdminService } from 'src/app/services/user-admin/user-admin.service';
import { GroupAdminService } from 'src/app/services/group-admin/group-admin.service';
import { MessageService } from 'primeng/api';
import { AppUtils } from "src/app/common/utility/appUtil";

@Component({
  selector: 'app-user-admin',
  templateUrl: './user-admin.component.html',
  styleUrls: ['./user-admin.component.scss']
})
export class UserAdminComponent implements OnInit {
  notValidEmail: boolean;
  userNotMatch: boolean = false;
  userNotExist: boolean = false;
  @Output() modalClosed = new EventEmitter<boolean>();
  @Input() isOpen: boolean = false;
  showSpinner: boolean = false;
  userNotFound = false;
  isUserAdminSearchClicked = false;
  newUserSoeid: string = '';
  userDetails: any;
  validationError;
  selectedAdminRoles = [];
  availableAdminRoles = [];
  errorMessage: string;
  successMessage: string;
  defaultAdminRoles = ["IS Admin", "Prod Support"];
  showGroupName: boolean = false;
  activeFlag: number = 0;
  actTabIdx: number = 0;
  displaySuccess: boolean = false;// for success message
  displayError: boolean = false;// for error message
  blankStringValidationMsg: string = "UserId can not be blank";
  newUserSoeIdValidationFlag: boolean = false;
  constructor(private userAdminService: UserAdminService, private groupAdminService: GroupAdminService, private messageService: MessageService) { }

  ngOnInit() {
  }

  resetMessages() {
    this.validationError = '';
    this.successMessage = '';
    this.errorMessage = null;
  }

  resetData() {
    this.userNotFound = false;
    this.userNotMatch = false;
    this.userDetails = null;
    this.selectedAdminRoles = [];
  }

  reset() {
    this.resetMessages();
    this.resetData();
  }

  closeModal() {
    this.modalClosed.emit();
  }

  keyDownFunction(event) {
    try {
      this.newUserSoeIdValidationFlag = false;
      if (event.keyCode == 13 || event.type == "click") {
        this.isUserAdminSearchClicked = true;

        console.debug(":SPINNER:: " + this.showSpinner + " ::user-admin.keyDownFunction");
        this.reset();
        if (!AppUtils.isUndefinedNullOrBlankStrAfterTrim(this.newUserSoeid)) {
          this.showSpinner = true;
          this.userAdminService.getUserDataById({ 'userId': this.newUserSoeid.trim() }).subscribe(userDetails => {
            this.showSpinner = false;
            console.debug(":SPINNER:: " + this.showSpinner + " ::user-admin.keyDownFunction");
            if (userDetails !== null) {
              this.userDetails = userDetails;
              if (userDetails.adminRoles && userDetails.adminRoles.length > 0) {
                this.selectedAdminRoles = userDetails.adminRoles.map(role => { return { role } });
              }
              if (userDetails.configDefaultAdminRoleList && userDetails.configDefaultAdminRoleList.length > 0) {
                this.availableAdminRoles = userDetails.configDefaultAdminRoleList.map(role => { return { role } });
              }
              else {
                this.availableAdminRoles = this.defaultAdminRoles.filter(role => !this.selectedAdminRoles.some(item => item.role === role)).map(role => { return { role }; });
              }
            }
            else {
              this.validationError = 'User Id not found! Please enter details to create new user.';
              this.userNotMatch = true;
              this.userNotFound = true;
              this.userDetails = { userId: this.newUserSoeid, userName: '', userEmail: '', active: true };
              this.availableAdminRoles = this.defaultAdminRoles.filter(role => !this.selectedAdminRoles.some(item => item.role === role)).map(role => { return { role } });
            }
          }, (error) => {
            console.log(error);
            this.errorMessage = `An error occured while searching user`;
            this.showSpinner = false;
            console.debug(":SPINNER:: " + this.showSpinner + " ::user-admin.keyDownFunction");
          });
        } else {
          this.newUserSoeIdValidationFlag = true;
        }
      }
    }
    catch (error) {
      console.log('Exception in keyDownFunction()' + error.message);
    }
  }

  saveChanges() {
    // C170665-1144 GIDA Team Facing ISA Access Issue - While try to modify a user id which already exist on the application.
    if (this.userDetails && this.userDetails.userId && this.newUserSoeid
      && this.userDetails.userId.toLowerCase() !== this.newUserSoeid.toLowerCase()) {
      try {
        this.userNotMatch = true;
        this.notValidEmail = false;
        this.userNotExist = false;
        this.validationError = "SoeId searched & current soeId are different! Please search again.";
      }
      catch (error) {
        console.log('Exception in saveChanges()' + error.message);
      }
    } else if (!this.isUserAdminSearchClicked) {
      this.userNotMatch = true;
      this.notValidEmail = false;
      this.userNotExist = false;
      this.validationError = "Please search to retrieve user details!";
    } else if (AppUtils.isUndefinedNullOrBlankStrAfterTrim(this.userDetails.userName)) {
      this.userNotExist = true;
      this.userNotMatch = false;
      this.notValidEmail = false;
      this.validationError = "User Id required! Please enter a valid User Id.";
    } else if (AppUtils.isUndefinedNullOrBlankStrAfterTrim(this.userDetails.userName)) {
      this.userNotExist = true;
      this.userNotMatch = false;
      this.notValidEmail = false;
      this.validationError = "UserName required! Please enter a valid UserName.";
    } else if (!AppUtils.userNameValidation(this.userDetails.userName)) {
      this.userNotExist = true;
      this.userNotMatch = false;
      this.notValidEmail = false;
      this.validationError = "UserName contains an unsupported character! Please enter a valid UserName.";
    } else if (AppUtils.isUndefinedNullOrBlankStrAfterTrim(this.userDetails.email)) {
      this.notValidEmail = true;
      this.userNotMatch = false;
      this.userNotExist = false;
      this.validationError = "Email Id required!";
    } else if (!AppUtils.validateEmail(this.userDetails.email)) {
      this.validationError = "Invalid email Id!";
      this.notValidEmail = true;
      this.userNotMatch = false;
      this.userNotExist = false;
    }


    else {
      try {
        var requestObj = { userId: this.userDetails.userId, userName: this.userDetails.userName, userEmail: this.userDetails.email, adminRole: this.selectedAdminRoles.map(item => item.role), active: this.userDetails.active };
        this.showSpinner = true;
        console.debug(":SPINNER:: " + this.showSpinner + " ::user-admin.saveChanges");
        this.groupAdminService.saveUserAdminData(requestObj).subscribe(userDetails => {
          this.displaySuccess = true;
          this.resetMessages();
          this.showSpinner = false;
          console.debug(":SPINNER:: " + this.showSpinner + " ::user-admin.saveChanges");
          this.displaySuccess = true;
          this.successMessage = `User details updated successfully!`;
          this.isOpen = false;
          setTimeout(() => { this.displaySuccess = false; this.closeModal(); }, 3000);
        }, (error) => {
          console.log(error);
          this.errorMessage = `An error occured while saving changes`;
          this.showSpinner = false;
          console.debug(":SPINNER:: " + this.showSpinner + " ::user-admin.saveChanges");
          this.displayError = true;
          setTimeout(() => { this.displayError = false; this.closeModal(); }, 3000);
        });
      }
      catch (error) {
        this.showSpinner = false;
        console.debug(":SPINNER:: " + this.showSpinner + " ::user-admin.saveChanges");
        console.log('Exception in ' + error.message);
      }
    }
  }
  onTabChange(event) {
    this.activeFlag = event.index;
  }
}

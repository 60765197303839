import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { ExcelService, ExcelExport } from "src/app/services/common/excel.service";
import { DatePipe } from "@angular/common";
import  * as _ from 'underscore';
import { UserDataService } from 'src/app/services/user-data.service';
import { PiwikProUtils } from 'src/app/common/utility/piwikProUtils';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const AUDIT_DETAILS = {
  FILE_NAME: "Export Audit Trails.xls",
  SHEET_NAME: "Audit Trail Sheet",
  HEADERS: ["User Action", "Action Details", "Group Details", "Updated By", "Time", "Tag"]
}



@Component({
  selector: 'app-audit-detail',
  templateUrl: './audit-detail.component.html',
  styleUrls: ['./audit-detail.component.scss']
})
export class AuditDetailComponent implements OnInit, OnChanges {

  @Input() audits: any = [];
  auditList: any = [];
  auditCount: number = 0;
  PDF_FILE_NAME = "Export Audit Trails.pdf";
   pdfDefination = {
   // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
    pageMargins: [ 10, 10, 10, 10 ],

     pageSize: 'A4',
    content: [
    {
        table: {
          widths: [70, 100, 90, 100, 90, 70],
          headerRows: 1,
          body: []
        }
      }
    
    ],
    styles: {
    
      tableHeader: {
        bold: true,
        fontSize: 13,
        color: 'black',
        fillColor: '#ffff00',
      }
    }
    
  }

  constructor(private userDataService: UserDataService, private excelService: ExcelService, private datePipe: DatePipe) { }

  ngOnInit() { }

  ngOnChanges(): void {
    this.auditCount = this.audits.length;
    this.auditList = [];

    // C153176-4424 | Sort the audit list to descending order.
    let sortedAudits: Array<any> = [];
    sortedAudits = _.sortBy(this.audits, function (i) { return -i.modDate; });

    sortedAudits.forEach(audit => {
      const auditObj = audit;

      if (audit.actionDetails) {
        auditObj.isExternal = audit.actionDetails === 'External' ? true : false;
        auditObj.isInternal = audit.actionDetails === 'Internal' ? true : false;
      } else {
        auditObj.actionDetails = '';
        auditObj.isExternal = auditObj.isInternal = false;
      }

      // Action and By UserId
      // C153176-5409 : Uniform name
      let userId = audit.userId ? 'By ' + audit.userId : audit.modBy ? 'By ' + audit.modBy : '';
      auditObj.actionBy = (audit.action + ' ' + userId).trimRight();

      // Tag
      auditObj.tag = audit.tag ? audit.tag : '';
      //Escalation
      if(audit.isClientChaseEscalation){
        auditObj.isClientChaseEscalation = 'Client Chase Escalation';
        auditObj.actionBy = '';
        auditObj.actionDetails = '';
      }
      if(audit.isConvCountEscalation){
        auditObj.isConvCountEscalation = 'Conv Count Escalation';
        auditObj.actionBy = '';
        auditObj.actionDetails = '';
      }
      if(audit.isSubjectEscalation){
        auditObj.isSubjectEscalation = 'Subject Escalation';
        auditObj.actionBy = '';
        auditObj.actionDetails = '';
      }
      if(audit.ispendingApprovalEscalation){
        auditObj.ispendingApprovalEscalation = 'Pending Approval Escalation';
        auditObj.actionBy = '';
        auditObj.actionDetails = '';
      }
      this.auditList.push(auditObj);
    });
  }

  /**
   * Method to download all the audit trails as an excel
   */
  downloadAutitTrails(): void {
    const auditData: any = [];

    let excelAuditList = [];
    Array.prototype.push.apply(excelAuditList, this.auditList);

    excelAuditList.forEach(audit => {
      let auditArr: string[] = [];
      auditArr[0] = audit.action;
      auditArr[1] = audit.actionDetails;
      auditArr[2] = audit.groupName;
      auditArr[3] = audit.modBy === null ? "" : audit.modBy;
      auditArr[4] = this.datePipe.transform(audit.modDate, 'M/d/yyyy H:mm');
      auditArr[5] = audit.tag;
      auditData.push(auditArr);
    });

    const auditDetails: ExcelExport = {
      fileName: AUDIT_DETAILS.FILE_NAME,
      sheetName: AUDIT_DETAILS.SHEET_NAME,
      header: AUDIT_DETAILS.HEADERS,
      data: auditData,
      width: [20, 20, 40, 40, 20, 20],
      height: 20
    };

    console.log("Request to generate audit trail report");
    this.excelService.generateExcel(auditDetails);
    PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Mailboxes ", "Download audit", "Click", "Download audit", 0);
  }

  downloadAutitTrailsAsPDF() {
    // reset table body
    this.pdfDefination.content[0].table.body = [];
    // add header
    let pdfHeaders = [];
    AUDIT_DETAILS.HEADERS.forEach(ad => {
      pdfHeaders.push({text: ad, style: 'tableHeader'});
    });
    this.pdfDefination.content[0].table.body.push(pdfHeaders);
    // add table body
    let excelAuditList = [];
    const auditData: any = [];
    Array.prototype.push.apply(excelAuditList, this.auditList);

    excelAuditList.forEach(audit => {
      let auditArr = [];
      auditArr[0] = {text: audit.action, color: 'black', fillColor: '#e9e9e2'};
      auditArr[1] = {text: audit.actionDetails, color: 'black', fillColor: '#e9e9e2'};
      auditArr[2] = {text: audit.groupName, color: 'black', fillColor: '#e9e9e2'};
      auditArr[3] = {text: audit.modBy === null ? "" : audit.modBy, color: 'black', fillColor: '#e9e9e2'};
      auditArr[4] = {text: this.datePipe.transform(audit.modDate, 'M/d/yyyy H:mm'), color: 'black',fillColor: '#e9e9e2'};
      auditArr[5] = {text: audit.tag, color: 'black', fillColor: '#e9e9e2'};
      auditData.push(auditArr);
    });
    auditData.forEach(auditDataRow => {
      this.pdfDefination.content[0].table.body.push(auditDataRow);
    });
    pdfMake.createPdf(this.pdfDefination).download(this.PDF_FILE_NAME);
  }

}
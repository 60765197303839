import { Injectable } from "@angular/core";
import * as Rx from "rxjs";
import { Observable } from "rxjs";
import { trigger } from '@angular/animations';

@Injectable({
    providedIn: 'root'
})
export class GridOperationService {

    // This is behavior when user select the conversation history in inbox grid.
    conversationSubject$ = new Rx.BehaviorSubject([]);

    //flag as indicator of websocket updates completed on grid
    isGridUpdated$ = new Rx.BehaviorSubject(true);

    // C153176-4660: behavior when user select inquiry in mailbox
    inquirySelection$ = new Rx.BehaviorSubject([]);

    // C153176-4660: cache for selected conversation, keyed by current tab in upper case
    private selectedConversations: any = {};
    // C153176-4660: cache for selected conversation, keyed by current tab in upper case
    private detailViewConversations: any = {};

    // C153176-4660: cache of latest conversation request
    latestConvRequestData: any;
    // C153176-4910
    latestConvId: any;

    setConversation(selectedConv:any): void {
        this.conversationSubject$.next(selectedConv);
    }

    getConversation(): Observable<any>{
        return this.conversationSubject$.asObservable();
    }

    clearConversation() : void {
        this.conversationSubject$.next([]);
    }

    getisGridUpdated() : Observable<any>{
        return this.isGridUpdated$.asObservable();
    }

    setisGridUpdated(isGridUpdated) : void{
        return this.isGridUpdated$.next(isGridUpdated);
    }

    setInquirySelection(selectedInq:any): void {
        this.inquirySelection$.next(selectedInq);
    }

    getInquirySelection(): Observable<any>{
        return this.inquirySelection$.asObservable();
    }

    setSelectedConversation(tabName, inquiryId, convId, assignedGroupName, assignedGroupId) {
        if (!tabName || !inquiryId || !convId) {
            return;
        }
        tabName = tabName.toUpperCase();
        this.selectedConversations[tabName] = {
            inquiryId: inquiryId,
            convId: convId,
            assignedGroupName: assignedGroupName,
            assignedGroupId: assignedGroupId
        }
    }

    getSelectedConversation(tabName) {
        tabName = tabName.toUpperCase();
        return this.selectedConversations[tabName];
    }

    clearSelectedConversation(tabName, inquiryId = undefined) {
        tabName = tabName.toUpperCase();
        if (!inquiryId) {
            delete this.selectedConversations[tabName];
            this.selectedConversations[tabName] = undefined;
        } else {
            let selected = this.selectedConversations[tabName];
            if (selected && selected.inquiryId === inquiryId) {
                delete this.selectedConversations[tabName];
                this.selectedConversations[tabName] = undefined;
            }
        }
    }

    setDetailViewConversation(tabName, inquiryId, convId, assignedGroupName, assignedGroupId) {
        if (!tabName || !inquiryId || !convId) {
            return;
        }
        tabName = tabName.toUpperCase();
        this.detailViewConversations[tabName] = {
            inquiryId: inquiryId,
            convId: convId,
            assignedGroupName: assignedGroupName,
            assignedGroupId: assignedGroupId
        }
    }

    getDetailViewConversation(tabName) {
        tabName = tabName.toUpperCase();
        return this.detailViewConversations[tabName];
    }

    clearDetailViewConversation(tabName) {
        tabName = tabName.toUpperCase();
        delete this.detailViewConversations[tabName];
        this.detailViewConversations[tabName] = undefined;
    }

    matchSelectedConversation(checkWebsocket, tabName, inquiry, assignedGroupName, assignedGroupId) {
        if (!inquiry ) {
            return false;
        }
        if (checkWebsocket && !inquiry.websocket) {
            return false;
        }
        //TODO:Performance ::  Above false will always gets returned and below code will not gets executed as inquiry.weksocket was updated to true/false in older implementation.
        let selectedConv = this.getSelectedConversation(tabName);
        if (!selectedConv || selectedConv.inquiryId !== inquiry._id) {
            return false;
        }
        // TBD check assignedGroupName
        if (assignedGroupId && assignedGroupId.length && selectedConv.assignedGroupId && selectedConv.assignedGroupId.length) {
           let intersection = assignedGroupId.filter(g => selectedConv.assignedGroupId.includes(g));
           if (!intersection || !intersection.length) {
               return false;
           }
        }
        let detailViewConv = this.getDetailViewConversation(tabName);
        if (!detailViewConv || detailViewConv.inquiryId !== inquiry._id) {
            return false;
        }
        // TBD check assignedGroupName
        /*
        if (assignedGroupId && assignedGroupId.length && detailViewConv.assignedGroupId && detailViewConv.assignedGroupId.length) {
            let intersection = assignedGroupId.filter(g => detailViewConv.assignedGroupId.includes(g));
            if (!intersection || !intersection.length) {
                return false;
            }
        }
        */
        // exact match, return true
        return true;
    }

    updateConvHistoryCount(rowData, inboxService, userDataService, requestCacheService) {
        if (!inboxService || !rowData || !userDataService || !requestCacheService) {
            return;
        }
        let index = 0;
        const me = this;
        setTimeout(() => {
            me._updateConvHistoryCount(rowData, index, inboxService, userDataService, requestCacheService);
        }, 3000);
    }

    _updateConvHistoryCount(rowData, index, inboxService, userDataService, requestCacheService) {
        if (index >= rowData.length) {
            return;
        }
        let inq = rowData[index];
        if (!inq) {
            return;
        }
        let selectInqAssignedGroupId = [];
        if (inq.workflows) {
          inq.workflows.forEach(workflow => {
            let assignedGroupId = workflow.assignedGroupId;
            if (userDataService.userGroupIdList.indexOf(assignedGroupId) > -1) {
              selectInqAssignedGroupId.push(assignedGroupId);
            }
          });
        }
        // C153176-5223 | conversation loading issue for nomination
        let hasNominatedOwnership = inq.workflows[0].hasNominatedOwnership !== undefined ? inq.workflows[0].hasNominatedOwnership : false;

        let inquiryData = {
          id: inq._id,
          selectInqAssignedGroupId: selectInqAssignedGroupId,
          isAllConvContent: false, // C153176-4660: do not load all content since only metadata is needed
          hasNominatedOwnership: hasNominatedOwnership
        };
        // Send request to get the all the inquiry conversation for the selected inquiry Id.
        inboxService.getInquiryIdExtSelected(inquiryData, inq).subscribe(inquiryDetail => {
          requestCacheService.updateInquiryCache(inq, inquiryData.isAllConvContent, inquiryDetail);
          if (inquiryDetail.conversationList && inquiryDetail.conversationList.length) {
            inq.convCount = inquiryDetail.conversationList.length;
            if (inquiryDetail.inquiry) {
              inquiryDetail.inquiry.convCount = inquiryDetail.conversationList.length;
            }
          }
          const me = this;
          setTimeout(() => {
              me._updateConvHistoryCount(rowData, index + 1, inboxService, userDataService, requestCacheService);
          }, 2000);
        });
    }

    /**
     * C153176-4660/1910: cache and lookup inquiry/conversation request for conversation details request
     * @return: true if the request shall proceed, false if the request may be skipped
     */
    updateConversationRequest(inquiryData, convId = undefined): boolean {
        if (!inquiryData || !inquiryData.id) {
            return false;
        }
        if (!this.latestConvRequestData) {
            this.latestConvRequestData = inquiryData;
            this.latestConvId = convId;
            return true;
        } else if (this.latestConvRequestData.id === inquiryData.id && this.latestConvId === convId) {
            let group1 = this.latestConvRequestData.selectInqAssignedGroupId;
            let group2 = inquiryData.selectInqAssignedGroupId;
            if (!group1 && !group2) {
                return false;
            } else if (!group1 || !group2) {
                this.latestConvRequestData = inquiryData;
                this.latestConvId = convId;
                return true;
            } else {
                let result = group1.length != group2.length ||
                  !(group1.sort().every((value, index) => { return value === group2.sort()[index]}));
                if (result) {
                    this.latestConvRequestData = inquiryData;
                    this.latestConvId = convId;
                }
                return result;
            }
        } else {
            this.latestConvRequestData = inquiryData;
            this.latestConvId = convId;
            return true;
        }
    }

    /**
     * C153176-4660: clear conversation request data
     */
    clearConversationRequestData() {
        this.latestConvId = undefined;
        this.latestConvRequestData = undefined;
    }

    /**
     * C153176-4614: match two inquiries
     */
    matchSelectedInquiry(inq1, inq2) {
        if (inq1 && inq1.length) {
            inq1 = inq1[0];
        }
        if (!inq1 && !inq2) {
            return true;
        }
        if (!inq1 || !inq2) {
            return false;
        }
        if (inq1._id !== inq2._id) {
            return false;
        }
        if (inq1.openGroups && inq2.openGroups && (inq1.openGroups === inq2.openGroups)) {
            return true;
        }
        if (!inq1.workflows || !inq2.workflows || !inq1.workflows.length || !inq2.workflows.length) {
            return false;
        }
        if (inq1.workflows[0].assignedGroupdName === inq2.workflows[0].assignedGroupName) {
            return true;
        } else {
            return false;
        }
    }
}
<ul class="nav d-flex align-items-center flex-column flex-nowrap right-side-bar" [ngStyle]="getSidebarStyle()"> <!-- C153176-4565: generate CSS classes dynamically-->
    <li class="py-3 w-100"
        [ngClass]="{
        'padding-btm-horizontal':inboxViewMode === 'horizontal',
        'padding-btm': inboxViewMode === 'vertical'}" style="margin-top: 10px">
        <div class="nav d-flex align-items-center flex-column" (click)="slideOpen('docs')">
            <div class="image-holder">
                <span class="top-right">{{attachmentList?.length}}</span>
                <img src="assets/Inbox/attachments_icon_u97.svg" alt="">
            </div>
            <div class="menu-text">DOCS</div>
        </div>
    </li>
    <li class="w-100" [ngClass]="{'padding-btm-horizontal':inboxViewMode === 'horizontal',
    'padding-btm': inboxViewMode === 'vertical'}">
        <div class="nav d-flex align-items-center flex-column" (click)="slideOpen('audit')">
            <div class="image-holder">
                <img src="assets/Inbox/audit_icon_u92.svg" alt="">
            </div>
            <div class="menu-text">AUDIT</div>
        </div>
    </li>
    <li class="w-100" [ngClass]="{'padding-btm-horizontal':inboxViewMode === 'horizontal', 
    'padding-btm': inboxViewMode === 'vertical'}">
        <div class="nav d-flex align-items-center flex-column" (click)="openClc()">
            <div [ngClass]="currentTab === 'PENDING APPROVAL'?'image-holder-disabled':'image-holder'">
                <img src="assets/clc/tranx-lookup-icon.svg" style="width:27px;height:27px" alt="CLC">
            </div>
            <div class="menu-text">TRANX <br> LOOKUP</div> <!-- C170665-1822 Update CLC Assist Icon/Label in QMA -->
        </div>
    </li> 
    <li class="w-100" [ngClass]="{'padding-btm-horizontal':inboxViewMode === 'horizontal',
    'padding-btm': inboxViewMode === 'vertical'}">
        <div class="nav d-flex align-items-center flex-column" (click)="slideOpen('notes')">
            <div class="image-holder">
                <span class="top-right notes-badge">{{noteCount}}</span>
                <img class="notes" src="assets/newMessage/notepad.svg" alt="">
            </div>
            <div class="menu-text">NOTES</div>
        </div>
    </li>
    <li class="w-100" [ngClass]="{'padding-btm-horizontal':inboxViewMode === 'horizontal',
    'padding-btm': inboxViewMode === 'vertical'}" *ngIf = "!hideContactsForTaskize">
        <div class="nav d-flex align-items-center flex-column" (click)="slideOpen('contacts')">
            <div class="image-holder">
                <img src="assets/newMessage/notebook.svg" alt="">
            </div>
            <div class="menu-text">CONTACTS</div>
        </div>
    </li>
</ul>

<!-- Right Panel Components[Open on top Nav buttons click] -->

<p-sidebar [(visible)]="slideVisible" [position]="'right'" [modal]="true" [showCloseIcon]="false"
    styleClass="sideBarStyle" (onHide)="hideSlideBar($event)">

    <p-tabView [activeIndex]="activeIndex" class="pTabStyle" (onChange)="onTabChange($event)">
        <p-tabPanel header="DOCS">
            <ng-template pTemplate="content">
                <app-attachment [attachments]="attachmentList"></app-attachment>
            </ng-template>
        </p-tabPanel>
        <p-tabPanel header="AUDIT">
            <ng-template pTemplate="content">
                <app-audit-detail [audits]="audits"></app-audit-detail>
            </ng-template>
        </p-tabPanel>
        <p-tabPanel header="NOTES">
            <ng-template pTemplate="content">
                <app-notes-details [notes]="notes" [selectedInquiryId]="selectedInquiryId" [assignedGroupName]="assignedGroupName" (noteAdded)="onNotesAdded($event)"></app-notes-details>
            </ng-template>
        </p-tabPanel>
        <p-tabPanel header="CONTACTS" cache="true" *ngIf = "!hideContactsForTaskize">
            <ng-template pTemplate="content">
                <!-- // C153176-4998 - Top Contacts not appearing for New Message and reply/reply all -->
                <contact-list (contactEvent)="onPushContact($event)" [fromGroupName]="groupName" [topContactAdded] = "topContactAdded"></contact-list>
            </ng-template>
        </p-tabPanel>
    </p-tabView>

</p-sidebar>

<!-- END of Right Panel Components -->
<p-dialog [(visible)]="displayClc" [modal]="true" [responsive]="true" [maximizable]="false"
[appendTo]="'body'" [styleClass]="'contextMenuModal CLCclass'" [closable]="false" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true"
[style]="{'min-height':'18em','max-height':'31em','width':'93em','top':'64px !important','margin-bottom':'16em'}">
    <div *ngIf="displayClc">
        <app-clc [clc]="clc" (closeClc)="closeClc($event)" (tradesEvent)="onSelectedTrades($event)"></app-clc>
    </div>
</p-dialog>
import { Pipe, PipeTransform } from '@angular/core';
import { MobileInboxItem } from './mobile-inbox-item.component';

@Pipe({
    name: 'generictransformer',
    pure: false
})
export class GenericTransformerPipe implements PipeTransform {
    constructor(private inboxItemComponent: MobileInboxItem) { }
    transform(templateValue: any,
        fnReference: Function,
        ...fnArguments: any[]) : any {
        // remove one input from fnArguments
        fnArguments.unshift(templateValue);
        // invoke the fnReference function
        return fnReference.apply(this.inboxItemComponent, fnArguments);
    }
}
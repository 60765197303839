   <!-- Jira : 4912 : NLP Suggestion box -->
  <div class="nlp-container d-flex flex-column align-items-between justify-content-between" 
  [ngClass]="displayReason?'nlp-container-with-rejection':'nlp-container'">
      <div >
    <div *ngIf="intermediateDataResponse?.rows?.length>0 && !displayReason" class="intermediate-container" 
      style=""> 
      <div style="display: flex;
      justify-content: space-between;margin-bottom: 10px;
    margin-top: 10px;">
    <div style="width: 77%;
    justify-content: space-between;
    display: flex;">
      <span class="internalReview" style ="color: #021E4A;
      /* font-style: italic; */
      font-size: 12px;
      font-family: 'Interstate';">
        <strong>The internal review table: </strong></span>
    </div>
    <div class="moreDetails" > 
      <a *ngIf="!showIntermediateDataFlg" (click)="showIntermediateDetails()">Show Details</a>
      <a *ngIf="showIntermediateDataFlg" (click)="showIntermediateDetails()">Hide Details</a>
      <div style="display: none;" *ngIf="suggetionStatus=='green'">
      <div class="menu-icon-border-pop-up mr-2 ml-2" >
        <img src="assets/Inbox/divider-line.svg" style="height: 22px;width: 10px;" alt="">
      </div>
      <div>
        <img src="assets/clc/send-feedback-icon.svg" style="cursor: pointer" (click)="onNlpFeedbackClick()" alt="Feedback">
      </div>
    </div>
  </div>
      </div>
     
      <div *ngIf="showIntermediateDataFlg" class="intermediateDataContainer">
        <table class="intermediateTable">
          <caption 
          style="padding: 0px;
          text-align: end;
          /* color: red; */
          font-family: 'Interstate';
          font-style: italic;
          color: #E2302D;
          font-size: 11px; caption-side: top ">
              * Internal Only
          </caption>
            <!-- style="white-space: nowrap;
            color: white;
            text-align: center;background: #021E4A;border: 1px solid #C4C8CE;padding: 10px;font-size: 12px;
  font-family: 'Interstate';padding-left: 5px;
    padding-right: 5px;"  -->
          <thead>
            <th id="nlpReview" *ngFor="let column of intermediateDataResponse.columns; let i = index" 
            class="nlp-review-table-data" style="background: #021E4A;
            color: white;
            padding: 5px;
            font-size: 12px;
            font-family: 'Interstate';border: 1px solid #C4C8CE">{{column}}</th>
          </thead>
          <tbody>
              <!-- [style.background-color]="row.get('isSugestionAvailable')=='y'?'palegreen':''" -->
            <tr 
             *ngFor="let row of intermediateDataResponse.rows; let r = index">
              <td *ngFor="let column of intermediateDataResponse.columns; let c = index" class="nlp-review-table-data"
              style="white-space: nowrap;white-space: nowrap;
              /* background: #021E4A; */
              /* color: white; */
              text-align: center;
              color: #414748;border: 1px solid #C4C8CE;padding: 5px;font-size: 12px;
  font-family: 'Interstate';background-color: white">{{row.get(column)}}</td>
            </tr>
          </tbody>
          
        </table>
      </div>
      
<!--       <div *ngIf="showIntermediateDataFlg" class="intermediateDataContainer">
 -->        <!-- Table column section -->
       <!--  <div class="header d-flex">
            <div *ngFor="let column of intermediateDataResponse.columns; let i = index" 
            style="color: white;    display: flex;
            align-items: center;margin-right: 1px;width:10em;justify-content: center;" class="nlp-review-table-data">{{column}}</div>
        </div> -->
             
      <!-- Table Data rows section -->
    <!--   <div class="rows d-flex">
          <div 
          *ngFor="let row of intermediateDataResponse.rows; let r = index" class="d-flex">
            <div *ngFor="let column of intermediateDataResponse.columns; let c = index"
             [style.background-color]="row.get('isSugestionAvailable')=='y'?'palegreen':''"
              class="nlp-review-table-data d-flex" style ="width: 10em;margin-right: 1px;justify-content: center;">{{row.get(column)}}</div>
          </div>
      </div>
     
     </div> -->
     
    </div>
    <div *ngIf="displayReason" id="reasonContainer" class="d-flex flex-column align-items-between justify-content-between">
        <div style="width: 99%">
          <span class="label-text">Rejection Reason :</span>
          <p-dropdown class="drop-down" [options]="rejectReasons" [styleClass]="'prime-dd-override'" optionLabel="label" (onChange)="onReasonChange($event)">
          </p-dropdown>
        </div>
        <div class="mt-2" *ngIf="canEnterReason">
          <textarea cols="120" name="rejectionReason" [(ngModel)]="rejectionReason" class="inputBox rejectionReason" maxlength="255" rows="3"></textarea>
        </div>
      </div>
    <div [hidden]="!showFeedbackPanel" class="nlpFeedbackPanel" style="padding:2%">
      <div *ngIf="showNlpFeedbackOtherOptions">
              <div style="
              font-family: 'InterstateBold';
              color: #021E4A;
              font-size: 12px;margin-bottom: 15px;
          ">
              Was the suggestion provided an adequate response? 
              <input type="radio" [value]="true" style="margin-left: 20px;margin-right: 20p;" name="adequteResponse" [(ngModel)] ="adequteResponse"> 
              Yes <input type="radio" [value]="false" style="margin-left: 20px;" name="adequteResponse" [(ngModel)]= "adequteResponse"> No
             </div> 
        <div>
        <textarea [placeholder]="feedbackPlaceholder" rows="4" cols="85" [(ngModel)]= "txtUserComments" id="txtFeedbackPanel"></textarea> 
      </div>
      <div style="
      margin-top: 5px;
      margin-bottom: 15px;
  ">
        <img src="./assets/Inbox/send-feedback-icon.svg" alt="Send"><span style="
        font-family: 'InterstateBold';
        color: #021E4A;
        font-size: 12px;
    ">Give Feedback</span> 
      </div>
      <div style="
      font-family: 'Interstate';
      color: #E2302D;
      font-size: 11px;
      font-style: italic;margin-bottom: 15px;
  ">
        **Please review the highlighted attributes in the email below and let us know if any were not matched correctly
        by selecting the correct category in the dropdown menu **
      </div>
      </div>
      <div [innerHtml]="suggetionsArray" style="color: #0F1010;font-family: 'Interstate'; font-size: 12px;" ></div>
       <!--  <div  #feedbackHtml> </div> -->
       <div class="d-flex flex-row justify-content-end" *ngIf="showNlpFeedbackOtherOptions">
         <!-- <button class="d-flex flex-column button justify-content-center" (click)="updateConvSuggstions('accept')" #acceptConvSuggestionsId>
           <img [src]="addBtn" class="p-1">
           <span #addLabel>ADD</span>
         </button> -->

         <button class="btn search-btn mr-4 pop-up-attch-button" (click)="onNlpFeedbackBackClick()" style="margin-top: 0px;
         margin-bottom: 0px;">
           Back
         </button>
         <button class="btn search-btn mr-4 pop-up-attch-button" (click)="saveNlpFeedback()" style="margin-top: 0px;
         margin-bottom: 0px;">
           Save
         </button>
         <!-- <button class="d-flex flex-column justify-content-center button cancel" (click)="updateConvSuggstions('decline')" #declineConvSuggestionsId>
           <img src="assets/newMessage/add-gray.svg" class="p-1">
           <span style="color:gray">REJECT</span>
         </button> -->
         <button class="btn search-btn mr-4 pop-up-attch-button pop-up-reject-btn" 
         (click)="cancelNlpFeedback()" style="margin-top: 0px;
         margin-bottom: 0px;">
          Cancel
         </button>
       </div>
    </div>
    <div id="convSuggestionsContainer" *ngIf="!displayReason && !showFeedbackPanel && showNlpPopup">
      <span *ngIf="showReplyComment" class="internalReview">{{replyComment}}</span>
      <div class="d-flex ext-auto-sugg-header justify-content-between" *ngIf="suggetionStatus=='green'">
        <span *ngIf="!showReplyComment" class="internalReview nlp-ext-sugg-header" style="display: flex;align-items: center;">
          <input type="checkbox" style="color: #021E4A;
          font-family: 'Interstate';
          font-size: 12px;
          font-weight: 800;margin-right: 10px;" [checked]="isExtAuoSuggetionSelected" (change)="chkboxExtSuggChanged()"> Add suggested text to response:
         </span>
         <div id="refreshContainerDiv">
           <span style="font-family: 'InterstateBold';font-size: 12px;">Trade Status As Of: <span class="date">{{ lastUpdatedDate }} </span></span>
           <span id="refreshTimeSpan"></span>
           <img src="assets/Inbox/divider-line.svg" class="p-1" alt="Refresh" style="height: 25px;">
           <span #refreshSuggestion (click)="refreshSuggestions()" *ngIf="enableNLPRefresh()" style="cursor: pointer;">
             <img [src]="refreshBtn" class="p-1 refreshIcon" alt="Refresh">
           </span>
           <span style="color: #007ad9;font-family: 'InterstateLight';font-size: 12px;" *ngIf="enableNLPRefresh()">
            Refresh
           </span>
           <span #refreshSuggestion  *ngIf="!enableNLPRefresh()" style="cursor: pointer;" class="nlp-tooltip">
            <img src="assets/Inbox/refresh-icon-inactive.svg" class="p-1 refreshIcon" alt="Refresh">
            <span class="nlp-tooltiptext" style="color:red;font-size: 14px;font-family:'InterstateLight';box-shadow: 1px 1px 1px 0px gray;background: white;padding: 2px;
            display: flex;
            justify-content: center;
            align-items: center;">
              <img src="assets/Inbox/disabled-icon.svg" class="p-1" alt="Refresh" style="height: 25px;">This is a Bulk Request , Please check relevant business application</span>
          </span>
          <span style="color: #007ad9;font-family: 'InterstateLight';font-size: 12px;"    *ngIf="!enableNLPRefresh()">Refresh</span>
         </div>
      </div>
      
    <!--   <div id="convSuggestionsId" [innerHTML]="suggestions"></div> -->
      <div id="convSuggestionsId" [innerHTML]="suggestions"></div>
     <!--  trade details table -->
     <div class="trade-details-header d-flex align-items-center justify-content-between" 
     style="margin-bottom: 12px;margin-top: 20px;" *ngIf="trade_details_table && trade_details_table.length && trade_details_table.length>0">
      <div class="left d-flex" style="align-items: center;" *ngIf="suggetionStatus=='green' || suggetionStatus=='orange'"> 
          <input type="checkbox" style="margin-right: 10px;" [checked]="isTradeDetailsSelected" (change)="checkboxIsTradeDetailsSelected($event)"> <div style="color: #021E4A;
          font-family: 'Interstate';
          font-size: 14px;
          font-weight: 800;">include trade details in response</div>
      </div>
       
        <div class="right">
            <div class="moreDetails-trade" > 
                <a *ngIf="!showTradeTableDataFlg" (click)="showTradeDetails()">Show Details</a>
                <a *ngIf="showTradeTableDataFlg" (click)="showTradeDetails()">Hide Details</a>
            </div>
        </div>
     </div>
     <div [hidden]="!showTradeTableDataFlg" class="tradeDetailsTbl my-scrollbar" style="display: flex;" *ngIf="trade_details_table && trade_details_table.length && trade_details_table.length>0">
      <as-split unit="percent"[direction]="'horizontal'" #split="asSplit" [gutterSize]="5" > <!-- [gutterSize]="30" --> <!-- (dragEnd)="dragEnd('percent', $event)"  -->
        <as-split-area [size]="50" #asSplitAreaTradeDetails> <!-- C153176-5191: split percentage, C153176-5251: disable splitter while in inlineReply display: none-->
          <!-- display: none -->
          <div #tradeDetailsTable id="tradeDetailsTbl"  class="tradeDetailsTbl tradeDetailsTblExport my-scrollbar" style="overflow: auto;display: none">
       <table class="tradeDetails"  style="" class="tradeDetailsTbl my-scrollbar tradeDetailsTblToExport">
        <thead>
            <tr *ngFor="let tradeDetailRow of trade_details_table; let i = index" [ngStyle]="{'height': i === 0 ? '10px' :  null }">
                <ng-container *ngIf="i === 0 && isAnyRowSelected">
                   <!--  <td  style="white-space: nowrap;
                    color: white;
                    text-align: center;background: #021E4A;border: 1px solid #C4C8CE;padding: 10px;font-size: 12px;
          font-family: 'Interstate';position: relative;">
                         
                       </td> -->
                     
              <td *ngFor="let headerValuePair of tradeDetailRow" style="white-space: nowrap;
              color: white;
              text-align: center;background: #021E4A;border: 1px solid #C4C8CE;padding: 10px;font-size: 12px;
    font-family: 'Interstate';position: relative;">
                
                   {{headerValuePair.header}}
                  
              </td>
             
            </ng-container>
            </tr>
          </thead>
          <tbody>
              <ng-container  *ngFor="let tradeDetailRow of trade_details_table; let i = index">
              <tr *ngIf="tradeDetailRow && tradeDetailRow[0] && tradeDetailRow[0].isROwVisible" style="height:20px">
                <!-- <td style="white-space: nowrap;white-space: nowrap;
                /* background: #021E4A; */
                color: white;
                text-align: center;
                color: #414748;border: 1px solid #C4C8CE;padding: 5px;font-size: 12px;
    font-family: 'Interstate';background-color: white">
<span class="deleteColumnNlp" style="float: right; cursor: pointer;">x</span>
                </td> -->
              
                <td *ngFor="let headerValuePair of tradeDetailRow" style="white-space: nowrap;white-space: nowrap;
                /* background: #021E4A; */
                color: white;
                text-align: center;
                color: #414748;border: 1px solid #C4C8CE;padding: 5px;font-size: 12px;
    font-family: 'Interstate';background-color: white">
                    {{headerValuePair.value}}
                </td>
              </tr>
              </ng-container>
            </tbody>
       </table>
<!--        <div *ngFor="let tradeDetailRow of trade_details_table" class="header-container d-flex">
 -->        <!--  columns -->
        <!-- <div *ngFor="let headerValuePair of tradeDetailRow" >
              <div class="header">
                  {{headerValuePair.header}}
              </div>
        </div> -->
     <!--  </div> -->
     </div>
     <!-- for display -->
     <div #tradeDetailsTableDisplay id="tradeDetailsTbl"  
     class="tradeDetailsTbl my-scrollbar tradeDetailsTblDisplayed" style="overflow: auto;
     align-items: center;
    font-size: 12px;
    font-family: 'Interstate';
    font-weight: 800;
    color: #021E4A;
    background: #F2F9FC;
    border-top: 2px solid #00BDF2;
     " 
     *ngIf="trade_details_table && trade_details_table.length && trade_details_table.length>0">
      <div class="table-header" style="justify-content: center;
      height: 30px;
      display: flex;
      /* background: #021E4A; */
      align-items: center;
      font-size: 12px;
      font-family: 'Interstate';
      font-weight: 800;
      color: #021E4A;
      background: #F2F9FC;
      ">
        Trade details
      </div> 
      <!-- style="display: none;" -->
      <table class="tradeDetails"   style="display: none;" class="tradeDetailsTbl my-scrollbar tradeDetailsTblDisplay" >
        <thead>
          
           <tr *ngFor="let tradeDetailRow of trade_details_table; let i = index" [style.height]="i ===0 ? '20px' : null">
               <ng-container *ngIf="i === 0">
                  <!--  <td  style="white-space: nowrap;
                   color: white;
                   text-align: center;background: #021E4A;border: 1px solid #C4C8CE;padding: 10px;font-size: 12px;
         font-family: 'Interstate';position: relative;">
                        
                      </td> -->
                      <!-- <td  style="white-space: nowrap;
                      color: white;
                      text-align: center;background: #021E4A;border: 1px solid #C4C8CE;padding: 10px;font-size: 12px;
            font-family: 'Interstate';position: relative;border-right-style: hidden;">
                       <input type="checkbox" (change)="onTradeDetailRowSelectionChanged($event,tradeDetailRow,i,true)"
                       [checked]="getTradeRowSelectionStatus().allRowsSelected" style="margin-left: 10px">
                     </td> -->
             <td *ngFor="let headerValuePair of tradeDetailRow" style="white-space: nowrap;
             color: white;
             text-align: center;background: #021E4A;border: 1px solid #C4C8CE;padding: 10px;font-size: 12px;
   font-family: 'Interstate';position: relative;">
               
                  {{headerValuePair.header}}
                 
             </td>
            
           </ng-container>
           </tr>
          </thead>
         <tbody>
                <tr *ngFor="let tradeDetailRow of trade_details_table; let i = index">
                    <!-- <td style="white-space: nowrap;white-space: nowrap;
                    /* background: #021E4A; */
                    color: white;
                    text-align: center;
                    color: #414748;border: 1px solid #C4C8CE;padding: 5px;font-size: 12px;
        font-family: 'Interstate';background-color: white">
     <span class="deleteColumnNlp" style="float: right; cursor: pointer;">x</span>
                    </td> -->
                    <td style="white-space: nowrap;white-space: nowrap;
                    /* background: #021E4A; */
                    color: white;
                    text-align: center;
                    color: #414748;border: 1px solid #C4C8CE;padding: 5px;font-size: 12px;
        font-family: 'Interstate';background-color: white;border-right-style: hidden;">
                      <input type="checkbox" (change)="onTradeDetailRowSelectionChanged($event,tradeDetailRow,i,false)" 
                      [checked]="tradeDetailRow[0].isROwVisible" style="margin-left: 10px"/>
                    </td>
                    <td *ngFor="let headerValuePair of tradeDetailRow" style="white-space: nowrap;white-space: nowrap;
                    /* background: #021E4A; */
                    color: white;
                    text-align: center;
                    color: #414748;border: 1px solid #C4C8CE;padding: 5px;font-size: 12px;
        font-family: 'Interstate';background-color: white">
                        {{headerValuePair.value}}
                    </td>
                  </tr>
             
           </tbody>
      </table>
     <ng-container>
      <ag-grid-angular 
      class="ag-theme-balham inbox-grid mailbox-grid-unique-class mailbox-grid-tyle context-menu-style-override grid-trade-validaiton grid-trade-detail nlpGrid"
[rowData]="rowData_trade_details" 
[columnDefs]="columnDefs_trade_details_table"
[headerHeight]="'40'" [rowHeight]="'40'"
style="width: 100%;"
[suppressMenuHide]="true" 
(gridReady)="onGridReadyTradeDetail($event)"
[rowSelection]="'multiple'"
(rowSelected)="onRowSelected($event)"
(filterChanged)="onFilterChangedTradeDetail($event)" 
[enableCellTextSelection]="true" [enableRangeSelection]="true"
>
</ag-grid-angular> 
     </ng-container>
       
      
     
     
      
<!--        <div *ngFor="let tradeDetailRow of trade_details_table" class="header-container d-flex">
-->        <!--  columns -->
       <!-- <div *ngFor="let headerValuePair of tradeDetailRow" >
             <div class="header">
                 {{headerValuePair.header}}
             </div>
       </div> -->
    <!--  </div> -->
    </div>
       </as-split-area>
    <as-split-area [size]="50" #asSplitAreaTradeValidation > <!-- C153176-5191: split percentage-->
     <!-- validation data table -->
     <div #tradeDetailsTableValidation id="tradeValidationDetailsTbl" 
      class="tradeDetailsTbl my-scrollbar tradeValidationDetailsTbl tradeDetailsTableValidation" 
     style="overflow: auto;background: #E6E7E9;"   [ngStyle]="tradeDetailsTableValidationstyle">
        <div class="table-header table-header-tradeValidation" style="justify-content: center;height: 30px;display: flex;
        background: #E6E7E9;align-items: center;">
        Trade Validation</div>
     <!-- usefilterchnagedEvent -->
     <!-- restoreFromHardCoded() {
      var hardcodedFilter = {
        country: {
          type: 'set',
          values: ['Ireland', 'United States'],
        },
        age: { type: 'lessThan', filter: '30' },
        athlete: { type: 'startsWith', filter: 'Mich' },
        date: { type: 'lessThan', dateFrom: '2010-01-01' },
      };
      this.gridApi.setFilterModel(hardcodedFilter);
      (filterChanged)="onFilterChangedTradeValidation($event)"
    } -->
      <ag-grid-angular 
            class="ag-theme-balham inbox-grid mailbox-grid-unique-class mailbox-grid-tyle context-menu-style-override grid-trade-validaiton nlpGrid"
      [rowData]="rowData_trade_validation_details_table" 
      [columnDefs]="columnDefs_trade_validation_details_table"
      [headerHeight]="'40'" [rowHeight]="'40'"
      style="width: 100%;"
       [suppressMenuHide]="true" (firstDataRendered)="onFirstDataRenderedTradeValidation($event)"
       (filterChanged)="onFilterChangedTradeValidation($event)" (firstDataRendered)="onFirstDataRenderedTradeValidation($event)"
       (gridReady)="onGridReadyTradeValidation($event)"
       [enableCellTextSelection]="true" [enableRangeSelection]="true">
      </ag-grid-angular>
      
      
<!--        <div *ngFor="let tradeDetailRow of trade_details_table" class="header-container d-flex">
-->        <!--  columns -->
       <!-- <div *ngFor="let headerValuePair of tradeDetailRow" >
             <div class="header">
                 {{headerValuePair.header}}
             </div>
       </div> -->
    <!--  </div> -->
    </div>
  </as-split-area>
  </as-split>
    </div>
    </div>
    </div>
   
    <div class="
    d-flex flex-row justify-content-end" *ngIf="!displayReason && !showFeedbackPanel">
      <!-- <button class="d-flex flex-column button justify-content-center" (click)="updateConvSuggstions('accept')" #acceptConvSuggestionsId>
        <img [src]="addBtn" class="p-1">
        <span #addLabel>ADD</span>
      </button> -->
      <button  class="btn search-btn mr-4 pop-up-attch-button" (click)="onNlpFeedbackClick()" 
      #acceptConvSuggestionsId *ngIf="trade_details_table && trade_details_table.length && trade_details_table.length>0">
        Feedback
      </button>
      <button  class="btn search-btn mr-4 pop-up-attch-button" (click)="updateConvSuggstions('accept')" 
      #acceptConvSuggestionsId *ngIf="(suggetionStatus=='green' || suggetionStatus=='orange')  && trade_details_table && trade_details_table.length && trade_details_table.length>0">
        ADD
      </button>
      <!-- <button class="d-flex flex-column justify-content-center button cancel" (click)="updateConvSuggstions('decline')" #declineConvSuggestionsId>
        <img src="assets/newMessage/add-gray.svg" class="p-1">
        <span style="color:gray">REJECT</span>
      </button> -->
      <button class="btn search-btn mr-4 pop-up-attch-button pop-up-reject-btn" 
      (click)="updateConvSuggstions('decline')" #declineConvSuggestionsId *ngIf="suggetionStatus=='green' && trade_details_table && trade_details_table.length && trade_details_table.length>0">
       REJECT
      </button>
    </div>
    <div class="
    d-flex flex-row justify-content-end" *ngIf="displayReason">
      <!-- <button class="d-flex flex-column button justify-content-center" (click)="updateConvSuggstions('accept')" #acceptConvSuggestionsId>
        <img [src]="addBtn" class="p-1">
        <span #addLabel>ADD</span>
      </button> -->
      <button  class="btn search-btn mr-4 pop-up-attch-button" (click)="updateConvSuggstions('decline')" #acceptConvSuggestionsId>
        SUBMIT
      </button>
      <!-- <button class="d-flex flex-column justify-content-center button cancel" (click)="updateConvSuggstions('decline')" #declineConvSuggestionsId>
        <img src="assets/newMessage/add-gray.svg" class="p-1">
        <span style="color:gray">REJECT</span>
      </button> -->
      <button class="btn search-btn mr-4 pop-up-attch-button pop-up-reject-btn" 
      (click)="closeSuggetionBox()">
       CANCEL
      </button>
    </div>
  </div>
<p-overlayPanel #NlpFeedbackDropdownList>
    Content
</p-overlayPanel>






import * as moment from '../../../../node_modules/moment/moment.js';
import { DragGripCellRenderer } from "./drag-grip-cell-renderer.component";
import { AppUtils } from 'src/app/common/utility/appUtil';

export class MailBoxGridColdefs {

  getMailboxColDefs() {
    let objAppUtils = new AppUtils();
    return [
      
      {
        headerName: '',
        field: 'expander',
        sortable: true,
        suppressMenu: true,
        resizable: false,
        suppressMovable: true,
        hide: false,
        showInSecondary: false,
        suppressSizeToFit: true,
        width: 31,
        cellRenderer: (params) => {
          if (params.node.group) {
              return '';  // Hide content for group rows
          }
          return params.value;  // Show content for non-group rows
      },
        headerCheckboxSelection: true,
        checkboxSelection: (params) => {
          // Only show checkbox for non-group rows
          return !params.node.group;
      }, 
        suppressColumnVirtualisation: true,
        tooltip: (params) => {
          // C153176-4566 | Show Client Category name on hover.
          if (params.node.group) {
            return null;
          } else { // Retrieves the value of the current field
            let category = this.getClientCategory(params);
            return category ? category.categoryName : '';
          }
        },
        cellStyle: (params) => {
          // C153176-4566 | Show Client Category Color Code.
          if (params.node.group) {
            return null;
          } else { // Retrieves the value of the current field
            let category = this.getClientCategory(params);
            if (category) {
              let clntCategoryColorCode = category ? category.colorCode : '';
              return {
                // C153176-4548 | Client category color in inbox behaves abruptly
                borderLeft: '5px solid',
                borderLeftColor: clntCategoryColorCode,
                width: '33px'
              }
            }
            //border which will not visible. To maintain even space for all checkboxes
            else {
              return {
                paddingLeft: '15px',
                width: '35px',
              };
            }
          }
          
        }
      },
      {
        filter: false,
        headerName: '',
        field: 'cheveron',
        sortable: false,
        suppressMenu: true,
        resizable: false,
        suppressMovable: true,
        hide: false,
        showInSecondary: false,
        cellRenderer: DragGripCellRenderer,
        suppressSizeToFit: true,
        width: 20,
        suppressColumnVirtualisation: true,
        
        // DO NOT DELETE will be used when custom category is available
        cellStyle: function (params) {
          return {
            marginLeft: '3px',
            paddingLeft: '3px',
            paddingRight: '0px',
            paddingTop: '2px'
            //        borderLeftColor: '#f1a840',
            //       borderLeftStyle: 'solid'
          }
        }
      },
      {
        filter: false,
        headerName: '',
        field: 'cheveron_1',
        sortable: false,
        suppressMenu: true,
        resizable: false,
        suppressMovable: true,
        hide: false,
        showInSecondary: false,
        // cellRenderer: "agGroupCellRenderer",
        cellRendererSelector: params => {
          return !params.node.group  ? {component:'agGroupCellRenderer'} : {};
      },
        suppressSizeToFit: true,
        suppressColumnVirtualisation: true,
        rowGroup:false,
        width: 20,
        enableRowGroup: false,
        // DO NOT DELETE will be used when custom category is available
        /*
        cellStyle: function (params) {
          return {
            marginLeft: '5px',
            paddingLeft: '5px',
            paddingRight: '5px',
  //        borderLeftColor: '#f1a840',
   //       borderLeftStyle: 'solid'
          }
        }*/
      },
      // icon to differentiate symphony and QMA INQ

      {
        headerName: '',
        field: 'isSymphonyChatGridCol',
        sortable: true,
        suppressMenu: true,
        resizable: true,
        suppressMovable: true,
        hide: false,
        showInSecondary: false,
        suppressSizeToFit: true,
        suppressColumnVirtualisation: true,
        width: 20,
        cellRenderer: function (params) {
          
          // C170665-103 Email Icon is cropped in intensity heatmap graphs
          if (params.data.isSymphonyChatroom && params.data.isSymphonyChatroom === "Y") {
            return "<span class='qma-inbox-notes'><img class='mailbox-grid-symphony-icon' src='assets/symphony/logo-symphony-clear-18.png'></span>";
          } else if (params.data.isTaskizeInquiry && params.data.isTaskizeInquiry === "Y") {
            return "<span class='qma-inbox-notes'><img class='mailbox-grid-symphony-icon' src='assets/Inbox/taskize-clear-logo-18.png'></span>";
          } else if (params.context && params.context.componentParent &&
            (params.context.componentParent.strMailboxView === "INBOX" || params.context.componentParent.strMailboxView === "SEARCH" || params.context.componentParent.strMailboxView === "TASKIZE")
            && (params.context.componentParent.symphonyEnabled || params.context.componentParent.taskizeEnabled)
          ) {
            return "<span class='qma-inbox-notes'><img class='mailbox-grid-mail-icon' src='assets/symphony/messages-icon-18.png'></span>";
          } else {
            if (params && params.context && params.context.componentParent) {
              params.context.componentParent.gridColumnApi.setColumnVisible('isSymphonyChatGridCol', false)
            }
            else {
              return "";
            }
          }
        }
      },
      {
        headerName: 'FROM',
        field: 'latestUserName',
        sortable: true,
        comparator: (valueA, valueB) => valueA.localeCompare(valueB),
        cellRenderer: "",
        /*  suppressMenu: true, */
        // headerClass:"mailbox-from-header",
        resizable: true,
        suppressMovable: true,
        hide: false,
        showInSecondary: false,
        suppressColumnVirtualisation: true,
        tooltipField: 'latestUserName',
        suppressSizeToFit: true,                    /* added in all column due to distored issue C153176-4904 */
        /* width: 130 */
        //DO NOT DELETE will be used when custom category is available
        /*  cellStyle: function (params) {
           return {
             borderLeft: '5px',
             borderLeftColor: '#f1a840',
             borderLeftStyle: 'solid'
           }

         } */
        // fix for C153176-4370 Observation- Limitations with filter not being provided at column level
        filter: 'agTextColumnFilter',
        menuTabs: ["filterMenuTab"],
        filterParams: {
          caseSensitive: true,
          defaultOption: "contains",
        },
      },

      {
        "headerName": "SUBJECT",
        "field": "subject",
        suppressMenu: false,
        resizable: true,
        suppressMovable: true,
        hide: false,
        cellRenderer: "",
        showInSecondary: false,
        suppressColumnVirtualisation: true,
        tooltipField: 'subject',
        suppressSizeToFit: true, /* added in all column due to distored issue C153176-4904 */
        /* width: 137 */
        // fix for C153176-4370 Observation- Limitations with filter not being provided at column level
        filter: 'agTextColumnFilter',
        menuTabs: ["filterMenuTab"],
        filterParams: {
          caseSensitive: true,
          defaultOption: "contains",
        },

      },
      {
        "headerName": "ASSIGNED TO",
        "field": "openUsers",
        headerClass: 'multiline',
        comparator: (valueA, valueB) => valueA.localeCompare(valueB),
        suppressMenu: false,
        resizable: true,
        suppressMovable: true,
        hide: false,
        cellRenderer: "",
        showInSecondary: false,
        suppressColumnVirtualisation: true,
        tooltipField: 'openUsers',
        suppressSizeToFit: true,    /* added in all column due to distored issue C153176-4904 */
        /* width: 137 */
        // fix for C153176-4370 Observation- Limitations with filter not being provided at column level
        filter: 'agTextColumnFilter',
        menuTabs: ["filterMenuTab"],
        filterParams: {
          caseSensitive: true,
          defaultOption: "contains",
        },
      },
      {
        "headerName": "ASSIGNED FROM",
        "field": "origUserName",
        headerClass: 'multiline',
        comparator: (valueA, valueB) => valueA.localeCompare(valueB),

        suppressMenu: false,
        resizable: true,
        suppressMovable: true,
        hide: false,
        cellRenderer: "",
        showInSecondary: false,
        suppressColumnVirtualisation: true,
        tooltipField: 'origUserName',
        suppressSizeToFit: true, /* added in all column due to distored issue C153176-4904 */
        /* width: 137 */
        // fix for C153176-4370 Observation- Limitations with filter not being provided at column level
        filter: 'agTextColumnFilter',
        menuTabs: ["filterMenuTab"],
        filterParams: {
          caseSensitive: true,
          defaultOption: "contains",
        },
      },
      {
        "headerName": "OWNERSHIP",
        "field": "hasOwnership",
        headerClass: 'multiline',
        // "width": 100,
        // sortable: true,
        suppressMenu: false,
        resizable: true,
        suppressMovable: true,
        hide: true,
        cellRenderer: "",
        tooltipField: 'hasOwnership',
        showInSecondary: true,
        suppressSizeToFit: true,
        suppressColumnVirtualisation: true,
        width: 100,
        // fix for C153176-4370 Observation- Limitations with filter not being provided at column level
        filter: 'agTextColumnFilter',
        menuTabs: ["filterMenuTab"],
        filterParams: {
          caseSensitive: true,
          defaultOption: "contains",
        },
      },
      {
        "headerName": "REQUEST TYPE",
        "field": "requestTypeStr",
        hide: true,
        suppressMenu: false,
        resizable: true,
        suppressMovable: true,
        cellRenderer: "",
        showInSecondary: true,
        suppressSizeToFit: true,
        suppressColumnVirtualisation: true,
        width: 120,
        valueGetter: (params) => params.data.requestTypeStr ? params.data.requestTypeStr : '',
        // fix for C153176-4370 Observation- Limitations with filter not being provided at column level
        filter: 'agTextColumnFilter',
        menuTabs: ["filterMenuTab"],
        filterParams: {
          caseSensitive: true,
          defaultOption: "contains",
        },
      },
      {
        "headerName": "PROCESSING REGION",
        "field": "processingRegion",
        hide: true,
        suppressMenu: false,
        resizable: true,
        suppressMovable: true,
        cellRenderer: "",
        suppressColumnVirtualisation: true,
        showInSecondary: true,
        suppressSizeToFit: true, /* added in all column due to distored issue C153176-4904 */
        // fix for C153176-4370 Observation- Limitations with filter not being provided at column level
        filter: 'agTextColumnFilter',
        menuTabs: ["filterMenuTab"],
        filterParams: {
          caseSensitive: true,
          defaultOption: "contains",
        },
      },
      {
        "headerName": "LOCK",
        "field": "lock",
        hide: true,
        suppressMenu: false,
        resizable: true,
        suppressMovable: true,
        cellRenderer: "",
        showInSecondary: true,
        suppressSizeToFit: true,
        suppressColumnVirtualisation: true,
        width: 100,
        // fix for C153176-4370 Observation- Limitations with filter not being provided at column level
        filter: 'agTextColumnFilter',
        menuTabs: ["filterMenuTab"],
        filterParams: {
          caseSensitive: true,
          defaultOption: "contains",
        },

      },
      {
        "headerName": "ESCALATION",
        "field": "isEscalated",
        hide: true,
        suppressMenu: false,
        resizable: true,
        suppressMovable: true,
        cellRenderer: "",
        showInSecondary: true,
        tooltipField: 'escalationReason',
        suppressSizeToFit: true,
        suppressColumnVirtualisation: true,
        width: 100,
        // fix for C153176-4370 Observation- Limitations with filter not being provided at column level
        filter: 'agTextColumnFilter',
        menuTabs: ["filterMenuTab"],
        filterParams: {
          caseSensitive: true,
          defaultOption: "contains",
        },

      },
      {
        "headerName": "ID",
        "field": "_id",
        hide: true,
        suppressMenu: false,
        resizable: true,
        suppressMovable: true,
        cellRenderer: "",
        showInSecondary: true,
        suppressSizeToFit: true,
        suppressColumnVirtualisation: true,
        width: 100,
        // fix for C153176-4370 Observation- Limitations with filter not being provided at column level
        filter: 'agTextColumnFilter',
        menuTabs: ["filterMenuTab"],
        filterParams: {
          caseSensitive: true,
          defaultOption: "contains",
        },

      },
      {
        "headerName": "LAST ACTION",
        "field": "action",
        hide: true,
        suppressMenu: false,
        resizable: true,
        suppressMovable: true,
        cellRenderer: "",
        suppressColumnVirtualisation: true,
        showInSecondary: true,
        suppressSizeToFit: true /* added in all column due to distored issue C153176-4904 */,
        // fix for C153176-4370 Observation- Limitations with filter not being provided at column level
        filter: 'agTextColumnFilter',
        menuTabs: ["filterMenuTab"],
        filterParams: {
          caseSensitive: true,
          defaultOption: "contains",
        },

      },

      {
        "headerName": "ASSIGNED GROUP",
        "field": "openGroups",
        hide: true,
        suppressMenu: false,
        resizable: true,
        suppressMovable: true,
        cellRenderer: "",
        showInSecondary: true,
        suppressColumnVirtualisation: true,
        tooltipField: 'openGroups',
        suppressSizeToFit: true /* added in all column due to distored issue C153176-4904 */,
        // fix for C153176-4370 Observation- Limitations with filter not being provided at column level
        filter: 'agTextColumnFilter',
        menuTabs: ["filterMenuTab"],
        filterParams: {
          caseSensitive: true,
          defaultOption: "contains",
        },

      },
      {
        "headerName": "ORIGINATING GROUP",
        "field": "origGroupName",
        hide: true,
        suppressMenu: false,
        resizable: true,
        suppressMovable: true,
        cellRenderer: "",
        showInSecondary: true,
        suppressColumnVirtualisation: true,
        tooltipField: 'origGroupName',
        suppressSizeToFit: true /* added in all column due to distored issue C153176-4904 */,
        // fix for C153176-4370 Observation- Limitations with filter not being provided at column level
        filter: 'agTextColumnFilter',
        menuTabs: ["filterMenuTab"],
        filterParams: {
          caseSensitive: true,
          defaultOption: "contains",
        },

      },
      {
        "headerName": "CREATED DATE",
        "field": "crtDate.$date",
        hide: true,
        suppressMenu: false,
        resizable: true,
        suppressMovable: true,
        showInSecondary: true,
        suppressColumnVirtualisation: true,
        suppressSizeToFit: true, /* added in all column due to distored issue C153176-4904 */
        cellRenderer: (data) => {
          // console.log(data.value);
          return objAppUtils.convertDate(data.value)
        },
        tooltipValueGetter: (data) => {
          return objAppUtils.convertDate(data.value)
        },
        // fix for C153176-4370 Observation- Limitations with filter not being provided at column level
        filter: 'agDateColumnFilter',
        menuTabs: ["filterMenuTab"],
        filterParams: this.getFilterParamsDate()

      },
      {
        "headerName": "CLIENT INTENT",
        "field": "intentSuggestionName",
        hide: true,
        suppressMenu: false,
        resizable: true,
        suppressMovable: true,
        suppressSizeToFit: true,
        suppressColumnVirtualisation: true,
        tooltipField: 'intentSuggestionName',
        filter: 'agTextColumnFilter',
        menuTabs: ["filterMenuTab"],
        filterParams: {
          caseSensitive: true,
          defaultOption: "contains",
        },
        cellRenderer: function (params) {
          if (params && params.value) {
            return "<div class='qma-inbox-age'>" + params.value + "</div>"
          } else {
            return "<div class='qma-inbox-age'>" + "" + "</div>"

          }
        },
        cellStyle: (params) => {
          return {
            "background-color": params.data.intentSuggestionIndicator,
            "textTransform": 'capitalize'
          }
        }
      },
      {
        "headerName": "TIME TO VD",
        "field": "intentTimeToVD",
        hide: true,
        suppressMenu: false,
        resizable: true,
        suppressMovable: true,
        showInSecondary: true,
        suppressColumnVirtualisation: true,
        suppressSizeToFit: true,
        filter: 'agNumberColumnFilter',
        menuTabs: ["filterMenuTab"],
        filterParams: {
          caseSensitive: true,
          defaultOption: "equals",
        },
        cellRenderer: function (params) {
          if (params && params.value) {
            return "<div class='qma-inbox-age'>" + params.value + " days</div>";
          } else {
            return "<div class='qma-inbox-age'>" + "" + "</div>";
          }
        },
        cellStyle: (params) => {
          if (params.value != "" && params.value == 0) {
            return {
              "background-color": "#FEE3AC"
            }
          }
          if (params.value > 0 && params.value < 3) {
            return {
              "background-color": "#CFF379"
            }
          }
          if (params.value > 2 && params.value < 11) {
            return {
              "background-color": "#52E88B"
            }
          }
          if (params.value > 10 && params.value < 31) {
            return {
              "background-color": "#8DE395"
            }
          }
          if (params.value > 30) {
            return {
              "background-color": "#6AD47E"
            }
          }
          if (params.value < 0 && params.value > -3) {
            return {
              "background-color": "#FFD8D4"
            }
          }
          if (params.value < -2 && params.value > -11) {
            return {
              "background-color": "#FFB1A9"
            }
          }
          if (params.value < -10 && params.value > -31) {
            return {
              "background-color": "#EB9485"
            }
          }
          if (params.value < -30) {
            return {
              "background-color": "#F36767"
            }
          }
        }
      },
      {
        "headerName": "INQUIRY STATUS",
        "field": "status",
        hide: true,
        suppressMenu: false,
        resizable: true,
        suppressMovable: true,
        cellRenderer: "",
        suppressColumnVirtualisation: true,
        showInSecondary: true,
        suppressSizeToFit: true       /* added in all column due to distored issue C153176-4904 */,
        // fix for C153176-4370 Observation- Limitations with filter not being provided at column level
        filter: 'agTextColumnFilter',
        menuTabs: ["filterMenuTab"],
        filterParams: {
          caseSensitive: true,
          defaultOption: "contains",
        },
      },

      {
        "headerName": "TAG",
        "field": "tag",
        hide: true,
        suppressMenu: false,
        resizable: true,
        suppressMovable: true,
        cellRenderer: "",
        suppressColumnVirtualisation: true,
        showInSecondary: true,
        suppressSizeToFit: true  /* added in all column due to distored issue C153176-4904 */,
        // fix for C153176-4370 Observation- Limitations with filter not being provided at column level
        filter: 'agTextColumnFilter',
        menuTabs: ["filterMenuTab"],
        filterParams: {
          caseSensitive: true,
          defaultOption: "contains",
        },
      },
      {
        "headerName": "RE-OPEN AGE(DAYS)",
        "field": "reOpenAge",
        hide: true,
        suppressMenu: false,
        resizable: true,
        suppressMovable: true,
        cellRenderer: "",
        suppressColumnVirtualisation: true,
        showInSecondary: true,
        suppressSizeToFit: true  /* added in all column due to distored issue C153176-4904 */

      },
      {
        "headerName": "RE-OPEN DATE",
        "field": "reOpenDate.$date",
        hide: true,
        suppressMenu: false,
        resizable: true,
        suppressMovable: true,
        // cellRenderer: "",
        showInSecondary: true,
        suppressSizeToFit: true,  /* added in all column due to distored issue C153176-4904 */
        suppressColumnVirtualisation: true,
        cellRenderer: (data) => {
          return objAppUtils.convertDate(data.value)
        },
        tooltipValueGetter: (data) => {
          return objAppUtils.convertDate(data.value)
        },
        // fix for C153176-4370 Observation- Limitations with filter not being provided at column level
        filter: 'agDateColumnFilter',
        menuTabs: ["filterMenuTab"],
        filterParams: this.getFilterParamsDate()

      },
      {
        "headerName": "LINK ID",
        "field": "linkId",
        hide: true,
        suppressMenu: false,
        resizable: true,
        suppressMovable: true,
        cellRenderer: "",
        suppressColumnVirtualisation: true,
        showInSecondary: true,
        suppressSizeToFit: true  /* added in all column due to distored issue C153176-4904 */,
        // fix for C153176-4370 Observation- Limitations with filter not being provided at column level
        filter: 'agTextColumnFilter',
        menuTabs: ["filterMenuTab"],
        filterParams: {
          caseSensitive: true,
          defaultOption: "contains",
        },

      },
      {
        "headerName": "LAST ACTION BY",
        "field": "lastActionby",
        hide: true,
        suppressMenu: false,
        resizable: true,
        suppressMovable: true,
        cellRenderer: "",
        suppressColumnVirtualisation: true,
        showInSecondary: true,
        suppressSizeToFit: true  /* added in all column due to distored issue C153176-4904 */,
        // fix for C153176-4370 Observation- Limitations with filter not being provided at column level
        filter: 'agTextColumnFilter',
        menuTabs: ["filterMenuTab"],
        filterParams: {
          caseSensitive: true,
          defaultOption: "contains",
        },

      },
      {
        "headerName": "LAST ACTION TIME",
        "field": "lastActionTime.$date",
        hide: true,
        suppressMenu: false,
        resizable: true,
        suppressMovable: true,
        suppressColumnVirtualisation: true,
        suppressSizeToFit: true,  /* added in all column due to distored issue C153176-4904 */
        //cellRenderer: "",
        showInSecondary: true,
        cellRenderer: (data) => {
          return objAppUtils.convertDate(data.value)
        },
        tooltipValueGetter: (data) => {
          return objAppUtils.convertDate(data.value)
        },
        // fix for C153176-4370 Observation- Limitations with filter not being provided at column level
        filter: 'agDateColumnFilter',
        menuTabs: ["filterMenuTab"],
        filterParams: this.getFilterParamsDate()
      },
      {
        "headerName": "RESOLVER",
        "field": "resolver",
        hide: true,
        suppressMenu: false,
        resizable: true,
        suppressMovable: true,
        cellRenderer: "",
        suppressColumnVirtualisation: true,
        showInSecondary: true,
        suppressSizeToFit: true  /* added in all column due to distored issue C153176-4904 */,
        // fix for C153176-4370 Observation- Limitations with filter not being provided at column level
        filter: 'agTextColumnFilter',
        menuTabs: ["filterMenuTab"],
        filterParams: {
          caseSensitive: true,
          defaultOption: "contains",
        },

      },
      {
        "headerName": "RESOLVE TIME",
        "field": "resolveTime.$date",
        hide: true,
        suppressMenu: false,
        resizable: true,
        suppressMovable: true,
        showInSecondary: true,
        suppressColumnVirtualisation: true,
        suppressSizeToFit: true,  /* added in all column due to distored issue C153176-4904 */
        cellRenderer: (data) => {
          //C153176-5065 | Resolve time should be shown correctly
          return objAppUtils.convertDate(data.value);
        },
        tooltipValueGetter: (data) => {
          return objAppUtils.convertDate(data.value);
        },
        // fix for C153176-4370 Observation- Limitations with filter not being provided at column level
        filter: 'agDateColumnFilter',
        menuTabs: ["filterMenuTab"],
        filterParams: this.getFilterParamsDate()
      },
      {
        "headerName": "SENDING ENTITY",
        "field": "clientName",
        hide: true,
        suppressMenu: false,
        resizable: true,
        suppressMovable: true,
        cellRenderer: "",
        suppressColumnVirtualisation: true,
        showInSecondary: true,
        suppressSizeToFit: true  /* added in all column due to distored issue C153176-4904 */,
        // fix for C153176-4370 Observation- Limitations with filter not being provided at column level
        filter: 'agTextColumnFilter',
        menuTabs: ["filterMenuTab"],
        filterParams: {
          caseSensitive: true,
          defaultOption: "contains",
        },

      },
      {
        "headerName": "GFPID",
        "field": "gpNum",
        hide: true,
        suppressMenu: false,
        resizable: true,
        suppressMovable: true,
        cellRenderer: "",
        showInSecondary: true,
        suppressColumnVirtualisation: true,
        tooltipField: 'gpNum',
        suppressSizeToFit: true   /* added in all column due to distored issue C153176-4904 */,
        // fix for C153176-4370 Observation- Limitations with filter not being provided at column level
        filter: 'agTextColumnFilter',
        menuTabs: ["filterMenuTab"],
        filterParams: {
          caseSensitive: true,
          defaultOption: "contains",
        },

      },
      {
        "headerName": "GFPID NAME",
        "field": "gpName",
        hide: true,
        suppressMenu: false,
        resizable: true,
        suppressMovable: true,
        cellRenderer: "",
        showInSecondary: true,
        tooltipField: 'gpName',
        suppressColumnVirtualisation: true,
        suppressSizeToFit: true   /* added in all column due to distored issue C153176-4904 */,
        // fix for C153176-4370 Observation- Limitations with filter not being provided at column level
        filter: 'agTextColumnFilter',
        menuTabs: ["filterMenuTab"],
        filterParams: {
          caseSensitive: true,
          defaultOption: "contains",
        },

      },
      {
        "headerName": "GFCID",
        "field": "gfcid",
        hide: true,
        suppressMenu: false,
        resizable: true,
        suppressMovable: true,
        cellRenderer: "",
        showInSecondary: true,
        suppressColumnVirtualisation: true,
        suppressSizeToFit: true   /* added in all column due to distored issue C153176-4904 */,
        // fix for C153176-4370 Observation- Limitations with filter not being provided at column level
        filter: 'agTextColumnFilter',
        menuTabs: ["filterMenuTab"],
        filterParams: {
          caseSensitive: true,
          defaultOption: "contains",
        },

      },
      {
        "headerName": "GFCID NAME",
        "field": "gfcName",
        hide: true,
        suppressMenu: false,
        resizable: true,
        suppressMovable: true,
        cellRenderer: "",
        showInSecondary: true,
        suppressColumnVirtualisation: true,
        tooltipField: 'gfcName',
        suppressSizeToFit: true  /* added in all column due to distored issue C153176-4904 */,
        // fix for C153176-4370 Observation- Limitations with filter not being provided at column level
        filter: 'agTextColumnFilter',
        menuTabs: ["filterMenuTab"],
        filterParams: {
          caseSensitive: true,
          defaultOption: "contains",
        },

      },
      {
        "headerName": "ROOT CAUSE",
        "field": "rootCause",
        hide: true,
        suppressMenu: false,
        resizable: true,
        suppressMovable: true,
        cellRenderer: "",
        suppressColumnVirtualisation: true,
        showInSecondary: true,
        suppressSizeToFit: true /* added in all column due to distored issue C153176-4904 */,
        // fix for C153176-4370 Observation- Limitations with filter not being provided at column level
        filter: 'agTextColumnFilter',
        menuTabs: ["filterMenuTab"],
        filterParams: {
          caseSensitive: true,
          defaultOption: "contains",
        },

      },
      {
        "headerName": "RESPONSE TIME ",
        "field": "responseTimeQMA",
        hide: true,
        suppressMenu: false,
        resizable: true,
        suppressMovable: true,
        cellRenderer: "",
        suppressColumnVirtualisation: true,
        showInSecondary: true,
        suppressSizeToFit: true /* added in all column due to distored issue C153176-4904 */,
        // fix for C153176-4370 Observation- Limitations with filter not being provided at column level
        filter: 'agTextColumnFilter',
        menuTabs: ["filterMenuTab"],
        filterParams: {
          caseSensitive: true,
          defaultOption: "contains",
        },
      },
      {
        "headerName": "QUERY COUNT",
        "field": "queryCount",
        hide: true,
        suppressMenu: false,
        resizable: true,
        suppressMovable: true,
        cellRenderer: "",
        suppressColumnVirtualisation: true,
        showInSecondary: true,
        suppressSizeToFit: true /* added in all column due to distored issue C153176-4904 */,
        // fix for C153176-4370 Observation- Limitations with filter not being provided at column level
        filter: 'agTextColumnFilter',
        menuTabs: ["filterMenuTab"],
        filterParams: {
          caseSensitive: true,
          defaultOption: "contains",
        },

      },
      {
        "headerName": "AVG. RESPONSE TIME",
        "field": "avgResponseTimeQMA",
        hide: true,
        suppressMenu: false,
        resizable: true,
        suppressMovable: true,
        cellRenderer: "",
        suppressColumnVirtualisation: true,
        showInSecondary: true,
        suppressSizeToFit: true  /* added in all column due to distored issue C153176-4904 */,
        // fix for C153176-4370 Observation- Limitations with filter not being provided at column level
        filter: 'agTextColumnFilter',
        menuTabs: ["filterMenuTab"],
        filterParams: {
          caseSensitive: true,
          defaultOption: "contains",
        },

      },
      {
        "headerName": "INQUIRY SOURCE",
        "field": "inquirySource",
        hide: true,
        suppressMenu: false,
        resizable: true,
        suppressMovable: true,
        cellRenderer: "",
        suppressColumnVirtualisation: true,
        showInSecondary: true,
        suppressSizeToFit: true  /* added in all column due to distored issue C153176-4904 */,
        // fix for C153176-4370 Observation- Limitations with filter not being provided at column level
        filter: 'agTextColumnFilter',
        menuTabs: ["filterMenuTab"],
        filterParams: {
          caseSensitive: true,
          defaultOption: "contains",
        },

      },
      {
        "headerName": "INQUIRY SUB-STATUS",
        "field": "inquirySubStatus",
        hide: false,
        suppressMenu: false,
        resizable: true,
        suppressMovable: true,
        cellRenderer: "",
        suppressColumnVirtualisation: true,
        showInSecondary: false,
        suppressSizeToFit: true  /* added in all column due to distored issue C153176-4904 */,
        // fix for C153176-4370 Observation- Limitations with filter not being provided at column level
        filter: 'agTextColumnFilter',
        menuTabs: ["filterMenuTab"],
        filterParams: {
          caseSensitive: true,
          defaultOption: "contains",
        },

      },
      {
        "headerName": "EXCEPTION ID",
        "field": "exceptionId",
        hide: true,
        suppressMenu: false,
        resizable: true,
        suppressMovable: true,
        suppressColumnVirtualisation: true,
        cellRenderer: "",
        showInSecondary: true,
        suppressSizeToFit: true    /* added in all column due to distored issue C153176-4904 */,
        // fix for C153176-4370 Observation- Limitations with filter not being provided at column level
        filter: 'agTextColumnFilter',
        menuTabs: ["filterMenuTab"],
        filterParams: {
          caseSensitive: true,
          defaultOption: "contains",
        },

      },
      {
        "headerName": "CLIENT CHASE COUNT",
        "field": "clientChaseCounter",
        hide: true,
        suppressMenu: false,
        resizable: true,
        suppressMovable: true,
        suppressColumnVirtualisation: true,
        cellRenderer: "",
        showInSecondary: true,
        suppressSizeToFit: true   /* added in all column due to distored issue C153176-4904 */,
        // fix for C153176-4370 Observation- Limitations with filter not being provided at column level
        filter: 'agTextColumnFilter',
        menuTabs: ["filterMenuTab"],
        filterParams: {
          caseSensitive: true,
          defaultOption: "contains",
        },

      },
      {
        "headerName": "ORIGINATOR TYPE",
        "field": "origType",
        hide: true,
        suppressMenu: false,
        resizable: true,
        suppressMovable: true,
        suppressColumnVirtualisation: true,
        cellRenderer: "",
        showInSecondary: true,
        suppressSizeToFit: true  /* added in all column due to distored issue C153176-4904 */,
        // fix for C153176-4370 Observation- Limitations with filter not being provided at column level
        filter: 'agTextColumnFilter',
        menuTabs: ["filterMenuTab"],
        filterParams: {
          caseSensitive: true,
          defaultOption: "contains",
        },

      },
      {
        "headerName": "ORIGINATOR EMAIL",
        "field": "origEmail",
        hide: true,
        suppressMenu: false,
        resizable: true,
        suppressMovable: true,
        cellRenderer: "",
        suppressColumnVirtualisation: true,
        showInSecondary: true,
        tooltipField: 'origEmail',
        suppressSizeToFit: true        /* added in all column due to distored issue C153176-4904 */,
        // fix for C153176-4370 Observation- Limitations with filter not being provided at column level
        filter: 'agTextColumnFilter',
        menuTabs: ["filterMenuTab"],
        filterParams: {
          caseSensitive: true,
          defaultOption: "contains",
        },

      },
      {
        "headerName": "WORKFLOW STATUS",
        "field": "workflowStatus",
        hide: true,
        suppressMenu: false,
        resizable: true,
        suppressMovable: true,
        cellRenderer: "",
        showInSecondary: true,
        suppressColumnVirtualisation: true,
        tooltipField: 'workflowStatus',
        suppressSizeToFit: true     /* added in all column due to distored issue C153176-4904 */,
        // fix for C153176-4370 Observation- Limitations with filter not being provided at column level
        filter: 'agTextColumnFilter',
        menuTabs: ["filterMenuTab"],
        filterParams: {
          caseSensitive: true,
          defaultOption: "contains",
        },

      },
      {
        "headerName": "REAGE DATE",
        "field": "reAgeDate.$date",
        hide: true,
        suppressMenu: false,
        resizable: true,
        suppressMovable: true,
        //cellRenderer: "",
        showInSecondary: true,
        suppressColumnVirtualisation: true,
        suppressSizeToFit: true,
        cellRenderer: (data) => {
          return objAppUtils.convertDate(data.value)
        },
        tooltipValueGetter: (data) => {
          return objAppUtils.convertDate(data.value)
        },
        // fix for C153176-4370 Observation- Limitations with filter not being provided at column level
        filter: 'agDateColumnFilter',
        menuTabs: ["filterMenuTab"],
        filterParams: this.getFilterParamsDate()

      },
      {
        "headerName": "ESCALATION REASON",
        "field": "escalationReason",
        hide: true,
        suppressMenu: false,
        resizable: true,
        suppressMovable: true,
        cellRenderer: "",
        suppressColumnVirtualisation: true,
        showInSecondary: true,
        tooltipField: "escalationReason",
        suppressSizeToFit: true    /* added in all column due to distored issue C153176-4904 */,
        // fix for C153176-4370 Observation- Limitations with filter not being provided at column level
        filter: 'agTextColumnFilter',
        menuTabs: ["filterMenuTab"],
        filterParams: {
          caseSensitive: true,
          defaultOption: "contains",
        },

      },

      {
        "headerName": "AGE(HRS)",
        "field": "ageInHrs",
        hide: true,
        suppressMenu: false,
        resizable: true,
        suppressMovable: true,
        cellRenderer: "",
        showInSecondary: true,
        suppressColumnVirtualisation: true,
        // filter: "agNumberColumnFilter",
        // fix for C153176-4370 Observation- Limitations with filter not being provided at column level
        suppressSizeToFit: true     /* added in all column due to distored issue C153176-4904 */,
        filter: 'agNumberColumnFilter',
        menuTabs: ["filterMenuTab"],
        filterParams: this.getFilterParamsNumber()

      },
      {
        "headerName": "RE-OPEN AGE(HRS)",
        "field": "reOpenAgeInHrs",
        hide: true,
        suppressMenu: false,
        resizable: true,
        suppressMovable: true,
        cellRenderer: "",
        suppressColumnVirtualisation: true,
        showInSecondary: true,
        suppressSizeToFit: true  /* added in all column due to distored issue C153176-4904 */,
        filter: 'agTextColumnFilter',
        menuTabs: ["filterMenuTab"],
        filterParams: {
          caseSensitive: true,
          defaultOption: "contains",
        },

      },
      {
        headerName: "RECEIVED",
        field: "modDate.$date",
        hide: false,
        suppressMenu: false,
        resizable: true,
        suppressMovable: true,
        /*  width: 137, */
        showInSecondary: false,
        suppressSizeToFit: true,  /* added in all column due to distored issue C153176-4904 */
        width: 120,
        suppressColumnVirtualisation: true,
        // fix for C153176-4370 Observation- Limitations with filter not being provided at column level
        filter: 'agDateColumnFilter',
        menuTabs: ["filterMenuTab"],
        cellRenderer: function (params)  {
          if (params.node.group) {
            return null;
          } else { // Retrieves the value of the current field
             return objAppUtils.convertDate(params.getValue())
          }
        },
        tooltipValueGetter: (params) => {
          if (params.node.group) {
            return null;
          } else { // Retrieves the value of the current field
            if (typeof params.data.modDate === 'object') {
              return objAppUtils.convertDate(params.data.modDate.$date)
            } else {
              return params.data.modDate;
            }
          }
        },
        filterParams: this.getFilterParamsDate()

      },
      {
        "headerName": "AGE", // C153176-5191 AGE(DAYS) -> AGE, AGE(HRS) stays unchanged
        "field": "age",
        hide: false,
        suppressMenu: false,
        resizable: true,
        suppressMovable: true,
        showInSecondary: false,
        suppressSizeToFit: true,  /* added in all column due to distored issue C153176-4904 */
        suppressColumnVirtualisation: true,
        width: 100,
        filter: "agNumberColumnFilter",
        menuTabs: ["filterMenuTab"],
        filterParams: {
          caseSensitive: true,
          defaultOption: "equals",
        },
        cellRenderer: function (params) {
          if (params.value > 0 && params.value == 1) {
            return "<div class='qma-inbox-age qma-inbox-age-0-1'> +" + params.value + " days</div>";

          }
          if (params.value == 2) {
            return "<div class='qma-inbox-age qma-inbox-age-1-2'> +" + params.value + " days</div>";

          } if (params.value > 2 && params.value < 6) {
            return "<div class='qma-inbox-age qma-inbox-age-3-5'> +" + params.value + " days</div>";

          }
          if (params.value > 5 && params.value < 11) {
            return "<div class='qma-inbox-age qma-inbox-age-6-10'> +" + params.value + " days</div>";

          }
          if (params.value > 10 && params.value < 31) {
            return "<div class='qma-inbox-age qma-inbox-age-11-30'> +" + params.value + " days</div>";

          }
          if (params.value > 31) {
            return "<div class='qma-inbox-age qma-inbox-age-gt-30'> +" + params.value + " days</div>";

          } if (params.value != "" && params.value == 0) {
            return "<div class='qma-inbox-age qma-inbox-age-0-1'> +" + params.value + " days</div>"
          }

        }

      },
      {
        "headerName": "READBY",
        "field": "readBy",
        hide: true,
        showInSecondary: true,
        tooltipField: "readBy",
        filter: "agTextColumnFilter",
        suppressMenu: false,
        resizable: true,
        suppressMovable: true,
        suppressColumnVirtualisation: true,
        cellRenderer: "",
        suppressSizeToFit: true, /* added in all column due to distored issue C153176-4904 */
        menuTabs: ["filterMenuTab"],
        filterParams: {
          caseSensitive: true,
          defaultOption: "contains",
        },
      },
      {
        "headerName": "CONVERSATION COUNT",
        "field": "convCount",
        hide: false,
        showInSecondary: false,
        suppressMenu: false,
        suppressColumnVirtualisation: true,
        // sortable: true,
        resizable: true,
        filter: "agNumberColumnFilter",
        suppressSizeToFit: true,  /* added in all column due to distored issue C153176-4904 */
        menuTabs: ["filterMenuTab"],
        filterParams: {
          caseSensitive: true,
          defaultOption: "equals",
        },
      },
      {
        "headerName": "CLIENT CATEGORY",
        "field": "clntCstmCategory",
        hide: true,
        showInSecondary: false,
        suppressMenu: false,
        suppressColumnVirtualisation: true,
        // sortable: true,
        resizable: true,
        suppressSizeToFit: true,  /* added in all column due to distored issue C153176-4904 */
        menuTabs: ["filterMenuTab"],
        filter: "agTextColumnFilter",
        filterParams: this.getFilterParamsNumber()
      },
      {
        headerName: 'Category', // category column
        field: 'category',
        sortable: true,
        resizable: true,
        suppressMovable: true,
        hide: true,
        showInSecondary: false,
        suppressColumnVirtualisation: true,
        suppressSizeToFit: true,
        filter: 'agTextColumnFilter',
        menuTabs: ["filterMenuTab"]
      },
      {
        headerName: 'Attachement',
        field: 'attchFlag',
        sortable: true,
        resizable: true,
        suppressMovable: true,
        hide: true,
        showInSecondary: false,
        suppressColumnVirtualisation: true,
        suppressSizeToFit: true,
        filter: 'agTextColumnFilter',
        menuTabs: ["filterMenuTab"]
      },
      {
        "headerName": "TIME IN QUEUE",
        "field": "timeInQueue",
        hide: true,
        showInSecondary: false,
        suppressMenu: false,
        suppressColumnVirtualisation: true,
        sortable: true,
        resizable: true,
        suppressSizeToFit: true,  /* added in all column due to distored issue C153176-4904 */
        menuTabs: ["filterMenuTab"],
        filter: "agTextColumnFilter",
        filterParams: {
          caseSensitive: true,
          defaultOption: "equals",
        },
        // [C170665-1456] Time in Queue sorting issue
        comparator: (valueA, valueB) => {
          const objA = this.createKeyValueObj(valueA);
          const objB = this.createKeyValueObj(valueB);
          let objAMinutes = 0;
          let objBMinutes = 0;
          if (objA['days']) {
            objAMinutes = objA['days'] * 1440;
          }
          if (objA['hours']) {
            objAMinutes = objAMinutes + (objA['hours'] * 60);
          }
          if (objA['mins']) {
            objAMinutes = objAMinutes + (objA['mins']);
          }
          if (objB['days']) {
            objBMinutes = objB['days'] * 1440;
          }
          if (objB['hours']) {
            objBMinutes = objBMinutes + (objB['hours'] * 60);
          }
          if (objB['mins']) {
            objBMinutes = objBMinutes + (objB['mins']);
          }
          return objAMinutes - objBMinutes;
        }
      },
      {
        "headerName": "REPLY COUNT", /* C170665-85 |  Query Count equivalent to CitiReplyCountFromQMA*/
        "field": "citiReplyCountFromQMA",
        hide: true,
        showInSecondary: false,
        suppressMenu: false,
        suppressColumnVirtualisation: true,
        // sortable: true,
        resizable: true,
        suppressSizeToFit: true,
        menuTabs: ["filterMenuTab"],
        filter: "agTextColumnFilter",
        filterParams: {
          caseSensitive: true,
          defaultOption: "equals",
        },
        type: 'rightAligned'
      },
      {
        "headerName": "SAFEKEEPING ACCOUNT", /* C170665-5 |  Safe Keeping Account*/
        "field": "skAccountNo",
        hide: true,
        showInSecondary: false,
        suppressMenu: false,
        suppressColumnVirtualisation: true,
        // sortable: true,
        resizable: true,
        suppressSizeToFit: true,
        menuTabs: ["filterMenuTab"],
        filter: "agTextColumnFilter",
        filterParams: {
          caseSensitive: true,
          defaultOption: "equals",
        },
        type: 'rightAligned'
      },
      {
        "headerName": "BRANCH", /* C170665-5 |  Branch */
        "field": "branch",
        hide: true,
        showInSecondary: false,
        suppressMenu: false,
        suppressColumnVirtualisation: true,
        // sortable: true,
        resizable: true,
        suppressSizeToFit: true,
        menuTabs: ["filterMenuTab"],
        filter: "agTextColumnFilter",
        filterParams: {
          caseSensitive: true,
          defaultOption: "equals",
        },
        type: 'rightAligned'
      },
      {
        headerName: 'RECENT NOTE',
        field: 'userNotes',
        sortable: true,
        resizable: true,
        suppressMovable: true,
        hide: true,
        showInSecondary: false,
        suppressColumnVirtualisation: true,
        suppressSizeToFit: true,
        filter: 'agTextColumnFilter',
        menuTabs: ["filterMenuTab"],
        cellRenderer: (params) => { // out of multiple notes show recent one
          let recctNoteContent = '';
          if (params.value && params.value.length > 0) {
            const recentNoteObject = params.value[params.value.length - 1];
            if (recentNoteObject && recentNoteObject.comments) {
              recctNoteContent = recentNoteObject.comments;
            }
          }
          return recctNoteContent;
        }
      },
      {
        "headerName": "ANNEX",
        "field": "followUp",
        suppressMenu: true,
        // sortable: true,
        resizable: true,
        hide: false,
        suppressMovable: true,
        width: 200, // C153176-5120: use fixed width to avoid hidden conv history scrollbar
        minWidth: 200,
        maxWidth: 200, /* add for annex distorted C153176-4904..*/
        showInSecondary: false,
        sortable: false,
        suppressSizeToFit: true,   /* added in all column due to distored issue C153176-4904 */
        suppressColumnVirtualisation: true,
        filter: 'agTextColumnFilter',
        menuTabs: ["filterMenuTab"],
        cellRenderer: function (params) {
          if (params.node.group) {
            return null;
          } else { // Retrieves the value of the current field
            let prevtimeTakenAnnex = sessionStorage.getItem("annexTimeTaken");
            let t0 = performance.now();
            var followupFlag = "";
            var autoAssignedFlag = "";
            var notesFlag = params.data.notesFlag === "Y" ? "<span class='qma-inbox-notes'><img class='mailbox-notes' src='assets/Inbox/notes_icon_selected_u108.svg' alt='Kiwi standing on oval'></span>" : "<span class='qma-inbox-notes'></span>";
            // C153176-5407 Use workflow attachment flag to show attachemtn in grid
            var attachFlag = "<span class='qma-inbox-attachement'></span>";
            let matchingWf = params.data.workflows.find(wf => wf.assignedGroupName === params.data.openGroups);
            if (matchingWf) {
              if (matchingWf.attchFlag && matchingWf.attchFlag === "Y") {
                attachFlag = "<span class='qma-inbox-attachement'><img class='' src='assets/Inbox/attachments_icon_selected_u107.svg' alt='Kiwi standing on oval'></span>";
              }
            }
            var esclationFlag = params.data.isEscalated === "Y" ?
              "<span class='qma-inbox-escalated'><i class='fa fa-exclamation-triangle' style='color:red;' title='" +
              params.data.escalationReason + "'></i></span>" : "<span class='qma-inbox-escalated'></span>";
            var important = params.data.urgentFlag === "Y" ? "<span class='qma-inbox-urgentFlg'> <i class='fa fa-exclamation' style='color: \#751919;'></i></span>" : "<span class='qma-inbox-urgentFlg'></span>";
            if ((params.data.followUp && params.data.followUp === "Y") ||
              (params.data.followUp && params.data.followUp.flag && params.data.followUp.flag === "Generic")) {
              followupFlag = "<span class='qma-inbox-followup'><img src='assets/Inbox/follow_up_icon_u112.svg' class='qma-grid-followup' alt='Kiwi standing on oval' class='qma-grid-followup'></span>";
            } else {
              followupFlag = "<span class='qma-inbox-followup'></span>";
  
            }
  
            // var nlp = (params.data.suggestionIndicator === "O" || params.data.suggestionIndicator === "R" || params.data.suggestionIndicator === "B" || params.data.suggestionIndicator === "G") ? "<span class='qma-inbox-nplm'><img src='assets/Inbox/nlp_icon_selected_u104.svg' alt='Kiwi standing on oval'></span>" : "";
            /*C153176-4660 Nlp icon some times blank so annex icon distorted*/
            let nlp = "<span class='qma-inbox-nplm mailbox-img-nlp'></span>";
            if (params.data.suggestionIndicator) {
              switch (params.data.suggestionIndicator) {
                case "O": {
                  nlp = "<span class='qma-inbox-nplm'><img src='assets/Inbox/nlp_icon_selected_u104_orange.svg' class='mailbox-img' alt='Kiwi standing on oval'></span>";
                  break;
                }
                case "R": {
                  nlp = "<span class='qma-inbox-nplm'><img src='assets/Inbox/nlp_icon_selected_u104_red.svg' class='mailbox-img' alt='Kiwi standing on oval'></span>";
                  break;
                }
                case "B": {
                  nlp = "<span class='qma-inbox-nplm'><img src='assets/Inbox/nlp_icon_selected_u104.svg' class='mailbox-img' alt='Kiwi standing on oval'></span>";
                  break;
                }
                case "G": {
                  nlp = "<span class='qma-inbox-nplm'><img src='assets/Inbox/nlp_icon_selected_u104_green.svg' class='mailbox-img' alt='Kiwi standing on oval'></span>";
                  break;
                }
                default: {
                  nlp = "<span class='qma-inbox-nplm mailbox-img-nlp'></span>";
                  break;
                }
  
              }
            }
  
            if (params.data.autoAssigned && params.data.autoAssigned === "Y") {
              autoAssignedFlag = "<span class='qma-inbox-nplm'><img src='assets/Inbox/auto-assign.svg' class='mailbox-img' alt='Kiwi standing on oval'></span>";
            } else {
              autoAssignedFlag = "<span class='qma-inbox-nplm mailbox-img-nlp'></span>";
  
            }
            var t1 = performance.now();
            if (prevtimeTakenAnnex) {
              const newtimeTaken = JSON.parse(sessionStorage.getItem("annexTimeTaken")) + (t1 - t0);
              sessionStorage.setItem("annexTimeTaken", newtimeTaken);
            } else {
              sessionStorage.setItem("annexTimeTaken", JSON.stringify(t1 - t0));
            }
            if (params.data.age && params.data.modDate.$date) {
  
              return "<div class='qma-mailbox-annex'>" + "<div class='mailbox-icons-placeholder'>" + important + esclationFlag + nlp + followupFlag + attachFlag + notesFlag + autoAssignedFlag + "</div>" + "</div>";
            } else {
              return "<div class='qma-mailbox-annex-single-line'>" + important + esclationFlag + nlp + followupFlag + attachFlag + notesFlag + autoAssignedFlag + "</div>";
            }
  
          }
          


        }

      }
    ];
  }

  static dateFormats = [
    'DD/MM/YYYY HH:mm:ss',
    'MM/DD/YYYY HH:mm:ss',
    'MM/DD/Y HH:mm:ss',
    'DD/MM/Y HH:mm:ss',
    'MMMM DD YYYY HH:mm:ss',
    'MMMM DD Y HH:mm:ss',
  ];

  static getSearchStateColumnDef() {
    return {
      "headerName": "STATE",
      "field": "direction",
      hide: false,
      suppressMenu: false,
      resizable: true,
      suppressMovable: true,
      showInSecondary: false,
      suppressSizeToFit: true,  /* added in all column due to distored issue C153176-4904 */
      sortable: true,
      width: 140,
      filter: 'agTextColumnFilter',
      menuTabs: ["filterMenuTab"],
      cellRenderer: function (params) {
        if (params.value) {
          if (params.value === 'IN') {
            return 'Inbox';
          } else if (params.value == 'OUT') {
            return 'Outbox';
          }
        }
        return params.value;
      }
    };

  }

  /**
   * Method to get the client Category with color code and name.
   * @param params
   */
  getClientCategory(params) {
    // C153176-4566 : Show System Defualt or Custom client category. System client category have precedence over custom client.
    // System Client Category
    let clientCategory = null;
    if (params.data.workflows) {
      const clntCtgryWf = params.data.workflows.find(x => x.clientCategory);
      if (clntCtgryWf && clntCtgryWf.clientCategory) {
        clientCategory = clntCtgryWf.clientCategory;
      }
    }


    // Default Client Category
    let customClientCategory = null;
    if (params.data.workflows) {
      const cstmCtgryWf = params.data.workflows.find(x => x.customClientCategory);
      if (cstmCtgryWf && cstmCtgryWf.customClientCategory) {
        customClientCategory = cstmCtgryWf.customClientCategory[0];
      }
    }
    let finalClientCategory = clientCategory ? clientCategory : customClientCategory;
    return finalClientCategory;
  }
  // C153176-5583 Include Null & Not Null into filters from drop down in Grid
  getFilterParams() {
    return {
      filterOptions: [
        'contains', 'notContains', 'equals', 'notEqual', 'startsWith', 'endsWith',
        {
          displayKey: 'blank',
          displayName: 'Is null',
          test: function (filterValue, cellValue) {
            return cellValue == null || cellValue == '';
          },
          hideFilterInput: true,
        },
        {
          displayKey: 'nonblanks',
          displayName: 'Is not null',
          test: function (filterValue, cellValue) {
            return cellValue !== null && cellValue !== '';
          },
          hideFilterInput: true,
        }
      ]

    };
  }
  // C153176-5583 Include Null & Not Null into filters from drop down in Grid
  getFilterParamsNumber() {
    return {
      filterOptions: [
        "equals",
        "notEqual",
        "lessThan",
        "lessThanOrEqual",
        "greaterThan",
        "greaterThanOrEqual",
        "inRange",
        {
          displayKey: 'blanks',
          displayName: 'Is null',
          test: function (filterValue, cellValue) {
            return cellValue == null || cellValue == "";
          },
          hideFilterInput: true,
        },
        {
          displayKey: 'nonblanks',
          displayName: 'Is not null',
          test: function (filterValue, cellValue) {
            return cellValue != null && cellValue != "";
          },
          hideFilterInput: true,
        }
      ]
    };
  }
  // C153176-5583 Include Null & Not Null into filters from drop down in Grid
  getFilterParamsDate() {
    return {
      comparator: function (filterLocalDate, cellValue) {
        let objAppUtils = new AppUtils();
        return objAppUtils.applyGridDateFilter(filterLocalDate, cellValue);
      },
      browserDatePicker: true,
      filterOptions: [
        "equals", "greaterThan", "lessThan", "notEqual", "inRange",
        {
          displayKey: 'blanks',
          displayName: 'Is null',
          test: function (filterValue, cellValue) {
            return cellValue == null;
          },
          hideFilterInput: true,
        },
        {
          displayKey: 'nonblanks',
          displayName: 'Is not null',
          test: function (filterValue, cellValue) {
            return cellValue != null;
          },
          hideFilterInput: true,
        }
      ]
    };
  }

  createKeyValueObj(value) {
    let updatedValue = value;
    if (updatedValue.includes('1 day')) {
      console.log(updatedValue);
    }
    // C170665-1515 Observation-- Not in Queue may be sorted as a value smaller than 0.
    if (updatedValue === 'NotInQ') {
      updatedValue = '-1 mins';
    }
    if (!updatedValue.includes('days')) {
      updatedValue = updatedValue.replace('day', 'days');
    }
    if (!updatedValue.includes('hours')) {
      updatedValue = updatedValue.replace('hour', 'hours');
    }
    if (!updatedValue.includes('mins')) {
      updatedValue = updatedValue.replace('min', 'mins');
    }
    let keyValue = {};
    const elemetsArray = updatedValue.split(' ');
    elemetsArray.forEach((element: any, index: any) => {
      if (isNaN(element)) {
        keyValue[element] = parseInt(elemetsArray[index - 1]);
      }
    });
    return keyValue;
  }
}

export class GfcModel {
    field: string;
    filCriteria: string;
    filterOnGfc: boolean;
    filterType: string;
    filterVal: string;
    ignoreCase: boolean;
    acctBranch: string;
    accCriteria: boolean;

    constructor(field?: string, filCriteria: string = "gfcIds.gfcId", filterOnGfc: boolean = false, filterType: string = 'contains',
        filterVal?: string, ignoreCase: boolean = false, acctBranch?: string, accCriteria: boolean = false) {
        this.field = field;
        this.filCriteria = filCriteria;
        this.filterOnGfc = filterOnGfc;
        this.filterType = filterType;
        this.filterVal = filterVal;
        this.ignoreCase = ignoreCase;
        this.acctBranch = acctBranch;
        this.accCriteria = accCriteria;
    }
}
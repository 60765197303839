
import { ToList } from "src/app/model/LoginUserInfo/ToList";
import { CcList } from "src/app/model/LoginUserInfo/CcList";
import { InqTemplateShareToGrp } from "src/app/model/user-profile/inqTemplateShareToGrp";
import { ShareToGrpList } from "src/app/model/LoginUserInfo/ShareToGrpList";

export class InquiryTemplate {
    name?: string;
    value?: string;
    templateName: string;
    templateContent: string;
    templateSubject: string;
    toList?: ToList[];
    ccList?: CcList[];
    shareToGrpList?: ShareToGrpList[];
    inqTemplateShareToGrp?: InqTemplateShareToGrp[];
    delTempGrpList?: string[];
    inqTemplateTo?: ToList[];
    inqTemplateCc?: CcList[];
    active?: boolean;
    modified?: number;
    modifiedBy?: string;
    mergeRecipients?: boolean;
}
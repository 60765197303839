import { Component, OnInit, Output, EventEmitter, ChangeDetectionStrategy, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import { NewMessageService } from '../../services/newMessage/new-message.service';
import { AgGridCheckboxComponent } from 'src/app/core/user/ag-grid-checkbox.component';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { GridOptions, Module } from "@ag-grid-community/core";
import { UserDataService } from 'src/app/services/user-data.service';
import { PiwikProUtils } from 'src/app/common/utility/piwikProUtils';

@Component({
  selector: 'app-cmcsearch',
  templateUrl: './cmcsearch.component.html',
  styleUrls: ['./cmcsearch.component.scss']
})
export class CmcsearchComponent implements OnInit {
  modules: Module[] = [ClientSideRowModelModule];

  display: boolean = false;
  displayPopup: boolean = true;
  public routesList: any = [];
  public routesColumn: any = [];
  public gridApiInquiry;
  public gridColumnApiInquiry;
  public disablePaginationNextLast: boolean = false;
  public disablePaginationPrevFirst: boolean = false;
  public lbTotalRecords: number = 0;
  public lbPageSize: number = 0;
  public lbCurrentPage: number = 0;
  public lbTotalPages: number = 0;
  public search: boolean = false;
  public defaultColDef;
  public soeId: string = '';
  public toCcBcc: string = '';
  public errorMsg: string = '';
  public displayError: boolean = false;
  @Output() cmcContactData = new EventEmitter<{ selectedContacts: any, toCcBcc: any }>();

  cmcSearchForm: FormGroup;

  // C153176-5259 : Spinner Variable
  showCmcSpinner: boolean = false;
  public isCorporateSearch = false;
  showFields: string = 'CMC';
  productFamilyData: any[] = [];
  showTCL: string;
  constructor(private userDataService: UserDataService, private newMessageService: NewMessageService, private builder: FormBuilder) { }

  ngOnInit() {
    let loginUserInfo = this.userDataService.loggedInUserInfo;
    this.productFamilyData.push({ option: 'Select Product Family', value: '' })
    let productFamilyDataArray = loginUserInfo.productFamily ? loginUserInfo.productFamily : []
    productFamilyDataArray.forEach(data => {
      this.productFamilyData.push({ option: data, value: data })
    });
    this.showTCL = loginUserInfo.enableBrazilFxIntegration ? loginUserInfo.enableBrazilFxIntegration : ''
    this.cmcSearchForm = this.builder.group({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      contactEmail: new FormControl('', [Validators.required]),
      clientIdentifierOption: new FormControl('', [Validators.required]),
      clientIdentifierValue: new FormControl('', [Validators.required]),
      // form control for TCL
      productFamily: new FormControl('', [Validators.required]),
      clientEmail: new FormControl('', [Validators.required]),
      taxId: new FormControl('', [Validators.required]),
    });

    this.createRouteColumn();
    this.routesList = [];
  }

  private createRouteColumn() {

    this.defaultColDef = {
      resizable: true,
      suppressMovable: true,
      editable: false,
      sortable: true,
      cellStyle: { 'background-color': 'white', 'color': '#002d72' },
      menuTabs: ["filterMenuTab"],
      filter: 'agTextColumnFilter',
      filterParams: {
        clearButton: true,
        applyButton: true,
        debounceMs: 200
      }
    };

    this.routesColumn = [
      {
        field: "contactName",
        headerName: 'NAME',
        width: 400,
        headerCheckboxSelection: true,
        checkboxSelection: true,
        tooltipField: "contactName",
        cellStyle: { 'display': 'inline', 'width': '170px' } // C153176-4600 Long names are not displayed in theName field,field displays ellipsis indicating a long value added for all field
      },
      {
        field: "contactEmail",
        headerName: 'EMAIL ID',
        width: 360,
        tooltipField: "contactEmail",
        cellStyle: { 'left': '168px !important', 'width': '130px' },
        autoSizeColumns: true
      },
      {
        field: "clientName",
        headerName: 'CLIENT NAME',
        width: 330,
        tooltipField: "clientName",
        cellStyle: { 'left': '312px !important', 'width': '160px' }
      },
      {
        field: "clientId",
        headerName: 'CLIENT ID',
        width: 290,
        tooltipField: "clientId",
        cellStyle: { 'left': '435px !important', 'width': '170px' },
      },
      {
        field: "clientIdType",
        headerName: 'CLIENT TYPE',
        width: 320,
        tooltipField: "clientIdType",
        cellStyle: { 'left': '534px !important', 'width': '120px' },
      },
      {
        field: "taxId",
        headerName: 'TAX ID',
        width: 250,
        tooltipField: "taxId",
        cellStyle: { 'left': '669px !important', 'width': '125px' }
      },
      {
        field: "managementId",
        headerName: 'MGMT ID',
        width: 260,
        tooltipField: "managementId",
        cellStyle: { 'left': '765px !important', 'width': '120px' }
      },
      {
        field: "mnemonic",
        headerName: 'MNEMONIC',
        width: 320,
        tooltipField: "mnemonic",
        cellStyle: { 'left': '862px !important', 'width': '130px' }
      },
      {
        field: "department",
        headerName: 'DEPT',
        width: 300,
        tooltipField: "department",
        cellStyle: { 'left': '991px !important', 'width': '120px' }
      }
    ];

    // this.rowSelection = "multiple";
  }

  loadCMCSearch(toCcBcc, soeId) {
    this.resetCMC();
    this.toCcBcc = toCcBcc;
    this.soeId = soeId;
    this.display = true;
  }

  hideModal() {
    this.display = false;
    this.resetCMC();
    this.isCorporateSearch = false;
  }

  validateCMC() {
    let firstName = this.cmcSearchForm.get('firstName').value;
    let lastName = this.cmcSearchForm.get('lastName').value;
    let contactEmail = this.cmcSearchForm.get('contactEmail').value;
    let clientIdentifierOption = this.cmcSearchForm.get('clientIdentifierOption').value;
    let clientIdentifierValue = this.cmcSearchForm.get('clientIdentifierValue').value;

    let regexFirstName = /^[A-Za-z ]+$/.test(firstName);
    let regexLastName = /^[A-Za-z ]+$/.test(lastName);
    let regexGpId = /^[0-9]*$/.test(clientIdentifierValue);
    let regexEmail = /^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/.test(contactEmail);

    if (!this.isNull(clientIdentifierOption) && this.isNull(clientIdentifierValue)) {
      this.errorMsg = 'Enter valid selected Client Identifier';
      return true;
    }
    else if (!regexFirstName && !this.isNull(firstName)) {
      this.errorMsg = 'Enter first name with characters from a-zA-Z';
      return true;
    }
    else if (!regexLastName && !this.isNull(lastName)) {
      this.errorMsg = 'Enter last name with characters from a-zA-Z';
      return true;
    }
    else if (!regexGpId && (clientIdentifierOption === "GP ID")) {
      this.errorMsg = 'Enter gp id with numbers only';
      return true;
    }
    else if (!regexEmail && !this.isNull(contactEmail)) {
      this.errorMsg = 'Enter valid email id';
      return true;
    }
    else if (this.isNull(firstName) && this.isNull(lastName) && this.isNull(contactEmail) && this.isNull(clientIdentifierOption) && this.isNull(clientIdentifierValue)) {
      this.errorMsg = 'Enter valid search criteria';
      return true;
    }

    // If select clientIdentifierOption is blank and clientIdentifierValue has value entred. Reset the value field
    if (this.isNull(clientIdentifierOption) && !this.isNull(clientIdentifierValue)) {
      this.cmcSearchForm.get('clientIdentifierValue').setValue('');
    }
    return false;
  }

  // Null value check function
  isNull(input) {
    if (input === "" || input === undefined || input == null) {
      return true;
    }
    return false;
  }

  closeModal() {
    this.displayError = false;
  }

  searchCMC() {
    if (this.validateCMC()) {
      this.displayError = true;
      return false;
    }
    this.routesList = [];
    let firstName = (this.isNull(this.cmcSearchForm.get('firstName').value)) ? '' : this.cmcSearchForm.get('firstName').value;
    let lastName = (this.isNull(this.cmcSearchForm.get('lastName').value)) ? '' : this.cmcSearchForm.get('lastName').value;
    let contactEmail = (this.isNull(this.cmcSearchForm.get('contactEmail').value)) ? '' : this.cmcSearchForm.get('contactEmail').value;
    let clientIdentifierOption = (this.isNull(this.cmcSearchForm.get('clientIdentifierOption').value)) ? '' : this.cmcSearchForm.get('clientIdentifierOption').value;
    let clientIdentifierValue = (this.isNull(this.cmcSearchForm.get('clientIdentifierValue').value)) ? '' : this.cmcSearchForm.get('clientIdentifierValue').value;
    console.log('Data:', firstName, lastName, contactEmail);
    let request =
    {
      "soeId": this.soeId,
      "firstName": firstName,
      "lastName": lastName,
      "contactEmail": contactEmail,
      "clientIdentifierOption": clientIdentifierOption,
      "clientIdentifierValue": clientIdentifierValue,
      "toCcBcc": this.toCcBcc.toUpperCase(),
      "inquiryId": "",
      "groupId": ""
    };

    let qmaContactList = [];
    this.search = true;
    this.showCmcSpinner = true;
    console.debug(":SPINNER:: " + this.showCmcSpinner + " ::cmcsearch.searchCMC");
    this.newMessageService.getContactListFromCMC(request).subscribe(result => {
      qmaContactList = result.qmaContactList;
      qmaContactList.forEach(route => {
        let obj: any = {};
        obj.contactName = route.lastName + "," + route.firstName;
        obj.contactEmail = route.email;
        obj.clientName = route.clientName;
        obj.clientId = route.clientID;
        obj.clientIdType = route.clientIdType;
        obj.managementId = route.managementId;
        obj.mnemonic = route.mnemonic;
        this.routesList.push(obj);
      });

      if (qmaContactList.length === 0) {
        this.search = false;
        this.errorMsg = "No contacts found in CMC.";
        this.displayError = true;
      } else {
        console.log('routesList:', this.routesList);
        this.gridApiInquiry.applyTransaction(this.routesList);
      }
      this.showCmcSpinner = false;
      console.debug(":SPINNER:: " + this.showCmcSpinner + " ::cmcsearch.searchCMC");
    }, error => {
      this.showCmcSpinner = false;
      console.debug(":SPINNER:: " + this.showCmcSpinner + " ::cmcsearch.searchCMC");
      throw new Error('Failed to fetch the cmc records' + error);
    });
    PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled, this.userDataService.loggedInUserInfo.userId, "Menu", "New Message - Contact Search", "Click", "New Message - Contact Search", 0);
  }

  onGridReadyInquiry(params) {
    this.gridApiInquiry = params.api;
    this.gridColumnApiInquiry = params.columnApi;
    params.api.sizeColumnsToFit();
    //this.gridColumnApi = params.columnApi;
  }

  onPaginationChanged() {
    if (this.gridApiInquiry) {
      this.lbTotalRecords = this.gridApiInquiry.paginationGetRowCount();
      this.lbPageSize = this.gridApiInquiry.paginationGetPageSize();
      this.lbCurrentPage = this.gridApiInquiry.paginationGetCurrentPage() + 1;
      this.lbTotalPages = this.gridApiInquiry.paginationGetTotalPages();

      let currentPage = this.gridApiInquiry.paginationGetCurrentPage() + 1;
      let lastPage = this.gridApiInquiry.paginationGetTotalPages();
      if (currentPage == lastPage) {
        this.disablePaginationNextLast = true;
      }
      else {
        this.disablePaginationNextLast = false;
      }

      if (currentPage == 1) {
        this.disablePaginationPrevFirst = true;
      }
      else {
        this.disablePaginationPrevFirst = false;
      }

      if (this.lbPageSize > this.lbTotalRecords) {
        this.lbPageSize = this.lbTotalRecords;
      }
      else {
        this.lbPageSize = this.gridApiInquiry.paginationGetPageSize();
      }
    }
  }
  setText(selector, text) {
    document.querySelector(selector).innerHTML = text;
  }
  setLastButtonDisabled(disabled) {
    //document.querySelector("#btLast").disabled = disabled;
  }
  onBtNext() {
    this.gridApiInquiry.paginationGoToNextPage();
  }
  onBtPrevious() {
    this.gridApiInquiry.paginationGoToPreviousPage();
  }
  onBtLast() {
    this.gridApiInquiry.paginationGoToLastPage();
  }
  onBtFirst() {
    this.gridApiInquiry.paginationGoToFirstPage();
  }

  resetCMC(currentTab?) {
    this.cmcSearchForm.reset();
    this.cmcSearchForm.get('clientIdentifierOption').setValue('');
    this.cmcSearchForm.get('productFamily').setValue('');
    this.search = false;
    this.showFields = currentTab ? currentTab : 'CMC';
  }

  isFirstColumn(params) {
    var displayedColumns = params.columnApi.getAllDisplayedColumns();
    var thisIsFirstColumn = displayedColumns[0] === params.column;
    return thisIsFirstColumn;
  }

  onSelectionRules() {
    let selectedRouteCount = 0;
    let arr = this.gridApiInquiry.getSelectedRows();
    selectedRouteCount = arr.length;
  }

  selectContacts() {
    let selectedRows = this.gridApiInquiry.getSelectedRows();
    let selectedContacts = [];
    selectedRows.forEach(row => {
      selectedContacts.push({ text: row.contactName, value: row.contactEmail });
    });
    this.cmcContactData.emit({ selectedContacts: selectedContacts, toCcBcc: this.toCcBcc });
    this.search = false;
    this.display = false;
  }

  selectContactsDL(cmcContactDataDL) {
    this.cmcContactData.emit(cmcContactDataDL);
    this.search = false;
    this.display = false;
  }
  oncmcCrptSearchError(errorMsg) {
    this.errorMsg = errorMsg;
    this.displayError = true;
  }

  onSourceChange(value: string) {
    if (value == 'CMC') {
      this.showFields = 'CMC'
    } else if (value == 'TCL') {
      this.showFields = 'TCL'
    }
  }

  searchTCL() {
    if (this.validateTCL()) {
      this.displayError = true;
      return false;
    }
    this.routesList = [];
    let productFamily = (this.isNull(this.cmcSearchForm.get('productFamily').value)) ? '' : this.cmcSearchForm.get('productFamily').value;
    let taxId = (this.isNull(this.cmcSearchForm.get('taxId').value)) ? '' : this.cmcSearchForm.get('taxId').value;
    let clientEmail = (this.isNull(this.cmcSearchForm.get('clientEmail').value)) ? '' : this.cmcSearchForm.get('clientEmail').value;
    let request =
    {
      "taxId": taxId,
      "productFamily": productFamily,
      "email": clientEmail,
      "source": "TCL"
    };

    let qmaContactList = [];
    this.search = true;
    this.showCmcSpinner = true;
    this.newMessageService.getContactListFromTCL(request).subscribe(result => {
      qmaContactList = result.qmaContactList;
      qmaContactList.forEach(route => {
        let obj: any = {};
        obj.contactName = route.firstName;
        obj.contactEmail = route.email;
        obj.clientName = route.clientName;
        obj.clientId = route.clientID;
        obj.clientIdType = route.clientIdType;
        obj.taxId = route.taxId
        obj.department = route.familyName
        this.routesList.push(obj);
      });
      if (qmaContactList.length === 0) {
        this.search = false;
        this.errorMsg = "No contacts found in TCL.";
        this.displayError = true;
      } else {
        console.log('routesList:', this.routesList);
        this.gridApiInquiry.applyTransaction(this.routesList);
      }
      this.showCmcSpinner = false;
      console.debug(":SPINNER:: " + this.showCmcSpinner + " ::cmcsearch.searchCMC");
    }, error => {
      this.showCmcSpinner = false;
      console.debug(":SPINNER:: " + this.showCmcSpinner + " ::cmcsearch.searchCMC");
      throw new Error('Failed to fetch the cmc records' + error);
    });
    PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled, this.userDataService.loggedInUserInfo.userId, "Menu", "New Message - Contact Search", "Click", "New Message - Contact Search", 0);
  }

  validateTCL() {
    let productFamily = this.cmcSearchForm.get('productFamily').value;
    let taxId = this.cmcSearchForm.get('taxId').value;
    let clientEmail = this.cmcSearchForm.get('clientEmail').value;

    let regexTaxId = /^[0-9]+$/.test(taxId);
    let regexEmail = /^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/.test(clientEmail);

    if (!regexTaxId && !this.isNull(taxId)) {
      this.errorMsg = 'Enter tax Id with digits from 0-9';
      return true;
    } else if (!regexEmail && !this.isNull(clientEmail)) {
      this.errorMsg = 'Enter valid email id';
      return true;
    } else if (this.isNull(productFamily) && !this.isNull(productFamily)) {
      this.errorMsg = 'Enter valid Product Family';
      return true;
    } else if (this.isNull(taxId) && this.isNull(clientEmail) && this.isNull(productFamily)) {
      this.errorMsg = 'Enter valid search criteria';
      return true;
    }
    return false;
  }
}
  <div class="heading">
    <span>CONTACT SEARCH</span>
  </div>
  <div class="search-list">
    <div class="input-group tofilter-section">
      <input #myInput type="text" placeholder='Search by Name, Email, Mgmt ID…' [(ngModel)]='query'
        (input)="filterItem(myInput.value)">
        <span><i class="fa fa-filter qma-mailbox-filter filter-icon-secton" (click)="callCmcSearchWindow()"></i></span>
      <div class="input-group-btn searchBox">
        <button class="btn btn-default">
          <span><img src="assets/core/layout/header/search_blue.svg" style="width:18px;height:18px;"></span>
        </button>
      </div>
    </div>
  </div>
  <ul class="listOfContacts">
      <li class="contacts" *ngFor="let groupcontact of filteredItems; trackBy: trackById"
        (click)="getContact(groupcontact, popup)">
        <div class="d-flex align-items-start flex-row">
          <div>
            <img src="assets/core/layout/header/person.jpg" alt="" class="profileImage">
          </div>
          <div class="d-flex flex-column ml-1">
            <div class="name">{{groupcontact.text}}</div>
            <div class="email">{{groupcontact.value}}</div>
            <div class="number">{{groupcontact.id}}</div>
          </div>
        </div>
      </li>
  </ul>
import { QmaConstant } from 'src/app/constant/qma-constant';
export class HtmlElementsPaginationService {
    private strOrignalConversation:string = "";
    private orignalConversationHtmlArrayToRender: any;
    private arrNonRenderedNHtmlElemets = [];
    private renderedHtmlElementIndex = 0
    private stringToRender = '';
    private htmlPaginationConfig= {
        htmlPaginationEnabled:false,
        minConvCountForConvPagination:0,
        pazeSizeForConvPagination:0,
        minElmCountForConvPagination:0,
        elmPageSizeForConvPagination:0
    }

    processOrignalConversation (orignalConversation:string,htmlPaginationConfig:any) { 
        try{
            if(!orignalConversation) {
                return;
            }
            this.htmlPaginationConfig = htmlPaginationConfig;
        if(!this.htmlPaginationConfig.htmlPaginationEnabled) {
            this.htmlPaginationConfig.htmlPaginationEnabled = false;
            return orignalConversation;
        } else if(this.htmlPaginationConfig.htmlPaginationEnabled){
            this.splitBasedOnHtmlTag(orignalConversation);
            if(this.orignalConversationHtmlArrayToRender.length > this.htmlPaginationConfig.minElmCountForConvPagination) {
                this.htmlPaginationConfig.htmlPaginationEnabled  = true;
                this.strOrignalConversation = orignalConversation;
            }
        }
        return this.htmlPaginationConfig.htmlPaginationEnabled;
        } catch(e) {
            console.log("exception in processOrignalConversation" +e);
        }
       
    }

    splitBasedOnHtmlTag(orignalConversation){
        try {
            let parser = new DOMParser();
            let doc = parser.parseFromString(orignalConversation, 'text/html');
            this.orignalConversationHtmlArrayToRender = doc.body.childNodes;
        } catch(e) {
            console.log("exception in splitBasedOnHtmlTag" + e);
        }
       
    }
    

    getNonRenderedNHtmlElements(nHtmlElementsToRender) {
        try {
            for(let i=this.renderedHtmlElementIndex; i < this.htmlPaginationConfig.elmPageSizeForConvPagination 
                && i< this.orignalConversationHtmlArrayToRender.length;i++) 
                {
                let tmpHtml = this.orignalConversationHtmlArrayToRender[this.renderedHtmlElementIndex].nodeName === "#text" ?
                this.orignalConversationHtmlArrayToRender[this.renderedHtmlElementIndex].textContent:
                this.orignalConversationHtmlArrayToRender[this.renderedHtmlElementIndex].outerHTML;
                if(tmpHtml) {
                    this.stringToRender = this.stringToRender + tmpHtml;
                }
                // this.stringToRender = this.stringToRender + this.orignalConversationHtmlArrayToRender[this.renderedHtmlElementIndex].outerHTML;
                this.renderedHtmlElementIndex++;
                }
            this.htmlPaginationConfig.elmPageSizeForConvPagination = this.htmlPaginationConfig.elmPageSizeForConvPagination + nHtmlElementsToRender;
        this.removeViewMoreIcon();
        if(this.orignalConversationHtmlArrayToRender.length === this.renderedHtmlElementIndex) {
            return this.stringToRender;
        } else {
            return this.insertViewMoreIcon(); 
        }
        }
        catch(e) {
            console.log('exception while element pagination',e);
            }
       
    }
    insertViewMoreIcon() {
        try {
            this.stringToRender = this.stringToRender + QmaConstant.QMA_MAIL_SPLIT_MORE_ICON;
            return this.stringToRender;
        } catch(e) {
            console.log('exception in  while element pagination',e);

        }
        
    }
    removeViewMoreIcon() {
        try {
        this.stringToRender = this.stringToRender.replace(QmaConstant.QMA_MAIL_SPLIT_MORE_ICON,'');
        return this.stringToRender;
        }
        catch(e) {
            console.log('exception in  while element pagination',e);

        } 
    }



}
import { Injectable } from '@angular/core';
import { HttpClientService } from '../../utility/http-client.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { serviceUrl } from '../../utility/serviceUrl';
import { QmaConstant } from 'src/app/constant/qma-constant';
import { Observable } from 'rxjs';

const httpFormPostOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  })
};

@Injectable({
  providedIn: 'root'
})

export class AdminSetupService extends HttpClientService{
  genericURL: string;
  constructor(http: HttpClient) {
    super(http);
    this.genericURL=QmaConstant.BASE_URL;
  }
  getMISExclusionReportRecords(formData:any):Observable<any>{
    let url=this.genericURL+'/'+serviceUrl.getMISExclusionReportRecords;
    console.log('get mis exclusion report'+formData);
    let req = "request:" + formData;
    return this.http.post(url,formData,httpFormPostOptions);
  }
  processGfpcBulkUpload(formData:any){
    console.log('process Gfpc bulk upload');
    let url:string =this.genericURL+'/'+serviceUrl.processGfpcBulkUpload;
    return this.http.post(url,formData);
  }
  
  processGrpBulkUpload(fileUpload) : Observable<any> {
    let url:string =this.genericURL+'/'+serviceUrl.processGrpBulkUpload;
    return this.http.post(url,fileUpload);
  }
  processRtmBulkUpload(formData:any){
    let url:string =this.genericURL+'/'+serviceUrl.processRtmBulkUpload;
    return this.http.post(url,formData);
  }
  saveBulkGroupResolve(formData:any):Observable<any>{
    let url:string=this.genericURL+'/'+serviceUrl.saveBulkGroupResolve;
    console.log('save bulk gruop resolve data');
    return this.http.post(url,formData,httpFormPostOptions);
  }
  saveExclusionReportData(formData:any):Observable<any>{
    let url:string=this.genericURL+'/'+serviceUrl.saveExclusionReportData;    
    return this.http.post(url,formData,httpFormPostOptions);
  }
  deactivateExclusionReportData(formData:any):Observable<any>{
    let url:string=this.genericURL+'/'+serviceUrl.deactivateExclusionReportData;    
    return this.http.post(url,formData,httpFormPostOptions);
  }

  downloadTemplate(templateType:string):void{
      const templateUrl = this.genericURL + '/' + serviceUrl.getTemplateFile + '?templateType=' + templateType;

      this.http.get(templateUrl, {responseType: "blob" }).subscribe(result => {
        var newBlob = new Blob([result], { type: "application/octet-stream" });
        if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
          (window.navigator as any).msSaveOrOpenBlob(newBlob);
          return;
        }

        const data = window.URL.createObjectURL(newBlob);
        let link = document.createElement('a');
        link.href = data;
        link.download = templateType + '.csv';
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

        setTimeout(function () {
          window.URL.revokeObjectURL(data);
          link.remove();
        }, 100);
      }, error => {
        console.log('error :', error);
      })
    }
}
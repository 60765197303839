import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef, HostListener } from '@angular/core';
import { DashboardService } from '../../services/dashboard/dashboard.service';
import { AppUtils } from 'src/app/common/utility/appUtil';
import * as HighCharts from 'highcharts';
import { DashboardLocalService } from "../../services/dashboard/dashboard-local.service";

import StockModule from 'highcharts/modules/stock';
StockModule(HighCharts);

import HC_exporting from 'highcharts/modules/exporting';
HC_exporting(HighCharts);
import OfflineExport from 'highcharts/modules/offline-exporting';
import ExportData from 'highcharts/modules/export-data';
OfflineExport(HighCharts);
ExportData(HighCharts);
import { FadingDialogComponent } from 'src/app/dashboard/fading-dialog/fading-dialog.component';

import * as $ from 'jquery';
import { UserDataService } from 'src/app/services/user-data.service';
import { PiwikProUtils } from 'src/app/common/utility/piwikProUtils';


@Component({
  selector: 'app-inquiries-by-trend',
  templateUrl: './inquiries-by-trend.component.html',
  styleUrls: ['./inquiries-by-trend.component.scss']/* ,
  host: {
    '(document:keydown)': 'onKeydown($event)'
  } */
})
export class InquiriesByTrendComponent implements OnInit, AfterViewInit {
  ngAfterViewInit(): void {
    /* this.cdr.detach(); */
  }
  constructor(private dashboardService: DashboardService, private userDataService: UserDataService, private localService: DashboardLocalService, private cdr: ChangeDetectorRef) {
    this.drilldownOn = false;
    this.inquiriesByTrend.ngComponent = this;
  }
  rootNode: any;
  HighCharts = HighCharts; // required
  protected inquiriesByTrend: any = {
    chart: {
      type: 'area',
      backgroundColor: '#FCFCFC',
      events: {
        load(e) {
          if (this.chart && this.chart.options.ngComponent) {
            this.chart.options.ngComponent.setChartExtremes();
          }
        },
        click(e) {
          if (this.chart && this.chart.options.ngComponent) {
            this.chart.options.ngComponent.switchToDrilldown(e.xAxis[0].value);
          } else if (this.options && this.options.ngComponent) {
            this.options.ngComponent.switchToDrilldown(e.xAxis[0].value);
          } else {
            this.drilldownOn = true;
          }
        },
        dblclick(e) {
          if (this.chart && this.chart.options.ngComponent) {
            this.chart.options.ngComponent.switchToDrilldown(e.xAxis[0].value);
          } else if (this.options && this.options.ngComponent) {
            this.options.ngComponent.switchToDrilldown(e.xAxis[0].value);
          } else {
            this.drilldownOn = true;
          }
        }
      }
    },
    title: {
      useHTML: true,
      text: '<span style="line-height: 15px;font-size: 15px;font-family: \'InterstateLight\';">INQUIRIES TREND</span>',
      align: 'left'
    },
    subtitle: {
      useHTML: true,
      text: '<div><span style="font-size:12px;">HIGHEST</span> ' +
        '<span style="font-size:13px;color:red;padding:0px 5px;" class="highestCount"></span>' +
        '<span style="font-size:12px;">inquiries&nbsp;&nbsp;<br class="d-block d-sm-none">LOWEST&nbsp;</span>' +    //C153176-4599 - Inquiry trend dashboard mobile allignments
        '<span style="font-size:13px;color:green;padding:0px 5px;" ' +
        'class="lowestCount"></span><span style="font-size:12px;">&nbsp;inquiries</span></div>',
      align: 'left',
      verticalAlign: 'top',
      y: 50,
    },
    navigator: {
      enabled: false
    },
    legend: {
      enabled: false
    },
    xAxis: [{
      labels: {
        style: {
          fontSize: '10px'
        }
      }
    }],
    yAxis: {
      min: 0,
      title: {
        text: null
      }
    },

    rangeSelector: {
      labelStyle: {
        display: 'none'
      },
      verticalAlign: 'top',
      buttonPosition: {
        align: 'right'
      },
      selected: 0,
      inputEnabled: false,
      buttons: [
        {
          type: 'week',
          count: 1,
          text: '1W'
        }, {
          type: 'month',
          count: 1,
          text: '1M'
        }, {
          type: 'month',
          count: 3,
          text: '1Q'
        },
        {
          type: 'year',
          count: 1,
          text: '1Y'
        }]
    },
    tooltip: {
      holdDelay: 1500,
      useHTML: true,
      backgroundColor: 'rgba(252,252, 252,1)',
      formatter() {
        return [''].concat(
          this.points.map((point) => {
            return '<div style="color:{point.color};">'
              + AppUtils.formatDate(new Date(point.x)) + '</div><div><b>&nbsp;' + point.y + '</b> inquiries</div>';
          })
        );
      }
    },
    plotOptions: {
      series: {
        cursor: 'pointer',
        events: {
          click: (e) => {
            this.switchToDrilldown(e.point.x);
          }
        }
      }
    },
    series: [{
      name: 'Inquiries Trend',
      data: null,
      threshold: null,
      lineColor: '#000088',
      lineWidth: 1,
      tooltip: {
        valueDecimals: 0,
        useHTML: true
      },
      opacity: 0.8
    }],
    navigation: {
      enabled: false,
      buttonOptions: {
        enabled: true,
        symbolSize: 28,
        symbolX: 25,
        symbolY: 22
      }
    },
    exporting: {
      // C153176-4479: support larger print max width
      printMaxWidth: 1500,
      menuItemDefinitions: {
        maximize: {
          onclick() {
            if (this.options && this.options.ngComponent) {
              this.options.ngComponent.enterFullScreen(this);
            } else {
              console.log('Failed to enter full screen');
            }
          },
          text: 'Maximize'
        }
      },
      buttons: {
        contextButton: {
          menuItems: [{
            textKey: 'printChart',
            onclick() {
              this.print();
            }
          }, {
            textKey: 'downloadPNG',
            onclick: () => { this.localService.download("inquiries-by-trend", "png"); }
            // function () {
            //     this.exportChart();
            // }
          }, {
            textKey: 'downloadJPEG',
            onclick: () => { this.localService.download("inquiries-by-trend", "jpg"); }
            // function () {
            //     this.exportChart({
            //         type: 'image/jpeg'
            //     });
            // }
          }, {
            textKey: 'downloadPDF',
            onclick: () => { this.localService.download("inquiries-by-trend", "pdf"); }
            // function () {
            //     this.exportChart({
            //         type: 'application/pdf'
            //     });
            // }
          }, {
            textKey: 'downloadCSV',
            onclick() {
              this.downloadCSV();
            }
          }, "maximize"], // "viewFullscreen"],
          symbol: 'url(assets/dashboard/contextmenu.png)'
        }
      },
      csv: {
        decimalPoint: ":"
      }
    },
    credits: {
      enabled: false
    },
    ngComponent: null,
    scrollbar: {
      barBackgroundColor: '#002D72',
      barBorderColor: '#002D72',
      barBorderRadius: 7,
      barBorderWidth: 0,
      buttonBackgroundColor: '#FCFCFC',
      buttonBorderColor: '#FCFCFC',
      buttonBorderWidth: 0,
      buttonArrowColor: '#FCFCFC',
      buttonBorderRadius: 7,
      rifleColor: '#FCFCFC',
      trackBackgroundColor: '#FCFCFC',
      trackBorderWidth: 1,
      trackBorderColor: '#FCFCFC',
      trackBorderRadius: 7,
      height: 6,
      enabled: true
    }
  };
  public drilldownOn = false;
  protected trendChart: any;
  protected chartData: any;
  protected drilldownDate: any;
  protected selectedDate: string = "";
  protected fullScreen = false;
  @ViewChild(FadingDialogComponent, { static: true }) fadingDialogComponent;
  protected minDate;
  protected maxDate;
  protected selectedGroup; // C153176-5105: don't initialize the 'selectedGroup' so that it will be initialized by caller
  selectedOption: string = 'inquiries';
  isNewResponse: boolean = false;
  availOptions = [
    { label: 'All', value: 'all' },
    { label: 'Inquiries', value: 'inquiries' },
    { label: 'Non-inquiries', value: 'noninquiries' }
  ];
  public inquiriesDrilldownColors = ['#54C571', '#FFBF00', '#9361A8', '#D40E8C', '#F75D59', '#2E64AF'];

  public inquiriesDrillDownOptions: any = {
    chart: {
      type: 'column',
      backgroundColor: '#FCFCFC',
      height: 290
    },
    title: {
      useHTML: true,
      text: '<span style="line-height: 15px;font-size: 15px;font-family: \'InterstateLight\';">INQUIRIES TIMELINE</span>',
      align: 'left',
      x: 24
    },
    subtitle: {
      useHTML: true,
      text: '<span class="innerLabel" style="font-family:InterState;font-size:14px;">'
        + $('<div>').text(this.selectedDate).html() + '</span>',
      align: 'left',
      verticalAlign: 'top',
      x: 200,
      y: 10
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>{point.y:.0f} </b></td></tr>',
      footerFormat: '</table>',
      shared: false,
      useHTML: true
    },
    legend: {
      enabled: true
    },
    xAxis: {
      categories: ['8am to 12pm', '12pm to 4pm', '4pm to 8pm', 'other']
    },
    yAxis: {
      reversed: false,
      min: 0,
      title: {
        text: 'Inquiries',
      }
    },
    series: [],
    navigation: {
      enabled: false,
      buttonOptions: {
        enabled: true,
        symbolSize: 28,
        symbolX: 25,
        symbolY: 22
      }
    },
    exporting: {
      // C153176-4479: support larger print max width
      printMaxWidth: 1500,
      menuItemDefinitions: {
        maximize: {
          onclick() {
            if (this.options && this.options.ngComponent) {
              this.options.ngComponent.enterFullScreen(this);
            } else {
              console.log('Failed to enter full screen');
            }
          },
          text: 'Maximize'
        }
      },
      buttons: {
        contextButton: {
          menuItems: [{
            textKey: 'printChart',
            onclick() {
              this.print();
            }
          }, {
            textKey: 'downloadPNG',
            onclick: () => { this.localService.download("inquiries-by-trend-drilldown", "jpg"); }
            // function () {
            //     this.exportChart();
            // }
          }, {
            textKey: 'downloadJPEG',
            onclick: () => { this.localService.download("inquiries-by-trend-drilldown", "jpg"); }
            // function () {
            //     this.exportChart({
            //         type: 'image/jpeg'
            //     });
            // }
          }, {
            textKey: 'downloadPDF',
            onclick: () => { this.localService.download("inquiries-by-trend-drilldown", "pdf"); }
            // function () {
            //     this.exportChart({
            //         type: 'application/pdf'
            //     });
            // }
          }, {
            textKey: 'downloadCSV',
            onclick() {
              this.downloadCSV();
            }
          }, "maximize"], // "viewFullscreen"],
          symbol: 'url(assets/dashboard/contextmenu.png)'
        }
      },
      csv: {
        decimalPoint: ":"
      }
    },
    credits: {
      enabled: false
    },
    ngComponent: null
  };
  drilldownChart: any;


  ngOnInit() {
    // subscribing data from local variable to reduce server hits.
    this.dashboardService.getInquiriesTrend.subscribe(data => {
      if (this.handleTrends(JSON.stringify(data))) {
        StockModule(HighCharts);
        HC_exporting(HighCharts);
        OfflineExport(HighCharts);
        ExportData(HighCharts);
        setTimeout(() => {
          const elem = $('#inquiries-by-trend');
          if (elem && elem.length) {
            HighCharts.setOptions({ lang: { thousandsSep: "," } });
            this.trendChart = HighCharts.stockChart('inquiries-by-trend', this.inquiriesByTrend);
            this.trendChart.options.ngComponent = this;
            this.setChartExtremes();
          }
        }, 0);
      } else {
        console.log("Invalid or empty trend data");
      }
    });

    this.dashboardService.getInquiriesTimeline.subscribe(data => {
      if (this.trendChart) {
        // Show the loading label
        this.trendChart.showLoading('Loading drilldown data...');
        const myChart = this.trendChart;
        const me = this;
        const series = this.generateTimelineSeries(data);
        if (series) {
          this.showDrilldownChart(series);
        } else {
          console.log('skipping showing drilldown due to empty data');
        }
        this.trendChart.hideLoading();
      }
    });
    this.localService.getSelectedGroupName.subscribe(groupName => {
      // C153176-5105: check whether the requested group was already requested previously. If so, skip the request.
      let selectedGroup = "All";
      if (groupName !== "") {
        selectedGroup = groupName;
      }
      if (this.selectedGroup === selectedGroup) {
        // the group data was just requested, skip requesting again.
        return;
      }
      this.selectedGroup = selectedGroup;
      this.dashboardService.getInquiriesTrendCnts(this.selectedGroup);
    });
  }

  /**
   * Handle request type counts by group
   * @param jsonData jSON response from service call
   */
  private handleTrends(jsonData: string): boolean {
    if (!jsonData) {
      return false;
    }
    try {
      /* C153176-5430:To add filter for Inquiries and non inquiries */
      let chartJson: Object = JSON.parse(jsonData);
      if (chartJson.hasOwnProperty('inquiries')) {
        this.isNewResponse = true;
        let inquiriesTrends = chartJson[this.selectedOption];
        const inquiryTrend = inquiriesTrends.map((elem) => {
          return { date: elem.date, inquiryCount: elem.count };
        });
        inquiryTrend.reverse();
        this.chartData = inquiryTrend;
      }
      else {
        this.chartData = chartJson;
      }
    } catch (error) { }
    return this.updateChart(true);
  }

  protected updateChart(updateData: boolean): boolean {
    if (!this.chartData || !this.chartData.length) {
      return false;
    }
    const data = this.chartData;
    // get the highest count entry
    const highestCount = data.reduce((prev, cur) =>
      prev && prev.inquiryCount > cur.inquiryCount ? prev : cur);
    // get the lowest count entry
    const lowestCount = data.reduce((prev, cur) =>
      prev && prev.inquiryCount < cur.inquiryCount ? prev : cur);

    const tempDom = $.parseHTML(this.inquiriesByTrend.subtitle.text);
    $('.highestCount', tempDom).text((highestCount ? highestCount.inquiryCount : 0));
    $('.lowestCount', tempDom).text((lowestCount ? lowestCount.inquiryCount : 0));

    this.inquiriesByTrend.subtitle.text = $(tempDom).prop('outerHTML');
    /*  this.inquiriesByTrend.subtitle.text = this.inquiriesByTrend.subtitle.text
       .replace('${highestCount}', highestCount ? highestCount.inquiryCount : 0)
       .replace('${lowestCount}', lowestCount ? lowestCount.inquiryCount : 0); */
    // get list of sorted ageBands
    this.inquiriesByTrend.series[0].data = data.map((p) => {
      const time = new Date(p.date).getTime();
      this.minDate = (!this.minDate || this.minDate > time) ? time : this.minDate;
      this.maxDate = (!this.maxDate || this.maxDate < time) ? time : this.maxDate;
      return [time, p.inquiryCount];
    });
    return true;
  }

  switchToDrilldown(timestamp) {
    this.drilldownOn = true;
    const date = AppUtils.formatDate(new Date(timestamp));
    this.drilldownDate = date;
    this.selectedDate = AppUtils.formatDate(new Date(timestamp), true).substring(4);
    this.getDrilldownEvent(timestamp);
  }

  getDrilldownEvent(date) {
    this.dashboardService.getInquiriesTimelineCnts(date, this.selectedGroup, this.selectedOption);
    PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled, this.userDataService.loggedInUserInfo.userId, "Dashboard", "Inquiries Trend Click on line graph", "Click", "Inquiries Trend Click on line graph", 1);
  }

  generateTimelineSeries(data) {
    const date = this.drilldownDate ? this.drilldownDate : data.date;
    const categorySet = new Set();
    const groupMap = {};
    for (const t in data) {
      if (t === 'date') {
        continue;
      }
      categorySet.add(t);
      const sub = data[t];
      if (sub) {
        for (const g of Object.keys(sub)) {
          const v = sub[g];
          if (!groupMap[g]) {
            groupMap[g] = {};
          }
          if (!groupMap[g][t]) {
            groupMap[g][t] = v;
          }
        }
      }
    }
    const categories = Array.from(categorySet).sort((a: string, b: string) => {
      if (a.toLowerCase().includes("am") && b.toLowerCase().includes("pm")) {
        return -1;
      } else if (a.toLowerCase().includes("pm") && b.toLowerCase().includes("am")) {
        return 1;
      } else if ((a.toLowerCase().includes("pm") && b.toLowerCase().includes("pm")) ||
        (a.toLowerCase().includes("am") && b.toLowerCase().includes("am"))) {
        if (a[0] < b[0]) {
          return -1;
        } else if (a[0] === b[0]) {
          return 0;
        } else {
          return 1;
        }
      } else {
        if (a.toLowerCase().includes('other') && !b.toLowerCase().includes('other')) {
          return 1;
        } else if (!a.toLowerCase().includes('other') && b.toLowerCase().includes('other')) {
          return -1;
        } else {
          const taIndex = a.toLowerCase().indexOf('to');
          const tbIndex = b.toLowerCase().indexOf('to');
          const ta = taIndex !== -1 ? a.substring(0, taIndex) : a;
          const tb = tbIndex !== -1 ? b.substring(0, tbIndex) : b;
          if (parseInt(ta) === 4 && parseInt(ta) !== parseInt(b)) {
            return 1;
          } else {
            if (ta.length < tb.length) {
              return -1;
            } else if (parseInt(ta) < parseInt(tb)) {
              return -1;
            } else if (parseInt(ta) === parseInt(tb)) {
              return 0;
            } else {
              return 1;
            }
          }
        }
      }
    });

    let groups = Object.keys(groupMap).sort();
    groups = groups.sort((a, b) => {
      if (a.includes('lte') && !b.includes('lte')) {
        return -1;
      } else if (!a.includes('lte') && b.includes('lte')) {
        return 1;
      } else {
        const na = parseInt(a.slice(2));
        const nb = parseInt(b.slice(2));
        if (na < nb) {
          return -1;
        } else if (na === nb) {
          return 0;
        } else {
          return 1;
        }
      }
    });
    const series = [];
    groups.forEach((g, index) => {
      const subs = [];
      const timelines = groupMap[g];
      categories.forEach((t) => {
        const subV = [];
        const c = timelines[t];
        subV.push(this.convertTimeLineLabel(t));
        subV.push(c);
        subs.push(subV);
      });
      const convertedGroup = this.convertGroupLabel(g);
      series.push({ id: convertedGroup, name: convertedGroup, data: subs, color: this.inquiriesDrilldownColors[index] });
    });
    this.inquiriesByTrend.xAxis[0].labels.drilldown = categories.map(e => this.convertTimeLineLabel(e));
    return series;
  }

  convertGroupLabel(g) {
    if (!g) {
      return g;
    }
    if (g.includes('lte5')) {
      return '<=05 Days';
    } else if (g.includes('gt5')) {
      return '+5 Days';
    } else if (g.includes('gt15')) {
      return '+15 Days';
    } else if (g.includes('gt30')) {
      return '+30 Days';
    } else {
      return g;
    }
  }

  convertTimeLineLabel(t) {
    if (!t) {
      return t;
    }
    if (t.includes('8am')) {
      return '8am to 12pm';
    } else if (t.includes('12pm')) {
      return '12pm to 4pm';
    } else if (t.includes('4pm')) {
      return '4pm to 8pm';
    } else if (t.includes('4to8')) {
      return '4pm to 8pm';
    } else if (t.includes('8to12')) {
      return '8am to 12pm';
    } else if (t.includes('12to4')) {
      return '12pm to 4pm';
    } else {
      return t;
    }
  }

  showDrilldownChart(dSeries) {
    if (dSeries.length) {
      this.inquiriesDrillDownOptions.subtitle.text = '<span class="innerLabel" style="font-family:InterState;font-size:14px;">'
        + $('<div>').text(this.selectedDate).html() + '</span>';
      this.inquiriesDrillDownOptions.subtitle.align = 'left';
      this.inquiriesDrillDownOptions.subtitle.verticalAlign = 'top';
      this.inquiriesDrillDownOptions.subtitle.x = 200;
      this.inquiriesDrillDownOptions.subtitle.y = 10;
    } else {
      this.inquiriesDrillDownOptions.subtitle.text = '<span class="innerLabel" style="font-family:InterState;font-size:16px;">'
        + $('<div>').text('No timeline data available on ' + this.selectedDate + '.').html() + '</span>';
      this.inquiriesDrillDownOptions.subtitle.align = 'center';
      this.inquiriesDrillDownOptions.subtitle.verticalAlign = 'center';
      this.inquiriesDrillDownOptions.subtitle.x = undefined;
      this.inquiriesDrillDownOptions.subtitle.y = 100;
    }

    this.inquiriesDrillDownOptions.series = dSeries;
    const elem = $('#inquiries-by-trend-drilldown');
    if (elem && elem.length) {
      if (this.drilldownChart) {
        this.drilldownChart.destroy();
      }
      this.drilldownChart = HighCharts.chart('inquiries-by-trend-drilldown', this.inquiriesDrillDownOptions);
      if (this.drilldownChart) {
        const component = this;
        this.drilldownChart.renderer.image("./assets/dashboard/back-arrow.svg", 6, 6, 22, 22).attr({
          zIndex: 3
        }).on('click', () => {
          component.switchToTrendChart();
        }).add();

      }
      this.drilldownChart.options.ngComponent = this;
    }
  }

  switchToTrendChart() {
    this.drilldownOn = false;
  }

  // handle ESC in case of full screen
  @HostListener('document:keydown.esc', ['$event'])
  onKeydown(event) {
    event.stopPropagation();
    event.preventDefault();
    if (this.fullScreen && event && event.code === 'Escape') {
      this.exitFullScreen(this.trendChart);
    }
  }

  enterFullScreen(chart: any) {
    const elem = $('#trend-chart-box');
    if (elem && elem.length) {
      elem.toggleClass('col-sm-6');
      elem.toggleClass('dashboardModal');
    }
    this.fullScreen = true;
    if (chart.exportContextMenu && chart.exportContextMenu.childNodes) {
      chart.exportContextMenu.childNodes.forEach(node => {
        if (node && node.childNodes) {
          node.childNodes.forEach(n => {
            if (n && n.innerText === 'Maximize') {
              n.style.display = 'none';
            }
          });
        }
      });
    }
    chart.reflow();
    if (this.fadingDialogComponent) {
      this.fadingDialogComponent.show($('#inquiries-by-trend-box')[0]);
    }
  }

  exitFullScreen(chart: any) {
    if (!chart) {
      return;
    }
    const elem = $('#trend-chart-box');
    if (elem && elem.length) {
      elem.toggleClass('col-sm-6');
      elem.toggleClass('dashboardModal');
    }
    this.fullScreen = false;
    if (this.fadingDialogComponent) {
      this.fadingDialogComponent.hide($('#inquiries-by-trend-box')[0]);
    }
    if (chart.exportContextMenu && chart.exportContextMenu.childNodes) {
      chart.exportContextMenu.childNodes.forEach(node => {
        if (node && node.childNodes) {
          node.childNodes.forEach(n => {
            if (n && n.innerText === 'Maximize') {
              n.style.display = '';
            }
          });
        }
      });
    }
    chart.reflow();
  }

  setChartExtremes() {
    /*
    if (this.trendChart && this.minDate && this.maxDate) {
      let delta = Math.floor((this.maxDate - this.minDate) / 10);
      let endDate = this.minDate + delta;
      this.trendChart.xAxis[0].setExtremes(this.minDate.valueOf(), endDate.valueOf());
    }
    */
  }

  onOptSelection(evt: any) {
    console.log(evt);
    PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled, this.userDataService.loggedInUserInfo.userId, "Dashboard", "Inquiries Trend -Inquiry Type Filter- switch to " + evt.value, "Click", "Inquiries Trend -Inquiry Type Filter- switch to " + evt.value, 0);
    this.ngOnInit();
  }
}
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { QmaConstant } from "src/app/constant/qma-constant";
import { Observable } from "rxjs";
import { ReportDetails } from "src/app/model/report/report-detail";

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  })
};

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  groupURL: string;
  constructor(private http: HttpClient) {
    this.groupURL = QmaConstant.BASE_URL + '/group';
  }

  /**
   * Method to get the Report Data for MIS report.
   */
  getReportDataForMIS(): Observable<ReportDetails[]> {
    return this.http.post<ReportDetails[]>(`${this.groupURL}/getReportDataForMIS`,{}, httpOptions);
  }

  /**
   * Method to save the Report Data for MIS report.
   */
  saveReportDataForMIS(reportDetails: ReportDetails): Observable<ReportDetails[]> {
    return this.http.post<ReportDetails[]>(`${this.groupURL}/saveReportDataForMIS`, reportDetails, httpOptions);
  }

  /**
   * Method to download the MIS report.
   */
  downloadReportDataForMIS(fileDetails: any): Observable<any> {
    return this.http.post<any>(`${this.groupURL}/downloadReportDataForMIS`, fileDetails, httpOptions);
  }

  /**
   * Method to download the requested file when avaialble.
   * 
   * @param fileName - File name with extension
   * @param isDefaultFileNameUsed - true or false
   */
  downloadExportedFile(fileName: string, isDefaultFileNameUsed: boolean): void {
    const attachementUrl = QmaConstant.BASE_URL + '/inquiry/downloadExportedFile?filename=' + fileName + '&isDefaultFileNameUsed=' + isDefaultFileNameUsed;
    this.http.get(attachementUrl, { responseType: "blob" }).subscribe(result => {
      var newBlob = new Blob([result], { type: "application/octet-stream" });
      const nav = (window.navigator as any);
      if (nav && nav.msSaveOrOpenBlob) {
        nav.msSaveOrOpenBlob(newBlob);
        return;
      }
      const data = window.URL.createObjectURL(newBlob);
      let link = document.createElement('a');
      link.href = data;
      link.download = fileName;
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

      setTimeout(function () {
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    }, error => {
      console.log('error :', error);
    });
  }
}

import { Component } from '@angular/core';
import { ICellRendererAngularComp } from "@ag-grid-community/angular";

@Component({
    selector: 'floating-cell',
    template: `
        <div class="row">
            <span class="groupTitle"> {{getGrpHeader(node)}}</span>
            <span class="groupCount">{{node.allChildrenCount}} </span>
            <span class="groupInq"> Inquiries</span>

        </div>`,
    styles: [
        `
        .row {
            display: inline;
        }
        .groupLine{
            border-top: 3px #005AA1 solid;
            margin-top: 0;
            line-height: 0;
            width: 100%;
            display: inline-block;

        }
        .groupTitle{
            margin-right: 2%;
            font-size: 12px !important;
        }
        .groupCount{
            margin-right: 1%;
            margin-right: 1%;
            font-size: 15px;
            font-family: Interstate !important;
            color: #002D72;
        }
        .groupInq{
            margin-right: 1%;
            font-size: 14px;
            font-family: Interstate !important;
            color: #002D72;
        }

    `
    ]
})
export class GroupRowInnerRenderer implements ICellRendererAngularComp {

    public flagCodeImg: string;
    public flagCode: any;
    public node: any;

    agInit(params: any): void {
        this.node = params.node;

    }

    refresh(): boolean {
        return false;
    }
    /* C153176-3065 group by for unassigned*/
    getGrpHeader(node) {
        return node.field === "openUsers" || node.field === 'requestTypeStr' ? node.key ? node.key : "UnAssigned" : node.key;
    }
}
<div class="d-flex justify-content-end mb-2 mt-2 qma-text">
  <div class="d-flex flex-column justify-content-center align-item-center p-2">
    <a href="#" class="text-center">
      <img src="assets/core/layout/header/vertical_icon_selected.svg" alt="Vertical">
    </a>
    <span >Vertical</span>
  </div>
  <div class="d-flex flex-column justify-content-center align-item-center p-2">
    <a href="#" class="text-center">
      <img src="assets/core/layout/header/horizontal_icon_selected.svg" alt="Horizontal">
    </a>
    <span>Horizontal</span>
  </div>
  <div class="d-flex flex-column justify-content-center align-item-center mr-3 p-2">
    <a href="#" class="text-center">
      <img src="assets/core/layout/header/chat_icon_selected.svg" alt="Chat View">
    </a>
    <span>Chat View</span>
  </div>
</div>
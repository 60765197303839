<div class="audit-wrapper" *ngIf="auditCount > 0">
    <div class="d-flex justify-content-end menu-container">
    <div class="d-flex mb-3 mr-1">
        <div class="ml-auto audit-download" (click)="downloadAutitTrails()">
            <img [src]="'assets/Inbox/download-xls.svg'" alt="" style="width: 23px;
            height: 25px;">
        </div>
    </div>
    <!-- audit download as PDF -->
    <div class="d-flex mb-3">
        <div class="ml-auto audit-download" (click)="downloadAutitTrailsAsPDF()">
            <img [src]="'assets/Inbox/download-pdf.svg'" alt="" style="width: 23px;
            height: 25px;">
        </div>
    </div>
</div>
    <div class="audit-progress">
        <ul *ngIf="auditList.length>0">
            <li *ngFor="let audit of auditList">
                <em class="fa fa-circle"></em>
                <div class='audit-text'>
                    <span>{{audit.modDate | date:'dd MMM hh:mm a'}}</span>
                    <span title="{{audit.actionDetails}}" [ngClass]="{
                        'external': audit.isExternal,
                        'internal': audit.isInternal,
                        'other-details': !audit.isExternal && !audit.isInternal
                    }">
                        {{(audit.actionDetails.length>30) ? (audit.actionDetails | slice:0:30)+'...':(audit.actionDetails)}}
                    </span>
                    <span title="{{audit.actionBy}}">
                        {{(audit.actionBy.length>27) ? (audit.actionBy | slice:0:27)+'...':(audit.actionBy)}}
                    </span>
                    <span *ngIf="audit.isClientChaseEscalation">
                        {{audit.isClientChaseEscalation}}
                    </span>
                    <span *ngIf="audit.isConvCountEscalation">
                        {{audit.isConvCountEscalation}}
                    </span>
                    <span *ngIf="audit.isSubjectEscalation">
                        {{audit.isSubjectEscalation}}
                    </span>
                    <span *ngIf="audit.ispendingApprovalEscalation">
                        {{audit.ispendingApprovalEscalation}}
                    </span>
                </div>
            </li>
            <li>
                <em class="fa fa-circle"></em>
            </li>
        </ul>
    </div>
</div>
<div id="mailbox-statistics-box" class="input-holder" (swipeleft)="onSwipe('left')" (swiperight)="onSwipe('right')" data-mc-options='{ "touchAction": "pan-y" }'><!-- C153176-5178: Added attribute to fix scroll blocking issue on mailbox stats -->
    <div  id="mailbox-statistics" class="dashboardChart"></div>
    <fading-dialog [chartId]= "'mailboxStatisticsDialog'" #mailboxStatisticsDialog></fading-dialog>
    <div *ngIf="showInboxView">
        <inboxView-component [isDashboardInlineView]=true></inboxView-component>
    </div>
    <div class="carouselButtonsWrapper flex-row align-items-center justify-content-center" *ngIf="(mailboxStats.length>1)">
        <div *ngFor="let d of mailboxStats; let i = index" (click)="onCarouselClick(i)" class="carousel-btn" [ngClass]="{'active': selectedIndex == i}"></div>
    </div>
</div>

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from 'rxjs';
import { LinkedUsersForGroupList } from '../model/LinkedUsersForGroupList/LinkedUsersForGroupList';
import { QmaConstant } from "src/app/constant/qma-constant";

const httpFormPostOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};
@Injectable({
  providedIn: 'root'
})
export class AdvSearchService {
  public advSearchFormData: any = {
    content: '',
    subject: '',
    originator: '',
    startDt: '',
    endDt: '',
    attachment: ''
  };
  baseURL: string;
  linkedUsersForGroupList: LinkedUsersForGroupList[];
  
  constructor(private httpClient: HttpClient) {
    this.baseURL = QmaConstant.BASE_URL + '/group';
  }
  
  getAdvSearchDetails(requestObj): Observable<LinkedUsersForGroupList[]> {
    return this.httpClient.post<LinkedUsersForGroupList[]>(this.baseURL + '/getLinkedUsersForGroupList', requestObj, httpFormPostOptions);
  }
}

import { Component, OnInit, Output, EventEmitter, ViewChild, Input, OnChanges, ChangeDetectionStrategy } from '@angular/core';
import { UserDataService } from "src/app/services/user-data.service";
import { ReportList } from "src/app/model/LoginUserInfo/ReportList";
import  * as _ from 'underscore';
import { ReportDetails } from "src/app/model/report/report-detail";
import { ReportService } from "src/app/services/reports/report.service";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CheckboxListComponent } from 'src/app/common/component/checkbox-list/checkbox-list.component';
import { DatePipe } from "@angular/common";
import { PiwikProUtils } from 'src/app/common/utility/piwikProUtils';

interface dropDownItem {
  name: string,
  value: string,
  label: string
}

// Message 
const SUCCESS_MESSAGE: string = "Your report will be processed shortly!";

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit, OnChanges {

  // Reports Field
  assignedGroups: dropDownItem[] = [];
  reportTypeList: ReportList[] = [];
  requestReports: ReportDetails[] = [];
  @ViewChild(CheckboxListComponent, { static: true }) checkboxListComponent: any;

  // Report Reactive Form
  reportForm: FormGroup;
  groupList: dropDownItem[] = [];

  @Input() modalOpen:boolean;
  @Output() modalClosed = new EventEmitter();
  maxDate = new Date();
  minDate = new Date();

  //message
  errorMessage: string;
  successMessage: string;

  constructor(private fb: FormBuilder, private userService: UserDataService, private reportService: ReportService, private datePipe: DatePipe) {
    this.getReportRelatedDetails();
    this.minDate.setMonth(this.maxDate.getMonth() - 3);
    this.minDate.setDate(this.maxDate.getDate() + 1);
  }

  ngOnInit() {    

    // Initiate Report form
    let selReportType = this.reportTypeList[0];
    this.reportForm = this.fb.group({
      reportType: [selReportType, [Validators.required]],
      startDate: ['', [Validators.required]]
    });
  }

  ngOnChanges() {
    if(this.modalOpen){
      this.resetForm();
    }
  }

  /**
   * Method to get report details to populate the fields.
   */
  getReportRelatedDetails(): void {
    this.userService.LocalGetLoginUserInfo().subscribe(loginUserInfo => {

      // Populate Assigned Group Name
      loginUserInfo.myGroups.forEach(group => {
        let grp: dropDownItem = {
          name: group.groupName,
          value: group.groupName,
          label: group.groupName
        }
        this.assignedGroups.push(grp);
      });

      // Populate Report Type list
      this.reportTypeList = [...loginUserInfo.reportList];

      // Sort the report list array by name
      this.reportTypeList = _.sortBy(this.reportTypeList, function (i) { return i.key.toLowerCase(); });
    });
  }

  /**
   * Method to get requested report history.
   */
  getRequestedReportHistory(): void {
    this.reportService.getReportDataForMIS().subscribe(reportDetail => {
      this.requestReports = [...reportDetail];
    }, error => {
      throw new Error('Service issue while getting Reports History');
    });
    PiwikProUtils.trackJsonDataForEvent(this.userService.loggedInUserInfo.pivotProConfigEnabled,this.userService.loggedInUserInfo.userId, "Menu", "Inquiry Reports - Request History", "Click", "Inquiry Reports - Request History", 0);
  }

  /**
   * Method to request report.
   * @param reportDetail : Report criteria.
   */
  onRequestReport(requestReport: FormGroup): void {
    const details = requestReport.value;
    const groupList = this.groupList.map(group => group.name);
    const selReportType = details.reportType && details.reportType.key ? details.reportType.key : this.reportTypeList[0].key;
    const strtDate = details.startDate ? details.startDate[0] : details.startDate;
    const endDate = details.startDate ? details.startDate[1] : details.startDate;

    let reportDetail: ReportDetails = {
      reportType: selReportType,
      startDate: strtDate,
      endDate: endDate,
      groupList: groupList
    };

    if (this.validateRequest(reportDetail)) {
      this.reportService.saveReportDataForMIS(reportDetail).subscribe(reports => {
        if (reports) {
          this.requestReports = [...reports];
          this.showMessage();
        }
      }, error => {
        throw new Error("Internal Address Data server call failed with the message:" + error);
      });
    }
    PiwikProUtils.trackJsonDataForEvent(this.userService.loggedInUserInfo.pivotProConfigEnabled,this.userService.loggedInUserInfo.userId, "Menu", "REPORTS - Inquiry reports request", "Click", "REPORTS - Inquiry reports request", 0);
  }

  /**
   * Method to validate report request. It returns true is request is valid
   * @param reportDetail 
   */
  validateRequest(reportDetail: ReportDetails): boolean {
    this.errorMessage = '';
    if (reportDetail) {
      if (reportDetail.startDate == null) {
        this.errorMessage = 'Enter Valid Date.';
      } else if (reportDetail.endDate == null) {
        this.errorMessage = 'Enter End Date.';
      } else if (reportDetail.groupList.length == 0) {
        this.errorMessage = 'Please select atleast one group.';
      }
    }
    let isValid = this.errorMessage ? false : true;
    return isValid;
  }

   /**
    * 
    * @param reportForm: Report Form instance
    */
  clearAndClose(reportForm:FormGroup): void { 
    this.resetForm();
    this.modalClosed.emit();
    PiwikProUtils.trackJsonDataForEvent(this.userService.loggedInUserInfo.pivotProConfigEnabled,this.userService.loggedInUserInfo.userId, "Menu", "REQUEST MIS REPORT - Cancel", "Click", "REQUEST MIS REPORT - Cancel", 0);
  }

  /**
   * Method to get the selected group.
   * @param group 
   */
  getAssignedGroups(group: any): void {
    this.groupList = [...group];
  }

  onTabSelected(event: any): void {
    console.log(event);
    if(event.index == 1){
      this.getRequestedReportHistory();
    }
  }

  resetForm(){
    this.reportForm.reset();
    this.checkboxListComponent.clearFilterText();
    this.checkboxListComponent.resetCheckList();
    this.errorMessage = '';
    this.successMessage = '';
  }

  /**
   * Method to create the report details on tooltip.
   * @param report 
   */
  createTooltip(report: ReportDetails) {
    const startDate = this.datePipe.transform(report.startDate, 'dd/MM/yyyy');
    const endDate = this.datePipe.transform(report.endDate, 'dd/MM/yyyy');
    return `<div class="d-flex flex-column p-2"><div><div>REPORT CRITERIA</div></div>
      <div>
        <div class="tooltip-content-grid">
          <label for="">Report type</label>
          <div>${report.reportType}</div>
        </div>
        <div class="tooltip-content-grid">
          <label for="">Groups</label>
          <div>${report.groupList}</div>
        </div>
        <div class="tooltip-content-grid">
          <label for="">Start date</label>
          <div>${startDate}</div>
        </div>
        <div class="tooltip-content-grid">
          <label for="">End date</label>
          <div>${endDate}</div>
        </div>
      </div>
    </div>`
  }

  /**
   * Method to download the report
   * @param report 
   */
  onDownload(report: ReportDetails): void {
    const fileDetails = { fileName: report.fileName };    
    this.reportService.downloadReportDataForMIS(fileDetails).subscribe(details => {
      if (details) {
        console.log('Report is going to download');
        this.reportService.downloadExportedFile(fileDetails.fileName, false);
      }
    }, error => {
      throw new Error("Internal Address Data server call failed with the message:" + error);
    });
    PiwikProUtils.trackJsonDataForEvent(this.userService.loggedInUserInfo.pivotProConfigEnabled,this.userService.loggedInUserInfo.userId, "Menu", "REPORTS - Inquiry Reports - Download", "Click", "REPORTS - Inquiry Reports - Download", 0);
  }

  /**
   * Method to show template message for few seconds.
   * 
   * @param name - template Name
   * @param oper - Operation = Delete - flase, New/Modify - true
   */
  showMessage(): void {
    this.successMessage = SUCCESS_MESSAGE;
    this.errorMessage = '';
    setTimeout(() => {
      this.successMessage = '';
    }, 4000);
  }
}

import { LeftNavigationService } from 'src/app/core/leftnav/leftnav.service';
import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { InboxService } from "src/app/services/inbox.service";
import { UserDataService } from '../../services/user-data.service';
import { DatePipe } from '@angular/common';
import { QmaConstant } from "src/app/constant/qma-constant";
import { DateFormatPipe } from 'src/app/common/pipes/date-format.pipe';
import { LoginUserInfo } from 'src/app/model/LoginUserInfo/LoginUserInfo';
import * as $ from 'jquery';
import { DomSanitizer } from '@angular/platform-browser';
import { isArray } from 'highcharts';
import { AppUtils } from 'src/app/common/utility/appUtil';
import  * as _ from 'underscore';
@Component({
  selector: 'app-nlp-view',
  templateUrl: './nlp-view.component.html',
  styleUrls: ['./nlp-view.component.scss'],
  providers: [DatePipe]
})
export class NlpViewComponent implements OnInit {

  suggestions: string;
  guserPrefDateFormat: string = '';
  lastUpdatedDate: Date = new Date();
  @ViewChild('refreshSuggestion', { static: true }) refreshSuggestion: ElementRef;
  @ViewChild('acceptConvSuggestionsId', { static: true }) acceptConvSuggestionsId: ElementRef;
  @ViewChild('declineConvSuggestionsId', { static: true }) declineConvSuggestionsId: ElementRef;
  @ViewChild('addLabel', { static: true }) addLabel: ElementRef
  inquiryStatus: string = '';
  inquiryId: number;
  convId: number;
  groupId: number;
  @Output() replyAll = new EventEmitter();
  @Output() msgGrowl = new EventEmitter();
  @Output() closeSuggestion = new EventEmitter(); // Jira : 4912 : NLP Suggestion box
  refreshBtn = "";
  addBtn = "";
  dateFormatPipe: DateFormatPipe;

  // C153176-6021 | Rejection Reason
  rejectionReason: string = '';
  rejectReasons: any[] = [];
  displayReason: boolean = false;
  canEnterReason: boolean = false;

  asssignedGroupName: string;
  @Output() noSuggestion = new EventEmitter();
  @Output() nodeAdded = new EventEmitter();

  intermediateColumnsData = ['Age', 'Ops Trd Ref', 'Con Qty', 'Isin Code', 'Trd Dt', 'Con_Setl_Dt','Con Amt','Status','Amount',
'Buy Sell','Setl Ccy','Fail Rsn Desc','Gp Number','Fail Rsn Cd','Ops Setl Trd Ref','Sdi Line 1','Sdi Line 2','Sdi Line 3','Sdi Line 4',
'Agt Nm'];
  intermediateDataResponse: any = {};
  replyComment = "";
  showReplyComment:boolean = false;
  showIntermediateDataFlg: boolean = true;
  trade_details : any;
  trade_details_table:any;
  trade_validation_details_table:any;
  isExtAuoSuggetionSelected = true;
  isTradeDetailsSelected = true;
  @ViewChild('tradeDetailsTable')tradeDetailsTable: ElementRef;
  showTradeTableDataFlg = true;
  showFeedbackPanel = false;
  suggetionsArray : any;
  @ViewChild('feedbackHtml') feedbackHtmlElementRef: ElementRef;
  @ViewChild('NlpFeedbackDropdownList') opNlpFeedbackDropdownList: any;
  userFeedback = {
    groupId:  0,
    userId: '',
    isValidResponse: true,
    comments: "",
    userProvidedMatch: {},
    inquiryId:0
  };
  objUserRejectionReason = {
    userProvidedMatch: {},
    txtUserRejectionReason: ''
  };
  userFeedbackArray = [];
  feedbackPlaceholder = "let us know why?";
  adequteResponse = true;
  txtUserComments = "";
  // split variables
  splitdirection = 'horizontal';
  splitAreaTradeDefaultSize = 50;
  splitAreaValidationDefaultSize = 50;
  @ViewChild('asSplitAreaTradeDetails') asSplitAreaTradeDetailsEl: ElementRef;
  @ViewChild('asSplitAreaTradeValidation') asSplitAreaTradeValidationEl: ElementRef;
  isAnyRowSelected = true;
  openNLPRejectionPanel = false;
  showNlpFeedbackOtherOptions = true;
  arrayHtmlDropdownIndex = [];
  suggetionStatus = "";
  columnDefs_trade_details_table = [{field:'Status',headerName:'Status',menuTabs: ['filterMenuTab'],
  filter: 'agTextColumnFilter',width:150, checkboxSelection:false,
  headerCheckboxSelection:false,sortable:true,headerCheckboxSelectionFilteredOnly:true, tooltipField:'Status',resizable: true},{field:'Ops Trd Ref',headerName:'Ops Trd Ref',menuTabs: [],
  filter: 'agTextColumnFilter',width:160, checkboxSelection:true,
  headerCheckboxSelection:true,sortable:true,headerCheckboxSelectionFilteredOnly:true, tooltipField:'Ops Trd Ref', resizable: true},
  ];
  columnDefs_trade_validation_details_table = [
        {field:'Status',headerName:'Status',menuTabs: ['filterMenuTab'],filter: 'agTextColumnFilter',
        width:150,tooltipField:'Status',resizable: true},
        {field:'GP_NAME',tooltipField:'GP_NAME',headerName:'GP Name',width:150,
         menuTabs: ['filterMenuTab'],filter: 'agTextColumnFilter', resizable: true},
        {field:'GP Match',tooltipField:'GP Match',headerName:'GP Match', menuTabs: [], resizable: true},
        {field:'Alert Acronym',tooltipField:'Alert Acronym',headerName:'Alert Acronym',menuTabs: [],width:100, resizable: true},
        {field:'Acct Nm',tooltipField:'Acct Nm',headerName:'Account Name',menuTabs: [],width:100, resizable: true},
        {field:'Clnt Mnem',tooltipField:'Clnt Mnem', headerName:'Client Mnem',menuTabs: [],width:100, resizable: true},
        {field:'Gp Number',tooltipField:'Gp Number',headerName:'Gp Number',menuTabs: [],width:100, resizable: true},
        {field:'Ctp Serv Loc',tooltipField:'Ctp Serv Loc',headerName:'CPS Location',menuTabs: [],width:100, resizable: true},
        {field:'Firm Acct Mnem',tooltipField:'Firm Acct Mnem',headerName:'Firm Acct Mnem',menuTabs: [],width:120, resizable: true},
        {field:'Bus Area',tooltipField:'Bus Area',headerName:'Bus Area',menuTabs: [],width:100, resizable: true}

  ]
  rowData_trade_details;any;
  rowData_trade_validation_details_table:any;
  gridApiTradeDetail:any;
  showNlpPopup = false;
  gridApiTradeValidation:any;
  nlpRefreshRequestColumns = [];
  QMA_TRADE_DETAIL_HIDDEN_COLUMNS = [];
  constructor(private inboxService: InboxService, private userDataService: UserDataService, private datePipe: DatePipe,
    private sanitizer: DomSanitizer) {
    this.dateFormatPipe = new DateFormatPipe();
  }

  ngOnInit() {
    this.guserPrefDateFormat = this.userDataService.getDateFormate();
    this.rejectReasons = QmaConstant.REJECTION_REASON;
    this.nlpRefreshRequestColumns = this.userDataService && this.userDataService.loggedInUserInfo && 
                                     this.userDataService.loggedInUserInfo.qmaUI2Config && 
                                    this.userDataService.loggedInUserInfo.qmaUI2Config.nlpRefreshRequestColumns ?
                                    this.userDataService.loggedInUserInfo.qmaUI2Config.nlpRefreshRequestColumns:QmaConstant.QMA_NLP_nlpRefreshRequestColumns;
    this.QMA_TRADE_DETAIL_HIDDEN_COLUMNS = this.userDataService && this.userDataService.loggedInUserInfo && 
                                          this.userDataService.loggedInUserInfo.qmaUI2Config && 
                                          this.userDataService.loggedInUserInfo.qmaUI2Config.QMA_TRADE_DETAIL_HIDDEN_COLUMNS?
                                          this.userDataService.loggedInUserInfo.qmaUI2Config.QMA_TRADE_DETAIL_HIDDEN_COLUMNS:QmaConstant.QMA_TRADE_DETAIL_HIDDEN_COLUMNS;

  }

  showConvSuggestion(data, status, inquiryStatus) {
    try {
    this.showNlpPopup = false;
      this.suggetionStatus = status;
      this.showFeedbackPanel = false;
      this.displayReason = false;
      this.canEnterReason = false;
      this.rejectionReason = '';
      this.rejectReasons = QmaConstant.REJECTION_REASON;
      this.showIntermediateDataFlg = false;

      this.refreshBtn = "assets/Inbox/refresh-icon-active.svg"; // C153176-6082
      this.addBtn = "assets/newMessage/add-blue.svg"; // C153176-6082
      if (this.refreshSuggestion) {
        this.refreshSuggestion.nativeElement.style.cursor = "pointer";
        this.refreshSuggestion.nativeElement.style.color = "dodgerblue";
        this.refreshSuggestion.nativeElement.style.pointerEvents = "initial";
      }
      if (this.acceptConvSuggestionsId) {
        this.acceptConvSuggestionsId.nativeElement.style.pointerEvents = "initial";
        this.acceptConvSuggestionsId.nativeElement.disabled = false;
        this.acceptConvSuggestionsId.nativeElement.style.color = "white";
      }
      if (this.declineConvSuggestionsId) {
        this.declineConvSuggestionsId.nativeElement.style.pointerEvents = "initial";
        this.declineConvSuggestionsId.nativeElement.disabled = false;
        this.declineConvSuggestionsId.nativeElement.style.color = "white";
      }
      this.inquiryId = data.inquiryId;
      this.convId = data.convId;
      this.groupId = data.groupId;
      this.inquiryStatus = inquiryStatus;
      this.asssignedGroupName = data.groupName;

      this.inboxService.fetchConvSuggestions(data).subscribe(response => {
        this.showNlpPopup = true;
        // response = this.getMockData();
        // convert from string to JSON
        if(response.tradesInfo) {
          response = this.convertStringToJSON(response);
        }

        
        // TODO get it from API
         // response.receivedAt = {$date:Date.now()};

       if (!this.isNull(response)) {
        // redesign change
        if(response && response.tradesInfo) {
          this.getNLPSuggetionsFromResponse(response , response.tradesInfo .queryResults);
        }
         // this.mergeSuggestedReply(response , response.data.tradesInfo.queryResults);
          console.log('result of NLP suggestions', response);
          let lastUpdatedDate = "Not Available";
          if (!this.isNull(response) && response.tradesInfo) {
            this.prepareIntermediateData(response, status);
          }
          if (!this.isNull(response.receivedAt) && !this.isNull(this.guserPrefDateFormat)) {
            // TODO remove mock
            let dt = response.receivedAt.$date;
            lastUpdatedDate = this.dateFormatPipe.transform(dt, this.guserPrefDateFormat);
          }
          if (response && response.tradesInfo && !this.isNull(response.tradesInfo.queryResults)) {
            this.showSuggestionPopup(response.suggestions, lastUpdatedDate, status, false, response.suggestedResponseTradeDetails,response);
          }
          else {
            if (status === "red") {
              this.showSuggestionPopup(response.message, lastUpdatedDate, status, false, response.suggestedResponseTradeDetails,response);
            }
            else if (status === "orange") {
              this.showSuggestionPopup(response.message, lastUpdatedDate, status, true, response.suggestedResponseTradeDetails,response);
            }
            else {
              window.document.body.click();
              let noSuggesstion = {
                contentNotAvlbelMsg: 'Conversation suggestion not available.',
                displayError: true
              }
              this.noSuggestion.emit(noSuggesstion);
            }
          }
        /*   if (this.showFeedbackPanel && !Array.isArray(this.suggetionsArray)) {
            this.applyTaggingInNLPResponse();
        } */
        if (!Array.isArray(this.suggetionsArray)) {
          this.applyTaggingInNLPResponse();
      }
        }
      },
        (error) => {
          console.log('Service Failure while fetching suggestions', error);
        }
      );
    } catch (e) {
      console.error("Exception in QMA 2 while showConvSuggestion() : ", e);
    }
  }

  showSuggestionPopup(suggestions, receivedAt, status, refreshDisabled,suggestedResponseTradeDetails,response) {
    this.lastUpdatedDate = receivedAt;
    this.suggestions =  suggestions;
    this.updateSuggestionFields(suggestions, receivedAt, status, refreshDisabled);
    this.trade_details = response.suggestedResponseTradeDetails ? response.suggestedResponseTradeDetails : [];
    let objDetailsTable = this.createTradeDetailsTableFromJSON(response.suggestedResponseTradeDetails);
    this.trade_details_table = objDetailsTable?objDetailsTable.details_table:this.trade_details_table;
    this.trade_validation_details_table = objDetailsTable ? objDetailsTable.trade_validation_details_table:this.trade_validation_details_table;
    setTimeout(() => {
      this.hideEmptyCols("tradeDetailsTblDisplay");
      this.hideEmptyCols("tradeDetailsTblToExport");
      this.hideEmptyCols("tradeValidationDetailsTbl");
      this.calculateHeightForSplitGutter();
    }, 0);
  }

  updateSuggestionFields(suggestions, receivedAt, status, refreshDisabled) {
    if (!this.isNull(refreshDisabled) && refreshDisabled && this.refreshSuggestion) {
      this.refreshSuggestion.nativeElement.style.pointerEvents = "none";
      this.refreshBtn = "assets/Inbox/refresh-icon-active.svg"; // C153176-6082
    }
    if ((!this.isNull(status) && status == "red" && this.refreshSuggestion && this.declineConvSuggestionsId) || (!this.isNull(refreshDisabled) && refreshDisabled && this.refreshSuggestion && this.declineConvSuggestionsId)) {
      this.acceptConvSuggestionsId.nativeElement.style.pointerEvents = "none";
      this.addBtn = "assets/newMessage/add-blue-disabled.svg"; // C153176-6082
      this.addLabel.nativeElement.style.color = "grey";
      this.acceptConvSuggestionsId.nativeElement.disabled = true;
      this.declineConvSuggestionsId.nativeElement.style.pointerEvents = "none";
      this.declineConvSuggestionsId.nativeElement.style.color = "grey";
      this.declineConvSuggestionsId.nativeElement.disabled = true;
    }
    if (this.inquiryStatus === QmaConstant.inquiryStatusEnum.RESOLVED && this.refreshSuggestion && this.declineConvSuggestionsId) {
      this.refreshSuggestion.nativeElement.style.pointerEvents = "none";
      this.refreshBtn = "assets/Inbox/refresh-icon.svg"; // C153176-6082
      this.acceptConvSuggestionsId.nativeElement.style.pointerEvents = "none";
      this.addBtn = "assets/newMessage/add-blue-disabled.svg"; // C153176-6082
      this.addLabel.nativeElement.style.color = "grey";
      this.acceptConvSuggestionsId.nativeElement.disabled = true;
      this.declineConvSuggestionsId.nativeElement.style.pointerEvents = "none";
      this.declineConvSuggestionsId.nativeElement.style.color = "grey";
      this.declineConvSuggestionsId.nativeElement.disabled = true;
    }
  }

  refreshSuggestions() {
    try {
      this.displayReason = false;
      this.canEnterReason = false;
      if (!this.isNull(this.convId) && !this.isNull(this.convId) && !this.isNull(this.inquiryId) && !this.isNull(this.groupId)) {
       // let tradeRefList = this.trade_details.flatMap((td)=>[td['Ops Trd Ref'],td['Ops Setl Trd Ref']]); 
       let tradeRefList = this.getTradeRefListToRefresh();       ;
        const postData = {
          inquiryId: this.inquiryId,
          convId: this.convId,
          groupId: this.groupId,
          action: "refresh",
          tradeRefList:tradeRefList 
        };
        this.inboxService.refreshSuggestions(postData).subscribe(response => {
          if (!this.isNull(response)) {
            console.log('result of refresh suggestions', response);
            if (!this.isNull(response) && !this.isNull(response.success) && response.success) {
              this.onCallCompleted('success', response.message);
            } else {
              this.onCallCompleted('error', 'Error while sending refresh suggestion request');
            }
          }
        },
          (error) => {
            this.onCallCompleted('error', 'Error while sending refresh suggestion request');
          }
        );
      }
      // Jira : 4912 : NLP Suggestion box
      this.closeSuggestion.emit(false);
    } catch (e) {
      console.error("Exception in QMA 2 while refreshSuggestions() : ", e);
    }
  }

  // Null value check function
  isNull(input) {
    if (input === "" || input === undefined || input == null) {
      return true;
    }
    return false;
  }
  isObjectEmpty(obj) {
    return obj && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype;
  }

  updateConvSuggstions(action) {
    try {
      if (!this.isNull(this.suggestions)) {
        // C153176-4459 : Add start and end tags to determine nlp suggestion.
        let nlpSuggestion = "";
        if (this.isExtAuoSuggetionSelected) {
          nlpSuggestion = this.suggestions;
        }
        if (this.isTradeDetailsSelected) {
          // Add trade details table here
          console.log(this.tradeDetailsTable);
          if (this.tradeDetailsTable && this.tradeDetailsTable.nativeElement && this.tradeDetailsTable.nativeElement.innerHTML) {
            // Add close icon to header
            let $content1 = $( ".tradeDetailsTblExport" ).clone();
            $content1 = $('<div></div>').append(this.tradeDetailsTable.nativeElement.innerHTML);
            $($content1.find('table >thead > tr > td').each(function () {
              $(this).append(QmaConstant.NLP_DELETE_SPAN_REDESIGNED);
            }));
           /*  nlpSuggestion = nlpSuggestion + $content[0].innerHTML; */
           // Add close Icon Header
            $($content1.find('table > thead > tr > td:first').each(function () {
              $(this).before(`<td  style="white-space: nowrap;
            color: white;
            text-align: center;background: #021E4A;border: 1px solid #C4C8CE;padding: 10px;font-size: 12px;
           font-family: 'Interstate';position: relative;">
               </td>`);
            }));
           // Add Close icon on each row
          //  table > tbody > tr > td:first
            $($content1.find('table > tbody > tr').each(function () {
              $(this).find('td:first').before(`<td style="white-space: nowrap;white-space: nowrap;
              /* background: #021E4A; */
              color: white;
              text-align: center;
              color: #414748;border: 1px solid #C4C8CE;padding: 5px;font-size: 12px;
              font-family: 'Interstate';background-color: white">
             <span class="deleteRowNlp" style="cursor: pointer;">x</span>
              </td>`);
            }));
            nlpSuggestion = nlpSuggestion +$content1.html();

          }
        }
        let nlpSuggestionWithStartEndTag = nlpSuggestion.replace('<html>', QmaConstant.START_NLP_SUGGESTION).replace('</html>', QmaConstant.END_NLP_SUGGESTION);
        nlpSuggestionWithStartEndTag = '<div id = "customText-nlp"><br></div><div id="convSuggestionsId" style="font-size: 12px; color: #0F1010;">' + nlpSuggestionWithStartEndTag + '</div>'
        let $content = $('<div></div>').append(nlpSuggestionWithStartEndTag);
        $($content[0]).find('#convSuggestionsId  p').each(function () {
          $(this).css({ "color": "#0F1010", "font-family": "Interstate", })
        })
        // C170665-124 | NLP response template update in QMA
        if (nlpSuggestionWithStartEndTag.indexOf('<table') > -1) {
          // Apply Backgroud and add cross icon to delete column.
          $($content[0]).find('tbody:first > tr').attr("bgcolor", "#002d72").css({ "background-color": "#002d72", "color": "white", });
          $($content[0]).find('tbody:first > tr > th').each(function () {
            $(this).append(QmaConstant.NLP_DELETE_SPAN);
          });

          // Apply font css to rows.
          $($content[0]).find('tbody[id*="trades"] > tr').each(function () {
            $($($content[0]).find('tbody[id*="trades"] tr > th')).each(function () {
              $(this).css({ "font-weight": "normal", });
            });
          });

        }
        nlpSuggestionWithStartEndTag = $content[0].innerHTML;
        // C153176-6021 | Add the rejection reason as notes to inquiry.
        const inquiryIds = [];
        inquiryIds.push(this.inquiryId);

        const postData = {
          inquiryId: this.inquiryId,
          convId: this.convId,
          groupId: this.groupId,
          action: action,
          suggestionValue: nlpSuggestionWithStartEndTag,
          inquiryIds: inquiryIds,//  C153176-6021 | Required for Add rejection reason as notes.
          userNote: this.rejectionReason, // C153176-6021 | Rejection Reason.
          category: this.rejectionReason,
         /*  userRejectionReason : {
            userProvidedMatch: {},
            txtUserRejectionReason: ''
          } */
        };

        if (action == 'accept') {
          this.displayReason = false;
          this.canEnterReason = false;
          this.acceptSuggestions(postData);
        }
        else if (action == 'decline') {
          this.canEnterReason = false;
          if (!this.displayReason) {
            this.toggleNLPRejectionPanel(true);
          } else {
            this.declineSuggestions(postData);
          }
        }
      }
    } catch (e) {
      console.error("Exception in QMA 2 while updateConvSuggstions() : ", e);
    }
  }

  declineSuggestions(postData) {
    try {
      // add tagging feedback
      if (!this.isObjectEmpty(this.userFeedback.userProvidedMatch)) {
        postData.actionDetails = this.userFeedback.userProvidedMatch;
        postData.actionDetails =  {
          userEntityTags: this.userFeedback.userProvidedMatch
        };
      }
      this.inboxService.declineSuggestions(postData).subscribe(response => {
        if (!this.isNull(response)) {
          console.log('result of decline suggestions', response);
          if (!this.isNull(response) && !this.isNull(response.success) && response.success) {
            this.onCallCompleted('success', response.message, postData);
          } else {
            this.onCallCompleted('error', 'Error while declining suggestions');
          }
        }
      },
        (error) => {
          this.onCallCompleted('error', 'Service Failure : Error while declining suggestions');
        }
      );
      // Jira : 4912 : NLP Suggestion box
      this.closeSuggestion.emit(false);
    } catch (e) {
      console.error("Exception in QMA 2 while declineSuggestions() : ", e);
    }
  }

  acceptSuggestions(postData) {
    this.replyAll.emit(postData);
    this.closeSuggestion.emit(false); // Jira : 4912 : NLP Suggestion box
  }


  /**
   * Methodto call whenReject reason is selected.
   *
   * @param event
   */
  onReasonChange(event) {
    this.canEnterReason = false;
    this.rejectionReason = ''
    let selectedReason = event.value;
    if ('none' !== selectedReason.value && 'others' !== selectedReason.value) {
      this.rejectionReason = selectedReason.label;
    }
    if ('others' == selectedReason.value) {
      this.canEnterReason = true;
    }
    if (selectedReason.label === "NLP suggestion not pulled for all trade references." || selectedReason.label === "NLP Suggestion outdated e.g. cancelled trade status etc") {
       this.showFeedbackPanel = true;
    } else {
      this.showFeedbackPanel = false;
    }
  }

  /**
   * Method to call when any service call completed.
   *
   * @param severity
   * @param detail
   * @param postData
   */
  onCallCompleted(severity: string, detail: string, postData?: any): void {
    let msgGrowlData = { severity: severity, summary: '', detail: detail };

    // If the rejection reason is added, We need to update the notes.
    if (postData && postData.userNote) {
      let currentDate = new Date();
      let userInfo: LoginUserInfo = this.userDataService.getUserOtherPrefs();
      let userNote = {
        commentDate: {
          $date: currentDate.getTime()
        },
        comments: postData.userNote,
        userId: userInfo.userId,
        userName: userInfo.userName,
        inquiryId: this.inquiryId,
        assignedGroupName: this.asssignedGroupName,
        origin: 'nlp'
      };
      this.nodeAdded.emit(userNote);
    }
    window.document.body.click();
    this.msgGrowl.emit(msgGrowlData);
  }
  prepareIntermediateData(response, status) {
    switch(status){
      case "green":
        {
          if(response && response.tradesInfo && response.tradesInfo.queryResults) {
            this.prepareNlpQueryData(response.tradesInfo.queryResults);
          }

        }
        break;
      case "red":
        this.prepareNlpQueryData(response);
        this.replyComment = response.replyComment;
        this.showReplyComment = this.replyComment?true:false;
        break;
      case "orange": {
        if(response && response.tradesInfo && response.tradesInfo.queryResults) {
          this.prepareNlpQueryData(response.tradesInfo.queryResults);
        }
      }
        break;
    }

  }

  private getNlpIntermediateData(response: any) {
    try{
      if (response.intermediateData) {
        let intermediateData = JSON.parse(response.intermediateData);
        if (intermediateData && intermediateData.clc && intermediateData.clc[0] && intermediateData.clc[0].responses) {
          let nlpIntermediateResponse: Array<string> = intermediateData.clc[0].responses;
          if (nlpIntermediateResponse) {
            let responseList: Array<object> = new Array<object>();
            nlpIntermediateResponse.forEach(item => {
              let obj = JSON.parse(item);
              responseList.push(obj);
            });

            let rowData: Array<any> = new Array<any>();
            responseList.forEach(item => {
              for (let i = 0; i < this.intermediateColumnsData.length; i++) {
                let key = this.intermediateColumnsData[i];
                let value = item[key];
                rowData.push(value);
              }
            });
            this.intermediateDataResponse = {
              columns: this.intermediateColumnsData,
              rows: rowData
            };
            this.processNlpRequestParams();
          }
        }
      }
    } catch(e){
      console.error("Exception in getNlpIntermediateData",e);
    }
  }

  private prepareNlpQueryData(queryResults: any) {
    try{
      
        if (queryResults && queryResults.length>0) {

          let queryColumns: Array<string> = [];
          let queryRows: Array<Map<string,string>> = [];
          queryResults.forEach(queryResult=>{
          let isSugestionAvailable = queryResult[1] && queryResult[1].length>0?'y':'n';
          if(queryResult && queryResult[0].length>0) {
            let row: Map<string,string> = new Map();
            queryResult[0].forEach(filterItem=>{
              if(!queryColumns.includes(filterItem['id'])){
                queryColumns.push(filterItem['id']);
              }
                row.set(filterItem['id'],filterItem['value']);
            });
            row.set('isSugestionAvailable', isSugestionAvailable);
            queryRows.push(row);
          }

           });
          this.intermediateDataResponse = {
            columns: queryColumns,
            rows: queryRows
          };
          this.processNlpRequestParams();
        }

    } catch(e){
      console.error("Exception in getNlpIntermediateData",e);
    }
  }

  showIntermediateDetails(){
    this.showIntermediateDataFlg = !this.showIntermediateDataFlg;
  }

  createTradeDetailsTableFromJSON(trade_details) {
    let details_table = [];
    let trade_validation_details_table = [];
    if (!trade_details || trade_details.count || trade_details.count > 0) {
        return;
    }
    trade_details.forEach((td: any) => {
      let row_header_value_arr = [];
      let row_header_value_trade_validation_arr = [];
      for (const [key, value] of Object.entries(td)) {
        //console.log(`${key}: ${value}`);
        if (!QmaConstant.NLP_TRADE_VALIDATION_FIELDS.includes(key)) {
          if(key.toLowerCase() !== "suggested reply"&& !this.QMA_TRADE_DETAIL_HIDDEN_COLUMNS.includes(key)) {
            row_header_value_arr.push({header:key , value:value, isROwVisible: false});
          }

        } else {
          const headerName = QmaConstant.NLP_TRADE_VALIDATION_FIELDS_MAPPING.find(k => k.apiField === key) ?
                      QmaConstant.NLP_TRADE_VALIDATION_FIELDS_MAPPING.find(k => k.apiField === key).displayHeader: key;
          row_header_value_trade_validation_arr.push({header:headerName,value:value});
        }
      }
      details_table.push(row_header_value_arr);
      trade_validation_details_table.push(row_header_value_trade_validation_arr);
      row_header_value_arr = [];
      row_header_value_trade_validation_arr = [];
    });
   // return details_table;
   return {details_table:details_table,trade_validation_details_table:trade_validation_details_table};
  }

  chkboxExtSuggChanged() {
    this.isExtAuoSuggetionSelected = !this.isExtAuoSuggetionSelected;
  }
  checkboxIsTradeDetailsSelected(event) {
    this.isTradeDetailsSelected = !this.isTradeDetailsSelected;
    this.onTradeDetailRowSelectionChanged(event, null, 0, true);
  }
  // Keep rows which generated message(remove others) if one or more green if no green keep all rows.
  processNlpRequestParams() {
    let isSuggetionAvailable = false;
    this.intermediateDataResponse.rows.forEach((row: any) => {
      if (row.get("isSugestionAvailable") === "y") {
        isSuggetionAvailable = true;
      }
    });
     // if one green remove other i.e non green
    if (isSuggetionAvailable) {
      this.intermediateDataResponse.rows.forEach((row: any,index) => {
        if (row.get("isSugestionAvailable") !== "y") {
          this.intermediateDataResponse.rows.splice(index, 1);
        }
      });
    }


  }
  showTradeDetails() {
    this.showTradeTableDataFlg = !this.showTradeTableDataFlg;
  }

  onNlpFeedbackClick() {
       this.showFeedbackPanel = !this.showFeedbackPanel;
       this.showNlpFeedbackOtherOptions = this.showFeedbackPanel;
       if (this.showFeedbackPanel && !Array.isArray(this.suggetionsArray)) {
           this.applyTaggingInNLPResponse();
       }
  }
  onNlpFeedbackBackClick() {
    this.showFeedbackPanel = !this.showFeedbackPanel;
  }
  applyTaggingInNLPResponse() {
    // Step 1 create array of values from internalreview Table
    let dataValuesToTag = [];
    this.intermediateDataResponse.rows.forEach(row => {
      const dataValue = Array.from(row.values());
      if (dataValue) {
        dataValuesToTag = dataValuesToTag.concat(dataValue);
      }
    });
    if (this.trade_validation_details_table && Array.isArray(this.trade_validation_details_table)) {
      this.trade_validation_details_table.forEach(tdRow => {
      const dataValues = tdRow.map(tMapp=>tMapp.value)
      if (dataValues) {
        dataValuesToTag = dataValuesToTag.concat(dataValues);
      }
      });
    }
    if (this.trade_details_table && Array.isArray(this.trade_details_table)) {
      this.trade_details_table.forEach(tdRow => {
      const dataValues = tdRow.map(tMapp=>tMapp.value)
      if (dataValues) {
        dataValuesToTag = dataValuesToTag.concat(dataValues);
      }
      });
    }
    if(!this.suggestions) {
      return;
    }
    let suggetionsWithoutHTML = this.suggestions.replace('<html>', '');
    suggetionsWithoutHTML = suggetionsWithoutHTML.replace('<html>', '');
    suggetionsWithoutHTML = suggetionsWithoutHTML.replace('</html>', '');
    this.suggetionsArray = suggetionsWithoutHTML.split(' ');


    // Step 2 Tag html Elements which are matching with values from internalreview Table
    this.suggetionsArray.forEach((htmlTag,index) => {
      if (htmlTag) {
        const htmlTagMatchedValue =  dataValuesToTag.find(element =>htmlTag.replace('<span>','')
        .replace('</span>','').replace(':','') == element);
        if (htmlTagMatchedValue && htmlTag.includes('<span>')) {
          this.arrayHtmlDropdownIndex.push(index);
          console.log(htmlTagMatchedValue);
          // this.suggetionsArray.splice(index+1, 0, this.getDropdownHtml());
          let htmltoAppend = this.suggetionsArray[index];
          htmltoAppend = htmltoAppend.replace('<span>','<div style="display: inline;">');
          htmltoAppend = htmltoAppend.replace('</span>','</div>');
          htmltoAppend = htmltoAppend.replace('</div>',this.getDropdownHtml(htmlTagMatchedValue,index) + '</div>');
          this.suggetionsArray[index] = htmltoAppend;
        }
      }
    });
     this.suggetionsArray = this.sanitizer.bypassSecurityTrustHtml(this.suggetionsArray.join(" "));
     // this.feedbackHtmlElementRef.nativeElement.insertAdjacentHTML('beforeend', this.suggetionsArray);
    // event binding
    setTimeout(() => {
      const component = this;
      $('#dropdown').parent().prev().css( "display", "inline" );
    /*   $( ".dropbtn" ).bind( "click", function() {
        $("#dropdown-content").toggleClass('nlp-dropdown-display');
        // reset prev selection on dropdown click
        let dataAttributeClicked = $(this).attr("data-actual-cat-value");
        for (const [key, value] of Object.entries(component.userFeedback.userProvidedMatch)) {
          if (dataAttributeClicked === value) {
            delete component.userFeedback.userProvidedMatch[key];
          }
        }
      }); */
      $( ".dropbtn" ).bind( "click", function() {
        // let $this = $(this);
        $(".dropdown-content").each(function(index,value) {
          $(this).removeClass('nlp-dropdown-display');
        });
        $(this).next().toggleClass('nlp-dropdown-display');
        // reset prev selection on dropdown click
        $(this).next().children().children().each(function() {
          console.log(this.children[0])
          $(this.children[0]).prop( "checked", false );
        })
        let dataAttributeClicked = $(this).attr("data-actual-cat-value");
        for (const [key, value] of Object.entries(component.userFeedback.userProvidedMatch)) {
          if (dataAttributeClicked === value) {
            delete component.userFeedback.userProvidedMatch[key];
          }
        }
      });
      $( ".dropbtn-close" ).bind( "click", function() {
        // $(".dropdown-content").toggleClass('nlp-dropdown-display');
       $(this).parent().parent().parent().toggleClass('nlp-dropdown-display')
        $("input:radio").each(function() {
          if (this.checked && this.value !=="other" && $(this).attr("data-actual-cat-value")) {
            component.userFeedback.userProvidedMatch[this.value] = $(this).attr("data-actual-cat-value");
          }
      });

          $("textarea").each(function() {
            if (this.value !=="" && $(this).attr("id") !== "txtFeedbackPanel") {
              component.userFeedback.userProvidedMatch[$(this).attr("data-actual-cat-value")] = this.value;
            }
        });

      });

       /*  let checkList: HTMLElement = document.getElementById('list1') as HTMLElement;
        if (checkList.classList.contains('visible'))
          checkList.classList.remove('visible');
        else
          checkList.classList.add('visible');
      }); */
      /* $('.dropdown-check-list').parent().parent().css( "display", "inline" );

      $( ".anchor" ).bind( "click", function() {
        let checkList: HTMLElement = document.getElementById('list1') as HTMLElement;
        if (checkList.classList.contains('visible'))
          checkList.classList.remove('visible');
        else
          checkList.classList.add('visible');
      });
    // Add eventListener
    $( ".nlp-feebback-dropdown" ).bind( "click", function() {
      component.onNLPDropdownClick(); */


    }, 300);

  }



  getDropdownHtml(actualCatValue,index) {
    /* return '<select id="framework">' +
    '<option value="">Angular</option>'+
    '<option value="">React</option>' +
    '<option value="">Vue.js</option>' +
   '<option>Ember.js</option>' +
    '</select>'; */
   /*  return '<img src="./assets/Inbox/grid-chevron-up.svg"' +
    'width="10" height="10" (click)="onNLPDropdownClick()" class="nlp-feebback-dropdown">'; */
   /*  return '<img src="./assets/Inbox/grid-chevron-up.svg"' +
    'width="10" height="10" (click)="onNLPDropdownClick()" class="nlp-feebback-dropdown">'; */
    // return '<button type="button" class="btn_img" (click)="onNLPDropdownClick()"></button>';
    /* '<div>Categories</div>'; */
    /* return '<span id="list1" class="dropdown-check-list" tabindex="100">'+
    '<span class="anchor">Select Fruits</span>'+
   ' <ul class="items" >'+
      '<li style="background: blue;">Header</li>'+
      '<li><input type="radio" name="drone"/>Apple </li>'+
      '<li><input type="radio" name="drone"/>Orange</li>'+
      '<li><input type="radio" name="drone"/>Grapes </li>'+
      '<li><input type="radio" name="drone"/>Berry </li>'+
    '</ul>'+
  '</span>'; */

  //let columns = this.intermediateDataResponse.columns;
  let columns = QmaConstant.NLP_FEEDBACK_ATTRIBUTES;
  let options = '';
    if (columns && Array.isArray(columns)) {
      columns.forEach(cl=>{
        options = options+ `<li style="margin-left: 10px;margin-top: 5px;margin-bottom: 5px;display: flex;align-items: center"><input class="nlp-feebback-radio" type="radio"
        style="margin-right: 5px"
    name="feedbackCategory" value="${cl}" data-actual-cat-value="${actualCatValue}"/>${cl} </li>`
      });
    }
   return `<span class="dropdown" id="dropdown" >` +
 //  '<button class="dropbtn" id="dropbtn">Dropdown</button>'+
 '<img src="./assets/Inbox/down-arrow-feedback.svg"' +
  `width="10" height="10" id="dropbtn_${index}" class="nlp-feebback-dropdown dropbtn" data-actual-cat-value="${actualCatValue}">`+
  '<span class="dropdown-content" id="dropdown-content">'+
  ' <ul class="items" style="margin: 0px;padding: 0px;">'+
  '<li  class="nlp-feedback-dd-header">Categories  <img src="./assets/core/layout/tabs/close-icon-white.svg" width="20" height="20"'+
   'style="margin-right: 10px;" class="dropbtn-close"></li>'+
   options+
   `<li style=""><input style="margin-left: 10px;margin-bottom: 5px" type="radio" name="feedbackCategory" value="other" data-actual-cat-value="${actualCatValue}"/>Other`+
   `<textarea  style="margin-left: 10px;margin-top: 5px;margin-right: 5px;" rows="4" cols="20" data-actual-cat-label="other" data-actual-cat-value="${actualCatValue}"></textarea>`+
    '</li>'
  /* '<li><input type="radio" name="drone"/>Apple </li>'+
  '<li><input type="radio" name="drone"/>Orange</li>'+
  '<li><input type="radio" name="drone"/>Grapes </li>'+
  '<li><input type="radio" name="drone"/>Berry </li>'+ */

'</ul>'+
 '</span>'+
'</span>';

  }

  saveNlpFeedback() {
    this.closeSuggestion.emit(false);
    this.userFeedback.isValidResponse = this.adequteResponse;
    this.userFeedback.groupId = this.groupId;
    this.userFeedback.comments = this.txtUserComments;
    this.userFeedback.inquiryId = this.inquiryId;
    this.userFeedback.userId = this.userDataService.loggedInUserInfo.userId;
    const postData = {
      inquiryId: this.inquiryId,
      convId: this.convId,
      groupId: this.groupId,
      action: "feedback",
      comment:this.txtUserComments,

      isAdequateResponse:this.adequteResponse
    };
    // add actiond details only if user entered
    if(!AppUtils.isEmptyObject(this.userFeedback.userProvidedMatch)) {
      postData['actionDetails'] = {
        userEntityTags: this.userFeedback.userProvidedMatch
      };
    }
    this.inboxService.declineSuggestions(postData).subscribe(response => {
      if (!this.isNull(response)) {
        console.log('result of Add Feedback', response);
        if (!this.isNull(response) && !this.isNull(response.success) && response.success) {
          this.onCallCompleted('success', response.message, postData);
        } else {
          this.onCallCompleted('error', 'Error while Adding feedback');
        }
      }
    },
      (error) => {
        this.onCallCompleted('error', 'Service Failure : Error while  Adding feedback');
      }
    );
  }
  cancelNlpFeedback() {
    this.showFeedbackPanel = false;
  }

    calculateHeightForSplitGutter() {
      setTimeout(() => {
        let tradeDetailsPanelHeight = this.asSplitAreaTradeValidationEl.nativeElement.offsetHeight;
        // set height of gutter
        $(".tradeDetailsTbl .as-split-gutter").css(
          {
              'cssText': `height: ${tradeDetailsPanelHeight}px !important;flex-basis: 5px; order: 1;margin-left: 3px;
              margin-right: 3px;`
          })
      }, 0);
    }
  onTradeDetailRowSelectionChanged(event, tradeDetailRow , index,isHeaderCheckboxChecked) {
    if (!this.trade_details_table[index]) {
      return;
    }
    try {
      if(isHeaderCheckboxChecked) {
        // this.trade_details_table.forEach(tb => {
        //   tb.forEach(td=>{
        //     td.isROwVisible = event && event.target ? event.target.checked : true;
        //   });
        // });
        this.gridApiTradeDetail.api.forEachNodeAfterFilter(node => {
            let shouldRowVisible = event && event.target ? event.target.checked : true;
            node.setSelected(shouldRowVisible);
            this.trade_details_table[+node.id].forEach(td=> {
                td.isROwVisible = event && event.target ? event.target.checked : true;
              });

          });
      } else {
        this.trade_details_table[index].forEach(td=> {
          td.isROwVisible = event && event.target ? event.target.checked : true;
        });
      }
      // header checkbox
      this.isAnyRowSelected = this.getTradeRowSelectionStatus().anyRowSelected;
      this.isTradeDetailsSelected = this.isAnyRowSelected;
    }
    catch(e) {
      console.log(e);
    }

  }
  getTradeRowSelectionStatus(){
    let status = {anyRowSelected:false,allRowsSelected:false};
    let allRowsCount = this.trade_details_table.length;
    let rowsSelected = 0;
    this.trade_details_table.forEach(td=> {
      if(td[0].isROwVisible) {
        rowsSelected = rowsSelected+1;
      }
  });
    if (rowsSelected === 0) {
      status.anyRowSelected = false;
    } else {
      status.anyRowSelected = true;
    }
    if(rowsSelected === allRowsCount) {
      status.allRowsSelected = true;
    }
    if(!this.isTradeDetailsSelected) {

    }
    return status;
  }
  toggleNLPRejectionPanel(flag) {
    this.displayReason =  flag;
    if (this.displayReason) {
      $('.nlp-modal-pop-up').css({'overflow':'initial','margin-top':'10%'});
    } else {
      $('.nlp-modal-pop-up').css({'overflow':'auto','top':'50px'});
    }
    this.showFeedbackPanel = !flag;
    this.showNlpFeedbackOtherOptions = !flag;
  }
  closeSuggetionBox() {
 this.closeSuggestion.emit(false);
  }
  hideEmptyCols(table) {
    try{
      let totalRows =  $('.' + table + ' tbody tr').length;
      let totalColumns = $('.' + table + ' tbody tr:first td').length;
      for(let i = 1; i <= totalColumns; i++) {
        let emptyCell = 0;
        $('.' + table + ' tbody').find('td:nth-child(' + i + ')').each(function() {
          if( $(this).html().trim() === ""){
              console.log($(this).html());
              emptyCell++;
            }
      });
      console.log(emptyCell);
      if(emptyCell === totalRows) {
        $('.' + table ).find('td:nth-child(' + i + ')').hide()
      } else {
        $('.' + table ).find('td:nth-child(' + i + ')').show()
      }
      }

    } catch(e) {
      console.log("Exception in hideEmptyCols" + e);
    }

}

convertStringToJSON(response) {
response.tradesInfo =  JSON.parse(response.tradesInfo);
return response;
}
getNLPSuggetionsFromResponse(response,queryResult) {
  response.suggestedResponseTradeDetails = [];
  queryResult.forEach(queryResult=>{
    if(queryResult && queryResult[1].length>0) {
      queryResult[1].forEach(queryResult=>{
        response.suggestedResponseTradeDetails.push(queryResult)
      });

    }
     });
     // add trade detail row data
     this.rowData_trade_details = response.suggestedResponseTradeDetails;
     this.addColumnsTOTradeDetailsGrid(response.suggestedResponseTradeDetails);
     this.rowData_trade_validation_details_table = response.suggestedResponseTradeDetails;
}
addColumnsTOTradeDetailsGrid(suggestedResponseTradeDetails) {
    let Keys = [];
    // reset column def
  this.columnDefs_trade_details_table = [{field:'Status',headerName:'Status',menuTabs: ['filterMenuTab'],
  filter: 'agTextColumnFilter',width:150, checkboxSelection:true,
  headerCheckboxSelection:true,sortable:true,headerCheckboxSelectionFilteredOnly:true, tooltipField:'Status',resizable: true},
  {field:'Ops Trd Ref',headerName:'Ops Trd Ref',menuTabs: [],
  filter: 'agTextColumnFilter',width:160, checkboxSelection:false,
  headerCheckboxSelection:false,sortable:true,headerCheckboxSelectionFilteredOnly:true, tooltipField:'Ops Trd Ref', resizable: true}
  ];

    suggestedResponseTradeDetails.forEach(tradeRow => {
            Keys = Keys.concat(Object.keys(tradeRow));
    });
    Keys = _.unique(Keys);
    Keys.forEach(k=>{
        if(!QmaConstant.NLP_TRADE_VALIDATION_FIELDS.includes(k) && k.toLowerCase() !== "suggested reply"
        &&!QmaConstant.QMA_TRADE_DETAIL_PREDIFINED_COLUMNS.includes(k)) {
            this.columnDefs_trade_details_table.push({headerName:k,field:k,sortable:true,menuTabs:[],filter: 'agTextColumnFilter',
            checkboxSelection: false ,
            headerCheckboxSelectionFilteredOnly:true,headerCheckboxSelection:false,width:100,tooltipField:k, resizable: true});
        }
    });
}


onGridReadyTradeDetail(gridApi) {
this.gridApiTradeDetail = gridApi;
// function depreciated in AGgrid 27 replacement used
// this.gridApiTradeDetail.api.setGridAutoHeight('autoHeight');
this.gridApiTradeDetail.api.setDomLayout('autoHeight');
}
onFirstDataRenderedTradeValidation(gridApi) {
  try{
    // let filterModel = {"Status":{"filterType":"text","operator":"AND","condition1":{"filterType":"text","type":"notEqual",
    // "filter":"Cancelled"},
    // "condition2":{"filterType":"text","type":"notEqual","filter":"Allocated"}}};
    let filterModel = {};
    let nlpFilters  = this.userDataService.loggedInUserInfo.qmaUI2Config.nlpFilters?this.userDataService.loggedInUserInfo.qmaUI2Config.nlpFilters:[];
    if(Array.isArray(nlpFilters) && nlpFilters.length > 0) {
      for(let i=0;i<nlpFilters.length ;i++) {
        let filterModelName = Object.keys(nlpFilters[i])[0];
        if(nlpFilters[i][Object.keys(nlpFilters[i])[0]].length === 1) {
          filterModel[filterModelName] =  {"filterType":"text","type":"notEqual","filter":nlpFilters[i][Object.keys(nlpFilters[i])[0]][0]};
        } else if(nlpFilters[i][Object.keys(nlpFilters[i])[0]].length > 1) {
          filterModel[filterModelName] = {};
           filterModel[filterModelName].filterType = "text";
            filterModel[filterModelName].operator = "AND";
            nlpFilters[i][Object.keys(nlpFilters[i])[0]].forEach((filterValue,index) => {
            filterModel[filterModelName]['condition'+(index+1)] = {"filterType":"text","type":"notEqual","filter":filterValue} 
          });
      };
      }
    } 
    gridApi.api.setFilterModel(filterModel)
  } catch(e) {
    console.log("Error while applying NLP filter",e)
  }
   
}
onFirstDataRenderedTradeDetail(gridApi) {
    let gpName = this.userDataService.loggedInUserInfo.qmaUI2Config.nlpGPName?this.userDataService.loggedInUserInfo.qmaUI2Config.nlpGPName:"";
    let filterModel = {"Status":{"filterType":"text","operator":"AND","condition1":{"filterType":"text","type":"notEqual","filter":"Cancelled"},"condition2":{"filterType":"text","type":"notEqual","filter":"Allocated"}},"GP_NAME":{"filterType":"text","type":"notEqual","filter":gpName}}
    gridApi.api.setFilterModel(filterModel)
}
onFilterChangedTradeValidation(gridApi) {
    let filterModel = gridApi.api.getFilterModel();
    this.gridApiTradeDetail.api.setFilterModel(filterModel);
    this.selectAllFilteredTradedetail();
    gridApi.api.setDomLayout('autoHeight');
    this.checkEmptyColumns(gridApi);
}
onFilterChangedTradeDetail(gridApi) {
  this.checkEmptyColumns(gridApi);
  this.hideNonTradeDetailsFilteredColumns(gridApi);
}
selectAllFilteredTradedetail(){
  this.deselectAllGridNodes();
    this.gridApiTradeDetail.api.forEachNodeAfterFilter(node => {
        let shouldRowVisible = true;
        node.setSelected(shouldRowVisible);
        this.trade_details_table[+node.id].forEach(td=> {
            td.isROwVisible = true;
          });
      });
}
deselectAllGridNodes() {
  this.gridApiTradeDetail.api.deselectAll();
}
onRowSelected($event) {
    this.trade_details_table[+$event.node.id].forEach(td=> {
        td.isROwVisible = $event.node.selected;
      });
    this.isAnyRowSelected = this.getTradeRowSelectionStatus().anyRowSelected;
    this.isTradeDetailsSelected = this.isAnyRowSelected;
    this.mergeSuggestedReply();
    setTimeout(() => {
      this.hideEmptyCols("tradeDetailsTblDisplay");
      this.hideEmptyCols("tradeDetailsTblToExport");
      this.hideEmptyCols("tradeValidationDetailsTbl");
      this.calculateHeightForSplitGutter();
    }, 0);

}

mergeSuggestedReply() {
    this.suggestions = '';
    let selectedNodes = this.gridApiTradeDetail.api.getSelectedNodes().map(n=>+n.id);
    this.trade_details.forEach((tradeDetail:any,index) => {
        if(selectedNodes.includes(index)) {
            this.suggestions += tradeDetail['Suggested Reply'];
        }
    });

    console.log(selectedNodes);
  }


  checkEmptyColumns(params) {
    let displayedRowsCount = params.api.getDisplayedRowCount();
    if(displayedRowsCount === 0) {
      return;
    }
    const columns = params.columnApi.getAllColumns();
    const rowsNodes = params.api.getRenderedNodes();
    columns.forEach(column => {
      const isColumnEmpty = !rowsNodes.some(rowNode => {
        const value = params.api.getValue(column, rowNode);
        return typeof value !== 'undefined' && value !== null && value !== "";
      });
        this.setVisibilityOfColumn(params,column,isColumnEmpty);
    });
  }
  setVisibilityOfColumn(params,column,isColumnEmpty) {
    params.columnApi.setColumnVisible(column.colId,!isColumnEmpty);
  }
  onGridReadyTradeValidation(gridApi) {
    this.gridApiTradeValidation = gridApi;
  }

  showNoSuggetionWindow() {
    window.document.body.click();
              let noSuggesstion = {
                contentNotAvlbelMsg: 'Conversation suggestion not available.',
                displayError: true
              }
              this.noSuggestion.emit(noSuggesstion);
  }
   // C170665-3719 NLP Trade Status - Refresh -Limit number of trades to 20
   enableNLPRefresh():boolean {
    try {
            let nlpMaxTradeRefreshCount = this.userDataService && this.userDataService.loggedInUserInfo && this.userDataService.loggedInUserInfo.qmaUI2Config &&
            this.userDataService.loggedInUserInfo.qmaUI2Config.nlpMaxTradeRefreshCount ?
            this.userDataService.loggedInUserInfo.qmaUI2Config.nlpMaxTradeRefreshCount:QmaConstant.QMA_NLP_TRADE_THRESHOLD;
      if(this.trade_details &&  this.trade_details.length && this.trade_details.length>0) {
      let tradeRefList = this.getTradeRefListToRefresh();
      return tradeRefList.length <= nlpMaxTradeRefreshCount;
      } else{
      return false;
      }
    } catch(exception) {
      console.error("Error in enableNLPRefresh",exception);
    }
    
  }
  getTradeRefListToRefresh() {
    try {
      let tradeRefList = [];
      this.trade_details.forEach(td => {
     this.nlpRefreshRequestColumns.forEach(columnName => {
      if(td[columnName]) {
        tradeRefList.push(td[columnName]);
      }
     });
   });
   return tradeRefList;   
    } catch(exception) {
      console.error("Error in getTradeRefListToRefresh",exception)
    }
   
  }
  hideNonTradeDetailsFilteredColumns(params) {
  this.QMA_TRADE_DETAIL_HIDDEN_COLUMNS.forEach(clmn=>{
    params.columnApi.setColumnVisible(clmn,false)
  });
  }
}


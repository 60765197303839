import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy} from '@angular/core';

@Component({
  selector: 'app-collapsible-list',
  templateUrl: './collapsible-list.component.html',
  changeDetection:ChangeDetectionStrategy.OnPush,
  styleUrls: ['./collapsible-list.component.scss']
})
export class CollapsibleListComponent implements OnInit {
  @Input() placeholder:String;
  @Input() items:Array<object>;
  @Input() listStyle:any = {'height':'220px'};
  @Output() onItemSelect = new EventEmitter();
  filteredItems:Array<object> ;
  native: any;
  
  ngOnInit() {
    this.assignCopy(); //On Server call
  }

  assignCopy(){
    this.filteredItems = Object.assign([], this.items);
  }

  filterItem(value:string){
    if(!value){
        this.assignCopy();
    }
    this.filteredItems = Object.assign([], this.items).filter(
      item => {
       if((this.isInChild(item, value))){
         return true;
       }
      }
    )
  }

  isInChild(_item:any, _val:any){
    let found = false;
    for(var i=0; i<_item.childList.length;i++){
      if(_item.childList[i].name.toLowerCase().indexOf(_val.toLowerCase()) > -1){
        found = true;
        break;
      }
    }
    return found;
  }

  itemSelected(par:any, child:any){    
    let obj:any = {};
    obj.parent = par;
    obj.selectedChild = child;   
    this.onItemSelect.emit(obj);
  }

  toggleItem(item:any){
    item.toggle = !item.toggle;
  }
}

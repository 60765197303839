import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';
import { NotificationService } from "./services/global-error/notification.service";
import { DOCUMENT } from "@angular/common";
import { QmaConstant } from "src/app/constant/qma-constant";
import { DashboardService } from './services/dashboard/dashboard.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UserDataService } from './services/user-data.service';
import { MicrofrontendInboxService } from './microfrontend-inbox/microfrontend-inbox.service';
import { authCodeFlowConfig } from './app.config';
import { OAuthService } from 'angular-oauth2-oidc';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {

  title = 'qma-ui';
  showSpinner: boolean = false;
  showMicroUi: boolean;
  notShowMicroUi: boolean
  loginUserInfo: boolean
  folderName = ''
  inquiryIdParam: any[] = [];
  accessType: any;
  entityTypeParam: any;
  productFamilyParam: any;
  dealStatusParam: any;
  entityDetails: any[] = [];
  inquiriesObjectParam: any[] = [];
  isLogoutRoute: boolean;
  isErrorRoute: boolean;
  // isAllowed: boolean = true;

  constructor(public dashboardService: DashboardService, private activatedRoute: ActivatedRoute, private userDataService: UserDataService, private microfrontendInboxService: MicrofrontendInboxService, public oauthService: OAuthService, private router: Router) {

    this.showSpinner = true;
    console.log(":SPINNER:: " + this.showSpinner + " ::app.constructor");
    this.oauthService.configure(authCodeFlowConfig);
    this.oauthService.loadDiscoveryDocumentAndLogin();
    this.oauthService.setupAutomaticSilentRefresh();
  }

  ngOnInit(): void {
    // Prevent app logic from executing in the silent refresh iframe
    if (window.location.search.includes('silent-refresh=true')) {
      console.log('Silent refresh iframe detected. Skipping initialization.');
      return;
    }

    // Router events
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.isLogoutRoute = event.urlAfterRedirects === '/logout';
      }
      if (event instanceof NavigationEnd) {
        this.isErrorRoute = event.urlAfterRedirects === '/error';
      }
    });


  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.showSpinner = false;
      console.log(":SPINNER:: " + this.showSpinner + " ::app.ngAfterViewInit");
      this.dashboardService.onLoad();
      this.loadApplication();
    }, 3000);
  }

  loadApplication() {
    this.activatedRoute.queryParams.subscribe(params => {
      let urlName = window.location.href
      let indexOfValue = urlName.indexOf('openWith')
      if (indexOfValue > -1) {
        if (params.openWith == 'microUi') {
          this.showMicroUi = true;
          this.folderName = 'INBOX';
          window.addEventListener('message', (event) => {
            console.log('post message from BMW microUi --->', event);
            if (event && event.origin === window.location.origin && event.data && event.data[`appName`] === 'BMW') {
              console.log('eventData from BMW microUi --->', event.data);
              this.accessType = event.data.accessType ? event.data.accessType : '';
              this.entityDetails = event.data.entityList ? event.data.entityList : [];
              this.productFamilyParam = event.data.productFamily ? event.data.productFamily : '';
              this.entityTypeParam = event.data.entityType ? event.data.entityType : '';
              this.dealStatusParam = event.data.preMatchedStatus ? event.data.preMatchedStatus : ''
              this.inquiryIdParam = event.data.inquiryIds ? event.data.inquiryIds : [];
              this.inquiriesObjectParam = event.data.inquiries ? event.data.inquiries : []
            } else {
              return false;
            }
            sessionStorage.setItem("currentTab", this.folderName);
            this.userDataService.httpGetLoginUserInfo();
            this.loginService();
          });
        }
      } else {
        this.notShowMicroUi = true
      }
    });
  }

  loginService() {
    this.userDataService.getServiceStatus().subscribe(status => {
      this.loginUserInfo = status;
      if (this.loginUserInfo) {
        this.userDataService.LocalGetLoginUserInfo().subscribe((data) => {
          let productFamilyFromLoginUser = data.productFamilyGroupIdMap
          let numberOfDays = data.searchInDays;
          let bussinessStartDate = data.bussinessStartDate
          let tabNameForIframe = this.folderName.toLowerCase();
          tabNameForIframe = tabNameForIframe.charAt(0).toUpperCase() + tabNameForIframe.slice(1);
          this.microfrontendInboxService.formRequestObjectOfGrid(tabNameForIframe, this.inquiryIdParam, this.productFamilyParam, productFamilyFromLoginUser, numberOfDays, this.inquiriesObjectParam, bussinessStartDate);
        })
      }
    });
  }

}

import { Component, OnInit, Input, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { ClcService } from "src/app/services/clc/clc.service";
import { QmaConstant } from 'src/app/constant/qma-constant';

interface CLCSuggestion {
  inquiryId: number,
  convId: number,
  convOrder: number,
  groupId: string,
  groupName: string,
  tradeId: string[]
}

// Constants
const INQUIRY_ID = "INQUIRY_ID";
const CLC_TRADES_ROWS = "CLC_TRADES_ROWS";
const CLC_SUGGESTION_CONTENT = '<html><p>Dear <span>User,</span></p><p>Please find the status of your trade(s) linked to inquiry(INQUIRY_ID) below.</p> CLC_TRADES_ROWS <br/><p>Please contact <b>*GT GLOBAL QMA Support</b> for further information and reference the inquiry number INQUIRY_ID on future correspondence.</p></html>';
const TITLE = "Suggestion for Linked trades to Inquiry: INQUIRY_ID";

@Component({
  selector: 'app-clc-suggestion',
  templateUrl: './clc-suggestion.component.html',
  styleUrls: ['./clc-suggestion.component.scss']
})
export class ClcSuggestionComponent implements OnInit, OnChanges {

  @Input() clcSuggestionRequest: CLCSuggestion;
  @Output() clcSuggestionReplied = new EventEmitter();
  suggestedContent: string;
  suggestedCLCTrades: any[];

  // Suggestion Popup
  display: boolean = false;
  title: string;

  constructor(private clcService: ClcService) { }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges): void {
    this.title = "";
    this.suggestedContent = "";
    if (changes.clcSuggestionRequest) {
      this.fetchClcSuggestionDetails(this.clcSuggestionRequest);
    }
  }

  /**
   * Method to create the request object to get the CLC suggestion content
   * 
   * @param reqData: CLCSuggestion 
   */
  fetchClcSuggestionDetails(reqData: CLCSuggestion): void {
    const clcSuggestionReqObj = {
      tradeRefClcOption: 'Trade Ref',
      tradeId: reqData.tradeId.toString(),
      securityClcOption: '',
      clcSecurity: '',
      clcQuantity: '',
      clcTradeDate: '',
      clcClient: '',
      clcSource: '',
      clcAmount: '',
      clcSettDate: '',
      sdiClcOption: '',
      clcSdi: '',
      clcSearchStatus: '',
      clcResultRowLimit: 100,
      actionSource: 'CLC Search for CLC Suggestion',
      inquiryId: reqData.inquiryId,
      groupId: reqData.groupId
    };
    if (!this.isUndefinedOrNull(clcSuggestionReqObj)) {
      this.performCLCSearchOnTradeRef(clcSuggestionReqObj);
    }
  }

  /**
   * Method to hit the clc service to get the CLC suggestion Content based on the request trades.
   * 
   * @param clcSuggestionReqObj 
   */
  performCLCSearchOnTradeRef(clcSuggestionReqObj: any): void {
    this.clcService.clcSearchAll(clcSuggestionReqObj).subscribe(response => {
      if (!this.isUndefinedOrNull(response) && !this.isUndefinedOrNull(response.success) && response.success) {
        this.suggestedCLCTrades = response.clcGridData;
        let inquiryId = clcSuggestionReqObj.inquiryId;
        this.title = TITLE.replace(/INQUIRY_ID/g,inquiryId);
        let clcSelectedRowsHtml = this.getHTMLDataForRow(this.suggestedCLCTrades, true);
        let clcSuggestionContent = CLC_SUGGESTION_CONTENT.replace(/INQUIRY_ID/g, inquiryId).replace(CLC_TRADES_ROWS, clcSelectedRowsHtml);
        this.suggestedContent = clcSuggestionContent;
      } else {
        this.suggestedContent = response.message;
      }
      this.display = true;
    }, error => {
      throw new Error("Service Failure while performing CLC Search");
    });
  }

  /**
   * Method to perform add the clc suggestion to reply.
   * @param clcSelectedRows 
   */
  onAddSuggestionToReply(clcSelectedRows: any): void {
    this.closeModal();
    let clcSelectedRowsHtml = this.getHTMLDataForRow(clcSelectedRows, false);
    let inquiryId = "" + this.clcSuggestionRequest.inquiryId;
    let clcSuggestionContent = CLC_SUGGESTION_CONTENT.replace(/INQUIRY_ID/g, inquiryId).replace(CLC_TRADES_ROWS, clcSelectedRowsHtml);

    let replyReqData : any = {...this.clcSuggestionRequest};
    replyReqData.clcSuggestionContent = clcSuggestionContent;

    // Let the parent know about the reply action of CLC suggestion
    this.clcSuggestionReplied.emit(replyReqData);
  }

  /**
   * Method to get the clc selected rows as HTML string.
   * @param clcSelectedRows 
   */
  getHTMLDataForRow(clcSelectedRows: any, readonly: boolean): string {
    let selectedRowHtml = "";
    const crossIcon = readonly ? "" : QmaConstant.CLC_DELETE_SPAN;;
    if (clcSelectedRows && clcSelectedRows.length > 0) {
        selectedRowHtml = "<br/><div style='margin:1%;width:80%;' id='conatinerDivForCLCTable'><table id='clcTable' border='1px solid black' style='font-size:10px;white-space:nowrap;border-collapse: collapse;'>" +
        "<thead style='background-color: #002d72;color: #fff;font-weight:bold !important;'><tr>" +
        "<td style='min-width:50px;'>Trade Ref</td>" +
        "<td style='min-width:50px;'>Security Name</td>" +
        "<td style='min-width:50px;'>CUSIP</td>" +
        "<td style='min-width:50px;'>ISIN" + crossIcon + "</td>" +
        "<td style='min-width:70px;'>SDI Line 1" + crossIcon + "</td>" +
        "<td style='min-width:70px;'>SDI Line 2" + crossIcon + "</td>" +
        "<td style='min-width:70px;'>SDI Line 3" + crossIcon + "</td>" +
        "<td style='min-width:70px;'>Trade Date" + crossIcon + "</td>" +
        "<td style='min-width:70px;'>Sett Date" + crossIcon + "</td>" +
        "<td style='min-width:85px;'>Con Amount" + crossIcon + "</td>" +
        "<td style='min-width:85px;'>Con Quantity" + crossIcon + "</td>" +
        "<td style='min-width:70px;'>Buy Sell" + crossIcon + "</td>" +
        "<td style='min-width:70px;'>Status" + crossIcon + "</td>" +
        "<td style='min-width:70px;'>Tran Type" + crossIcon + "</td>" +
        "<td style='min-width:100px;'>Commentary" + crossIcon + "</td>" +
        "<td style='min-width:100px;'>Remarks</td>" +
        "</tr></thead><tbody style='text-align:center;'>";

      clcSelectedRows.forEach(currentRow => {
        selectedRowHtml += "<tr>";
        selectedRowHtml += "<td>" + currentRow.tradeRef + "</td>";
        selectedRowHtml += "<td>" + currentRow.securityName + "</td>";
        selectedRowHtml += "<td>" + currentRow.cusip + "</td>";
        selectedRowHtml += "<td>" + currentRow.isin + "</td>";
        selectedRowHtml += "<td>" + currentRow.sdi1 + "</td>";
        selectedRowHtml += "<td>" + currentRow.sdi2 + "</td>";
        selectedRowHtml += "<td>" + currentRow.sdi3 + "</td>";
        selectedRowHtml += "<td>" + currentRow.tradeDate + "</td>";
        selectedRowHtml += "<td>" + currentRow.settDate + "</td>";
        selectedRowHtml += "<td>" + currentRow.conAmount + "</td>";
        selectedRowHtml += "<td>" + currentRow.conQuantity + "</td>";
        selectedRowHtml += "<td>" + currentRow.buySell + "</td>";
        selectedRowHtml += "<td>" + currentRow.status + "</td>";
        selectedRowHtml += "<td>" + currentRow.tranType + "</td>";
        selectedRowHtml += "<td>" + currentRow.commentary + "</td>";
        selectedRowHtml += "<td></td>";
        selectedRowHtml += "</tr>";
      });
      selectedRowHtml += "</tbody></table><span id='endSpanElementInCLCTableConatainer'></span></div>";
      return selectedRowHtml;
    }
  }

  /**
   * Method to check whether the input is undefined or null. It returns true or false
   * @param input 
   */
  isUndefinedOrNull(input: any): boolean {
    return (input === "" || input === undefined || input == null);
  }

  /**
   * Method to close the modal
   */
  closeModal() {
    this.display = false;
  }
}

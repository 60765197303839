<!-- added to create chat room */ -->
<p-dialog [(visible)]="displayCreateInqPopUp" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'"
    [styleClass]="'symphonyCreateInquiry'" [closable]="false" [dismissableMask]="true" [baseZIndex]="10000"
    [closeOnEscape]="true" [style]="{'width':'40em'}">
    <p-header>
        <div class="d-flex flex-row align-items-center justify-content-between mx-2 mt-1">
            <h6 class="gfid-title create-chat-popup-header-text" style="font-weight: bold;">Create New Inquiry</h6>
        </div>
    </p-header>
    <form #createInquiryForm="ngForm" (ngSubmit)="createInquiry(createInquiryForm)" (keydown.enter)="$event.preventDefault()">
    <div class="d-flex flex-row align-items-start">
        <label for="from" class="input pr-3" style="margin-left: 81px;">From: </label>
        <p-dropdown [options]="groupNames" [styleClass]="'prime-dd-override group-details-width'" [filter]="true"
            (onChange)="onGroupNameChange($event)" [(ngModel)]="newInqFrom" 
            id="newInqFrom"  name="newInqFrom" #chatFrom="ngModel" required
            >
        </p-dropdown>
        <span class="mobile-view-cross"  *ngIf="(!chatFrom.valid && chatFrom.touched) || (!chatFrom.valid && createInquiryForm.submitted)">
            <img src="assets/newMessage/error.svg" class="error" title="From is required">
        </span>
    </div>
    <div class="d-flex flex-row align-items-start  control-group mob-to mobile-toccbcc">
        <label for="from" class="input pr-3" style="margin-left: 100px;">To: </label>
        <p-autoComplete [suggestions]="filteredToUsersMultiple" (completeMethod)="filterUsers($event)" [minLength]="1"
            field="text" [multiple]="true" [styleClass]="'symphonyAutoComplete override override-newmessage-autocomplete'"
            [(ngModel)]="toUsersList" (onKeyUp)="onKeyUp($event, 'to')" 
            (onBlur)="onBlur($event, 'to')"  (onSelect)="onSelectRecipient($event)" size="10000" [style]="{'width':'360px'}"
            id="toUsersList"  name="toUsersList" #chatMember="ngModel" required
            >
            <!--C153176-4930 -->
           <!--  <ng-template let-value pTemplate="selectedItem">
                <span [ngClass]="getContactStatus(value.value)" oncontextmenu="return false;" class="ui-autocomplete-token-label new-message-token"
                    (contextmenu)="addToTop($event, addtotop, value , 'to')" draggable="true" (dragstart)="drag($event, 'to', value)"
                    (mouseover)="displayContactInfo($event, opContactInfo, value)" style="width: 600px">{{value.text}}</span>
            </ng-template> -->
        </p-autoComplete>
     <!--    <p-overlayPanel #opContactInfo [appendTo]="'body'" [dismissable]="true" [showTransitionOptions]="'0ms'"
            [hideTransitionOptions]="'0ms'">
            <gdir-contact-details [soeId]="selectedContactId"></gdir-contact-details>
        </p-overlayPanel> -->
        <span class="mobile-view-cross"  *ngIf="(!chatMember.valid && chatMember.touched) || (!chatMember.valid && createInquiryForm.submitted)">
            <img src="assets/newMessage/error.svg" class="error" title="To is required">
        </span>
    </div>

    <div class="d-flex flex-row align-items-start" style="width: 42.6em;">
        <label for="from" class="input" style="margin-left: 70px;margin-right: 12px;">Subject: </label>
        <input id="input" type="text" size="46" pInputText [(ngModel)]="createInqSubject" style="width: 358px;" 
        id="createInqSubject"  name="createInqSubject" #chatSubject="ngModel" required>
    </div>


    <div class="d-flex flex-row align-items-start pl-4 pr-4">
        <label for="from" class="input pr-2">Request Type: </label>
        <p-dropdown [styleClass]="'prime-dd-override group-details-width'" [(ngModel)]="createInqReqType" [options]="requestTypes"
        id="createInqReqType"  name="createInqReqType" #chatReqType="ngModel" required>
        </p-dropdown>
        <span class="mobile-view-cross"  *ngIf="(!chatReqType.valid && chatReqType.touched) || (!chatReqType.valid && createInquiryForm.submitted)">
            <img src="assets/newMessage/error.svg" class="error" title="Request Type is required">
        </span>
    </div>
    <p-footer>
        <div class="d-flex flex-row flex-wrap bottom-button-holder px-3 pb-2 pt-3 justify-content-end">
            <button type="submit" class="btn save-btn mr-3" style="width:80px !important">Submit</button>
            <button type="button" class="btn save-btn" (click)="cancelCreateInqPopup()" style="width:80px !important">Cancel</button>

        </div>
    </p-footer>
</form>
</p-dialog>

<!--to show service response message-->
<p-dialog [(visible)]="displayResponse" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'"
    [styleClass]="'contextMenuModal successModal ModelClose symphony-create-chat-model'" [closable]="true" [dismissableMask]="true" 
    [baseZIndex]="10000" [closeOnEscape]="true"
    [style]="{width: '20%','max-height':'400px',overflow:'auto'}">
    <p-header>
        <div class="d-flex flex-row align-items-center justify-content-between">
            <h6 class="success-title" *ngIf="responseMessage && responseMessage" >{{responseMessage}}</h6>
        </div>
    </p-header>
    <!-- <div class="success-msg">{{responseMessage}}</div> -->
 <!--    <div class="mx-2 mt-1 success-msg" *ngIf="responseMessage.title && responseMessage.excludedMembers">
            <h6 class="gfid-title" style="font-weight: bold;" >{{responseMessage.title}}</h6>
            <ul *ngIf="responseMessage && responseMessage.excludedMembers">
                <li *ngFor="let exUsers of responseMessage.excludedMembers" class="qma-inbox-row-read">
                    {{exUsers}}
                </li>
            </ul>
    </div> -->
</p-dialog>

<p-confirmDialog #cd header="Confirm Action" width="425" rejectIcon="No" acceptIcon="No" [styleClass]="'savedraft-section'"  key="externalDomailDialog">
        <p-footer>
            <button type="button" pButton icon="pi pi-check" label="Yes"  (click)="cd.accept()"></button>   
            <button type="button" pButton icon="pi pi-times" label="No" (click)="cd.reject()" ></button>
               
        </p-footer>
</p-confirmDialog>
import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { ContextMenuService } from 'src/app/services/contextMenu/context-menu.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-re-age',
  templateUrl: './re-age.component.html',
  styleUrls: ['./re-age.component.scss']
})
export class ReAgeComponent implements OnInit,OnDestroy {
  display:boolean = false;  
  linkExceptionModel:any;
  selectedMenuItem:any;
  isMessage:boolean = true;
  subManager = new Subscription();
  headerText = "CONFIRM ACTION";
  labelText = "Are you sure you want to Re-Age Inquiries?";

  constructor(private contextMenuService:ContextMenuService) {
  }

  ngOnInit() {
  }
  
  triggerFalseClick() {
    this.headerText = "CONFIRM ACTION";
    this.labelText = "Are you sure you want to Re-Age Inquiries?";
    this.isMessage = true;
    this.display = true;
  }

  hideModal(){
    this.display = false;
  }

  updateReAge(){    
	  const action = "Re Age Inquiry";
    let sub1 = this.contextMenuService.httpReAgeInquiry(this.selectedMenuItem, action).subscribe(result => {
      if(result.newRequests.length > 0 ) {
        this.labelText = " Inquiry(s) "  + JSON.stringify(result.newRequests) + " sent successfully for re-age approval to Supervisor.";
      }
      else if(result.oldRequests.length > 0 ) {
        this.labelText = "Re-age request for Inquiry(s) "  + JSON.stringify(result.oldRequests) + " not processed as it's already awaiting supervisor approval for Re-Age";
      } 
      else if(result.autoApproved.length > 0 ){
        this.labelText = "Re-age request for Inquiry(s) "  + JSON.stringify(result.autoApproved) + " is successful.";
      }
      this.isMessage = false;
      this.headerText = "INFO";
    });     
    this.subManager.add(sub1);
    //this.hideModal();
  }

  ngOnDestroy(){
    this.subManager.unsubscribe();
  }
}

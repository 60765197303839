import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-recipent-data',
  templateUrl: './recipent-data.component.html',
  styleUrls: ['./recipent-data.component.scss']
})
export class RecipentDataComponent implements OnInit, OnChanges {
  @Input() allToCcDBUser:any[];
  @Input() isClosed:boolean= true;
  @Output() pushToTo = new EventEmitter();
  @Output() loadCmc = new EventEmitter();
  query:string = '';
  filteredItems:Array<any> = [];

  constructor() { }

  ngOnInit() {
    this.assignCopy();
  }

  ngOnChanges(){
    /* if(!this.isClosed){
      this.query='';
      this.assignCopy();
    } */
  }

  callCmcSearchWindow(){
    this.loadCmc.emit();
  }

  getContact(_group:any){
    this.pushToTo.emit(_group);
  }

  trackById(index: number, item: any) {
    return item.id;
  } 

  filterItem(value){
    if(!value){
        this.assignCopy();
    } // when nothing has typed
    this.filteredItems = Object.assign([], this.allToCcDBUser).filter(
       item => item.value.toLowerCase().indexOf(value.toLowerCase()) > -1
    )
  }

  assignCopy(){
    this.filteredItems = Object.assign([], this.allToCcDBUser);
 }
}

import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { QmaConstant } from "src/app/constant/qma-constant";
import { Observable } from "rxjs";
import { UserSignature } from "src/app/model/LoginUserInfo/UserSignature";
import { UserPreferences } from "src/app/model/user-profile/userPreferences";
import { InquiryTemplate } from "src/app/model/user-profile/inquiryTemplate";

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  })
};

@Injectable({
  providedIn: 'root'
})
export class ProfileSettingService {
  
  userURL: string;
  inquiryURL: string;

  constructor(private http: HttpClient) {
    this.userURL = QmaConstant.BASE_URL + '/users';
    this.inquiryURL = QmaConstant.BASE_URL + '/inquiry';
  }

  /**
   * Method to save, delete and update signature - We need to pass the array of user signatures
   * 
   * @param signatures - Signature List
   */
  saveSignatureData(signatures: UserSignature[]): Observable<any> {
    return this.http.post(this.userURL + '/saveSignatureData', signatures, httpOptions);
  }

  /**
   * Method to save or update inquiry template.
   * 
   * @param inquiryTemplate - Inquiry Template Object
   */
  saveInquiryTemplate(inquiryTemplate: InquiryTemplate): Observable<any> {
    return this.http.post(this.userURL + '/saveInquiryTemplate', inquiryTemplate, httpOptions);
  }

  /**
   * Method to delete inquiry template.
   * 
   * @param inquiryTemplate - Delete Inquiry Template Object
   */
  deleteInquiryTemplate(inquiryTemplate: InquiryTemplate): Observable<any> {
    return this.http.post(this.userURL + '/deleteInquiryTemplate', inquiryTemplate, httpOptions);
  }

  /**
   * Method to save or update user profile details which includes user prefences
   * 
   * @param userDetails - User Details Object
   */
  saveUserDetail(userDetails: UserPreferences): Observable<any> {
    return this.http.post(this.userURL + '/saveUserDetail', userDetails, httpOptions);
  }

  /**
   * Method to reset inquiry assigned to the user when he/she is out of offcie.
   */
  resetInquiryAssignment(): Observable<any> {
    return this.http.post(this.inquiryURL + '/resetInquiryAssignment', httpOptions);
  }

}

<div>
  <div> <!-- C153176-5194:To/CC/ BCC changes  -->
    <label for="from" >From: </label>

    <p-dropdown  [options]="groupNames" [styleClass]="'prime-dd-override'"
      [filter]="true" (onChange)="onGroupNameChange($event)" >
    </p-dropdown>
   <!--  <div *ngIf="parentForm.controls.groupName.errors && (parentForm.controls.groupName.dirty || parentForm.controls.groupName.touched)" class="">
      <img src="assets/newMessage/error.svg" class="error" title="GroupName is Required">
  </div> -->
  </div>
</div>
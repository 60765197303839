import { Injectable } from '@angular/core';
import { HttpClientService } from '../../utility/http-client.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { serviceUrl } from '../../utility/serviceUrl';
import { QmaConstant } from 'src/app/constant/qma-constant';
import { Observable, BehaviorSubject } from 'rxjs';

const httpFormPostOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  })
};
@Injectable({
  providedIn: 'root'
})
export class SuperAdminSetupService extends HttpClientService{
  private messageSource = new BehaviorSubject("default message");
  currentMessage = this.messageSource.asObservable();
  constructor(http: HttpClient) {
    super(http);
  }
changeMessage(message: string) {
    this.messageSource.next(message)
}
getGroupsList() {
    let url: string = serviceUrl.getAllGroups;
    return this.post(url,{});
} 
getOrgGroup(formData){
  let url: string = QmaConstant.BASE_URL+'/' + serviceUrl.getOrgGroup;
  return this.http.post(url,formData,httpFormPostOptions );
} 
getOrgData(formData){
  let url: string = QmaConstant.BASE_URL+'/' + serviceUrl.getOrgData;
  return this.http.post(url,formData,httpFormPostOptions );
}
getRequests(formData){
  let url: string = QmaConstant.BASE_URL+'/' + serviceUrl.getRequests;
  return this.http.post(url,formData,httpFormPostOptions );
}
saveOrgUnApprovedRequest(formData){
  let url: string = QmaConstant.BASE_URL+'/' + serviceUrl.saveOrgUnApprovedRequest;
  return this.http.post(url,formData,httpFormPostOptions );
}
saveOrgData(formData){
  let url: string = QmaConstant.BASE_URL+'/' + serviceUrl.saveOrgData;
  return this.http.post(url,formData,httpFormPostOptions );
}
getRequestById(formData){
  let url: string = QmaConstant.BASE_URL+'/' + serviceUrl.getRequestById;
  return this.http.post(url,formData,httpFormPostOptions );
}
saveOrgAdminData(formData):Observable<any>{
  let url: string = QmaConstant.BASE_URL+'/' + serviceUrl.saveOrgAdminData;
  return this.http.post(url,formData,httpFormPostOptions );
}
deleteOrgAdminData(formData){
  let url: string = QmaConstant.BASE_URL+'/' + serviceUrl.deleteOrgAdminData;
  return this.http.post(url,formData,httpFormPostOptions );
}
getAlreadyRaisedRequest(formData){
  let url: string = QmaConstant.BASE_URL+'/' + serviceUrl.getAlreadyRaisedRequest;
  return this.http.post(url,formData,httpFormPostOptions ); 
}
}

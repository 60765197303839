import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
@Component({
  selector: 'app-org-metadata',
  templateUrl: './org-metadata.component.html',
  styleUrls: ['./org-metadata.component.scss']
})
export class OrgMetaDataComponent implements OnInit {
  orgMetadataTypeList:any=[]; //C170665-850 display org metadata
  orgMetaDataType:any;
  orgRowMetaData:any=[];
  orgRequestTypeList:any=[];
  orgRootCauseList:any=[];
  orgMetaColumnDefs:any=[];
  orgRequestTypes:any=[];
  orgRootCauses:any=[];
  orgReqTypeRootCauseMap:any=[];
  updatedOrgReqTypeCauseMap:any=[];
  displayOrgMetadata:boolean=false; //C170665-850 flag for display org level metadata in hard code data point 
  orgMetaDataGridApi:any;
  orgMetaDataGridColumnApi:any;
  @Input() orgName:any;
  public metaCheckbox;
  reqList: any = [];
  selectedOrgMetaDataType:any;
  orgData:any;
  ngOnInit() {
     //C170665-850 for display   org level metadata
     this.orgMetadataTypeList = [
      { label: 'Request Types', value: 'RequestTypes' },
      { label: 'Root Cause', value: 'Root Cause' },
      { label: 'Request Type/ Root Cause Mapping', value: 'reqTypeCauseMapping' }, 
    ];
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.selectedOrgMetaDataType="RequestTypes";
    this.populateOrgMetaData(changes.orgName.currentValue);
    this.selectOrgMetaDataType(this.selectedOrgMetaDataType,"onload");
  }
  selectOrgMetaDataType(metaDataTypeVar, mode?: string) {
    if (mode != 'onload') {
      if (this.orgMetaDataType === 'RequestTypes') {
        this.orgRequestTypeList = this.orgRowMetaData;
      } else if (this.orgMetaDataType === 'Root Cause') {
        this.orgRootCauseList = this.orgRowMetaData;
      } 
      if (metaDataTypeVar !== 'reqTypeCauseMapping') {
        if (this.updatedOrgReqTypeCauseMap.length > 0) {
          this.orgReqTypeRootCauseMap = [...this.updatedOrgReqTypeCauseMap];
          this.updatedOrgReqTypeCauseMap = [];
        }
      }
    }
    this.orgMetaDataType = metaDataTypeVar;
    if (this.orgMetaDataType === 'RequestTypes') {
      this.orgRowMetaData = this.orgRequestTypeList;
      this.orgMetaColumnDefs = [
        {
          colId: 'requestType',
          field: "requestType",
          headerName: 'Request type',
          width: 200,
          cellStyle: { 'display': 'inline', 'width': '250px' },  
          editable:false,
          sort: 'asc',
          comparator: function (a, b) {
            if (typeof a === 'string') {
              return a.localeCompare(b);
            } else {
              return (a > b ? 1 : (a < b ? -1 : 0));
            }
          }
        },
        {
          colId: 'highlevelRequestType',
          field: "highlevelRequestType",
          headerName: 'Generic request type',
          width: 200,
          editable: true,
          cellEditor: "agSelectCellEditor",
          cellStyle: { 'left': '235px !important', 'width': '257px' },
          cellEditorParams: { values: this.reqList }
        },
        {
          colId: '1',
          field: "requestID",
          hide: true,
          headerName: ''
        }
      ];
    } else if (this.orgMetaDataType === 'Root Cause') {
      this.orgRowMetaData = this.orgRootCauseList;
      this.orgMetaColumnDefs = [
        {
          field: "rootCause",
          width: 493,
          editable:false,
          sort: 'asc', // C153176-5701 | Sorting in Alphabetical order.
          comparator: function (a, b) {
            if (typeof a === 'string') {
              return a.localeCompare(b);
            } else {
              return (a > b ? 1 : (a < b ? -1 : 0));
            }
          }
        },
        {
          colId: '1',
          field: "rootID",
          hide: true,
          headerName: ''
        }
      ];
    }  
    this.metaCheckbox = {
      width: 100,
      checkboxSelection: this.isFirstColumn,
      resizable: true,
      suppressMenu: true,
      suppressMovable: true,
      editable: true,
      cellStyle: { 'background-color': 'white', 'height': '41px' }
    };
  }
  onOrgMetaDataGridReady(params) {
    this.orgMetaDataGridApi = params.api;
    this.orgMetaDataGridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  }
   //C170665-850 for get org level metadata
   populateOrgMetaData(element){
    this.orgRequestTypeList=[];
    this.orgRootCauseList=[];
     try{
       if(element){
         // for highlevel request type root cause mapping
         if(element.requestTypes && element.highLevelRequestTypeMapping){
           element.highLevelRequestTypeMapping.forEach(req=>{  
           this.orgRequestTypeList.push({'requestType':req.requestType,'highlevelRequestType':req.highlevelRequestType,'requestID':req.requestType});
           })  
          }else{
           this.orgRequestTypeList=[];
         }
       // root cause
       if(element.rootCause){
         element.rootCause.forEach(rootCause=>{
          this.orgRootCauseList.push({ 'rootCause': rootCause, 'rootID': rootCause });
         })
       }else{
         this.orgRootCauseList=[];
       }
       // for request type root cuase mapping
       this.orgReqTypeRootCauseMap = [];
       this.updatedOrgReqTypeCauseMap = []; 
       let orgReqTypeRootCauseMap = element.requestTypeRootCauseMapping;
       if (orgReqTypeRootCauseMap) {
           for (const [key, value] of Object.entries(orgReqTypeRootCauseMap)) {
                if (Array.isArray(value)) {
                  this.orgReqTypeRootCauseMap.push({ 'requestType': key, 'rootCause': value });
                }
              }
           }else{
              this.orgReqTypeRootCauseMap=[];
           }
           this.orgRequestTypes = element.requestTypes ? [...element.requestTypes].sort() : [];
           this.orgRootCauses = element.rootCause ? [...element.rootCause].sort() : [];
       }else{
         this.initializeOrgMetaData();
       }
     }catch(error){
       console.log("Exception has been occured in UserComponent.populateOrgMetaData()"+error.message);
     }
   this.selectOrgMetaDataType(this.orgMetaDataType, 'onload')
 }
 initializeOrgMetaData(){
  this.orgRequestTypeList=[];
  this.orgRootCauseList=[];
  this.orgReqTypeRootCauseMap=[];
  this.orgRequestTypes=[];
  this.orgRootCauses=[];
  this.displayOrgMetadata=false;
  this.orgRowMetaData=[];
}
isFirstColumn(params) {
  var displayedColumns = params.columnApi.getAllDisplayedColumns();
  var thisIsFirstColumn = displayedColumns[0] === params.column;
  return thisIsFirstColumn;
}
}
import {Directive, HostListener} from '@angular/core';

@Directive({
  selector: '[disable-nclick]'
})

/**
 * XXXXX-5273: directive to prevent triggering of multiple consecutive clicks
 */
export class DisableMultiClickDirective {

  @HostListener('click', ['$event'])
  public handleClick(event: any) {
    let currentTs = Date.now();
    let disabled = event.srcElement.getAttribute('disabled')
    if (disabled) {
      event.preventDefault();
      event.stopImmediatePropagation();
      return;
    }
    event.srcElement.setAttribute('disabled', 'disabled');
    setTimeout(() => {
      event.srcElement.removeAttribute('disabled');
    }, 1500);
  }
}
import { Injectable, Output, EventEmitter, OnInit, OnDestroy, Directive } from '@angular/core';
import { AppUtils } from '../../common/utility/appUtil';
import { Subscription } from 'rxjs';

@Directive()
export class ContextMenuValidator implements OnInit, OnDestroy {
  action: string;
  selectedRows: any;
  loginUserData: any;
  selectedMail: any;
  actionPopupComp: any;
  subscription: Subscription;
  errorMessage: string;
  showPopup: boolean;
  @Output() serviceNotifier: EventEmitter<string> = new EventEmitter();

  constructor() {
    this.subscription = this.serviceNotifier.subscribe((message) => {
        if(this.actionPopupComp && this.showPopup) {
            this.actionPopupComp.open('Validation Service Error', message, false, true, false, false, false);
        }
    });
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    if (this.subscription) {
        this.subscription.unsubscribe();
    }
  }

  /**
   * 
   * @param val Valiate aprove action
   * @param selectedRows
   * @param: actionPopupComp
   * @param loginUserData 
   */
  validateApproveAction(val: any, selectedRows: any, actionPopupComp: any, loginUserData: any, selectedMail: any): any {
      if(val && (val === "Approve" || val.action === 'Approve')) {
        this.action = "Approve";
        this.selectedRows = selectedRows;
        this.loginUserData = loginUserData;
        this.selectedMail = selectedMail;
        this.actionPopupComp = actionPopupComp;
        return this.validateApprove(loginUserData);
      } else {
        return { res: false, message: 'Unsupported action :' + val, popup: true };
      }
  }

  /**
   * Validate 'Approve' action
   * @param selectedRows
   * @param loginUserData 
   */
  validateApprove(loginUserData: any): any {
    if (!this.selectedRows || this.selectedRows.length === 0) {
      	return { res: false, message: 'No item selected!', popup: true};
    }
    if (this.selectedRows.length > 1) {
        console.log('More than one item is selected and bulk approve is not supported');
        return { res: false, message: 'Approve action is not applicable for bulk selection.', popup: true};
    }
    // Checks for basic validations before confirmation from user
    if (!this.makerCheckerValidations(loginUserData)) {
      return { res: false, message: 'Maker/checker validation failed.', popup: true};
    }

    if (!this.checkExternalDomain()) {
        
        console.log('The mail has more than one external address.');

        // Provide the confirmation window for the user to agree
        let question = "The approval you are about to make has more than one external email domain. " +
           " Do you want to proceed?";
        this.actionPopupComp.data = this.getUpdatePendingInquiryData();
        this.actionPopupComp.open("Confirmation", question, false, false, false, true, true);
    } else {
        // sending null value as a parameter since the element field is not required.
        this.actionPopupComp.data = this.getUpdatePendingInquiryData();
        this.actionPopupComp.updatePendingInquiry();
    }
    return { res: true, popup: false };
  }

  /**
   * 
   * @param val Valiate reject action
   * @param selectedRows
   * @param: actionPopupComp
   * @param loginUserData 
   */
  validateRejectAction(val: any, selectedRows: any, actionPopupComp: any, loginUserData: any, selectedMail: any): any {
    if(val && (val === "Reject" || val.action === 'Reject')) {
      this.action = "Reject";
      this.selectedRows = selectedRows;
      this.loginUserData = loginUserData;
      this.selectedMail = selectedMail;
      this.actionPopupComp = actionPopupComp;
      return this.validateReject(loginUserData);
    } else {
      return { res: false, message: 'Unsupported action :' + val, popup: true };
    }
}

/**
   * Validate 'Reject' action
   * @param selectedRows
   * @param loginUserData 
   */
validateReject(loginUserData: any): any {
  if (!this.selectedRows || this.selectedRows.length === 0) {
      return { res: false, message: 'No item selected!', popup: true};
  }
  if (this.selectedRows.length > 1) {
      console.log('More than one item is selected and bulk reject is not supported');
      return { res: false, message: 'Reject action is not applicable for bulk selection.', popup: true};
  }
  // Checks for basic validations before confirmation from user
  if (!this.makerCheckerValidations(loginUserData)) {
    return { res: false, message: 'Maker/checker validation failed.', popup: true};
  }

  if (!this.checkExternalDomain()) {
      
      // Provide the confirmation window for the user to agree
      let question = "The reject action you are about to make has more than one external email domain. " +
         " Do you want to proceed?";
      this.actionPopupComp.data = this.getUpdatePendingInquiryData();
      this.actionPopupComp.open("Confirmation", question, false, false, false, true, true);
  } else {
      // sending null value as a parameter since the element field is not required.
      this.actionPopupComp.data = this.getUpdatePendingInquiryData();
      this.actionPopupComp.updateRejectInquiry();
  }

  return { res: true, popup: false };
}

  getUpdatePendingInquiryData(elem?: any) {
    let inquiryIdList = [];
    let assignedGroupName = '';
    let direction = '';
    let type = '';
    var successMessage = '';
    this.selectedRows.forEach((dataItem) => {
      //Common group action is allowed here
      const wfIndex = dataItem.workflows.length -1;
      if (dataItem.workflows[wfIndex] && dataItem.workflows[wfIndex].direction) {
        direction = dataItem.workflows[wfIndex].direction;
      }
    
	  assignedGroupName = dataItem.workflows[wfIndex].assignedGroupName;
	  inquiryIdList.push(dataItem._id);
	  successMessage = successMessage + dataItem._id;
      successMessage = successMessage + ",";
      if (!direction && dataItem.direction) {
          direction = dataItem.direction;
      }
	});

	if (direction){
		if (direction === "PENDINGAPPROVAL") {
			type = "Email Approval";
    }
    else if(direction == 'NOMINATE_OWNERSHIP'){
      type = "NOMINATE_OWNERSHIP";
    } 
    else if(direction === "PND_REAGE") {
			type = "Re Age Inquiry";
		}
    }
    
	if (!inquiryIdList || inquiryIdList.length === 0){
		this.onServiceFailure("Your action cannot be completed, as the inquiry has been updated by the system or another user.", false);
		return null;
	}
	let postData = {
        inquiryIds : inquiryIdList,
        action : this.action,
        assignedGroupName : assignedGroupName,
        type : type,
        successMessage: successMessage
    }
    console.log('update pending inquiry, data =', postData);
    return postData;
  }

  makerCheckerValidations(loginUserData: any) {
    let validationSuccess = false;
    if (this.checkSameApprover()){
        return false;
    } else {
        if ((this.checkPendingApprovalType('PENDINGAPPROVAL')  && this.checkPeerReviewer(loginUserData)) || 
                (this.checkPendingApprovalType('PND_REAGE') && this.checkSupervisorReviewer(loginUserData)) ||
                (this.checkPendingApprovalType('NOMINATE_OWNERSHIP')  && this.checkSupervisorReviewer(loginUserData)) ) {
            validationSuccess = true;
        }
    }
    return validationSuccess;
  }

  // Maker Checker Validation Helper method
  checkSameApprover(): boolean {
    let isCreatorApproverSame = false;
    this.selectedRows.forEach((dataItem) => {
      const workflows = dataItem.workflows;
      // On the assumption that every record is at the Group Level.
      if (workflows && workflows.length > 0) {
        workflows.forEach((data, idx) => {
            if (data.assignedGroupName === dataItem.openGroups && 
                (data.direction === 'PENDINGAPPROVAL' || data.direction === 'PND_REAGE' || data.direction === 'NOMINATE_OWNERSHIP')) {
                const isMakerDataSame = data.maker === this.loginUserData.userId;
                if (data.maker && isMakerDataSame) {
                    isCreatorApproverSame = true;
                    return false;
                } else if (!data.maker && dataItem.latestUserId === this.loginUserData.userId) {
                    // Case where maker is not present.
                    isCreatorApproverSame = true;
                    return false;
                }
            }
        });
      }
    });

    if (isCreatorApproverSame) {
        console.log('You cannot approve/reject your own inquiry');
        this.onServiceFailure('You cannot approve/reject your own inquiry.', false);
    }

    return isCreatorApproverSame;
  }

  /**
   * This method checks whether inquiry is requested type
   */
  checkPendingApprovalType(directionType: string): boolean {
    let isApproverRoleRequired = false;
    let numberOfDirections = [];
    this.selectedRows.forEach((dataItem) => {
      if (dataItem.direction) {
        numberOfDirections.push(dataItem.direction);
      } else if(dataItem.workflows && dataItem.workflows.length) {
        // take the last (latest) workflow item for direction type checking
        const wfIndex = dataItem.workflows.length -1;
        if (dataItem.workflows[wfIndex] && dataItem.workflows[wfIndex].direction) {
          numberOfDirections.push(dataItem.workflows[wfIndex].direction);
        }
      }
    });
    numberOfDirections = numberOfDirections.filter((v, i, a) => a.indexOf(v) === i);
    if ( numberOfDirections.length === 1 && numberOfDirections[0] === directionType ) {
      isApproverRoleRequired = true;
    }
    return isApproverRoleRequired;
  }

  // Maker Checker Validation Helper method
  checkPeerReviewer(loginUserData: any): boolean { 
    const isMakerCheckerReviewer = this.checkApproverRole('Peer Reviewer', loginUserData);
    if (!isMakerCheckerReviewer) {
        console.log('User is not authorized to review the Inquiries');
        this.onServiceFailure('User is not authorized to review the inquiries.', false);
    }
    return isMakerCheckerReviewer;
  }

  /**
   * This method identified group of selected inquiry and send request further
   * for role checking. 
   */
  checkApproverRole(role: string, loginUserData: any): boolean {
    let canApprove = false;
    let numberOfGroups = [];
    this.selectedRows.forEach((dataItem) => {
        if (dataItem.openGroups){
            numberOfGroups.push(dataItem.openGroups);
        } else if (dataItem.workflows[0] && dataItem.workflows[0].assignedGroupName) {
            numberOfGroups.push(dataItem.workflows[0].assignedGroupName);
        }
    });
    numberOfGroups = numberOfGroups.filter((v, i, a) => a.indexOf(v) === i);
    if(numberOfGroups.length === 1) {
        canApprove = this.checkCanApprove(numberOfGroups[0], role, loginUserData);
    }
    return canApprove;
  }

  /**
   * This method checks whether user can approve the request or not
   */
  checkCanApprove(selectedInqGrp, role, loginUserData: any): boolean{
    let canApprove = false;
    if (selectedInqGrp && role) {
      /*   for (const currentGrpRoleDetails of loginUserData.groupRoles.length) {
            if (currentGrpRoleDetails
                && ( currentGrpRoleDetails.groupName.trim() === selectedInqGrp.trim() )
                && (currentGrpRoleDetails.groupRole.indexOf(role) > -1) ) {
                canApprove = true;
            }
        } */
        loginUserData.groupRoles.forEach(currentGrpRoleDetails=>{
            if(currentGrpRoleDetails && currentGrpRoleDetails.groupName.trim() === selectedInqGrp.trim() && (currentGrpRoleDetails.groupRole.indexOf(role) > -1) ){
                canApprove = true;
            }
        })
       
    }
    return canApprove;
  }

  /**
   * This method checks whether user have supervisor role or not
   */
 checkSupervisorReviewer(loginUserData: any): boolean {
    const isMakerCheckerSupervisor = this.checkApproverRole('Supervisor', loginUserData);
    if (!isMakerCheckerSupervisor) {
        console.log('User is not authorized to approve/reject the Request.');
        this.onServiceFailure('User is not authorized to approve/reject the Request.', false);
    }

    return isMakerCheckerSupervisor;
  }

  checkExternalDomain(): boolean {
    if (!this.selectedMail || !this.selectedMail.conversationList 
        || !this.selectedMail.conversationList.length 
        || !this.selectedMail.conversationList[0].recipients
        || !this.selectedMail.conversationList[0].recipients.length) {
        console.log('Invalid ')
        return true;
    }
    const recipients = this.selectedMail.conversationList[0].recipients;
    const externalAddresses = [];
    recipients.forEach((r) => {
        if (r && r.emailAddr && !AppUtils.isCitiDomainEmail(r.emailAddr, this.loginUserData.citiDomainList)) {
            let mailId = r.emailAddr;
            mailId = mailId.substr(mailId.indexOf('@') + 1, mailId.length);

            // Duplicate domain prevention
            if (externalAddresses.length == 0 || externalAddresses.indexOf(mailId) == -1) {
                externalAddresses.push(mailId); // C153176-6034- Push only domain instead of email address.
            }
        }
    });
    return externalAddresses.length <= 1;
  }

  onServiceFailure(message: string, showPopup: boolean) {
      this.errorMessage = message? message: this.errorMessage;
      this.showPopup = showPopup;
      this.serviceNotifier.emit(message);
  }
}

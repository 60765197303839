<p-dialog [(visible)]="display" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'"
  [styleClass]="'contextMenuModal profileModal'" [closable]="false" [dismissableMask]="true" [baseZIndex]="10000"
  [closeOnEscape]="true" [style]="{'height':'830px','max-width':'1350px', 'width': '90%'}" [resizable]="false">

  <p-header>
    <div class="d-flex flex-row align-items-center justify-content-between" style="padding: 5px 5px 0px px;">
      <!-- <span class="gfid-title" style="font-size: 12px;width: 12em;"> </span> -->
      <h6 class="gfid-title" style="font-size: 20px">MAILBOX ONBOARDING</h6>
      <button type="button" (click)="hideModal()" class="d-flex flex-row close qma-text"
        style="border:none !important;opacity:1">
        <img src="assets/Inbox/close_icon_u73.svg" style="opacity:1" alt="Close">
        <h6>CLOSE</h6>
      </button>
    </div>
  </p-header>

  <div class="gfid-body admin-gfid-padding">
    <ng-template #customLoadingTemplate>
      <div class="custom-class-loader">
        <div class="spinner-container">
          <svg class="loader" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340 340">
            <circle cx="170" cy="170" r="160" stroke="#021e48" />
            <circle cx="170" cy="170" r="135" stroke="#00bdf2" />
            <circle cx="170" cy="170" r="110" stroke="#021e48" />
            <circle cx="170" cy="170" r="85" stroke="#00bdf2" />
          </svg>
        </div>
      </div>
    </ng-template>
    <ngx-loading [show]="showSpinner" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate">
    </ngx-loading>

    <div class="w-80">
      <p-tabView class="adminview" #mailBoxDLMappingTabs (onChange)="onTabChange($event)" [styleClass]="'admin-tabview'"
        [scrollable]="false"
        [style]="{'height':'685px','width':'90%','max-width':'1200px','margin-left':'4rem',overflow: 'hidden', 'position': 'relative'}"
        [activeIndex]="actTabIdx">
        <p-tabPanel header="MailBox Details" class="tab-heading">
          <div class="container">
            <div class="row p-2">
              <div class="col-3">
                <label for="from" class="flex-grow pull-right">Mapping Type: </label>
              </div>
              <div class="col-9">
                <p-dropdown [styleClass]="'prime-dd-override '" class="drop-down w-50"
                  [(ngModel)]="mailboxUIData.mappingType" [options]="mappingTypeList">
                </p-dropdown>
                <input type="checkbox" class="m-2" [(ngModel)]="isNewDLOnboarding" (change)="toggleNewDLMapMode()">
                <span>Add New Mapping</span>
              </div>
            </div>
            <div *ngIf="mailboxUIData.mappingType=='DEDICATED'">
              <div class="row p-2">
                <div class="col-3">
                  <label for="from" class="flex-grow pull-right">Mailbox Name: </label>
                </div>
                <div class="col-9 mt-1">
                  <input type="text" [(ngModel)]="mailboxUIData._id"
                    class="w-50  t-box inp-txt-mng group-details-input-mng" (input)="mailboxNameErrorMessage=''" />
                  <span *ngIf="!isNewDLOnboarding" style="padding-top:10px;">
                    <button style="width: 86px !important;opacity:1;float: inherit !important;" type="button"
                      class="close qma-text grp-details-mng-lookup" (click)="callSearchService('DLName')">
                      <img class="qma-text" style="opacity:1" src="assets/Inbox/search_icon_selected_u42.svg" alt="Search">
                      <h6 style="font-size:14px;padding-left:3px;display: inline;">SEARCH</h6>
                    </button>
                  </span>
                  <p class="has-error" *ngIf="mailboxNameErrorMessage!=''">{{mailboxNameErrorMessage}}</p>
                </div>
              </div>
              <ng-container *ngIf="isNewOrSearch">
                <div class="row p-2">
                  <div class="col-3">
                    <label for="from" class="flex-grow pull-right">DL Name: </label>
                  </div>
                  <div class="col-9 mt-1">
                    <input type="text" [(ngModel)]="mailboxUIData.mappedDL"
                      class="w-50  t-box inp-txt-mng group-details-input-mng" (input)="dlNameErrorMessage=''" />
                    <p class="has-error" *ngIf="dlNameErrorMessage!=''">{{dlNameErrorMessage}}</p>
                  </div>
                </div>

                <div class="row p-2">
                  <div class="col-3">
                    <label for="from" class="flex-grow pull-right">Login Secret: </label>
                  </div>
                  <div class="col-9 mt-2">
                    <input type="password" [(ngModel)]="mailboxUIData.loginSecret"
                      class="w-50  t-box inp-txt-mng group-details-input-mng"
                      (input)="mailboxLoginSecretErrorMessage=''" />
                    <p class="has-error" *ngIf="mailboxLoginSecretErrorMessage!=''">{{mailboxLoginSecretErrorMessage}}
                    </p>
                  </div>
                </div>
                <div class="row p-2">
                  <div class="col-3">
                    <label for="from" class="flex-grow pull-right">Confirm Login Secret: </label>
                  </div>
                  <div class="col-9 mt-2">
                    <input type="password" [(ngModel)]="confirmMailboxLoginSecret"
                      class="w-50  t-box inp-txt-mng group-details-input-mng"
                      (input)="confirmMailboxLoginSecretErrorMessage=''" />
                    <p class="has-error" *ngIf="confirmMailboxLoginSecretErrorMessage!=''">
                      {{confirmMailboxLoginSecretErrorMessage}}</p>
                  </div>
                </div>
              </ng-container>
            </div>

            <div *ngIf="mailboxUIData.mappingType=='PERSONAL'" class="row p-2">
              <div class="col-3">
                <label for="from" class="flex-grow pull-right">SOE ID: </label>
              </div>
              <div class="col-9 mt-1">
                <input type="text" [(ngModel)]="mailboxUIData._id"
                  class="w-50  t-box inp-txt-mng group-details-input-mng" (input)="soeIdErrorMessage=''" />
                <span *ngIf="!isNewDLOnboarding" style="padding-top:10px;">
                  <button style="width: 86px !important;opacity:1;float: inherit !important;" type="button"
                    class="close qma-text grp-details-mng-lookup" (click)="callSearchService('SOEID')">
                    <img class="qma-text" style="opacity:1" src="assets/Inbox/search_icon_selected_u42.svg" alt="Search">
                    <h6 style="font-size:14px;padding-left:3px;display: inline;">SEARCH</h6>
                  </button>
                </span>
                <p class="has-error" *ngIf="soeIdErrorMessage!=''">{{soeIdErrorMessage}}</p>
              </div>
            </div>
            <ng-container *ngIf="isNewOrSearch">
              <div class="row p-2">
                <div class="col-3">
                  <label for="from" class="flex-grow pull-right">Minimum Required Memory(In MB): </label>
                </div>
                <div class="col-9">
                  <p-dropdown [styleClass]="'prime-dd-override '" class="drop-down w-50"
                    [(ngModel)]="mailboxUIData.minMemoryRequirementInMB" [options]="memoryRequirementInMBList"
                    (onChange)="memoryReqErrorMessage=''">
                  </p-dropdown>
                  <p class="has-error" *ngIf="memoryReqErrorMessage!=''">{{memoryReqErrorMessage}}</p>
                </div>
              </div>
              <div class="row p-2">
                <div class="col-3">
                  <label for="from" class="flex-grow pull-right">Maximum Required Memory(In MB): </label>
                </div>
                <div class="col-9">
                  <p-dropdown [styleClass]="'prime-dd-override '" class="drop-down w-50"
                    [(ngModel)]="mailboxUIData.maxMemoryRequirementInMB" [options]="memoryRequirementInMBList"
                    (onChange)="memoryReqErrorMessage=''">
                  </p-dropdown>
                  <p class="has-error" *ngIf="memoryReqErrorMessage!=''">{{memoryReqErrorMessage}}</p>
                </div>
              </div>
              <div *ngIf="!isNewDLOnboarding">
                <div class="row p-2">
                  <div class="col-3">
                    <label for="from" class="flex-grow pull-right">Is To Be Force Started: </label>
                  </div>
                  <div class="col-9 mt-1">
                    <span class="group-details-input-mng">{{mailboxUIData.isToBeForceStarted? 'Y': 'N'}}</span>
                  </div>
                </div>
                <div class="row p-2">
                  <div class="col-3">
                    <label for="from" class="flex-grow pull-right">Is To Be Force Stopped: </label>
                  </div>
                  <div class="col-9 mt-1">
                    <span class="group-details-input-mng">{{mailboxUIData.isToBeForceStopped? 'Y': 'N'}}</span>
                  </div>
                </div>
                <div class="row p-2">
                  <div class="col-3">
                    <label for="from" class="flex-grow pull-right">Current Running Status: </label>
                  </div>
                  <div class="col-9 mt-1">
                    <span class="group-details-input-mng">{{mailboxUIData.currentRunStatus}}</span>
                  </div>
                </div>
                <div class="row p-2">
                  <div class="col-3">
                    <label for="from" class="flex-grow pull-right">Started At: </label>
                  </div>
                  <div class="col-9 mt-1">
                    <span
                      class="group-details-input-mng">{{mailboxUIData.startedAt?.$date | date: 'dd/MM/yyyy HH:mm:ss'}}</span>
                  </div>
                </div>
                <div class="row p-2">
                  <div class="col-3">
                    <label for="from" class="flex-grow pull-right">Started On: </label>
                  </div>
                  <div class="col-9 mt-1">
                    <span class="group-details-input-mng">{{mailboxUIData.startedOn}}</span>
                  </div>
                </div>
                <div class="row p-2">
                  <div class="col-3">
                    <label for="from" class="flex-grow pull-right">Modified By: </label>
                  </div>
                  <div class="col-9 mt-1">
                    <span class="group-details-input-mng">{{mailboxUIData.modBy}}</span>
                  </div>
                </div>
                <div class="row p-2">
                  <div class="col-3">
                    <label for="from" class="flex-grow pull-right">Modified Date: </label>
                  </div>
                  <div class="col-9 mt-1">
                    <span
                      class="group-details-input-mng">{{mailboxUIData.modDate?.$date | date: 'dd/MM/yyyy HH:mm:ss'}}</span>
                  </div>
                </div>
                <div class="row p-2">
                  <div class="col-3">
                    <label for="from" class="flex-grow pull-right">Created By: </label>
                  </div>
                  <div class="col-9 mt-1">
                    <span class="group-details-input-mng">{{mailboxUIData.crtBy}}</span>
                  </div>
                </div>
                <div class="row p-2">
                  <div class="col-3">
                    <label for="from" class="flex-grow pull-right">Created Date: </label>
                  </div>
                  <div class="col-9 mt-1">
                    <span
                      class="group-details-input-mng">{{mailboxUIData.crtDate?.$date | date: 'dd/MM/yyyy HH:mm:ss'}}</span>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>

        </p-tabPanel>
        <!--exchange details-->
        <p-tabPanel *ngIf="mailboxUIData.mappingType=='DEDICATED'" header="Exchange Details" class="tab-heading">
          <div class="container">
            <div class="row p-2">
              <div class="col-3">
                <label for="from" class="flex-grow pull-right">Host Name: </label>
              </div>
              <div class="col-9">
                <input type="text" [(ngModel)]="mailboxUIData.hostName"
                  class="w-50  t-box inp-txt-mng group-details-input-mng" (input)="hostNameeErrorMessage=''" />
                <p class="has-error" *ngIf="hostNameeErrorMessage!=''">{{hostNameeErrorMessage}}</p>
              </div>
            </div>
            <div class="row p-2">
              <div class="col-3">
                <label for="from" class="flex-grow pull-right">Attachment Path: </label>
              </div>
              <div class="col-9 mt-2">
                <input type="text" [(ngModel)]="mailboxUIData.attachmentPath"
                  class="w-50  t-box inp-txt-mng group-details-input-mng" (input)="attachmentPathErrorMessage=''" />
                <p class="has-error" *ngIf="attachmentPathErrorMessage!=''">{{attachmentPathErrorMessage}}</p>
              </div>
            </div>
            <div class="row p-2">
              <div class="col-3">
                <label for="from" class="flex-grow pull-right">Wait Time Between Poll: </label>
              </div>
              <div class="col-9 mt-2">
                <input type="text" [(ngModel)]="mailboxUIData.waitTimeBetweenPoll"
                  class="w-50  t-box inp-txt-mng group-details-input-mng" (input)="waitTimeBWPollErrorMessage=''" />
                <p class="has-error" *ngIf="waitTimeBWPollErrorMessage!=''">{{waitTimeBWPollErrorMessage}}</p>
              </div>
            </div>
            <div class="row p-2">
              <div class="col-3">
                <label for="from" class="flex-grow pull-right">Support Email: </label>
              </div>
              <div class="col-9 mt-2">
                <input type="text" [(ngModel)]="mailboxUIData.supportEmail"
                  class="w-50  t-box inp-txt-mng group-details-input-mng" (input)="supportEmailErrorMessage=''" />
                <p class="has-error" *ngIf="supportEmailErrorMessage!=''">{{supportEmailErrorMessage}}</p>
              </div>
            </div>
            <div class="row p-2">
              <div class="col-3">
                <label for="from" class="flex-grow pull-right">Max Error Email Count: </label>
              </div>
              <div class="col-9 mt-2">
                <input type="text" [(ngModel)]="mailboxUIData.maxErrorEmailCount"
                  class="w-50  t-box inp-txt-mng group-details-input-mng" (input)="MaxErrorEmailCountErrorMessage=''" />
                <p class="has-error" *ngIf="MaxErrorEmailCountErrorMessage!=''">{{MaxErrorEmailCountErrorMessage}}</p>
              </div>
            </div>

            <div class="row p-2">
              <div class="col-3">
                <label for="from" class="flex-grow pull-right">Domain: </label>
              </div>
              <div class="col-9 mt-2">
                <input type="text" [(ngModel)]="mailboxUIData.domain"
                  class="w-50  t-box inp-txt-mng group-details-input-mng" (input)="domainErrorMessage=''" />
                <p class="has-error" *ngIf="domainErrorMessage!=''">{{domainErrorMessage}}</p>
              </div>
            </div>
            <div class="row p-2">
              <div class="col-3">
                <label for="from" class="flex-grow pull-right">Version: </label>
              </div>
              <div class="col-9 mt-2">
                <input type="text" [(ngModel)]="mailboxUIData.version"
                  class="w-50  t-box inp-txt-mng group-details-input-mng" (input)="versionErrorMessage=''" />
                <p class="has-error" *ngIf="versionErrorMessage!=''">{{versionErrorMessage}}</p>
              </div>
            </div>
            <div class="row p-2">
              <div class="col-3">
                <label for="from" class="flex-grow pull-right">Exchange Web Service URL: </label>
              </div>
              <div class="col-9 mt-2">
                <input type="text" [(ngModel)]="mailboxUIData.exchangeWebServiceUrl"
                  class="w-50  t-box inp-txt-mng group-details-input-mng"
                  (input)="exchangeWebServiceUrlErrorMessage=''" />
                <p class="has-error" *ngIf="exchangeWebServiceUrlErrorMessage!=''">{{exchangeWebServiceUrlErrorMessage}}
                </p>
              </div>
            </div>
            <div class="row p-2">
              <div class="col-3">
                <label for="from" class="flex-grow pull-right">Allowed Domain: </label>
              </div>
              <div class="col-9 mt-2">
                <input type="text" [(ngModel)]="mailboxUIData.allowedDomains"
                  class="w-50  t-box inp-txt-mng group-details-input-mng" (input)="allowedDomainErrorMessage=''" />
                <p class="has-error" *ngIf="allowedDomainErrorMessage!=''">{{allowedDomainErrorMessage}}</p>
              </div>
            </div>
            <div class="row p-2">
              <div class="col-3">
                <label for="from" class="flex-grow pull-right">Reader Batch Size: </label>
              </div>
              <div class="col-9 mt-2">
                <input type="text" [(ngModel)]="mailboxUIData.readerBatchSize"
                  class="w-50  t-box inp-txt-mng group-details-input-mng" (input)="readerBatchSizeErrorMessage=''" />
                <p class="has-error" *ngIf="readerBatchSizeErrorMessage!=''">{{readerBatchSizeErrorMessage}}</p>
              </div>
            </div>
          </div>

        </p-tabPanel>
        <!--for error message-->
        <p-dialog [(visible)]="hasError" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'"
          [styleClass]="'contextMenuModal errorModal ModelClose'" [closable]="true" [dismissableMask]="true"
          [baseZIndex]="10000" [closeOnEscape]="true" [style]="{width: '30%'}" (hide)="hasError=false">
          <p-header>
            <div class="d-flex flex-row align-items-center justify-content-between">
              <h6 class="success-title">Error Message</h6>
            </div>
          </p-header>
          <div class="success-msg">{{errorMessage}}</div>
        </p-dialog>
        <!--for success message-->
        <p-dialog [(visible)]="hasSuccess" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'"
          [styleClass]="'contextMenuModal successModal ModelClose'" [closable]="true" [dismissableMask]="true"
          [baseZIndex]="10000" [closeOnEscape]="true" [style]="{width: '30%'}" (hide)="hasSuccess=false">
          <p-header>
            <div class="d-flex flex-row align-items-center justify-content-between">
              <h6 class="success-title">Success Message</h6>
            </div>
          </p-header>
          <div class="success-msg">{{successMessage}}</div>
        </p-dialog>

        <p-footer class="fix-footer">
          <div class="d-flex flex-row footer-btn-container"></div>
          <div class="d-flex flex-row flex-wrap bottom-button-holder px-3 pb-2 pt-3 justify-content-end">
            <button *ngIf="!isNewDLOnboarding" type="submit" class="btn save-btn mr-4"
              [disabled]="mailboxUIData.currentRunStatus=='STARTED' || mailboxUIData.currentRunStatus==''"
              (click)="start()">Start</button>
            <button *ngIf="!isNewDLOnboarding" type="submit" class="btn save-btn mr-4"
              [disabled]="mailboxUIData.currentRunStatus=='STOPPED' || mailboxUIData.currentRunStatus==''"
              (click)="stop()">Stop</button>
            <button *ngIf="isNewDLOnboarding" type="submit" class="btn save-btn mr-4" (click)="add()">Create</button>
            <button *ngIf="!isNewDLOnboarding" type="submit" class="btn save-btn mr-4"
              (click)="update()">Update</button>
            <button *ngIf="!isNewDLOnboarding" type="submit" class="btn save-btn mr-4"
              (click)="remove()">Remove</button>
            <button type="button" class="btn clear-btn" (click)="resetForm()">Reset</button>
          </div>
        </p-footer>
      </p-tabView>
    </div>
  </div>
</p-dialog>
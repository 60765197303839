import { LoginUserInfo } from 'src/app/model/LoginUserInfo/LoginUserInfo';
import { Component, ViewChildren, ViewChild, QueryList, EventEmitter, Input, Output } from '@angular/core';
import { NameCount } from '../../model/Common/NameCount';
import { MyViewsService } from '../../services/myViews/my-views.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CheckboxListComponent } from '../../common/component/checkbox-list/checkbox-list.component';
import { ActionPopupComponent } from 'src/app/common/component/action-popup/action-popup.component';
import { QmaConstant } from '../../constant/qma-constant';

import * as $ from 'jquery';
import '../../../../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js';
import '../../../../node_modules/bootstrap-datepicker/dist/js/bootstrap-datepicker.js';
// import 'src/assets/myviews/query-builder.myviews.css';
import '../../../../node_modules/jQuery-QueryBuilder/dist/js/query-builder.js';
import '../../../../node_modules/jquery-extendext/jQuery.extendext.js';
import '../../../../node_modules/dot/doT.js';
import { UserDataService } from "src/app/services/user-data.service";
import * as moment from 'moment';
import { PiwikProUtils } from 'src/app/common/utility/piwikProUtils';
import { AppUtils } from 'src/app/common/utility/appUtil';

@Component({
  selector: 'app-myviews',
  templateUrl: './myviews.component.html',
  styleUrls: ['./myviews.component.scss']
})
export class MyviewsComponent {
  @ViewChildren(CheckboxListComponent) checkboxListComponents: QueryList<CheckboxListComponent>;
  @ViewChild(ActionPopupComponent, { static: true }) actionPopupComponent: any;
  public myViewsForm: FormGroup;
  viewName: string;
  selAssignedGrps: any;
  selBoxes: any;
  scroll: any = 'native';
  disableSubmit: boolean = false;
  isNew = false;
  qb: any;
  @Input() myViewsItems: NameCount[] = [];

  title = 'test-app';
  dateFormat = 'DD/MMM/YYYY';
  private timer;
  protected filterDataWithWorkFlow: any;
  protected criteria: any;
  protected defaultCriteria: any;

  myViewColumns: NameCount[] = [];
  mailboxesJson: any = [
    {
      name: "searchInbox",
      value: "Inbox",
      label: "Inbox",
    },
    {
      name: "searchResolved",
      value: "Resolved",
      label: "Resolved",
    },
    {
      name: "searchSent",
      value: "Outbox",
      label: "Sent",
    },
    {
      name: "searchFollowUp",
      value: "FollowUp",
      label: "Follow Up",
    },
    {
      name: "searchPending",
      value: "Pending Approval",
      label: "Pending Approval",
    },
    {
      name: "searchPotentialEscalations",
      value: "Potential Escalations",
      label: "Potential Escalations",
    }
  ];
  error: any = { isError: false, errorMessage: '' };
  queryBuilderReady: boolean;
  queryRulesReady: boolean;
  allowGetUserView: boolean;
  userFilterColumns;
  specialChars = ['\\', '^', '$', '.', '|', '?', '*', '+', '(', ')', '[', '{'];
  userView: any;
  allowedMonthsInMyViews = 0;
  constructor(private myViewService: MyViewsService, private formBuilder: FormBuilder, private userDataService: UserDataService) {
    this.queryBuilderReady = false;
    this.queryRulesReady = false;
    this.allowGetUserView = true;
    this.myViewsForm = formBuilder.group({
      mailboxes: this.buildMailBoxes(true),
      viewName: ['', Validators.required],
      query: ['', Validators.required]
    });

    this.myViewService.getUserViewSubj().subscribe(userView => {
      this.userView = userView;
      this.loadMyView(userView);
    });
    this.myViewService.getSaveUserViewSubj().subscribe(response => {
      if (response && response.processingstatus === "true") {
        this.showValidate("MyView '" + this.viewName + "' " + (this.isNew ? "created" : "updated") + " successfully!");
        // Update the loggedin user view
        if (this.isNew) {
          this.userDataService.setUserView(this.viewName, this.isNew);
        }
        this.clearSearch(false);
        this.myViewService.onMyViewUpdate.emit({ viewName: this.viewName, type: 'add' });
        this.myViewService.onCloseMyView.emit(this.isNew);
      } else {
        this.showValidate("Failed to save '" + this.viewName + "'!");
      }
      this.disableSubmit = false;
    });

  }

  loadMyView(userView) {
    if (!this.allowGetUserView) {
      this.allowGetUserView = true;
      return;
    }
    this.userFilterColumns = userView.filterColumns;
    if (userView.filterColumns) {
      this.myViewColumns = [];
      userView.filterColumns.forEach(elem => {
        this.myViewColumns.push(elem);
      });
    }
    if (userView.defaultCriteria) {
      this.defaultCriteria = userView.defaultCriteria;
    }
    if (userView.criteria) {
      this.criteria = userView.criteria;
    }
    this.queryRulesReady = false;
    const me = this;
    setTimeout(() => {
      me.processFilterAndColumns(userView);
      me.processDefinitions(userView);
      me.buildQueryBuilder();
    }, 100);
  }

  criteriaClick(event) {
    if (event && event.index === 1) {
      this.buildQueryBuilder();
    }
    PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled, this.userDataService.loggedInUserInfo.userId, "Menu", "MY VIEWS DEFINATION/CRITERIA", "Click", "MY VIEWS DEFINATION/CRITERIA", 0);
  }

  buildQueryBuilder() {
    const qb = $("#builder-criteria");
    if (qb && qb.length) {
      if (!this.queryBuilderReady) {

        qb.queryBuilder({
          filters: this.filterDataWithWorkFlow,
          icons: {
            add_rule: 'icon-plus1815',
            add_group: 'icon-plus1815',
            remove_rule: 'icon-delete1698',
            remove_group: 'icon-delete1698'
          },
          lang: {
            add_rule: '<span style="text-decoration:underline;">Add rule</span>',
            add_group: '<span style="text-decoration:underline;">Add group</span>',
            delete_rule: '<span style="text-decoration:underline;">Cancel</span>',
            delete_group: '<span style="text-decoration:underline;">Cancel</span>'
          },
          select_placeholder: 'Select Field...',
          templates: {
            operatorSelect: '\
            {{? it.operators.length === 1 }} \
            \
            {{?}} \
            {{ var optgroup = null; }} \
            <select class="form-control {{? it.operators.length === 1 }}hide{{?}}" name="{{= it.rule.id }}_operator"> \
              <option value="Select Condition..." disabled selected>Select Condition...</option> \
              {{~ it.operators: operator }} \
                {{? optgroup !== operator.optgroup }} \
                  {{? optgroup !== null }}</optgroup>{{?}} \
                  {{? (optgroup = operator.optgroup) !== null }} \
                    <optgroup label="{{= it.translate(it.settings.optgroups[optgroup]) }}"> \
                  {{?}} \
                {{?}} \
                <option value="{{= operator.type }}" \
                {{? operator.icon}}data-icon="{{= operator.icon}}"{{?}}>{{= it.translate("operators", operator.type) }}</option> \
              {{~}} \
              {{? optgroup !== null }}</optgroup>{{?}} \
            </select>',
            ruleValueSelect: '\
            {{ var optgroup = null; }} \
            <select class="form-control" name="{{= it.name }}" {{? it.rule.filter.multiple }}multiple{{?}}> \
                <option value="Enter Value..." disabled selected>Enter Value</option> \
              {{~ it.rule.filter.values: entry }} \
                {{? optgroup !== entry.optgroup }} \
                  {{? optgroup !== null }}</optgroup>{{?}} \
                  {{? (optgroup = entry.optgroup) !== null }} \
                    <optgroup label="{{= it.translate(it.settings.optgroups[optgroup]) }}"> \
                  {{?}} \
                {{?}} \
                <option value="{{= entry.value }}">{{= entry.label }}</option> \
              {{~}} \
              {{? optgroup !== null }}</optgroup>{{?}} \
            </select>'
          },
        });
        this.queryBuilderReady = true;
        if (!this.qb || !this.qb.length) {
          this.qb = qb;
        }
      }
      if (!this.queryRulesReady) {
        let criteria = this.criteria;
        if (criteria) {
          criteria = JSON.parse(criteria);

          criteria = this.processCriteria(criteria);
          if (criteria && criteria.$and && criteria.$and.length > 1) {
            qb.queryBuilder('setRulesFromMongo', criteria.$and[1]);
          }
        }
        // When rules changed :
        qb.on('getRules.queryBuilder.filter', (e) => {
        });
        this.queryRulesReady = true;
      }
    } else {
      console.log("query builder element not available");
    }
  }



  processFilterAndColumns(data): boolean {
    if (!data) {
      return false;
    }
    const filterData = data.filterColumns;
    if (!filterData) {
      return false;
    }
    let criteria = data.criteria;
    if (data.defaultCriteria) {
      criteria = data.defaultCriteria;
    }
    const workFlowColumnDefUi = data.workFlowColumnDefUi2;
    if (workFlowColumnDefUi) {
      workFlowColumnDefUi.forEach((w) => {
        w.placeholder = 'Type Value...';
      });
    }

    // Filter fields to be hidden for Criteria
    const criteriaFilterFields = ["notesFlag", "attachFlag", "status"];
    for (let j = criteriaFilterFields.length - 1; j >= 0; j--) {
      for (let i = filterData.length - 1; i >= 0; i--) {
        if (filterData[i] && filterData[i].id === criteriaFilterFields[j]) {
          filterData.splice(i, 1);
          break;
        }
      }
    }
    this.filterDataWithWorkFlow = this.generateFilterColumns(filterData, workFlowColumnDefUi);
    try {

      let performanceCOnfig = this.userDataService.loggedInUserInfo.defaultViewPerformanceConfig;
      let allowedMonths = performanceCOnfig ? performanceCOnfig.viewsConfig.find(vc => vc.viewName === "CustomViews").months : 3;
      this.allowedMonthsInMyViews = allowedMonths;
      let comp = this;
      this.addDateValidationToFilters(this.filterDataWithWorkFlow.filter(fd => fd.type === "date"), allowedMonths, comp);
      this.criteria = criteria;

    } catch (e) {
      console.error('Error while adding validation in myview filter:- ' + e);
    }
    return true;
  }

  generateFilterColumns(filterData, workFlowColumnDefUi) {
    if (filterData && workFlowColumnDefUi) {
      for (const fd of filterData) {
        const entries = workFlowColumnDefUi.filter((f) => {
          return f.label === fd.label;
        });

        if (!(entries && entries.length)) {
          const isAddToViewList: boolean = fd.label !== 'Re-Open Date' && fd.label !== 'Re-Open Age';
          if (fd.label && isAddToViewList && (!fd.disableFilter || fd.disableFilter === 'N')) {
            fd.placeholder = 'Type Value...';
            workFlowColumnDefUi.push(fd);
          }
        }
      }
    }
    if (workFlowColumnDefUi) {
      workFlowColumnDefUi = workFlowColumnDefUi.sort((a, b) => { a.label > b.label ? 1 : -1 });
    }
    return workFlowColumnDefUi;
  }

  processDefinitions(userView) {
    // set view name
    if (this.viewName && this.viewName.length > 0) {
      $('#myviews-view-name').val(this.viewName);
    }
    let viewType;
    if (this.criteria) {
      const criteria = JSON.parse(this.criteria);
      if (criteria.$and && criteria.$and.length && criteria.$and[0].viewType) {
        viewType = criteria.$and[0].viewType;
      }
    }
    const selectedGroups = userView.selectedShowCols;
    if (this.checkboxListComponents) {
      this.checkboxListComponents.forEach((c) => {
        if (c && c.name === 'mail' && viewType) {
          c.applySelection([{ label: viewType }]);
        } else if (c && c.name === 'groups' && selectedGroups && selectedGroups.length) {
          if (c.items && c.items.length > 0 && c.filteredItems.length === 0) {
            c.assignCopy();
          }
          c.applySelection(selectedGroups);
        }
      });
    }
  }

  getSelectedBox(selBoxes: any) {
    this.selBoxes = selBoxes;
  }

  getAssignedGroups(selAssignedGrps: any) {
    this.selAssignedGrps = selAssignedGrps;
  }

  buildMailBoxes(reset) {
    const arr = this.mailboxesJson.map(mailboxItem => {
      if (reset) {
        mailboxItem.binary = false;
      }
      return this.formBuilder.control(mailboxItem.binary);
    });
    return this.formBuilder.array(arr);
  }

  showValidate(message) {
    if (this.actionPopupComponent) {
      this.actionPopupComponent.open("MyViews", message,
        false, true, false, false, false);
    } else {
      console.log(message);
    }
  }

  /**
   * Submit new or updated my view to backend, including validation necessary before the submit.
   * @param value structure of view name
   */
  submitMyViews(value: any) {

    this.viewName = value ? value.viewName : undefined;
    if (!this.viewName) {
      this.showValidate("View name is required. Please specify view name!");
      return;
    } else if (this.viewName.length > 50) {
      this.showValidate("View name should not be more than 50 characters!!");
      return;
    } else if (!this.nameValidation(this.viewName)) {
      this.showValidate("View name should not contain special characters other than /&-.");
      return;
    } else if (!this.checkReservedName(this.viewName)) {
      this.showValidate("This view name is reserved for the default mailbox. Please try a different name.");
      return;
    } else if (!this.checkDupViewName(this.viewName)) {
      this.showValidate("This view name duplicates with an existing MyView. Please try a different name.");
      return;
    }

    const viewType = this.selBoxes && this.selBoxes.length ? this.selBoxes[0].value : undefined;
    if (!viewType) {
      this.showValidate("Please specify SEARCH IN type!");
      return;
    }

    let criteriaResult;
    let _defaultCriteria = this.defaultCriteria;
    if (this.queryBuilderReady) {
      const qb = $('#builder-criteria');
      if (qb && qb.length) {
        if (!this.qb || !this.qb.length) {
          this.qb = qb;
        }
        // show validation error
        let isFilterValid = qb.queryBuilder('validate');
        if (!isFilterValid) {
          return;
        }

        criteriaResult = $('#builder-criteria').queryBuilder('getMongo');
        _defaultCriteria = $.extend(true, {}, criteriaResult)
        this.convertEmbedeCriteria(criteriaResult);

        if ($.isEmptyObject(criteriaResult)) {
          this.showValidate("Please define a criteria to create a custom view!");
          return;
        }
      } else {
        this.showValidate("No valid criteria specified. Please define a criteria to create a custom view!");
        return;
      }
    } else {
      this.showValidate("Please define a criteria in the criteria tab to create a custom view!");
      return;
    }
    this.disableSubmit = true;
    this.saveQuery(this.viewName, viewType, criteriaResult, _defaultCriteria);
  }

  /**
   * Clear myview edits
   */
  clearSearch(isNew) {
    if (this.actionPopupComponent.display) {
      // do not allow myview dialog to close unless any action popup is closed
      return false;
    }
    if (this.queryBuilderReady) {
      const qb = $('#builder-criteria');
      if (qb && qb.length) {
        qb.queryBuilder('reset');
      } else if (this.qb && this.qb.length) {
        this.qb.queryBuilder('reset');
      }
      this.criteria = undefined;
    }
    this.checkboxListComponents.forEach((comp) => {
      if (isNew) {
        if (comp.name === "mail") {
          comp.items = this.mailboxesJson;
        } else {
          comp.items = this.myViewColumns;
          comp.checkboxes.forEach((element) => {
            element.nativeElement.checked = false;
          });
          comp.checkboxes.first.nativeElement.checked = false;
          comp.selectAllBinary = false;
        }
        comp.selectedValue.length = 0;
        comp.assignCopy();
      }
      comp.resetCheckList();
      comp.clearFilterText();
    });
    this.error.isError = false;
    this.isNew = isNew;
    if (isNew) {
      this.viewName = '';
      this.myViewsForm = this.formBuilder.group({
        mailboxes: this.buildMailBoxes(true),
        viewName: [this.viewName, Validators.required],
        query: ['', Validators.required]
      });
    }
    if (this.actionPopupComponent) {
      this.actionPopupComponent.hideModal();
    }
    return true;
  }

  processCriteria(criteria) {
    if (!criteria) {
      return criteria;
    }
    const criteriaStr = JSON.stringify(criteria);
    const criteriaJSON = JSON.parse(criteriaStr);
    if (this.escapeSpecialChars('key', criteriaJSON, null)) {
      // criteria has been updated, return the updated value aka ;criteriaJSON
      return criteriaJSON;
    } else {
      return criteria;
    }
  }


  escapeSpecialChars(key, value, parent) {
    let result;
    if (value != null && typeof value === 'object') {
      Object.entries(value).forEach(([ckey, cvalue]) => {
        if (this.escapeSpecialChars(ckey, cvalue, value)) {
          result = true;
        }
      });
    } else {
      if (key && key === '$regex') {
        if (value) {

          let filtered = this.specialChars.filter(c => value.indexOf('\\' + c) > -1);
          if (filtered && filtered.length) {
            filtered.forEach(c => {
              value = value.replace('\\' + c, c);
            });
            if (parent) {
              parent[key] = value;
              result = true;
            }
          }
        }
      }
    }
    return result;
  }

  /**
   * Save myview to backend
   * @param viewName view name
   * @param viewType view type
   * @param criteriaQuery criteria JSON query
   * @param defaultCriteria default criteria JSON query in MongoDB format
   */
  saveQuery(_viewName, viewType, criteriaQuery, defaultCriteria) {
    // Append status information to criteria
    const inputCriteria = {
      "$and": [{
        "viewType": viewType
      }, criteriaQuery]
    };

    const _defaultCriteria = {
      "$and": [{
        "viewType": viewType
      }, defaultCriteria]
    };
    const columnData = { columnsToShow: this.selAssignedGrps, orderByColumns: [] };

    const queryObject = {
      viewName: _viewName, criteria: inputCriteria, columnsToShow: columnData.columnsToShow,
      orderByColumns: columnData.orderByColumns, defaultCriteria: _defaultCriteria
    };

    this.myViewService.saveUserView(queryObject);
    PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled, this.userDataService.loggedInUserInfo.userId, "Menu", "MY VIEWS CRITERIA - Save", "Click", "MY VIEWS CRITERIA - Save", 0);
  }

  initMyView(viewName) {
    this.viewName = viewName;
    this.isNew = false;
    this.myViewsForm = this.formBuilder.group({
      mailboxes: this.buildMailBoxes(false),
      viewName: [this.viewName, Validators.required],
      query: ['', Validators.required]
    });
    this.allowGetUserView = true;
    const me = this;
    setTimeout(() => {
      me.myViewService.getUserView(viewName);
    }, 0);
  }

  clearAndClose() {
    this.clearSearch(this.isNew);
    if (!this.isNew) {
      this.loadMyView(this.userView);
    }
    PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled, this.userDataService.loggedInUserInfo.userId, "Menu", "MY VIEWS CRITERIA - Reset", "Click", "MY VIEWS CRITERIA - Reset", 0);
    //this.myViewService.onCloseMyView.emit(this.isNew);
  }

  nameValidation(inputtxt) {
    const letterNumber = /^[0-9a-zA-Z\.\-\/&\s]+$/;
    if ((inputtxt.match(letterNumber))) {
      return true;
    } else {
      return false;
    }
  }

  checkReservedName(viewName) {
    if (viewName) {
      const normalizedReservedNames = QmaConstant.reservedViewNames.map(x => x.toUpperCase());
      if (normalizedReservedNames && normalizedReservedNames.includes(viewName.toUpperCase())) {
        return false;
      }
    }
    return true;
  }

  checkDupViewName(viewName) {
    return !this.isNew || !this.myViewsItems || this.myViewsItems.findIndex(v => v.name === viewName) === -1;
  }

  convertEmbedeCriteria(userCriteria) {
    let prop;
    for (prop in userCriteria) {
      if (userCriteria[prop] && (prop === '$and' || prop === '$or')) {
        let criteria = userCriteria[prop];
        let stringCtr = JSON.stringify(criteria);
        if (stringCtr.indexOf("workflows.") > -1) {
          let userCtrwithWorkFlow = [];
          this.andOrManupulation(criteria, userCtrwithWorkFlow, null);
          userCtrwithWorkFlow = userCtrwithWorkFlow.reverse();
          userCriteria[prop] = userCtrwithWorkFlow;
        }
      }
    }
  }

  andOrManupulation(criteria, userCtrwithWorkFlow, workFlowKey) {
    let finalJson = {};
    let jsonObjArray = [];
    for (let key = 0; key < criteria.length; key++) {
      let key1 = Object.keys(criteria[key])[0];
      let value1 = criteria[key][key1];
      if (key1.indexOf("workflows.") > -1) {
        let regXJson = {};
        let dbKey = key1.substring("workflows.".length, key1.length);
        let dbKeyType;
        if (this.userFilterColumns && this.userFilterColumns.length) {
          for (var idx = 0; idx < this.userFilterColumns.length; idx++) {
            const viewData = this.userFilterColumns[idx];
            if (viewData.id === key1 || (dbKey && viewData.id === dbKey)) {
              dbKeyType = viewData.type;
              break;
            }
          }
        }
        // Special case for age hence not putting it with generic				// String,Integer,Date columns types.
        if (dbKey === "age") {
          regXJson["$exists"] = false;
          let ageValue = 0;
          if (value1.$gt) {
            dbKey = dbKey + "$gt";
            ageValue = value1.$gt;
          }
          else if (value1.$lt) {
            dbKey = dbKey + "$lt";
            ageValue = value1.$lt;
          } else {
            dbKey = dbKey + "$eq";
            ageValue = value1;
          }
          regXJson["$nin"] = [-1000100, ageValue];
        } else if (dbKeyType === 'integer') {
          regXJson = this.handleIntegerColumn(value1, regXJson);
        } else if (dbKeyType === 'date') {
          regXJson = this.handleDateColumn(value1, regXJson);
        } else {
          regXJson = this.handleStringColumn(value1, regXJson);
        }

        /*
         * if(!util.isUndefinedOrNull(finalJson.workflows) &&
         * !util.isUndefinedOrNull(finalJson.workflows.$elemMatch)) {
         * finalJson.workflows.$elemMatch[dbKey]=regXJson; } else {
         */
        let workFlowJson = {};
        workFlowJson[dbKey] = regXJson;
        finalJson["workflows"] = { "$elemMatch": workFlowJson };
        jsonObjArray.push(finalJson);
        finalJson = {};
        // }
      } else {
        if (key1 === '$and' || key1 === '$or') {
          this.andOrManupulation(value1, userCtrwithWorkFlow, key1);
        } else {
          finalJson[key1] = value1;
          jsonObjArray.push(finalJson);
          finalJson = {};
        }
      }
    }
    if (workFlowKey) {
      finalJson[workFlowKey] = jsonObjArray;
      userCtrwithWorkFlow.push(finalJson);
    } else /* if(jsonObjArray.length==1) */ {
      for (var index = 0; index < jsonObjArray.length; index++) {
        userCtrwithWorkFlow.push(jsonObjArray[index]);
      }
    }
  }

  handleStringColumn(value1, regXJson) {
    var isNotEqualCase = false;
    // handle not equal case
    if (value1.$ne) {
      value1 = value1.$ne;
      isNotEqualCase = true;
    }
    if (value1.$regex) {
      regXJson["$regex"] = value1.$regex;
    }
    else {
      regXJson["$regex"] = "^" + value1 + "$"
    }
    if (value1.$options) {
      regXJson["$options"] = value1.$options;
    }
    else {
      regXJson["$options"] = "i";
    }
    regXJson["$nin"] = ["-1000100"];

    if (isNotEqualCase) {
      var temp = {};
      temp["$ne"] = regXJson;
      regXJson = temp;
    }
    return regXJson;
  }

  handleIntegerColumn(value1, regXJson) {
    if (value1.$gt) {
      regXJson["$gt"] = value1.$gt;
    }
    else if (value1.$lt) {
      regXJson["$lt"] = value1.$lt;
    }
    else {
      regXJson["$eq"] = value1;
    }
    regXJson["$nin"] = [-1000100];
    return regXJson;
  }

  handleDateColumn(value1, regXJson) {
    for (let datekey in value1) {
      if (value1.hasOwnProperty(datekey)) {
        const inputDate = new Date(value1[datekey]);
        regXJson[datekey] = inputDate.toISOString();
      }
    }
    regXJson['$nin'] = ['-1000100']
    return regXJson;
  }
  addDateValidationToFilters(filterDataWithWorkFlow, allowdMonths, comp) {
    try {
      filterDataWithWorkFlow.forEach(wf => {
        wf['validation'] = {
          callback: function (value, rule) {
            let isValidDateRange = AppUtils.isValidDates(value, rule, allowdMonths);
            if (!isValidDateRange) {
              comp.showValidate(`Date should be in  permitted range of  ${allowdMonths} months and can not be a future date`);
              return "Date is outside permitted range of three months";
            } else {
              return true;
            }

          }
        }
      });
    } catch (e) {
      console.error("Exception in  addDateValidationToFilters:- " + e);
    }
  }

}

import { Component, OnInit, AfterContentInit, ChangeDetectorRef, Inject } from '@angular/core';
import { trigger, state, transition, style, animate } from "@angular/animations";
import { UserDataService } from '../services/user-data.service';
import { TextValue } from '../model/Common/TextValue';
import { TabDataService } from '../services/tab-data.service';
import { InboxService } from '../services/inbox.service';
import { DashboardService } from '../services/dashboard/dashboard.service';
import { MyViewsService } from '../services/myViews/my-views.service';
import { MyGroup } from "src/app/model/LoginUserInfo/MyGroup";
import { NotificationService } from "../services/global-error/notification.service";
import { DOCUMENT } from "@angular/common";
import { QmaConstant } from "src/app/constant/qma-constant";
import { RightNavigationService } from '../services/panel/panel.service';
import { SymphonyChatServiceService } from 'src/app/symphony/service/symphony-chat-service.service';
import { PiwikProUtils } from '../common/utility/piwikProUtils';

@Component({
  selector: 'app-core',
  templateUrl: './core.component.html',
  styleUrls: ['./core.component.scss'],
  animations: [
    trigger('slideMenu', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('400ms ease-in', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate('300ms ease-out', style({ transform: 'translateX(100%)' }))
      ])
    ])
  ]
})
export class CoreComponent implements AfterContentInit {
  clickStatusArray: Array<boolean> = [false, false, false]; //Array to maintain the status of TopNav items(Notification, Help, User)
  advCriteriaToggle: boolean = true;
  loginUserInfo: boolean = false;
  sideBarStyle: any = 'right-panel';
  displayOverlay: boolean = false;
  menuStatusArray: any;
  isDashboard: boolean = true;
  requestObj: { "groupList": TextValue[] } = { "groupList": [] }; // adv search click req object
  public fgsColor: string;
  showPopUp: boolean = false;
  showNoGroupPopup: boolean = false;
  clrSearchCriteria: boolean = false;
  showError: boolean = false;
  errorMessage: string = '';
  isMobile: boolean = false;
  streamId: String = '';
  // symphony change
  symphonyEnabled: boolean = false;

  toggleOverlay(value: any) {
    //console.log("display Overlay :: ", value);
    this.displayOverlay = value;
  }

  hideOverlay() {
    this.displayOverlay = false;
  }

  constructor(private userDataService: UserDataService,
    private tabDataService: TabDataService,
    private inboxService: InboxService,
    private dashboardService: DashboardService,
    private myViewService: MyViewsService,
    private cdr: ChangeDetectorRef,
    private notificationService: NotificationService,
    private rightNavigationService: RightNavigationService,
    @Inject(DOCUMENT) private document,
    private symphonyChatService: SymphonyChatServiceService
  ) {
    /*C153176-4795 Mobile change */
    this.isMobile = (window.innerWidth <= QmaConstant.MOBILE_WIDTH);
    if (!this.isMobile) {
      // C153176-5105: comment unnecessary calls
      //this.dashboardService.getInquiriesTrendCnts();//AppUtils.formatDate()
      this.dashboardService.getIntensityHeatMapCnts();
      //this.dashboardService.getmailBoxStats();  //C153176-5677:Called twice
    }
    // C170665-663 | Making single call to endpoint getDashboardCounts
    // this.dashboardService.getDashboardCountsService();
    this.notificationService.getRErrorMessage().subscribe(data => {
      if (data) {
        this.showError = true;
        this.errorMessage = data.detail;
      }
    });

    this.rightNavigationService.getNavStatus().subscribe(clickedIndex => {
      if (clickedIndex < 0) {
        this.clickStatusArray.fill(false);
      }
      else {
        this.clickStatusArray[clickedIndex] = true;
      }
    })
  }
  ngOnInit() {
    // symphony change
    // check symphony entitlement
    this.symphonyChatService.getSymphonyEntitlement().subscribe((entitlement: any) => {
      if (entitlement && entitlement.status && this.userDataService.loggedInUserInfo && this.userDataService.loggedInUserInfo.symphonyConfig
        && this.userDataService.loggedInUserInfo.symphonyConfig.symphonyEnabled && this.userDataService.loggedInUserInfo.isSymphonyEnabledForUser == 'Y') {
        this.symphonyEnabled = true;
        // if symphony enabled add boolean for top nav option
        this.clickStatusArray.push(false);
      }
      else {
        this.symphonyEnabled = false;
      }
    });
  }

  ngAfterContentInit() {
    // get data from user data service
    this.userDataService.httpGetLoginUserInfo();
    this.userDataService.getServiceStatus().subscribe(status => {
      this.loginUserInfo = status;
      let loggedInUser = this.userDataService.loggedInUserInfo;
      if (loggedInUser) {
        // Check added to handle User not having any group roles-UI will display message to contact Group Admin
        this.validateGroupRoles(loggedInUser.myGroups);

        // Check for out of office
        if (loggedInUser.outOfOffice) {
          const outOfOfficeSettings = this.userDataService.loggedInUserInfo.outOfOfficeSettings;
          const startDate = new Date(outOfOfficeSettings.fromDate);
          const toDate = new Date(outOfOfficeSettings.toDate);
          const today = new Date();
          // C170665-3212 Display pop up when user is OOO within date range
          if (today >= startDate || today <= toDate) {
            this.showPopUp = true;
          }
        }
        PiwikProUtils.trackJsonDataForTab(this.userDataService.loggedInUserInfo.pivotProConfigEnabled, this.userDataService.loggedInUserInfo.userId, "Tab", "Dasboard");
        PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled, this.userDataService.loggedInUserInfo.userId, "Menu", "Dasboard", "Click", "Dashboard", 0);


        // For Performance inprovement. Call dashboard count service on event instaed of every interval.
        this.dashboardService.getDashboardCountsEvent();
        this.cdr.detectChanges();
      }

    });
  }

  selectedMenuItem(_evt: any) {
    this.menuStatusArray = _evt;
  }

  handleViewRequestEvent(options) {
  }

  /**
   * Method to close OOO Confirm Popup
   */
  onCancel(): void {
    this.showPopUp = false;
  }

  /**
   * Method to reset the OOO setting
   */
  onConfirm(): void {
    this.userDataService.resetOutOfOffice().subscribe(result => {
      if (result.processingstatus) {
        console.log('The OOO setting is reset');
        this.showPopUp = false;

        // C153176-6103 : Update the changes on success.
        let loggedInUser = { ...this.userDataService.loggedInUserInfo };
        let outOfOfficeSettings = { ...loggedInUser.outOfOfficeSettings };
        outOfOfficeSettings.outOfOffice = false;
        loggedInUser.outOfOffice = false;
        loggedInUser.outOfOfficeSettings = outOfOfficeSettings;
        this.userDataService.setLoginUserInfo(loggedInUser);
      }
    }, error => {
      throw new Error("Failed to reset Out of office setting");
    });
  }

  /**
   * Method to validate the group roles assigned to user.
   * @param myGroups : Groups
   */
  validateGroupRoles(myGroups: MyGroup[]): void {
    if (myGroups === undefined || myGroups === null || myGroups.length === 0) {
      this.showNoGroupPopup = true;
    }
  }

  /**
   * Method to close popup.
   */
  onCloseGroupPopup(): void {
    this.showNoGroupPopup = false;
    this.showError = false;
  }

  /**
   * Method to clear the search criteria on tab close.
   */
  clrSearchOnTabClose(event: any): void {
    this.clrSearchCriteria = event;
  }

  mailSupport() {
    const origin = this.document.location.origin;
    let qmaUrl = QmaConstant.QMA_URL;;
    if (origin.indexOf('qa') >= 0 || origin.indexOf('uat') >= 0)
      window.location.href = "XXXX" + this.errorMessage;
    else if (origin.indexOf('prod') >= 0)
      window.location.href = "XXXX" + this.errorMessage;
    else
      window.location.href = "XXXX" + this.errorMessage;
  }

  openSymphonyChatView(streamId: String) {
    console.log('chat is being opened for streamId : ' + streamId);
    this.streamId = streamId;
  }

  closeChatView(event) {
    this.streamId = '';
  }
}

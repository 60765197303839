import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { ContextMenuService } from 'src/app/services/contextMenu/context-menu.service';
import { GridOptions, Module } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-group-details',
  templateUrl: './group-details.component.html',
  styleUrls: ['./group-details.component.scss']
})

export class GroupDetailsComponent implements OnInit, OnDestroy {
  modules: Module[] = [ClientSideRowModelModule];
  @Input() isNewMessage: boolean = false;
  @Output() addClicked = new EventEmitter();
  getAllGfcURL: string = "/inquiry/getAllGfcIds";
  contextServiceObject: any;
  firstLoad: boolean = false;

  private gridApi: any;
  private gridColumnApi: any;
  public gridOptions: GridOptions;
  baseURL: string;
  selectedMenuItem: any;
  display: boolean = false;
  rowData: any;
  subManager = new Subscription();

  columnDefs = [
    { headerName: 'Group Name', field: 'grpName', width: 120, cellClass: "cell-wrap-text", suppressMenu: true, headerHeight: 44 },
    { headerName: 'Status', field: 'status', width: 80, cellClass: "cell-wrap-text", suppressMenu: true, headerHeight: 44 },
    { headerName: 'Request Type', field: 'requestType', width: 100, cellClass: "cell-wrap-text", suppressMenu: true, headerHeight: 44 },
    { headerName: 'Assigned To', field: 'assignedTo', width: 100, cellClass: "cell-wrap-text", suppressMenu: true, headerHeight: 44 }
  ];

  constructor(private contextMenuService: ContextMenuService) {
    this.contextServiceObject = contextMenuService;
    this.gridOptions = <GridOptions>{};
  }

  ngOnInit() {
  }

  triggerFalseClick() {
    this.display = true;
    this.firstLoad = true;
    this.applyGridOptions();
    let sub1 = this.contextServiceObject.httpGetGroupDetails(this.selectedMenuItem).subscribe((res: any) => {
      var tempArray = [];
      res.workflowList.forEach((val) => {
        let obj: any = {};
        obj.grpName = val.assignedGroupName;
        obj.status = val.status;
        obj.requestType = val.requestType;
        obj.assignedTo = val.assignedUserName;
        tempArray.push(obj)
      });
      this.rowData = tempArray;
    });
    this.subManager.add(sub1);
  }

  hideModal() {
    this.display = false;
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.showLoadingOverlay;
    params.api.sizeColumnsToFit();
  }

  applyGridOptions() {
    this.gridOptions.suppressDragLeaveHidesColumns = true;
    this.gridOptions.suppressContextMenu = true;
    this.gridOptions.suppressRowDeselection = true;
    this.gridOptions.suppressHorizontalScroll = true;
  }

  onRowClicked(evt: any) { }

  ngOnDestroy() {
    this.subManager.unsubscribe();
  }
}

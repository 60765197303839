import {Component} from "@angular/core";
import {ICellRendererAngularComp} from "@ag-grid-community/angular";
import {ColorPickerModule} from 'primeng/colorpicker';


@Component({
  selector: 'app-color-picker',
  template: "<p-colorPicker (onChange)='updateValue()' [(ngModel)]='colorCode' appendTo='body' [baseZIndex]='99999'></p-colorPicker><span [ngStyle]='{color:colorCode}'>{{colorCode}}</span>"
})


export class ColorPickerComponent implements ICellRendererAngularComp {
  public params: any;
  public gridApi : any;
  public colorCode: string;
  private fieldname: string;
  public value: any;

  agInit(params: any): void {
      this.params = params;
      this.gridApi = params.api;
      this.fieldname = params.fieldname;
      this.colorCode = (this.params.value) ? this.params.value : '#002D72'; 
      //console.log("COLOR="+this.color);

  }

  onKeyDown(event): void {
    if (event.keyCode == 13) {
      this.updateValue();
    }
  }

  updateValue() {
    //this.params[this.fieldname] = this.getValue();
    this.params.data.colorCode = this.colorCode;
  }
  getValue(): any {
    return this.colorCode;
  }

  refresh(): boolean {
    console.log("COLOR="+this.colorCode);
      return true;
      
  }
}
import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import * as Rx from "rxjs";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  errorMsg = new Rx.BehaviorSubject<Object>(null);
  message: string ='';

  constructor(private messageService: MessageService) { }

  showSuccess(message: string): void {
    console.log('Show Success: ' + message);
  }

  showError(message: string){
    this.message = message;
    this.setErrorMessage();
  }

  setErrorMessage() {
    let error = {
      severity:'error', 
      summary: 'Application Server Error', 
      detail: this.message
    }
    this.errorMsg.next(error);
  }

  getRErrorMessage(): Observable<any>{
    return this.errorMsg.asObservable();
   }
}

export class MailboxOnboardingResponse {
    _id? : string = '';
    mappedDL?: string = '';
    mappingType?: string = "DEDICATED";
    minMemoryRequirementInMB?: number = 256;
    maxMemoryRequirementInMB?: number = 256;
    isToBeForceStarted?: boolean = false;
    isToBeForceStopped?: boolean = false;
    currentRunStatus?: string = '';
    startedAt?: string = '';
    startedOn?: string = '';
    modBy?: string = '';
    modDate?: Date = null;
    crtBy?: string = '';
    crtDate?: Date = null;
    loginSecret?: string = '';
    //exchange details
    hostName?: string = '';
    attachmentPath?: string = '';
    waitTimeBetweenPoll?: number;
    supportEmail?: string = '';
    maxErrorEmailCount?: number;
    domain?: string = '';
    version?: string = '';
    exchangeWebServiceUrl?: string = '';
    allowedDomains?: string = '';
    readerBatchSize?: number;
    success?: boolean;
    message?: string;
}
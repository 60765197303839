import { HolidayAndShiftDetails } from './HolidayAndShiftDetails';
import { RequestTypeMapping } from './RequestTypeMapping';
import { HeirarchyData } from './HeirarchyData';
import { Favorite } from './Favorite';
import { Route } from './Route';
import { Template } from './Template';
import { ExchangeFolder } from './ExchangeFolder';

export class MyGroup {
    id: number;
    crtDate: any;
    modDate: any;
    version: number;
    crtBy: string;
    modBy: string;
    active: boolean;
    makerCheckerRqd: boolean;
    makerCheckerDomains: string[];
    groupName: string;
    groupEmail: string;
    routes: Route[];
    parentDLAliases?: any;
    recipients: string[];
    requestTypes: string[];
    disclaimer: string;
    predictiveRecipients: boolean;
    enableAutoAssignment: boolean;
    enableAutoReplySuggestion: boolean;
    attachmentBasedMakerChecker: boolean;
    tags: string[];
    heirarchyData: HeirarchyData;
    favorites: Favorite[];
    allGroupEmailsFromCIMS: string[];
    manualGroupEmails: string[];
    isEmailSharingBlocked: boolean;
    autoResponseDetails?: any;
    userAssignmentExclusionList: any[];
    rootCauseList: string[];
    isRootCauseMandatory: boolean;
    processingRegionList: string[];
    isProcessingRegionMandatory: boolean;
    autoReplyEnable: boolean;
    isInquirySourceMandatory: boolean;
    isInqReferenceIdReq: boolean;
    isRootCauseMandatoryWOReply: boolean;
    enableXstreamIntegration: boolean;
    isGfidMandatory: boolean;
    isTagMandatory: boolean;
    convCountThresholdForEscalation: number;
    responseTimeThresholdForEscalation: number;
    paResponseTimeThresholdForEscalation: number;
    excludeMyGroupWhileReplyAll: boolean;
    hideClientList: boolean;
    escKeywords: any[];
    holidayAndShiftDetails: HolidayAndShiftDetails;
    country: string;
    timeZone: string;
    hrHierarchy?: any;
    subjectEscalationList: any[];
    requestTypeMappings: RequestTypeMapping[];
    templates: Template[];
    requestTypeRootCauseFilter: boolean = false; // C170665-10 : Request Type and Root Cause flag
    requestTypeRootCauseMapping: {}; // C170665-10 : Request Type and Root Cause Mapping object
    exchFolders: ExchangeFolder[];
    streamId? : string;
}
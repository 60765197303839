/**
 * Mobile search handler
 */
export class MobileSearchHandler {
  viewComponent: any;
  // current mailbox row data
  currentRowData:Array<any>;
  // all mailbox row data
  allRowData:Array<any>;
  // serach tab texts
  searchTabs: Array<any>;
  // searchable columns
  searchColumns: Array<any>;
  // currently act tab text
  selectedSearchTab: string;
  // text filtering enabled
  textFilterEnabled = false;
  // cached search text
  searchTarget: string;
  // currently active tab index
  searchTabIndex: number = 0;
  // context search text
  contextSearchTarget: string;
  // global search text
  globalSearchTarget: string;


  constructor() {
    this.searchTabs = [
        { text: 'CURRENT MAILBOX', filter: true},
        { text: 'ALL MAILBOXES', filter: false}
    ];
  }

  /**
   * Initialize search panel
   */
  initSearchPanel() {
    if (!this.selectedSearchTab) {
    // set currentSearchTab if not already
    this.selectedSearchTab = this.searchTabs[this.searchTabIndex].text;
    this.textFilterEnabled = this.searchTabs[this.searchTabIndex].filter;
    }
    this.initCurrentSearchData(); // C153176-5267: refatoring
    // prepare search columns
    this.prepareSearchColumns();
  }

  // C153176-5267: initialize current search data
  initCurrentSearchData(dupData = false) {
    if (this.searchTabIndex === 0) {
     /*    if (!this.currentRowData || !this.currentRowData.length) {
            if(this.viewComponent.rowData && this.viewComponent.rowData.length) {
                if (dupData) {
                    this.currentRowData = [...this.viewComponent.rowData];
                } else { */
                    this.currentRowData = this.viewComponent.rowData;
          /*       }
            }
 */       /*  } else if (dupData) {
            this.currentRowData = [...this.viewComponent.rowData];
        }*/
    } 
  }

  cleanupSearchPanel() {
      // C153176-4885
      this.contextSearchTarget = undefined;
      this.globalSearchTarget = undefined;
      this.searchTabIndex = 0; //C153176-5019: reset selected tab to be current mailbox
  }

  prepareSearchColumns() {
      // C170665-2663 Search function limitations
    if (this.viewComponent && this.viewComponent.columnConfig && this.viewComponent.inboxViewComponent && this.viewComponent.inboxViewComponent.isMobile) {
        this.searchColumns = this.viewComponent.columnConfig;
    } else if (this.viewComponent.columnConfig) {
        this.searchColumns = this.viewComponent.columnConfig.filter(col => col.hide !== true);
    }
  }

  hasSearchChanged(text) {
      if (!text && !this.searchTarget) {
          return false;
      }
      if (!text || !this.searchTarget) {
          return true;
      }
      if (text.length !== this.searchTarget.length) {
          return true;
      }
      return text !== this.searchTarget;
  }

  /**
   * Context search entry point
   * @param text 
   * @param forced 
   */
  searchText(text, forced) {
    // check search changed first before updating search target.
    const searchChanged = this.hasSearchChanged(text);
    // update search target
    this.searchTarget = text;
    if (!this.searchTabs[this.searchTabIndex].filter || !this.viewComponent.rowData || !this.viewComponent.rowData.length) { //!this.textFilterEnabled
      return;
    }
    this.contextSearch(searchChanged);
  }

  /**
   * Search tab change event handling
   * @param event
   */
  onSearchTabChange(event: any) {
    let indexChanged = this.searchTabIndex !== event.index;
    this.searchTabIndex = event.index;
    this.selectedSearchTab = this.searchTabs[this.searchTabIndex].text;
    this.textFilterEnabled = this.searchTabs[this.searchTabIndex].filter;
    if (event.index === 1 && this.globalSearchTarget !== this.searchTarget) {
      // a new global search is warantted
      this.globalSearch(indexChanged);
    } else if (event.index === 0 && this.contextSearchTarget !== this.searchTarget) {
      this.contextSearch(true);
    } else if(event.index === 0) {
        this.initSearchPanel();
    }
  }

  /**
   * Access point for a context search
   * @param searchChanged
   */
  contextSearch(searchChanged) {
    // no check of text legnth here. Context search may happen on any length
    this.contextSearchTarget = this.searchTarget;
    const changedIds = [];
    const filtered = this.viewComponent.rowData.filter(data => this.filterRowData(data, this.contextSearchTarget, changedIds));
    if (filtered) {
      this.currentRowData = filtered;
    }
    if (searchChanged) {
        this.viewComponent.inboxService.notifyMobileSearchChanged(changedIds);
    }

  }

  /**
   * Filter on one row of data
   */
  filterRowData(rowData, text, changedIds) {
    if (!this.searchColumns || !this.searchColumns.length) {
        return true;
    }
    const result = this.searchColumns.some(col => {
        return rowData.hasOwnProperty(col.colId) && (!text || ('' + rowData[col.colId]).includes(text));
    });
    if (result) {
        changedIds.push(rowData._id);
    }
    return result;
  }

  /**
   * Access point to trigger a global search if text length meets min-length requirement
   */
  globalSearch(indexChanged) {
    if (!this.searchTarget || this.searchTarget.length < 4) {
        // no or not enough text for search
        return;
    } else {
        this.globalSearchTarget = this.searchTarget;
        this.viewComponent.doGlobalSearch(this.globalSearchTarget, indexChanged);
    }
  }

  /**
   * Update global search result,
   * @return true if the result has been taken, otherwise returns false
   */
  updateData() {
      if (this.searchTabIndex === 1) {
          this.allRowData = [...this.viewComponent.inboxViewComponent.rowData];
          return true;
      } else {
          return false;
      }
  }

  /**
   * C153176-5267: reset data in All Mailboxes
   */
  resetAllMailboxData() {
      this.allRowData= [];
  }
}
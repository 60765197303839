import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'usernamedot'
})
export class UserNamePipe implements PipeTransform {
    transform(value: string) {
        if (!value) {
            return value;
        }
        let bsIndex = value.indexOf('[');
        let beIndex = value.indexOf(']');
        if (bsIndex !== -1 && beIndex !== -1 && bsIndex < beIndex) {
            // remove the [] segment
            const removed = value.substr(bsIndex, beIndex+1);
            const ckArray = value.split(removed);
            value = ckArray.join('');
        }
        // trim first, then split
        let chunks = value.trim().split(' ');
        let hasComma = value.indexOf(',') !== -1;
        if (chunks && chunks.length > 1) {
            if (!hasComma) {
                // first name,...,last name
                return chunks[0].toUpperCase()[0] + chunks[chunks.length -1].toUpperCase()[0];
            } else {
                // last name, firstname, ...
                return chunks[1].toUpperCase()[0] + chunks[0].toUpperCase()[0];
            }
        } else if (chunks && chunks.length == 1) {
            return chunks[0].toUpperCase()[0];
        } else {
            return '';
        }
    }
}
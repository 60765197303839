import { Component, OnInit, ViewChild, OnDestroy, ElementRef } from '@angular/core';
import { ContextMenuService } from 'src/app/services/contextMenu/context-menu.service';
import { OverlayPanel } from "primeng/overlaypanel";
import { AgGridCheckboxComponent } from 'src/app/core/user/ag-grid-checkbox.component';
import { Subscription } from 'rxjs';
import { SaveInquiryRules } from 'src/app/model/group-admin/saveInquiryRules';
import { GroupInquiryCriteriaList } from 'src/app/model/group-admin/groupInquiryCriteriaList';
import { GroupInquiryActions } from 'src/app/model/group-admin/groupInquiryActions';
import { GroupAdminService } from "src/app/services/group-admin/group-admin.service";
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { GridOptions, Module } from "@ag-grid-community/core";
import { InquiryOrderComponent } from "src/app/core/user/inquiry-order-component";
import { ExcelService, ExcelExport, InqRulesReorderInfo } from "src/app/services/common/excel.service";
import * as $ from 'jquery';
import { UserDataService } from 'src/app/services/user-data.service';
import { PiwikProUtils } from '../../utility/piwikProUtils';
import * as _ from "lodash";

@Component({
  selector: 'app-create-rule',
  templateUrl: './create-rule.component.html',
  styleUrls: ['./create-rule.component.scss']
})
export class CreateRuleComponent implements OnInit {
  @ViewChild('duplicate', { static: true }) overlaypanel: OverlayPanel;
  // Jira:4703 : Confirmation Dialog before delete
  showPopUpDelete: boolean = false;
  confirmMsgText: string;
  groupTab: string;
  duplicateColumn: any = [];
  duplicateList: any = [];
  duplicateCheckBox: any;
  public gridColumnApiDuplicateInquiry;
  public gridApiDuplicateInq;
  modules: Module[] = [ClientSideRowModelModule];

  public isNotAuthorize: boolean = false;
  loginUserInfo: any;
  selectedGroupName: string = "";
  public routesList: any = [];
  public routesColumn: any = [];
  entitleDataUserName: any = [];
  public gridApiInquiry;
  public gridColumnApiInquiry;
  public inquiryFlag = 0;
  public filterIconHide: boolean = false;
  public filterIconHideHorizontal: boolean = false;
  public filterText = "";
  public validationCheck: boolean = true;
  public validationRules;
  public inquiryRulesChecbox;
  public rulesDeleteFlag: boolean = true;
  contextServiceObject: any;
  public display: boolean = false;
  rowSelection;
  requestTypeList = [];
  rootCauseList = [];
  tagsList = [];
  originalTagList = [];
  processingRegion = [];
  selectedGroup: any;
  groupsData: any = [];
  selectedMenuItem: any;
  groupName: any;
  public entitleData: any;
  public disablePaginationNextLast: boolean = false;
  public disablePaginationPrevFirst: boolean = false;
  public lbTotalRecords: number = 0;
  public lbPageSize: number = 0;
  public lbCurrentPage: number = 0;
  public lbTotalPages: number = 0;
  selectedMail: any;
  version: number;
  allUsers: any = [];
  public infoContent: string;
  displaySuccess: boolean = false;
  displayError: boolean = false;
  routeListBackup = [];
  public errorMessage: string = "";
  restoreHideFlag: boolean = false;  /*<!--temperory hide will be enable after service change -->*/

  //Inquiry Rules Upload and Download Variables
  @ViewChild('uploadFilesForInquiry', { static: true }) uploadFilesForInquiry: ElementRef;

  //Inquiry Rules Upload rules - Required to validate the values
  groupRequestTypes = [];
  groupProcessingRegion = [];
  groupTags = [];
  groupUsers = [];
  invalidRowMessage = [];
  uploadedValidInqRules = [];

  // Message attributes
  inquiryRulesUpload: boolean = false;
  inquiryRulesMsgTitle: string = '';
  inquiryRulesMsg: string = '';
  inquiryRulesConfirm: boolean = false;
  inquiryRulesRejected: boolean = false;

  //BCC Group Inquiry Rules
  isBccGrpSelected = false;
  inqAssignedGroups: any = [];

  constructor(private contextMenuService: ContextMenuService, private userDataService: UserDataService, private groupAdminService: GroupAdminService, private excelService: ExcelService) {
    this.contextServiceObject = contextMenuService;
    this.infoContent = 'Inquiry Rules for the group can be configured here.';
  }

  ngOnInit() {
    // inq rule reorder
    this.excelService.getinqRulesReorderInfo().subscribe((data: InqRulesReorderInfo) => {
      this.updateInqRuleOrder(data);
    });
  }

  private createRouteColumn() {
    this.isBccGrpSelected = (this.groupName === '*GT GLOBAL x-Stream BCC Group');
    /*inquiry rule redesign start*/
    this.routesColumn = [
      {
        colId: 'order',
        field: "order",
        headerName: '#',
        width: 101,
        editable: false,
        cellRendererFramework: InquiryOrderComponent
      },
      {
        colId: 'rule1',
        field: "rule1",
        headerName: 'FROM RULE',
        width: 145,
        editable: true,
        cellStyle: { 'color': '#00bdf2', 'height': '41px', 'left': '112px !important', 'width': '140px' },
        cellEditor: "agSelectCellEditor",
        cellEditorParams: {
          values: ["Begins With", "Contains", "Not Contains", "Equal", "Not Equal"]
        }
      },
      {
        colId: 'value1',
        field: "value1",
        headerName: 'FROM VALUE',
        width: 200,
        editable: true,
        tooltipField: 'value1',
        cellStyle: { 'left': '250px !important', 'width': '215px' }
      },
      {
        colId: 'rule2',
        field: "rule2",
        headerName: 'SUBJECT RULE',
        width: 137,
        editable: true,
        cellStyle: { 'color': '#00bdf2', 'height': '41px', 'left': '460px !important', 'width': '115px' },
        cellEditor: "agSelectCellEditor",
        cellEditorParams: {

          values: ["Begins With", "Contains", "Not Contains", "Equal", "Not Equal"]
        }

      },
      {
        colId: 'value2',
        field: "value2",
        headerName: 'SUBJECT VALUE',
        width: 200,
        editable: true,
        tooltipField: 'value2',
        cellStyle: { 'left': '590px !important', 'width': '190px' }
      },
      {
        colId: 'rule3',
        field: "rule3",
        headerName: 'TO RULE',
        width: 100,
        editable: true,
        cellStyle: { 'color': '#00bdf2', 'height': '41px', 'left': '790px !important', 'width': '90px' },
        cellEditor: "agSelectCellEditor",
        cellEditorParams: {

          values: ["Begins With", "Contains", "Not Contains", "Equal", "Not Equal"]
        }

      },
      {
        colId: 'value3',
        field: "value3",
        headerName: 'TO VALUE',
        cellStyle: { 'color': '#00bdf2', 'height': '41px', 'left': '895px !important', 'width': '165px' },
        width: 155,
        editable: true,
        tooltipField: 'value3',
        enableBrowserTooltips: true,
      },
      {
        colId: 'attachment',
        field: "attachment",
        headerName: 'ATTACHMENT',
        width: 142,
        editable: false,
        cellStyle: { 'left': '1070px !important' },
        cellRendererFramework: AgGridCheckboxComponent, cellRendererParams: { fieldname: 'attachment' }
      }
    ];

    this.inquiryRulesChecbox = {
      width: 100,
      checkboxSelection: this.isFirstColumn,
      headerCheckboxSelection: this.isFirstColumn,
      resizable: true,
      suppressMovable: true,
      suppressMenu: true,
      editable: true,
      cellStyle: { 'height': '41px' }
    };
    this.rowSelection = "multiple";
    //BCC Inquiry rules
    if (this.isBccGrpSelected) {
      this.routesColumn.push({
        colId: 'assign',
        field: "assign",
        headerName: 'Assign To Group',
        width: 500,
        editable: true,
        cellEditor: "agSelectCellEditor",
        cellStyle: { 'left': '1190px !important', 'width': '450px' },
        cellEditorParams: {
          values: this.inqAssignedGroups
        }
      });
    }
    else {
      this.routesColumn.push({
        colId: 'checkbox',
        field: "checkbox",
        headerName: 'NON-INQUIRY',
        width: 80,
        editable: false,
        cellStyle: { 'left': '1190px !important' },
        cellRendererFramework: AgGridCheckboxComponent, cellRendererParams: { fieldname: 'checkbox' }
      }, {
        colId: 'user',
        field: "user",
        headerName: 'USER',
        width: 150,
        editable: true,
        cellEditor: "agSelectCellEditor",
        cellStyle: { 'left': '1275px !important', 'width': '155px' },
        cellEditorParams: {
          values: this.entitleDataUserName
        },
      }, {
        colId: 'art',
        field: "art",
        headerName: 'REQUEST TYPE',
        width: 110,
        editable: true,
        cellEditor: "agSelectCellEditor",
        enableBrowserTooltips: true,
        cellStyle: { 'left': '1425px !important', 'width': '110px' },
        cellEditorParams: {
          values: this.requestTypeList
        }
      }, {
        colId: 'at',
        field: "at",
        headerName: 'TAG',
        width: 110,
        editable: true,
        cellEditor: "agSelectCellEditor",
        enableBrowserTooltips: true,
        cellStyle: { 'left': '1525px !important', 'width': '130px' },
        cellEditorParams: {
          values: this.tagsList
        }
      },
        {
          colId: 'apr',
          field: "apr",
          headerName: 'REGION',
          width: 120,
          editable: true,
          cellEditor: "agSelectCellEditor",
          enableBrowserTooltips: true,
          cellStyle: { 'left': '1640px !important', 'width': '128px' },
          cellEditorParams: {
            values: this.processingRegion
          }
        }
      );
    }

  }
  /*inquiry rule redesign end*/

  triggerFalseClick() {
    let isAdmin = false;

    // Reset the values before assign new values.
    this.invalidRowMessage = [];
    this.groupRequestTypes = [];
    this.groupProcessingRegion = [];
    this.groupTags = [];
    this.groupUsers = [];

    let sub1 = this.contextServiceObject.httpGetGroupData(this.groupName).subscribe(res => {
      this.selectedGroupName = this.groupName;
      this.loginUserInfo.groupRoles.forEach(element => {
        if (element.groupName === this.groupName && (element.groupRole.includes("Group Admin") || element.groupRole.includes("Supervisor"))) {
          isAdmin = true;
        }

      });
      if (!isAdmin) {
        this.isNotAuthorize = true;
        this.errorMessage = "You are not authorized to take this action. Please contact your Group Admin.";
      } else {
        this.display = true;
        this.routesList = [];
        this.routeListBackup = [];
        this.entitleDataUserName = [];
        this.tagsList = [];
        this.processingRegion = [];
        this.requestTypeList = [];
        if (this.selectedMail) {
          let obj: any = {};
          obj.order = 1;
          obj.field1 = 'From';
          obj.rule1 = 'Contains';
          obj.value1 = this.selectedMail.conversationList[0].recipients[0].displayName;
          obj.field2 = 'Subject';
          obj.rule2 = 'Contains';
          let val2 = [];
          for (let i = 1; i < this.selectedMail.conversationList[0].recipients.length; i++) {
            val2.push(this.selectedMail.conversationList[0].recipients[i].emailAddr);
          }
          obj.value3 = val2.join(',');                   // wrong value displayed
          obj.field3 = 'To';
          obj.rule3 = 'Contains';
          obj.value2 = this.selectedMail.conversationList[0].subject; // wrong value displayed
          obj.checkbox = false;
          obj.apr = '';
          obj.art = '';
          obj.at = '';
          obj.user = '';
          obj.assign = '';
          obj.attachment = false;

          this.routesList.push(obj);
        }
        this.allUsers = [];
        res.forEach(el => {
          this.allUsers.push({ 'userName': el.userName, 'userId': el.userId });
          if (el.myGroups) {
            el.myGroups.forEach(el1 => {
              if (el1.processingRegionList) {
                el1.processingRegionList.forEach(pr => {
                  this.processingRegion.push(pr);
                });
                this.processingRegion.unshift("");
              }
              if (el1.requestTypes) {
                el1.requestTypes.forEach(rt => {
                  this.requestTypeList.push(rt);
                });
                this.requestTypeList.unshift("");
              }
              if (el1.tags) {
                el1.tags.forEach(tg => {
                  this.tagsList.push(tg);
                });
                this.tagsList.unshift("");
              }

              if (el1.routes) {
                el1.routes.forEach((route, index) => {
                  let obj: any = {};
                  obj.order = index + 2; // Inquiry Rules screen redesign and Rules Reordering
                  obj.field1 = 'From';
                  obj.rule1 = route.fromOperator;
                  obj.value1 = route.from;
                  obj.field2 = 'Subject';
                  obj.rule2 = route.subjectOperator;
                  obj.value2 = route.subject;
                  obj.field3 = 'To';
                  obj.rule3 = route.toOperator;
                  obj.value3 = route.to;
                  obj.checkbox = route.actions.markAsNonInquiry;
                  obj.attachment = route.attachment;
                  // Inquiry Rules screen redesign and Rules Reordering
                  let processing = [];
                  if (el1.processingRegionList) {
                    for (let i = 0; i < el1.processingRegionList.length; i++) {
                      if (el1.processingRegionList[i] === route.actions.assignProcessingRegion)
                        processing.push(route.actions.assignProcessingRegion);
                    }
                  }
                  obj.apr = processing;
                  let assignRequest = [];
                  if (el1.requestTypes) {
                    for (let i = 0; i < el1.requestTypes.length; i++) {
                      if (el1.requestTypes[i] === route.actions.assignRequestType)
                        assignRequest.push(route.actions.assignRequestType);
                    }
                  }
                  obj.art = assignRequest;
                  let assigntTag = [];
                  if (el1.tags) {
                    for (let i = 0; i < el1.tags.length; i++) {
                      if (el1.tags[i] === route.actions.assignToTag)
                        assigntTag.push(route.actions.assignToTag);
                    }
                  }
                  obj.at = assigntTag;
                  let assignUser = [];
                  if (res) {
                    for (let i = 0; i < res.length; i++) {
                      if (res[i].userId === route.actions.assignToUserId)
                        assignUser.push(res[i].userName);
                    }
                  }
                  obj.userid = route.actions.assignToUserId
                  let username = res.filter(e => e.userId == route.actions.assignToUserId)[0];
                  obj.user = assignUser;//(username) ? username.userName : route.actions.assignToUserId;
                  obj.assign = route.actions.assignGroup;
                  obj.ruleOrder = route.ruleOrder;
                  obj.deletion = route.actions.markForDeletion;

                  this.routesList.push(obj);
                });
              }
              this.version = el1.version;
            });
          }
        });
        for (let i = -1; i < this.allUsers.length; i++) {
          if (i === -1) {
            this.entitleDataUserName.push('');
          } else {
            this.entitleDataUserName.push(this.allUsers[i].userName);
          }
        }
        this.inqAssignedGroups = this.userDataService.getGroupListForRule();
        this.createRouteColumn();
        sessionStorage.setItem('routeListBackup', JSON.stringify(this.routesList));
        this.routeListBackup = JSON.parse(JSON.stringify(this.routesList));

        //Populate the values from the Group
        this.groupRequestTypes = [...this.requestTypeList];
        this.groupProcessingRegion = [...this.processingRegion];
        this.groupTags = [...this.tagsList];
        this.groupUsers = [...this.entitleDataUserName];
      }
    });

  }
  // Inquiry Rules screen redesign and Rules Reordering
  copyInquiryRecords() {
    const selectedInquiryforCopied = JSON.parse(JSON.stringify(this.gridApiInquiry.getSelectedRows()));
    const coipedRow = selectedInquiryforCopied.length;

    selectedInquiryforCopied.forEach((element, index) => {
      element['order'] = '';
    });
    selectedInquiryforCopied.push(...this.routesList);
    this.routesList = selectedInquiryforCopied;
    this.routesList.forEach((element, index) => {
      element.order = index + 1;
    });
    this.gridApiInquiry.applyTransaction(this.routesList);

    //Select the copied row from the grid.
    setTimeout(() => {
      this.gridApiInquiry.forEachNode(function (node, index) {
        if (index < coipedRow) {
          node.setSelected(true);
        }
        else {
          node.setSelected(false);
        }
      });
    }, 200);
  }
  //Delete dialog should show if inquiry rules is selected.
  setConfirmation(tab): void {
    if (tab == "inquiryRules") {
      const selectedRules = this.gridApiInquiry.getSelectedRows();
      if (null == selectedRules || selectedRules.length == 0) {
        return;
      }
    }
    this.showPopUpDelete = true;
    this.confirmMsgText = "Are you sure want to delete the selected items ?";
    this.groupTab = tab;
  }
  onCancel() {
    this.showPopUpDelete = false;
  }
  onOk() {
    if (this.groupTab == 'inquiryRules') {
      this.deleteInquiryRulesRecords();
    }

  }

  checkDuplicate(event, overlaypanel: OverlayPanel): void {
    overlaypanel.toggle(event);
    this.duplicateColumn = [
      // disabling inq rules reorder will be enabled later
      {
        colId: 'rule1',
        field: "rule1",
        headerName: 'RULE',
        width: 145,
        editable: false,
        cellStyle: { 'color': '#00bdf2', 'height': '41px', 'width': '140px' },

      },
      {
        colId: 'value1',
        field: "value1",
        headerName: 'VALUE',
        width: 200,
        editable: false,
        tooltipField: 'value1',
        cellStyle: { 'left': '158px !important', 'width': '215px' }
      },
      {
        colId: 'rule2',
        field: "rule2",
        headerName: 'RULE',
        width: 137,
        editable: false,
        cellStyle: { 'color': '#00bdf2', 'height': '41px', 'left': '350px !important', 'width': '115px' },


      },
      {
        colId: 'value2',
        field: "value2",
        headerName: 'VALUE',
        width: 200,
        editable: false,
        tooltipField: 'value2',
        cellStyle: { 'left': '497px !important', 'width': '190px' }
      },
      {
        colId: 'rule3',
        field: "rule3",
        headerName: 'RULE',
        width: 100,
        editable: false,
        cellStyle: { 'color': '#00bdf2', 'height': '41px', 'left': '690px !important', 'width': '90px' },


      },
      {
        colId: 'value3',
        field: "value3",
        headerName: 'VALUE',
        cellStyle: { 'color': '#00bdf2', 'height': '41px', 'left': '800px !important', 'width': '165px' },
        width: 155,
        editable: false,
        tooltipField: 'value3',
        enableBrowserTooltips: true,
      },
      {
        colId: 'order',
        field: "order",
        headerName: 'RULE#',
        width: 101,
        editable: false,
        cellStyle: { 'left': '945px !important' },
        //cellRendererFramework: InquiryOrderComponent
        cellRenderer: (params) => {
          let indexHtml = '';
          let temp = [];
          params.data.indexList.forEach(element => {
            temp.push(element);
            if (temp.length < 2) {
              //add class for the span elements
              indexHtml += '<span class="notify-badge-inq-rules duplicat-badge-color-normal duplicate-badge" >' + element + '</span>';
            } else {
              indexHtml += '<span class="notify-badge-inq-rules duplicat-badge-color-warning duplicate-badge" >' + element + '</span>';
            }

          });
          return indexHtml;
        }
      }
    ];
    this.duplicateCheckBox = {
      resizable: true,
      suppressMovable: true,
      suppressMenu: true,
    };
    this.duplicateList = [];
    this.routeListBackup = [];
    var launchOptimistic = this.routesList.map((elem) => {
      return {
        rule1: elem.rule1,
        value1: elem.value1,
        rule2: elem.rule2,
        value2: elem.value2,
        rule3: elem.rule3,
        value3: elem.value3
      }
    });
    let launchFilterd = _.filter(
      _.uniq(
        _.map(launchOptimistic, function (elem) {
          if (_.filter(launchOptimistic, {
            rule1: elem.rule1,
            value1: elem.value1,
            rule2: elem.rule2,
            value2: elem.value2,
            rule3: elem.rule3,
            value3: elem.value3
          }).length > 1) {
            return elem;
          }

          return false;
        })),
      function (value) { return value; });
    let t2 = _.uniqWith(launchFilterd, _.isEqual);
    this.duplicateList = t2;
    this.gridApiDuplicateInq.applyTransaction(this.duplicateList);
    this.duplicateList.forEach(elem => {
      let index = this.routesList.filter(x => (x.rule1 == elem.rule1 && x.value1 == elem.value1 && x.rule2 == elem.rule2 && x.value2 == elem.value2 && x.rule3 == elem.rule3 && x.value3 == elem.value3)).map(x => x.order);
      elem.indexList = index;
    })
  }

  //row data has been populated, registering callback for user clicks
  dupGridRowDataChanged(event: any) {
    this.handleDupInqSelection();
  }


  restoreRulesShowPopUp(event, overlaypanel: OverlayPanel): void {
    overlaypanel.toggle(event);
  }
  closeRestorePopup(event, overlayPanel) {
    overlayPanel.hide();
  }

  restoreRules() {
    this.routesList = [];
    this.routesList = [...JSON.parse(sessionStorage.getItem('routeListBackup'))];
    this.gridApiInquiry.applyTransaction([...JSON.parse(sessionStorage.getItem('routeListBackup'))]);
  }

  hideDuplicateModal(overlaypanel) {
    overlaypanel.hide();
  }

  updateInqRuleOrder(inqRulesReorderInfo: InqRulesReorderInfo) {
    if (Object.entries(inqRulesReorderInfo).length === 0 && inqRulesReorderInfo.constructor === Object) {
      return;
    }
    this.removeInqRuleFromGrid(inqRulesReorderInfo);

  }

  removeInqRuleFromGrid(inqRulesReorderInfo: InqRulesReorderInfo) {
    // Inquiry Rules screen redesign and Rules Reordering
    const inquiryRuletoDelete = this.routesList.findIndex(irowData => irowData.order === inqRulesReorderInfo.originalOrderNumber);
    this.routesList.splice(inquiryRuletoDelete, 1);
    // Inquiry Rules screen redesign and Rules Reordering
    let nodeToReOrder = [];

    this.gridApiInquiry.forEachNode(function (node) {
      if (inqRulesReorderInfo.originalOrderNumber === node.data.order) {
        // Inquiry Rules screen redesign and Rules Reordering
        nodeToReOrder.push(node.data)
      }
    });
    if (nodeToReOrder.length > 0) {
      this.gridApiInquiry.applyTransaction({ remove: nodeToReOrder });
    }
    this.addInqRuleAtNewPosition(inqRulesReorderInfo, nodeToReOrder[0]);
    PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled, this.userDataService.loggedInUserInfo.userId, "Inquiry view", "Remove rules", "Click", "Remove rules", 0);
  }
  addInqRuleAtNewPosition(inqRulesReorderInfo: InqRulesReorderInfo, nodeToReOrder) {
    this.routesList.splice(inqRulesReorderInfo.newOrderNumber - 1, 0, nodeToReOrder);
    this.gridApiInquiry.applyTransaction({
      add: [nodeToReOrder],
      addIndex: inqRulesReorderInfo.newOrderNumber - 1
    });
  }

  hideModal() {
    this.restoreRules();
    this.validationRules = '';
    PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled, this.userDataService.loggedInUserInfo.userId, "Inquiry view", "Reset rules", "Click", "Reset rules", 0);
  }

  closeBtn() {
    this.display = false;
    this.validationRules = '';
  }

  onSelectionRules() {
    let selectedRouteCount = 0;
    let arr = this.gridApiInquiry.getSelectedRows();
    selectedRouteCount = arr.length;
    if (selectedRouteCount == 0) {
      this.rulesDeleteFlag = true;
    } else {
      this.rulesDeleteFlag = false;
    }
  }

  isFirstColumn(params) {
    var displayedColumns = params.api.getAllDisplayedColumns();
    var thisIsFirstColumn = displayedColumns[0] === params.column;
    return thisIsFirstColumn;
  }

  onGridReadyInquiry(params) {
    this.gridApiInquiry = params.api;
    this.gridColumnApiInquiry = params.columnApi;
    params.api.sizeColumnsToFit();
  }
  onGridDuplicate(params) {
    this.gridApiDuplicateInq = params.api;
    this.gridColumnApiDuplicateInquiry = params.columnApi;
  }

  // select clicked inquiry rule (and make sure it is visible)
  selectInquiryRule(event: any) {
    if (event && event.currentTarget && (event.currentTarget.innerText !== undefined)) {
      let index = parseInt(event.currentTarget.innerText);
      index = Math.max(0, index - 1);
      if (this.gridApiInquiry) {
        let c_node = this.gridApiInquiry.getDisplayedRowAtIndex(index);
        if (c_node) {
          c_node.setSelected(true, true);
          this.gridApiInquiry.ensureIndexVisible(index, 'middle');
          if (this.overlaypanel) {
            this.overlaypanel.hide();
          }
        }
      }
    }
    PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled, this.userDataService.loggedInUserInfo.userId, "Inquiry view", "Select rule", "Click", "Select rule", 0);
  }

  //handle inquiry index selection
  handleDupInqSelection() {
    const me = this;
    setTimeout(() => {
      let elems = $('#inquiryRulesGridDuplicate .duplicate-badge');
      if (!elems || !elems.length) {
        return;
      }
      elems.click(event => {
        me.selectInquiryRule(event);
      });
    }, 1000);
    //PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Inquiry view", "Check duplicate rule", "Click", "Check duplicate rule", 0);
  }
  addRules(key, char, pinned) {
    // Initialize a new rule object with default empty values
    let obj: any = {
      field1: 'From',
      rule1: 'Contains',
      value1: '',    // This will be set uniquely per row
      field2: 'Subject',
      rule2: 'Contains',
      value2: '',    // This will be set uniquely per row
      field3: 'To',
      rule3: 'Contains',
      value3: '',    // 'To' field - will be populated with recipients
      checkbox: false,
      apr: '',
      art: '',
      at: '',
      user: '',
      assign: '',
      attachment: false
    };

    // Populate unique values for 'From', 'Subject', and 'To' for each new row
    if (this.selectedMail?.conversationList?.length) {
      // 'From' field: pick the first recipient's display name
      obj.value1 = this.selectedMail.conversationList[0]?.recipients[0]?.displayName || '';

      // 'Subject' field: assign the subject of the email
      obj.value2 = this.selectedMail.conversationList[0]?.subject || '';

      // 'To' field: collect other recipients' display names (excluding the first one)
      let val2 = [];
      for (let i = 1; i < this.selectedMail.conversationList[0]?.recipients.length; i++) {
        val2.push(this.selectedMail.conversationList[0].recipients[i].displayName);
      }
      obj.value3 = val2.join(',');  // Join the recipients' names into a single string
    }

    // Add the new rule at the beginning of the routesList with a fresh copy of obj
    this.routesList.unshift({ ...obj });

    // Recalculate and update the 'order' for all rules to maintain correct numbering
    this.routesList = this.routesList.map((element, index) => {
      return {
        ...element,
        order: index + 1  // Assign incremented order to each row
      };
    });

    // Apply the full transaction to ag-Grid to reflect all changes in the grid
    this.gridApiInquiry.applyTransaction(this.routesList);

    // Set focus and start editing the new rule in the grid
    this.gridApiInquiry.setFocusedCell(0, "rule1");
    this.gridApiInquiry.startEditingCell({
      rowIndex: 0,
      colKey: "rule1"
    });

    // Track the addition of a new rule using PiwikProUtils for analytics
    PiwikProUtils.trackJsonDataForEvent(
      this.userDataService.loggedInUserInfo.pivotProConfigEnabled,
      this.userDataService.loggedInUserInfo.userId,
      "Inquiry view",
      "Add new rule",
      "Click",
      "Add new rule",
      0
    );
  }






  /**
   * Method to to download Inquiry rules as Excel file
   */
  download_rules() {
    let columnList = this.gridApiInquiry.columnModel.displayedColumns.filter(col => col.colId !== "order").map(column => column.colId);
    let headerMsg = [
      [{ data: { type: "String", value: "The rules in this file will overwrite the rules shown in the screen." }, mergeAcross: 6 }], []
    ];
    let params = {
      customHeader: headerMsg,
      columnKeys: columnList,
      fileName: "Inquiry Rules",
      sheetName: "Inquiry Rules"
    };
    this.gridApiInquiry.exportDataAsExcel(params);
    PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled, this.userDataService.loggedInUserInfo.userId, "Inquiry view", "Download rules", "Click", "Download rules", 0);
  }

  deleteInquiryRulesRecords() {
    this.showPopUpDelete = false; // Jira:4703 : Confirmation Dialog before delete
    let selectedInquiryRules = this.gridApiInquiry.getSelectedRows();
    this.gridApiInquiry.applyTransaction({ remove: selectedInquiryRules });
    for (let index = 0; index < selectedInquiryRules.length; index++) {
      for (let i = 0; i < this.routesList.length; i++) {
        if (this.routesList[i].ruleOrder === selectedInquiryRules[index].ruleOrder) {
          this.routesList.splice(i, 1);
          break;
        }
      }
    }
    PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled, this.userDataService.loggedInUserInfo.userId, "Inquiry view", "Delete rules records", "Click", "Delete rules records", 0);
  }

  closeModal(_str: any) {
    if (_str == "success") { this.displaySuccess = false; }
    if (_str == "error") { this.displayError = false; }
  }

  onPaginationChanged() {
    if (this.gridApiInquiry) {
      this.lbTotalRecords = this.gridApiInquiry.paginationGetRowCount();
      this.lbPageSize = this.gridApiInquiry.paginationGetPageSize();
      this.lbCurrentPage = this.gridApiInquiry.paginationGetCurrentPage() + 1;
      this.lbTotalPages = this.gridApiInquiry.paginationGetTotalPages();

      let currentPage = this.gridApiInquiry.paginationGetCurrentPage() + 1;
      let lastPage = this.gridApiInquiry.paginationGetTotalPages();
      if (currentPage == lastPage) {

        this.disablePaginationNextLast = true;

      }
      else {

        this.disablePaginationNextLast = false;
      }

      if (currentPage == 1) {

        this.disablePaginationPrevFirst = true;
      }
      else {
        this.disablePaginationPrevFirst = false;
      }

      if (this.lbPageSize > this.lbTotalRecords) {
        this.lbPageSize = this.lbTotalRecords;
      }
      else {
        this.lbPageSize = this.gridApiInquiry.paginationGetPageSize();
      }
    }
  }
  setText(selector, text) {
    document.querySelector(selector).innerHTML = text;
  }
  setLastButtonDisabled(disabled) {
  }
  onBtNext() {
    this.gridApiInquiry.paginationGoToNextPage();
  }
  onBtPrevious() {
    this.gridApiInquiry.paginationGoToPreviousPage();
  }
  onBtLast() {
    this.gridApiInquiry.paginationGoToLastPage();
  }
  onBtFirst() {
    this.gridApiInquiry.paginationGoToFirstPage();
  }
  // search for inquiry rules
  onKey(event: any) {
    this.gridApiInquiry.setQuickFilter(event.target.value);
  }
  filterIconClick() {
    this.filterIconHide = true;
  }
  filterCloseClick() {
    this.filterIconHide = false;
    this.filterText = "";
    this.gridApiInquiry.setQuickFilter("");
  }
  showAddtoGrpPanel(event, overlaypanel: OverlayPanel): void {
    overlaypanel.toggle(event);
  }

  saveInquiryRules() {
    let saveInquiryRules: SaveInquiryRules = new SaveInquiryRules();
    saveInquiryRules.groupName = this.groupName;
    saveInquiryRules.version = this.version;
    saveInquiryRules.criteriaList = [];
    this.validationCheck = true;
    this.routesList.every((element, index) => {
      if ((element.value1 !== '' && element.value1 !== null) || (element.value2 !== '' && element.value2 !== null) || (element.value3 !== '' && element.value3 !== null)) {
        let groupInquiryCriteriaList: GroupInquiryCriteriaList = new GroupInquiryCriteriaList();
        groupInquiryCriteriaList.from = element.value1;
        groupInquiryCriteriaList.fromOperator = element.rule1;
        groupInquiryCriteriaList.ruleOrder = (this.routesList.length - index) * 10;
        groupInquiryCriteriaList.subject = element.value2;
        groupInquiryCriteriaList.subjectOperator = element.rule2;
        groupInquiryCriteriaList.to = element.value3;
        groupInquiryCriteriaList.toOperator = element.rule3;
        groupInquiryCriteriaList.attachment = element.attachment;

        groupInquiryCriteriaList.actions = new GroupInquiryActions();

        if (element.apr && element.apr.length != 0) {
          groupInquiryCriteriaList.actions.assignProcessingRegion = Array.isArray(element.apr) ? element.apr[0] : element.apr;
        }

        if (element.art && element.art.length != 0) {
          groupInquiryCriteriaList.actions.assignRequestType = Array.isArray(element.art) ? element.art[0] : element.art;
        }

        if (element.at && element.at.length != 0) {
          groupInquiryCriteriaList.actions.assignToTag = Array.isArray(element.at) ? element.at[0] : element.at;
        }

        //Use soeid instead of user name.
        if (element.user && element.user.length != 0) {
          let userName = Array.isArray(element.user) ? element.user[0] : element.user;
          let entitleUser = this.allUsers.find(u => u.userName === userName); //Assign user getting set blank
          groupInquiryCriteriaList.actions.assignToUserId = entitleUser ? entitleUser.userId : '';
        }

        if (element.assign && element.assign.length != 0) {
          groupInquiryCriteriaList.actions.assignGroup = Array.isArray(element.assign) ? element.assign[0] : element.assign;
        }

        groupInquiryCriteriaList.actions.markAsNonInquiry = element.checkbox;
        groupInquiryCriteriaList.actions.markForDeletion = element.deletion;

        if (this.isBccGrpSelected) {
          if (!groupInquiryCriteriaList.actions.assignGroup) {
            this.validationCheck = false;
          }
        } else {
          /*Validation issue on save Inquiry */
          this.validationCheck = (!(element.hasOwnProperty("apr") && element.apr.length == 0) ||
            !(element.hasOwnProperty("art") && element.art.length == 0) ||
            !(element.hasOwnProperty("at") && element.at.length == 0) ||
            !(element.hasOwnProperty("user") && element.user.length == 0) ||
            element.checkbox || element.attachment);
        }
        if (!this.validationCheck) {
          this.validationRules = 'Please select at least one action for the rule';
          return false;
        }
        saveInquiryRules.criteriaList.push(groupInquiryCriteriaList);
        return true;
      } else {
        this.validationCheck = false;
        this.validationRules = 'To, From & Subject Values should not be empty';
        return false;
      }
    });

    if (this.validationCheck) {
      this.groupAdminService.saveInquiryData(saveInquiryRules).subscribe(result => {
        this.display = false;
        this.displaySuccess = true;
      },
        error => {
          this.display = false;
          this.displayError = true;
        });
    }
    PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled, this.userDataService.loggedInUserInfo.userId, "Inquiry view", "Save rules", "Click", "Save rules", 0);
  }

  /**
   *  Method to open the file explorer to browse the file.
   */
  uploadInquiry() {
    this.uploadFilesForInquiry.nativeElement.click();
  }

  /**
   *Method to upload the inquiry rule and Read the inquiry rules.
   */
  fileEventForInquiry(evt) {
    this.invalidRowMessage = [];
    this.uploadedValidInqRules = [];

    const fileValidationObj = this.validateFileUpload(evt.target.files[0].name);
    if (fileValidationObj.isValid) {
      let fileReader = new FileReader();
      fileReader.onload = (e) => {
        // Reset the upload value for the same file to upload.
        evt.target.value = null;

        let arrayBuffer = fileReader.result;
        let headers = this.excelService.getHeaderRow(arrayBuffer, 2);

        const headerValidateObj = this.validateFileHeader(headers);
        if (headerValidateObj.isValid) {
          let uploadInquiryRules = this.excelService.readExcelContent(arrayBuffer, false, 2);
          const inquiryRulesObj = this.getValidAndInvalidRules(uploadInquiryRules);
          let rejectRowMap = inquiryRulesObj.invalid;
          this.uploadedValidInqRules = [...inquiryRulesObj.valid];

          if (rejectRowMap.size > 0) {
            rejectRowMap.forEach((val, key) => {
              let rejectRowsDetails = 'Record #' + key + ' is rejected due to invalid data in ' + val.join(', ') + ' field.\r\n';
              this.invalidRowMessage.push(rejectRowsDetails);
            });
            // Show Rejected Inquiry Rules
            this.inquiryRulesRejected = true;
          }
          else if (this.uploadedValidInqRules.length > 0) {
            this.onInquiryRulesApply();
          }
        }
        else {
          this.onInquiryRulesShowMessage(false, headerValidateObj.errorMessage);
        }
      }
      fileReader.readAsArrayBuffer(evt.target.files[0]);
    }
    else {
      // Reset the upload value for the same file to upload.
      evt.target.value = null;
      this.onInquiryRulesShowMessage(false, fileValidationObj.errorMessage);
    }
  }


  //Validation for Uploaded Inquiry Rules
  /**
   * Method to validate the uploaded file type.
   * @param fileName
   */
  validateFileUpload(fileName: string): any {
    let validateObj = { isValid: true, errorMessage: '' };

    // Upload file should be Excel i.e extension should be .xlsx
    if (!fileName.includes('.xlsx')) {
      validateObj.isValid = false;
      validateObj.errorMessage = 'File upload failed due to invalid file type; only supported file type is .xlsx';
    }
    return validateObj;
  }

  /**
   * Method to validate the upload file has same header as of grid header except sequence column.
   * @param headers
   */
  validateFileHeader(headers: any): any {
    let validateObj = { isValid: true, errorMessage: '' };
    let columnList = this.gridApiInquiry.columnModel.displayedColumns.filter(col => col.colId !== "order").map(column => column.colDef.headerName);

    // Validate that upload Excel and Grid have same columns.
    let isHeaderValid = true;
    if (columnList.length === headers.length && columnList.every(col => headers.includes(col))) {
      for (let idx = 0; idx < columnList.length; idx++) {
        isHeaderValid = columnList[idx] === headers[idx];
        if (!isHeaderValid) {
          return;
        }
      }
    } else {
      isHeaderValid = false;
    }

    if (!isHeaderValid) {
      validateObj.isValid = isHeaderValid;
      validateObj.errorMessage = 'File Upload failed due to incorrect headers';
    }
    return validateObj;
  }

  /**
   * Method to get the valid and invalid inquiry rules from the uploaded rules.
   * @param uploadInquiryRules
   */
  getValidAndInvalidRules(uploadInquiryRules: any): any {

    let rejectRowMap = new Map();
    let validInquiryRules = [];

    for (let i = 0; i < uploadInquiryRules.length; i++) {
      let rejectedColArr = [];
      let rowData = uploadInquiryRules[i];

      if (this.isBccGrpSelected) {
        if (rowData['Assign To Group'] && !this.inqAssignedGroups.includes(rowData['Assign To Group'])) {
          rejectedColArr.push('Assign To Group');
        }
      }
      else {
        //Validate the fields
        if (rowData['USER'] && !this.groupUsers.includes(rowData['USER'])) {
          rejectedColArr.push('User');
        }

        if (rowData['REQUEST TYPE'] && !this.groupRequestTypes.includes(rowData['REQUEST TYPE'])) {
          rejectedColArr.push('Request Type');
        }

        if (rowData['TAG'] && !this.groupTags.includes(rowData['TAG'])) {
          rejectedColArr.push('Tag');
        }

        if (rowData['REGION'] && !this.groupProcessingRegion.includes(rowData['REGION'])) {
          rejectedColArr.push('Region');
        }
      }
      if (rejectedColArr.length > 0) {
        rejectRowMap.set(i + 1, rejectedColArr);
      } else {
        validInquiryRules.push(rowData);
      }
    };

    const inquiryRulesObj = {
      'invalid': rejectRowMap,
      'valid': validInquiryRules
    }
    return inquiryRulesObj;
  }

  /**
   * Method to update the inquiry rules grid with upload inquiry rules.
   * @param validInquiryRules
   */
  updateInquiryRulesGrid(validInquiryRules: any): void {
    if (validInquiryRules.length > 0) {
      let inquiryRuleList = [];
      validInquiryRules.forEach((rowData, i) => {
        let inquiryRule = {
          'order': i + 1, // Show row seq as per number of records.
          'rule1': rowData['FROM RULE'],
          'value1': rowData['FROM VALUE'],
          'rule2': rowData['SUBJECT RULE'],
          'value2': rowData['SUBJECT VALUE'],
          'rule3': rowData['TO RULE'],
          'value3': rowData['TO VALUE'],
          'checkbox': "true" === rowData['NON-INQUIRY'],
          'user': rowData['USER'],
          'art': rowData['REQUEST TYPE'],
          'at': rowData['TAG'],
          'apr': rowData['REGION'],
          'assign': rowData['Assign To Group'],
          'attachment': "true" === rowData['ATTACHMENT']
        };
        inquiryRuleList.push(inquiryRule);
      });
      this.gridApiInquiry.applyTransaction(inquiryRuleList);
      this.routesList = [];
      this.routesList = [...inquiryRuleList];

      let message = 'Inquiry Rules are created successfully !';
      this.onInquiryRulesShowMessage(true, message);
    }
  }

  /**
   *On Show Inquiry Rules Upload messages
   */
  onInquiryRulesShowMessage(isSuccess: boolean, message: string): void {
    this.inquiryRulesUpload = true;
    this.inquiryRulesMsgTitle = isSuccess ? 'Success Message' : 'Error Message';
    this.inquiryRulesMsg = message;

    //  Timeout Message
    setTimeout(() => {
      this.inquiryRulesUpload = false;
      this.inquiryRulesMsgTitle = '';
      this.inquiryRulesMsg = '';
    }, 3000);
  }

  /**
   *Method to show the overwrite rule confirmation popup.
   */
  onInquiryRulesApply(): void {
    let overwriteMsg = 'The rules from the uploaded file will overwrite the existing set of rules in the screen.';
    this.inquiryRulesConfirm = true;
    this.inquiryRulesMsg = overwriteMsg;
  }

  /**
   *Method called when user confirm to override the existing inquiry rules.
   */
  onInquiryRulesConfirm(): void {
    this.onInquiryRulesCancel();
    this.updateInquiryRulesGrid(this.uploadedValidInqRules);
  }

  /**
   *Method called when user cancle to override the existing inquiry rules.
   */
  onInquiryRulesCancel(): void {
    this.inquiryRulesConfirm = false;
    this.inquiryRulesMsg = '';
  }

  /**
   * Method called when user confirm or cancel for Rejected inquiry rules.
   */
  onInquiryRulesRejectedConfirm(): void {
    this.inquiryRulesRejected = false;
    if (this.uploadedValidInqRules.length > 0) {
      this.onInquiryRulesApply();
    }
  }

  /**
   * Method to download failed Inquiry rules
   */
  onDownloadLogs(): void {
    this.excelService.downloadAsText(this.invalidRowMessage, "Rejected_Inquiry_Rules");
  }
}

import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { QmaConstant } from '../../constant/qma-constant';
import { Observable } from 'rxjs';
 
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  })
};
@Injectable({
  providedIn: 'root'
})
export class HelpService {
  baseURL: string;
  userURL: string;
  constructor(private http: HttpClient) { 
    this.baseURL = QmaConstant.BASE_URL + "/inquiry";
    this.userURL = '/addNotification';
  }
   /**
   * Method to supportFeedback
   * 
   * @param supportFeedback
   */
  helpNotification(data, editorData): Observable<any> {
    let req = "request="+ encodeURIComponent(JSON.stringify(data));
    let body = req + ":::editorData:" + encodeURIComponent(editorData);
    return this.http.post(this.baseURL + this.userURL, body , httpOptions);
  }
}

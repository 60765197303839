import { Injectable, Output, EventEmitter, Directive } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable, BehaviorSubject } from 'rxjs';
import { QmaConstant } from '../../constant/qma-constant';
import { MyView } from '../../model/MyViews/MyView';
import { NameCount } from '../../model/Common/NameCount';
import { UserView } from '../../model/LoginUserInfo/UserView';

const httpFormPostOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Directive()
@Injectable({
  providedIn: 'root'
})
export class MyViewsService {
  baseURL: string;
  myViewColumns: NameCount[] = [];
  userView: UserView;
  userViewSubj = new BehaviorSubject({});
  saveUserViewSubj = new BehaviorSubject({});
  deleteUserViewSubj = new BehaviorSubject({});
  @Output() onCloseMyView = new EventEmitter();
  @Output() onMyViewUpdate = new EventEmitter();
  myViewInitialized: boolean = false; // C153176-5287: track whether myView config has been initialized

  constructor(private httpClient: HttpClient) {
    this.baseURL = QmaConstant.BASE_URL + '/users';
  }

  public getUserView(viewName ="xyz") {
    // C153176-5287: do not call default view's getUserView more than once
    if (viewName === 'xyz' && this.myViewInitialized) {
      return;
    }
    this.httpClient.post<MyView>(this.baseURL + '/getUserView', {"viewName": viewName}, httpFormPostOptions)
      .subscribe(userView => {
        this.userViewSubj.next(userView);
        this.myViewInitialized = true; // C153176-5287
      });
  }

  public getUserViewSubj(): Observable<any> {
    return this.userViewSubj.asObservable();
  }

  public saveUserView(queryObject) {
    this.httpClient.post<MyView>(this.baseURL + '/saveUserView', queryObject, httpFormPostOptions)
      .subscribe(userView => {
        this.saveUserViewSubj.next(userView);
      }, error => {
        console.log('saveUserView, error:', error);
        this.saveUserViewSubj.next(error);
      });
  }

  public deleteUserView(viewName) {
    this.httpClient.post<MyView>(this.baseURL + '/deleteUserView', {viewName: viewName}, httpFormPostOptions)
      .subscribe(userView => {
        this.deleteUserViewSubj.next(userView);
      }, error => {
        console.log('deleteUserView, error:', error);
        this.deleteUserViewSubj.next(error);
      });
  }

  public getSaveUserViewSubj(): Observable<any> {
    return this.saveUserViewSubj.asObservable();
  }

  public getDeleteUserViewSubj(): Observable<any> {
    return this.deleteUserViewSubj.asObservable();
  }
}

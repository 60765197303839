import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ClcService } from "src/app/services/clc/clc.service";
import { FormGroup, FormBuilder, Validators, FormControlName, FormControl } from '@angular/forms';
import { CLCSearchRequest } from '../../../model/clc/CLCSearchRequest';
import { NewMessageService } from '../../../services/newMessage/new-message.service';
import { TabDataService } from '../../../services/tab-data.service';
import { QmaConstant } from '../../../constant/qma-constant';
import { InquiryTemplate } from '../../../model/user-profile/inquiryTemplate';
import { UserDataService } from '../../../services/user-data.service';
import * as _ from 'underscore';
import { UserSignature } from "src/app/model/LoginUserInfo/UserSignature";
import { InboxService } from '../../../services/inbox.service';
import { DatePipe } from "@angular/common";
import { AppUtils } from "src/app/common/utility/appUtil";
const USER_NAME = "USER_NAME";
const EMAIL_ADDR = "EMAIL_ADDR";
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import * as $ from 'jquery';

import { GridOptions, Module } from "@ag-grid-community/core";

@Component({
  selector: 'app-clc',
  templateUrl: './clc.component.html',
  styleUrls: ['./clc.component.scss']
})
export class ClcComponent implements OnInit {
  modules: Module[] = [ClientSideRowModelModule];
  cLCSearchRequest: any = {};
  clcAddToReply: any = {};
  clcHeading: string;
  elementClcGridData: any = [];
  localElementClcGridData: any = [];
  clcArray: any[] = [];
  error: any = { isError: false, errorMessage: '' };
  dbMaxRowCount: number;
  pinnedArray: any;
  public clcList: any = [];
  public clcColumn: any = [];
  public clcPinnedColumn: any = [];
  public gridApiClc;
  public gridPinnedApi;
  public clcForm: FormGroup;
  public clcPinnedCheckbox;
  public clcCheckbox;
  public clcPinnedList: any = [];
  index: number = 1;
  rowSelection: string;
  linkUnlinkMessage: string;
  ValidMessage: string = '';
  ValidationFlag: boolean = false;
  ValidSearchMessage: string = '';
  ValidationSearchFlag: boolean = false;
  displayGrid: boolean = false;
  tradeId: string;
  security: string;
  securities: string[];
  selectedCLCTrades: any = [];
  source: string;
  sources: string[];
  cliendId: string;
  sdi: string;
  tradeDate: string;
  settlementDate: string;
  status: string;
  quantity: number;
  amount: number;
  rowLimit: number;
  pinned: boolean;
  search: boolean;
  closePinnedSuccessFlag: boolean = false;
  tradeIdentifierOptions: any[] = [{ label: 'Select Trade Id', value: '' }];
  sdiOptions: any[] = [{ label: 'Select SDI', value: '' }];
  securityOptions: any[] = [{ label: 'Select Security', value: '' }];
  sourceOptions: any[] = [{ label: 'Select Source', value: '' }];
  statusOptions: any[] = [{ label: 'Select Status', value: '' }];
  clientIdOptions: any[] = [{ label: 'Select Client Id', value: '' }];
  maxDate = new Date();

  public lbTotalRecords: number = 0;
  public lbPageSize: number = 0;
  public lbCurrentPage: number = 0;
  public lbTotalPages: number = 0;
  public disablePaginationNextLast: boolean = false;
  public disablePaginationPrevFirst: boolean = false;

  quickReply: InquiryTemplate;
  inquiry: any = {};
  notes: any[] = []; // Notes
  @Input() clc: any; // sent from parent

  @Output() closeClc: any = new EventEmitter();
  @Output() tradesEvent: any = new EventEmitter();

  // Spinner Variable
  showClcSpinner: boolean = false;

  constructor(private clcService: ClcService, private formBuilder: FormBuilder, private tabDataService: TabDataService, private datePipe: DatePipe, private newMessageService: NewMessageService,) {
    this.populateCLCOptions();
  }

  ngOnInit() {
    // this.createClcHeader();
    this.clcForm = this.formBuilder.group({
      tradeId: [''],
      tradeIdInput: [''],
      clientId: [''],
      clientIdInput: [''],
      sdi: [''],
      sdiInput: [''],
      security: [''],
      securityInput: [''],
      statusInput: [''],
      sourceInput: [''],
      tradeDate: [''],
      settlementDate: [''],
      amount: [''],
      quantity: [''],
      rowLimit: ['']
    });

    this.initialize();
  }

  initialize(): void {
    if (this.checkInquiry()) {  //It is applicable for Inbox Inquiry
      // C170665-1822 Update CLC Assist Icon/Label in QMA
      this.clcHeading = "TRANX lookup for Inquiry " + this.clc.inquiryId + " : " + this.clc.groupName;

      let initialSelectedCLCTrades = { 'inquiryId': this.clc.inquiryId, 'groupId': this.clc.groupId };
      this.clcService.getLinkedTradesToInquiry(initialSelectedCLCTrades).subscribe(result => {
        if (result.data && result.data.length > 0) {
          this.pinnedArray = result.data;
          if (this.gridPinnedApi) {
            // C153176-4594 - #4 | Populate selected trades as pinned in Sent folder
            this.gridPinnedApi.applyTransaction(this.pinnedArray);
            this.openPinnedTrades();
          }
        }
      });
    } else { //It is applicable for New Message
      // C170665-1822 Update CLC Assist Icon/Label in QMA
      this.clcHeading = "TRANX lookup for New Inquiry";
    }
  }

  /**
   * Methdo to open pinned trades.
   */
  openPinnedTrades(): void {
    /** After page gets loaded if there is pinned trades, it should open pinned section after 1 sec delay to control screen distortion */
    setTimeout(() => {
      if (this.pinnedArray && this.pinnedArray.length > 0) {
        this.index = 0;
      }
    }, 1000);
  }

  onTabOpen(e) {
    this.index = e.index;
  }

  /**
   * Method to populate CLC options
   */
  populateCLCOptions(): void {
    this.clcService.getClcConfigForUI().subscribe(clc => {

      clc.tradeRefOptions.forEach(trade => {
        this.tradeIdentifierOptions.push({ label: trade, value: trade });
      });

      clc.sdiOptions.forEach(sdi => {
        this.sdiOptions.push({ label: sdi, value: sdi });
      });

      clc.securityOptions.forEach(security => {
        this.securityOptions.push({ label: security, value: security });
      });

      clc.sourceOptions.forEach(source => {
        this.sourceOptions.push({ label: source, value: source });
      })
      clc.statusOptions.forEach(status => {
        this.statusOptions.push({ label: status, value: status });
      })
      clc.clientIdOptions.forEach(client => {
        this.clientIdOptions.push({ label: client, value: client });
      })
      this.rowLimit = clc.clcGridPageRecordCount;
      this.dbMaxRowCount = clc.page_size;
    });
  }


  /**
  * Method To Check data available for CLC or not
  */
  checkInquiry() {
    return this.clc != undefined && this.clc.inquiryId != null && this.clc.groupId != undefined;
  }

  /**
   * Method to reset CLC Search.
   */
  resetCLC(): void {
    this.clcForm.reset();

    // C153176-4594 - #9: Search result should clear on click of clear.
    this.displayGrid = false;
    this.clcColumn = [];
  }

  /**
   * On Submit CLC details
   */
  onClcSubmit() {
    this.createRowObj();
  }

  createClcHeader(): void {
    this.clcColumn = [
      {
        colId: 'accountName',
        field: "accountName",
        headerName: 'Account Name',
        width: 250,
        editable: false,
        cellStyle: { 'white-space': 'normal !important' }
      },
      {
        colId: 'tradeRef',
        field: "tradeRef",
        headerName: 'Trade Ref',
        width: 150,
        editable: false,
        cellEditor: "agSelectCellEditor"
      },
      {
        colId: 'citiTradeId',
        field: "citiTradeRef",
        headerName: 'Citi Trade ID',
        width: 150,
        editable: false
      },
      {
        colId: 'securityName',
        field: "securityName",
        headerName: 'Security Name',
        width: 150,
        editable: false
      },
      {
        colId: 'sourceSystem',
        field: "sourceSystem",
        headerName: 'Source System',
        width: 150,
        editable: false,
        cellEditor: "agSelectCellEditor"
      },
      {
        colId: 'cusip',
        field: "cusip",
        headerName: 'CUSIP Code',
        width: 150,
        editable: false
      },
      {
        colId: 'isin',
        field: "isin",
        headerName: 'ISIN Code',
        width: 150,
        editable: false,
        cellEditor: "agSelectCellEditor"
      },
      {
        colId: 'sedol',
        field: "sedol",
        headerName: 'SEDOL Code',
        width: 150,
        editable: false
      },
      {
        colId: 'sdi1',
        field: "sdi1",
        headerName: 'SDI Line 1',
        width: 150,
        editable: false
      },
      {
        colId: 'sdi2',
        field: "sdi2",
        headerName: 'SDI Line 2',
        width: 150,
        editable: false
      },
      {
        colId: 'sdi3',
        field: "sdi3",
        headerName: 'SDI Line 3',
        width: 150,
        editable: false
      },
      {
        colId: 'gpName',
        field: "gpName",
        headerName: 'GP name',
        width: 150,
        editable: false
      },
      {
        colId: 'gpId',
        field: "gpId",
        headerName: 'GPID',
        width: 150,
        editable: false
      },
      {
        colId: 'mgId',
        field: "mgId",
        headerName: 'MGID',
        width: 150,
        editable: false
      },
      {
        colId: 'commentary',
        field: "commentary",
        headerName: 'Commentary',
        width: 150,
        editable: false
      },
      {
        colId: 'amtRemaining',
        field: "amtRemaining",
        headerName: 'Amt Remaining',
        width: 150,
        editable: false
      },
      {
        colId: 'qtyRemaining',
        field: "qtyRemaining",
        headerName: 'Qty Remaining',
        width: 150,
        editable: false
      },
      {
        colId: 'tradeDate',
        field: "tradeDate",
        headerName: 'Trade Date',
        width: 150,
        editable: false
      },
      {
        colId: 'settDate',
        field: "settDate",
        headerName: 'Sett Date',
        width: 150,
        editable: false
      },
      {
        colId: 'buySell',
        field: "buySell",
        headerName: 'Buy/Sell',
        width: 90,
        editable: false,
        cellStyle: function (params) {
          if (params.value == 'Buy') { return { background: '#007bff', color: '#ffffff' }; }
          if (params.value == 'Sell') { return { background: '#28a745', color: '#ffffff' }; }
        }
      },
      {
        colId: 'status',
        field: "status",
        headerName: 'Status',
        width: 150,
        editable: false
      },
      {
        colId: 'conAmount',
        field: "conAmount",
        headerName: 'Amt Con',
        width: 150,
        editable: false
      },
      {
        colId: 'conQuantity',
        field: "conQuantity",
        headerName: 'Qty Con',
        width: 150,
        editable: false
      },
      {
        colId: 'failReason',
        field: "failReason",
        headerName: 'Fail Reason',
        width: 150,
        editable: false
      },
      {
        colId: 'tranType',
        field: "tranType",
        headerName: 'Tran Type',
        width: 150,
        editable: false
      },
      {
        colId: 'imsNumber',
        field: "imsNumber",
        headerName: 'IMS number',
        width: 150,
        editable: false
      },
      {
        colId: 'clntMnem',
        field: "clntMnem",
        headerName: 'Client mneumonic',
        width: 150,
        editable: false
      },
      {
        colId: 'mcleId',
        field: "mcleId",
        headerName: 'MCLE id',
        width: 150,
        editable: false
      },
      {
        colId: 'alertAcronym',
        field: "alertAcronym",
        headerName: 'Alert acronym',
        width: 150,
        editable: false
      }
    ];
    this.clcCheckbox = {
      width: 50,
      checkboxSelection: this.isFirstColumn,
      headerCheckboxSelection: this.isFirstColumn,
      resizable: true,
      suppressMovable: true,
      suppressMenu: true,
      editable: true,
      cellStyle: { 'background-color': 'white' }
    };
    this.rowSelection = "multiple";
  }

  isFirstColumn(params) {
    let displayedColumns = params.columnApi.getAllDisplayedColumns();
    let thisIsFirstColumn = displayedColumns[0] === params.column;
    return thisIsFirstColumn;
  }

  validationSearchInput(postData) {
    this.error = { isError: false, errorMessage: '' };
    if (postData) {
      if (!this.isNull(postData.clcSecurity) && this.isNull(postData.securityClcOption)) {
        this.error = { isError: true, errorMessage: 'Please select security option for search.' };
        return false;
      }
      if (!this.isNull(postData.tradeId) && this.isNull(postData.tradeRefClcOption)) {
        this.error = { isError: true, errorMessage: 'Please select trade ref option for search.' };
        return false;
      }
      if (!this.isNull(postData.clcSdi) && this.isNull(postData.sdiClcOption)) {
        this.error = { isError: true, errorMessage: 'Please select SDI option for search.' };
        return false;
      }
      if (!this.isNull(postData.clcResultRowLimit) && !this.isNull(this.dbMaxRowCount) && parseInt(postData.clcResultRowLimit, 10) > this.dbMaxRowCount) {
        this.error = { isError: true, errorMessage: "Maximun row limit is : (1 -" + this.dbMaxRowCount + ")" };
        return false;
      }
      if (!this.isNull(postData.clientId) && this.isNull(postData.clientIdOptions)) {
        this.error = { isError: true, errorMessage: "Please select client id option for search" };
        return false;
      }
      return true;
    }
    return false;
  }

  createRowObj(): void {
    let tradeid = (this.clcForm.get('tradeId').value) ? this.clcForm.get('tradeId').value : '';
    let tradeIdInput = (this.clcForm.get('tradeIdInput').value) ? this.clcForm.get('tradeIdInput').value : '';
    let clientId = (this.clcForm.get('clientId').value) ? this.clcForm.get('clientId').value : '';
    let clientIdInput = (this.clcForm.get('clientIdInput').value) ? this.clcForm.get('clientIdInput').value : '';
    let sdi = (this.clcForm.get('sdi').value) ? this.clcForm.get('sdi').value : '';
    let sdiInput = (this.clcForm.get('sdiInput').value) ? this.clcForm.get('sdiInput').value : '';
    let security = (this.clcForm.get('security').value) ? this.clcForm.get('security').value : '';
    let securityInput = (this.clcForm.get('securityInput').value) ? this.clcForm.get('securityInput').value : '';
    let statusInput = (this.clcForm.get('statusInput').value) ? this.clcForm.get('statusInput').value : '';
    let sourceInput = (this.clcForm.get('sourceInput').value) ? this.clcForm.get('sourceInput').value : '';
    let tradeDate = (this.clcForm.get('tradeDate').value) ? this.datePipe.transform(this.clcForm.get('tradeDate').value, 'MM-dd-yyyy') : '';
    let settlementDate = (this.clcForm.get('settlementDate').value) ? this.datePipe.transform(this.clcForm.get('settlementDate').value, 'MM-dd-yyyy') : '';
    let amount = (this.clcForm.get('amount').value) ? this.clcForm.get('amount').value : '';
    let quantity = (this.clcForm.get('quantity').value) ? this.clcForm.get('quantity').value : '';
    let rowLimit = (this.clcForm.get('rowLimit').value) ? this.clcForm.get('rowLimit').value : 100;

    this.cLCSearchRequest = {
      "tradeRefClcOption": tradeid, "tradeId": tradeIdInput, "securityClcOption": security, "clcSecurity": securityInput,
      "clcQuantity": quantity, "clcTradeDate": tradeDate, "clcClient": "", "clientIdOptions": clientId, "clcSource": sourceInput,
      "clcAmount": amount, "clcSettDate": settlementDate, "sdiClcOption": sdi, "clcSdi": sdiInput, "clcSearchStatus": statusInput,
      "clcResultRowLimit": rowLimit, "clientId": clientIdInput
    };

    // It will add if inbox available and click on CLC Icon
    if (this.checkInquiry()) {
      this.cLCSearchRequest.inquiryId = this.clc.inquiryId;
      this.cLCSearchRequest.groupId = this.clc.groupId;
    }

    if (this.validationSearchInput(this.cLCSearchRequest)) {
      this.showClcSpinner = true;
      console.debug(":SPINNER:: " + this.showClcSpinner + " ::clc.validationSearchInput");
      this.clcService.clcSearchAll(this.cLCSearchRequest).subscribe(element => {
        this.gridApiClc.applyTransaction(element.clcGridData);
        this.createClcHeader();
        this.showClcSpinner = false;
        console.debug(":SPINNER:: " + this.showClcSpinner + " ::clc.validationSearchInput");
      }, error => {
        this.showClcSpinner = false;
        console.debug(":SPINNER:: " + this.showClcSpinner + " ::clc.validationSearchInput");
        throw new Error('Failed to fetch the clc records' + error);
      });
      this.displayGrid = true;
    }
  }

  onGridReadyClc(params) {
    this.gridApiClc = params.api;
    params.api.sizeColumnsToFit();
  }

  onClcPaginationChanged() {
    if (this.gridApiClc) {
      this.lbTotalRecords = this.gridApiClc.paginationGetRowCount();
      this.lbPageSize = this.gridApiClc.paginationGetPageSize();
      this.lbCurrentPage = this.gridApiClc.paginationGetCurrentPage() + 1;
      this.lbTotalPages = this.gridApiClc.paginationGetTotalPages();

      let currentPage = this.gridApiClc.paginationGetCurrentPage() + 1;
      let lastPage = this.gridApiClc.paginationGetTotalPages();

      this.disablePaginationNextLast = (currentPage == lastPage) ? true : false;
      this.disablePaginationPrevFirst = (currentPage == 1) ? true : false;
      this.lbPageSize = (this.lbPageSize > this.lbTotalRecords) ? this.lbTotalRecords : this.gridApiClc.paginationGetPageSize();
    }
  }

  onBtNext() {
    this.gridApiClc.paginationGoToNextPage();
  }

  onBtPrevious() {
    this.gridApiClc.paginationGoToPreviousPage();
  }

  onBtLast() {
    this.gridApiClc.paginationGoToLastPage();
  }

  onBtFirst() {
    this.gridApiClc.paginationGoToFirstPage();
  }


  submitSearch(value) {
  }

  onGridReadyPinned(params) {
    this.gridPinnedApi = params.api;
    this.gridPinnedApi.applyTransaction(this.pinnedArray);
    this.createPinnedHeader();
    params.api.sizeColumnsToFit();
    this.openPinnedTrades();
  }

  onPinnedClcRows() {
    let selectedRouteCount = 0;
    let selectedPinned: any = [];
    this.clcArray = this.gridApiClc.getSelectedRows();
    if (this.clcArray && (this.clcArray).length > 0) {
      this.ValidSearchMessage = "";
      this.ValidationSearchFlag = false;

      this.clcArray.forEach(element => {
        selectedPinned.push({
          tradeRef: element.tradeRef,
          securityName: element.securityName,
          accountName: element.accountName,
          cusip: element.cusip
        })
      });

      this.selectedCLCTrades = selectedPinned;
      this.selectedCLCTrades = ({
        "inquiryId": this.clc.inquiryId,
        "groupId": this.clc.groupId,
        "selectedCLCTrades": selectedPinned
      });

      this.clcService.linkTradesToInquiry(this.selectedCLCTrades).subscribe(result => {
        this.pinnedArray = result.data;
        this.gridPinnedApi.applyTransaction(this.pinnedArray);
        this.closePinnedSuccessFlag = true;
        this.linkUnlinkMessage = "Trades Linked Successfully.";
        this.index = 0;
        setTimeout(() => { this.closePinnedSuccessFlag = false; }, 3000);
      },
        error => {
          this.closePinnedSuccessFlag = false;
        });
    } else {
      this.ValidSearchMessage = "Select atleast one trade to pin.";
      this.ValidationSearchFlag = true;
    }
  }

  /* Method : On click of UnPin */
  onUnpinnedClcRows() {
    let selectedRouteCount = 0;
    let selectedUnpinned: any = [];

    this.clcArray = this.gridPinnedApi.getSelectedRows();
    if (this.clcArray && (this.clcArray).length > 0) {
      this.ValidMessage = "";
      this.ValidationFlag = false;

      this.clcArray.forEach(element => {
        selectedUnpinned.push(element.tradeRef);
      });

      this.selectedCLCTrades = ({
        "inquiryId": this.clc.inquiryId,
        "groupId": this.clc.groupId,
        "selectedTradeToUnlink": selectedUnpinned
      });

      this.clcService.unlinkTradesToInquiry(this.selectedCLCTrades).subscribe(result => {
        this.pinnedArray = result.data;
        this.gridPinnedApi.applyTransaction(this.pinnedArray);
        this.closePinnedSuccessFlag = true;
        this.linkUnlinkMessage = "Trades Un-Linked Successfully.";
        setTimeout(() => { this.closePinnedSuccessFlag = false; }, 3000);
      },
        error => {
          // console.error('Error while getting response from linkTradesToInquiry()');
          this.closePinnedSuccessFlag = false;
        });
    } else {
      this.ValidMessage = "Select atleast one trade to unpin.";
      this.ValidationFlag = true;
    }
  }

  createPinnedHeader(): void {
    this.clcPinnedColumn = [
      {
        colId: 'tradeRef',
        field: "tradeRef",
        headerName: 'Trade Ref',
        width: 350,
        editable: false,
        cellEditor: "agSelectCellEditor"
      },
      {
        colId: 'accountName',
        field: "client",
        headerName: 'Account Name',
        width: 350,
        editable: false
      },
      {
        colId: 'securityName',
        field: "security",
        headerName: 'Security Name',
        width: 350,
        editable: false
      },
      {
        colId: 'cusip',
        field: "cusip",
        headerName: 'CUSIP Code',
        width: 350,
        editable: false
      }
    ];
    this.clcPinnedCheckbox = {
      width: 250,
      checkboxSelection: this.isFirstColumn,
      headerCheckboxSelection: this.isFirstColumn,
      resizable: true,
      suppressMovable: true,
      suppressMenu: true,
      editable: true,
      cellStyle: { 'background-color': 'white' }
    };
    this.rowSelection = "multiple";
  }

  closePinnedSuccess() {
    this.closePinnedSuccessFlag = false;
  }

  /** @param seachResult - seachResult  */
  addToReply(seachResult: string): any {
    this.getSelectedTrades(seachResult);
  }

  closeModal(_str: any) {
    if (_str == "success") { this.closeClc.emit(false); }
  }

  /**
   * Method to get the selected CLC trades.
   * @param search
   */
  getSelectedTrades(search: string): void {
    let selectedRouteCount = 0;
    let selectedUnpinnedValue: string = "";
    this.clcArray = (search == 'pinnedResult') ? this.gridPinnedApi.getSelectedRows() : this.gridApiClc.getSelectedRows();
    this.showValidationMessages(search, this.clcArray.length);

    if (this.clcArray && (this.clcArray).length > 0) {
      this.clcArray.forEach(element => {
        selectedUnpinnedValue += element.tradeRef + ",";
      });

      selectedUnpinnedValue = selectedUnpinnedValue.replace(/,\s*$/, "");

      this.clcAddToReply = {
        "tradeRefClcOption": 'Trade Ref', "tradeId": selectedUnpinnedValue, "securityClcOption": "", "clcSecurity": "", "clientIdOptions": "",
        "clcQuantity": "", "clcTradeDate": "", "clcClient": "", "clcSource": "", "clcAmount": "", "clcSettDate": "", "sdiClcOption": "", "clcSdi": "",
        "clcSearchStatus": "", "clcResultRowLimit": "100", "clientId": "", "actionSource": "CLC Search for CLC Linked Trades"
      };

      // If CLC data are available
      if (this.checkInquiry()) {
        this.clcAddToReply.inquiryId = this.clc.inquiryId;
        this.clcAddToReply.groupId = this.clc.groupId;
      }

      let tabId = this.clc.tabId;
      if (undefined === tabId) {
        let currTabName = AppUtils.getCurrentTab();
        tabId = currTabName.match(/\d+/g);
        tabId = tabId[0];
      }

      this.clcService.clcSearchAll(this.clcAddToReply).subscribe(element => {
        let existClcRecords: boolean = false; // Flag for already selected clc records
        // Message content with last mail header
        this.elementClcGridData = this.tabDataService.clcData.get(tabId);
        if (this.isNull(this.elementClcGridData)) {
          this.localElementClcGridData = element.clcGridData;
        } else {
          this.localElementClcGridData = [...element.clcGridData, ...this.elementClcGridData];
          existClcRecords = true;
        }
        this.localElementClcGridData = this.removeDuplicates(this.localElementClcGridData, 'tradeRef');
        this.tabDataService.clcData.set(tabId, this.localElementClcGridData);
        const selectedTrades: string = !existClcRecords ? this.getHTMLDataForRow(this.localElementClcGridData) : this.getHTMLDataForRowOnly(this.localElementClcGridData);;
        const selectedCLCTrades: any[] = this.getSelectedCLCTrades(this.localElementClcGridData);
        const clcTrades = {
          'selectedCLCTrades': selectedCLCTrades,
          'selectedCLCTradesStr': selectedTrades
        }
        this.tradesEvent.emit(clcTrades);
      });
    }
  }

  /**
   * Method to get the selected CLC trades, that need to pass in the send inquiry request.
   * @param selectedTrades
   */
  getSelectedCLCTrades(selectedTrades: any[]): any[] {
    let selectedCLCTrades: any[] = [];
    selectedTrades.forEach(trade => {
      selectedCLCTrades.push({ "tradeRef": trade.tradeRef, "securityName": trade.securityName, "accountName": trade.accountName, "cusip": trade.cusip });
    });
    return selectedCLCTrades;
  }

  /**
   * Method to show or hide validation messages.
   * @param search
   * @param selectedItems
   */
  showValidationMessages(search: string, selectedItems: number): void {
    if (selectedItems > 0) {
      if (search === 'pinnedResult') {
        this.ValidMessage = '';
        this.ValidationFlag = false;
      } else {
        this.ValidSearchMessage = '';
        this.ValidationSearchFlag = false;
      }
    } else {
      if (search == 'pinnedResult') {
        this.ValidMessage = 'Please select linked trades.';
        this.ValidationFlag = true;
      } else {
        this.ValidSearchMessage = 'Please select linked trades.';
        this.ValidationSearchFlag = true;
      }
    }
  }

  removeDuplicates(myArr, prop) {
    return myArr.filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
    });
  }

  // Null value check function
  isNull(input) {
    if (input === "" || input === undefined || input == null) {
      return true;
    }
    return false;
  }

  getHTMLDataForRow(clcSelectedRows): string {
    let selectedRowHtml = "";
    const crossIcon = QmaConstant.CLC_DELETE_SPAN;
    if (clcSelectedRows && clcSelectedRows.length > 0) {
      let selectedRowHtml = "<br/><div style='margin:1%;width:80%;' id='conatinerDivForCLCTable'><table id='clcTable' border='1px solid black' style='font-size:10px;white-space:nowrap;border-collapse: collapse;'>" +
        "<thead style='background-color: #002d72;color: #fff;font-weight:bold !important;'><tr>" +
        "<td style='min-width:50px;'>Trade Ref</td>" +
        "<td style='min-width:50px;'>Security Name</td>" +
        "<td style='min-width:50px;'>CUSIP</td>" +
        "<td style='min-width:50px;'>ISIN" + crossIcon + "</td>" +
        "<td style='min-width:70px;'>SDI Line 1" + crossIcon + "</td>" +
        "<td style='min-width:70px;'>SDI Line 2" + crossIcon + "</td>" +
        "<td style='min-width:70px;'>SDI Line 3" + crossIcon + "</td>" +
        "<td style='min-width:70px;'>Trade Date" + crossIcon + "</td>" +
        "<td style='min-width:70px;'>Sett Date" + crossIcon + "</td>" +
        "<td style='min-width:85px;'>Con Amount" + crossIcon + "</td>" +
        "<td style='min-width:85px;'>Con Quantity" + crossIcon + "</td>" +
        "<td style='min-width:70px;'>Buy Sell" + crossIcon + "</td>" +
        "<td style='min-width:70px;'>Status" + crossIcon + "</td>" +
        "<td style='min-width:70px;'>Tran Type" + crossIcon + "</td>" +
        "<td style='min-width:100px;'>Commentary" + crossIcon + "</td>" +
        "<td style='min-width:100px;'>Remarks</td>" +
        "</tr></thead><tbody style='text-align:center;'>";

      clcSelectedRows.forEach(currentRow => {
        selectedRowHtml += "<tr>";
        selectedRowHtml += "<td>" + currentRow.tradeRef + "</td>";
        selectedRowHtml += "<td>" + currentRow.securityName + "</td>";
        selectedRowHtml += "<td>" + currentRow.cusip + "</td>";
        selectedRowHtml += "<td>" + currentRow.isin + "</td>";
        selectedRowHtml += "<td>" + currentRow.sdi1 + "</td>";
        selectedRowHtml += "<td>" + currentRow.sdi2 + "</td>";
        selectedRowHtml += "<td>" + currentRow.sdi3 + "</td>";
        selectedRowHtml += "<td>" + currentRow.tradeDate + "</td>";
        selectedRowHtml += "<td>" + currentRow.settDate + "</td>";
        selectedRowHtml += "<td>" + currentRow.conAmount + "</td>";
        selectedRowHtml += "<td>" + currentRow.conQuantity + "</td>";
        selectedRowHtml += "<td>" + currentRow.buySell + "</td>";
        selectedRowHtml += "<td>" + currentRow.status + "</td>";
        selectedRowHtml += "<td>" + currentRow.tranType + "</td>";
        selectedRowHtml += "<td>" + currentRow.commentary + "</td>";
        selectedRowHtml += "<td></td>";
        selectedRowHtml += "</tr>";
      });
      selectedRowHtml += "</tbody></table><span id='endSpanElementInCLCTableConatainer'></span></div>";
      return selectedRowHtml;
    }
  }

  /**
   * Method to append the already populated clc records and return the selected clc trades an string.
   */
  getHTMLDataForRowOnly(clcSelectedRows): string {
    let selectedRowHtml = "";
    const crossIcon = QmaConstant.CLC_DELETE_SPAN;;
    const headerObject = this.getClcColumnHeaders();
    console.log('Headers : ' + JSON.stringify(headerObject));

    if (clcSelectedRows && clcSelectedRows.length > 0) {
      let selectedRowHtml = "<br/><div style='margin:1%;width:80%;' id='conatinerDivForCLCTable'><table id='clcTable' border='1px solid black' style='font-size:10px;white-space:nowrap;border-collapse: collapse;'>" +
        "<thead style='background-color: #002d72;color: #fff;font-weight:bold !important;'><tr>";

      if (headerObject['Trade Ref'])
        selectedRowHtml += "<td style='min-width:50px;'>Trade Ref</td>";
      if (headerObject['Security Name'])
        selectedRowHtml += "<td style='min-width:50px;'>Security Name</td>";
      if (headerObject['CUSIP'])
        selectedRowHtml += "<td style='min-width:50px;'>CUSIP</td>";
      if (headerObject['ISIN'])
        selectedRowHtml += "<td style='min-width:50px;'>ISIN" + crossIcon + "</td>";
      if (headerObject['SDI Line 1'])
        selectedRowHtml += "<td style='min-width:70px;'>SDI Line 1" + crossIcon + "</td>";
      if (headerObject['SDI Line 2'])
        selectedRowHtml += "<td style='min-width:70px;'>SDI Line 2" + crossIcon + "</td>";
      if (headerObject['SDI Line 3'])
        selectedRowHtml += "<td style='min-width:70px;'>SDI Line 3" + crossIcon + "</td>";
      if (headerObject['Trade Date'])
        selectedRowHtml += "<td style='min-width:70px;'>Trade Date" + crossIcon + "</td>";
      if (headerObject['Sett Date'])
        selectedRowHtml += "<td style='min-width:70px;'>Sett Date" + crossIcon + "</td>";
      if (headerObject['Con Amount'])
        selectedRowHtml += "<td style='min-width:85px;'>Con Amount" + crossIcon + "</td>";
      if (headerObject['Con Quantity'])
        selectedRowHtml += "<td style='min-width:85px;'>Con Quantity" + crossIcon + "</td>";
      if (headerObject['Buy Sell'])
        selectedRowHtml += "<td style='min-width:70px;'>Buy Sell" + crossIcon + "</td>";
      if (headerObject['Status'])
        selectedRowHtml += "<td style='min-width:70px;'>Status" + crossIcon + "</td>";
      if (headerObject['Tran Type'])
        selectedRowHtml += "<td style='min-width:70px;'>Tran Type" + crossIcon + "</td>";
      if (headerObject['Commentary'])
        selectedRowHtml += "<td style='min-width:100px;'>Commentary" + crossIcon + "</td>";
      if (headerObject['Remarks'])
        selectedRowHtml += "<td style='min-width:100px;'>Remarks</td>";
      selectedRowHtml += "</tr></thead><tbody style='text-align:center;'>";

      clcSelectedRows.forEach(currentRow => {
        selectedRowHtml += "<tr>";
        if (headerObject['Trade Ref'])
          selectedRowHtml += "<td>" + currentRow.tradeRef + "</td>";
        if (headerObject['Security Name'])
          selectedRowHtml += "<td>" + currentRow.securityName + "</td>";
        if (headerObject['CUSIP'])
          selectedRowHtml += "<td>" + currentRow.cusip + "</td>";
        if (headerObject['ISIN'])
          selectedRowHtml += "<td>" + currentRow.isin + "</td>";
        if (headerObject['SDI Line 1'])
          selectedRowHtml += "<td>" + currentRow.sdi1 + "</td>";
        if (headerObject['SDI Line 2'])
          selectedRowHtml += "<td>" + currentRow.sdi2 + "</td>";
        if (headerObject['SDI Line 3'])
          selectedRowHtml += "<td>" + currentRow.sdi3 + "</td>";
        if (headerObject['Trade Date'])
          selectedRowHtml += "<td>" + currentRow.tradeDate + "</td>";
        if (headerObject['Sett Date'])
          selectedRowHtml += "<td>" + currentRow.settDate + "</td>";
        if (headerObject['Con Amount'])
          selectedRowHtml += "<td>" + currentRow.conAmount + "</td>";
        if (headerObject['Con Quantity'])
          selectedRowHtml += "<td>" + currentRow.conQuantity + "</td>";
        if (headerObject['Buy Sell'])
          selectedRowHtml += "<td>" + currentRow.buySell + "</td>";
        if (headerObject['Status'])
          selectedRowHtml += "<td>" + currentRow.status + "</td>";
        if (headerObject['Tran Type'])
          selectedRowHtml += "<td>" + currentRow.tranType + "</td>";
        if (headerObject['Commentary'])
          selectedRowHtml += "<td>" + currentRow.commentary + "</td>";
        if (headerObject['Remarks'])
          selectedRowHtml += "<td></td>";
        selectedRowHtml += "</tr>";
      });
      selectedRowHtml += "</tbody></table><span id='endSpanElementInCLCTableConatainer'></span></div>";
      return selectedRowHtml;
    }
  }

  /**
   * Method to get the existing CLC columns.
   */
  getClcColumnHeaders(): any {
    let headerObject = {};
    let countId = this.newMessageService.getIndex() - 1;
    let editorInstance: any = document.getElementById('my-editor' + countId + '_ifr');
    $(editorInstance).contents().find('#clcTable thead tr td').each(function () {
      let headerNode = $(this).contents().filter(function () {
        return this.nodeType == 3; // text node
      });
      let headerName = $(headerNode).text();
      headerObject[headerName] = headerName;
    });
    return headerObject;
  }
}
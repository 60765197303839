<div class="container user-info global-directory">
  <div class="row">
    <div class="col-3 p-2">
      <div class="picture display-individual">
        <span *ngIf="profilePicSrc==null">{{soeId.substr(0,2)}}</span> 
        <img *ngIf="profilePicSrc!=null" [src]="profilePicSrc" alt="">
      </div>
    </div>
    <div class="col-9 p-2">
      <div class="container">
        <div class="row">
          <label>{{name}}</label>
        </div>
        <div class="row">
          <div class="text">{{officer_title || 'Not Available' }} <span *ngIf="job_title!=''">({{job_title || 'Not Available' }})</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <span *ngIf="isCollapsed" (click)="toggleContactInfo()"><em class="pi pi-fw pi-chevron-down"></em></span>
  <span *ngIf="!isCollapsed" (click)="toggleContactInfo()"><em class="pi pi-fw pi-chevron-up"></em></span>
</div>

<div *ngIf="!isCollapsed" class="container user-info contact-box pt-0 mt-1">
  <hr>
  <div class="row mt-2">
    <div class="col-md-6">
      <label>Call</label>
      <div class="text">
        {{work_phone || 'Not Available'}}
      </div>
    </div>
    <div class="col-md-6 pull-right">
      <label>Office</label>
      <div class="text">
        {{location || 'Not Available'}}
      </div>
    </div>
  </div>

  <div class="row mt-2">
    <div class="col-md-12">
      <label>Email</label>
      <div class="text">
        {{email_id || 'Not Available'}}
      </div>
    </div>
  </div>
</div>
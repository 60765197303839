<div class="gfid-body">
  <div class="d-flex flex-row mt-3 pr-4">
    <form [formGroup]="reportForm" (ngSubmit)="onRequestReport(reportForm)">
      <p-tabView class="myViewTab" #adminTabs (onChange)="onTabSelected($event)" [styleClass]="'admin-tabview'"
        [scrollable]="false" [style]="{'height':'650px','width':'70em','margin-left': '27px',overflow: 'hidden'}">
        <p-tabPanel header="REPORT CRITERIA">
          <div class="d-flex flex-row flex-wrap align-items-center justify-content-between my-4 pr-4">
            <div class="d-flex flex-row flex-nowrap align-items-center justify-content-start">
              <div class="myview-view-label pr-2">Report Type:</div>
              <p-dropdown class="drop-down" [options]="reportTypeList" [styleClass]="'prime-dd-override prime-dropdown-snooze'"
                optionLabel="key" [style]="{'width': '220px'}" formControlName="reportType">
              </p-dropdown>
            </div>
            <div class="d-flex flex-row flex-nowrap align-items-start" style="margin-right: 120px;">
              <div class="myview-view-label pr-2">
                Date Range:
              </div>
              <div class="d-flex flex-row flex-nowrap justify-content-between align-items-between">
                <p-calendar class="" dateFormat="mm/dd/yy" formControlName="startDate" [hideOnDateTimeSelect]="true"
                  [styleClass]="'report-range-cal advance-calender schedule-cal tab-calender-fix'" [readonlyInput]="false"
                  [showIcon]="true" [baseZIndex]="10000" [minDate]="minDate" [maxDate]="maxDate" selectionMode="range"
                  [style]="{'width': '200px'}">
                </p-calendar>
              </div>
            </div>
          </div>
          <div class="d-flex flex-column mt-3">
            <div class="mb-1 myview-view-label">
              Select Groups
            </div>
            <div class="d-flex flex-column flex-nowrap myviews-elem">
              <div>
                <app-checkbox-list [placeholder]="'Quick Search...'" [name]="'groups'" [items]="assignedGroups"
                  [listStyle]="{'height':'310px'}" (onItemSelect)="getAssignedGroups($event)" [resetBehaviour]="true"></app-checkbox-list>
              </div>
            </div>
          </div>
          <div class="invalid-feedback" *ngIf="errorMessage">
            <span>{{errorMessage}}</span>
          </div>
          <div class="success-feedback" *ngIf="successMessage">
              <span>{{successMessage}}</span>
            </div>
        </p-tabPanel>

        <p-tabPanel header="REQUEST HISTORY">
          <div class="d-flex flex-row flex-nowrap align-items-end w-100 pt-3">
            <div class="w-100 pr-4 reports-history-container">
              <div class="grid-container header-grid">
                <div>FILE</div>
                <div>REQUEST CREATED DATE</div>
                <div>DETAILS</div>
              </div>
              <div class="reports-content-container">
                <div class="grid-container grid-row" *ngFor="let report of requestReports; let i = index">
                  <div class="w-100">
                    <span class="download-btn" (click)="onDownload(report)">
                      <img src="assets/Inbox/download_icon_u70.svg" *ngIf="report.processFlag" alt="Download">
                    </span>
                    <span>{{report.reportType}}</span>
                  </div>
                  <div>{{report.crtDate | date: 'dd/MM/yyyy hh:mm:ss a'}}</div>
                  <div>
                    <div [pTooltip]="createTooltip(report)" class="rep-details" [escape]="false" tooltipPosition="left"
                      tooltipEvent="hover" tooltipStyleClass="reports-tooltip">Report Details</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </p-tabPanel>

        <p-footer style="width: 69.7%;top:40em;margin-left: -5px;">
          <div class="d-flex flex-row footer-btn-container"></div>
          <div class="d-flex flex-row flex-wrap bottom-button-holder px-3 pb-2 pt-3 justify-content-end">
            <button type="submit" class="btn save-btn mr-4">Request Report</button>
            <button type="button" class="btn clear-btn" (click)="clearAndClose(reportForm)">Cancel</button>
          </div>
        </p-footer>
      </p-tabView>
    </form>
  </div>
</div>
import { Component,OnInit, Output, EventEmitter, } from '@angular/core';
import { MessageService } from 'primeng/api';
import { InboxService } from 'src/app/services/inbox.service';
import { UserDataService } from 'src/app/services/user-data.service';

@Component({
  selector: 'app-cid-view',
  templateUrl: './cid-view.component.html',
  styleUrls: ['./cid-view.component.scss']
})
export class CidViewComponent implements OnInit {
  feedbackPlaceholder = "Let us know why?";
  suggestionsArray : any;
  cidFeedback: any;
  intentCollection = [];
  intentOptions;
  inquiryId: number;
  convId: number;
  groupId: number;
  txtUserComments :"";
  showOtherIntentBox: boolean [] = []
  otherIntentValue : '';
  @Output() closeCustomerIntentModal = new EventEmitter();
  constructor(private inboxService: InboxService, private userDataService: UserDataService, private messageService: MessageService) { 
    let intentCollectionData = this.userDataService && this.userDataService.loggedInUserInfo && this.userDataService.loggedInUserInfo.intentList;
    if (intentCollectionData && intentCollectionData.length > 0 ) {
      intentCollectionData.forEach(intentData => {
        this.intentCollection.push ({
          label: intentData, value: intentData 
        })
      });
    }
  }

  ngOnInit(): void {
  }

  showClientIntentFeedback(data,feedbackType?) {
    try {
      this.inboxService.fetchConvSuggestions(data).subscribe(response => {
      this.cidFeedback = response.intents;
      this.inquiryId = data.inquiryId;
      this.convId = data.convId;
      this.groupId = data.groupId;
      if(feedbackType) {
        this.saveClientIntentFeedback(feedbackType);
      }
      },(error) => {
          console.log('Service Failure while fetching intent suggestions', error);
        }
      );
    } catch (e) {
      console.error("Exception in QMA 2 while fetching intent suggestions : ", e);
    }
  }


  saveClientIntentFeedback(adequteResponse) {
    let correctIntentsArray = [];
    const correctIntents = {} 
    if (!adequteResponse) {
      if (this.cidFeedback && this.cidFeedback.length > 0 ) {
        this.cidFeedback.forEach(intentData => {
          if(intentData.intentOptions != 'Others') {
            correctIntentsArray.push({
              "sentence": intentData.sentence,
              "intent" : intentData.intentOptions
            })
          } else {
            correctIntentsArray.push ({
              "sentence": intentData.sentence,
              "intent": intentData.otherIntentValue
            })
          }
        });
      }
      correctIntentsArray.forEach((element) => {
        correctIntents[element.sentence] = element.intent
      })
    }
    const postData = {
      inquiryId: this.inquiryId,
      convId: this.convId,
      groupId: this.groupId,
      description:this.txtUserComments ? this.txtUserComments : '',
      intentUserFeedback:adequteResponse,
      "correctIntents": {
        "correctIntents": correctIntents
      }
    };
    this.inboxService.submitIntentSuggestionFeedback(postData).subscribe(response => {
      let displayMessage= response.message;
      if (response.success) {
        this.messageService.add({ severity: 'success', key: 'qmaToast', summary: '', detail: displayMessage });   
      } else {
        this.messageService.add({ severity: 'error', key: 'qmaToast', summary: '', detail: displayMessage });
      }
      },error => {
        let displayMessage = 'Error occured while saving feedback'
        this.messageService.add({ severity: 'error', key: 'qmaToast', summary: '', detail: displayMessage });
      }
    );
    this.closeCustomerIntentModal.emit(false);
  }
  cancelClientIntentFeedback() {
    this.resetClientIntentFeedbackData()
    this.closeCustomerIntentModal.emit(false);
  }

  onIntentValueChange(idx,event) {
    if (event.value == 'Others') {
      this.showOtherIntentBox[idx] = true;
    } else {
      this.showOtherIntentBox[idx] = false;
    }
  }

  resetClientIntentFeedbackData () {
    this.txtUserComments = '';
    if (this.cidFeedback && this.cidFeedback.length > 0) {
      this.cidFeedback.forEach((intentData,idx)=> {
        this.showOtherIntentBox[idx] = false
      });
    }
  }
}

import { InboxViewHelper } from './inboxViewHelper';
/**
 * Inbox view filter utilities
 */
export class InboxViewFilters {

  getAgeFilter(chartDataToFilterGridbox) {
    if (!chartDataToFilterGridbox || chartDataToFilterGridbox.subtype !== 'age' || !chartDataToFilterGridbox.subvalue) {
        return;
    }

    let ageType = chartDataToFilterGridbox.subvalue;
    let startAge;
    let endAge;

    //Check for Age Band and set up Range Values
    if (ageType.indexOf('0-1 day') !== -1) {
        startAge = 0;
        endAge = 1
    }
    else if (ageType.indexOf('2 days') !== -1) {
        startAge = 2;
        endAge = 2
    }
    else if (ageType.indexOf('3-5 days') !== -1) {
        startAge = 3;
        endAge = 5;
    }
    else if (ageType.indexOf('6-10 days') !== -1) {
        startAge = 6;
        endAge = 10;
    }
    else if (ageType.indexOf('11-30 days') !== -1) {
        startAge = 11;
        endAge = 30;
    }
    else if (ageType.indexOf('30+ days') !== -1) {
        startAge = 31;
    }
    return { startAge: startAge, endAge: endAge };
  }

  public filterGridViewData(inquiryList, requestObj, requestFilter, gridViewDataModel, assignedGroups, inboxService,
       currentUserId) {
    if (!inquiryList && !inquiryList.length) {
        return inquiryList;
    }
    if (requestObj && requestObj.isRequestFromMailBoxStats && gridViewDataModel && gridViewDataModel.ageData) {
        // there is 'ageData' for mailbox stats inline view, notify the chart
        inboxService.triggerAgeData(gridViewDataModel.ageData);
    }
    if (requestFilter) {
        const filter = requestFilter;
        switch (filter.type) {
            case "assignedUserName":
            let subfilter = this.getAgeFilter(filter);
            inquiryList = inquiryList.filter((lst) => {
                if (lst && lst.age !== undefined) {
                    const age = lst.age;
                    if (subfilter.startAge !== undefined && age < subfilter.startAge) {
                        return false;
                    } else if (subfilter.endAge !== undefined && age > subfilter.endAge) {
                        return false;
                    }
                }
                if (lst.workflows && lst.workflows.length > 0) {
                    let workflows = lst.workflows.filter((wf) => {
                        if (filter && filter.type && filter.value) {
                            let v = wf[filter.type];
                            if (!v || (requestObj.isChartView !== 'Y' && v !== filter.value)
                                || (requestObj.isChartView === 'Y' && v.indexOf(filter.value) == -1)) {
                                    if(filter.type !== "assignedUserName"){
                                    return false;
                                    }
                            }
                        }
                        return true;
                    });
                    return workflows && workflows.length;
                } else {
                    // no workflow, return true only if when filter is invalid
                    return !((filter.type && filter.value) || (filter.subtype && filter.subtype));
                }
            });
            break;
            case "readBy":
            inquiryList = inquiryList.filter((lst) => {
                const hasUser = lst.readBy && currentUserId && lst.readBy.includes(currentUserId);
                return (filter.value && hasUser) || (!filter.value && !hasUser);
            });
            break;
            default:
            inquiryList = inquiryList.filter((lst) => {
                if (lst.workflows && lst.workflows.length > 0) {
                    const workflows = lst.workflows.filter((wf) => {
                        if (filter && filter.type && filter.value) {
                            let v = wf[filter.type];
                            if (!v || (requestObj.isChartView !== 'Y' && v !== filter.value)
                            || (requestObj.isChartView === 'Y' && v.indexOf(filter.value) === -1)) {
                                if(filter.type !== "requestType"){
                                    return false;
                                }
                            }
                        }
                        if (filter && filter.subtype && filter.subvalue) {
                            let v = wf[filter.subtype];
                            if (!v || v !== filter.subvalue) {
                                return false;
                            }
                        }
                        return true;
                    });
                    return workflows && workflows.length;
                } else {
                    // no workflow, return true only if when filter is invalid
                    return !((filter.type && filter.value) || (filter.subtype && filter.subtype));
                }
            });
            break;
        }
        console.log("Pre-filter length=" + gridViewDataModel.inquiryDataList.length + ", post filter=" + inquiryList.length);
    } else if (requestObj.viewName === 'Search' && assignedGroups && assignedGroups.length) {
        // C153176-4885, 4994: skip filtering against current users' assigned groups
    }
    return inquiryList;
  }

  /**
   *  C153176-4885, 4994: refactored method: filtering inquiries against specified 'assignedGroups'
   */
  filterOnAssignedGroups(inquiryList, assignedGroups) {
    let filteredInquiryList = inquiryList.filter((inquiry) => {
        return this.filterAssignedGroups(inquiry, assignedGroups); // C153176-5150 call refactored function
    });
    console.log("Adv Search: pre-filter length=" +
      (inquiryList ? inquiryList.length : 0) + ", post filter=" + filteredInquiryList.length);
    return filteredInquiryList;
  }

  /**
   * C153176-5150: filter aginst specified assigned groups
   */
  filterAssignedGroups(inquiry, assignedGroups) {
    if (!inquiry || !assignedGroups || !assignedGroups.length) {
        // return true on empty object
        return true;
    }
    // check 'openGroups' first
    if (inquiry.openGroups) {
      for (const grp of assignedGroups) {
        if (grp && inquiry.openGroups.indexOf(grp.name) !== -1) {
            return true;
        }
      }
    }
    // if 'openGroups' doesn't match, check 'workflow.assignedGroupName'.
    if (inquiry.workflows && inquiry.workflows.length > 0) {
        const workflows = inquiry.workflows.filter((wf) => {
            let isInGroup = false;
            if (wf.assignedGroupName) {
                for (const grp of assignedGroups) {
                    if (grp && wf.assignedGroupName.indexOf(grp.name) !== -1) {
                        isInGroup = true;
                        break;
                    }
                }
                if (!isInGroup) {
                    return false;
                }
            }
            return true;
        });
        return workflows && workflows.length;
    } else {
        return true;
    }
  }
}
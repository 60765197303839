export const serviceUrl = {
    loadgridViewData: 'api/search/loadGridData',
    loadWorkflowData: 'api/search/loadWorkflowData',
    replayMessages: 'api/search/ReplayMessages',
    loadReportData: 'api/search/loadReportData',
    loadMailboxLag: 'api/metrics/mailbox/lag',
    loadKafkaSpoutLag: 'api/metrics/kafka/lag',
    loadAvgData: 'api/metrics/report/time/average',
    loadBucketData: 'api/metrics/report/time/buckets',
    loadMailBoxLevelData: 'api/search/loadMailBoxLevelData',
    getAllGroups: 'group/getAllGroups',
    getManagementHeirarchy:'group/getManagementHeirarchy',
    getGroupData:'group/getGroupData',
    getGroupDataById:'group/getGroupDataById',
    getUserData:'users/getUserData',
    saveGroupAdminInfo:'group/saveGroupAdminInfo',
    getUserDataById: 'users/getUserData',
    saveUserAdminData: 'users/saveUserAdminData',
    saveManagementHierarchy:'group/saveManagementHeirarchy',
    getAdminDbCollections: 'generic/getAdminDbCollections',
    getDBDocumentForID: 'generic/getDBDocumentForID',
    createBackupCollection: 'generic/createBackupCollection ',
    updateDBCollectionDocument: 'generic/updateDBCollectionDocument',
    reloadDbCollectionUI: 'generic/reloadDbCollectionUI',
    getTemplateFile: 'generic/getTemplateFile',
    processGrpBulkUpload: 'generic/processGrpBulkUpload',
    processGfpcBulkUpload:'generic/processGfpcBulkUpload',
    processRtmBulkUpload: 'generic/processRtmBulkUpload',
    getMISExclusionReportRecords:'generic/getMISExclusionReportRecords',
    deactivateExclusionReportData:'generic/deactivateExclusionReportData',
    saveExclusionReportData:'generic/saveExclusionReportData',
    saveBulkGroupResolve:'generic/saveBulkGroupResolve',
    getOrgGroup:'organization/getOrgGroup',
    getOrgData:'organization/getOrgData',
    saveOrgData:'organization/saveOrgData',
    getRequests:'organization/getRequests',
    saveOrgUnApprovedRequest:'organization/saveOrgUnApprovedRequest',
    getRequestById:'organization/getRequestById',
    deleteOrgAdminData:'users/deleteOrgAdminData', 
    saveOrgAdminData:'users/saveOrgAdminData',
    getAlreadyRaisedRequest:'organization/getAlreadyRaisedRequest',
    disableOrgMetaData:'organization/disableOrgMetaData',
    uploadReleaseNote:'generic/uploadReleaseNotes'
    
  };

<div id="inquiries-by-trend-box" style="width: 100%;min-height: 325px; height: 100%; overflow: hidden;">
    <div id="inquiries-by-trend"  [hidden]="drilldownOn" class="dashboardChart"></div>
    <div id="inquiries-by-trend-drilldown" [hidden]="!drilldownOn" class="dashboardChart"></div>
    <fading-dialog [chartId]= "'inquiriesByTrendDialog'" #inquiriesByTrendDialog></fading-dialog>
    <div class="d-flex flex-row align-items-center justify-content-end inquiry-type" *ngIf="!drilldownOn &&  isNewResponse">
        <div class="mr-2">Inquiry type:</div>
        <div>
            <p-dropdown [options]="availOptions" [(ngModel)]="selectedOption" (onChange)="onOptSelection($event)" [styleClass]="'prime-dd-override prime-dropdown-inquirytrend'"  placeholder="Select..." [(ngModel)]="selectedOption">
            </p-dropdown> 
        </div>
    </div>
</div>

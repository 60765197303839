<p-dialog [(visible)]="display" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'"
  [styleClass]="'contextMenuModal notifyModal'" [closable]="false" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true"
  [style]="{'max-width':'700px','width':'100%', 'top': '27px'}" [resizable]="false">

  <p-header>
    <div class="d-flex flex-row align-items-center justify-content-between mx-2">
      <h6 class="notify-title">NOTIFY</h6>
      <button type="button" (click)="hideModal()" class="d-flex flex-row close qma-text">
        <img src="assets/Inbox/close_icon_u73.svg" alt="Close">
        <h6>CLOSE</h6>
      </button>
    </div>
  </p-header>
  <div class="notify-body mx-2">        
      <div class="notify-holder">
          <div>
              <div class="custom-control custom-checkbox">
                <input type="checkbox" (change)="addLoginUser($event)" [checked]="notifyMe" class="custom-control-input" id="notifyCheckbox" name="notifyCheck">
                <label class="custom-control-label" for="notifyCheckbox">Also notify me when inquiry changes</label>
              </div>
            </div>       
        <div class="d-flex flex-row py-3" style="justify-content:start;">
          <div class="d-flex flex-column assign-width" style="margin-right: 98px">
            <div class="mb-2">
              <ins class="heading-style">Assigned group</ins>
            </div>
            <div class="d-flex flex-column flex-nowrap advance-search-elem">
              <div class="Owner-checkbox mobileView-ownership">
                <app-checkbox-list #groupname [placeholder]="'Quick Search...'" [name]="'value'" [items]="groups"  [listStyle]="{'height':'178px'}" 
                styleClass="notifyClass" checked="groupNames.binary" (onItemChecked)="getSelectedGroup($event)"></app-checkbox-list>
              </div>
            </div>
          </div>                  
          <div class="d-flex flex-column owner-width">              
            <div class="mb-2 mobile-mt">
              <ins class="heading-style">Owner</ins>
            </div>
            <div class="Owner-checkbox mobileView-ownership">
              <app-checkbox-list #owner [searchIcon]="true" [placeholder]="'Quick Search...'" [name]="'ownership'" [items]="assignedUsersList"
              [listStyle]="{'height':'178px'}"  styleClass="notifyClass" (onItemChecked)="getSelectedItems($event)"></app-checkbox-list>            
            </div>
          </div>
        </div>
        <div *ngIf="isOwnerSelected" style="font-size:14px; margin-left: 27.2em; font-family:Interstate; font-weight: normal; color:#ff0000">Please select atleast one owner.</div> 
        <div class="mt10 mobile-mt">Reason for notification:*</div>
        <div *ngIf="isReason" style="font-size:14px; font-family:Interstate; font-weight: normal; color:#ff0000">Reason for Notification is mandatory.</div>       
        <div class="d-flex flex-row align-items-center mb-4">
          <div class="label-text" for="reason"></div>
          <div class="d-flex flex-row align-items-center input-holder dd-comp">
            <div class="d-flex flex-row w-100 align-items-center">
              <textarea rows="4" cols="50" name="reason" [value]="reason" [(ngModel)]="reason"></textarea>
            </div>
          </div>
        </div>
        
      </div>
  </div>
  <p-footer>
    <div class="d-flex flex-row flex-wrap bottom-button-holder px-3 pb-2 pt-3 justify-content-end footer-width">
      <button type="submit" class="btn search-btn mr-4 mobile-btn" (click)="setNotification()">Apply</button>
      <button type="reset" class="btn clear-btn mobile-btn" (click)="resetFields()">Reset</button>
    </div>
  </p-footer>
</p-dialog>
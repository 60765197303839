import { AppUtils } from '../../common/utility/appUtil';
import { QmaConstant } from '../../constant/qma-constant';
/**
 * Help utils ported from QMA 1.0
 */
export class InboxViewHelper {

  static replaceableKeys = [ 
      'workflows.crtDate', 
      'workflows.modDate', 
      'workflows.lastActionTime', 
      'workflows.resolveTime', 
      'workflows.reAgeDate', 
      'workflows.ackEscalationTime'
    ];

  static updateCriteria(criteria, sourceCriteria) {
      let c = criteria;
      for (let t of InboxViewHelper.replaceableKeys) {
          let v = InboxViewHelper.findJSONValue(sourceCriteria, t);
          if (v) {
            InboxViewHelper.updateJSONValue(criteria, t, v);
          }
      }
      return c;
  }

  static findJSONValue(obj, target) {
      if (obj && !(typeof obj === 'string' || obj instanceof String || (typeof obj === 'number' && isFinite(obj)))) {
        const keys = Object.keys(obj);
        if (keys && keys.length) {
            for(let key of keys) {
                const value = obj[key];
                if (key && key === target && value) {
                    return value;
                } else {
                    let result= InboxViewHelper.findJSONValue(value, target);
                    if (result) {
                        return result;
                    }
                }
            }
        }
      }
      return undefined;
  }

  /**
   * Update JSON value with specified key/value pair
   */
  static updateJSONValue(obj, t_key, t_value) {
    let result = false;
    if (obj && !(typeof obj === 'string' || obj instanceof String || (typeof obj === 'number' && isFinite(obj)))) {
        const keys = Object.keys(obj); 
        if (keys && keys.length) {
            let tdb_keys = [];
            for(let key of keys) {
                if (key && key === t_key) {
                    if (!key.startsWith('workflows.')) {
                        obj[key] = t_value;
                    } else {
                        tdb_keys.push(key);
                        const n_key = key.substring('workflows.'.length);
                        obj[n_key] = t_value;
                    }
                    result = true;
                    break;
                } else {
                    if (key.startsWith('workflows.')) {
                        tdb_keys.push(key);
                        const n_key = key.substring('workflows.'.length);
                        obj[n_key] = obj[key];
                        if(InboxViewHelper.updateJSONValue(obj[n_key], t_key, t_value)) {
                            result = true;
                        }
                    } else {
                        if(InboxViewHelper.updateJSONValue(obj[key], t_key, t_value)) {
                            result = true;
                        }
                    }
                    if (result) {
                        break;
                    }
                }
            }
            if (tdb_keys.length) {
                for (let tdb_key of tdb_keys) {
                    delete obj[tdb_key];
                }
            }
        }
      }
      return result;
  }

  static updateFields(src, target) {
    target.crtDate = src.crtDate;
    target.modDate = src.modDate;
    target.reOpenDate = src.reOpenDate;
    target.lastActionTime = src.lastActionTime;
    target.resolveTime = src.resolveTime;
    target.reAgeDate = src.reAgeDate;
    target.ackEscalationTime = src.ackEscalationTime;
  }

  static extendFields(elem, cachedElem) {
    if (!elem) {
        return;
    }
    InboxViewHelper.updateFields(elem, cachedElem);
    if (!AppUtils.isUndefinedOrNull(elem.crtDate.$date))
    {
        elem.crtDate = new Date(elem.crtDate.$date);
        elem.modDate = new Date(elem.modDate.$date);
    }
    if (!AppUtils.isUndefinedOrNull(elem.responseTimeQMA))
    {
        //elem.avgResponseTimeQMA = elem.responseTimeQMA/elem.replyCountQMA;
        //elem.avgResponseTimeQMA = elem.avgResponseTimeQMA.toFixed(2);
    }
    else
    {
        elem.crtDate = new Date(elem.crtDate);
        elem.modDate = new Date(elem.modDate);
    }
    if (!AppUtils.isUndefinedOrNull(elem.reOpenDate)){
        let reOpenDate =  new Date(elem.reOpenDate.$date);
        if(AppUtils.isUndefinedOrNull(reOpenDate)){
            reOpenDate = elem.reOpenDate;
        }
        elem.reOpenDate = new Date(reOpenDate);
    }
    if(!AppUtils.isUndefinedOrNull(elem.lastActionTime))
    {
        elem.lastActionTime = new Date(elem.lastActionTime.$date)
    }
    if(!AppUtils.isUndefinedOrNull(elem.resolveTime))
    {
        elem.resolveTime = new Date(elem.resolveTime.$date)
    }
    if(!AppUtils.isUndefinedOrNull(elem.reAgeDate))
    {
        elem.reAgeDate = new Date(elem.reAgeDate.$date)
    }
    if(!AppUtils.isUndefinedOrNull(elem.ackEscalationTime))
    {
        elem.ackEscalationTime = new Date(elem.ackEscalationTime.$date)
    }
    return elem;
  }

  /**
   * C153176-5081: filter based on UI criteria returned from /getGridViewData
   */
  static filterByViewCriteria(viewType, inq, criteria, defaultCriteria) {
    const cachedInq = {};
    const inquiry = InboxViewHelper.extendFields(inq, cachedInq);
	let expr = [ "" ];
	let op = [];
	InboxViewHelper.convertToBooleanExpression(criteria, op, expr, inquiry, viewType);
    const exprResult = eval(expr[0]);
    InboxViewHelper.updateFields(cachedInq, inq);
	return exprResult;
  }

  static filterByMyViewCriteria(viewType, inq, criteria, defaultCriteria, index = -1) {
    const cachedInq = {};
    const inquiry = InboxViewHelper.extendFields(inq, cachedInq);
    let jsonCriteria = JSON.parse(criteria);
    jsonCriteria = InboxViewHelper.updateCriteria(jsonCriteria, defaultCriteria);
    criteria = JSON.stringify(defaultCriteria); //jsonCriteria);
	let expr = [ "" ];
	let op = [];
	InboxViewHelper.convertToBooleanExpression(JSON.parse(criteria), op, expr, inquiry, viewType);
    const exprResult = eval(expr[0]);
    InboxViewHelper.updateFields(cachedInq, inq);
	return exprResult;
  }

  static convertToBooleanExpression(criteria, op, expr, inq,viewType) {
	for (var key in criteria) {
        const value = criteria[key];

        if (value instanceof Array) { 
            if (key == '$and') op.push('&&');
            else if (key == '$or') op.push('||');

            expr[0] = expr[0] + ' ' + ' (';
            InboxViewHelper.tokenizeArrayExpr(value, op, expr, inq,viewType); // Tokenize Array expression
            expr[0] = expr[0] + ' ' + ')';
            if (op.length > 0) {
                const operator = op[op.length - 1];
                op.pop(key);// remove the group operator on encounter of close bracket
                expr[0] = expr[0].replace(operator + ' ' + ')', ')'); // remove extra operator added while adding last operand
            }
        } else {
            ///[C153176-160] changes for saved search with work flow Criteria query
            expr[0] = expr[0] + ' ' + InboxViewHelper.evalIndividualExpr(key, value, inq,op[op.length - 1],viewType) + ' ' + op[op.length - 1];
        }
	}
	//console.log("InboxViewHelper:: convertToBooleanExpression "+expr[0]);
  }

  static tokenizeArrayExpr(objects, op, expr, inq, viewType) {
	Object.keys(objects).forEach(key => {
		var value = objects[key];
		if (value instanceof Array)
		{

			if (key == '$and') op.push('&&');
			else if (key == '$or') op.push('||');

			expr[0] = expr[0] + ' ' + ' (';
			InboxViewHelper.tokenizeArrayExpr(value, op, expr, inq, viewType);
			expr[0] = expr[0] + ' ' + ')';
			if (op.length > 0)
			{
				var operator = op[op.length - 1];
				op.pop(key);// remove the group operator on encounter of close bracket
				expr[0] = expr[0].replace(operator + ' ' + ')', ')'); // remove extra operator added while adding last operand
			}
		}
		else if (value instanceof Object)
		{
			InboxViewHelper.convertToBooleanExpression(value, op, expr, inq, viewType);
		}
	});
  }

  static evalIndividualExpr(key, value, inq,userOperator,viewType
    )///[C153176-160] changes for saved search with work flow Criteria query
    {

    let inqArray = [ inq ];
    if (value instanceof Object)  // like regex, date etc, not simple comparison (i.e not just key, value
    { 
        var key1 = Object.keys(value)[0];
        var value1 = value[key1];
        var stringValue=JSON.stringify(value1);
        if(AppUtils.isUndefinedOrNull(stringValue)) {
            stringValue=" ";
        }
        //console.log("key: " + key + ", value: " + value +", key1: " + key1 + ", value1: " + value1 +" , userOperator: "+ userOperator + ", viewType: " + viewType);
        //console.log(key1+' ----> '+value1);
        if (key1 === '$elemMatch')  // SPECIFIC EMBEDDED OBJECT FIELDS
        {
                // inner level filter
                inqArray = inqArray.filter(function(inq)
                {
                    var wf = inq.workflows.filter(function(workflow)
                    {
                    //Required to show updates in Tags tab..
                    //Code to evaluate workflow tags in the incoming inquiry.
                    //If tags are present, basicCriteria is built based on them. Else, it follows the already present logic.
                        var hasValidBasicCriteria = false;
                        if(!AppUtils.isUndefinedOrNull(value1.tag) && stringValue.indexOf("-1000100")==-1)
                        {
                            hasValidBasicCriteria =  workflow.tag == value1.tag  
                                                        && value1.assignedGroupId === workflow.assignedGroupId;
                        }
                        else if(stringValue.indexOf("-1000100")==-1)
                        {
                            //C153176-368-Resolved Inquiry Cannot be found with OUT version
                            if(!AppUtils.isUndefinedOrNull(viewType) && viewType == parseInt(QmaConstant.inboxGridViewType.Outbox.viewType))
                            {
                                //hasValidBasicCriteria=workflow.direction == value1.direction 
                                hasValidBasicCriteria= InboxViewHelper.checkDirectionCriteria(workflow,value1)
                                                && value1.assignedGroupId.$in.includes(workflow.assignedGroupId);
                            }
                            //This will be used in future when we will enable escalation for all the box types right now only enabling it for Inbox.
    //							else if(!AppUtils.isUndefinedOrNull(viewType) && viewType == gVars.typeEnum.ESCALATION.TYPE){
    //								hasValidBasicCriteria =  workflow.status == value1.status  								
    //								&& _.contains(value1.assignedGroupId.$in, workflow.assignedGroupId);
    //								if(hasValidBasicCriteria && value1 && value1.$or){
    //									hasValidBasicCriteria = InboxViewHelper.checkEscalationCriteria(hasValidBasicCriteria, value1, value1.$or,workflow);
    //								}
    //							}
                            else
                            {
                                hasValidBasicCriteria =  workflow.status == value1.status  
                                                        //&& workflow.direction == value1.direction 
                                                        &&	InboxViewHelper.checkDirectionCriteria(workflow,value1)
                                                        && value1.assignedGroupId.$in.includes(workflow.assignedGroupId);
                                if(hasValidBasicCriteria && value1 && value1.$or){
                                    hasValidBasicCriteria = InboxViewHelper.checkEscalationCriteria(value1.$or,workflow);
                                }
                                if(hasValidBasicCriteria && value1 && value1.followUp){
                                    hasValidBasicCriteria = InboxViewHelper.checkFollowUpCriteria(workflow);
                                }
                            }
                        }

                        //Rules only apply for Inbox Types of View. It doent apply on Resolvedbox , Outbox and Peding Approval Box. 
                        if(workflow.status == 'Open' && workflow.direction == 'IN' && AppUtils.isUndefinedOrNull(value1.tag) && stringValue.indexOf("-1000100")==-1 )
                        {
                            //the changes to incorporate rules requirement
                            var hasValidRulesCriteria = false;
                            
                            if(value1.rulesFlag) //Case - Views : Inbox Types
                            {
                                if(value1.rulesFlag.$exists == false)
                                {
                                    hasValidRulesCriteria = AppUtils.isUndefinedOrNull(workflow.rulesFlag);
                                }
                            }
                            else if( value1["rulesFlag.markAsNonInquiry"] && value1["rulesFlag.markAsNonInquiry"] == true) //Case - Viwe : Non Inquiry Inbox
                            {
                                if(workflow.rulesFlag && workflow.rulesFlag.markAsNonInquiry)
                                {
                                    hasValidRulesCriteria = workflow.rulesFlag.markAsNonInquiry;
                                }
                            }
                            else if(value1["rulesFlag.markForDeletion"] && value1["rulesFlag.markForDeletion"] == true ) //Case - Viwe : Delete Inquiry Inbox
                            {
                                if(workflow.rulesFlag && workflow.rulesFlag.markForDeletion)
                                {
                                    hasValidRulesCriteria = workflow.rulesFlag.markForDeletion;
                                }
                            }
                            //end - rules requirement

                            return hasValidBasicCriteria && hasValidRulesCriteria;//return combined evaluation
                        }else if(stringValue.indexOf("-1000100")>-1)
                        {
                            hasValidBasicCriteria= InboxViewHelper.checkUserViewctr(workflow,value1,userOperator,viewType)
                        }
                        
                        return hasValidBasicCriteria;
                    });
                    return wf.length > 0;
                });
        }
        // Any column filter of type Date needs to be added here inorder to be matched and shown in the UI
        //else if (key == 'crtDate' || key == 'modDate' || key == 'lastActionTime' || key == 'resolveTime' || key == 'reAgeDate' || key =='ackEscalationTime')  // DATES
        else if (InboxViewHelper.replaceableKeys.includes(key) || InboxViewHelper.replaceableKeys.includes( 'workflows.' + key))
        {
            inqArray = inqArray.filter(function(obj)
            {
                return InboxViewHelper.compareDates(value1, InboxViewHelper.getObjValues(obj, key), key1)
            });
            
            var length = Object.keys(value).length;

            if(length > 1) //BETWEEN OPERATOR
            {
                var key2 = Object.keys(value)[1];
                var value2 = value[key2];
                inqArray = inqArray.filter(function(obj)
                {
                    return InboxViewHelper.compareDates(value2, InboxViewHelper.getObjValues(obj, key), key2)
                });
            }
        }
        else if (key1 === '$regex' || key1 === '$regularExpression')  // STRINGS
        {
            inqArray = inqArray.filter(function(obj)
            {
                const v = InboxViewHelper.getAttrValue(obj, key);
                // C153176-5120 invoke evalValues to handle both single value and multi-value cases
                return InboxViewHelper.evalValues(v, (v) => {
                    //below line is commented as just matching is not case insensitive
                    //return obj[key].match(value1);
                    
                    //ignore case matching is handled by converting to lower case and matching 
                    var inqValue = v.toLowerCase();
                    var crtValue = InboxViewHelper.getValue(value1).toLowerCase();
                    return inqValue.match(crtValue);
                });
            });
        }
        else if (key1 === '$ne')  // STRINGS
        {
            inqArray = inqArray.filter(function(obj)
            {
                // C153176-5120 invoke evalValues to handle both single value and multi-value cases
                return  InboxViewHelper.evalValues(InboxViewHelper.getAttrValue(obj, key), (v) => v != value1);
            });
        }
        else if (key1 === '$nin')  // STRINGS
        {
            return !(value1.includes(inq.readyBy));
        }
        else if (key1 === '$exists')  // STRINGS
        {
            inqArray = inqArray.filter(function(obj)
            {
                var exists;
                
                if(key === 'workflows.assignedUserId')
                {
                    var userId = InboxViewHelper.getAttrValue(obj, key);
                    
                    // C153176-5120 invoke evalValues to handle both single value and multi-value cases
                    exists = InboxViewHelper.evalValues(userId, (v) => !AppUtils.isUndefinedOrNull(v));
                }
                else
                {
                    // C153176-5120 invoke evalValues to handle both single value and multi-value cases
                    exists = InboxViewHelper.evalValues(InboxViewHelper.getAttrValue(obj, key), (v) => !AppUtils.isUndefinedOrNull(v));
                }
                
                return exists === value1;
            });
        }
        /*else if (key == '_id' && key1 == '$in')  // SOLR Search
        {
            return value1.indexOf(inq._id) > -1;
        }*/
        else if (key === 'workflows.assignedGroupId' && key1 === '$in')  // Home SOLR Search 
        {
            inqArray = inqArray.filter(function(inq)
            {
                var wf = inq.workflows.filter(function(workflow)
                {
                    return value1.indexOf(workflow.assignedGroupId) > -1;
                });
                return wf.length > 0;
            });
        }		else  // INTEGERS
        {
            inqArray = inqArray.filter((obj) => {
                return InboxViewHelper.compareNumbers(value[key1], InboxViewHelper.getObjValues(obj, key), key1);
            });
        }
    }
    else
    { // just key , value

        if (key === 'userFolders.folderName')  // inner level filter
        { 
            inqArray = inqArray.filter(function(inq)
            {
                var wf = inq.userFolders.filter(function(userFolder)
                {
                    return userFolder.folderName == value;
                });
                return wf.length > 0;
            });
        }
        else if (key === 'workflows.assignedUserId')  // inner level filter
        { 
            inqArray = inqArray.filter(function(inq)
            {
                var wf = inq.workflows.filter(function(workflow)
                {
                    return workflow.assignedUserId == value;
                });
                return wf.length > 0;
            });
        }
        else if (key === 'urgentFlag')  // inner level filter
        { 
            return "Y" === value;
        }
        else if (key === 'attchFlag')  // inner level filter
        { 
            return "Y" === value;
        }
        else if (key === 'suggestionIndicator')  // inner level filter
        { 
            return "O" == value || "G" == value || "R" == value || "B" == value;
        }
        else
        {
            inqArray = inqArray.filter(function(obj)
            {
                // C153176-5120 invoke evalValues to handle both single value and multi-value cases
                return InboxViewHelper.evalValues(InboxViewHelper.getAttrValue(obj, key), (v) => v == value);
            });
        }
    }

    return inqArray.length > 0;
  }

  static checkEscalationCriteria(escalationOrCondition,workflow){
    const esc1 = escalationOrCondition[0];
    const esc2 = escalationOrCondition[1];
    const esc3 = escalationOrCondition[2];
    const esc4 = escalationOrCondition[3];
    const esc5 = escalationOrCondition[5];
    return (esc1.isConvCountEscalation === workflow.isConvCountEscalation 
            || esc2.isClientChaseEscalation === workflow.isClientChaseEscalation	|| esc3.isSubjectEscalation === workflow.isSubjectEscalation
            || esc4.responseTimeEscalationFlag === workflow.responseTimeEscalationFlag
            || esc5.isManualEscalation === workflow.isManualEscalation);
  }
  
  static checkFollowUpCriteria(workflow){
    let criteriaMatched = false;
    if(workflow.followUp && "Generic" === workflow.followUp.flag)
    {
        criteriaMatched = true;
    }
    return criteriaMatched;
  }
  /*
    * Match workflow direction 
    * If the view direction has array then use $in to match or match directly
    * 
    */
  static checkDirectionCriteria(workflow, value){
    let criteriaMatched = false;
    if(!value.direction)
    {
        criteriaMatched= true;
    }
    else if(workflow.direction && value.direction && value.direction.$in && value.direction.$in.length)
    {
        criteriaMatched= value.direction.$in.includes(workflow.direction)
    }
    else
    {
        criteriaMatched=workflow.direction === value.direction 
        
    }
    
    return criteriaMatched;
  }

  static parseDate(s) {
    var months = {jan:0,feb:1,mar:2,apr:3,may:4,jun:5,
                  jul:6,aug:7,sep:8,oct:9,nov:10,dec:11};
    // C153176-5309: myviews websocket handling: support embedded '$date'
    if ((typeof s === 'string') && (s.indexOf('/') > -1)) {
        var p = s.split('/');
        return new Date(parseInt(p[2]), months[p[1].toLowerCase()], parseInt(p[0]));
    } if (typeof s === 'object' && s.$date) {
        return new Date(s.$date);
    } else {
        return new Date(s);
    }
  }

  static compareDates(date1, date2, key1) {

    if (date2 === undefined || (date2 instanceof Array && !date2.length)) {
        // if the value is absent, return false
        return false;
    }
    if (date2 instanceof Array && date2.length) {
        // take the last date
        return InboxViewHelper.compareDates(date1, date2[date2.length -1], key1);
    }

    date1 = InboxViewHelper.parseDate(date1);

	date2 = new Date(date2); // date2 format - long number
    // C153176-4798: should compare in timestamps with '+', and not overide with yr/mon/dt only
	//date2 = new Date(date2.getUTCFullYear(), date2.getUTCMonth(), date2.getUTCDate());
	if (key1 == '$gt') return +date2 > +date1;
	else if (key1 == '$lt') return +date2 < +date1;
	else if (key1 == '$gte') return +date2 >= +date1;
	else if (key1 == '$lte') return +date2 <= +date1;
	else if (key1 == '$eq') return +date2 == +date1;
    else if (key1 == '$ne') return +date2 != +date1;
    return true;
  }

  /**
   * compare numbers with one or many number targets (in array)
   */
  static compareNumbers(num1, num2, key1) {

    if (num2 === undefined || (num2 instanceof Array && !num2.length)) {
        // if the value is absent, return false
        return false;
    }
    if (num2 instanceof Array && num2.length == 1) {
        return InboxViewHelper.compareNumbers(num1, num2[0], key1);
    }

	if (key1 == '$gt') {
        return InboxViewHelper.andCriteria((num1, num2) => num2 > num1, num1, num2);
    } else if (key1 == '$gte') {
        return InboxViewHelper.andCriteria((num1, num2) => num2 >= num1, num1, num2);
    } else if (key1 == '$lt') {
        return InboxViewHelper.andCriteria((num1, num2) => num2 < num1, num1, num2);
    } else if (key1 == '$lte') {
        return InboxViewHelper.andCriteria((num1, num2) => num2 <= num1, num1, num2);
    } else if (key1 == '$eq') {
        return InboxViewHelper.andCriteria((num1, num2) => num2 === num1, num1, num2);
    } else if (key1 == '$ne') {
        return InboxViewHelper.orCriteria((num1, num2) => num2 !== num1, num1, num2);
    }
    return false;
  }

  static andCriteria(f, ref, target) {
    if (target instanceof Array) {
        let result = true;
        target.forEach((num2) => {
          result = result && f.call(ref, num2);
        });
        return result;
    } else {
      return f(ref, target);
    }
  }

  static orCriteria(f, ref, target) {
    if (target instanceof Array) {
        let result = false;
        target.forEach((num2) => {
          result = result || f.call(ref, num2);
        });
        return result;
    } else {
        return f(ref, target);
    }
  }

  static compareUserViewDate(workflowDtVal,ctrDtVal,booleanArray) {
      if (!AppUtils.isUndefinedOrNull(workflowDtVal) && !AppUtils.isUndefinedOrNull(ctrDtVal))
      {
          if (!AppUtils.isUndefinedOrNull(ctrDtVal.$lt))
          {
              booleanArray.push(workflowDtVal.$date < ctrDtVal.$lt.$date);
          }
          if (!AppUtils.isUndefinedOrNull(ctrDtVal.$gt))
          {
              booleanArray.push(workflowDtVal.$date > ctrDtVal.$gt.$date);
          }
  
          if (!AppUtils.isUndefinedOrNull(ctrDtVal.$lte))
          {
              booleanArray.push(workflowDtVal.$date <= ctrDtVal.$lte.$date);
          }
          if (!AppUtils.isUndefinedOrNull(ctrDtVal.$gte))
          {
              booleanArray.push(workflowDtVal.$date >= ctrDtVal.$gte.$date);
          }
      }
  }

    //ctrValue is criteria value
    static compareUserViewageCalculation(workflow,ctrValue,booleanArray,viewType)
    {
        var modDate=new Date();
        var crtDate = new Date(workflow.crtDate.$date);
        if (parseInt(QmaConstant.inboxGridViewType.Resolved.viewType) == viewType && workflow.status == 'Resolved')
        {
        modDate = new Date(workflow.modDate.$date);
        }
        if(!AppUtils.isUndefinedOrNull(ctrValue.age$gt))
        {
        booleanArray.push(AppUtils.getInquiryAge(crtDate, modDate)>ctrValue.age$gt.$nin[1]);
        }
        if(!AppUtils.isUndefinedOrNull(ctrValue.age$lt))
        {
        booleanArray.push(AppUtils.getInquiryAge(crtDate, modDate)<ctrValue.age$lt.$nin[1]); 
        }
        if(!AppUtils.isUndefinedOrNull(ctrValue.age$eq))
        {
        booleanArray.push(AppUtils.getInquiryAge(crtDate, modDate)==ctrValue.age$eq.$nin[1]);
        }

    }
    static compareWorkflowIntegerField(workflowVal,ctrVal,booleanArray)
    {
        if(!AppUtils.isUndefinedOrNull(workflowVal))
        {
            if(!AppUtils.isUndefinedOrNull(ctrVal.$gt))
            {
                booleanArray.push(workflowVal>ctrVal.$gt);
            }
            if(!AppUtils.isUndefinedOrNull(ctrVal.$lt))
            {
            booleanArray.push(workflowVal<ctrVal.$lt); 
            }
            if(!AppUtils.isUndefinedOrNull(ctrVal.$eq))
            {
                booleanArray.push(workflowVal==ctrVal.$eq);
            }
        }
        else
        {
        booleanArray.push(false);
        }
        
    }
    static compareWorkflowStringField(workflowVal,ctrVal,booleanArray)
    {
        // not equal case
        if(!AppUtils.isUndefinedOrNull(ctrVal.$ne))
        {
            if(!AppUtils.isUndefinedOrNull(workflowVal))
            {
                var regx = new RegExp(ctrVal.$ne.$regex,ctrVal.$ne.$options);
                var match=workflowVal.match(regx);
                booleanArray.push(AppUtils.isUndefinedOrNull(match) || match.length==0);
            }
            else
            {
                booleanArray.push(true);
            }
        }
        else
        {
            if(!AppUtils.isUndefinedOrNull(workflowVal))
            {
                var regx = new RegExp(ctrVal.$regex,ctrVal.$options);
                var match=workflowVal.match(regx);
                booleanArray.push(!AppUtils.isUndefinedOrNull(match) && match.length>0);
            }
            else
            {
                booleanArray.push(false);
            }
        }
    }

    static checkUserViewctr(workflow,ctrValue,userOperator,viewType)
    {
        
        var isUserCtrSucess=false;
        var booleanArray=[];
        if(!AppUtils.isUndefinedOrNull(workflow) && !AppUtils.isUndefinedOrNull(ctrValue))
        {
            if(!AppUtils.isUndefinedOrNull(ctrValue.assignedUserName) )
            {///[C153176-436]Case Insensitive is not working for Begins with and Contains condition for Request Type & Tag
                InboxViewHelper.compareWorkflowStringField(workflow.assignedUserName, ctrValue.assignedUserName, booleanArray);
            }
            if(!AppUtils.isUndefinedOrNull(ctrValue.requestType))
            {
                InboxViewHelper.compareWorkflowStringField(workflow.requestType, ctrValue.requestType, booleanArray);
            }
            if(!AppUtils.isUndefinedOrNull(ctrValue.resolver))
            {
                InboxViewHelper.compareWorkflowStringField(workflow.resolver, ctrValue.resolver, booleanArray);
            }
            if(!AppUtils.isUndefinedOrNull(ctrValue.assignedGroupName))
            {
                InboxViewHelper.compareWorkflowStringField(workflow.assignedGroupName, ctrValue.assignedGroupName, booleanArray);
            }
            if(!AppUtils.isUndefinedOrNull(ctrValue.tag))
            {
                InboxViewHelper.compareWorkflowStringField(workflow.tag, ctrValue.tag, booleanArray);
            }
            if(!AppUtils.isUndefinedOrNull(ctrValue.linkId))
            {
                InboxViewHelper.compareWorkflowIntegerField(workflow.linkId, ctrValue.linkId,booleanArray )
                       
            }
             if(!AppUtils.isUndefinedOrNull(ctrValue.queryCount))
                {
                    InboxViewHelper.compareWorkflowIntegerField(workflow.queryCount, ctrValue.queryCount,booleanArray );
                           
                } 
             if(!AppUtils.isUndefinedOrNull(ctrValue.rootCause))
                {
                    InboxViewHelper.compareWorkflowStringField(workflow.rootCause, ctrValue.rootCause, booleanArray);
                }
             if(!AppUtils.isUndefinedOrNull(ctrValue.processingRegion))
                {
                    InboxViewHelper.compareWorkflowStringField(workflow.processingRegion, ctrValue.processingRegion, booleanArray);
                }
             if(!AppUtils.isUndefinedOrNull(ctrValue.inquirySource))
                {
                    InboxViewHelper.compareWorkflowStringField(workflow.inquirySource, ctrValue.inquirySource, booleanArray);
                }
            if(!AppUtils.isUndefinedOrNull(ctrValue.crtDate))//[C153176-160] Saved Search filter criteria is not working for all columns
            {
                InboxViewHelper.compareUserViewDate(workflow.crtDate,ctrValue.crtDate,booleanArray);
            }
            if(!AppUtils.isUndefinedOrNull(ctrValue.modDate))
            {
                InboxViewHelper.compareUserViewDate(workflow.modDate,ctrValue.modDate,booleanArray)
            }
            if(!AppUtils.isUndefinedOrNull(ctrValue.age$gt)||!AppUtils.isUndefinedOrNull(ctrValue.age$lt)||!AppUtils.isUndefinedOrNull(ctrValue.age$eq))
            {
                InboxViewHelper.compareUserViewageCalculation(workflow,ctrValue,booleanArray,viewType)
            }
            if(!AppUtils.isUndefinedOrNull(ctrValue.isSubjectEscalation))
            {
                InboxViewHelper.compareWorkflowStringField(workflow.isSubjectEscalation, ctrValue.isSubjectEscalation, booleanArray);
            }
            if(!AppUtils.isUndefinedOrNull(ctrValue.isConvCountEscalation))
            {
                InboxViewHelper.compareWorkflowStringField(workflow.isConvCountEscalation, ctrValue.isConvCountEscalation, booleanArray);
            }
            if(!AppUtils.isUndefinedOrNull(ctrValue.isClientChaseEscalation))
            {
                InboxViewHelper.compareWorkflowStringField(workflow.isClientChaseEscalation, ctrValue.isClientChaseEscalation, booleanArray);
            }
            if(!AppUtils.isUndefinedOrNull(ctrValue.responseTimeEscalationFlag))
            {
                InboxViewHelper.compareWorkflowStringField(workflow.responseTimeEscalationFlag, ctrValue.responseTimeEscalationFlag, booleanArray);
            }
            if(!AppUtils.isUndefinedOrNull(ctrValue.isManualEscalation))
            {
                InboxViewHelper.compareWorkflowStringField(workflow.isManualEscalation, ctrValue.isManualEscalation, booleanArray);
            }
            if(!AppUtils.isUndefinedOrNull(ctrValue.manualEscalationReason))
            {
                InboxViewHelper.compareWorkflowStringField(workflow.manualEscalationReason, ctrValue.manualEscalationReason, booleanArray);
            }
            if(!AppUtils.isUndefinedOrNull(ctrValue.lastActionTime))
            {
                InboxViewHelper.compareUserViewDate(workflow.lastActionTime,ctrValue.lastActionTime,booleanArray)
            }
            if(!AppUtils.isUndefinedOrNull(ctrValue.resolveTime))
            {
                InboxViewHelper.compareUserViewDate(workflow.resolveTime,ctrValue.resolveTime,booleanArray)
            }
            if(!AppUtils.isUndefinedOrNull(ctrValue.reAgeDate))
            {
                InboxViewHelper.compareUserViewDate(workflow.reAgeDate,ctrValue.reAgeDate,booleanArray)
            }
            if(!AppUtils.isUndefinedOrNull(ctrValue.reOpenDate))
            {
                InboxViewHelper.compareUserViewDate(workflow.reOpenDate,ctrValue.reOpenDate,booleanArray)
            }
            if(!AppUtils.isUndefinedOrNull(ctrValue.workflowStatus))
            {
                InboxViewHelper.compareWorkflowStringField(workflow.workflowStatus, ctrValue.workflowStatus, booleanArray);
            }
            if(!AppUtils.isUndefinedOrNull(ctrValue.suggestionIndicator))
            {
                InboxViewHelper.compareWorkflowStringField(workflow.suggestionIndicator, ctrValue.suggestionIndicator, booleanArray);
            }
            for (var idx=0;idx<booleanArray.length;idx++)
             {
              if (idx == 0) 
              {
                isUserCtrSucess = booleanArray[idx];
              } 
              else 
              {
                 if (userOperator == '&&') 
                 {
                    isUserCtrSucess = isUserCtrSucess && booleanArray[idx];
                 } 
                 else 
                 {
                    isUserCtrSucess = isUserCtrSucess || booleanArray[idx];
                 }
              }
             }
        }
    
    return isUserCtrSucess;
    }

    /**
     * Retrieve the latest or unique value of the specified attribute from inquiry object.
     */
    static getAttrValue(obj, key) {
        const values = InboxViewHelper.getObjValues(obj, key);
        return values; // C153176-5120: return array is any
    }

    /**
     * Extract values of the specified attribute from inquiry object, either at top-level (inquiry) or at child-level (workflows)
     * @return an array of non-undefined values
     */
    static getObjValues(obj, key) {
        if (!obj || !key) {
            return undefined;
        }
        if (obj[key] !== undefined) {
            return [obj[key]];
        }
        let altKey;
        if (key.startsWith('workflows.')) {
            altKey = key.substring('workflows.'.length);
        }
        if (altKey && obj[altKey] !== undefined) {
            return [obj[altKey]];
        }
        const wfs = obj.workflows;
        if (wfs && wfs.length) {
            const values = wfs.reduce((v, wf) => {
                if (wf[key] !== undefined) {
                    v.push(wf[key]);
                } else if (altKey && wf[altKey] !== undefined) {
                    v.push(wf[altKey]);
                }
                return v;
            }, []);
            return values;
        } else {
            return [];
        }
    }

    static getValue(value) {
        if (!value) {
            return value;
        }
        if (value instanceof String) {
            return value;
        } else if (value instanceof Object) {
            return value.pattern;
        } else {
            return value;
        }
    }

    // C153176-5120: handle array and non-array in one shot
    static evalValues(values, f) {
        if (values && Array.isArray(values)) {
            let index = values.findIndex(v => f.call(null, v));
            return index > -1;
        } else {
            return f.call(null, values);
        }
    }
}
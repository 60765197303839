
<div class="notification-bar" *ngIf ="showNotificationHtml">      
  <div class='logo pt-4'><p class="pb-2 text-center active">NOTIFICATIONS</p></div>
  <div>
      <div class="nav d-flex align-items-center flex-column flex-nowrap">
            <div (click)="onOptionClick(0)" class="w-111 px-4 pt-2" [class.active]='clickStatusArray[0]' >
                <div class="d-flex align-items-center justify-content-end flex-row flex-nowrap active mb-2 pr-2" *ngIf="this.notificationArray.length>1">
                    <div class="clickable-cursor d-flex flex-row align-items-center" (click)="dismissAll();closePanel();">
                        <img style="width: 16px;height: 16px;" src="assets/core/layout/tabs/close_icon.svg" alt="Close"><ins>Dismiss All</ins></div>                              
                </div>
                <div *ngIf="this.notificationArray.length == 0" class="no-notification text-center mt-4"><h6>There are no notifications for you today</h6></div> 
                <ng-scrollbar class="notification-scrollbar"
                    [invertY]="false"
                    [trackX]="false"
                    [compact]="false"
                    [shown]="native"
                    [autoUpdate]="true">    
                <ul class="p-0 notification-text grey-color">
                    <li *ngFor="let item of this.notificationArray" class="d-flex flex-row flex-wrap mb-1 pr-1 subMenu"
                    [ngClass]="{'subMenu-escalation': item.subject == 'ESCALATION', 'subMenu-unsnooze': item.subject == 'UNSNOOZE', 'subMenu-nomination': item.subject == 'OWNERSHIP NOMINATION',

                    'subMenu-approval': item.subject == 'APPROVED' || item.subject == 'ACCEPTED', 'subMenu-reject': item.subject == 'REJECT' || item.subject == 'REJECTED'}" [@items]> <!--  color added for pending approval-->

                        <div class="notification-text-cont">                             
                            <div class="m-0 inquiry-header qma-text-new">{{(item.subject == 'APPROVED' || item.subject == 'REJECT') ? 'PENDING APPROVAL' : item.subject == 'ACCEPTED' || 
                                item.subject == 'REJECTED'? 'OWNERSHIP NOMINATION': item.subject == 'UNSNOOZE' ? 'SNOOZE' : item.subject}}</div>
                            <div class="inquiry-content text-truncate">Inquiry Id: {{item._id}}  {{item.action}}</div>
                        </div>
                        <div class="d-flex align-items-end flex-column flex-nowrap" >
                            <div class="inquiry-date">{{item.crtDate.$date }}</div>
                                <div class="d-flex align-items-end flex-row flex-nowrap justify-content-end mt-1">
                                <div (click)="viewNotification(item);closePanel();" class="qma-text right-content d-flex align-items-center flex-column flex-nowrap pr-2">
                                    <img src="assets/core/layout/tabs/eye.svg" style="width: 15px;height: 7px;margin-bottom: 2px; cursor: pointer;" alt="View">
                                    <span class="icon-text"><ins>View</ins></span>
                                </div>
                                <div (click)="dismissNotification(item);closePanel();" class="qma-text right-content d-flex align-items-center flex-column flex-nowrap">
                                    <img src="assets/core/layout/tabs/close_icon_active.svg" style="width: 14px;height: 14px; cursor: pointer;" alt="Close">
                                    <span class="icon-text"><ins>Dismiss</ins></span>
                                </div>
                            </div>
                        </div>
                    </li>                      
                </ul>   
             </ng-scrollbar>               
            </div>          
              <!-- <li (click)="onOptionClick(1)" class="w-100" [class.active]='clickStatusArray[1]'>
                  <div class="d-flex align-items-center flex-row flex-nowrap">
                      <div><i class="fa fa-inbox"></i></div>
                      <div class="menu-text">REPORTS</div>
                      <div class="sub-menu">
                          <i class="fa " [ngClass]="clickStatusArray[1] ? 'fa-chevron-up': 'fa-chevron-down'" style="font-size:15px"></i>        
                      </div>
                  </div>
                  <div>
                      <ul *ngIf="clickStatusArray[1]" class="subMenu">
                          <li><a href="#">Inbox</a><span class="custom-badge pull-right">15</span></li>
                          <li><a href="#">Sent</a><span class="custom-badge pull-right">5</span></li>
                          <li><a href="#">Pending approvals</a><span class="custom-badge pull-right">5</span></li>
                          <li><a href="#">Potential escalations</a><span class="custom-badge pull-right">5</span></li>
                          <li><a href="#">Resolved</a><span class="custom-badge pull-right">5</span></li>
                          <li><a href="#">Non-inquiries</a><span class="custom-badge pull-right">5</span></li>
                          <li><a href="#">Drafts</a><span class="custom-badge pull-right">5</span></li>
                      </ul>
                  </div>
              </li>     -->    
            </div>
      </div>
  </div>
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PersonalEmailComponent } from './personal-email.component';
import {TreeModule} from 'primeng/tree';

@NgModule({
  declarations: [PersonalEmailComponent],
  imports: [
    CommonModule,
    TreeModule
  ],
  exports: [PersonalEmailComponent]
})
export class PersonalEmailModule { }

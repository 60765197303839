import { Component, OnInit, OnDestroy, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { ContextMenuService } from 'src/app/services/contextMenu/context-menu.service';
import { CheckboxListComponent } from '../checkbox-list/checkbox-list.component';
import { Subscription } from 'rxjs';
import { MessageService } from 'primeng/api';
import { UserDataService } from 'src/app/services/user-data.service';

@Component({
  selector: 'app-ownership',
  templateUrl: './ownership.component.html',
  styleUrls: ['./ownership.component.scss']
})

export class OwnershipComponent implements OnInit,OnDestroy {
  display:boolean = false;
  ownershipModel:any;
  selectedMenuItem:any;
  assignedUsersList:any[];
  tabArray:Array<boolean> = [true, false];
  userGroups:any;
  groupsData:any;
  allGroups:any;
  ownershipGroup:any;
  subManager = new Subscription();
  selectedItems:any = [];
  ownerList:any=[];
  nominateOwner:string = "";
  assignedOwner:string="";
  takeOwnershipValidation:boolean = false;
  nominateOwnershipValidation:boolean = false;
  isReasonBlank:boolean = false;
  allGrpData:Array<any> = [];
  loginUserInfo:any;
  isMultiGroupSelected:any = "";
  assignToMe = false;
  disableAssignToMe = false;
  @ViewChildren(CheckboxListComponent) checkboxListComponent: QueryList<CheckboxListComponent>;

  constructor(private contextMenuService:ContextMenuService,private messageService: MessageService, private userdata: UserDataService) {}
  ngOnInit() {
    this.userdata.LocalGetLoginUserInfo().subscribe((data)=>{
      this.loginUserInfo = data;
    })  
  }
  onShowDialog(){
    this.assignToMe = false;
    this.nominateOwner = "";
    if(this.selectedMenuItem.length ==1 && this.selectedMenuItem[0].openUsers.indexOf(this.loginUserInfo.userId) > -1){
      this.disableAssignToMe = true;
    }
  }

  ngOnDestroy(){
  }

  triggerFalseClick() {
    let groupsData =  this.selectedMenuItem.map((element)=> {
     let obj;
      obj = element.workflows.map((item)=>{
        var rObj = {};
        rObj["label"] = item.assignedGroupName;
        rObj["value"] = item.assignedGroupId;
        rObj["name"] = item.assignedGroupName;
        return rObj;
      });
      return obj;
    });
    groupsData = groupsData.flat();    
    this.userGroups =  this.removeDuplicates(groupsData, "label");
    this.display = true;
    this.resetFields();
    this.resetValidations(false);
    this.isReasonBlank = false;
    this.tabArray = [true, false];
    this.populateGroups();
  }
// changes for [C170665-1013] Nominate ownership - slowness in loading ownership tab and group list
populateGroups(){
  if(this.allGrpData.length > 0){
    this.filterTakeOwnershipGroups();
  }
  else{
    let sub1 = this.contextMenuService.httpGetAllGroups().subscribe(res => {
      let grp = res.map(obj =>{ 
        var rObj = {};
        rObj["label"] = obj.text;
        rObj["value"] = obj.value;
        rObj["name"] = obj.text;
        return rObj;
      });
      this.allGrpData = this.removeDuplicates(grp, "label");
      this.filterTakeOwnershipGroups();
    })
  }
} 
//C170665-1013 filter loop to replace with forEach for not returning 
filterTakeOwnershipGroups(){
    let userGrp = this.userGroups;      
    this.allGroups=this.allGrpData;
    if(userGrp){
      userGrp.forEach(obj=>{    
        let index=this.allGroups.findIndex(x=>x.label===obj.label);
        if(index>-1){
          this.allGroups.splice(index,1);
        }
      })
    }
}

  hideModal(){
    this.display = false;
    this.subManager.unsubscribe();
    this.disableAssignToMe = false; 
  }

  selectedTab(_index){
    this.isReasonBlank = false;
    if(!this.tabArray[_index] && this.selectedItems.length>0){
    this.nominateOwnershipValidation = true;
    this.takeOwnershipValidation = true;     
      return;
    }
    this.tabArray.fill(false);
    this.tabArray[_index]=true;
    this.nominateOwnershipValidation = false;
    this.takeOwnershipValidation = false;
  }

  onSubmit(){
    //if Assign to me is selected then only this("if") block will be executed
    if(this.assignToMe){
      let userId = this.loginUserInfo.userId;
      /* this.contextMenuService.httpSetAssignOwner(this.selectedMenuItem, userId).subscribe(result => {
        if(result.processingstatus){
          this.messageService.add({key: 'qmaToast', severity:'success', summary: 'Success', detail: "Action Completed"});
        }  
      }); */

     
      let assignedGrp = this.selectedMenuItem[0].workflows.filter
      (wf=>wf.assignedGroupName === this.selectedMenuItem[0].openGroups)[0].assignedGroupId;

      this.contextMenuService.httpTakeOwnership(this.selectedMenuItem, assignedGrp, userId).subscribe(result => {
        if (result.Success) {
          this.messageService.add({ key: 'qmaToast', severity: 'success', summary: 'Success', detail: result.message });
        }
      });

      this.assignToMe = false;
      this.hideModal();
      return;
    }
    
    if(this.selectedItems.length === 0){      
      return;
    }

    let selItem = this.selectedItems.map((item:any) =>{
      return item.value;
    })
    
    if(this.tabArray[1]){
      if(this.nominateOwner.trim() == ""){
        this.isReasonBlank = true;
        return;
      }
      this.contextMenuService.httpNominateOwnership(this.selectedMenuItem, selItem, this.nominateOwner).subscribe(result => {
        if(result.Success){
          this.messageService.add({key: 'qmaToast', severity:'success', summary: 'Success', detail: result.message});
        }  
      });
    }
    else{
        this.contextMenuService.httpTakeOwnership(this.selectedMenuItem, selItem, this.assignedOwner).subscribe(result => {
          if(result.Success){
            this.messageService.add({key: 'qmaToast', severity:'success', summary: 'Success', detail: result.message});
          } 
        });
      
    }

    this.hideModal();
  }

  removeDuplicates(myArr, prop) {
    return myArr.filter((obj, pos, arr) => {
        return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
    });
 }

  getSelectedItems(_evt:any, tabName?:"da"){
    this.selectedItems = [];
    this.selectedItems = _evt;
    this.takeOwnershipValidation = false;
    this.nominateOwnershipValidation = false;
    this.isReasonBlank = false;
    let commonOwner = [];
    this.ownerList = [];
    let currentList = [];

    this.selectedItems.forEach((element, i) => {
      currentList = [];
      Array.prototype.push.apply(currentList, this.getAssignOwner(element.value, element.label));
      if(i > 0)//intersecting owners for all assigned groups
          commonOwner = this.operation(commonOwner, currentList, true); // 'true' for intersection
      else
          commonOwner = currentList;
    }); 
    this.ownerList = commonOwner;
  }

  getSelectedOwner(_evt:any){
    if(_evt.length)
      this.assignedOwner = _evt[0].value;
    else
      this.assignedOwner = "";
  }

  // Generic helper function that can be used for the three operations:        
operation(list1, list2, isUnion) {
  var result = [];
  
  for (var i = 0; i < list1.length; i++) {
      var item1 = list1[i],
          found = false;
      for (var j = 0; j < list2.length && !found; j++) {
          found = item1.value === list2[j].value;
      }
      if (found === !!isUnion) { // isUnion is coerced to boolean
          result.push(item1);
      }
  }
  return result;
}

  resetFields(){
    this.checkboxListComponent.forEach((com, idx) => {
       com.resetCheckList();
       com.clearFilterText();
    });
    this.resetValidations(false);
    this.assignToMe = false;
    this.disableAssignToMe = false; 
  }

  resetValidations(_boolean:boolean){
    this.nominateOwnershipValidation = _boolean;
    this.takeOwnershipValidation = _boolean;
  }

  getAssignOwner(selectedId, groupName): any {
    let assignedOwnerlist = [];
    for (var idx = 0; idx < this.loginUserInfo.myGroups.length; idx++) {
      if (selectedId == this.loginUserInfo.context[idx].groupId) {
        for (var ind = 0; ind < this.loginUserInfo.context[idx].assignOwnerList.length; ind++) {
          assignedOwnerlist.push({
            value: this.loginUserInfo.context[idx].assignOwnerList[ind].value,
            label: this.loginUserInfo.context[idx].assignOwnerList[ind].text,
          });
        }
      }
    }
    return assignedOwnerlist
  }
  onAssignToMe(e){
    this.assignToMe = e.target.checked;
  }
}
 <div style="padding-left:20px">
    <div class="d-flex flex-row mt-4 mrg-left-4em mrg-lef ">
        <div class="w-20 d-flex flex-row align-items-start ">
            <label for="from" class="flex-grow meta-margin">Metadata type: </label>
                <p-dropdown [styleClass]="'prime-dd-override'" class="drop-down w-25 org-details-input" [options]="orgMetadataTypeList" [(ngModel)]="selectedOrgMetaDataType"
                        (onChange)="selectOrgMetaDataType($event.value,'onchange')">
                    </p-dropdown>
                </div>
            </div>
            <div *ngIf="orgMetaDataType !== 'reqTypeCauseMapping'" class="d-flex flex-row mt-4">
                <ag-grid-angular #agGrid style="width: 55.9%; height: 440px" id="metadata" class="ag-theme-balham headerPart" [columnDefs]="orgMetaColumnDefs"
                    [rowData]="orgRowMetaData"  [defaultColDef]="metaCheckbox" [singleClickEdit]="true" [stopEditingWhenCellsLoseFocus]="true" [suppressRowClickSelection]="true"
                    (gridReady)="onOrgMetaDataGridReady($event)" [rowHeight]="43" [headerHeight]="44" [suppressHorizontalScroll]="true">
                </ag-grid-angular>
            </div>
            <div *ngIf="orgMetaDataType === 'reqTypeCauseMapping'" class="d-flex flex-column">
                <app-reqtype-rootcause [requestTypes]='orgRequestTypes' [requestCauses]='orgRootCauses' [reqTypeRootCauseList]="orgReqTypeRootCauseMap"
                    (updateMapping)="onApplyMapping($event)">
                </app-reqtype-rootcause>
            </div>
            </div>
import { Injectable } from '@angular/core';
import { HttpClientService } from '../../utility/http-client.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { serviceUrl } from '../../utility/serviceUrl';
import { QmaConstant } from 'src/app/constant/qma-constant';
import { Observable } from 'rxjs';

const httpFormPostOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  })
};

@Injectable({
  providedIn: 'root'
})

export class ReleaseNoteService extends HttpClientService{
  genericURL: string;
  constructor(http: HttpClient) {
    super(http);
    this.genericURL=QmaConstant.BASE_URL;
  }

  uploadReleaseNotes(formData:any) {
    let url:string =this.genericURL+'/'+serviceUrl.uploadReleaseNote;
    return this.http.post(url,formData);
  }

}
import { QmaConstant } from 'src/app/constant/qma-constant';

export class MailBoxHelper{
    calculateAndSetAge(inquiry:any){
       //if age is not coming from service
       if(inquiry.age === ""){
        var crtDate = new Date(inquiry.workflows[0].crtDate.$date);
        var currentTime = new Date();
        const diffTime = Math.abs(crtDate.getTime() - currentTime.getTime());
        const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24)); 
        if(diffDays > 0 ){
            console.log(diffDays);
        }
        return diffDays;
       } 
    }

    // C153176-4706: retrieve subject from origSubject, fallback to subject if needed
    getSubject(inq) {
        if (!inq) {
            return QmaConstant.NO_SUBJECT;
        }
        if (inq.origSubject) {
            return inq.origSubject;
        }
        if (inq.subject) {
            return inq.subject;
        }
        return QmaConstant.NO_SUBJECT;
    }
}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AttachmentComponent } from "./attachment/attachment.component";
import { AuditDetailComponent } from "./audit-detail/audit-detail.component";
import { NotesDetailsComponent } from "./notes-details/notes-details.component";
import { FormsModule } from "@angular/forms";

@NgModule({
  declarations: [
    AttachmentComponent,
    AuditDetailComponent,
    NotesDetailsComponent
  ],
  imports: [
    CommonModule,
    FormsModule
  ],
  exports: [
    AttachmentComponent,
    AuditDetailComponent,
    NotesDetailsComponent
  ],
})
export class InboxArtifactsModule { }

import { Component, ViewChild } from '@angular/core';
import { ICellRendererAngularComp } from "@ag-grid-community/angular";
import { MultiSelect, MultiSelectModule } from 'primeng/multiselect';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'app-user-entitlement-checkbox',
  //templateUrl: './user-entitlement-checkbox.component.html',
  /* Jira : C153176-4323 Issue- Multiple delete actions needed to delete the user from Group Admin.*/
  template: `<p-multiSelect #role (onChange)='updateValue()' [defaultLabel]="'Choose Role'" [options]="entGrpName" 
  [(ngModel)]="selectedRoleName" [styleClass]="'preference-multiselect UECheckbox multiselectUserEnt'" [style]="{'width':'180px'}" [showToggleAll]="false" 
  [maxSelectedLabels]="4" [baseZIndex]="9999" [appendTo]="'body'"></p-multiSelect>`,   
})
export class UserEntitlementCheckboxComponent implements ICellRendererAngularComp {
  public params: any;
  public gridRoleApi : any;
  public selectedRoleName: any[];
 
  private fieldname: string;
  public value: any;
  public entGrpName : any[];
  @ViewChild('role') multiselect: MultiSelect;


  agInit(params: any): void {
    this.params = params;
    this.gridRoleApi = params.api;
    this.fieldname = params.fieldname;    
    this.entGrpName = [      
      { label: 'Group Admin', value: 'Group Admin' },
      { label: 'Peer Reviewer', value: 'Peer Reviewer' },
      { label: 'Supervisor', value: 'Supervisor' },
      { label: 'Analyst', value: 'Analyst' }      
    ]; 
    
  
    this.selectedRoleName = (this.params.value).split(',');
    this.selectedRoleName = this.selectedRoleName.map(function (el) {
      return el.trim();
    });
    
  }

  updateValue() {
    this.params.data.role = this.getValue();
  }
  getValue(): any {
    if(this.selectedRoleName.length > 0)
      return (this.selectedRoleName).join();
    else
      return this.selectedRoleName;
  }

  refresh(): boolean { 
      return true;    
  }

}

import { Component, OnInit, ViewChildren, ViewChild, ElementRef, QueryList, Output, EventEmitter, ChangeDetectionStrategy, HostListener } from '@angular/core';   // Advance search pop up issue
//import { SelectItem } from 'primeng/api';
import { SelectItem } from '../../model/LoginUserInfo/SelectItem';
import { AdvSearchService } from '../../services/adv-search.service';
import { LinkedUsersForGroupList } from '../../model/LinkedUsersForGroupList/LinkedUsersForGroupList';
import { UserDataService } from '../../services/user-data.service';
import { TabDataService } from '../../services/tab-data.service';
import { InboxService } from '../../services/inbox.service';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { AppUtils } from '../../common/utility/appUtil';
import { CheckboxListComponent } from '../../common/component/checkbox-list/checkbox-list.component';
import { NameCount } from 'src/app/model/Common/NameCount';
import * as $ from 'jquery';   // Advance search pop up issue
import { QmaConstant } from "src/app/constant/qma-constant"; //Advance search pop up issue
import { PiwikProUtils } from 'src/app/common/utility/piwikProUtils';

@Component({
  selector: 'app-adv-criteria',
  templateUrl: './adv-criteria.component.html',
  styleUrls: ['./adv-criteria.component.scss'],
})
export class AdvCriteriaComponent implements OnInit {  
  shouldShow: boolean = false;
  assignedShouldShow: boolean = false;
  requestShouldShow: boolean = false;
  tagsShouldShow: boolean = false;
  statusShouldShow: boolean = false;
  ageShouldShow: boolean = false;
  hoursShouldShow: boolean = false;
  @ViewChild("advSearchContainer", { static: true }) advSearchContainer: ElementRef; //tracking click events outside component
  @ViewChildren(CheckboxListComponent) checkboxListComponents: QueryList<CheckboxListComponent>;
  @Output() searchToggle = new EventEmitter();
  linkedUsersForGroupList: LinkedUsersForGroupList[];
  selectedAssignedGroups: string;
  selectedRequestTypes: string;
  selectedTags: string;
  assignedGroups: NameCount[] = [];
  requestTypes: SelectItem[] = [];
  tags: SelectItem[] = [];
  status: SelectItem[] = [];
  selHours: any = [];
  selDays: any = [];
  // declare fields for Searchin
  searchInAllMailBox: string;
  searchInInBox: string;
  searchInPendAppr: string;
  searchInResolved: string;
  searchInSent: string;
  searchInMyViews: string;

  statusOpen: string;
  statusResolved: string;
  statusPendAppr: string;
  statusReOpen: string;
  statusEscalation: string;
  checked: string;
  dateFrom: string;
  dateTo: string;

  scroll: any = 'native';

  primelistStyle: string = 'advance-prime-list';
  mailboxesJson: any = [
    {
      name: "searchInbox",
      value: "Inbox",
      label: "Inbox",
      binary: true // Default Inbox to be selected under Advance filter on load of QMA2.0
    },
    {
      name: "searchPending",
      value: "Pending Approval",
      label: "Pending Approval",
      binary: false
    },
    {
      name: "searchResolved",
      value: "Resolved",
      label: "Resolved",
      binary: false
    },
    {
      name: "searchSent",
      value: "Sent",
      label: "Sent",
      binary: false
    }
  ];
 
  statusJson: any = [{
    name: "searchInAll",
    value: "Open",
    label: "Open",
    binary: false
  },
  {
    name: "searchInbox",
    value: "Resolved",
    label: "Resolved",
    binary: false
  }
  ];
  ageJson: any = [
    {
      name: "aboveThirty",
      value: "30",
      label: "30+ days",
      //binary: false
    },
    {
      name: "aboveFiften",
      value: "15",
      label: "15+ days",
      //binary: false
    },
    {
      name: "aboveFive",
      value: "5",
      label: "5+ days",
      //binary: false
    },
    {
      name: "aboveSingle",
      value: "1",
      label: "1+ day",
      //binary: false
    }
  ];
  hoursJson: any = [
    {
      name: "aboveFourty",
      value: "48",
      label: "48+ hrs",
      //binary: false
    },
    {
      name: "aboveTwenty",
      value: "24",
      label: "24+ hrs",
      //binary: false
    },
    {
      name: "aboveTwelve",
      value: "12",
      label: "12+ hrs",
      //binary: false
    },
    {
      name: "aboveSix",
      value: "6",
      label: "6+ hrs",
      //binary: false
    }

  ]

  selReqTypes: any;
  selAssignedGrps: any;
  selTags: any;
  selBoxes: any;
  selStatuses: any;
  error: any = { isError: false, errorMessage: '' };
  isSubmitted = false;
  public advSearchForm: FormGroup;
  public advSearchError = false;

  @HostListener('document:click', ['$event']) click(e){
  //: Advance search pop up issue 
    if(window.innerWidth <= QmaConstant.MOBILE_WIDTH){
      let adv_elems = $(e.target).parents('.adv-search-button');
      if (this.advSearchContainer && this.advSearchContainer.nativeElement && this.advSearchContainer.nativeElement.contains(e.target) ||
         adv_elems.length || $(e.target).hasClass('adv-search-button')) {
        $('#adv').show();
      } else {
        $('#adv').hide();
      }
    }
  }
  constructor(private advSearchService: AdvSearchService, private userDataService: UserDataService, private tabDataService: TabDataService, private inboxService: InboxService, private formBuilder: FormBuilder) {
    // store form data in service so when reopen the last searched data is prepopulated 
    this.advSearchForm = formBuilder.group({
      mailboxes: ['Inbox', []], // Default Inbox to be selected under Advance filter on load of QMA2.0
      statuses: this.buildStatusBoxes(),
      age : this.buildAgeJson(),
      hours : this.buildHoursJson(),
      content: [this.advSearchService.advSearchFormData.content, Validators.required],
      subject: [this.advSearchService.advSearchFormData.subject, Validators.required],
      originator: [this.advSearchService.advSearchFormData.originator, Validators.required],
      startDt: [this.advSearchService.advSearchFormData.startDt, Validators.required],
      endDt: [this.advSearchService.advSearchFormData.endDt, Validators.required],
      attachment: [this.advSearchService.advSearchFormData.attachment, Validators.required]
    });   
  }
  
  ngOnInit() {
    this.getSelectedBox("Inbox"); // Default Inbox to be selected under Advance filter on load of QMA2.0
    this.assignedGroups = this.userDataService.assignedGroups;
    //this.requestTypes = this.userDataService.requestTypes;
    //this.tags = this.userDataService.tags;

    let requestArray:SelectItem[] = [];
    this.userDataService.requestTypes.forEach(element => {           
      requestArray.some(val=> val.value == element.value) ? null : requestArray.push({'text': element.text,'label': element.label,'value': element.value,'listItem': element.listItem});      
    });
    this.requestTypes=requestArray;
    
    let tagsArray:SelectItem[] = [];
    this.userDataService.tags.forEach(element => {
      tagsArray.some(tag=> tag.value == element.value) ? null : tagsArray.push({'text': element.text,'label': element.label,'value': element.value,'listItem': element.listItem});
    });
    this.tags=tagsArray;

    this.advSearchError = false;

    /*  TO DO QmaConstant.inquiryStatusEnum.forEach(elem => {
    /*  QmaConstant.inquiryStatusEnum.forEach(elem => {
       let textValue = new TextValue( elem.text, elem.label, elem.value)
       this.status.push(textValue);
     }); */
    // TO DO get data for linkedUsers groupList - This is done in old UI - need to find the usage
    /*  this.advSearchService.getAdvSearchDetails(this.userDataService.requestObj).subscribe(linkedUsersForGroupList => {
       this.advSearchService.linkedUsersForGroupList = linkedUsersForGroupList;
     }); */
  }
  buildMailBoxes() {
    const arr = this.mailboxesJson.map(mailboxItem => {
      return this.formBuilder.control(mailboxItem.binary);
    });
    return this.formBuilder.array(arr);
  }
  buildAgeJson() {
    const arr = this.ageJson.map(ageItem => {
      return this.formBuilder.control(ageItem.binary);
    });
    return this.formBuilder.array(arr);
  }
  buildHoursJson() {
    const arr = this.hoursJson.map(hoursItem => {
      return this.formBuilder.control(hoursItem.binary);
    });
    return this.formBuilder.array(arr);
  }
  buildStatusBoxes() {
    const arr = this.statusJson.map(status => {
      return this.formBuilder.control(status.binary);
    });
    return this.formBuilder.array(arr);
  }
  public get mailboxes(): FormArray {
    return this.advSearchForm.get('mailboxes') as FormArray;
  }
  public get statuses(): FormArray {
    return this.advSearchForm.get('statuses') as FormArray;
  }
  public get age(): FormArray {
    return this.advSearchForm.get('age') as FormArray;
  }
  public get hours(): FormArray {
    return this.advSearchForm.get('hours') as FormArray;
  }
  private newMethod(): string {
    return "advance-prime-list";
  }
  submitSearch(value: any) {
    this.validateDate();
    if (this.error.isError) {
      return;
    }
    let tempSelAssignedGrps: string[] = [];
    if (this.selAssignedGrps) {
      this.selAssignedGrps.forEach(element => {
        tempSelAssignedGrps.push(element.id);
      });
      PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Search", "Advanced Search in - Assigned group", "Click", "Advanced Search in - Assigned group", 0);
    }
    let tempSelReqTypes: string[] = [];
    if (this.selReqTypes) {
      this.selReqTypes.forEach(element => {
        tempSelReqTypes.push(element.label);
      });
      PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Search", "Advanced Search in - Request Type", "Click", "Advanced Search in - Request Type", 0);
    }
    let tempSelTags: string[] = [];
    if (this.selTags) {
      this.selTags.forEach(element => {
        tempSelTags.push(element.label);
      });
      PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Search", "Advanced Search in - Tags", "Click", "Advanced Search in - Tags", 0);
    }
    let tempSelAge: string[] = [];
    if (this.selDays) {
      this.selDays.forEach(element => {
        tempSelAge.push(element.label);
      });
    }
    let tempSelHours: string[] = [];
    if (this.selHours) {
      this.selHours.forEach(element => {
        tempSelHours.push(element.label);
      });
    }
    let tempSelStatuses: string[] = [];
    if (this.selStatuses) {
      this.selStatuses.forEach(element => {
        tempSelStatuses.push(element.label);
      });
      PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Search", "Advanced Search in - Status", "Click", "Advanced Search in - Status", 0);
    }

    let formData: any = {};
    let today = new Date();
    today.setHours(23, 59, 59, 999);
    if (value && value.content) {
      let content;
      content = AppUtils.escapeSpecialCharSolrValue(value.content);
      let keyContent = 'content';
      formData[keyContent] = content;
      PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Search", "Advanced Search in - Content", "Click", "Advanced Search in - Content", 0);
    }
    if (value && value.originator) {
      let keyOriginator = 'originator';
      formData[keyOriginator] = value.originator;
      PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Search", "Advanced Search in - TO/FROM", "Click", "Advanced Search in - TO/FROM", 0);
    }
    if (value && value.subject) {
      let keySubject = 'subject';
      formData[keySubject] = value.subject;
      PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Search", "Advanced Search in - Subject", "Click", "Advanced Search in - Subject", 0);
    }

    //: If end date is not selected, it should be todays date
    if (value && value.startDt && !value.endDt) {
      let keyStartDt = 'startDt';
      let startDt = value.startDt;
      startDt.setHours(0, 0, 0, 0);  // get start time of date.
      formData[keyStartDt] = startDt;

      let keyEndDt = 'endDt';
      formData[keyEndDt] = today;
      PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Search", "Advanced Search in - From Date", "Click", "Advanced Search in - From Date", 0);
    }
    if (value && value.startDt && value.endDt) {
      let keyStartDt = 'startDt';
      let startDt = value.startDt;
      startDt.setHours(0, 0, 0, 0);  // get start time of date.
      formData[keyStartDt] = startDt;

      let keyEndDt = 'endDt';
      let endDt = value.endDt;
      endDt.setHours(23, 59, 59, 999);  //get end time of date.
      formData[keyEndDt] = value.endDt;
      PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Search", "Advanced Search in - End Date", "Click", "Advanced Search in - End Date", 0);
    }
    if (tempSelAssignedGrps.length > 0) {
      let keyAssignedGroupIdList = 'assignedGroupIdList';
      formData[keyAssignedGroupIdList] = tempSelAssignedGrps;
    }
    if (tempSelReqTypes.length > 0) {
      let keyReqTypeList = 'reqTypeList';
      formData[keyReqTypeList] = tempSelReqTypes;
    }
    if (tempSelTags.length > 0) {
      let keyTagList = 'tagList';
      formData[keyTagList] = tempSelTags;
    }
    if (tempSelAge.length > 0) {
      let keyAgeList = 'ageList';
      formData[keyAgeList] = tempSelAge;
    }
    if (tempSelHours.length > 0) {
      let keyHoursList = 'hoursList';
      formData[keyHoursList] = tempSelHours;
    }
    if (tempSelStatuses.length > 0) {
      let keyStatusList = 'statusList';
      formData[keyStatusList] = tempSelStatuses;
    }
    if (value && value.attachment) {
      let keyAttachment = 'attachment';
      // add attachment only when the toggle is enabled
      formData[keyAttachment] = true;
      PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Search", "Advanced Search in - Attachment", "Click", "Advanced Search in - Attachment", 0);
    }
    this.advSearchError = true;
    if (formData && Object.keys(formData).length !== 0) {
      this.advSearchError = false;
      let reqObj = { "viewName": "Search", "advanceSearchData": formData };
      this.inboxService.requestObj = reqObj;
      this.inboxService.resetRequest = true;
      // set or clear searchBoxes at inbox service based on user selection
      this.inboxService.searchBoxes = (this.selBoxes =='Sent') ? 'Outbox' : this.selBoxes
      this.inboxService.triggerMobileSearch('adv-search');
      this.tabDataService.sendTabData("SEARCH", 0);
      this.loadFormDataToService();
      this.isSubmitted = true;
      this.searchToggle.emit(this.isSubmitted);
    }
    PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Search", "Advanced Search - Submit", "Click", "Advanced Search - Submit", 0);
  }
  endDateSelect(event){
    this.error = { isError: false, errorMessage: '' };
    let endDt = new Date(event);
    if (endDt && endDt > new Date()) {
      this.error = { isError: true, errorMessage: 'Date to can\'t be greater than today.' }; //Jira : 4540 Advance Search Date Issue
      return;
    }
  }
  startDateSelect(event){
    this.error = { isError: false, errorMessage: '' };
    let startDt = new Date(event);
    if (startDt && startDt > new Date()) {
      this.error = { isError: true, errorMessage: 'Date from can\'t be greater than today.' }; //Jira : 4540 Advance Search Date Issue
      return;
    }
  }
  validateDate() {
    // Jira : 4885 : advance search date issue
    this.error = { isError: false, errorMessage: '' };
    let sDateFromForm = this.advSearchForm.controls['startDt'].value;  //Jira : 4540 Advance Search Date Issue
    let eDateFromForm = this.advSearchForm.controls['endDt'].value;    
    if(!this.isNull(eDateFromForm) && this.isNull(sDateFromForm)){
      this.error = { isError: true, errorMessage: 'Please enter date from.' }; //Jira : 4540 Advance Search Date Issue
      return;
    }
    let eDate = (eDateFromForm) ? eDateFromForm : new Date();  // Jira : 4886 : End date is not mandatory
    let startDt = new Date(sDateFromForm);
    let endDt = new Date(eDate);
    let today = new Date();
    today.setHours(23, 59, 59, 999);
    if (endDt && endDt > today) {
      this.error = { isError: true, errorMessage: 'Date to can\'t be greater than today.' }; //Jira : 4540 Advance Search Date Issue
      return;
    }
    if (startDt && endDt && startDt > endDt) {
      if(this.isNull(eDateFromForm)){ //Jira : 4540 Advance Search Date Issue
        this.error = { isError: true, errorMessage: 'Date from can\'t be greater than today.' }; //Jira : 4540 Advance Search Date Issue
        return;
      }else{
        this.error = { isError: true, errorMessage: 'Date from can\'t be greater than date to.' }; //Jira : 4540 Advance Search Date Issue
        return;
      }      
    }
  }
  // Jira : 4885 : advance search date issue
   // Null value check function
   isNull(input) {
    if (input === "" || input === undefined || input == null) {
      return true;
    }
    return false;
  }
  loadFormDataToService() {
    this.advSearchService.advSearchFormData.content = this.advSearchForm.controls.content.value;
    this.advSearchService.advSearchFormData.subject = this.advSearchForm.controls.subject.value;
    this.advSearchService.advSearchFormData.originator = this.advSearchForm.controls.originator.value;
    this.advSearchService.advSearchFormData.startDt = this.advSearchForm.controls.startDt.value;
    this.advSearchService.advSearchFormData.endDt = this.advSearchForm.controls.endDt.value;
    this.advSearchService.advSearchFormData.attachment = this.advSearchForm.controls.attachment.value;
  }
  clearSearch() {
    //Jira : 4540 Advance Search radio button
    this.mailboxesJson.forEach((mail,index) => {
      if(index !== 0) {
        mail.checked = false;
        mail.binary = false;
      }
     
    });
    this.checkboxListComponents.forEach((comp) => {
      comp.resetCheckList();
    });
    this.error.isError = false;
    this.advSearchError = false;
    this.inboxService.triggerResetSearch('adv-search');
    this.advSearchForm.reset(
      {
        'mailboxes': 'Inbox',
        'statuses': '',
        'age' : '',
        'hours' : '',
        'content': '',
        'subject': '',
        'originator': '',
        'startDt': '',
        'endDt': '',
        'attachment': ''
      }
    );
    this.getSelectedBox("Inbox"); // Default Inbox to be selected under Advance filter on load of QMA2.0
  }
  getRequestGroups(selReqTypes: any) {
    this.selReqTypes = selReqTypes;
    console.log("selTags" + JSON.stringify(selReqTypes));
  }
  getAssignedGroups(selAssignedGrps: any) {
    this.selAssignedGrps = selAssignedGrps;
    if (this.selAssignedGrps && this.selAssignedGrps.length > 0) {
      this.advSearchError = false;
    }
    //console.log("####" + JSON.stringify(selAssignedGrps));
  }
  getTags(selTags: any) {
    this.selTags = selTags;
    if (this.selTags && this.selTags.length > 0) {
      this.advSearchError = false;
    }
    console.log("selTags" + JSON.stringify(selTags));
  }
  getSelectedBox(selBoxes: any) {
    this.selBoxes = selBoxes;
  }
  getStatusBox(selStatuses: any) {
    this.selStatuses = selStatuses;
    if (this.selStatuses && this.selStatuses.length > 0) {
      this.advSearchError = false;
    }
  }
  getAgeHours(selHours: any) {
    this.selHours = selHours;
    if (this.selHours && this.selHours.length > 0) {
      this.advSearchError = false;
    }    
  }
  getAgeDays(selDays: any) { 
    this.selDays = selDays;
    if (this.selDays && this.selDays.length > 0) {
      this.advSearchError = false;
    }  
  }
  // mobile view
  showSlide(shouldShow): void {
    this.shouldShow = !this.shouldShow;
    this.assignedShouldShow = false;
    this.requestShouldShow = false;
    this.tagsShouldShow = false;
    this.statusShouldShow = false;
    this.ageShouldShow = false;
    this.hoursShouldShow = false;
  }
  assignedShowSlide(assignedShouldShow): void {
    this.assignedShouldShow = !this.assignedShouldShow;
    this.requestShouldShow = false;
    this.tagsShouldShow = false;
    this.statusShouldShow = false;
    this.shouldShow = false;
    this.ageShouldShow = false;
    this.hoursShouldShow = false;
  }
  requestShowSlide(requestShouldShow): void {
    this.requestShouldShow = !this.requestShouldShow;
    this.assignedShouldShow = false;
    this.tagsShouldShow = false;
    this.statusShouldShow = false;
    this.shouldShow = false;
    this.ageShouldShow = false;
    this.hoursShouldShow = false;
  }
  tagsShowSlide(tagsShouldShow): void {
    this.tagsShouldShow = !this.tagsShouldShow;
    this.assignedShouldShow = false;
    this.requestShouldShow = false;
    this.statusShouldShow = false;
    this.shouldShow = false;
    this.ageShouldShow = false;
    this.hoursShouldShow = false;
  }
  statusShowSlide(statusShouldShow): void {
    this.statusShouldShow = !this.statusShouldShow;
    this.assignedShouldShow = false;
    this.requestShouldShow = false;
    this.tagsShouldShow = false;
    this.shouldShow = false;
    this.ageShouldShow = false;
    this.hoursShouldShow = false;
  }
  ageShowSlide(statusShouldShow): void {
    this.ageShouldShow = !this.ageShouldShow;
    this.assignedShouldShow = false;
    this.requestShouldShow = false;
    this.tagsShouldShow = false;
    this.shouldShow = false;
    this.statusShouldShow = false;
    this.hoursShouldShow = false;
  }
  hoursShowSlide(statusShouldShow): void {
    this.hoursShouldShow = !this.hoursShouldShow;
    this.assignedShouldShow = false;
    this.requestShouldShow = false;
    this.tagsShouldShow = false;
    this.shouldShow = false;
    this.statusShouldShow = false;
    this.ageShouldShow = false;
  }
  closeAdvanceSearch(){
    this.searchToggle.emit(false);
  }
}

import { Directive, Output, Input, EventEmitter, HostBinding, HostListener } from '@angular/core';
import { InboxService } from '../../services/inbox.service';
@Directive({
  selector: '[fileDragEnter]'
})
//drag enter listener - to support file drop on ATTACH icon
export class FileDragEnterDirective {
  constructor(private inboxService : InboxService) {

  }
  //Dragenter listener
  @HostListener('dragenter', ['$event'])
  public onDragEnter(event) {
    this.inboxService.setFileDragState(true);
  }
}
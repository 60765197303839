import { Component, Renderer2, Inject, ViewChild, Input, ChangeDetectorRef } from '@angular/core';
declare var window: any;
import { GridOptions, Module } from "@ag-grid-community/core";
import { SymphonyChatServiceService } from '../service/symphony-chat-service.service';
import { DOCUMENT } from '@angular/common';
import { SymphonyCreateInquiryComponent } from '../symphony-create-Inquiry/symphony-create-Inquiry.component';
import { AllToCcDBUser } from 'src/app/model/LoginUserInfo/AllToCcDBUser';
import { FormGroup, NgForm } from '@angular/forms';
import { UserDataService } from 'src/app/services/user-data.service';
import { AppUtils } from 'src/app/common/utility/appUtil';
import { ReturnStatement } from '@angular/compiler';
import { GroupAdminService } from 'src/app/services/group-admin/group-admin.service';
import { forkJoin, Subscription } from 'rxjs';
import { AllActiveGroup } from 'src/app/model/LoginUserInfo/AllActiveGroup';
import { WebsocketService } from '../../services/websocket/websocket.service';
import { SelectedMailModel } from '../model/selected-mail.model';
import { SelectedMailRequest } from '../model/selected-mailRequest-model';
import * as $ from 'jquery';
import { SymphonyCreateChatComponent } from '../symphony-create-chat/symphony-create-chat.component';
import { GridDataCacheService } from 'src/app/services/cache/grid-data-cache.service';
import { InboxService } from '../../services/inbox.service';
import * as _ from 'underscore';
import { GridViewDataModel } from 'src/app/model/GridViewDataModel/GridViewDataModel';
import { SymphonyChatViewComponent } from '../symphony-chat-view/symphony-chat-view.component';
import { InboxViewComponent } from 'src/app/inbox/inboxView/inboxView.component';
@Component({
  selector: 'app-symphony-grid-view',
  templateUrl: './symphony-grid-view.component.html',
  styleUrls: ['./symphony-grid-view.component.scss']
})
export class SymphonyGridViewComponent {
  private displayChatPopup: boolean = false;
  private gridApi;
  private gridColumnApi;
  public rowSelection: string = "single";
  splitdirection: string = ''
  splitAreaMailboxDefaultSize = 58;
  splitAreaChatViewDefaultSize = 42;
  showSpinner = true;
  /* reusable code start*/
  assignedGroup: string = '';
  public chatText = "";
  public tabNameforChatView = "";
  thisComponent: any;
  selectedMail: any = null;
  public inqData: any;
  public strMailboxView: string = "INBOX";
  /* reusable code end*/
  @ViewChild('sympCreateInqComp', { static: true }) sympCreateInqComp: SymphonyCreateInquiryComponent;
  /* @ViewChild('chatViewComponant') sympChatViewComp: SymphonyChatViewComponent; */
  @Input() sympChatViewComp: SymphonyChatViewComponent;
  columnDefs = [
    {
      field: 'streamId',
      headerName: '',
      cellRenderer: function (params) {
        return "<span class='qma-inbox-notes'><img class='mailbox-grid-symphony-icon' src='assets/symphony/syphony-2.png'></span>";
      },
      width: 55,
      suppressSizeToFit: true,
      resizable: false
    },
    {
      field: 'from',
      headerName: 'FROM',
      resizable: true,
      width: 150,

    },
    {
      field: 'subject',
      headerName: 'SUBJECT',
      width: 150,
    },
    {
      field: 'assigendgroup',
      headerName: 'ASSIGNEDGROUP'
    }/* ,
   {
     field:'streamId',
     headerName:'StreamId'
   } */

  ];

  rowData: any;
  defaultColDef = {
    sortable: true,
    resizable: true,
    filter: true,
    width: 100,
    headerComponentParams: {
      menuIcon: 'fa-bars',
      template:
        '<div class="ag-cell-label-container" role="presentation">' +
        '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
        '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +

        '    <span ref="eText" class="ag-header-cell-text mailbox-header" role="columnheader" ></span>' +
        '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
        '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>' +
        '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>' +
        '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
        '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
        '  </div>' +
        '</div>'
    },
    suppressColumnVirtualisation: true

  }
  selectedRow: any;
  // for add or remove member
  displayAddMemberPopUp: boolean = false;
  toUsersList: AllToCcDBUser[] = [];
  memberToBeRemoved: AllToCcDBUser = null;
  selectedRoomId: string = '';
  selectedRoomName: string = '';
  //to show response message
  displayResponse: boolean = false;
  responseMessage: any = '';
  loggedInUser: string;
  @Input() listStyle: any;

  filteredToUsersMultiple: any[] = [];
  allToCcDBUser: AllToCcDBUser[] = [];
  userList: AllToCcDBUser[] = [];
  selectedGroupList: any[];
  selectedGroup: string = '';
  displayRemoveMemberPopUp: boolean = false;
  @Input() parentForm: FormGroup;
  memberToBeAddedAutoComplete: boolean = true;
  public rowClassRules: any;
  public symphonyEntitlement: any;
  public gridLoadTimeStampPrev: any;
  public gridLoadTimeStampCurrent: any;
  externalSymphonyIds: Array<number> = new Array<number>();
  memebersListArray: Array<string> = new Array<string>();
  toUserSoeIds: Array<string> = new Array<string>();
  grpListArray: Array<number> = new Array<number>();
  citiDomains: any[];
  allActiveGroups: AllActiveGroup[] = [];
  email_regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  //websocket subscription
  private webSocketSubscription: Subscription;
  isWebSocketRedesignEnable: boolean = true;
  inboxView: string = "horizontal";
  public filterIconHideHorizontal: boolean = false;
  public filterIconHide: boolean = false;
  public filterText = "";
  public showCreateSympIcon: boolean = false;
  public rowGroupPanelShow: string = 'never';
  @ViewChild('sympcrtChatComp') sympcrtChatComp: SymphonyCreateChatComponent;
  @Input() fromInbox: boolean = false;
  dataCache: GridDataCacheService;
  loggedInUserObj: any;
  @Input() initialCount: any;
  finalCriteria: any;
  gridDetailsObject = {
    'criteria': "",
    'name': "",
    'selInq': [],
    'viewType': 0
  };
  public lbTotalRecords: string = '0';
  public lbPageSize: number = 0;
  public lbCurrentPage: number = 0;
  public lbTotalPages: number = 0;
  viewType: any;
  myViewCriteria: any;
  myViewDefaultCriteria: any;
  finalUICriteria: any;
  isDashboardInlineView: false;
  inboxViewComponent: any;
  @ViewChild('addMemberForm', { static: true }) addMemberForm: NgForm;
  @ViewChild('removeMemberForm', { static: true }) removeMemberForm: NgForm;
  allUsersSymphony: any;
  onlyOwnerOfChat = false;
  onlyOwnerOfChatName = "";
  constructor(private symphonyChatServiceService: SymphonyChatServiceService, private _renderer2: Renderer2,
    private userDataService: UserDataService,
    @Inject(DOCUMENT) private _document: Document, private groupAdminService: GroupAdminService,
    private wsService: WebsocketService, private cd: ChangeDetectorRef, private inboxService: InboxService) {
    this.dataCache = new GridDataCacheService(this.inboxService, this, this.userDataService);
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
    this.thisComponent = this;

  }
  ngOnInit() {
    this.loadGroups();
    this.getUserData();
    if (this.fromInbox) {
      return;
    }
    if (!window['symphony']) {
      // this.loadSymphonyScript();
    }
    this.getSymphonyEntitlement();
    this.loadUserSymphonyChatRoom();
    // to highlight unread chats
    this.rowClassRules = {
      "qma-inbox-row-read": (params) => {
        return !params.data.isContainsUnreadChat;
      },
      "qma-inbox-row": (params) => {
        return params.data.isContainsUnreadChat;;
      }
    };
    this.tabNameforChatView = AppUtils.getCurrentTab().toUpperCase();
  }
  private loadSymphonyScript() {
    let script = this._renderer2.createElement('script');
    script.type = "text/javascript";
    script.src = "XXXXX";
    script.dataset.onload = "onloadCallback";
    script.id = "symphony-ecm-sdk";
    script.async = true;
    script.defer = true;
    this._renderer2.appendChild(this._document.head, script);
  }
  // service call to fetch chats
  loadUserSymphonyChatRoom() {
    console.log('creating chat room');
    this.symphonyChatServiceService.loadUserSymphonyChatRoom({}).subscribe(
      (data: Array<Object>) => {
        let currentDate = new Date();
        this.gridLoadTimeStampCurrent = currentDate;
        this.gridLoadTimeStampPrev = currentDate;
        // this.rowData = [];
        let chatRoomData = [];

        data.forEach((v, i, data) => {
          console.log(v);
          if (v['streamType']['type'] == 'ROOM') {
            let name = v['roomAttributes']['name'];
            let id = v['id'];
            let obj = {};
            obj['subject'] = name;
            obj['streamId'] = id;
            obj['from'] = "Symphony";
            obj["isContainsUnreadChat"] = false;
            chatRoomData.unshift(obj);
          }
        });

        ;
        const arrStreamIds = chatRoomData.map(cd => cd.streamId);
        let reqObj = { symphonyStreamId: arrStreamIds };
        this.symphonyChatServiceService.getSymphonyDetails(reqObj).subscribe((res: any) => {
          res.SymphonyDetailsList.forEach(sympDetails => {
            // update chat room data
            let rowToUpdate = chatRoomData.find(cd => cd.streamId === sympDetails.id);
            if (rowToUpdate) {
              const from = this.allToCcDBUser.find((user) => user.id === sympDetails.crtBy).text
              let grpName = "";
              if (sympDetails.groupList) {
                sympDetails.groupList.forEach((gd, index) => {
                  if (this.userDataService.loggedInUserInfo.myGroups.find(mg => mg.id === gd)) {
                    if (index !== sympDetails.groupList - 1) {
                      grpName += this.userDataService.loggedInUserInfo.myGroups.find(mg => mg.id === gd).groupName + ";";
                    } else {
                      grpName += this.userDataService.loggedInUserInfo.myGroups.find(mg => mg.id === gd).groupName
                    }

                  }
                })
              }
              rowToUpdate.from = from;
              rowToUpdate.assigendgroup = grpName;
              rowToUpdate.toSoeIds = sympDetails.memberList;
            }
          });
          this.rowData = chatRoomData;
          this.subscribeToWebSocketUpdate();
        }, (error) => {
          console.log(error);
          this.rowData = chatRoomData;
        });
      },

    );
  }
  // pass respective selected attributes to chatview
  onSelectionChanged(event?: any) {
    // only one row can be selected at a time
    if (this.gridApi.getSelectedRows() && this.gridApi.getSelectedRows()[0]) {
      this.selectedRow = this.gridApi.getSelectedRows()[0];
      // selected row mark as  read
      this.markChatAsRead();
      // to rerender chatview with latedt streamID
      // pass selected row to child component.
      /*  if (this.symphonyChatViewComp) {
         this.symphonyChatViewComp.renderSymphonyChatView(this.selectedRow);
       } */
      this.processChatRoomSelection();
    }
  }
  // once loading of data in grid comleted
  rowDataChanged(params) {
    this.selectFirstInquiry();

  }
  // for initial load select first chat by default
  selectFirstInquiry() {
    if (this.gridApi) {
      let rowNode = this.gridApi.getDisplayedRowAtIndex(0);
      if (rowNode) {
        rowNode.setSelected(true);
      }
    }
  }
  // grid context menu
  getChatGridContextMenu = (params, mailboxComponant?: any) => {
    // any participant can convert chat to inq
    this.inboxViewComponent = mailboxComponant;
    let contextMenu = [
      {
        name: 'Create New Inquiry',
        action: () => {
          // open new message in new tab
          this.sympChatViewComp.prepareConvAttachementSymphony();
          this.selectedRow.convHistory = this.sympChatViewComp.chatConveration;
          this.symphonyChatServiceService.setDatasymponyChatToInq(this.selectedRow);
          /// this.sympCreateInqComp.displayCreateInqPopup(this.selectedRow);
        }
      }
    ];
    // Add remove can be performed only when bot is owner
    // return from here if bot is not owner
    if (!this.isBotMember() || !this.isBotOwner()) {
      return contextMenu;
    }

    // only owner of chat room can add/remove members

    if (mailboxComponant && mailboxComponant.sympChatViewComp &&
      mailboxComponant.sympChatViewComp.allUsersSymphony) {
      const isLoggedInUserOwner = mailboxComponant.sympChatViewComp.allUsersSymphony
        .some(u => u.symphonyId === +this.userDataService.loggedInUserInfo.symphonyId && u.isOwner);
      if (isLoggedInUserOwner) {
        contextMenu.push(
          {
            name: 'Add Member',
            action: () => {
              // reset form field
              this.inboxViewComponent = mailboxComponant;
              this.toUsersList = [];
              this.addMemberForm.resetForm();
              this.selectedRoomName = this.selectedRow.subject;
              this.displayAddMemberPopUp = true;
              this.selectedRoomId = this.selectedRow.streamId;
            }
          },
          {
            name: 'Remove Member',
            action: () => {
              this.selectedRoomName = this.selectedRow.subject;
              this.memberToBeRemoved = null;
              this.removeMemberForm.resetForm();
              this.displayRemoveMemberPopUp = true;
              this.selectedRoomId = this.selectedRow.streamId;
            }
          },
        )
        // if there are more than one owner allow owner to remove himself
        if (mailboxComponant.sympChatViewComp.allUsersSymphony
          .filter(u => u.isOwner).length > 2) {
          contextMenu.push(
            {
              name: 'Remove Yourself',
              action: () => {
                this.loggedInUser;
                this.selectedRoomId = this.selectedRow.streamId;
                this.getUserSymphonyId(this.loggedInUser, this.removeMember);
              }
            }
          );
        };
      } else { // only participants other than owner can remove self
        contextMenu.push(
          {
            name: 'Remove Yourself',
            action: () => {
              this.loggedInUser;
              this.selectedRoomId = this.selectedRow.streamId;
              this.getUserSymphonyId(this.loggedInUser, this.removeMember);
            }
          }
        );
      }
    }

    return contextMenu;

  }
  // add or remove member from chat room
  actionOnChatRoom(action: string, actionForm) {
    if (actionForm && !actionForm.valid) {
      return;
    }
    switch (action) {
      case 'add':
        this.getRoomMember();
        break;
      case 'remove':
        this.getUserSymphonyId(this.memberToBeRemoved.id, this.removeMember);
        break;
      case 'cancel':
        this.displayAddMemberPopUp = false;
        this.displayRemoveMemberPopUp = false;
        break;
    }
  }

  getUserSymphonyId(selectedSoeId: string, callback: Function) {
    // cant remove user if he is only owner
    if (this.isLoggedInUserOnlyOwnerOtherThanbot()
      && selectedSoeId === this.userDataService.loggedInUserInfo.userId) {
      this.onlyOwnerOfChat = true;
      this.onlyOwnerOfChatName = this.memberToBeRemoved && this.memberToBeRemoved.text ? this.memberToBeRemoved.text : "";
      return;
    }
    console.log('get user symphony id');
    let reqParam = {};
    if (selectedSoeId == undefined || selectedSoeId == null || selectedSoeId == '') {
      let memToRemTemp: any;
      if (this.memberToBeRemoved != undefined && this.memberToBeRemoved != null) {
        memToRemTemp = this.memberToBeRemoved;
        memToRemTemp = memToRemTemp.trim();
      }
      const req = { memberSoeId: memToRemTemp, memberType: 'CorpEmail' };
      let id;
      this.symphonyChatServiceService.getUserSymphonyIdByType(req).subscribe(res => {
        id = res;
        selectedSoeId = id.payload.ssoId;
        reqParam['memberSoeId'] = selectedSoeId;
        this.symphonyChatServiceService.getUserSymphonyId(reqParam).subscribe(
          (data: Object) => {
            console.log(data);
            let userSymphonyId = data['payload']['id'];
            callback.call(this, userSymphonyId);
          }, (error) => {
            console.log(error);
          }
        );
      });
    } else {
      reqParam['memberSoeId'] = selectedSoeId;
      this.symphonyChatServiceService.getUserSymphonyId(reqParam).subscribe(
        (data: Object) => {
          console.log(data);
          let userSymphonyId = data['payload']['id'];
          callback.call(this, userSymphonyId);
        }, (error) => {
          console.log(error);
        });
    }
  }
  isLoggedInUserOnlyOwnerOtherThanbot() {
    // if logged in user is only owner other than bot user cant remove self
    if (this.inboxViewComponent && this.inboxViewComponent.sympChatViewComp &&
      this.inboxViewComponent.sympChatViewComp.allUsersSymphony) {
      const isLoggedInUserOwner = this.inboxViewComponent.sympChatViewComp.allUsersSymphony
        .some(u => u.symphonyId === +this.userDataService.loggedInUserInfo.symphonyId && u.isOwner);
      if (isLoggedInUserOwner && this.inboxViewComponent.sympChatViewComp.allUsersSymphony
        .filter(u => u.isOwner).length === 2) {
        return true;
      }
    } else {
      return false;
    }
  }

  isBotMember() {
    if (this.inboxViewComponent && this.inboxViewComponent.sympChatViewComp &&
      this.inboxViewComponent.sympChatViewComp.allUsersSymphony) {
      return this.inboxViewComponent.sympChatViewComp.allUsersSymphony
        .some(u => u.symphonyId === +this.userDataService.loggedInUserInfo.symphonyConfig.symphonyBotMemberId);
    } else {
      return false;
    }
  }
  isBotOwner() {
    if (this.inboxViewComponent && this.inboxViewComponent.sympChatViewComp &&
      this.inboxViewComponent.sympChatViewComp.allUsersSymphony) {
      return this.inboxViewComponent.sympChatViewComp.allUsersSymphony
        .some(u => u.symphonyId === +this.userDataService.loggedInUserInfo.symphonyConfig.symphonyBotMemberId
          && u.isOwner);
    } else {
      return false;
    }
  }
  getRoomMember(): void {
    if (this.inboxViewComponent) {
      this.inboxViewComponent.showSpinner = true;
      console.debug(":SPINNER:: " + this.inboxViewComponent.showSpinner + " ::symphony-grid-view.getRoomMember");
    }
    this.displayAddMemberPopUp = false;
    this.memebersListArray = [];
    this.toUserSoeIds = [];
    this.grpListArray = [];
    this.externalSymphonyIds = [];
    let response = [];
    let symphonyExternalIdsRes = [];
    this.toUsersList.forEach(toUser => {
      // service call only if its group
      if (toUser.id == null) {
        response.push(this.groupAdminService.getGroupDataEntitlement(toUser.text));
        this.grpListArray.push(+toUser.value);
      } else if (toUser.id !== "") { // internal soeid
        this.toUserSoeIds.push(toUser.id);
        this.memebersListArray.push(toUser.id);
      } else { // external id to check if valid onboarded symphonyid
        const req = { memberSoeId: toUser.email, memberType: 'CorpEmail' }
        /* this.symphonyChatServiceService.getUserSymphonyIdByType(req).subscribe((res:any)=>{
          if (res && res.payload && res.payload.id)
          this.externalSymphonyIds.push(res.payload.id);
        }) */
        symphonyExternalIdsRes.push(this.symphonyChatServiceService.getUserSymphonyIdByType(req));
      }

    });
    if (response.length > 0) {  // wait till call for all groups complete
      let symphonyIdsBySoeIdres = [];
      symphonyIdsBySoeIdres = symphonyIdsBySoeIdres.concat(symphonyExternalIdsRes);
      forkJoin(...response).subscribe((soeids: any) => {
        console.log(soeids);
        this.memebersListArray = soeids.flat().map(sid => sid.userId).concat(this.toUsersList.filter(tu => tu.id != null).map(tu => tu.id));
        // this.saveChatRoom(this.memebersListArray);
        this.memebersListArray.forEach(mlSoeId => {
          let reqParam = {};
          reqParam['memberSoeId'] = mlSoeId;
          symphonyIdsBySoeIdres.push(this.symphonyChatServiceService.getUserSymphonyId(reqParam));
        });
        let AddMemberRes = [];
        // get soeids of all members in grp as well as only soeds and external ids
        forkJoin(...symphonyIdsBySoeIdres).subscribe((res: any) => {
          let sympIdsArray = res.filter(rs => rs.status).flat().flat().map(sy => sy.payload).map(sy => sy.id);
          sympIdsArray.forEach(symId => {
            let reqParam = {};
            reqParam['memberSymphonyId'] = symId;
            reqParam['streamId'] = this.selectedRow.streamId;
            AddMemberRes.push(this.symphonyChatServiceService.addMember(reqParam));
          });
          // call back to show success message
          forkJoin(...AddMemberRes).subscribe((res: any) => {
            this.actionOnChatRoom('cancel', null);
            this.showResponseMessage(null, "Members Added");
            this.addMemberToChatroomQMA(null);
          });
        });
      });
    } else { // no grp involved only soeids
      // Add only soeids no group
      let symphonyIdsRes = [];
      let AddMemberRes = [];

      this.memebersListArray.forEach(mlSoeId => {
        let reqParam = {};
        reqParam['memberSoeId'] = mlSoeId;
        symphonyIdsRes.push(this.symphonyChatServiceService.getUserSymphonyId(reqParam));
      });
      symphonyIdsRes = symphonyIdsRes.concat(symphonyExternalIdsRes);
      forkJoin(...symphonyIdsRes).subscribe((sympIds: any) => {
        // Filter out the id which are not symphony enabled.
        let sympIdsArray = sympIds.filter(sy => sy.status).flat().flat().map(sy => sy.payload).map(sy => sy.id);

        if (sympIdsArray.length > 0) {
          sympIdsArray.forEach(symId => {
            let reqParam = {};
            reqParam['memberSymphonyId'] = symId;
            reqParam['streamId'] = this.selectedRow.streamId;
            AddMemberRes.push(this.symphonyChatServiceService.addMember(reqParam));
          });
          // call back to show success message
          forkJoin(...AddMemberRes).subscribe((res: any) => {
            console.log(this.memebersListArray);
            this.actionOnChatRoom('cancel', null);
            this.showResponseMessage(res[0], "Members added");
            this.addMemberToChatroomQMA(null);
          });
        } else {
          this.actionOnChatRoom('cancel', null);
          this.showResponseMessage(null, "Doesn't have Symphony Access");
        }
      });
      // this.saveChatRoom(this.memebersListArray);
    }


  }
  addMember(userSymphonyId: string) {
    let reqParam = {};
    reqParam['memberSymphonyId'] = userSymphonyId;
    reqParam['streamId'] = this.selectedRoomId;
    this.symphonyChatServiceService.addMember(reqParam).subscribe(
      (data: Object) => {
        console.log(data);
        this.actionOnChatRoom('cancel', null);
        this.showResponseMessage(data);
        this.addMemberToChatroomQMA(data);
      },
      (error) => {
        console.log(error);
      }
    );
  }
  removeMember(userSymphonyId: string) {
    this.displayRemoveMemberPopUp = false;
    if (this.inboxViewComponent) {
      this.inboxViewComponent.showSpinner = true;
      console.debug(":SPINNER:: " + this.inboxViewComponent.showSpinner + " ::symphony-grid-view.removeMember");
    }
    let reqParam = {};
    reqParam['memberSymphonyId'] = userSymphonyId;
    reqParam['streamId'] = this.selectedRoomId;
    this.symphonyChatServiceService.removeMember(reqParam).subscribe(
      (data: Object) => {
        console.log(data);
        this.actionOnChatRoom('cancel', null);
        this.showResponseMessage(data, "Member removed");
        this.removeMemberToChatroomQMA(data);
      },
      (error) => {
        console.log(error);
      }
    );
  }
  showResponseMessage(data: Object, messagetoShow?: any) {
    // this.addMemberToChatroomQMA(data);
    if (this.inboxViewComponent) {
      this.inboxViewComponent.showSpinner = false;
      console.debug(":SPINNER:: " + this.inboxViewComponent.showSpinner + " ::symphony-grid-view.showResponseMessage");
    }
    this.displayResponse = true;
    this.responseMessage = messagetoShow;
  }
  getFilteredToCCUsers(query) {
    let filtered = [];
    this.allToCcDBUser.forEach(record => {
      if ((record.text.toLowerCase().indexOf(query.toLowerCase()) >= 0) || (record.id.toLowerCase().indexOf(query.toLowerCase()) >= 0)) {
        filtered.push(record);
      }
    });
    this.allActiveGroups.forEach(record => {
      if ((record.text.toLowerCase().indexOf(query.toLowerCase()) >= 0)) {
        filtered.push(record);
      }
    });
    return filtered;
  }

  filterUsers(event) {
    // reset onlyowner flag
    this.onlyOwnerOfChat = false;
    this.onlyOwnerOfChatName = "";
    let query = event.query;
    this.filteredToUsersMultiple = this.getFilteredToCCUsers(query);
  }
  loadGroups() {
    this.userDataService.LocalGetLoginUserInfo().subscribe(loginUserInfo => {
      this.isWebSocketRedesignEnable = loginUserInfo.cvWebsocketConfig.isWebSocketRedesignEnable;
      this.citiDomains = loginUserInfo.citiDomainList;
      const groups = loginUserInfo.myGroups;
      this.selectedGroupList = [];
      this.loggedInUser = loginUserInfo.userId;
      this.loggedInUserObj = loginUserInfo;
      groups.forEach(element => {
        this.selectedGroupList.push({ 'name': element.groupName });
      });
      this.selectedGroup = this.selectedGroupList[0];
      loginUserInfo.allToCcDBUsers.forEach(toCcBccUsers => {
        let individualRecipent: AllToCcDBUser = { ...toCcBccUsers };
        individualRecipent.email = individualRecipent.value;
        this.allToCcDBUser.push(individualRecipent);
      });
    });
  }

  notifyUnreadSymphonyChats(symphonyNotificationList, mailboxComponant) {
    if (!this.inboxViewComponent) {
      this.inboxViewComponent = mailboxComponant;
    }
    try {
      let selectedRow = this.inboxViewComponent.gridApi.getSelectedRows()[0];
      if (!this.inboxViewComponent.gridApi.getSelectedRows()[0]) {
        return;
      }
      symphonyNotificationList.forEach((nl: any) => {
        // dont updated selected row
        if (selectedRow.symphonyStreamId === nl.conversationId) {
          return;
        }
        let chatToFindInGrid = this.inboxViewComponent.rowData.find(rd => rd.symphonyStreamId === nl.conversationId)
        const rowNode = this.inboxViewComponent.gridApi.getRowNode(chatToFindInGrid._id + '_' +
          chatToFindInGrid.workflows[0].assignedGroupId + '_' + chatToFindInGrid.workflows[0].direction);
        if (rowNode) {
          let chatRowData = rowNode.data;
          if (chatRowData && chatRowData.readBy) {
            // mark as unread
            chatRowData.readBy = []
          }
          rowNode.setData(chatRowData);
          // this.updateRowDataBySreamId(nl.conversationId, chatRowData);
        }
      });

    }
    catch (exc) {
      console.log("Exceptiion in notification")
    }

  }

  updateChatRowTimeStamp(chatRes) {
    const rowNode = this.gridApi.getRowNode(chatRes.payload[0].stream.streamId);
    if (!rowNode) {
      return;
    }
    let chatRowData = rowNode.data;
    if (chatRes && chatRes.payload && chatRes.payload.length > 0) {
      if (rowNode) {
        if (new Date(chatRes.payload[0].timestamp) > this.gridLoadTimeStampPrev) {
          // update unread chat flag
          chatRowData.isContainsUnreadChat = true;
          rowNode.setData(chatRowData);
          this.updateRowDataBySreamId(chatRes.payload[0].stream.streamId, chatRowData);
        }

      }
    }
  }
  getRowNodeId(data) {
    if (data && data.streamId) {
      return data.streamId;
    }
  };

  getSymphonyEntitlement() {
    this.symphonyChatServiceService.getSymphonyEntitlement().subscribe((entitlement: any) => {
      this.symphonyEntitlement = entitlement;
    });
  }
  markChatAsRead() {
    // selected row mark as  read
    let rowNode = this.gridApi.getRowNode(this.selectedRow.streamId);
    let selectedRowData = rowNode.data;
    selectedRowData.isContainsUnreadChat = false;
    rowNode.setData(selectedRowData);
    this.updateRowDataBySreamId(this.selectedRow.streamId, selectedRowData);
  }
  updateRowDataBySreamId(streamid, updatedData) {
    let dataToupdae = this.inboxViewComponent.rowData.find(rd => rd.symphonyStreamId === streamid);
    dataToupdae = updatedData;
  }
  onKeyUp(event: KeyboardEvent, field) {
    let extEmail: boolean = false;
    // this.closeFlagInvalid = false;
    if (event.key === "Enter") {
      let tokenInput = event.srcElement as any;
      let email = tokenInput.value.trim();
      // C153176-4930: refactoring the processing of email input to separate method 'handleEmail'
      extEmail = this.isExternalEmailInToCc(email);
      this.handleEmail(tokenInput, email, field);
    }
    //if (extEmail)
    // this.secure.emit(true);
    // else
    // this.secure.emit(false);
  }
  isExternalEmailInToCc(email): boolean {
    let isExternal: boolean = false;
    if (!AppUtils.isUndefinedOrNull(email)) {

      // C153176-5006 : If User enter soeid who has access to QMA application
      let emailList = email.split(';');
      emailList.forEach(emailValue => {
        emailValue = emailValue.trim();
        const appUser = this.allToCcDBUser.find(user => user.id === emailValue);
        if (emailValue && !AppUtils.isCitiDomainEmail(emailValue, this.citiDomains) && !appUser) {
          isExternal = true;
        }
      });
    }
    return isExternal;
  }

  handleEmail(tokenInput, Temail, field) {
    this.filteredToUsersMultiple = [];
    Temail = Temail.split(';');
    let invalid = [], email = [];
    let isNonQmaCitiUser: boolean = false;
    email = Temail.filter(value => {
      value = value.trim();

      //test and extract email from <foo@bar.com>
      if (/\<([^\>]+)\>/g.test(value)) {
        let tempArr = /\<([^\>]+)\>/g.exec(value);
        value = tempArr[1];
      }

      // C153176-5006 : If User enter soeid who has access to QMA application
      const appUser = this.allToCcDBUser.find(user => user.id === value);
      if (appUser) {
        return true;
      }

      //check for non QMA citi user
      /*  isNonQmaCitiUser = this.validateNonQmaCitiUserSoeId(value);
       if (isNonQmaCitiUser) {
         return true;
       } */
      // Push invalid email or soied to display invalid message.
      if (value !== "" && !this.email_regex.test(value)) { // C153176-5262: refactor regex
        invalid.push(value);
        return false;
      } else {
        return true;
      }
    });
    /* if (invalid.length > 0) {
      tokenInput.value = "";
      this.closeFlagInvalid = true;
      setTimeout(() => { this.closeFlagInvalid = false; }, 3000);
    } */

    if (email.length) {
      email.forEach(async element => {
        element = element.trim();
        if (/\<([^\>]+)\>/g.test(element)) {
          let tempArr = /\<([^\>]+)\>/g.exec(element);
          element = tempArr[1];
        }
        if (element !== "") {
          let item = new AllToCcDBUser();

          // C153176-5006 : If User enter soeid who has access to QMA application
          const appUser = this.allToCcDBUser.find(user => user.id === element);
          if (appUser) {
            item = { ...appUser };
          }

          else {
            item = { text: element, value: element, email: element, id: '', active: false };
          }
          if (!this.toUsersList) {
            this.toUsersList = [];
          }
          switch (field) {
            case "to":
              if (!this.isDuplicate(item, 'to'))
                this.toUsersList.push(item);
              /*   this.addMemberForm.control.patchValue({'chatMember':item}); */
              this.addMemberForm.control.patchValue({ toUsersList: this.toUsersList });
              tokenInput.value = "";
              break;

          }
        }
      });

    }
  }
  isDuplicate(item, toCcBcc) {
    let found = false;
    switch (toCcBcc) {
      case 'to':
        this.toUsersList.forEach(element => {
          if (element.email === item.email) {
            found = true;
          }
        });
        break;
      /* case 'cc':
        this.ccUsersList.forEach(element => {
          if (element.email === item.email) {
            found = true;
          }
        });
        break; */
      /* case 'bcc':
        this.bccUsersList.forEach(element => {
          if (element.email === item.email) {
            found = true;
          }
        });
        break; */
    }
    return found;
  }
  getUserData() {
    let groupNameTmp = [];
    groupNameTmp.push({ label: 'Select', value: '' });
    this.userDataService.LocalGetLoginUserInfo().subscribe(loginUserInfo => {
      //Get All groups
      loginUserInfo.allActiveGroups.forEach(activeGroup => {
        this.allActiveGroups.push(activeGroup);
      });
      this.dataCache.setPageSize(loginUserInfo, false);
      // this.loadGridData();
    });

  }

  loadGridData() {
    if (!this.inboxService) {
      console.log("Invalid InboxService, skipping loading grid data!");
      return;
    }
    if (this.inboxService.showSpinner) {
      this.showSpinner = false;
      console.debug(":SPINNER:: " + this.inboxViewComponent.showSpinner + " ::symphony-grid-view.loadGridData");
    }
    //C153176-4796 reset check-before-load flag as it has been consumed by the caller of this method.
    /*  this.inboxService.checkBeforeLoad = true;
     this.inboxService.resetRequest = true; */
    this.dataCache.requestGridData();
  }

  subscribeToWebSocketUpdate() {
    this.webSocketSubscription = this.wsService.getwebSocketUpdate().subscribe(
      (response: MessageEvent): any => {
        try {
          if (this.isWebSocketRedesignEnable) {
            if (!response.data) {
              return;
            }
            let wsData = JSON.parse(response.data);
            if (!wsData.symphonyNotificationList) {
              return;
            }
            //  this.notifyUnreadSymphonyChats(wsData.symphonyNotificationList);
          }
        }
        catch (exception) {
          console.log(exception);
        }
      }
    );
  }
  firstDataRendered(params) {
    this.showSpinner = false;
    console.debug(":SPINNER:: " + this.inboxViewComponent.showSpinner + " ::symphony-grid-view.firstDataRendered");
  }

  processChatRoomSelection() {
    this.selectedMail = null;
    this.inqData = null;
    let selectedChatSymphony = $.extend(true, {}, SelectedMailModel);
    let selectedChatRequest = $.extend(true, {}, SelectedMailRequest);
    selectedChatRequest.streamId = this.selectedRow.streamId;
    selectedChatSymphony.inquiry.subject = this.selectedRow.subject;
    selectedChatSymphony.inquiry.recipientGrps = this.selectedRow.assigendgroup;
    selectedChatSymphony.inquiry.recipientSoeIds = this.selectedRow.toSoeIds;
    this.selectedMail = { ...selectedChatSymphony };
    this.inqData = { ...selectedChatRequest };
    this.cd.detectChanges();
  }

  onAttachment(event) {

  }
  onNote(event) {

  }
  onAudit(event) {

  }
  clearChatText() {

  }

  showSympCreateChatPopup() {
    if (!this.sympcrtChatComp) {
      return;
    }
    this.sympcrtChatComp.displayChatRoomPopup();
  }


  onBodyScroll(params) {
    /*  const allRowsRendered = params.api.rowRenderer.rowModel.rowsToDisplay.length > 0
       ? params.api.rowRenderer.rowModel.rowsToDisplay.filter(rd => !rd.alreadyRendered) : true;
     let allLeafChildren = this.getLength(params.api.clientSideRowModel.rootNode.allLeafChildren);
     let displayLength = this.getLength(params.api.rowRenderer.rowModel.rowsToDisplay);
     //console.debug('onBodyScroll, first/lastRenderedRow=',
     //  params.api.rowRenderer.firstRenderedRow, params.api.rowRenderer.lastRenderedRow,
     //  ', displayLength, allLeafChildren, start/endPage=',
     //  displayLength, allLeafChildren, this.dataCache.startPage, this.dataCache.endPage);
     if ((displayLength && params.api.rowRenderer.lastRenderedRow === displayLength - 1) ||
       (this.dataCache.endPage > 0 && params.api.rowRenderer.lastRenderedRow >=
         this.dataCache.endPage * this.dataCache.defaultPageSize - 1)) { // C153176-4986: pagination boundary fix
       this.requestPage(false, allLeafChildren - 1);
     } else if (displayLength && params.api.rowRenderer.firstRenderedRow == 0) {
       this.requestPage(true, 0);
     } */
  }
  addMemberToChatroomQMA(data) {
    let existingGroup = this.selectedRow.mailboxGridRow.groupList;
    let addedGroups = this.grpListArray;
    let allGroups = [];
    if (existingGroup) {
      allGroups = existingGroup;
    }
    if (addedGroups) {
      addedGroups.forEach(ag => {
        allGroups.push(ag);
      })
    }
    allGroups = _.uniq(allGroups);
    const reqObj = {
      streamId: this.selectedRow.streamId
    };
    let allQMAUsers = [];
    let externalUseList = []
    this.symphonyChatServiceService.getSymphonyMemberLists(reqObj).subscribe((ml: any) => {
      let addedMembersSymphonyIds = ml.members.map(user => user).map(user => user.user).map(user => user.userId);
      console.log(ml);
      addedMembersSymphonyIds.forEach(symId => {
        let data = this.loggedInUserObj.userSymphonyIdToUserMap[symId.toString()];
        if (data) {
          allQMAUsers.push(data.soeId);
        } else {
          if (symId) {
            let member = ml.members.map(user => user).map(user => user.user).find(m => m.userId === symId)
            if (member) {
              externalUseList.push(member.email);
            }

          }

        }
        console.log(data);
      });

      const reqObjAddMembers = {
        symphonyStreamId: this.selectedRow.streamId,
        chatroomName: this.selectedRow.subject,
        inquiryId: this.selectedRow.mailboxGridRow._id,
        allQmaUser: allQMAUsers,
        groupList: allGroups,
        externalUserList: externalUseList
      }
      this.symphonyChatServiceService.addMemberToChatroom(reqObjAddMembers).subscribe(res => {
        console.log(res);
      });
    });


  }

  removeMemberToChatroomQMA(data) {
    let existingGroup = this.selectedRow.mailboxGridRow.groupList;
    let allGroups = [];
    if (existingGroup) {
      allGroups = existingGroup;
    }
    allGroups = _.uniq(allGroups);
    const reqObj = {
      streamId: this.selectedRow.streamId
    };
    let allQMAUsers = [];
    let externalUseList = []
    this.symphonyChatServiceService.getSymphonyMemberLists(reqObj).subscribe((ml: any) => {
      let addedMembersSymphonyIds = ml.members.map(user => user).map(user => user.user).map(user => user.userId);
      console.log(ml);
      addedMembersSymphonyIds.forEach(symId => {
        let data = this.loggedInUserObj.userSymphonyIdToUserMap[symId.toString()];
        if (data) {
          allQMAUsers.push(data.soeId);
        } else {
          if (symId) {
            externalUseList.push(symId.toString());
          }

        }
        console.log(data);
      });

      const reqObjAddMembers = {
        inquiryId: this.selectedRow.mailboxGridRow._id,
        allQmaUser: allQMAUsers,
        groupList: allGroups,
        externalUserList: externalUseList
      }
      this.symphonyChatServiceService.removeMemberToChatroom(reqObjAddMembers).subscribe(res => {
        console.log(res);
      });
    });


  }



  // C153176-5106: taking into account request filter in setting the total count
  setGridTotalCount(count, unReadCount): void {
    let filter = this.dataCache.getRequestFilter(this.dataCache.requestObj);
    if (!filter || filter.type !== 'readBy' || unReadCount === null || unReadCount === undefined) {
      this.initialCount = count;
      return;
    }
    let hasRead = filter.value;
    if (hasRead) {
      // derive READ count
      this.initialCount = Math.max(0, count - unReadCount);
    } else {
      // set UNREAD count
      this.initialCount = unReadCount || 0;
    }
  }
  setgridDetailsObject(tabName, criteria, viewType, selInq = null) {
    this.gridDetailsObject.name = tabName;
    this.gridDetailsObject.criteria = criteria
    this.gridDetailsObject.viewType = viewType
    this.gridDetailsObject.selInq = selInq ? selInq : this.gridDetailsObject.selInq;
  }

  retrieveGetGridViewResponse(gridViewDataModel: GridViewDataModel, requestObj, columnUpdateFlag = true) {
    // set initial count
    if (gridViewDataModel && gridViewDataModel.totalRecords !== undefined && requestObj.pageNum === 1) {
      this.setGridTotalCount(gridViewDataModel.totalRecords, gridViewDataModel.totalUnreadRecords); // C153176-5106 refactoring
      // C153176-5107: notified totalUnreadRecords
      // this.tabDataService.setUnReadCount(this.strMailboxView, gridViewDataModel.totalUnreadRecords);
      let initialUnReadCountAtUI = 0;

    }
    /*  if (requestObj && requestObj.isChartView) {
       this.isChartView = requestObj.isChartView;
     } */
    /* if (requestObj && requestObj.isRequestFromMailBoxStats) {
      this.isDashboardInlineView = true;
    } */
    // C153176-4447: set searchView flag to true if the request is global search or adv search
    // this.searchView = (requestObj && (requestObj.advanceSearchData || requestObj.solrSearchText)) ? true : false;
    // call to update Pagination panel
    this.updatePaginationPanel({ CurrentPage: requestObj.pageNum });
    // restore previously saved Column Configuration
    //console.log("From DB" + JSON.parse(gridViewDataModel.columnConfig.columnConfig).columnConfig)
    let inquiryList = gridViewDataModel.inquiryDataList;
    if (!inquiryList || inquiryList.length === 0) {
      inquiryList = [];
      // C153176-4300 -- Conversation View should be empty
      this.selectedMail = null;
      this.inqData = null; // C153176-4660 reset request data
    }
    this.finalCriteria = gridViewDataModel.finalCriteria;
    // save criteria fields for MyView
    if (this.viewType === -1) {
      this.myViewCriteria = gridViewDataModel.finalCriteria;
      this.myViewDefaultCriteria = gridViewDataModel.defaultUserCriteria;
    }
    // C153176-5081: cache finalUICritieria
    this.finalUICriteria = gridViewDataModel.finalUICriteria;
    // apply filter
    /*  inquiryList = this.inboxViewFilters.filterGridViewData(inquiryList,
       requestObj, this.dataCache.getRequestFilter(requestObj), gridViewDataModel, this.assignedGroups, this.inboxService, this.loginUserInfo.userId);
    */  // to update escalation reason and isEscalated flag
    let escalationReasonStart = performance.now();


    let escalationReasonEnd = performance.now();
    console.debug("Call to generate escalation reason took " + (escalationReasonEnd - escalationReasonStart) + " milliseconds.");
    let additionalClmsStart = performance.now();
    /*  inquiryList.forEach(inq => {
       inq.cheveron = "";
       inq.cheveron_1 = "";
       inq.checkbox = "";
       if(this.strMailboxView == "INBOX" && this.symphonyEnabled) {
         inq.isSymphonyChatGridCol = ""
       }


     }); */
    var additionalClmsEnd = performance.now();
    console.debug("Call to Add additional columns took " + (additionalClmsEnd - additionalClmsStart) + " milliseconds.");

    /* let exportData = inquiryList;
    exportData.forEach(inq => {
      if (inq.followUp) {
        inq.followUp = "Y"
      } else {
        inq.followUp = "N"
      }
      if (inq.age === "") {
        //objMailBoxHelper.calculateAndSetAge(inq);
      }
    }); */
    // this.dataExportToExcel = exportData;

    if (!inquiryList || !inquiryList.length) {
      // stop loading spinner here only if there is no data
      this.inboxService.stopViewLoading();
    }
    // C153176-4885: if this is page 1 and inquiry count is less than initial count, and the initial count is less than page size,
    // update the initial count
    if (requestObj.pageNum === 1 && this.initialCount < requestObj.pageSize && inquiryList && inquiryList.length < this.initialCount) {
      this.setGridTotalCount(inquiryList.length, undefined); // C153176-5106 refactoring - do not pass totalUnreadRecords here since it was already taken into account
    }
    if (this.isWebSocketRedesignEnable) {
      this.setgridDetailsObject(AppUtils.getCurrentTab(), gridViewDataModel.finalCriteria, +gridViewDataModel.viewType);
    }

    return { inquiryList: inquiryList, totalPages: 0 };
  }

  updatePaginationPanel(paginationDetail: any) {
    this.lbCurrentPage = paginationDetail.CurrentPage ? paginationDetail.CurrentPage : this.lbCurrentPage;
    this.lbCurrentPage = Math.max(1, this.lbCurrentPage);
  }

  updateCountLabels(inq = null, isDeleted = null) {
    return;
  }
  inboxStateChanged(params) {
    console.log(params);
  }
  notifyMobileDataReady() {
    return;
  }
  markChatAsUnread(chatRow) {
    if (chatRow && chatRow.isSymphonyChatroom && chatRow.isSymphonyChatroom === "Y") {
      if (!chatRow.readBy) {
        chatRow.readBy = [this.userDataService.loggedInUserInfo.userId];
      } else {
        chatRow.readBy.push(this.userDataService.loggedInUserInfo.userId)
      }
    }
  }
  markChatAsReadInGrid(chatRow, inboxviewComp) {
    if (!this.inboxViewComponent) {
      this.inboxViewComponent = inboxviewComp;
    }
    if (chatRow && chatRow.isSymphonyChatroom && chatRow.isSymphonyChatroom === "Y") {
      if (!chatRow.readBy) {
        chatRow.readBy = [this.userDataService.loggedInUserInfo.userId];
      } else {
        chatRow.readBy.push(this.userDataService.loggedInUserInfo.userId)
      }
      const rowNode = this.inboxViewComponent.gridApi.getRowNode(chatRow._id + '_' +
        chatRow.workflows[0].assignedGroupId + '_' + chatRow.workflows[0].direction);
      if (rowNode) {
        const chatRowData = rowNode.data;
        rowNode.setData(chatRowData);
        this.updateRowDataBySreamId(chatRow.symphonyStreamId, chatRowData);
      }

    }
  }
}

import { Component, OnInit, Output, EventEmitter, Input, Renderer2, Inject, ViewChild } from '@angular/core';
import { SymphonyChatServiceService } from '../service/symphony-chat-service.service';
import { GroupAdminService } from 'src/app/services/group-admin/group-admin.service';
import { UserDataService } from 'src/app/services/user-data.service';
import { AllToCcDBUser } from 'src/app/model/LoginUserInfo/AllToCcDBUser';
import { DOCUMENT } from '@angular/common';
import { AppUtils } from '../../common/utility/appUtil';
import { AllActiveGroup } from 'src/app/model/LoginUserInfo/AllActiveGroup';
declare var window: any;
import { NewMessageService } from '../../services/newMessage/new-message.service';
import { SendInquiry } from 'src/app/model/NewMessage/SaveDraft';
import { ConfirmationService, MessageService } from 'primeng/api';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import { NgForm } from '@angular/forms';
@Component({
  selector: 'app-symphony-create-inquiry',
  templateUrl: './symphony-create-Inquiry.component.html',
  styleUrls: ['./symphony-create-Inquiry.component.scss']
})
export class SymphonyCreateInquiryComponent implements OnInit {
  @Output() streamId = new EventEmitter();
  chatRooms: Array<Object> = [];
  filteredChatRooms: Array<Object> = [];
  inputChatRoomName: string = '';
  inputMemberSoeids: string = '';
  memebersList: string = '';
  @Input()
  displayCreateInqPopUp: boolean = false;
  selectedGroupList: any[];
  selectedGroup: string = '';
  filteredToUsersMultiple: AllToCcDBUser[] = [];
  allToCcDBUser: AllToCcDBUser[] = [];
  userList: AllToCcDBUser[] = [];

  contextmenu = false;
  contextmenuX = 0;
  contextmenuY = 0;
  itemId = "";
  memberSelectionType: string = 'group';

  //add or remove member
  displayAddMemberPopUp: boolean = false;
  memberToBeAdded: AllToCcDBUser = null;
  selectedRoomId: string = '';
  selectedRoomName: string = '';
  //to show response message
  displayResponse: boolean = false;
  responseMessage: string = '';
  loggedInUser: string;
  @Input() listStyle: any;
  groupNames: any[] = [];
  // selected row of grid
  selectedGridRow: any;
  citiDomains: any[];
  @Output() recipientUpdateEvent = new EventEmitter(); // C153176-5896 | On Remove Recipient
  isRecUpdated: boolean = false;
  selectedContactId: string = '';
  toUsersList: AllToCcDBUser[] = [];
  ccUsersList: AllToCcDBUser[] = [];
  bccUsersList: AllToCcDBUser[] = [];
  email_regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  allActiveGroups: AllActiveGroup[] = [];
  extRecipients = [];
  requestTypes = [];
  requestTypeMap = new Map<string, string[]>();
  newInqFrom : string = "";
  createInqSubject : string = "";
  createInqReqType : string = "";
  files: FileList;
  chatConveration : string = "";
  externalAddresses = [];
  externalDomainsEmailIds = [];
 externalDomainsEmails = [];
 externalAddressesAndEmailIds = new Map();
 @ViewChild('createInquiryForm', { static: true }) createInquiryForm: NgForm;
 loggedInUserObj: any;
 //below will allow only letter,digit,space and ',' 
 userNameAndSoeIdRegEx = /^(?:[A-Za-z]+)(?:[A-Za-z0-9 ,]*)$/;
  constructor(private symphonyChatServiceService: SymphonyChatServiceService,
    private groupAdminService: GroupAdminService,
    private userDataService: UserDataService,
    private _renderer2: Renderer2,
    private newMessageService: NewMessageService,
    @Inject(DOCUMENT) private _document: Document, private confirmationService: ConfirmationService) { }

  ngOnInit() {
    if (!window['symphony']) {
      // this.loadSymphonyScript();
    }
//    this.loadUserSymphonyChatRoom();
    this.loadGroups();
    this.getUserData();

  }

  private loadSymphonyScript() {
    window['onloadCallback'] = function () {
      console.log('symphony');
    }
    let script = this._renderer2.createElement('script');
    script.type = "text/javascript";
    script.src = "XXXXX";
    script.dataset.onload = "onloadCallback";
    script.id = "symphony-ecm-sdk";
    script.async = true;
    script.defer = true;
    this._renderer2.appendChild(this._document.head, script);
  }

  openSymphonyView(streamId: string) {
    this.streamId.emit(streamId);
  }

  createChatRoom() {
    console.log('creating chat room' + this.inputChatRoomName);
    if (this.inputChatRoomName && this.inputChatRoomName.length > 0) {
      this.getRoomMember();
    }
  }

  loadUserSymphonyChatRoom() {
    console.log('creating chat room');
    this.symphonyChatServiceService.loadUserSymphonyChatRoom({}).subscribe(
      (data: Array<Object>) => {
        this.chatRooms = [];
        console.log(data);
        data.forEach((v, i, data) => {
          console.log(v);
          if (v['streamType']['type'] == 'ROOM') {
            let name = v['roomAttributes']['name'];
            let id = v['id'];
            let obj = {};
            obj['name'] = name;
            obj['id'] = id;
            this.chatRooms.unshift(obj);
          }
        });
        this.filteredChatRooms = Object.assign([], this.chatRooms);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  displayCreateInqPopup(selectedGridRow) {
    this.reserForm();
    this.selectedGridRow = selectedGridRow;
    // auto pupulate from based on preferences
    this.autoPopulateFrom();
    this.autoPopulateToField();
    this.autoPopulateToFieldSoeids();
    this.populateDataFromGridRow();
    this.displayCreateInqPopUp = true;
    this.getChatConversations();
  }

  populateDataFromGridRow() {
    this.createInqSubject = this.selectedGridRow.subject
  }

  cancelCreateInqPopup() {
    this.displayCreateInqPopUp = false;
  }

  getRoomMember(): void {
    let memebersList: Array<string> = new Array<string>();
    console.log(this.userList);

    if (this.memberSelectionType === 'soeId' && this.userList.length > 0) {
      this.userList.forEach((user) => {
        memebersList.push(user.id);
      });
      this.saveChatRoom(memebersList);
    } else if (this.selectedGroup) {
      this.groupAdminService.getGroupDataEntitlement(this.selectedGroup['name'])
        .subscribe((userList: Array<Object>) => {
          userList.forEach((v, i, arr) => {
            memebersList.push(v['userId']);
          });
          this.saveChatRoom(memebersList);
        },
          err => {
            console.log(err);
          });
    }
  }

  private saveChatRoom(memebersList: Array<string>) {
    /*  this.memebersList = memebersList.toString();
       let reqParam = {};
       reqParam['name'] = this.inputChatRoomName;
       reqParam['roomMembers'] = this.memebersList;
     this.symphonyChatServiceService.createSymphonyChatRoom( this.loggedInUser, reqParam).subscribe((data) => {
       this.CreateInq = false;
       console.log(data);
       data['message'] = 'Room created';
       this.showResponseMessage(data);
       this.loadUserSymphonyChatRoom();
     }, (error) => {
       console.log(error);
       this.CreateInq = false;
     }); */
  }

  loadGroups() {
    this.userDataService.LocalGetLoginUserInfo().subscribe(loginUserInfo => {
      const groups = loginUserInfo.myGroups;
      this.selectedGroupList = [];
      this.loggedInUser = loginUserInfo.userId;
      this.loggedInUserObj = loginUserInfo;
      groups.forEach(element => {
        this.selectedGroupList.push({ 'name': element.groupName });
      });
      this.selectedGroup = this.selectedGroupList[0];
      loginUserInfo.allToCcDBUsers.forEach(toCcBccUsers => {
        let individualRecipent: AllToCcDBUser = { ...toCcBccUsers };
        individualRecipent.email = individualRecipent.value;
        this.allToCcDBUser.push(individualRecipent);
      });
    });
  }

  getFilteredToCCUsers(query) {
    let filtered = [];

    this.allToCcDBUser.forEach(record => {
      if ((record.text.toLowerCase().indexOf(query.toLowerCase()) >= 0) || (record.id.toLowerCase().indexOf(query.toLowerCase()) >= 0)) {
        filtered.push(record);
      }
    });
    this.allActiveGroups.forEach(record => {
      if ((record.text.toLowerCase().indexOf(query.toLowerCase()) >= 0)) {
        filtered.push(record);
      }
    });

    //C153176-5496 | External Recipents for suggestion.
    if (this.extRecipients) {
      this.extRecipients.forEach(record => {
        if ((record.text.toLowerCase().indexOf(query.toLowerCase()) >= 0)) {
          filtered.push(record);
        }
      });
    }

    return filtered;
  }

  filterUsers(event) {
    let query = event.query;
    this.filteredToUsersMultiple = this.getFilteredToCCUsers(query);
  }

  onRightClick(event, roomId, roomName) {
    this.selectedRoomId = roomId;
    this.selectedRoomName = roomName;
    this.contextmenuX = event.clientX;
    this.contextmenuY = event.clientY;
    this.itemId = event.currentTarget.id;
    this.contextmenu = true;
  }

  disableContextMenu() {
    this.contextmenu = false;
  }

  filterItem(value: string) {
    if (!value) {
      this.filteredChatRooms = Object.assign([], this.chatRooms);
    }
    this.filteredChatRooms = Object.assign([], this.chatRooms).filter(
      item => item.name.toLowerCase().indexOf(value.toLowerCase()) > -1
    )
  }

  getUserSymphonyId(selectedSoeId: string, callback: Function) {
    console.log('get user symphonu id');
    let reqParam = {};
    reqParam['memberSoeId'] = selectedSoeId;
    this.symphonyChatServiceService.getUserSymphonyId(reqParam).subscribe(
      (data: Object) => {
        console.log(data);
        let userSymphonyId = data['payload']['id'];
        callback.call(this, userSymphonyId);
        ;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  openAddMemberPopup() {
    this.displayAddMemberPopUp = true;
  }

  actionOnChatRoom(action: string) {
    switch (action) {
      case 'add':
        this.getUserSymphonyId(this.memberToBeAdded.id, this.addMember);
        break;
      case 'remove':
        this.getUserSymphonyId(this.memberToBeAdded.id, this.removeMember);
        break;
      case 'cancel':
        this.displayAddMemberPopUp = false;
        break;
    }
  }

  addMember(userSymphonyId: string) {
    let reqParam = {};
    reqParam['memberSymphonyId'] = userSymphonyId;
    reqParam['streamId'] = this.selectedRoomId;
    this.symphonyChatServiceService.addMember(reqParam).subscribe(
      (data: Object) => {
        console.log(data);
        this.actionOnChatRoom('cancel');
        this.showResponseMessage(data);
        ;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  removeMember(userSymphonyId: string) {
    let reqParam = {};
    reqParam['memberSymphonyId'] = userSymphonyId;
    reqParam['streamId'] = this.selectedRoomId;
    this.symphonyChatServiceService.removeMember(reqParam).subscribe(
      (data: Object) => {
        console.log(data);
        this.actionOnChatRoom('cancel');
        this.showResponseMessage(data);
        ;
      },
      (error) => {
        console.log(error);
      }
    );
  
  }

  showResponseMessage(data: Object) {
    this.displayResponse = true;
    this.responseMessage = data['message'];
  }

  showResponseMessageForSaveInq(data: string) {
    this.displayResponse = true;
    this.responseMessage = data;
  }
  cancelPopup() {

  }

  getUserData() {
    let groupNameTmp = [];
    groupNameTmp.push({ label: 'Select', value: '' });
    this.userDataService.LocalGetLoginUserInfo().subscribe(loginUserInfo => {
      this.citiDomains = loginUserInfo.citiDomainList;
      loginUserInfo.myGroups.forEach(myGroup => {
        groupNameTmp.push({ label: myGroup.groupName, value: myGroup.groupName });
        this.requestTypeMap.set(myGroup.groupName, myGroup.requestTypes);
      });
      this.groupNames = groupNameTmp;
      //Get All groups
      loginUserInfo.allActiveGroups.forEach(activeGroup => {
        this.allActiveGroups.push(activeGroup);
      });
    });

  }

  onGroupNameChange(groupName) {
    this.populateRequestType(groupName.value);
  }
  onSelectRecipient(recipient: any): void {
    console.log("Select Recipent : ", recipient);
    this.onUpdateRecipient(recipient);
  }
  onUpdateRecipient(recipient: any): void {
    try {
      let extEmail = this.isExternalEmailInToCc(recipient.email);
      if (extEmail) {
        this.isRecUpdated = !this.isRecUpdated;
        this.recipientUpdateEvent.emit(this.isRecUpdated);
      }
    } catch (e) {
      console.error("Fail to fetch email : ", e);
      this.isRecUpdated = !this.isRecUpdated;
      this.recipientUpdateEvent.emit(this.isRecUpdated);
    }
  }

  isExternalEmailInToCc(email): boolean {
    let isExternal: boolean = false;
    if (!AppUtils.isUndefinedOrNull(email)) {

      // C153176-5006 : If User enter soeid who has access to QMA application
      let emailList = email.split(';');
      emailList.forEach(emailValue => {
        emailValue = emailValue.trim();
        const appUser = this.allToCcDBUser.find(user => user.id === emailValue);
        if (emailValue && !AppUtils.isCitiDomainEmail(emailValue, this.citiDomains) && !appUser) {
          isExternal = true;
        }
      });
    }
    return isExternal;
  }
  // display contact info on hover to contact
  displayContactInfo(event, value: any) {
    if (value && value.id) {
      this.selectedContactId = value.id.toUpperCase();
    }
  }


  onKeyUp(event: KeyboardEvent, field) {
    let extEmail: boolean = false;
    // this.closeFlagInvalid = false;
    if (event.key === "Enter") {
      let tokenInput = event.srcElement as any;
      let email = tokenInput.value.trim();
      // C153176-4930: refactoring the processing of email input to separate method 'handleEmail'
      extEmail = this.isExternalEmailInToCc(email);
      this.handleEmail(tokenInput, email, field);
    }
    //if (extEmail)
    // this.secure.emit(true);
    // else
    // this.secure.emit(false);
  }


  isDuplicate(item, toCcBcc) {
    let found = false;
    switch (toCcBcc) {
      case 'to':
        this.toUsersList.forEach(element => {
          if (element.email === item.email) {
            found = true;
          }
        });
        break;
      case 'cc':
        this.ccUsersList.forEach(element => {
          if (element.email === item.email) {
            found = true;
          }
        });
        break;
      case 'bcc':
        this.bccUsersList.forEach(element => {
          if (element.email === item.email) {
            found = true;
          }
        });
        break;
    }
    return found;
  }
  handleEmail(tokenInput, Temail, field) {
    this.filteredToUsersMultiple = [];
    Temail = Temail.split(';');
    let invalid = [], email = [];
    let isNonQmaCitiUser: boolean = false;
    email = Temail.filter(value => {
      value = value.trim();

      //test and extract email from <foo@bar.com>
      if (/\<([^\>]+)\>/g.test(value)) {
        let tempArr = /\<([^\>]+)\>/g.exec(value);
        value = tempArr[1];
      }

      // C153176-5006 : If User enter soeid who has access to QMA application
      const appUser = this.allToCcDBUser.find(user => user.id === value);
      if (appUser) {
        return true;
      }

      //check for non QMA citi user
       isNonQmaCitiUser = this.validateNonQmaCitiUserSoeId(value);
       if (isNonQmaCitiUser) {
         return true;
       }
      // Push invalid email or soied to display invalid message.
      if (value !== "" && !this.email_regex.test(value)) { // C153176-5262: refactor regex
        invalid.push(value);
        return false;
      } else {
        return true;
      }
    });
    /* if (invalid.length > 0) {
      tokenInput.value = "";
      this.closeFlagInvalid = true;
      setTimeout(() => { this.closeFlagInvalid = false; }, 3000);
    } */

    if (email.length) {
      email.forEach(async element => {
        element = element.trim();
        if (/\<([^\>]+)\>/g.test(element)) {
          let tempArr = /\<([^\>]+)\>/g.exec(element);
          element = tempArr[1];
        }
        if (element !== "") {
          let item = new AllToCcDBUser();

          // C153176-5006 : If User enter soeid who has access to QMA application
          const appUser = this.allToCcDBUser.find(user => user.id === element);
          if (appUser) {
            item = { ...appUser };
          }
          else if(isNonQmaCitiUser) {
          // check for non QMA and Citi user
          // const nonQmaCitiUser = await this.nonQmaCitiUser(element, field);
          /* if(nonQmaCitiUser){
            item = { ...nonQmaCitiUser };
          } else */ 
          /* { */
           /*  tokenInput.value = "";
             // this.closeFlagInvalid = true;
            setTimeout(() => { 
              // this.closeFlagInvalid = false; 
            }, 3000);
            return; */
         /*  } */
        } else {
          /* const userExternal = { text: element, value: element, email: element, id: '', active: false };
          item = {...userExternal}; */
          item.active = false;
          item.email = element;
          item.text = element;
          item.value = element;
          item.id = '';
          }

          switch (field) {
            case "to":
              if (!this.isDuplicate(item, 'to'))
                this.toUsersList.push(item);
              // this.parentForm.get('toUsers').patchValue(this.toUsersList);
              this.createInquiryForm.control.patchValue({toUsersList:this.toUsersList});
              tokenInput.value = "";
              break;
            case "cc":
              if (!this.isDuplicate(item, 'cc'))
                this.ccUsersList.push(item);
              // this.parentForm.get('ccUsers').patchValue(this.ccUsersList);
              tokenInput.value = "";
              break;
            case "bcc":
              if (!this.isDuplicate(item, 'bcc'))
                this.bccUsersList.push(item);
              // this.parentForm.get('bccUsers').patchValue(this.bccUsersList);
              tokenInput.value = "";
              break;

          }
        }
      });

    }
  }

  onBlur(event: any, field) {
    // C153176-2906, C153176-4930: upon leaving the field, handle case of external email without breaking suggesstion handling
    let tokenInput = event.srcElement as any;
    let email = tokenInput.value.trim();
    // first check whether the current input match any suggestions
    let suggestions = this.getFilteredToCCUsers(email);
    if (!suggestions || !suggestions.length) {
      // C153176-4930: does NOT have any suggestions, check whether it's a valid external email. If yes, take it.
      // C153176-4930: check / accept internal email too
      // this.closeFlagInvalid = false;
      let extEmail = this.isExternalEmailInToCc(email);
      if (email) {
        this.handleEmail(tokenInput, email, field);
        /* if (!this.closeFlagInvalid) {
          // C153176-4930: if the closeFlagInvalid is false, the input is valid email id(s)
          if (extEmail) {
            this.secure.emit(true);
          } else {
            this.secure.emit(false);
          }
          // !important to return now so as to keep the input field
          return;
        } */
      }
      // C153176-4930: in other cases
      event.srcElement.value = "";
    } else {
      // C153176-4930: has suggestions, clear the field
      event.srcElement.value = "";
      // this.resizeMailBody.emit(true);
    }
  }

  getContactStatus(value) {
    if (!isNaN(+value)) {
      return "bold";
    } else if (this.isExternalEmailInToCc(value)) {
      return "external";
    } else {
      return "";
    }
  }

  populateRecipients(groupName): void {
    this.extRecipients = [];
    // this.extRecipients = this.recipientsMap.get(groupName) || [];
  }

  populateRequestType(groupName) {
    this.requestTypes = [];
    this.requestTypes.push({ label: 'Select', value: '' });

    this.requestTypeMap.forEach((value: string[], key: string) => {
      if (!this.isNull(value)) {
        if (key === groupName) {
          for (let i = 0; i < value.length; i++) {
            this.requestTypes.push({ label: value[i], value: value[i] });
          }
        }
      }
    });


  }
  isNull(input) {
    if (input === "" || input === undefined || input == null) {
      return true;
    }
    return false;
  }

  createReqObjForSaveInq() {
    const token = AppUtils.getToken();
    let makerCheckerReq = this.checkIfMakerCheckerRequired();
    return new SendInquiry(null, this.toUsersList, [], this.newInqFrom, "N", this.createInqSubject,
           this.createInqReqType, [], "NEW INQUIRY", false,
      null, false, token, true, 
      "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Open Sans,Helvetica Neue,sans-serif", 
      "16px", null, "", "", null, null, null, null, null, null,
      [], null, null, null, null, null, null, "NEW",[],this.selectedGridRow.streamId);
  }
  createInquiry(createInquiryForm) {
    if (!createInquiryForm.valid) {
      return;
    }
    this.checkExternalAddressesAndEmailIds(this.toUsersList);
    if (this.externalAddresses.length > 1 ) {
      this.displayCreateInqPopUp = false;
      this.showExternalEmailAddressPopupMessage();
    } else {
        this.sendInquiry();
    }
    
  
  }

  sendInquiry() {
    this.displayCreateInqPopUp = false;
    let reqObj = this.createReqObjForSaveInq();
     
    this.newMessageService.saveInquiry(reqObj,
    this.chatConveration).subscribe(result => {
      if(result && result.inquiryId) {
        this.showResponseMessageForSaveInq("Inquiry created successfully");
      } else {
        this.showResponseMessageForSaveInq("Inquiry not created");
      }
      
    });
  }

  

  getChatConversations() {
    // to get email id from stphony entitlement
    this.symphonyChatServiceService.getSymphonyEntitlement().subscribe((entitlement: any) => {
      const streamId = this.selectedGridRow.streamId;
      //const userEmail = this.userDataService.loggedInUserInfo.email;
      const userEmail = entitlement && entitlement.payload && entitlement.payload.email ? entitlement.payload.email : "";
      let reqObj = {
        streamId: streamId,
        userEmail: userEmail,
        limit: 10,
        fromDate :0
      }
      this.symphonyChatServiceService.getChatConversations(reqObj, this.loggedInUserObj).subscribe((chatConv) => {
        console.log(chatConv);
        this.prepareConvAttachement(chatConv)
      });
    });
  }

  prepareConvAttachement(chatConv: any) {
    if (chatConv && chatConv.payload) {
       const msg =chatConv.payload[0].message;
       this.chatConveration = "<div id='signatureDiv'></div><div id='disclaimerDiv'>";
       chatConv.payload.forEach(element => {
        const date = new Date(element.timestamp);
        const month = +date.getMonth() + 1;
        this.chatConveration += "[" + month + "/" +
                            date.getDate() + "/" +
                            date.getFullYear() + " " + this.formatAMPM(date) +  "]" + " " +element.user.displayName + ":"
                            + "</br>"+
                            element.message + "</br>" + "</br>";
       });
       this.chatConveration += "</div>";
       this.uploadConvHistory();
    }
  }

  formatAMPM(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    let strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }
  uploadConvHistory() {
    let data = new FormData();
    data.append("filesTobePwdProtected", "");
    data.append("fileInfo", "");
    data.append("filesAlreadySecured", "");
    data.append("attachmentPwdProtectionFlag", "");
    //data.append('files', ele, "testFile");
    var blob = new Blob(["This is my first text."], {type: "text/plain;charset=utf-8"});
    this.newMessageService.uploadMultipleFiles(blob).subscribe(result => {
      console.log('result of file upload:', result);
    },
      (error) => {
        console.log('error while upload :', error);
      }
    );
  }

  reserForm() {
    this.newInqFrom = "";
    this.toUsersList = [];
    this.createInqReqType = "";
  }
 // auto pupulate from based on preferences
  autoPopulateFrom() {
    const defaultFrmGrp = this.userDataService.loggedInUserInfo.preferences.find(pf=>pf.key === "defaultFromId").value
    if (defaultFrmGrp) {
      this.newInqFrom = this.userDataService.loggedInUserInfo.myGroups.find(mg=>mg.id === +defaultFrmGrp).groupName;
      this.onGroupNameChange({value:this.newInqFrom});
    }
  }
// auto pupulate to based on chat
  autoPopulateToField() {
    this.toUsersList = [];
    const selectedrowGrpNames = this.selectedGridRow.assigendgroup;
    if(selectedrowGrpNames) {
      const selectedGrpNames = selectedrowGrpNames.split(';');
      let selectedGrps: AllActiveGroup[] = this.allActiveGroups.filter(ag =>selectedGrpNames.includes(ag.text));
      selectedGrps.forEach((sg) =>{
        this.toUsersList.push({
            active:sg.active,
            country:sg.country,
            email:sg.email,
            id:sg.id,
            text:sg.text,
            timeZone:sg.timeZone,
            toFrom:'to',
            value:sg.value
        });
      });
       
    }

  }

  autoPopulateToFieldSoeids() {
//    this.toUsersList = [];
    const selectedrowToSoeids = this.selectedGridRow.toSoeIds;
    if(selectedrowToSoeids) {
     
      let selectedSoeids: AllToCcDBUser[] = this.allToCcDBUser.filter(ag =>selectedrowToSoeids.includes(ag.id));
      
      selectedSoeids.forEach((sg) =>{
        this.toUsersList.push({
            active:sg.active,
            country:sg.country,
            email:sg.email,
            id:sg.id,
            text:sg.text,
            timeZone:sg.timeZone,
            toFrom:'to',
            value:sg.value
        });
      });
       
    }

  }
  
  // validate soeid and user name
  validateNonQmaCitiUserSoeId(value: string): boolean {
    return this.userNameAndSoeIdRegEx.test(value);//result;
  }

  showExternalEmailAddressPopupMessage() {
    this.confirmationService.confirm({
      message: '<b>The mail you are going to send has more than one external email domain - </b><br>' + this.externalDomainsEmailIds + '.<br><br><b>Do you want to proceed?</b><br><br>',
      accept: () => {
        // this.sendMail($event, requestObj, content);
        this.sendInquiry();
      },
      key: "externalDomailDialog",
      acceptLabel: 'Yes',
      rejectLabel: 'No'
    });
  }

  checkExternalAddressesAndEmailIds(emailids) {
    this.externalAddresses = [];
    this.externalDomainsEmailIds = [];
    this.externalDomainsEmails = [];
    for (let i = 0; i < emailids.length; i++) {
      if (!AppUtils.isUndefinedOrNull(i) && !AppUtils.isUndefinedNullOrBlank(emailids[i].value) && AppUtils.validateEmail(emailids[i].value) && !AppUtils.isCitiDomainEmail(emailids[i].value, this.citiDomains)) {
        let mailId = emailids[i].value;
        mailId = mailId.substr(mailId.indexOf("@") + 1, mailId.length);
        // Duplicate domain prevention
        if (this.externalAddresses.length == 0 || this.externalAddresses.toString().toLowerCase().indexOf(mailId.toLowerCase()) == -1) {
          let mailValue = emailids[i].value;
          if (mailValue.indexOf("#") != -1) {
            mailValue = mailValue.replace(/#/g, "\\#");
          }
          this.externalDomainsEmailIds.push("<br>" + "<i>" + mailValue + "</i>");
          this.externalAddresses.push(mailId);
          this.externalDomainsEmails.push(mailValue);
        }
      }
      this.externalAddressesAndEmailIds.set('externalAddressesArray', this.externalAddresses);
      this.externalAddressesAndEmailIds.set('externalDomainsEmailIdsArray', this.externalDomainsEmailIds.join("<br>"));
      this.externalAddressesAndEmailIds.set('externalDomainsEmailsArray', this.externalDomainsEmails);
    }
  }

  checkIfMakerCheckerRequired() {
    if(!this.loggedInUserObj.makerCheckerRqd) {
      return false;
    } else if(this.loggedInUserObj.makerCheckerRqd && this.externalAddresses.length > 1) {
      return true;
    }
  }

}

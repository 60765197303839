import { Component, ViewChild, ViewContainerRef, OnDestroy } from "@angular/core";
import { ICellRendererAngularComp } from "@ag-grid-community/angular";

@Component({
  selector: 'checkbox-cell',
  template: `<div><label class="inline mt-0 pt-0 agchecbox"></label>
               <input #input style="display:inline-block;" (blur)="updateRowValue()" (keydown)="onKeyDown($event)" [(ngModel)]="value" type='checkbox' /></div>`
})

export class AgGridCheckboxComponent implements ICellRendererAngularComp, OnDestroy {
  params: any;
  public value: any;
  private fieldname: string;
  //private type: string;
  //isReadonly: boolean = false;

  @ViewChild('input', { read: ViewContainerRef, static: true }) public input;

  agInit(params: any): void {
    this.params = params.data;
    this.fieldname = params.fieldname;
    //this.type = params.type;
    this.value = (this.params[this.fieldname] == true) ? 'checked' : false;

  }

  onKeyDown(event): void {
    if (event.keyCode == 13) {
      this.updateRowValue();
    }
  }

  updateRowValue() {
    this.params[this.fieldname] = this.getValue();
  }

  ngOnDestroy() {
  }
  getValue(): any {
    return this.value;
  }
  refresh(): boolean {
    return true;
  }


}

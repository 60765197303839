/**
 * Highcharts plugin for customizing printing function.
 */
(function (factory) {
    "use strict";

    if (typeof module === "object" && module.exports) {
        module.exports = factory;
    } else {
        factory(Highcharts);
    }
}(function (H) {
    var rel = H.relativeLength;
    H.wrap(H.Chart.prototype, 'print', function () {
        const defaultOptions = H.defaultOptions,
            doc = H.doc,
            Chart = H.Chart,
            addEvent = H.addEvent,
            removeEvent = H.removeEvent,
            fireEvent = H.fireEvent,
            createElement = H.createElement,
            discardElement = H.discardElement,
            css = H.css,
            merge = H.merge,
            pick = H.pick,
            extend = H.extend,
            isTouchDevice = H.isTouchDevice,
            win = H.win,
            userAgent = win.navigator.userAgent,
            SVGRenderer = H.SVGRenderer,
            symbols = H.Renderer.prototype.symbols,
            isMSBrowser = /Edge\/|Trident\/|MSIE /.test(userAgent),
            isFirefoxBrowser = /firefox/i.test(userAgent);
        let t_chart = this,
        origDisplay = [],
        body = doc.body,
        childNodes = body.childNodes,
        printMaxWidth = t_chart.options.exporting.printMaxWidth,
        resetParams,
        handleMaxWidth;

        // Move the chart container(s) to another div
        function moveContainers(moveTo, cloneFlag) {
            (
                t_chart.fixedDiv ? // When scrollablePlotArea is active (#9533)
                [t_chart.fixedDiv, t_chart.scrollingContainer] :
                [t_chart.container]
            ).forEach(function (div) {
              if (div && moveTo) {
                moveTo.appendChild(div.cloneElement(true));
              }
            });
        }

        if (t_chart.isPrinting) { // block the button while in printing mode
            return;
        }

        t_chart.isPrinting = true;
        t_chart.pointer.reset(null, 0);

        fireEvent(t_chart, 'beforePrint');

        let cloned_elems = [];
        let chart_elems = t_chart.fixedDiv ? // When scrollablePlotArea is active (#9533)
            [t_chart.fixedDiv, t_chart.scrollingContainer] :
            [t_chart.container];
        chart_elems.forEach((e) => {
            let cloned = e.cloneNode(true);
            if (cloned) {
                cloned_elems.push(cloned);
            }
        });

        // Handle printMaxWidth
        handleMaxWidth = printMaxWidth && t_chart.chartWidth > printMaxWidth;
        if (handleMaxWidth) {
            resetParams = [t_chart.options.chart.width ? t_chart.options.chart.width : t_chart.chartWidth, undefined, false];
            t_chart.setSize(printMaxWidth, undefined, false);
        }

        // hide all body content
        [].forEach.call(childNodes, function (node, i) {
            if (node.nodeType === 1) {
                origDisplay[i] = node.style.display;
                node.style.display = 'none';
            }
        });
        // Give the browser time to draw WebGL content, an issue that randomly
        // appears (at least) in Chrome ~67 on the Mac (#8708).
        setTimeout(function () {
            // append cloned elements to body
            cloned_elems.forEach((e) => {
                body.appendChild(e);
            });
            // print the body
            win.focus();
            win.print();
            // allow the browser to prepare before reverting
            setTimeout(function () {
                // remove cloned elements from body
                cloned_elems.forEach((e) => {
                    try {
                        body.removeChild(e);
                    } catch(err) {
                        console.log(err);
                    }
                });

                // restore all body content
                [].forEach.call(childNodes, function (node, i) {
                    if (node.nodeType === 1) {
                        node.style.display = origDisplay[i];
                    }
                });

                t_chart.isPrinting = false;

                // Reset printMaxWidth
                if (handleMaxWidth) {
                    //t_chart.setSize.apply(t_chart, resetParams);
                }

                fireEvent(H.Chart, 'afterPrint');

            }, 1000);
        }, 800);
   });
}));

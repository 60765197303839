import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LogoutComponent } from './user/logout/logout.component';
import {ErrorPageComponent} from "./common/error-page/error-page.component";

const routes: Routes = [

  { path: 'logout', component: LogoutComponent },
  { path: 'error', component: ErrorPageComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component } from '@angular/core';
import { ICellRendererAngularComp } from "@ag-grid-community/angular";
import { MultiSelectModule } from 'primeng/multiselect';
import { SelectItem } from 'primeng/api';
import { SuperAdminSetupService } from '../services/super-admin-setup/super-admin-setup.service';
@Component({
  selector: 'app-super-admin-checkbox',
  template: `<p-multiSelect (onChange)='updateValue()' [defaultLabel]="'Select Requests'" [options]="entGrpName" 
  [(ngModel)]="selectedRequestName" [styleClass]="'preference-multiselect UECheckbox multiselectUserEnt'" [style]="{'width':'180px'}" [showToggleAll]="false" 
  [maxSelectedLabels]="4" [baseZIndex]="9999" [appendTo]="'body'" [filter]="false"></p-multiSelect>`,   
})
export class SuperAdminCheckboxComponent implements ICellRendererAngularComp {
  public params: any;
  public gridRoleApi : any;
  public selectedRequestName: any[];
  private fieldname: string;
  public value: any;
  public entGrpName : any[];
  constructor(private superAdminSetupSerivce:SuperAdminSetupService){
  }
  agInit(params: any): void {
    this.params = params;
    this.gridRoleApi = params.api;
    this.fieldname = params.fieldname;    
    this.entGrpName = [      
      { label: 'Request Type', value: 'highLevelRequestTypeMapping'},
      { label: 'Root Cause', value: 'rootCause' },
      { label:'Request Type Root Cause',value:'requestTypeRootCauseMapping'},
      { label: 'Disable Group Level Metadata', value: 'disableGroupLeveMetaData'},
      { label: 'Enable Org Level Metadata', value: 'enableOrgLevelMetaData'},
      { label: 'Enable Inquiry Sub-Status', value: 'orgLevelInquirySubStatusEnabled'}
    ]; 
    this.selectedRequestName = (this.params.value).split(',');
    this.selectedRequestName = this.selectedRequestName.map(function (el) {
      return el.trim();
    });
  }
  updateValue() {
    this.params.data.role = this.getValue();
    this.superAdminSetupSerivce.changeMessage(this.params.data);
  }
  getValue(): any {
      return this.selectedRequestName;
  }

  refresh(): boolean { 
      return true;    
  }

}

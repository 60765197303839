export class NameCount {
    name: string;
    label?: string;
    id?:any;
    count?: number;
    childList?: Array<ListCount>;
    toggle?:boolean;
    constructor(name:string,label:string, id:any, count?:number, childList?:Array<ListCount>,toggle?:boolean) {
        this.name = name;
        this.label = label;
        this.id = id;
        this.count = count;
        this.childList = childList;
        this.toggle = toggle;
    }
}
export class ListCount{
    name:string;
    count:number;
    label?:string;
    constructor(name:string, count?:number, label?:string){
        this.name = name;
        this.label = label;
        this.count = count;
    }
}

import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';


@Pipe({ name: 'date_format' })
export class DateFormatPipe implements PipeTransform {
    DEFAULT_DATE_FORMAT = 'dd/MMM/yy h:mm:ss a';
    dateFormatterMap = new Map<string, string>();
    format : string;

    constructor() {
        this.dateFormatterMap.set('dd/MMM/yy h:mm:ss tt','dd/MMM/yy h:mm:ss a');
        this.dateFormatterMap.set('M/dd/yyyy h:mm:ss tt','M/dd/yyyy h:mm:ss a');
        this.dateFormatterMap.set('dd/M/yyyy h:mm:ss tt','dd/M/yyyy h:mm:ss a');
        this.dateFormatterMap.set('dd/M/yyyy hh:mm:ss','dd/M/yyyy hh:mm:ss');
        this.dateFormatterMap.set('dd/MM/yyyy HH:mm:ss zzzz','dd/MM/yyyy HH:mm:ss zzzz');
        this.dateFormatterMap.set('dd MMM hh:mm a','dd MMM hh:mm a');
    }

    transform(value, formatString): string {
        this.dateFormatterMap.forEach((value: string, key: string) => {
            if(key === formatString)    
                this.format = value;
        });
        if (!this.format) {
            this.format = this.DEFAULT_DATE_FORMAT;
        }
        let datePipe = new DatePipe("en-US");
        try {
            value = datePipe.transform(value, this.format);
            return value;
        } catch(err) {
            // the conversion may fail, in which case original value would be returned
            return value;
        }
    }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from 'rxjs';
import { MessageService } from 'primeng/api';
import { QmaConstant } from '../constant/qma-constant';
import { LoginUserInfo } from '../model/LoginUserInfo/LoginUserInfo';
import * as Rx from "rxjs";

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  })
};
@Injectable({
  providedIn: 'root'
})

export class TaskizeAuthService {
  taskizeEntitlement$ = new Rx.BehaviorSubject({});
  windowUrlTaskize: string;
  windowHandle: Window;
  intervalId : any = null;
  loopCount = 600;
  intervalLength = 100;
  authorizationCode: any;
  baseURL: string;
  isTaskizeAuthenticationSuccessful: boolean = false;
  openTaskizeAuthenticationPopup: boolean = false;
  isTaskizeInquiry: boolean = false;
  private taskizeEntitlement: boolean;

  constructor(private messageService: MessageService,private http: HttpClient) {
    this.baseURL = QmaConstant.BASE_URL + '/qmataskize';
   }

  createOauthWindow (loginUserInfoData : LoginUserInfo) {
    let authUrl = loginUserInfoData.taskizeConfig.auth_url ? loginUserInfoData.taskizeConfig.auth_url :'' 
    let responseCode = loginUserInfoData.taskizeConfig.response_type ? loginUserInfoData.taskizeConfig.response_type: ''
    let clientIdTaskize = loginUserInfoData.taskizeConfig.client_id ?loginUserInfoData.taskizeConfig.client_id : ''
    let redirectUrl = loginUserInfoData.taskizeConfig.redirect_url ?loginUserInfoData.taskizeConfig.redirect_url :''
    this.windowUrlTaskize = authUrl + '?response_type=' + responseCode +'&client_id=' + clientIdTaskize + '&redirect_uri=' + redirectUrl;  
    console.log('taskize callbackUrl' ,  this.windowUrlTaskize);
    return window.open(this.windowUrlTaskize,"_blank","width = 800,height = 800")
 }

 closeOauthWindow(windowHandle) {
    let loopCount = this.loopCount;
    this.intervalId = window.setInterval(() => {
      if (loopCount-- < 0) {
        window.clearInterval(this.intervalId);
        windowHandle.close()
      } else {
        let href : string;
        try {
          href = windowHandle.location.href;
        }
         catch(e) {
          console.log('Authorization Taskize error', e);
        }
        if (href != null ) {
          const currentLocation = new URL(windowHandle.location.href);
          const authorizationCode = currentLocation.searchParams.get("code");
          if(authorizationCode != '' && authorizationCode != null && authorizationCode != undefined){
            // give a get call to QMA backend get taskizeTokenExpiryDate and then close the window after check
            window.clearInterval(this.intervalId);
            this.getTaskizeTokenExpiryDate().subscribe(taskizeData => {
              console.log("taskizeData taskize", taskizeData);
              sessionStorage.setItem('taskizeTokenExpiryTime', taskizeData.taskizeTokenExpiryDate)
              if (taskizeData.taskizeTokenExpiryDate != null && taskizeData.taskizeTokenExpiryDate != '' && taskizeData.taskizeTokenExpiryDate != undefined ) {
                this.messageService.add({ severity: 'success', key: 'qmaToast', summary: '', detail: 'Your authentication to taskize is successful' });
                this.isTaskizeAuthenticationSuccessful = true;
                windowHandle.close()
              } else {
                this.messageService.add({ severity: 'error', key: 'qmaToast', summary: '', detail: 'Your authentication to taskize failed' });
                this.isTaskizeAuthenticationSuccessful = false;
                windowHandle.close();
              }
            },error => {
              this.messageService.add({ severity: 'error', key: 'qmaToast', summary: '', detail: 'Your authentication to taskize failed due to internal error' });
              console.log('taskize error', error)
              this.isTaskizeAuthenticationSuccessful = false;
              windowHandle.close();
          });
          }
        }
      }
    },this.intervalLength)
  }

 checkTaskizeTokenExpiry(loginUserInfoData : LoginUserInfo) {
    let expiryDateTimeFromLoginUserInfo = new Date(loginUserInfoData.taskizeConfig.taskizeTokenExpiryDate)
    let expiryDateTimeLatestFromTaskize = new Date(sessionStorage.getItem('taskizeTokenExpiryTime'))
    let today = new Date();
    let finalExpiryTime;
    if(loginUserInfoData.taskizeConfig.taskizeTokenExpiryDate != '' || loginUserInfoData.taskizeConfig.taskizeTokenExpiryDate != null || loginUserInfoData.taskizeConfig.taskizeTokenExpiryDate != undefined) {
      if(expiryDateTimeLatestFromTaskize.getTime() > expiryDateTimeFromLoginUserInfo.getTime()) {
        finalExpiryTime = expiryDateTimeLatestFromTaskize.getTime();
      } else {
        finalExpiryTime = expiryDateTimeFromLoginUserInfo.getTime();
      }
    } else {
      finalExpiryTime = expiryDateTimeLatestFromTaskize.getTime();
    }
    
    if (today.getTime() > finalExpiryTime) {
      this.messageService.add({ severity: 'warn', key: 'qmaToast', summary: '', detail: 'Your taskize session has expired. Please login again in the taskize window to complete the operation' });
      this.openTaskizeAuthenticationPopup = true;
      this.windowHandle = this.createOauthWindow(loginUserInfoData)
      this.closeOauthWindow(this.windowHandle)
    } else if ((loginUserInfoData.taskizeConfig.taskizeTokenExpiryDate == '' || loginUserInfoData.taskizeConfig.taskizeTokenExpiryDate == null || loginUserInfoData.taskizeConfig.taskizeTokenExpiryDate == undefined) && sessionStorage.getItem('taskizeTokenExpiryTime') == null) {
      this.messageService.add({ severity: 'warn', key: 'qmaToast', summary: '', detail: 'Please login in the taskize window to authenticate' });
      this.openTaskizeAuthenticationPopup = true;
      this.windowHandle = this.createOauthWindow(loginUserInfoData)
      this.closeOauthWindow(this.windowHandle)
    }
  }

  checkTaskizeTokenExpiryForNewInquiry(loginUserInfoData : LoginUserInfo){
    this.checkTaskizeTokenExpiry(loginUserInfoData);
    if(this.openTaskizeAuthenticationPopup){
      return this.isTaskizeAuthenticationSuccessful;
    } else {
      return true;
    }
  }

  getTaskizeTokenExpiryDate(): Observable<any> {
    return this.http.get(this.baseURL + '/getTaskizeTokenExpiryDate',httpOptions);
  }

  setIsTaskizeInquiry(isTaskizeInquiry){
    this.isTaskizeInquiry = isTaskizeInquiry
  }

  checkIsTaskizeInquiry() {
    return this.isTaskizeInquiry
  }
}


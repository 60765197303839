import { Injectable } from '@angular/core';
import { TabDataService } from 'src/app/services/tab-data.service';
import { QmaConstant } from 'src/app/constant/qma-constant';
import { InboxService } from 'src/app/services/inbox.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  })
};
@Injectable({
  providedIn: 'root'
})
export class MicrofrontendInboxService {

  baseURL: string;

  private defaultPageSize = QmaConstant.inboxGridViewType.defaultPageSize;
  startDate: Date;
  endDate: Date;
  inquiriesObjectParam: any [] = [];
  constructor(private tabDataService:TabDataService,private inboxService:InboxService,private http: HttpClient) {
    this.baseURL = QmaConstant.BASE_URL + '/inquiry';
  }

  formRequestObjectOfGrid(tabName: string,inquiryIdfromIframe?,productFamilyFromIframe?,productFamilyFromLoginUser?,numberOfDays?,inquiriesObjectParam?,bussinessStartDate?) {
  // begin - create requestObj to load inbox component
  // viewName should be the tabName if specified.
    let viewName = tabName ? tabName :  this.tabDataService.savedTabData;
    if (viewName == 'Sent') {
      viewName = 'Outbox'; // Business asked to rename Outbox to Sent in UI
    }
    /* C153176-4809:Escalation collapse issue fix for left Navigation */
    if(viewName == 'Escalation'){
      viewName = 'Potential Escalations';
    }
    let requestObj: any = {};
    if (viewName == 'Assigned To Me') {
      requestObj['assignedToMe']  = 'Y'; 
    }
    if (viewName == 'Unassigned') {
      requestObj['unAssignedMessage']  = 'Y'; 
    }
    if(viewName == 'Chat'){
      viewName = 'ChatView';
    }
    requestObj['viewName'] = viewName;
    if (inquiryIdfromIframe && inquiryIdfromIframe.length == 0 && QmaConstant.inboxGridViewType[viewName].viewType) {
      requestObj['viewType'] = QmaConstant.inboxGridViewType[viewName].viewType;
    }

    // server side pagination
    requestObj['pageNum'] = QmaConstant.inboxGridViewType.defaultPageNo;
    requestObj['pageSize'] = this.defaultPageSize;
    requestObj['viewDataType'] = 'microUi'
    let advanceSearchData : any = {}
    if(inquiryIdfromIframe && inquiryIdfromIframe.length > 0) {
      advanceSearchData['inquiryId'] = inquiryIdfromIframe
      requestObj['viewName'] = 'Search';
    }

    let today = new Date();
    today.setHours(23, 59, 59, 999);
    if (numberOfDays != null && numberOfDays != undefined && numberOfDays >= 0 && inquiryIdfromIframe && inquiryIdfromIframe.length == 0) {
      this.startDate = new Date(new Date(bussinessStartDate).setHours(0,0,0,0))
      advanceSearchData['startDt'] = this.startDate;
      advanceSearchData['endDt'] = today;
      this.endDate = today;
    }

    if(productFamilyFromIframe) {
      let assignedGroup = this.getAssignedGroupId(productFamilyFromIframe,productFamilyFromLoginUser);
      advanceSearchData['assignedGroupIdList'] = assignedGroup;
    }

    if(advanceSearchData && Object.keys(advanceSearchData).length !== 0) {
      requestObj['advanceSearchData'] = advanceSearchData
    }
    
    this.inquiriesObjectParam = inquiriesObjectParam;
  // end - create requestObj to load inbox component
    this.inboxService.requestObj = requestObj;
    // set the resetRequest so this will reset the request anew (when user opens a previously opened tab)
    this.inboxService.resetRequest = true;
  }

  onLinkDeLinkEntity(requestObj: any): Observable<any> {
    return this.http.post(this.baseURL + '/linkDelinkEntity', requestObj, httpOptions);
  }

  getAssignedGroupId(productFamilyFromIframe,productFamilyFromLoginUser) {
    let assignedGroupId = []
    let productFamilyFromIframeInArray = productFamilyFromIframe.split(',').map(String)
    let productFamilyForAssignedGroup = Object.entries(productFamilyFromLoginUser).filter((productFamilyLogin) => productFamilyFromIframeInArray.some((productFamilyIframe) => productFamilyIframe == productFamilyLogin[0]));
    productFamilyForAssignedGroup.forEach(prodFamilyData => {
      assignedGroupId.push(prodFamilyData[1])
    });
    return assignedGroupId
  }

  getStartDate() {
    return this.startDate;
  }

  getEndDate() {
    return this.endDate
  }

  getConversationIdForInquiry(currentInquiryId) {
    let conversationIdforInquiryid = []
    if (this.inquiriesObjectParam && this.inquiriesObjectParam.length > 0) {
      this.inquiriesObjectParam.forEach(inquiriesData => {
        if (Number(inquiriesData.inquiryId) == currentInquiryId )
        conversationIdforInquiryid.push(Number(inquiriesData.conversationId))
      })
    }
    return conversationIdforInquiryid;
  }
}

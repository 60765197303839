import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UserAdminService } from 'src/app/services/user-admin/user-admin.service';
import { GroupAdminService } from 'src/app/services/group-admin/group-admin.service';
import { MessageService } from 'primeng/api';
import { OverlayPanel } from "primeng//overlaypanel";
import { AppUtils } from "src/app/common/utility/appUtil";
import { UserDataService } from 'src/app/services/user-data.service';


@Component({
  selector: 'app-user-group-assignment',
  templateUrl: './user-group-assignment.component.html',
  styleUrls: ['./user-group-assignment.component.scss']
})
export class UserGroupAssignmentComponent implements OnInit {
  initalAvailableValue: any = [];
  selectedValue: {};
  initalValue: any = [];
  groupRemove: any = [];
  groupAdd: any = [];
  inputUserGroupsToAdd: any = [];
  inputUserGroupsToRemove: any = [];
  userGroupDisplayList: any = [];
  allGroupDisplayList: any = [];


  @Output() modalClosed = new EventEmitter<boolean>();
  @Input() isOpen = false;
  adminGroupList: any = [];
  inputUserSupervisorGroups: any = [];
  inputUserPeerReviewGroups: any = [];
  inputUserGroupAdminGroups: any = [];
  inputUserGroups: any = [];
  groupListData: any = [];
  showDetails: boolean;
  showSpinner: boolean = false;
  userNotFound = false;
  newUserSoeid = null;
  userDetails: any;
  notFound = false;
  validationError;
  selectedAdminRoles: any = [];
  availableAdminRoles = [];
  errorMessage: string;
  successMessage: string;
  defaultAdminRoles = ["IS Admin", "Prod Support"];
  showGroupName: boolean = false;
  activeFlag: number = 0;
  actTabIdx: number = 0;
  displaySuccess: boolean = false; // for success message
  displayError: boolean = false; // for error message
  userGroupRoleListData: any = [];
  selectedGroup: '';
  selectedRole: any = 'Analyst';
  gUserData: any;

  public infoPreferences: string = '';
  blankStringValidationMsg:string = "UserId can not be blank";
  newUserSoeIdValidationFlag:boolean = false;

  constructor(private userAdminService: UserAdminService, private groupAdminService: GroupAdminService, private messageService: MessageService, private userDataService: UserDataService) { }

  ngOnInit() {
    this.userDataService.LocalGetLoginUserInfo().subscribe(loginUserInfo => {
      this.gUserData = loginUserInfo;
    });

  }

  callLookupSercive() {
    try {
      this.newUserSoeIdValidationFlag=false;
      if (!AppUtils.isUndefinedNullOrBlankStrAfterTrim(this.newUserSoeid)) {
        this.userNotFound = false;
        this.notFound = false;
        this.showSpinner = true;
        console.debug(":SPINNER:: "+ this.showSpinner+" ::user-group-assignment.callLookupSercive");
        this.userAdminService.getUserDataById({ 'userId': this.newUserSoeid.trim() }).subscribe(userDetails => {
          if (!AppUtils.isUndefinedOrNull(this.gUserData) && !AppUtils.isUndefinedOrNull(userDetails)) {
            let isBAISAdmin = false;
            const adminRoles = this.gUserData.adminRoles;
            if (!AppUtils.isUndefinedOrNull(adminRoles)) {
              isBAISAdmin = (adminRoles.indexOf("BA Admin") !== -1 || adminRoles.indexOf("IT Admin") !== -1);
            }
            if (isBAISAdmin) {
              this.getAllDBGroupsList();
            } else {
              this.groupListData = [];
              this.adminGroupList = [];
              this.allGroupDisplayList = [];
              if (this.gUserData.groupRoles && this.gUserData.groupRoles != null) {
                this.gUserData.groupRoles.forEach(roleEle => {
                  if ((roleEle.groupRole.indexOf("Group Admin") !== -1 || roleEle.groupRole.indexOf("Supervisor") !== -1) && !AppUtils.isUndefinedOrNull(roleEle.groupName)) {
                    if (!this.groupListData.includes(roleEle.groupName)) {
                      this.groupListData.push(roleEle.groupName);
                      this.adminGroupList = this.groupListData;
                    }
                  }
                });
              }
            }
            this.showSpinner = false;
            console.debug(":SPINNER:: "+ this.showSpinner+" ::user-group-assignment.callLookupSercive");
            if (userDetails !== null) {
              if (!AppUtils.isUndefinedNullOrBlank(userDetails.active) && userDetails.active === true) {
                this.userDetails = userDetails;
                this.userNotFound = true;

                this.getAdminBasedUserData(userDetails.groupRoles);
                this.populateUserGroups();
                if (this.selectedRole !== "Analyst") {
                  this.groupChangesForInit(this.selectedRole);
                }
              } else {
                this.validationError = "This user is inactive.";
                this.userNotFound = false;
                this.notFound = true;
              }


              // });
            }
          } else {
            this.validationError = "User not found! Please enter a valid User Id.";
            this.userNotFound = false;
            this.notFound = true;
            this.showSpinner = false;
            console.debug(":SPINNER:: "+ this.showSpinner+" ::user-group-assignment.callLookupSercive");
          }

        }, (error) => {
          console.log(error);
          this.errorMessage = `An error occured while searching user`;
          this.showSpinner = false;
          console.debug(":SPINNER:: "+ this.showSpinner+" ::user-group-assignment.callLookupSercive");
        });
        this.userGroupRoleListData = [
          { label: 'Analyst', value: 'Analyst' },
          { label: 'Group Admin', value: 'Group Admin' },
          { label: 'Peer Reviewer', value: 'Peer Reviewer' },
          { label: 'Supervisor', value: 'Supervisor' },
        ];

      } else{
        this.newUserSoeIdValidationFlag=true;
      }
    } catch (error) {
      this.showSpinner = false;
      console.debug(":SPINNER:: "+ this.showSpinner+" ::user-group-assignment.callLookupSercive");
      console.log('Exception in callLookupSercive()' + error.message);
    }
  }

  populateUserGroups() {
    // Clean up of data
    this.inputUserGroupsToAdd = [];
    this.inputUserGroupsToRemove = [];
    this.userGroupDisplayList = [];

    // This is populated from db on search of the user and wont be changed until a new search.
    // The below arrays are used as base reference and do not change at anytime.

    if (this.selectedRole === "Analyst") {
      this.userGroupDisplayList = this.inputUserGroups;
    } else if (this.selectedRole === "Group Admin") {
      this.userGroupDisplayList = this.inputUserGroupAdminGroups;

    } else if (this.selectedRole === "Peer Reviewer") {
      this.userGroupDisplayList = this.inputUserPeerReviewGroups;

    } else if (this.selectedRole === "Supervisor") {
      this.userGroupDisplayList = this.inputUserSupervisorGroups;
    }
    this.userGroupDisplayList = this.sortIgnoreCase(this.userGroupDisplayList);
    this.initalValue = [...this.userGroupDisplayList];

  }

  getAllDBGroupsList() {
    try {
      this.adminGroupList = [];
      this.groupAdminService.getAllActiveDBGroups().subscribe(result => {
        this.adminGroupList = result;
        if (!AppUtils.isUndefinedOrNull(this.adminGroupList)) {
          //Sorting adminGroupList
          const adminGroupListArray: any = [];
          //Sort according to the group name
          this.adminGroupList.forEach(element => {
            if (!AppUtils.isUndefinedNullOrBlank(element)) {
              if (!adminGroupListArray.includes(element.text)) {
                adminGroupListArray.push(element.text);
              }
            }

          });
          const adminGroupSortListArray = this.sortIgnoreCase(adminGroupListArray);
          adminGroupListArray.forEach(element => {
            if (!AppUtils.isUndefinedOrNull(element)) {
              this.allGroupDisplayList.push(element);
            }

          });
        }

      }, (error) => {
        console.log(error);
        this.errorMessage = `An error occured while getting groups`;
      });

    } catch (error) {
      console.log('Exception in getAllDBGroupsList()' + error.message);
    }
  }
  groupChangesForInit(role) {

    this.selectedRole = role;
    this.initalValue = [];
    this.initalAvailableValue = [];
    this.allGroupDisplayList = [];
    this.userGroupDisplayList = [];
    if (role === "Analyst") {
      this.userGroupDisplayList = this.inputUserGroups;
      const difference = this.adminGroupList.filter(x => !this.inputUserGroups.includes(x));
      if (difference && difference.length > 0) {
        this.allGroupDisplayList = difference;
        //this.allGroupDisplayList = this.sortIgnoreCase(this.allGroupDisplayList);
      }

    } else if (role === "Group Admin") {
      this.userGroupDisplayList = this.inputUserGroupAdminGroups;
      const difference = this.adminGroupList.filter(x => !this.inputUserGroupAdminGroups.includes(x));
      if (difference && difference.length > 0) {
        this.allGroupDisplayList = difference;
      }
    } else if (role === "Peer Reviewer") {
      this.userGroupDisplayList = this.inputUserPeerReviewGroups;
      const difference = this.adminGroupList.filter(x => !this.inputUserPeerReviewGroups.includes(x));
      if (difference && difference.length > 0) {
        this.allGroupDisplayList = difference;
      }

    } else if (role === "Supervisor") {
      this.userGroupDisplayList = this.inputUserSupervisorGroups;
      const difference = this.adminGroupList.filter(x => !this.inputUserSupervisorGroups.includes(x));
      if (difference && difference.length > 0) {
        this.allGroupDisplayList = difference;
      }
    }
    this.allGroupDisplayList = this.sortIgnoreCase(this.allGroupDisplayList);
    this.userGroupDisplayList = this.sortIgnoreCase(this.userGroupDisplayList);
    this.initalValue = [...this.userGroupDisplayList];
    this.initalAvailableValue = [...this.allGroupDisplayList];
  }
  getAdminBasedUserData(groupRoles) {
    this.allGroupDisplayList = [];
    this.inputUserGroups = [];
    this.inputUserPeerReviewGroups = [];
    this.inputUserGroupAdminGroups = [];
    this.inputUserSupervisorGroups = [];
    if (!AppUtils.isUndefinedOrNull(this.adminGroupList) && !AppUtils.isUndefinedOrNull(groupRoles)) {
      groupRoles.forEach(element => {
        if (this.adminGroupList.indexOf(element.groupName) !== -1) {
          if (element.groupRole.indexOf("Analyst") !== -1) {
            if (!this.inputUserGroups.includes(element.groupName)) {
              this.inputUserGroups.push(element.groupName);
            }
          }

          if (element.groupRole.indexOf("Peer Reviewer") !== -1) {
            if (!this.inputUserPeerReviewGroups.includes(element.groupName)) {
              this.inputUserPeerReviewGroups.push(element.groupName);
            }
          }

          if (element.groupRole.indexOf("Group Admin") !== -1) {
            if (!this.inputUserGroupAdminGroups.includes(element.groupName)) {
              this.inputUserGroupAdminGroups.push(element.groupName);
            }
          }
          if (element.groupRole.indexOf("Supervisor") !== -1) {
            if (!this.inputUserSupervisorGroups.includes(element.groupName)) {
              this.inputUserSupervisorGroups.push(element.groupName);
            }
          }
        }
      });
    }
    const difference = this.adminGroupList.filter(x => !this.inputUserGroups.includes(x));
    if (difference && difference.length > 0) {
      this.allGroupDisplayList = difference;
      this.initalAvailableValue = [...this.allGroupDisplayList];
    }
  }
  saveGroupUserAdminDataToDB() {
    const userIdList: any = [];
    userIdList.push(this.userDetails.userId);
    if (this.userDetails.userId !== this.newUserSoeid) {
      try {
        this.notFound = true;
        this.validationError = "SoeId searched & current soeId are different! Please search again.";
      } catch (error) {
        console.log('Exception in saveChanges()' + error.message);
      }
    } else {
      try {
        this.groupAdd = this.userGroupDisplayList.filter(x => !this.initalValue.includes(x));
        this.groupRemove = this.initalValue.filter(x => !this.userGroupDisplayList.includes(x));
        const formData = {
          userId: userIdList,
          groupRole: this.selectedRole,
          groupListToAdd: this.groupAdd,
          groupListToRemove: this.groupRemove
        };
        this.showSpinner = true;
        console.debug(":SPINNER:: "+ this.showSpinner+" ::user-group-assignment.saveGroupUserAdminDataToDB");
        this.groupAdminService.saveUserGroupsBulk(formData).subscribe(userDetails => {
          this.displaySuccess = true;
          this.resetMessages();
          this.showSpinner = false;
          console.debug(":SPINNER:: "+ this.showSpinner+" ::user-group-assignment.saveGroupUserAdminDataToDB");
          this.displaySuccess = true;
          this.successMessage = `User details updated successfully!`;
          this.isOpen = false;
          this.groupAdd = [];
          this.groupRemove = [];
          setTimeout(() => { this.displaySuccess = false; this.closeModal(); }, 3000);
        }, (error) => {
          console.log(error);
          this.errorMessage = `An error occured while saving changes`;
          this.showSpinner = false;
          console.debug(":SPINNER:: "+ this.showSpinner+" ::user-group-assignment.saveGroupUserAdminDataToDB");
          this.displayError = true;
          setTimeout(() => { this.displayError = false; this.closeModal(); }, 3000);
        });
      } catch (error) {
        this.showSpinner = false;
        console.debug(":SPINNER:: "+ this.showSpinner+" ::user-group-assignment.saveGroupUserAdminDataToDB");
        console.log('Exception in ' + error.message);
      }
    }
  }
  onTabChange(event) {
    this.activeFlag = event.index;
  }
  showAddtoGrpPanel(event, overlaypanel: OverlayPanel): void {
    overlaypanel.toggle(event);
  }
  resetMessages() {
    this.validationError = '';
    this.successMessage = '';
    this.errorMessage = null;
    this.selectedRole = "Analyst";
  }

  resetData() {
    this.userNotFound = false;
    this.notFound = false;
    this.userDetails = null;
    this.newUserSoeid = null;
  }

  reset() {
    this.resetMessages();
    this.resetData();
  }

  closeModal() {
    this.modalClosed.emit();
  }

  sortIgnoreCase(array: any) {
    if (array !== null && array !== undefined) {
      array = array.sort(function (a, b) {
        if (a.label && b.label) {
          return a.label.toLowerCase().localeCompare(b.label.toLowerCase());
        } else {
          return true;
        }
      });
      return array;
    }
  }
}

<p-dialog [(visible)]="display" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'"
  [styleClass]="'contextMenuModal'" [closable]="false" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true"
  [style]="{'max-width':'700px','width':'60%'}">

  <p-header>
    <div class="d-flex flex-row align-items-center justify-content-between mx-2 mt-1">
      <h6 class="updateMemo-title">VIEW OR UPDATE MEMO</h6>
      <button type="button" (click)="hideModal()" class="d-flex flex-row close qma-text">
        <img src="assets/Inbox/close_icon_u73.svg" alt="Close">
        <h6>CLOSE</h6>
      </button>
    </div>
  </p-header>
  <div class="updateMemo-body mx-2">
    <form #memoForm="ngForm">
      <div class="d-flex flex-row align-items-center input-holder my-4">       
        <div class="d-flex flex-row w-100 align-items-center"> 
          <textarea rows="4" cols="50" name="memoModel" [(ngModel)]="memoModel"></textarea>
        </div>
      </div>
    </form>
  </div>
  <p-footer>
    <div class="d-flex flex-row flex-wrap bottom-button-holder px-3 pb-2 pt-3 justify-content-end">
      <button type="submit" class="btn search-btn mr-4" (click)="updateMemo(memoForm)">Submit</button>
      <button type="reset" class="btn clear-btn" (click)="memoForm.reset()">Reset</button>
    </div>
  </p-footer>
</p-dialog>
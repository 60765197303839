import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PersonalCalendarComponent } from './personal-calendar.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { FormsModule } from '@angular/forms';
import { FlatpickrModule } from 'angularx-flatpickr';
//import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';

import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule as Alias } from 'primeng/calendar';
import { InputTextareaModule } from 'primeng/inputtextarea';

@NgModule({
  declarations: [PersonalCalendarComponent],
  imports: [
    CommonModule,
    FormsModule,
    DialogModule,
    InputTextModule,
    AutoCompleteModule,
    Alias,
    InputTextareaModule,
  ],
  exports: [PersonalCalendarComponent]
})
export class PersonalCalendarModule { }

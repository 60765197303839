
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";

@Pipe({
  name: 'safeHtml'
})
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {}
  transform(value: string) {
    //Links in email body when clicked should open up in new tab of browser
    const processedString = this.addTargetToUrl(value);
    return this.sanitized.bypassSecurityTrustHtml(processedString);
  }
// Links in email body when clicked should open up in new tab of browser
  addTargetToUrl(htmlString) {
    let doc = new DOMParser().parseFromString(htmlString, "text/html");
    const anchors = doc.getElementsByTagName("a");
    let isEnchorExists = false;
    for (let i = 0; i < anchors.length; i++) {
      anchors[i].setAttribute('target', '_blank');
      isEnchorExists =true;
    }
    return isEnchorExists ? "<html>" + doc.documentElement.innerHTML + "</html>" : htmlString;
  }
}
  
import { Component, OnInit, OnDestroy, ViewChild, ViewChildren, QueryList, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { DashboardService } from '../../services/dashboard/dashboard.service';
import { InboxService } from '../../services/inbox.service';
import { OverlayPanel } from "primeng//overlaypanel";
import { TabView } from 'primeng/tabview';
import { Subscription } from 'rxjs';
import { CheckboxListComponent } from '../../common/component/checkbox-list/checkbox-list.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UserDataService } from '../../services/user-data.service';
import { CoreComponent } from 'src/app/core/core.component';
import { Message } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { QmaConstant } from 'src/app/constant/qma-constant';

interface AttachmentOption {
  name: string;
}
@Component({
  selector: 'app-dashboard-view',
  templateUrl: './dashboard-view.component.html',
  styleUrls: ['./dashboard-view.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MessageService]
})
export class DashboardViewComponent implements OnInit, OnDestroy {
  groupNames: any[] = [];
  tabArray: Array<boolean> = [true, false];
  attachmentsOptions: any = [];
  display: boolean;
  filteredGroups: any;
  requestTypeMap;
  requestTypes: any[] = [];
  @ViewChild('requestTypes') checkboxlistcomp: CheckboxListComponent;
  assignMenu: any;

  /* Dashboard Setting By Om -14-08-19 */
  isAllChart: boolean = true;
  isOwnerChart: boolean = true;
  isHeatmapChart: boolean = true;
  isRequesttypeChart: boolean = true;
  isGroupChart: boolean = true;
  isTrendChart: boolean = true;
  isCommunitywidgetChart: boolean = true;
  isMailboxChart: boolean = true;
  isAll: boolean = true;
  isOwner: boolean = true;
  isHeatmap: boolean = true;
  isRequesttype: boolean = true;
  isGroup: boolean = true;
  isTrend: boolean = true;
  isCommunitywidget: boolean = true;
  isMailbox: boolean = true;
  growlmessages: Message[] = [];
  saveSuccess: boolean = false;
  lastRefreshDate = new Date().toLocaleString();

  @ViewChildren(CheckboxListComponent) checkboxListComponents: QueryList<CheckboxListComponent>;
  @ViewChild('adminTabs') adminTabs: TabView;
  error: any = { isError: false, errorMessage: '' };
  public advSearchForm: FormGroup;
  isMobile: boolean = false;

  protected groupColors = {};
  protected viewRequestSubscription: Subscription;

  constructor(private dashboardService: DashboardService, private formBuilder: FormBuilder, private userDataService: UserDataService, private inboxService: InboxService,
    private coreComponent: CoreComponent, private messageService: MessageService, private cdr: ChangeDetectorRef) {
    this.isMobile = (window.innerWidth <= QmaConstant.MOBILE_WIDTH);
    if (this.isMobile) { //C153176-4717:Removed unnecssary initial calls for performance
      this.dashboardService.getIntensityHeatMapCnts();
      this.dashboardService.getmailBoxStats();
    }
  }

  ngOnInit() {
    try {
      // calling http request once only
      this.dashboardService.getHomePageChartsData();
      this.getGroupData();
      this.getInitialDataForCharts();
      this.viewRequestSubscription = this.inboxService.viewRequest.subscribe((options) => {
        this.coreComponent.handleViewRequestEvent(options);
      });
      let detail: string = 'Dashboard Settings saved successfully.';
      this.growlmessages.push({ severity: 'success', summary: 'Success Message', detail: detail });
      this.dashboardService.listenSaveFlag().subscribe((flag: any) => {
        this.saveSuccess = flag;
      });
      this.inboxService.httpResponse.subscribe((flag) => {
        this.lastRefreshDate = new Date().toLocaleString();

      });
      this.userDataService.userUsageStats("Dashboard", "Dashboard").subscribe(result => console.log("StartTime:" + new Date()));
    } catch (e) {
      console.error("Exception in QMA 2 while dashboard view ngOnInit() : ", e);
    }
  }

  refreshAllCharts() {
    this.dashboardService.getOpenInquiryGroupData();
    this.dashboardService.getInquiriesTrendCnts();
    this.dashboardService.getDashboardCountsService();
    this.dashboardService.getIntensityHeatMapCnts();
    this.dashboardService.getHomePageChartsData();
    this.dashboardService.getmailBoxStats();
  }

  ngOnDestroy() {
    if (this.viewRequestSubscription) {
      this.viewRequestSubscription.unsubscribe();
    }
  }

  getInitialDataForCharts() {
    this.dashboardService.getDashboardLayout().subscribe(defaultData => {
      // Initially dashboardLayout is null. Fix Console error by XXXXX
      let ddc = defaultData.dashboardLayout ? defaultData.dashboardLayout.charts : null;
      if (defaultData && defaultData.dashboardLayout && ddc && ddc.length > 0) {
        const charts = ddc[0];

        this.isAll = this.isAllChart = charts.All;
        this.isOwner = this.isOwnerChart = charts.ownerChart;
        this.isHeatmap = this.isHeatmapChart = charts.heatmapChart;
        this.isRequesttype = this.isRequesttypeChart = charts.requesttypeChart;
        // this.isGroup = this.isGroupChart = charts.groupChart;
        this.isTrend = this.isTrendChart = charts.trendChart;
        this.isCommunitywidget = this.isCommunitywidgetChart = charts.communityWidget;
        this.isMailbox = this.isMailboxChart = charts.mailbox;
      }
    });
  }
  /* For Dashboard Popup by OM */
  showDashBoardPanel(event, overlaypanel: OverlayPanel): void {
    overlaypanel.toggle(event);
  }

  handleChange(e, chartName) {
    if (chartName.name == 'isAllChart') {
      this.isAllChart = e.checked;
      this.isOwnerChart = e.checked;
      this.isHeatmapChart = e.checked;
      this.isRequesttypeChart = e.checked;
      this.isGroupChart = true; // e.checked;
      this.isTrendChart = e.checked;
      this.isCommunitywidgetChart = e.checked;
      this.isMailboxChart = e.checked;
    } else if (chartName.name != 'isAllChart') {
      chartName.name = e.checked;
      if (this.isOwnerChart && this.isHeatmapChart && this.isRequesttypeChart &&
        this.isTrendChart && this.isCommunitywidgetChart && this.isMailboxChart) {
        this.isAllChart = true;
      } else {
        this.isAllChart = false;
      }
    }
  }
  onDoLoadChart() {
    this.isAll = this.isAllChart;
    this.isOwner = this.isOwnerChart;
    this.isHeatmap = this.isHeatmapChart;
    this.isRequesttype = this.isRequesttypeChart;
    this.isTrend = this.isTrendChart;
    this.isCommunitywidget = this.isCommunitywidgetChart;
    this.isMailbox = this.isMailboxChart;
  }

  setAsDefault() {
    let postDataForDefaultSetting = {
      "dashboardLayout":
      {
        'name': 'default',
        'charts': [
          {
            'All': this.isAll,
            'ownerChart': this.isOwner,
            'heatmapChart': this.isHeatmap,
            'requesttypeChart': this.isRequesttype,
            'groupChart': true, // this.isGroup,
            'trendChart': this.isTrend,
            'communityWidget': this.isCommunitywidget,
            'mailbox': this.isMailbox
          }
        ]
      }

    };
    this.dashboardService.postDashboardSettingDefault(postDataForDefaultSetting).subscribe(response => {
      this.messageService.add({ key: 'custom', summary: 'Success Confirmation', detail: 'Your setting saved successfully !' });
    },
      error => {
      }
    );
  }
  getGroupData() {
    let groupNameTmp = [];
    this.userDataService.LocalGetLoginUserInfo().subscribe(loginUserInfo => {
      this.getUserGroups(loginUserInfo);
      loginUserInfo.myGroups.forEach(myGroup => {
        groupNameTmp.push({ label: myGroup.groupName, value: myGroup.groupName });
      });
    });
    this.groupNames = groupNameTmp;
  }

  populateGroupData(groupNames: any) {
    this.setRequestTypesForSelectedGroups(groupNames);
  }

  setRequestTypesForSelectedGroups(groupNames) {
    this.requestTypeMap = new Map<string, string[]>();
    for (let groupName of groupNames) {
      let temp = this.assignMenu.find(item => item.groupName == groupName);
      console.log('User Name :', temp.groupOwner);
      let requestTypes = this.getRequestTypes(groupName.label);
      this.requestTypeMap.set(groupName.label, requestTypes);
    }
    let requestTypesTmp = [];
    this.requestTypeMap.forEach((types: string[], key: string) => {
      if (!this.isNull(types)) {
        for (let i = 0; i < types.length; i++) {
          requestTypesTmp.push({ label: types[i], value: types[i], name: types[i], binary: false });
        }
      }
    });
    this.requestTypes = requestTypesTmp;
    this.checkboxlistcomp.items = this.requestTypes;
    this.checkboxlistcomp.assignCopy();
  }

  // Null value check function
  isNull(input) {
    if (input === "" || input === undefined || input == null) {
      return true;
    }
    return false;
  }

  getRequestTypes(groupName): any[] {
    let requestTypes = [];
    this.userDataService.LocalGetLoginUserInfo().subscribe(loginUserInfo => {
      loginUserInfo.myGroups.forEach(myGroup => {
        if (myGroup.groupName === groupName) {
          requestTypes = myGroup.requestTypes;
        }
      });
    });
    return requestTypes;
  }

  showDashBoardSetting(event, overlaypanel: OverlayPanel): void {
    this.attachmentsOptions = [];
    this.attachmentsOptions.push({ name: "Dashboard Settings" }, { name: "Set as Default" });
    overlaypanel.toggle(event);
  }

  changeFlag($event) {
    this.display = $event;
  }

  openAdminDialog() {
    this.display = true;
    this.dashboardService.listenSaveFlag().subscribe((flag: any) => {
      this.saveSuccess = flag;
    });
  }
  hideModal() {
    this.display = false;
  }

  clearSearch() {
    this.checkboxListComponents.forEach((comp) => {
      comp.resetCheckList();
    });
    this.error.isError = false;
  }
  selectedTab(_index: number) {
    this.tabArray.fill(false);
    this.tabArray[_index] = true;
  }

  getUserGroups(_loginUserInfo: any) {
    this.assignMenu = [];
    for (let i = 0; i < _loginUserInfo.context.length; i++) {
      var groupOwnerList = [], uniqueData = [], assignMenuAvailable = [];
      for (let item = 0; item < _loginUserInfo.context[i].assignOwnerList.length; item++) {
        if (_loginUserInfo.context[i].assignOwnerList[item].active) {
          groupOwnerList.push(_loginUserInfo.context[i].assignOwnerList[item]);
        }
      }

      for (let idx = 0; idx < _loginUserInfo.myGroups.length; idx++) {
        if (_loginUserInfo.myGroups[idx].id == _loginUserInfo.context[i].groupId) {
          this.assignMenu.push({
            groupOwner: groupOwnerList,
            groupName: _loginUserInfo.myGroups[idx].groupName,
            id: _loginUserInfo.myGroups[idx].id
          });
        }

      }
    }
  }

  addGroupColor(group, color) {
    this.groupColors[group] = color;
  }

  getGroupColor(group) {
    if (!group || !this.groupColors[group]) {
      return undefined;
    }
    return this.groupColors[group];
  }

  onReject() {
    this.messageService.clear('c');
  }

}

import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class RightNavigationService {
  private clickStatusArray: Array<boolean> = [false, false, false]; //Array to maintain the status of TopNav items(Notification, Help, User)
  private clickedItemIndex:number = -1;
  private subject = new BehaviorSubject(this.clickedItemIndex);
  constructor() {
  }

  /**
   * To open Navigation Panel
   */
  public open(_clickedItemIndex:number) {
    this.clickedItemIndex = _clickedItemIndex;
    this.subject.next(_clickedItemIndex);    
  }

   /**
   * To close Navigation Panel
   */
  public close() { 
    this.subject.next(-1);    
  }

   /**
   * To get the current status on Navigation Panel
   */
  public getNavStatus(){
   return this.subject.asObservable();
  }

    /**
   * Toggle Navigation Panel
   */
  public toggle(): void {
    //this.isOpen = !this.isOpen;
    //this.subject.next(this.isOpen);
  }
}
import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef, HostListener } from '@angular/core';
import { DashboardService } from '../../services/dashboard/dashboard.service';
import { DashboardLocalService } from 'src/app/services/dashboard/dashboard-local.service';
import * as HighCharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import OfflineExport from 'highcharts/modules/offline-exporting';
import ExportData from 'highcharts/modules/export-data';
import HC_more from 'highcharts/highcharts-more';
import * as CustomizedPrinter from '../utils/customized-printer';
import * as $ from 'jquery';
import '../../../../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js';
import { FadingDialogComponent } from 'src/app/dashboard/fading-dialog/fading-dialog.component';
import { UserDataService } from "src/app/services/user-data.service";
import { InboxService } from 'src/app/services/inbox.service';
import { PiwikProUtils } from 'src/app/common/utility/piwikProUtils';

HC_exporting(HighCharts);
OfflineExport(HighCharts);
ExportData(HighCharts);
HC_more(HighCharts);
CustomizedPrinter(HighCharts);

@Component({
  selector: 'app-intensity-heatmap',
  templateUrl: './intensity-heatmap.component.html',
  styleUrls: ['./intensity-heatmap.component.scss']/* ,
  host: {
    '(document:keydown)' : 'onKeydown($event)'
  } */
})
export class IntensityHeatmapComponent implements OnInit, AfterViewInit {
  ngAfterViewInit(): void {
    /* this.cdr.detach(); */
  }
  rootNode: any;
  HighCharts = HighCharts; // required
  protected heatmapOptions: any = {
    chart: {
      type: 'columnrange',
      backgroundColor: '#FCFCFC',
      inverted: true,
      animation: false,
      events: {
        load() {
          if (this.options && this.options.ngComponent) {
            this.options.ngComponent.redrawChartWidgets(this);
          } else {
            console.log('Cannot find ng component for intensity map!');
          }
        },
        redraw(e) {
          if (this.options && this.options.ngComponent) {
            this.options.ngComponent.redrawChartWidgets(this);
          } else {
            console.log('Cannot find ng component for intensity map!');
          }
        }
      }
    },
    title : {
      useHTML: true,
      text:  '<span style="line-height: 15px;font-size: 15px;font-family: \'InterstateLight\';">INTENSITY HEATMAP</span>',
      align: 'left'
    },
    xAxis: {
        type: 'categories',
        categories: [],
        visible: false
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Inquiries'
      },
      visible: false
    },
    tooltip: {
      backgroundColor: 'rgba(252,252, 252, 1)',
      formatter(tooltip) {
        const t = tooltip.defaultFormatter.call(this, tooltip);
        const component = this.point.series.chart.options.ngComponent;
        if (component) {
          const result = component.updateTooltip(tooltip, this.point, t);
          if (result) {
            return result;
          }
        }
        return t;
      }
    },
    labels: {
      items: []
    },
    legend: {
      enabled: false
    },
    plotOptions: {
     series: {
       groupPadding: 0.5, // required to overlap the bars
       pointWidth: 14,
       pointPadding: 0
     }
    },
    series: null,
    navigation: {
      enabled: false,
      buttonOptions: {
        enabled: true,
        symbolSize: 28,
        symbolX: 25,
        symbolY: 22
      }
    },
    exporting: {
      // C153176-4479: support larger print max width
      printMaxWidth: 1500,
      menuItemDefinitions: {
        maximize: {
          onclick() {
            if (this.options && this.options.ngComponent) {
              this.options.ngComponent.enterFullScreen(this);
            } else {
              console.log('intensity-heatmap: Failed to enter full screen');
            }
          },
          text: 'Maximize'
        },
        downloadCsv: {
          onclick() {
            if (this.options && this.options.ngComponent) {
              this.options.ngComponent.customDownloadCsv(this);
            } else {
              console.log('intensity-heatmap: Failed to download CSV');
            }
          },
          text: 'Download CSV'
        }
      },
      buttons: {
        contextButton: {
          menuItems: [{
            textKey: 'printChart',
            onclick() {
                this.print();
            }
        },  {
            textKey: 'downloadPNG',
            onclick: () => {this.localService.download("intensity-heatmap", "png"); }
            // function () {
            //     this.exportChart();
            // }
        }, {
            textKey: 'downloadJPEG',
            onclick:  () => {this.localService.download("intensity-heatmap", "jpg"); }
            // function () {
            //     this.exportChart({
            //         type: 'image/jpeg'
            //     });
            // }
        }, {
            textKey: 'downloadPDF',
            onclick:  () => {this.localService.download("intensity-heatmap", "pdf"); }
            // function () {
            //     this.exportChart({
            //         type: 'application/pdf'
            //     });
            // }
        }, "downloadCsv", "maximize"], // "viewFullscreen"],
          symbol: 'url(assets/dashboard/contextmenu.png)'
        }
      },
      csv: {
        decimalPoint: ":"
        // lineDelimiter:':'
      }

    },
    credits: {
      enabled: false
    },
    ngComponent: null
  };
  protected barColors = { red: '#EF7B87', amber: '#FFBF00', green: '#00B0B9'};
  protected breakPercentage = 15;
  protected initialPercentage = 5;
  protected rowCount: number;
  protected secondStart: number;
  protected heatmapChart: any;
  protected chartData: any;
  protected chartWidth: number;
  protected chartHeight: number;
  protected categories;
  protected labelX1: number;
  protected labelX2: number;
  protected labelLastX: number;
  protected labelY: number;
  protected rowHeaderHeight: number;
  protected rowHeaderMargin: number;
  protected rowHeight: number;
  protected rdata;
  protected fullScreen = false;
  protected groupMap = {};
  protected colorCodeMap = {}; // C153176-4539: 'colorCode' per category, from service response
  @ViewChild(FadingDialogComponent, { static: true }) fadingDialogComponent;
  public data: any;
  protected totalCountLimitFlag;
  protected totalInquiryCount;
  protected totalInquiryLabel = "Total Inquiries".toUpperCase();

  /**
   * Constructor
   * @param dashboardService dashboard service
   * @param localService local service
   */
  constructor(private dashboardService: DashboardService, private localService: DashboardLocalService, 
    private userDataService: UserDataService, private inboxService:InboxService, private cdr: ChangeDetectorRef) {
    this.heatmapOptions.ngComponent = this;
  }

  ngOnInit() {
    this.dashboardService.getIntensityHeatMap.subscribe(data => {
      this.data = data;
      console.log('getIntensityHeatMap :', data);
      if (!this.checkEmptyObject(data) && this.handleHeatmap(data)) {
          const component = this;
          HC_exporting(HighCharts);
          OfflineExport(HighCharts);
          ExportData(HighCharts);
          HC_more(HighCharts);
          CustomizedPrinter(HighCharts);
          setTimeout(() => {
            const elem = $('#intensity-heatmap');
            if (elem && elem.length) {
              component.populateLabels();
              HighCharts.setOptions({lang: {thousandsSep: ","}});
              component.heatmapChart = HighCharts.chart('intensity-heatmap', component.heatmapOptions);
              component.heatmapChart.options.ngComponent = component;              
              this.populateWidgets(this.heatmapChart);
             // this.setupEventHandler();
              
            }
          }, 1000);
      }
    });
  }

  checkEmptyObject(obj: any) {
    return Object.entries(obj).length === 0 && obj.constructor === Object;
  }

  redrawChartWidgets(chart) {
    if (chart && (this.chartWidth === undefined || chart.chartWidth !== this.chartWidth || chart.chartHeight !== this.chartHeight)) {
      this.chartWidth = chart.chartWidth;
      this.chartHeight = chart.chartHeight;
      setTimeout(() => {
        const elem = $('#intensity-heatmap');
        if (elem && elem.length) {
          this.populateLabels();
          this.heatmapChart = HighCharts.chart('intensity-heatmap', this.heatmapOptions);
          this.heatmapChart.options.ngComponent = this;          
          this.populateWidgets(this.heatmapChart);
          this.setupEventHandler(); /* C153176-5431: Disable double click as this is planned for next release[28th May] now*/
       }
      }, 0);
    }
  }

  populateWidgets(chart) {
    const me = this;
    if (chart && chart.series && chart.series.length > 1) {
      [2, 3, 4, 5].forEach((i) => {
        const series = chart.series[i];
        const s0index = i;
        if (series) {
          const points = series.points;
          if (points) {
            if (series.group && series.group.element && series.group.element.childNodes) {
              // collecting existing <img> elements and remove them from DOM node, since new nodes will be added.
              const removals = Array.from(series.group.element.childNodes).filter((node: any, index, array) => {
                if (node.localName) {
                  return node.localName.includes('image');
                } else {
                  return false;
                }
              });
              if (removals) {
                removals.forEach(node => {
                  series.group.element.removeChild(node);
                });
              }
            }
            // add image or text nodes for each point.
            points.forEach((point, pIndex) => {
              if (s0index < 4) {
                // add image for series less than 4
                if (point.index < (me.rowCount - 1)) {
                  chart.renderer.image("./assets/dashboard/heatmap-limit.png", point.plotX - 8, point.plotHigh - 10, 20, 20).attr({
                    opacity: 1,
                    zIndex: 100,
                    dataTooltip: true,
                    title: 'Limit: ' + Math.round(point.high - point.low),
                  }).add(series.group);
                }
              }
            });
          }
        }
      });
    } else {
        console.log("Heatmap chart or series cannot be found!");
    }
    setTimeout(() => {
      $('[dataTooltip=true]').tooltip();
    }, 0);
  }

  handleHeatmap(data): boolean {
    if (!data || !data.intensityHeatMap || !data.intensityHeatMap.length) {
      console.log("No intensity heatmap data returned!");
      return false;
    }
    let totalCount = 0;
    let totalLimitCount = 0;
    const categorySet = new Set();
    const countMap = {};
    const limitMap = {};
    this.colorCodeMap = {}; // C153176-4539: initialize / reset colorCodeMap
    this.groupMap = {}; // mapping between heat tag and group
    data.intensityHeatMap.map((elem) => {
      let pkey;
      Object.keys(elem).forEach((t) => {
        if (!pkey) {
          pkey = t;
        }
        const v = elem[t];
        if (t !== 'tagLimit' && t !== 'groupName' && t !== 'colorCode') { // C153176-4539: exclude 'colorCode' as new category
          t = t.toUpperCase();
          categorySet.add(t);
          const vNum = (v !== null && v !== undefined ? (typeof v === 'string' ? parseInt(v, 10) : v) : 0);
          if (vNum) {
            totalCount += vNum;
          }
          countMap[t] = vNum;
        } else if (t === 'tagLimit') {
          if (pkey) {
            const vNum = (v !== null && v !== undefined ? (typeof v === 'string' ? parseInt(v, 10) : v) : 0);
            if (vNum) {
              totalLimitCount += vNum;
            }
            limitMap[pkey.toUpperCase()] = vNum;
          }
        } else if (t === 'groupName') {
          if (pkey) {
            this.groupMap[pkey] = v;
          }
        } else if (t === 'colorCode') { // C153176-4539, populate 'colorCode' in each category
          if (pkey) {
            if (v) {
              this.colorCodeMap[pkey.toUpperCase()] = v;
            }
          }
        }
      });
      // C153176-4539: populate default color code from loggedUserInfo if not already
      if (pkey && !this.colorCodeMap[pkey.toUpperCase()] && this.userDataService.loggedInUserInfo
        && this.userDataService.loggedInUserInfo.clientCategories) {
        let index = this.userDataService.loggedInUserInfo.clientCategories.findIndex(c => c && c.categoryName === pkey);
        if (index > -1) {
          this.colorCodeMap[pkey.toUpperCase()] = this.userDataService.loggedInUserInfo.clientCategories[index].colorCode;
        }
      }
    });
    const maxTotalCount = totalCount < totalLimitCount? totalLimitCount: totalCount;
    this.totalCountLimitFlag = totalCount < totalLimitCount && totalLimitCount;
    this.totalInquiryCount = totalCount;
    const breakCount = (1 + this.breakPercentage / 100) * maxTotalCount;
    const initialCount = (this.initialPercentage * maxTotalCount / 100);
    const categories = Array.from(categorySet); // C153176-4566 : Categories sorted at service side.
    categories.push(this.totalInquiryLabel);
    this.computeLayout(categories);
    const limits = [];
    const fulldata = [];
    const rdata = [];
    const tdata = [];
    categories.map((c:any) => {
      const v = countMap[c];
      limits.push(limitMap[c]);
      fulldata.push(maxTotalCount);
      rdata.push(v);
      tdata.push(null);
    });
    tdata.pop();
    tdata.push(maxTotalCount);
    const fullbarcolor = '#dddddd';
    // build series data
    // s1: first column; s2: second column
    const s1Start = initialCount;
    const s2Start = initialCount + breakCount + 1;
    this.secondStart = s2Start;
    const breakData = [];
    const s2ufulldata = [];
    const s2ulimits = [];
    const s2urdata = [];
    const s2utdata = [];
    const s1ufulldata = [];
    const s1ulimits = [];
    const s1urdata = [];
    const s1utdata = [];
    // populate two columns of data except the last row
    for (let i = 0; i < (this.rowCount - 1); i++) {
      let c_index = Math.min(2*i, categories.length -1);
      let sCat = categories[c_index]; //i
      // second column
      const s2uindex = 2 * i + 1;
      if (s2uindex < (rdata.length - 1)) {
        c_index = Math.min(s2uindex, categories.length -1);
        const sCat2 = categories[c_index];
        breakData.push([sCat2, s1Start + fulldata[2 * i] + 1, s2Start - 1]);
        s2ufulldata.push([sCat2, s2Start, s2Start + (fulldata[s2uindex] ? fulldata[s2uindex] : 0)]);
        s2ulimits.push([sCat2, s2Start, s2Start + (limits[s2uindex] ? limits[s2uindex] : 0)]);
        s2urdata.push([sCat2, s2Start, s2Start + (rdata[s2uindex] ? rdata[s2uindex] : 0)]);
        s2utdata.push([sCat2, s2Start, s2Start + (tdata[s2uindex] ? tdata[s2uindex] : 0)]);
      }
      // first column
      const s1uindex = 2 * i;
      s1ufulldata.push([sCat, s1Start, s1Start + (fulldata[s1uindex] ? fulldata[s1uindex] : 0)]);
      s1ulimits.push([sCat, s1Start, s1Start + (limits[s1uindex] ? limits[s1uindex] : 0)]);
      s1urdata.push([sCat, s1Start, s1Start + (rdata[s1uindex] ? rdata[s1uindex] : 0)]);
      s1utdata.push([sCat, s1Start, s1Start + (tdata[s1uindex] ? tdata[s1uindex] : 0)]);
    }
    // populate last row data
    const lastvcat = categories[categories.length - 1];
    const lastvindex = fulldata.length - 1;
    const lastvstart = categories.length > 2 ? Math.floor(maxTotalCount * 0.60) : Math.floor(maxTotalCount * 0.30);
    s1ufulldata.push([lastvcat, lastvstart, lastvstart + (fulldata[lastvindex] ? fulldata[lastvindex] : 0)]);
    s1ulimits.push([lastvcat, lastvstart, lastvstart + (limits[lastvindex] ? limits[lastvindex] : 0)]);
    s1urdata.push([lastvcat, lastvstart, lastvstart + (rdata[lastvindex] ? rdata[lastvindex] : 0)]);
    s1utdata.push([lastvcat, lastvstart, lastvstart + (tdata[lastvindex] ? tdata[lastvindex] : 0)]);
    const series = [{
        name: 'Total Inquiries -',
        data: s1ufulldata,
        color: fullbarcolor,
        borderRadius: this.heatmapOptions.plotOptions.series.pointWidth / 2,
      }, {
       name: 'Total Inquiries',
       data: s2ufulldata,
       color: fullbarcolor,
       borderRadius: this.heatmapOptions.plotOptions.series.pointWidth / 2,
      }, {
        name: 'Limit - ',
        data: s1ulimits,
        color: fullbarcolor,
        zIndex: 10,
        opacity: 1
      }, {
       name: 'Limit',
       data: s2ulimits,
       color: fullbarcolor,
       zIndex: 10,
       opacity: 1
      }, {
       name: 'Actual Inquiries -',
       data: s1urdata,
       zIndex: 11,
       opacity: 0.7,
       borderRadius: this.heatmapOptions.plotOptions.series.pointWidth / 2,
       zoneAxis: 'x',
       labelzones: this.getLabelColorZones(s1urdata, s1ulimits, totalCount, s1Start),
       // C153176-4539: use colorCodeMap derived from service call or loggedUserInfo
       zones: this.getBarColorZones(s1urdata, s1ulimits, totalCount, s1Start),
      }, {
        name: 'Actual Inquiries',
        data: s2urdata,
        zIndex: 11,
        opacity: 0.7,
        borderRadius: this.heatmapOptions.plotOptions.series.pointWidth / 2,
        zoneAxis: 'x',
        labelzones: this.getLabelColorZones(s2urdata, s2ulimits, totalCount, s2Start),
        // C153176-4539: use colorCodeMap derived from service call or loggedUserInfo
        zones: this.getBarColorZones(s2urdata, s2ulimits, totalCount, s2Start), // C153176-5174
      }];
    this.categories = categories;
    this.heatmapOptions.series = series;
    this.rdata = rdata;
    // reduce xAxis items
    this.heatmapOptions.xAxis.categories = categories.reduce((p:any[], c) => {
      if (p.length < this.rowCount) {
        p.push(c);
      }
      return p;
    }, []);
    return true;
  }

  populateLabels() {
    const rdata = this.rdata;
    const categories = this.categories;
    const series = this.heatmapOptions.series;
    this.computeLayout(categories);
    // add labels
    this.heatmapOptions.labels.items = [];
    for (let c0index = 0; c0index < categories.length; c0index++) {
      const category = categories[c0index];
      let count = '' + rdata[c0index];
      let xoffset = this.labelX1;
      let seriesElem = series[4];
      const series0index = Math.floor(c0index / 2);
      if (c0index % 2 === 1) {
        xoffset = this.labelX2;
        seriesElem = series[5];
      }
      // C153176-4539: for labels, keep using the color derived from count vs. limit ratios
      let countColor = seriesElem.labelzones[series0index] ? seriesElem.labelzones[series0index].color : undefined;
      let y: number = 0 + this.labelY + (series0index * this.rowHeight);
      if (c0index === (categories.length - 1)) {
        xoffset = this.labelLastX;
        count = '' + Math.round(series[0].data[0][2] - series[0].data[0][1]);
        countColor = '#333333';
        if ( (categories.length % 2) === 0) {
          y += this.rowHeight;
        }
      }
      if (category === this.totalInquiryLabel) {
        if (this.totalCountLimitFlag) {
          count = this.totalInquiryCount;
        }
      }
      this.heatmapOptions.labels.items.push({
        html: '<span style="line-height: 14px;font-size: 14px;font-family: \'InterstateLight\';">'
            + category + '</span>',
        style: {
          left: '' + xoffset + 'px',
          top: '' + y + 'px',
          color: '#333333'
        }
      });
      const x: number = 0 + xoffset + 25 + (category.length * 8);
      this.heatmapOptions.labels.items.push({
        html: '<span style="line-height: 20px;font-size: 20px;font-family: \'InterstateLight\';">'
            + count + '</span>',
        style: {
          left: '' + x + 'px',
          top: '' + y + 'px',
          color: countColor
        }
      });
    }
  }

  getDummyLimit(countMap, totalCount: number) {
    if (!countMap) {
      return undefined;
    }
    const result = {};
    for (const key of Object.keys(countMap)) {
      const range = (totalCount - countMap[key]) * 9 / 10;
      result[key] = Math.floor(1 + Math.random() * Math.floor(range)) + countMap[key];
    }
    return result;
  }

  getLabelColorZones(data, limits, totalCount, offset) {
    const zones = [];
    for (const i of Object.keys(data)) {
      const v = Math.max(data[i][2] - offset, 0);
      const t = Math.max(limits[i][2] - offset, 0);
      const r = t ? Math.floor(100 * v / t) : 100;
      let color; // no default color
      if (r < 25) {
        color = this.barColors.green;
      } else if (r > 75) {
        color = this.barColors.red;
      } else if (!r) {
        color = this.barColors.green;
      } else {
        color = this.barColors.amber;
      }
      if (r) {
        zones.push({value: parseInt(i, 10) + 0.1, color});
      } else {
        zones.push({color});
      }
    }
    return zones;
  }

  // C153176-4539: use colorCodeMap derived from service call or loggedUserInfo
  getBarColorZones(data, limits, totalCount, offset) {
    const zones = [];
    for (const i of Object.keys(data)) {
      let key = data[i][0];
      let color = this.barColors.amber;
      if (key) {
        let colorCode = this.colorCodeMap[key.toUpperCase()];
        if (colorCode) {
          zones.push({value: parseInt(i, 10) + 0.1, color: colorCode}); // C153176-5174
        } else {
          zones.push({color});
        }
      } else {
        zones.push({color});
      }
    }
    return zones;
  }

  computeLayout(categories) {
    // note that the categories include a separate "total inquiries" line
    this.rowCount = Math.round((categories.length - 1) / 2) + 1;
    if (this.chartWidth && this.chartHeight && this.rowCount) {
      this.rowHeaderHeight = 60;
      this.rowHeaderMargin = -55;
      this.rowHeight = (this.chartHeight - this.rowHeaderHeight) / this.rowCount;
      this.labelX1 = this.chartWidth * 0.12;
      this.labelX2 = this.chartWidth * 0.55;
      this.labelY = this.rowHeaderHeight + this.rowHeaderMargin;
      this.labelLastX = this.chartWidth * 0.30;
    }
  }

  updateTooltip(tooltip, point, text) {
    const result = [];
    const p0index = point.index;
    const s0index = point.series.index;
    const s0res = (s0index % 2);
    // update categories
    const c0index = 2 * p0index + s0res;
    if (c0index >= this.categories.length) {
      // disable tooltip in these cases
      result.push('');
      result.push('');
      result.push('');
      return [];
    }
    if (text[0] && text[0].length > 0) {
      const endvi = text[0].indexOf(">");
      const s1 = text[0].substring(0, endvi + 1);
      const ras = text[0].substring(endvi + 1);
      const second2s = ras.indexOf("<");
      const s2 = ras.substring(second2s);
      result.push(s1 + this.categories[c0index] + s2);
    }
    if (text[1] && text[1].length > 0) {
      const first1b = text[1].indexOf("<b>");
      const lastvb = text[1].lastIndexOf("</b>");
      const s1 = text[1].substring(0, first1b + 3);
      const s2 = text[1].substring(lastvb);
      const v = Math.round(point.high - point.low);
      if(text[1].toUpperCase().indexOf(this.totalInquiryLabel) > -1 && this.totalCountLimitFlag) {
        result.push(s1 + ('' + this.totalInquiryCount) + s2);
      } else {
        result.push(s1 + v + s2);
      }
    }
    if (text[2]) {
      result.push(text[2]);
    }
    return result;
  }

  // handle ESC in case of full screen
  @HostListener('document:keydown.esc', ['$event'])
  onKeydown(event) {
    event.stopPropagation();
    event.preventDefault();
    if (this.fullScreen && event && event.code === 'Escape') {
      this.exitFullScreen(this.heatmapChart);
    }
  }

  enterFullScreen(chart: any) {
    const elem = $('#intensity-heatmap-chart-box');
    if (elem && elem.length) {
      elem.toggleClass('col-sm-6');
      elem.toggleClass('dashboardModal');
    }
    this.fullScreen = true;
    if (chart.exportContextMenu && chart.exportContextMenu.childNodes) {
      chart.exportContextMenu.childNodes.forEach(node => {
        if (node && node.childNodes) {
          node.childNodes.forEach(n => {
            if (n && n.innerText === 'Maximize') {
               n.style.display = 'none';
            }
          });
        }
      });
    }
    chart.reflow();
    if (this.fadingDialogComponent) {
      this.fadingDialogComponent.show($('#intensity-heatmap-box')[0]);
    }
  }

  exitFullScreen(chart: any) {
    if (!chart) {
      return;
    }
    const elem = $('#intensity-heatmap-chart-box');
    if (elem && elem.length) {
      elem.toggleClass('col-sm-6');
      elem.toggleClass('dashboardModal');
    }
    this.fullScreen = false;
    if (this.fadingDialogComponent) {
      this.fadingDialogComponent.hide($('#intensity-heatmap-box')[0]);
    }
    if (chart.exportContextMenu && chart.exportContextMenu.childNodes) {
      chart.exportContextMenu.childNodes.forEach(node => {
        if (node && node.childNodes) {
          node.childNodes.forEach(n => {
            if (n && n.innerText === 'Maximize') {
               n.style.display = '';
            }
          });
        }
      });
    }
    chart.reflow();
  }

  customDownloadCsv(chart: any) {
    let csv = 'Category, Inquiry Count\n';
    let total = 0;
    this.data.intensityHeatMap.forEach(i => {
      Object.keys(i).forEach(k => {
          if (k !== 'tagLimit') {
            const v = i[k];
            csv += k + ',' + v + '\n';
            total += v;
        }
      });
    }) ;
    csv += "Total Inquiries, " + total;
    const hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + csv;
    hiddenElement.target = '_blank';
    hiddenElement.download = 'Intensity_Heat_Map.csv';
    hiddenElement.click();
  }

  setupEventHandler() {
    if (this.heatmapChart) {
      this.heatmapChart.series.forEach((series) => {
        if (series && series.points) {
          series.points.forEach((p) => {
            if (p && p.graphic) {
              const point = p;
              p.graphic.on('dblclick', () => {
                if (point && point.series && point.series.chart && point.series.chart.options
                  && point.series.chart.options.ngComponent) {
                    point.series.chart.options.ngComponent.handleDoubleClick(point);
                  }
              });
            }
          });
        }
      });
    }
  }

  handleDoubleClick(point) {
    let idx = this.data.intensityHeatMap.findIndex(obj => {   
        return Object.keys(obj).some(key => {
          return (key.toUpperCase() == point.name.toUpperCase());
        });
    });

  if(idx<0){return;}
  const groupName = this.data.intensityHeatMap[idx].hasOwnProperty('groupName') ?  this.data.intensityHeatMap[idx].groupName : "";
  const categoryName = Object.keys(this.data.intensityHeatMap[idx])[0];
  const color = point.color;
  this.inboxService.startViewLoading({color});
  this.inboxService.requestView({viewName: "intensityHeatMap",categoryName: categoryName, groupName:groupName, type:'intensityHeatmap'});
  
  PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Dashboard", "Intensity Heat Map Bar", "Double Click", "Intensity Heat Map Bar", 4);
  }
}

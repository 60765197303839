import { Component, OnInit, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-quick-reply',
  templateUrl: './quick-reply.component.html',
  styleUrls: ['./quick-reply.component.scss']
})
export class QuickReplyComponent implements OnInit {
  @Output() cancel = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  onCancel(a?:any){
    this.cancel.emit('true');
  }

}

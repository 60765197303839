import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgGridModule } from '@ag-grid-community/angular';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ListboxModule } from 'primeng/listbox';
import { SharedModule } from "src/app/common/shared-module";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TabViewModule } from 'primeng/tabview';
import { ColDefsService } from '../services/mailbox/get-ColDefsService.service';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { DropdownModule } from 'primeng/dropdown';
import { DialogModule } from 'primeng/dialog';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MessageService } from 'primeng/api';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SidebarModule } from 'primeng/sidebar';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TooltipModule } from 'primeng/tooltip';
import { AngularSplitModule } from 'angular-split';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from '@dchtools/ngx-loading-v18';
import { SlideMenuModule } from 'primeng/slidemenu';
import { MenuModule } from 'primeng/menu';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { SymphonyModule } from '../symphony/symphony.module';
import { CheckboxModule } from 'primeng/checkbox';
import { MicrofrontendInboxComponent } from './microfrontend-inbox.component';
import { CalendarModule } from 'primeng/calendar';
import { MicrofrontendAdvancedSearchComponent } from './microfrontend-advanced-search/microfrontend-advanced-search.component';
import { NewMessageModule } from '../new-message/new-message.module';
import { MicrofrontendMailDetailViewComponent } from './microfrontend-mailDetailView/microfrontend-mailDetailView.component';
import { CoreModule } from '../core/core.module';
import { InboxModule } from '../inbox/inbox.module';


@NgModule({
  declarations: [
    MicrofrontendInboxComponent,
    MicrofrontendAdvancedSearchComponent,
    MicrofrontendMailDetailViewComponent
  ],
  imports: [
    SymphonyModule,
    CommonModule,
    AgGridModule,
    NgScrollbarModule,
    OverlayPanelModule,
    ListboxModule,
    TabViewModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    AutoCompleteModule,
    DropdownModule,
    DialogModule,
    ToastModule,
    ConfirmDialogModule,
    TooltipModule,
    CalendarModule,
    SidebarModule,
    AngularSplitModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.circleSwish,
      backdropBackgroundColour: 'rgba(0,0,0,0.1)',
      backdropBorderRadius: '4px',
      primaryColour: '#021e48',
      secondaryColour: '#021e48',
      tertiaryColour: '#021e48',
      fullScreenBackdrop: true
    }),
    SlideMenuModule,
    MenuModule,
    CheckboxModule,
    NgxUiLoaderModule,
    RadioButtonModule,
    InputSwitchModule,
    InboxModule,
    NewMessageModule,
    CoreModule
  ],
  exports: [
    MicrofrontendInboxComponent,
    NgScrollbarModule,
    OverlayPanelModule,
    ListboxModule,
    SharedModule,
    FormsModule,
    DialogModule,
    TooltipModule,
    CheckboxModule
  ],
  providers: [ColDefsService, MessageService],

})

export class MicrofrontendInboxModule { }

<p-dialog [(visible)]="display" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'"
  [styleClass]="'contextMenuModal'" [closable]="false" [dismissableMask]="true" [baseZIndex]="10000"
  [closeOnEscape]="true" [style]="{'max-width':'500px','width':'70%'}">
  <p-header>
    <div class="d-flex flex-row align-items-center justify-content-between mx-2 mt-1">
      <h6 class="gfid-title">Ownership Re-Assignment</h6>
      <button type="button" (click)="closeModal()" class="d-flex flex-row close qma-text">
        <img src="assets/Inbox/close_icon_u73.svg" alt="Close">
        <h6>CLOSE</h6>
      </button>
    </div>
  </p-header>
  <div class="gfid-body mx-2">
    <div class="grid-holder mb-2">
      <div class="ui-g" style="margin-bottom:10px">
        <ng-template ngFor let-autoRouteGroup [ngForOf]="autoRouteGroups" let-idx="index">
          <div class="ui-g-12">
            <p-checkbox name="autoRoutedWorkflow" value='{{autoRouteGroup.value}}' label='{{autoRouteGroup.label}}' 
              [(ngModel)]="autoRoutedGroupIds" [labelStyleClass]="label-text">
            </p-checkbox>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
  <p-footer>
    <div class="d-flex flex-row flex-wrap bottom-button-holder px-3 pb-2 pt-3 justify-content-center">
      <button class="btn reply-btn" (click)="onReAssign(autoRoutedGroupIds, inquiryId)">Re-Assign</button>
    </div>
  </p-footer>
</p-dialog>
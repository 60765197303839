import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef, HostListener } from '@angular/core';
import { DashboardService } from '../../services/dashboard/dashboard.service';
import * as HighCharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import OfflineExport from 'highcharts/modules/offline-exporting';
import ExportData from 'highcharts/modules/export-data';
import * as RoundedCorners from '../utils/rounded-corners';
import { DashboardLocalService } from "../../services/dashboard/dashboard-local.service";
import { DashboardViewComponent } from '../dashboard-view/dashboard-view.component';
import { InboxService } from 'src/app/services/inbox.service';
import { UserDataService } from 'src/app/services/user-data.service';
import { FadingDialogComponent } from 'src/app/dashboard/fading-dialog/fading-dialog.component';
import * as $ from 'jquery';
import { PiwikProUtils } from 'src/app/common/utility/piwikProUtils';


HC_exporting(HighCharts);
OfflineExport(HighCharts);
ExportData(HighCharts);
RoundedCorners(HighCharts);

@Component({
  selector: 'app-inquiries-by-request-type',
  templateUrl: './inquiries-by-request-type.component.html',
  styleUrls: ['./inquiries-by-request-type.component.scss']/* ,
  host: {
    '(document:keydown)': 'onKeydown($event)',
    '(window:resize)': 'onResize($event)'
  } */,
  host: {
    '(window:resize)': 'onResize($event)'
  } 
})
export class InquiriesByRequestTypeComponent implements OnInit, AfterViewInit {
  ngAfterViewInit(): void {
   /*  this.cdr.detach(); */
  }

  
  rootNode: any;
  HighCharts = HighCharts; // required

  inquiriesByRequestType: any = {
    chart: {
      type: 'bar',
      backgroundColor: '#FCFCFC',
      events: {
        redraw(e) {
            if (this.options && this.options.ngComponent) {
              this.options.ngComponent.enableLegendToggle(this, true);
            } else {
              console.log('Cannot find ng component for request type chart!');
            }
          }
      }
    },
    title: {
      useHTML: true,
      text: '<span class="mobile-request-type" style="line-height: 15px;font-size: 15px;font-family: \'InterstateLight\';">OPEN INQUIRIES BY REQUEST TYPE</span>',
      align: 'left'
    },
    xAxis: {
      categories: [],
      labels: {
        style: {
          fontSize: '10px'
        },
        formatter() {
          let str = this.value;
          if (!isNaN(str)) {
              str = '';
          }
          return str;
        }
      },
      scrollbar: {
        height: 6,
        barBackgroundColor: '#002D72',
        barBorderColor: '#002D72',
        barBorderRadius: 7,
        barBorderWidth: 0,  
        buttonBackgroundColor: '#FCFCFC',
        buttonBorderColor: '#FCFCFC',
        buttonBorderWidth: 0,
        buttonArrowColor: '#FCFCFC',
        buttonBorderRadius: 7,
        rifleColor: '#FCFCFC',
        trackBackgroundColor: '#FCFCFC',
        trackBorderWidth: 1,
        trackBorderColor: '#FCFCFC',
        trackBorderRadius: 7,
        enabled: true, // 'auto'
        showFull: false,
      },
      min: 0,
      max: 12
    },
    yAxis: {
      min: 0,
      title: {
        text: null
      }
    },
    legend: {
      align: 'right',
      layout: 'vertical',
      verticalAlign: 'top',
      y: 20,
      width: '28%',
      maxHeight: 200,
      itemMarginTop: 6,
      symbolRadius: 0,
      symbolPadding: 0,
      symbolWidth: 0.1,
      symbolHeight: 0.1,
      labelFormatter() {
        const value = this.name;
        // C153176-4981: short name for desktop only
        let temp: string = value;
        if (window.innerWidth >= 767) {
          temp = value.length > 35 ? value.slice(0, 33) + '...' : value; // #3
        }
        return '<div class="legendLabelRT" title="' + value
          + '"> <span class="custom-marker-bis" style="border-color:' + this.color + ';"></span>' + temp + '</div>'; // #3
      },
      useHTML: true
    },
    labels: {
      items: []
    },
    tooltip: {
      headerFormat: '<div style="font-size:10px">{point.key}</div><table>',
      pointerFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td> '
        + '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
      footerFormat: '</table>',
      shared: false,
      useHTML: true,
      backgroundColor: 'rgba(252,252, 252, 1)',
      outside: false,
      zIndex: 9998,
      positioner: (w, h, pt) => {
        return { x: Math.max(0, Math.min(pt.plotX, (this.requestTypeChart.chartWidth - 320 - w))), y: Math.max(pt.plotY, 45)};
      },
    },
    plotOptions: {
      series: {
        stacking: 'normal',
        pointWidth: 16,
        events:{
          legendItemClick:function(){
            let s = this.chart.series,
            index = this.index;
            let isHidden = s.some((obj)=>!obj.visible);

            if(isHidden){
              s[index].setVisible(!s[index].visible);
              if(s.every(obj => !obj.visible)){ //C153176-5756:Show all when none selected
                for(let i = 0; i < s.length; i++) {
                  s[i].setVisible(true);
                }
              }
              return false
            }
            
            for(let i = 0; i < s.length; i++) {
              s[i].setVisible(i == index);
            }
            return false;
          }
        }
      }

    },
    series: [
    ],
    navigation: {
      buttonOptions: {
        enabled: true,
        symbolSize: 28,
        symbolX: 25,
        symbolY: 22
      }
    },
    exporting: {
      // C153176-4479: support larger print max width
      printMaxWidth: 1500,
      menuItemDefinitions: {
        maximize: {
          onclick() {
            if (this.options && this.options.ngComponent) {
              this.options.ngComponent.enterFullScreen(this);
            } else {
              console.log('Failed to enter full screen');
            }
          },
          text: 'Maximize'
        }
      },
      buttons: {
        contextButton: {
          menuItems: [{
            textKey: 'printChart',
            onclick() {
                this.print();
            }
        },  {
            textKey: 'downloadPNG',
            onclick: () => {this.localService.download("inquiries-by-request-type", "png"); }
            // function () {
            //     this.exportChart();
            // }
        }, {
            textKey: 'downloadJPEG',
            onclick: () => {this.localService.download("inquiries-by-request-type", "jpg"); }
            // function () {
            //     this.exportChart({
            //         type: 'image/jpeg'
            //     });
            // }
        }, {
            textKey: 'downloadPDF',
            onclick: () => {
              this.localService.download("inquiries-by-request-type", "pdf");
                // this.exportChart({
                //     type: 'application/pdf'
                // });
            }
        }, {
            textKey: 'downloadCSV',
            onclick() {
              this.downloadCSV();
            }
        }, "maximize"], // "viewFullscreen"],
          symbol: 'url(assets/dashboard/contextmenu.png)'
        }
      },
      csv: {
        decimalPoint: ":"
      }

    },
    credits: {
      enabled: false
    },
    ngComponent: null
  };
  requestTypeChart: any;
  protected chartData: any;
  protected selectedGroup: string;
  protected fullScreen = false;
  @ViewChild(FadingDialogComponent, { static: true }) fadingDialogComponent;
  protected chartWidth;
  protected chartHeight;
  legendExpanded: boolean = false; // C153176-4981: legend display mode

  protected colors = ['#FFE57E', '#004785', '#D40E8C', '#F7921E', '#2E64AF', '#9361A8', '#00BDF2', '#00B0B9', '#21D1D1',
  '#FFD600', '#EF7B87', '#56D9FE', '#FFBF00', '#65C8AE', '#54B1ED', '#185E99', '#FFC559', '#E65168'];
  protected mobileScrollbarColors = ['#78AAEB', '#002D72', '#FCFCFC']; // C153176-4981: scrollbar colors

  constructor(public dashboardService: DashboardService, private localService: DashboardLocalService, private inboxService: InboxService,
              private parentComponent: DashboardViewComponent, private userDataService: UserDataService,  private cdr: ChangeDetectorRef) {
    this.selectedGroup = null;
    this.inquiriesByRequestType.ngComponent = this;
  }


  ngOnInit() {
    // C153176-4981:for mobile version, set the entire scrollbar visible to user
    if (window.innerWidth < 767) {
      this.inquiriesByRequestType.xAxis.scrollbar.buttonBackgroundColor = this.mobileScrollbarColors[2];//this.mobileScrollbarColors[1];
      this.inquiriesByRequestType.xAxis.scrollbar.buttonBorderColor = this.mobileScrollbarColors[2]; // this.mobileScrollbarColors[1];
      this.inquiriesByRequestType.xAxis.scrollbar.buttonArrowColor = this.mobileScrollbarColors[1];
      this.inquiriesByRequestType.xAxis.scrollbar.trackBackgroundColor = this.mobileScrollbarColors[0];
      this.inquiriesByRequestType.xAxis.scrollbar.trackBorderColor = this.mobileScrollbarColors[0];
      this.inquiriesByRequestType.xAxis.scrollbar.buttonBorderRadius = 0;
      this.inquiriesByRequestType.xAxis.scrollbar.buttonBorderWidth = 0;
      this.inquiriesByRequestType.xAxis.scrollbar.barBorderRadius = 0;
    }
    // subscribing data from local variable to reduce server hits.
    this.dashboardService.getDashboardChartData.subscribe((data: any) => {
      if (this.handleAssignedRequestTypesByGroup(data && data.requestTypeByGroup ? data.requestTypeByGroup : undefined)) {
        const elem = $('#inquiries-by-request-type');
        if (elem && elem.length) {
          HC_exporting(HighCharts);
          RoundedCorners(HighCharts);
          OfflineExport(HighCharts);
          ExportData(HighCharts);
          HighCharts.setOptions({lang: {thousandsSep: ","}});
          this.requestTypeChart = HighCharts.chart('inquiries-by-request-type', this.inquiriesByRequestType);
          this.requestTypeChart.options.ngComponent = this;
          this.setupEventHandler();
          this.enableLegendToggle(this.requestTypeChart, false);
        }
      }
    });
    this.localService.getSelectedGroupName.subscribe(group => {
      if (!group || group === this.selectedGroup) {
        this.selectedGroup = null;
      } else {
        this.selectedGroup = group;
      }
      if (this.updateChart()) {
        if (this.requestTypeChart) {
          this.requestTypeChart.destroy();
        }
        this.requestTypeChart = HighCharts.chart('inquiries-by-request-type', this.inquiriesByRequestType);
        this.requestTypeChart.redraw();
        this.setupEventHandler();
        this.enableLegendToggle(this.requestTypeChart, false);
      }
    });
  }

  /**
   * Handle request type counts by group
   * @param jsonData json response from service call
   */
  private handleAssignedRequestTypesByGroup(jsonData: string): boolean {
    if (!jsonData) {
      console.log('Invalid request types by group');
      return false;
    }
    this.chartData = JSON.parse(jsonData);
    return this.updateChart(true);
  }

  protected updateChart(updateData?: boolean): boolean {
    if (!this.chartData) {
      return false;
    }
    // reduce the array to two-layer map keyed by group and requestType
    const data = this.chartData;
    const currentGroup = this.selectedGroup;
    const allRequestTypes = new Set();
    const requestTypesByGroup = data.reduce((map: {}, item) => {
      if (item) {
        const group: string = item.group;
        if (currentGroup && group !== currentGroup) {
          return map;
        }
        const requestType: string = item.requestType;
        // if (skipped_req_types.includes(requestType)) {
        //  return map;
        // }
        const itemCount: number = item.count;
        const element = map[group];
        if (group && requestType) {
          allRequestTypes.add(requestType);
          if (element) {
            if (itemCount) {
              let existingCount: number = element[requestType];
              if (existingCount !== undefined) {
                existingCount += itemCount;
                element[requestType] = existingCount;
              } else {
                element[requestType] = itemCount;
              }
            }
          } else {
            map[group] = {};
            map[group][requestType] = itemCount ? itemCount : 0;
          }
        }
      }
      return map;
    }, {});
    // get list of sorted ageBands
    const groups: Array<string> = Object.keys(requestTypesByGroup).sort();
    const allRequestTypeArray = Array.from(allRequestTypes).sort();
    // fill in empty slots with zero counts and populate series
    this.inquiriesByRequestType.series = [];
    let index = 0;
    groups.map(group => {
      let element = requestTypesByGroup[group];
      if (!element) {
        console.log('Invalid request type item on group=' + group);
        return group;
      }
      // generate a serie from 'ownerItem'
      const serie: any = {};
      serie.name = group;
      serie.color = this.getGroupColor(group, index);
      serie.data = [];
      let augmented = false;
      let hasData = false;
      element = allRequestTypeArray.reduce((item: {}, requestType:string) => {
        if (!item[requestType]) {
          item[requestType] = 0;
          augmented = true;
        } else {
          hasData = true;
        }
        const y = item[requestType];
        serie.data.push(y);
        // push this serie to chart.series
        return item;
      }, element);
      if (augmented) {
        requestTypesByGroup[group] = element;
      }
      if (hasData) {
          serie.borderRadiusTopLeft = '50%';
          serie.borderRadiusTopRight = '50%';
      }
      this.inquiriesByRequestType.series.push(serie);
      index++;
      return group;
    });
    this.inquiriesByRequestType.xAxis.categories = allRequestTypeArray;
    return true;
  }

  getGroupColor(group, index) {
    let color;
    if (this.parentComponent) {
      color = this.parentComponent.getGroupColor(group);
    } else {
      console.log("Cannot find parent compoent from request-type chart!");
    }

    if (!color && index < this.colors.length) {
      color = this.colors[index];
    }
    return color;
  }

  setupEventHandler() {
    if (this.requestTypeChart) {
      this.requestTypeChart.series.forEach((series) => {
        if (series && series.points) {
          series.points.forEach((p) => {
            if (p && p.graphic) {
              const point = p;
              p.graphic.on('dblclick', () => {
                if (point && point.series && point.series.chart && point.series.chart.options
                  && point.series.chart.options.ngComponent) {
                    point.series.chart.options.ngComponent.handleDoubleClick(point.category, point.color, point.y, point.series.name);
                  }
              });
            }
          });
        }
      });
    }
  }

  enableLegendToggle(chart, resize) {
    if (!chart) {
      return;
    }
    if (this.chartWidth === undefined || chart.chartWidth !== this.chartWidth || chart.chartHeight !== this.chartHeight) {
      this.chartWidth = chart.chartWidth;
      this.chartHeight = chart.chartHeight;
    } else if (resize) {
      // request due to resize, but size hasn't changed, do nothing
      return;
    }
    if (!resize && chart.toggleButton && chart.toggleLabel) {
      // if not resizing but chart already has a toggle button, do nothing
      return;
    }
    // re-create the toggle label and button
    if (chart.toggleButton) {
      chart.toggleButton.destroy();
      chart.toggleButton = null;
    }
    if (chart.toggleLabel) {
      chart.toggleLabel.destroy();
      chart.toggleLabel = null;
    }
    const lWidth = Math.max(0, Math.floor(this.chartWidth - 160));
    chart.toggleLabel = chart.renderer.text(
      '<span class="mobileView" style="font-size:13px;font-family:\'InterstateLight\';text-align:right;min-width:90px;">HIDE LEGEND</span>',
      lWidth,
      20,
      true).css({
        textAlign: 'right',
        minWidth: '90px'
      }).add();
    const bbox = chart.toggleLabel.getBBox();
    chart.toggleButton = chart.renderer.label('<label class="switch-rt"><input id="request-type-legend-toggle"'
      + ' type="checkbox" checked><span class="slider-rt round-rt"></span></label>',
      lWidth + bbox.width + 5,
      5,
      'button',
      null,
      null,
      true).on('change', (e) => {
        // do nothing here
    }).add();
    // C153176-4981: add expand/collapse button
    if (window.innerWidth < 767) {
      if (chart.legendExpandButton) {
        chart.legendExpandButton.destroy();
        chart.legendExpandButton = null;
      }
      chart.legendExpandButton = chart.renderer.label(
      '<label style="font-size:16px;font-family:\'InterstateLight\';text-align:right;min-width:15px;">'
      +'<span class="fa fa-caret-down" id="rt-legend-expand-button"></span></label>',
      lWidth + bbox.width + 30,
      25,
      'button',
      null,null,true).add();
    }

    setTimeout(() => {
      const elem = $("#request-type-legend-toggle");
      elem.val(true);
      if (elem && elem.length) {
        elem.unbind();
        elem.change(e => {
          const val = !$('#request-type-legend-toggle').val();
          $('#request-type-legend-toggle').val(val);
          if (this.requestTypeChart) {
            this.toggleLegend(this.requestTypeChart);
          }
        });
      } else {
        console.log('Cannot find #request-type-legend-toggle');
      }
    }, 800);
    setTimeout(() => {
      if (window.innerWidth < 767) {
        $("#request-type-legend-toggle").prop('checked', false);
        this.hideLegend(this.requestTypeChart);
      }
    }, 900);
    // C153176-4981: handle legend toggling
    setTimeout(() => {
      if (window.innerWidth < 767) {
        const elem = $("#rt-legend-expand-button");
        $("#rt-legend-expand-button").unbind();
        $("#rt-legend-expand-button").click((e) => {
          if (!this.legendExpanded) {
            $('.legendLabelRT').removeClass('legendLabelRT').addClass('legendExpandLabelRT');
            $("#rt-legend-expand-button").removeClass('fa-caret-down').addClass('fa-caret-up');
            let parent = $('#inquiries-by-request-type-box .dashboardChart .highcharts-container .highcharts-legend .highcharts-legend-item > span');
            if (parent && parent.length) {
              parent.css('height', 'fit-content !important');
              let top = 19;
              parent.each((index, elem) => {
                $(elem).css('top', '' + top + 'px');
                top += 20;
              })
            }
          } else {
            $('.legendExpandLabelRT').removeClass('legendExpandLabelRT').addClass('legendLabelRT');
            $("#rt-legend-expand-button").removeClass('fa-caret-up').addClass('fa-caret-down');
            let parent = $('#inquiries-by-request-type-box .dashboardChart .highcharts-container .highcharts-legend .highcharts-legend-item > span');
            if (parent && parent.length) {
              parent.css('height', 'unset');
              let top = 19;
              parent.each((index, elem) => {
                $(elem).css('top', '' + top + 'px');
              })
            }
          }
          this.legendExpanded = !this.legendExpanded;
        });
      }
    }, 200);
  }

  handleDoubleClick(type, color, count, group) {
    this.requestMessageView(type, color, count, group);
    PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Dashboard", "Open Inquiries by Request type bar", "Double Click", "Open Inquiries by Request type bar", 1);
  }

  requestMessageView(type, color, count, group) {
    // C153176-4447: request with 'groupId', 'requestType' and 'isReqFromQma2Dashboard'
    const groupId = group && this.userDataService && this.userDataService.allActiveGroupIds ?
      this.userDataService.allActiveGroupIds[group]: "all";
    this.inboxService.startViewLoading({color});
    this.inboxService.requestView({viewName: "Open Inquiries By Request Type", count, type: "requestType",
      value: type, groupId: groupId, requestType: type, isReqFromQma2Dashboard: true});
  }

  // handle ESC in case of full screen
  @HostListener('document:keydown.esc', ['$event'])
  onKeydown(event) {
    event.stopPropagation();
    event.preventDefault();
    if (this.fullScreen && event && event.code === 'Escape') {
      this.exitFullScreen(this.requestTypeChart);
    }
  }

  enterFullScreen(chart: any) {
    const elem = $('#request-type-chart-box');
    if (elem && elem.length) {
      elem.toggleClass('col-sm-6');
      elem.toggleClass('col-sm-12');
      elem.toggleClass('dashboardModal');
    }
    this.fullScreen = true;
    if (chart.exportContextMenu && chart.exportContextMenu.childNodes) {
      chart.exportContextMenu.childNodes.forEach(node => {
        if (node && node.childNodes) {
          node.childNodes.forEach(n => {
            if (n && n.innerText === 'Maximize') {
               n.style.display = 'none';
            }
          });
        }
      });
    }
    chart.reflow();
    if (this.fadingDialogComponent) {
      this.fadingDialogComponent.show($('#inquiries-by-request-type-box')[0]);
    }
  }

  exitFullScreen(chart: any) {
    if (!chart) {
      return;
    }
    const elem = $('#request-type-chart-box');
    if (elem && elem.length) {
      elem.toggleClass('col-sm-6');
      elem.toggleClass('col-sm-12');
      elem.toggleClass('dashboardModal');
    }
    this.fullScreen = false;
    if (this.fadingDialogComponent) {
      this.fadingDialogComponent.hide($('#inquiries-by-request-type-box')[0]);
    }
    if (chart.exportContextMenu && chart.exportContextMenu.childNodes) {
      chart.exportContextMenu.childNodes.forEach(node => {
        if (node && node.childNodes) {
          node.childNodes.forEach(n => {
            if (n && n.innerText === 'Maximize') {
               n.style.display = '';
            }
          });
        }
      });
    }
    chart.reflow();
  }

  toggleLegend(chart) {    
    if (!chart) {
      return;
    }
    const legend = chart.legend;
    let text;
    let html;
    if (legend.display) {
      chart.legend.update( {
        enabled: false
      });
      chart.update({
        marginRight: 22
      });
      legend.display = false;
      text = 'SHOW LEGEND';
      html = '<span class="mobileView" style="font-size:13px;font-family:\'InterstateLight\';text-align:right;min-width:90px;">SHOW LEGEND</span>';
      $("#rt-legend-expand-button").hide(); // C153176-4981: hide expand/collapse toggle
    } else {
      chart.legend.update ({
        enabled: true
      });
      chart.update({
        marginRight: undefined
      });
      legend.display = true;
      text = 'HIDE LEGEND';
      html = '<span class="mobileView" style="font-size:13px;font-family:\'InterstateLight\';text-align:right;min-width:90px;">HIDE LEGEND</span>';
      $("#rt-legend-expand-button").show(); // C153176-4981: show expand/collapse toggle
    }
    if (chart.toggleLabel && chart.toggleLabel.element) {
      chart.toggleLabel.element.textContext = text;
      chart.toggleLabel.element.innerText = text;
      chart.toggleLabel.element.innerHTML = html;
    }
    chart.redraw();
  }
  hideLegend(chart) {
    if (!chart) {
      return;
    }
    const legend = chart.legend;
    let text;
    let html;
    chart.legend.update({
      enabled: false
    });
    chart.update({
      marginRight: 22
    });
    legend.display = false;
    text = 'SHOW LEGEND';
    html = '<span class="mobileView" style="font-size:13px;font-family:\'InterstateLight\';text-align:right;min-width:90px;">SHOW LEGEND</span>';

    chart.toggleLabel.element.textContext = text;
    chart.toggleLabel.element.innerText = text;
    chart.toggleLabel.element.innerHTML = html;

    chart.redraw();
    $("#rt-legend-expand-button").hide(); // C153176-4981: hide expand/collapse toggle
  }
  showLegend(chart) {
    if (!chart) {
      return;
    }
    const legend = chart.legend;
    let text;
    let html;
    chart.legend.update({
      enabled: true
    });
    chart.update({
      marginRight: undefined
    });
    legend.display = true;
    text = 'HIDE LEGEND';
    html = '<span class="mobileView" style="font-size:13px;font-family:\'InterstateLight\';text-align:right;min-width:90px;">HIDE LEGEND</span>';
    chart.toggleLabel.element.textContext = text;
    chart.toggleLabel.element.innerText = text;
    chart.toggleLabel.element.innerHTML = html;

    chart.redraw();
    $("#rt-legend-expand-button").show(); // C153176-4981: show expand/collapse toggle
  }
  onResize(event) {
    if (event.target.innerWidth < 767) // window width
    {
      this.hideLegend(this.requestTypeChart);
    } else {
      this.showLegend(this.requestTypeChart);
    }
  }
}

import { MailBoxColumnChooser } from 'src/app/model/MailBox/mailboxColumnChooser';

export let inboxGridGroupByColumnDefs = [
    {
        headerName: 'FROM',
        field: 'latestUserName',
        autoHeight: true,
        sortable: true,
        // cellRenderer: "agGroupCellRenderer",
        cellRenderer: "",
        rowGroup: false,
        hide: false,
        suppressMenu: true,
        resizable: false,
        suppressMovable: true

        // DO NOT DELETE will be used when custom category is available
        /*  cellStyle: function (params) {
           return {
             borderLeft: '5px',
             borderLeftColor: '#f1a840',
             borderLeftStyle: 'solid'
           }

         } */
    },
    {
        "headerName": "SUBJECT",
        "field": "subject",
        sortable: true,
        // "width": 100,
        "height": 50,
        rowGroup: false,
        hide: false,
        cellRenderer: "",
        resizable: false,
        suppressMovable: true,
        suppressMenu: true
    },

    {
      "headerName": "ASSIGNED TO",
      "field": "hasOwnership",
      //  "width": 100,
      sortable: true,
      rowGroup: false,
      hide: false,
      cellRenderer: "",
      resizable: false,
      suppressMovable: true,
      suppressMenu: true
    },
    {
      "headerName": "ASSIGNED FROM",
      "field": "openUsers",
      headerClass: 'multiline',
      // "width": 100,
      sortable: true,
      rowGroup: false,
      hide: false,
      cellRenderer: "",
      resizable: false,
      suppressMovable: true,
      suppressMenu: true
    }
    // ,
    // {
    //   "headerName": "Request Type",
    //   "field": "requestTypeStr",
    // },
    // {
    //   "headerName": "Processing Region",
    //   "field": "processingRegion",
    // }
    ,
    {
        "headerName": "AGE",
        "field": "age",
        sortable: true,
        autoHeight: true,
        rowGroup: false,
        hide: false,
        resizable: false,
        suppressMovable: true,
        suppressMenu: true,
        cellRenderer(params) {
            if (params.value) {
                const currentRowAge = params.data.age;
                const currentRowDate = params.data.modDate.$date;
                const monthNames = ["January", "February", "March", "April", "May", "June",
                    "July", "August", "September", "October", "November", "December"
                ];
                const dateObj = new Date(currentRowDate);
                const date = dateObj.getDate();
                const month = monthNames[dateObj.getMonth()];
                const dateHtml = "<div class='qma-inbox-date'>" + date + " " + month + "</div>";
                if (currentRowAge > 0 && currentRowAge == 1) {
                    return "<div class='qma-inbox-age qma-inbox-age-0-1'> +" + params.value + " days</div>" + dateHtml;

                }
                if (currentRowAge == 2) {
                    return "<div class='qma-inbox-age qma-inbox-age-1-2'> +" + params.value + " days</div>" + dateHtml; ;

                } if (currentRowAge > 2 && currentRowAge < 6) {
                    return "<div class='qma-inbox-age qma-inbox-age-3-5'> +" + params.value + " days</div>" + dateHtml;

                }
                if (currentRowAge > 5 && currentRowAge < 11) {
                    return "<div class='qma-inbox-age qma-inbox-age-6-10'> +" + params.value + " days</div>" + dateHtml;

                }
                if (currentRowAge > 10 && currentRowAge < 31) {
                    return "<div class='qma-inbox-age qma-inbox-age-11-30'> +" + params.value + " days</div>" + dateHtml;

                }
                if (currentRowAge > 31) {
                    return "<div class='qma-inbox-age qma-inbox-age-gt-30'> +" + params.value + " days</div>" + dateHtml;

                }
                if (currentRowAge == 0 || currentRowAge == "") {
                    return dateHtml;
                }


            }


        }
    }
    ,
    {
        "headerName": "ANNEX",
        "field": "age",
        sortable: true,
        autoHeight: true,
        resizable: false,
        suppressMovable: true,
        suppressMenu: true,
        cellRenderer(params) {
            if (params.value) {
                const notesFlag = params.data.notesFlag === "Y" ? "<span class='qma-inbox-notes'>" +
                                "<img src='assets/Inbox/notes_icon_selected_u108.svg' alt='Kiwi standing on oval'>"
                                  + "</span>" : "";
                const attachFlag = params.data.attchFlag === "Y" ? "<span class='qma-inbox-attachement'>"+
                "<img src='assets/Inbox/attachments_icon_selected_u107.svg' alt='Kiwi standing on oval'></span>" : "";
                const followupFlag = params.data.followUp === "Y" ? "<span><img class='qma-grid-followup' "+
                "src='assets/Inbox/follow_up_icon_u112.svg' alt='Kiwi standing on oval'></span>" : "";
                let nlp;
                switch (params.data.suggestionIndicator) {
                    case "O": {
                        nlp = "<span class='qma-inbox-nplm'><img  class='mailbox-img' src='assets/Inbox/nlp_icon_selected_u104_orange.svg'"+
                             " alt='Kiwi standing on oval'></span>";
                        break;
                    }
                    case "R": {
                        nlp = "<span class='qma-inbox-nplm'><img  class='mailbox-img' src='assets/Inbox/nlp_icon_selected_u104_red.svg'"+
                            " alt='Kiwi standing on oval'></span>";
                        break;
                    }
                    case "B": {
                        nlp = "<span class='qma-inbox-nplm'><img  class='mailbox-img' src='assets/Inbox/nlp_icon_selected_u104.svg'"+
                        " alt='Kiwi standing on oval'></span>";
                        break;
                    }
                    case "G": {
                        nlp = "<span class='qma-inbox-nplm'><img  class='mailbox-img' src='assets/Inbox/nlp_icon_selected_u104_green.svg'"+
                            " alt='Kiwi standing on oval'></span>";
                        break;
                    }
                    default: {
                        nlp = "";
                        break;
                    }

                }

                return nlp + notesFlag + attachFlag + followupFlag;
            }
        }
    }
];
export class InboxGroupByHelper {

     createGroupByList(mailboxColumn): MailBoxColumnChooser[] {
        const lstmailBoxColumnChooser: MailBoxColumnChooser[] = new Array();
        mailboxColumn.forEach((cols) => {
            if (cols.headerName !== "ANNEX" && cols.hide == false ) {
                lstmailBoxColumnChooser.push(
                    {
                        binary: false,
                        label: cols.headerName,
                        name: cols.headerName,
                        value: cols.headerName
                    }

                );
            }

        })
        return lstmailBoxColumnChooser;

    }

    mailBoxAgeCellRenderer() {
        return {
            "headerName": "AGE",
            "field": "age",
            sortable: true,
            autoHeight: true,
            rowGroup: false,
            hide: false,
            suppressMenu: true,
            resizable: false,
            suppressMovable: true,
            cellRenderer(params) {
                if (params.value) {
                    const currentRowAge = params.data.age;
                    const currentRowDate = params.data.modDate.$date;
                    const monthNames = ["January", "February", "March", "April", "May", "June",
                        "July", "August", "September", "October", "November", "December"
                    ];
                    const dateObj = new Date(currentRowDate);
                    const date = dateObj.getDate();
                    const month = monthNames[dateObj.getMonth()];
                    const dateHtml = "<div class='qma-inbox-date'>" + date + " " + month + "</div>";
                    if (currentRowAge > 0 && currentRowAge == 1) {
                        return "<div class='qma-inbox-age qma-inbox-age-0-1'> +" + params.value + " days</div>" + dateHtml;

                    }
                    if (currentRowAge == 2) {
                        return "<div class='qma-inbox-age qma-inbox-age-1-2'> +" + params.value + " days</div>" + dateHtml;;

                    } if (currentRowAge > 2 && currentRowAge < 6) {
                        return "<div class='qma-inbox-age qma-inbox-age-3-5'> +" + params.value + " days</div>" + dateHtml;
                    }
                    if (currentRowAge > 5 && currentRowAge < 11) {
                        return "<div class='qma-inbox-age qma-inbox-age-6-10'> +" + params.value + " days</div>" + dateHtml;

                    }
                    if (currentRowAge > 10 && currentRowAge < 31) {
                        return "<div class='qma-inbox-age qma-inbox-age-11-30'> +" + params.value + " days</div>" + dateHtml;

                    }
                    if (currentRowAge > 31) {
                        return "<div class='qma-inbox-age qma-inbox-age-gt-30'> +" + params.value + " days</div>" + dateHtml;

                    }
                    if (currentRowAge == 0 || currentRowAge == "") {
                        return dateHtml;
                    }


                }


            }
        };
    }
}

<div class="notes-header">
  <div class="d-flex flex-row justify-content-end">
    <div (click)="showHideAddNote()" [hidden]="!inquiryId">
      <img class="add-icon" [src]="'assets/newMessage/add-white.svg'" alt="">
    </div>
    <div (click)="showHideAddNote()" class="mr-auto" [hidden]="!inquiryId">
      <span [ngClass]="{'add-note-link': !toggleAdd, 'add-note-link-clicked': toggleAdd}">Add note</span>
    </div>
    <div class="note-download" (click)="downloadNotes()" *ngIf="notesList.length > 0">
      <img [src]="'assets/Inbox/download_icon_artifact.svg'" alt="">
    </div>
  </div>

  <div *ngIf="toggleAdd" class="note-area">
    <div>
      <textarea cols="30" rows="5" class="inputBox" maxlength="255" [(ngModel)]="note"></textarea>
    </div>
    <div class="btn-container px-2 py-1">
      <div class="d-flex flex-row justify-content-end">
        <div (click)="addNote()" class="d-flex flex-column align-items-center justify-content-between">
          <img class="add-img" [src]="'assets/newMessage/add-blue.svg'" alt="">
          <span>ADD</span>
        </div>
        <div (click)="cancelAddNote()" class="d-flex flex-column align-items-center justify-content-between pl-3">
          <img [src]="'assets/newMessage/crossGrey.svg'" alt="">
          <span class="grey-color">CANCEL</span>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="notes-progress">
  <ul>
    <li *ngFor="let note of notesList">
      <em class="fa fa-circle"></em>
      <div class='note-text'>
        <span class='comment-date'>{{note.commentDate.$date | date:'dd MMM hh:mm a'}}</span>
        <span class='user-by'>By {{note.userName}}</span>
        <span class="comments">{{note.comments}}</span>
      </div>
    </li>
    <li *ngIf="noteCount > 0">
      <em class="fa fa-circle"></em>
      <div class='resolved'>
          <span></span>
        </div>
    </li>
  </ul>
</div>
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { QmaConstant } from 'src/app/constant/qma-constant';
import { MailboxOnboardingResponse } from 'src/app/model/mailbox-onboarding/MailboxOnboardingResponse';

const httpFormPostOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  })
};

@Injectable({
  providedIn: 'root'
})
export class MailboxDlMappingService {

  constructor(private http: HttpClient) { }
  
  getStatus(requestObj:object){
    return this.http.post<MailboxOnboardingResponse>(QmaConstant.BASE_URL + '/readerUI/getStatus',requestObj, httpFormPostOptions);
  }

  add(requestObj:object){
    return this.http.post(QmaConstant.BASE_URL + '/readerUI/add',requestObj, httpFormPostOptions);
  }

  update(requestObj:object){
    return this.http.post(QmaConstant.BASE_URL + '/readerUI/update',requestObj, httpFormPostOptions);
  }

  remove(requestObj:object){
    return this.http.post(QmaConstant.BASE_URL + '/readerUI/remove',requestObj, httpFormPostOptions);
  }

  start(requestObj:object){
    return this.http.post(QmaConstant.BASE_URL + '/readerUI/start',requestObj, httpFormPostOptions);
  }

  stop(requestObj:object){
    return this.http.post(QmaConstant.BASE_URL + '/readerUI/stop',requestObj, httpFormPostOptions);
  }

  getDefaultExchangeData(){
    return this.http.get<MailboxOnboardingResponse>(QmaConstant.BASE_URL + '/readerUI/getDefaultExchangeData');
  }
}

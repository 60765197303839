import { Component, OnInit, Input, OnDestroy, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { NotificationService } from "../../common/services/notifications.service";
import { Subscription } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { trigger, transition, style, animate } from "@angular/animations";
import { WebsocketService } from '../../services/websocket/websocket.service';
import { UserDataService } from "src/app/services/user-data.service";
import { DateFormatPipe } from 'src/app/common/pipes/date-format.pipe';
import { InboxService } from '../../services/inbox.service';
import { ContextMenu } from 'src/app/model/Common/ContextMenu';
import { TabDataService } from '../../services/tab-data.service';
import { InquiryCache } from 'src/app/common/cache/inquiry-cache';
import { RightNavigationService } from 'src/app/services/panel/panel.service';
import { SymphonyNotificationService } from 'src/app/common/services/Symphony-notifications.service ';
import { QmaConstant } from 'src/app/constant/qma-constant';
import * as $ from 'jquery';
import { SelectedMailModel } from 'src/app/symphony/model/selected-mail.model';
import { SelectedMailRequest } from 'src/app/symphony/model/selected-mailRequest-model';
import { SymphonyChatServiceService } from 'src/app/symphony/service/symphony-chat-service.service';
@Component({
  selector: 'app-notification-symphony',
  templateUrl: './notification-symphony.component.html',
  styleUrls: ['./notification-symphony.component.scss'],

  animations: [
    trigger('items', [
      transition(':leave', [
        style({ transform: 'scale(1)', opacity: 1, height: '*' }),
        animate('1s cubic-bezier(.8, -0.6, 0.2, 1.5)',
          style({
            transform: 'scale(0.5)', opacity: 0,
            height: '0px', margin: '0px'
          }))
      ])
    ])
  ]
})
export class SymphonyNotificationComponent implements OnInit, OnDestroy {
  notificationArray: Array<any>;
  clickStatusArray: Array<boolean> = [false, false];
  native: any = 'native';
  private subscription: Subscription;
  public loginUserInfo: any;
  dateFormatPipe: DateFormatPipe;
  guserPrefDateFormat = '';
  protected requestFilter;
  public rowData: any;
  public dataExportToExcel: any;
  public strMailboxView = "";
  selectedMail: any;
  selectedInquiryId: any;
  selectedConvId: number;
  selectedInqId: number;
  componentName = "notification-symphony";
  subjectName = '';
  tabName: string = '';
  @Output() onFilter: EventEmitter<any> = new EventEmitter();
  private webSocketSubscription: Subscription;
  constructor(private wsService: WebsocketService,
    private userDataService: UserDataService,
    private inboxService: InboxService,
    private cdr: ChangeDetectorRef,
    private contextMenu: ContextMenu,
    private tabDataService: TabDataService,
    private inquiryCache: InquiryCache,
    private rightNavigationService: RightNavigationService,
    private httpClient: HttpClient,
    private symphonyNotificationService: SymphonyNotificationService,
    private symphonyChatServiceService: SymphonyChatServiceService) {
    this.userDataService.LocalGetLoginUserInfo().subscribe(loginUserData => {
      this.loginUserInfo = loginUserData;
      let dateFormat = '';
      loginUserData.preferences.map(function (val) {
        if (val.key === "dateFormatterSelectId") {
          dateFormat = val.value;
        }
      });
      //this.guserPrefDateFormat = dateFormat;
      this.guserPrefDateFormat = "dd/MMM/yy";

      if (this.inquiryCache) {
        this.inquiryCache.init(this.componentName);
      }
    });
    this.dateFormatPipe = new DateFormatPipe();
    //this. getAllNotification();
    //this.subjectName = "Pending Approval";
  }


  // Null value check function
  isNull(input) {
    if (input === "" || input === undefined || input == null) {
      return true;
    }
    return false;
  }
  onOptionClick(clickedItemIndex: number) {
    for (let i = 0; i < this.clickStatusArray.length; i++) {
      const val = this.clickStatusArray[i];
      this.clickStatusArray[i] = (clickedItemIndex === i) ? !val : false;
    }
  }
  viewNotification(item: any) {
    this.openInquiryView(item);
    this.symphonyNotificationService.filter(item);
  }

  /**
   *  get tab name based on notification item
   */
  getTabName(item: any) {
    let approvalFlag = false;
    let rejectFlag = false; // Notification open in related tab issue
    let tabName = '';
    item.workflows.forEach(item => {
      if (item.workflowStatus) {
        let approvalTest = item.workflowStatus.split("-");
        approvalFlag = approvalTest.indexOf("Approved " || "Accepted ") !== -1;
        //Notification open in related tab issue
        let approvalRejectTest = item.workflowStatus.split("-");
        rejectFlag = approvalRejectTest.indexOf("Rejected ") !== -1;
      } else {
        if (item.nominationRejectionReason) {
          tabName = "Inbox";
          this.tabName = "Inbox";
        }
      }
      if (this.tabName == "") {
        if (approvalFlag || rejectFlag) {
          tabName = "Inbox";
        } else if (item.direction === "NOMINATE_OWNERSHIP") {
          tabName = "Pending Approval";
        } else if (item.direction !== "IN" && item.direction !== "OUT") {
          tabName = "Pending Approval";
        } else if (!this.isNull(item.generalEscalationReason) && item.isConvCountEscalation === "Y") {
          tabName = "Potential Escalations";
        } else if (item.action === "FollowUp" || item.action === "Snooze" || item.action === "UnSnooze") {
          if (item.action.toLowerCase() === "snooze") {
            tabName = "Snoozed";
          } else if (item.action === "UnSnooze") {
            tabName = "Inbox";
          } else {
            tabName = "Inbox";
          }
        }
      }
    });
    return tabName;
  }

  /**
   * open inquiry with dedicated "INQUIRY:" tab
   */
  openInquiryView(item: any) {
    // re-trigger tab with the current inquiry

    let selectedChatSymphony = $.extend(true, {}, SelectedMailModel);
    let selectedChatRequest = $.extend(true, {}, SelectedMailRequest);
    selectedChatRequest.streamId = item.symphonyStreamId;
    selectedChatSymphony.inquiry.subject = item.chatRoomName;
    selectedChatRequest.selectedMailboxRow = item;
    // to refesh notes on right navigation
    let selectedInquiryId = item._id;
    let assignedGroupName = item.openGroups;
    // group list mapping need to confirm
    // selectedChatSymphony.inquiry.recipientGrps = selectedRow.assigendgroup;
    // selectedChatSymphony.inquiry.recipientSoeIds = selectedRow.toSoeIds;
    let selectedMailfromNotification = { ...selectedChatSymphony };
    selectedMailfromNotification.inquiry._id = item._id;
    let inqData = { ...selectedChatRequest };
    this.tabDataService.setInquiryData({
      tabNameforChatView: "CHAT_" + item._id,
      selectedMail: selectedMailfromNotification,
      assignedGroup: assignedGroupName,
      selectedConvId: null,
      selectedInqId: selectedInquiryId ? selectedInquiryId : null,
      inquiryWorkflow: item.workflow,
      inquirySubject: item.chatRoomName ? item.chatRoomName : '',
      inquiryReportedConvCount: 0,
      mailContainerHeight: this.inboxService.currentViewHeight,
      inqData: inqData,
      inboxViewMode: 'tab',
      inboxViewName: this.getTabName(item)
    });
    this.tabDataService.sendTabData("CHAT:" + item._id, 0);
  }

  dismissNotification(item: any, event: any) {
    this.symphonyNotificationService.dismissNotification(item);
    this.symphonyNotificationService.filterCount(this.notificationArray);
  }

  dismissAll() {
    this.symphonyNotificationService.dismissAllNotifications();
    this.symphonyNotificationService.filterCount(this.notificationArray);

  }

  ngOnInit() {
    this.subscription = this.symphonyNotificationService.notificationData.subscribe(notifications => {
      this.notificationArray = notifications;
      this.inquiryCache.addAll(this.componentName, this.notificationArray);
    });
    this.subscribeToWebSocketUpdate();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  /**
  * Method to close Panel
  */
  closePanel() {
    this.rightNavigationService.close();
  }

  subscribeToWebSocketUpdate() {
    this.webSocketSubscription = this.wsService.getwebSocketUpdate().subscribe(
      (response: MessageEvent): any => {
        try {

          if (response.data) {

            let inqIdArray = JSON.parse(response.data);

            if (inqIdArray.symphonyNotificationList && inqIdArray.symphonyNotificationList.length > 0) {
              // this.processSymphonyWebSocketUpdate(inqIdArray.symphonyNotificationList);
            }
            else if (inqIdArray && inqIdArray.wsInquiryList && inqIdArray.wsInquiryList.length > 0) {
              let t0 = performance.now()
              this.ProcessNotification(response);
              //  this.processWebSocketUpdate(inqIdArray.wsInquiryList);
              let t1 = performance.now();
              this.inboxService.triggerHttpSuccess(200);
              console.log(`Websocket:: processing for inq id:- ${inqIdArray.wsInquiryList[0]._id}  and tab:- ${this.strMailboxView} took:- ${Math.ceil((t1 - t0))} miliseconds`);

            }

          }
        }
        catch (exception) {
          console.log(exception);
        }
      }
    );
  }

  ProcessNotification(response) {
    let data: any;
    if (this.loginUserInfo.cvWebsocketConfig.isCVWebSocketUpdateEnabled
      && JSON.parse(response.data).wsInquiryList) {
      data = JSON.parse(response.data).wsInquiryList;
    }
    let action = '';
    let subject = '';
    let notificationSetting = [];
    let approvalFlag = false;
    let RejectFlag = false;
    let isUserId = false;
    if (data && data.length > 0) {
      data.forEach(inq => {
        // pre-populate action and subject with websocket message's
        action = inq.action ? inq.action : action;
        subject = inq.subject ? inq.subject : subject;
        let workflows = [...inq.workflows];
        workflows.reverse();
        workflows.forEach(item => {
          let updated = true;
          if (item.workflowStatus) {
            let approvalTest = item.workflowStatus.split("-");
            approvalFlag = approvalTest.indexOf("Approved ") !== -1;
            let approvalReject = item.workflowStatus.split("-");
            RejectFlag = approvalReject.indexOf("Rejected ") !== -1;
          }
          if (item.nominationRejectionReason) {
            action = "approvals";
            subject = "REJECTED";
          }
          if (subject !== "REJECTED") {
            if (approvalFlag) {
              action = "approvals";
              subject = "APPROVED";
            } else if (RejectFlag) {
              action = "approvals";
              subject = "REJECT";
            } else if (item.workflowStatus == "Accepted - NOMINATE_OWNERSHIP" && item.direction === "IN" && !item.nominationRejectionReason && item.modBy == this.loginUserInfo.userId) {
              action = "approvals";
              subject = "ACCEPTED";
            } else if (item.workflowStatus == "Pending - NOMINATE_OWNERSHIP" && item.direction === "NOMINATE_OWNERSHIP" && !item.nominationRejectionReason && item.modBy == this.loginUserInfo.userId) {
              action = "approvals";
              subject = "OWNERSHIP NOMINATION";
            } else if (item.direction !== "IN" && item.direction !== "OUT" && item.direction !== "NOMINATE_OWNERSHIP") {
              action = "approvals";
              subject = "PENDING APPROVAL";
            } else if (!this.isNull(item.generalEscalationReason) && item.isConvCountEscalation === "Y") {
              action = "escalation";
              subject = "ESCALATION";
            } else if (item.action === "FollowUp" || item.action === "Snooze" || item.action === "UnSnooze") {
              action = (item.action == "UnSnooze") ? "snooze" : item.action.toLowerCase();
              if (item.action === "Snooze") {
                subject = "SNOOZE";
              } else if (item.action === "UnSnooze") {
                subject = "UNSNOOZE";
              } else {
                subject = "FOLLOW UP";
              }
            } else {
              updated = false;
            }
          } else {
            updated = false;
          }
          if (!updated) {
            //if action and subject have been updated by this workflow, but action is populated in workflow, take it for both fields.
            action = item.action ? item.action : action;
            subject = item.action ? item.action.toUpperCase() : subject;
          }
          this.subjectName = subject;
        })
        if (inq.notify && inq.notify.length > 0) {
          isUserId = inq.notify.indexOf(this.loginUserInfo.userId) !== -1;
        }
        for (const key in this.loginUserInfo.notificationSettings) {
          if (this.loginUserInfo.notificationSettings[key]) {
            notificationSetting.push(key.toLowerCase());
          }
        }
        let isNotify = false;
        isUserId = false;
        if (inq.action && inq.action === QmaConstant.symphonyChatActionsEnum.NEWCHAT ||
          inq.action && inq.action === QmaConstant.symphonyChatActionsEnum.ADDEDMEMBER ||
          inq.action && inq.action === QmaConstant.symphonyChatActionsEnum.REMOVEDMEMBER) {
          isNotify = true;
        }
        inq.crtDate.$date = Object(this.dateFormatPipe.transform(inq.crtDate.$date, this.guserPrefDateFormat));
        const indx = this.inquiryCache.findIndex(this.componentName, inq._id);
        if ((isNotify) || (isUserId)) { // Notification should push for every change on inquiry
          data[0].subject = subject;
          data[0].chatRoomName = inq.symphonyChatroomName ? inq.symphonyChatroomName : "";
          this.notificationArray.unshift(inq);
          this.inquiryCache.add(this.componentName, inq, this.notificationArray.length - 1, this.notificationArray.length);
          this.symphonyNotificationService.setNotificationCount(this.notificationArray);
          this.symphonyNotificationService.filterCount(this.notificationArray);
          this.loadAllSymphonyChatMmbers(inq.symphonyStreamId, inq);
        }
      });

    }

  }


  loadAllSymphonyChatMmbers(streamId, inq) {

    const reqObj = {
      streamId: streamId
    };
    this.symphonyChatServiceService.getSymphonyMemberLists(reqObj).subscribe((ml: any) => {
      // notify updated members so they can be displayed in chatview
      this.symphonyChatServiceService.setwsSymphonyMembers({ mlRes: ml, chat: inq });
      // if current user is removed dont show show icon
      if (ml && ml.members && ml.members.length > 0) {
        let currentUserSymphonyId = +this.loginUserInfo.symphonyId;
        let chatSymIds = ml.members.map(m => m.user.userId);
        if (!chatSymIds.includes(currentUserSymphonyId)) {
          inq.isCurrentUserMember = false;
        }

      }

    }, (error) => {


    });
  }

}

import { MailBoxColumnChooser } from 'src/app/model/MailBox/mailboxColumnChooser';

export class MailBoxSecColumnChooser {

    getSecondaryColumnslst(mailboxSecondaryColumns) {
        let lstmailBoxColumnChooser: MailBoxColumnChooser[] = new Array();
        try {

            mailboxSecondaryColumns.forEach((cols) => {
                if (cols.headerName !== "ANNEX" && cols.field !== "cheveron" && cols.field !== "checkbox" && cols.field !== "category" && cols.field !== "attchFlag") {
                    if (cols.showInSecondary == true) {
                        lstmailBoxColumnChooser.push(
                            {
                                binary: true,
                                label: cols.headerName.toUpperCase(),
                                name: cols.headerName.toUpperCase(),
                                value: cols.headerName.toUpperCase()
                            })
                    }
                    else if (cols.showInSecondary == false && cols.hide == true) {
                        lstmailBoxColumnChooser.push(
                            {
                                binary: false,
                                label: cols.headerName.toUpperCase(),
                                name: cols.headerName.toUpperCase(),
                                value: cols.headerName.toUpperCase()
                            })
                    }

                }


            })
        }
        catch (exp) {
            console.log(exp);
        }
        return lstmailBoxColumnChooser;
    }
}
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import html2canvas from 'html2canvas';


@Injectable({
  providedIn: 'root'
})
export class DashboardLocalService {
  getSelectedGroupName = new BehaviorSubject("");

  constructor() { }

  setSelectedGroupName(gruopName: string) {
    this.getSelectedGroupName.next(gruopName);
  }

  download(elemId, option) {
    if (elemId && option)
      setTimeout(() => {
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
        html2canvas(document.getElementById(elemId), { allowTaint: true, scale: 1.5 }).then(function (canvas) {
          switch (option) {
            case "jpg":
              var a = document.createElement('a');
              a.href = canvas.toDataURL("image/jpeg").replace("image/jpeg", "image/octet-stream");
              a.download = elemId + ".jpg"; // id will be the name of chart
              a.click();
            break;
            case "png":
              var a = document.createElement('a');
              a.href = canvas.toDataURL("image/jpeg").replace("image/png", "image/octet-stream");
              a.download = elemId + ".png"; // id will be the name of chart
              a.click();
            break;
            case "pdf":
              var data = canvas.toDataURL();
              var docDefinition = {
                content: [{
                  image: data,
                  width:500
                }]
              };
              pdfMake.createPdf(docDefinition).download(elemId); // id will be the name of chart
            break;
          }
        });

      }, 0)
  }
}

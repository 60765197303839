import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {
  public transform(value, keys: string, term: string) {
// In Right Nav Contacts, user is not able to search string starts with
    if (!term) return value;
    if (term.includes('*')) {//if asterik in string
      return (value || []).filter(item => keys.split(',').some(key => item.hasOwnProperty(key) && item[key].toLowerCase().includes(term.toLowerCase())));
    } else {
      return (value || []).filter(item => keys.split(',').some(key => item.hasOwnProperty(key) && new RegExp(term, 'gi').test(item[key])));
    }

  }
}

import { MailBoxColumnChooser } from 'src/app/model/MailBox/mailboxColumnChooser';
export class MailboxColumnChooserHelper{
   
    createColumnChooserList(mailboxColumn): MailBoxColumnChooser[] {
        let lstmailBoxColumnChooser: MailBoxColumnChooser[] = new Array();
        mailboxColumn.forEach((cols) => {
            if (cols.headerName !== "ANNEX" && cols.headerName !== "Attachment" && cols.headerName !== "Notes" && cols.headerName !== "Follow Up" && cols.headerName !== "Suggestion Indicator") {
                if (cols.hide == false) {
                    lstmailBoxColumnChooser.push(
                        {
                            binary: true,
                            label: cols.headerName.toUpperCase(),
                            name: cols.headerName.toUpperCase(),
                            value: cols.headerName.toUpperCase()
                        }

                    );
                }
                else {
                    lstmailBoxColumnChooser.push(
                        {
                            binary: false,
                            label: cols.headerName.toUpperCase(),
                            name: cols.headerName.toUpperCase(),
                            value: cols.headerName.toUpperCase()
                        })
                }
            }

        })
        return lstmailBoxColumnChooser;

    }
}
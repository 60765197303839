import { Component, OnInit, OnDestroy } from '@angular/core';
import { ContextMenuService } from 'src/app/services/contextMenu/context-menu.service';
import { Subscription } from 'rxjs';
import { UserDataService } from 'src/app/services/user-data.service';
import { PiwikProUtils } from '../../utility/piwikProUtils';

@Component({
  selector: 'app-link-exception',
  templateUrl: './link-exception.component.html',
  styleUrls: ['./link-exception.component.scss']
})
export class LinkExceptionComponent implements OnInit,OnDestroy  {
  display:boolean = false;  
  linkExceptionModel:any;
  selectedMenuItem:any;
  contextServiceObject:any;
  subManager = new Subscription();

  constructor(private userDataService: UserDataService,private contextMenuService:ContextMenuService) {
    this.contextServiceObject = contextMenuService;
   }
  ngOnInit() {}

  
  triggerFalseClick() {
    this.linkExceptionModel = "";
    this.display = true;
  }

  hideModal(){
    this.display = false;
  }

  saveEnquirySearch(form:any){
    if(form.valid){
    let sub1 = this.contextServiceObject.httpSetLinkExceptionID(this.selectedMenuItem, form.value.linkExceptionModel).subscribe(res => console.log(res));;
    this.subManager.add(sub1);    
    this.hideModal();
    }
    PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Inquiry view", "Context menu - Link Exception", "Click", "Context menu - Link Exception", 0);
  }

  ngOnDestroy(){
    this.subManager.unsubscribe();
  }
}

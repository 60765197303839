import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { UserDataService } from "src/app/services/user-data.service";
import { AppUtils } from "src/app/common/utility/appUtil";
import { QmaConstant } from "src/app/constant/qma-constant";

enum USER_TYPE {
  INDIVIDUAL = "IS_INDIVIDUAL",
  GROUP = "IS_GROUP",
  EXTERNAL = "IS_EXTERNAL"
}

enum DEFAULT_IMAGE {
  INDIVIDUAL = "assets/common/person.jpg",
  GROUP = "assets/common/group_icon.svg",
  EXTERNAL = "assets/common/external_icon.svg"
}
enum DEFAULT_IMAGE_SYMPHONY {
  INDIVIDUAL = "assets/symphony/other-qma-user.png",
  GROUP = "assets/symphony/rainbow-box-square.png",
  EXTERNAL = "assets/symphony/extenalUser.png" // extenalUser
}
interface Recipient {
  displayName: string,
  emailAddr: string,
  groupId: number,
  toFrom: string,
  userId: string,
  isExternal: boolean
}

@Component({
  selector: 'app-image-placeholder',
  templateUrl: './image-placeholder.component.html',
  styleUrls: ['./image-placeholder.component.scss']
})
export class ImagePlaceholderComponent implements OnInit {
  get userType() { return USER_TYPE; }

  @Input() recipient?: Recipient;
  @Input() recipients?: Recipient[];
  @Input() styleClass:string;
  @Input() symphonyRedesign: boolean = false;

  type: string;
  imageURL: string = "";
  content?: string;

  imageSource: string;
  initials: string = '';
  activeGroups: string[] = [];
  citiDomainlist: string[] = [];

  constructor(private userService: UserDataService) {
    this.userService.LocalGetLoginUserInfo().subscribe(loggedInUser => {
      this.activeGroups = this.userService.allActiveGroupList;
      this.citiDomainlist = loggedInUser.citiDomainList;
    });
  }

  ngOnInit() {
    if (this.recipient) {
      this.setRecipientImage(this.recipient);
    }
    else if (this.recipients) {
      const recipient: Recipient = this.recipients.find(rec => rec.toFrom === QmaConstant.FROM);
      this.setRecipientImage(recipient);
    }
  }

  /**
   * Method to identify the user based on Id or email address and set the its type.
   * @param recipient 
   */
  setRecipientImage(recipient: Recipient): void {
    const soeIdPattern = /^[a-zA-Z]{2}\d{5}/;
    const mailPattern = /(^[a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/;

    if (recipient) {
      let userId = recipient.userId || recipient.emailAddr;
      const groupId = recipient.groupId;
      this.content = recipient.displayName ||userId;

      // Check if the userid is group 
      if (this.activeGroups.indexOf(userId.toLowerCase()) > -1 || groupId > 0) {  // Add condition for group icon.
        this.getImageSource(USER_TYPE.GROUP);
      }

      // Check if the user is external
      else if (!AppUtils.isCitiDomainEmail(userId, this.citiDomainlist) && groupId === null) {
        this.getImageSource(USER_TYPE.EXTERNAL);
      }

      // Fetch the first and Last letter.
      else if (soeIdPattern.test(userId) || mailPattern.test(userId)) {

        if (soeIdPattern.test(userId)) {
          const soeId = userId.match(soeIdPattern);
          const id = soeId[0];
          this.initials = id.substring(0, 2);
        }
        else if (mailPattern.test(userId)) {
          const soeId = userId.match(/^[a-zA-Z0-9._-]+/);
          const id = soeId[0];
          // This is email id, we need to get the initails
          const emailArr: string[] = id.split('.');
          this.initials = emailArr[0].charAt(0) + emailArr[emailArr.length - 1].charAt(0);
        }
       
        this.getImageSource(USER_TYPE.INDIVIDUAL);
      }
      else{
        this.initials = userId.toUpperCase().substring(0, 2);
        this.getImageSource(USER_TYPE.INDIVIDUAL);
      }
    }
  }

  /**
   * Method to set the image source based on type of recipient.
   * @param type 
   */
  getImageSource(type: string): void {
    if(this.symphonyRedesign) {
      return this.getImageSourceSymphonyRedesign(type);
    }
    switch (type) {
      case USER_TYPE.INDIVIDUAL:
        this.type = USER_TYPE.INDIVIDUAL;
        this.imageSource = this.imageURL ? this.imageURL : DEFAULT_IMAGE.INDIVIDUAL;
        break;
      case USER_TYPE.GROUP:
        this.type = USER_TYPE.GROUP;
        this.imageSource = this.imageURL ? this.imageURL : DEFAULT_IMAGE.GROUP;
        break;
      case USER_TYPE.EXTERNAL:
        this.type = USER_TYPE.EXTERNAL;
        this.imageSource = this.imageURL ? this.imageURL : DEFAULT_IMAGE.EXTERNAL;
        break;
    }
  }

  getImageSourceSymphonyRedesign(type: string): void {
    switch (type) {
      case USER_TYPE.INDIVIDUAL:
        this.type = USER_TYPE.INDIVIDUAL;
        this.imageSource = this.imageURL ? this.imageURL : DEFAULT_IMAGE_SYMPHONY.INDIVIDUAL;
        break;
      case USER_TYPE.GROUP:
        this.type = USER_TYPE.GROUP;
        this.imageSource = this.imageURL ? this.imageURL : DEFAULT_IMAGE_SYMPHONY.GROUP;
        break;
      case USER_TYPE.EXTERNAL:
        this.type = USER_TYPE.EXTERNAL;
        this.imageSource = this.imageURL ? this.imageURL : DEFAULT_IMAGE_SYMPHONY.EXTERNAL;
        break;
    }
  }

}

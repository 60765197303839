import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { UserDataService } from "src/app/services/user-data.service";
import { QmaConstant } from '../constant/qma-constant';
import { AppUtils } from 'src/app/common/utility/appUtil';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { SessionTimeOut } from './sessiontimeout.service';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
    constructor(private sessionTimeoutService: SessionTimeOut){

    }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // reset session timeout counter on each successful request
        return next.handle(request).pipe(
            map(event => {
              if (event instanceof HttpResponse) {
                // Do stuff with your response
                if (event && event.url && event.url.indexOf('portal-auth/jwtSSO/idpService?vendor-issuer=websocket-cv-saml')===-1) {
                  // this.sessionTimeoutService.resetCounter();
                }
              }
              return event;
            }),
            catchError((err: any, caught) => {
              if (err instanceof HttpErrorResponse) {
                return throwError(err);
              }
            }));
    }
}

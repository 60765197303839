<p-dialog [(visible)]="display" [modal]="true" [responsive]="true" [maximizable]="false"
[appendTo]="'body'" [styleClass]="'contextMenuModal profileModal'" [closable]="false" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true"
[style]="{'height':'830px','max-width':'1350px', 'width': '90%'}" [resizable]="false">

  <p-header>
    <div class="d-flex flex-row align-items-center justify-content-between" style="padding: 5px 5px 0px px;">
      <span class="gfid-title" style="font-size: 12px;width: 12em;"> </span>
      <button type="button" (click)="hideModal()" class="d-flex flex-row close qma-text"
        style="border:none !important;opacity:1">
        <img src="assets/Inbox/close_icon_u73.svg" style="opacity:1" alt="Close">
        <h6>CLOSE</h6>
      </button>
    </div>
  </p-header>

  <div class="gfid-body admin-gfid-padding">
    <ng-template #customLoadingTemplate>
        <div class="custom-class-loader">
            <div class="spinner-container">
                <svg class="loader" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340 340">
                    <circle cx="170" cy="170" r="160" stroke="#021e48" />
                    <circle cx="170" cy="170" r="135" stroke="#00bdf2" />
                    <circle cx="170" cy="170" r="110" stroke="#021e48" />
                    <circle cx="170" cy="170" r="85" stroke="#00bdf2" />
                </svg>
            </div>
        </div>
    </ng-template>
    <ngx-loading [show]="showSpinner" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate">
    </ngx-loading>

    <div class="w-80">
        <p-tabView class="adminview" #profileTabs (onChange)="onTabChange($event)" [styleClass]="'user-admin-tabview'" [scrollable]="false"
        [style]="{'height':'1075px','width':'90%','max-width':'1200px','margin-left':'4rem',overflow: 'hidden', 'position': 'relative'}"
        [activeIndex]="actTabIdx">
        <p-tabPanel header="Group Setup" class="tab-heading">
          <div class="row p-2">
            <div class="col-4">
              <label for="from" class="flex-grow flex-grow pull-left">Group Details: </label>
            </div>
            <div class="col-8"></div>
          </div>
          <div class="container">
            <div class="row p-2">
              <div class="col-3">
                <label for="from" class="flex-grow pull-right">Group: </label>
              </div>
              <div class="col-9" *ngIf="!loading">
                <p-dropdown *ngIf="!isNewGroupSetup" [styleClass]="'prime-dd-override '" class="drop-down w-50"
                  [(ngModel)]="selectedGroup" filter="true" [options]="groupsList" (onChange)="showUserGroupRelData()" [virtualScroll]="true" itemSize="5" [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'">
                </p-dropdown>
                <input *ngIf="isNewGroupSetup" type="text" [(ngModel)]="newGroupName" class="w-50  t-box inp-txt-mng group-details-input-mng" (input)="groupNameErrorMessage=''"/>
                <input type="checkbox" class="m-2" [(ngModel)]="isNewGroupSetup" (change)="toggleGroupMode()"><span>Add new Group</span>
                <p class="has-error" *ngIf="groupNameErrorMessage!=''">{{groupNameErrorMessage}}</p>
              </div>
            </div>
            <div class="row p-2">
                <div class="col-3">
                <label for="from" class="flex-grow flex-grow pull-right">Group Manager: </label>
                </div>
                <div class="col-9">
                <span style="width:388px;">
                  <input type="text"  [(ngModel)]="groupManager" placeholder="Enter SOEID..." class="w-50 t-box inp-txt-mng group-details-input-mng"/>
                </span>
               <span style="padding-top:10px;">
                  <button style="width: 86px !important;opacity:1;float: inherit !important;" type="button" class="close qma-text grp-details-mng-lookup"
                      (click)="callLookupService()">
                      <img class="qma-text" style="opacity:1" src="assets/Inbox/search_icon_selected_u42.svg" alt="Lookup">
                      <h6 style="font-size:14px;padding-left:3px;display: inline;">LOOKUP</h6>
                  </button>
              </span>
              <span *ngIf="validationFlag" style="color: red;padding-top:10px;">{{validationMsg}} The userid entered is not valid </span>
              <span *ngIf="groupManager&&!groupManager.trim()&&groupManager.trim().length==0" style="color: red;padding-top:10px;">{{blankStringValidationMsg}}</span>
              </div>
              
          </div>
            <div class="row p-2">
              <div class="col-3">
                <label for="from" class="flex-grow pull-right">Email: </label>
              </div>
              <div class="col-9 mt-2">
                <input type="text" [(ngModel)]="email" class="w-50  t-box inp-txt-mng group-details-input-mng" (input)="emailErrorMessage=''"/>
                <p class="has-error" *ngIf="emailErrorMessage!=''">{{emailErrorMessage}}</p>
              </div>
            </div>

            <div class="row p-2">
              <div class="col-3">
                <label for="from" class="flex-grow pull-right">Country: </label>
              </div>
              <div class="col-9"  *ngIf="!groupLoading">
                <p-dropdown [styleClass]="'prime-dd-override '" class="drop-down w-50" 
                  [(ngModel)]="selectedCountry" [options]="countriesList">
                </p-dropdown>
              </div>
            </div>
            <div class="row p-2">
              <div class="col-3">
                <label for="from" class="flex-grow pull-right">Enable MIS Reporting: </label>
              </div>
              <div class="col-9">
                <p-radioButton name="yes" value="Yes" [(ngModel)]="isEnableReportingSelected" label="Yes" (onClick)="setSelectedRadio('Yes')" 
                class="yesRadioClass" ></p-radioButton>
                <p-radioButton name="yes" value="No" [(ngModel)]="isEnableReportingSelected" label="No" (onClick)="setSelectedRadio('No')" 
                class="noRadioClass" ></p-radioButton>
              </div>
            </div>
            <div class="row p-2">
              <div class="col-3">
                <label for="from" class="flex-grow pull-right">Start Date:</label>
              </div>
              <div class="col-5 calendarCls">
                <p-calendar dateFormat="mm/dd/yy" [(ngModel)]="misReportingDate" [showOtherMonths]="true" 
                  [hideOnDateTimeSelect]="true" [selectOtherMonths]="false"
                  [monthNavigator]="true" [yearNavigator]="true"  [yearRange]="misDateRange"
                  [styleClass]="'advance-calender schedule-cal tab-calender-fix'"
                  [readonlyInput]="false" [showIcon]="true" [minDate]="minimumDate" [disabled]="isEnableReportingSelected != 'No'"
                  placeholder="MM/DD/YYYY">
                </p-calendar>
              </div>
            </div>

            <div class="row p-2">
              <div class="col-3">
                <label for="from" class="flex-grow pull-right">Time Zone: </label>
              </div>
              <div class="col-9" *ngIf="!groupLoading">
                <p-dropdown [styleClass]="'prime-dd-override '" class="drop-down w-50" 
                  [(ngModel)]="selectedTimeZone" [options]="timeZonesList">
                </p-dropdown>
              </div>
            </div>
          </div>
          <div class="row p-2">
            <div class="col-4">
              <label for="from" class="flex-grow flex-grow pull-left">Group Admin:</label>
            </div>
            <div class="col-8"></div>
          </div>
          <div class="container">
            <div class="row p-2">
              <div class="col-2 align-middle">
                <label for="from" class="flex-grow pull-right">SOEID: </label>
              </div>
              <div class="col-3  p-2 align-middle">
                <input type="text"  [(ngModel)]="soeId" class="t-box inp-txt-mng group-details-input-mng" />
                <p class="has-error" *ngIf="userErrorMessage!=''">{{userErrorMessage}}</p>
              </div>
              <div class="col-1 align-middle">
                <button type="button" class="btn select-btn add-btn" (click)="addUserGroupSetupsRight()"><i class="fa fa-angle-right"
                    aria-hidden="true"></i></button>
              </div>
              <div class="col-3" *ngIf="!groupLoading">
                <p-listbox [options]="selectedSoeIDsList" [listStyle]="{'height':'160px','max-height':'180px','max-width':'254px'}" readonly="true">
                  <p-header>
                    <label>SOEIDs</label>
                  </p-header>
                  <ng-template let-item pTemplate="item">
                    <span *ngIf="item">{{item.label}}       
                    </span>
                  </ng-template>
                </p-listbox>
              </div>
              <div class="col-3"></div>
            </div>
          </div>
          <div class="row p-2">
            <div class="col-4">
              <label for="from" class="flex-grow flex-grow pull-left">Management Hierarchy: </label>
            </div>
            <div class="col-8"></div>
          </div>
          <div class="container">
            <div class="row p-2">
              <div class="col-3">
                <label for="from" class="flex-grow pull-right">Business Segment: </label>
              </div>
              <div class="col-9">
                <!-- <p-dropdown [styleClass]="'prime-dd-override '" class="drop-down w-50" 
                  [(ngModel)]="selectedBusinessSegment" [options]="businessSegmentsList">
                </p-dropdown> -->
                <input type="text" [(ngModel)]="selectedBusinessSegment" class="t-box w-50 inp-txt-mng group-details-input-mng" disabled/>
              </div>
            </div>
            <div class="row p-2">
              <div class="col-3">
                <label for="from" class="flex-grow pull-right">Level 1 Hierarchy: </label>
              </div>
              <div class="col-9">
                <!-- <p-dropdown [styleClass]="'prime-dd-override '" class="drop-down w-50" [disabled]="true"
                  [(ngModel)]="selectedL1" [options]="L1List">
                </p-dropdown> -->
                <input type="text" [(ngModel)]="selectedL1" class="t-box w-50 inp-txt-mng group-details-input-mng" disabled/>
              </div>
            </div>
            <div class="row p-2">
              <div class="col-3">
                <label for="from" class="flex-grow pull-right">Level 2 Hierarchy: </label>
              </div>
              <div class="col-9">
                <!-- <p-dropdown [styleClass]="'prime-dd-override '" class="drop-down w-50" [disabled]="true"
                  [(ngModel)]="selectedL2" [options]="L2List">
                </p-dropdown> -->
                <input type="text" [(ngModel)]="selectedL2" class="t-box w-50 inp-txt-mng group-details-input-mng" disabled/>
              </div>
            </div>
            <div class="row p-2">
              <div class="col-3">
                <label for="from" class="flex-grow pull-right">Level 3 Hierarchy: </label>
              </div>
              <div class="col-9">
                <input type="text" [(ngModel)]="selectedL3" class="t-box w-50 inp-txt-mng group-details-input-mng" disabled/>
              <!--   <button type="button" class="btn btn-primary add-btn" (click)="addLev3GrpSetup()">Add</button>
                <p class="has-error" *ngIf="level3UserErrorMessage!=''">{{level3UserErrorMessage}}</p> -->
              </div>
            </div>
  
            <div class="row p-2">
              <div class="col-3">
                <label for="from" class="flex-grow pull-right">GFCID: </label>
              </div>
              <div class="col-9">
                <p-dropdown [styleClass]="'prime-dd-override '" class="drop-down w-50" 
                  [(ngModel)]="selectedGFCID" [options]="GFCIDsList">
                </p-dropdown>
              </div>
            </div>
            <div class="row p-2">
              <div class="col-3">
                <label for="inActive" class="flex-grow pull-right">Inactive: </label>
              </div>
              <div class="col-9">
                <div class="preference-switch">
                  <p-inputSwitch id="inActive" [(ngModel)]="inactive" [styleClass]="'advance-inputSwitch'">
                  </p-inputSwitch>
                </div>
              </div>
            </div>
          </div>
        </p-tabPanel>
        <!--for error message-->
        <p-dialog [(visible)]="hasError" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'"
          [styleClass]="'contextMenuModal errorModal ModelClose'" [closable]="true" [dismissableMask]="true"
          [baseZIndex]="10000" [closeOnEscape]="true" [style]="{width: '30%'}" (hide)="hasError=false">
          <p-header>
            <div class="d-flex flex-row align-items-center justify-content-between">
              <h6 class="success-title">Error Message</h6>
            </div>
          </p-header>
          <div class="success-msg">{{errorMessage}}</div>
        </p-dialog>
        <!--for success message-->
        <p-dialog [(visible)]="hasSuccess" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'"
        [styleClass]="'contextMenuModal successModal ModelClose'" [closable]="true" [dismissableMask]="true"
        [baseZIndex]="10000" [closeOnEscape]="true" [style]="{width: '30%'}" (hide)="hasSuccess=false">
        <p-header>
          <div class="d-flex flex-row align-items-center justify-content-between">
            <h6 class="success-title">Success Message</h6>
          </div>
        </p-header>
        <div class="success-msg">{{successMessage}}</div>
      </p-dialog>

        <p-footer class="fix-footer">
          <div class="d-flex flex-row footer-btn-container"></div>
          <div class="d-flex flex-row flex-wrap bottom-button-holder px-3 pb-2 pt-3 justify-content-end">
            <button type="submit" class="btn save-btn mr-4" (click)="saveGroupAdmininfo()">Submit</button>
            <button type="button" class="btn clear-btn" (click)="resetForm()">Reset</button>
          </div>
        </p-footer>
      </p-tabView>
    </div>
  </div>
</p-dialog>
import { Component, OnInit, ViewChildren, QueryList, AfterViewInit, ElementRef, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { ExpTinyComponent } from 'src/app/common/component/exp-tiny/exp-tiny.component';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { HelpService } from 'src/app/services/help/help.service';
import { AppUtils } from 'src/app/common/utility/appUtil';
import SupportFeedback from 'src/app/model/help/supportFeedback';
import { UserDataService } from 'src/app/services/user-data.service';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { OverlayPanel } from "primeng//overlaypanel";
import { InboxService } from 'src/app/services/inbox.service';
import { NewMessageService } from 'src/app/services/newMessage/new-message.service';
import { RightNavigationService } from 'src/app/services/panel/panel.service';
import { QmaConstant } from 'src/app/constant/qma-constant';
interface attachmentOption {
  name: string,
  id: string,
  fileInfo: any
}
@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
})
export class HelpComponent implements OnInit, AfterViewInit {
  requestTypes = [];
  priorities = [];
  requestType: string;
  priority: string;
  subject: string;
  activeMenuArray: any = [false, false, false, false];
  display: boolean = false;
  editorFlag: boolean = false;
  emailDisc: string = ' ';
  action: string;
  notificationFrom: string;
  notificationSubject: string;
  attachments: any[];
  attachmentsOptions: any = [];
  attachmentLength: any = 0;
  postDataForAttachmentFile: Array<any> = [];
  isElipsisShow: boolean = false;
  @ViewChild('uploadFiles', { static: true }) uploadFiles: ElementRef;
  files: FileList;
  token: string;
  @ViewChildren(ExpTinyComponent) editorList: QueryList<ExpTinyComponent>;
  public parentForm: FormGroup;
  public errMsg = "";
  public displayError = false;
  public qmaConstant = QmaConstant;
  constructor(private newMessageService: NewMessageService, private formBuilder: FormBuilder, private helpService: HelpService, private userDataService: UserDataService, private messageService: MessageService, private inboxService: InboxService, private rightNavigationService:RightNavigationService) {

    this.requestTypes.push({ label: "Request Type:", value: "Request Type:" });
    this.requestTypes.push({ label: "Enhancement", value: "Enhancement" });
    this.requestTypes.push({ label: "General Feedback", value: "General Feedback" });

    this.priorities.push({ label: "Request Priority:", value: "Request Priority:" });
    this.priorities.push({ label: "Highest", value: "Highest" });
    this.priorities.push({ label: "High", value: "High" });
    this.priorities.push({ label: "Medium", value: "Medium" });
    this.priorities.push({ label: "Low", value: "Low" });
  }
  ngOnInit() {
    this.parentForm = this.formBuilder.group({
      requestType: new FormControl('', []),
      priority: new FormControl('', []),
      subject: new FormControl('', []),
      attachments: new FormControl('', [])
    });
  }
  ngAfterViewInit() {
    let exp: ExpTinyComponent;
    this.editorList.forEach(div => {
      if (div.elementId == "emailDisc") {
        exp = div;
        exp.setData(this.emailDisc);
      }
    });
  }
  onMouseOver(index) {
    this.activeMenuArray[index] = true;
  }
  onMouseOut(index) {
    this.activeMenuArray[index] = false;
  }
  openFeedbackDialog() {
    this.display = true;
    this.editorFlag = true;
    this.resetHelp();
    this.postDataForAttachmentFile = [];
  }

  hideModal() {
    this.display = false;
    this.editorFlag = false;
    this.resetHelp();
    this.postDataForAttachmentFile = [];
  }

  mailSupport() {
  
    window.location.href = "XXXX";
  }

  /**
   * Method to send feedback.
   */
  sendFeedback() {
    try {
      this.emailDisc = this.getEditorInstance("emailDisc").getData();
      this.requestType = this.parentForm.get('requestType').value ? this.parentForm.get('requestType').value : '';
      this.priority = this.parentForm.get('priority').value ? this.parentForm.get('priority').value : '';
      this.subject = this.parentForm.get('subject').value ? this.parentForm.get('subject').value : '';
      let attachment = this.parentForm.get('attachments').value ? this.parentForm.get('attachments').value : [];

      let SupportFeedback_list = {
        action: "USERFEEDBACK",
        notificationFrom: this.userDataService.loggedInUserInfo.email,
        notificationSubject: this.requestType + " " + "(Priority: " + this.priority + ")" + this.subject,
        token: AppUtils.getToken(),
        attachment: attachment
      }
      this.messageService.clear();
      this.helpService.helpNotification(SupportFeedback_list, this.emailDisc).subscribe(result => {
        if (result.processingstatus) {
          this.hideModal();
          this.messageService.add({ key: 'myKey1', severity: 'success', summary: 'Help Success', detail: 'Thank you for providing the feedback, your feedback is captured successfully.' });
        }
      }, error => {
        console.error('Failed to send the feedback due to ', error);
      });
    }
    catch (e) {
      console.error("Exception in QMA 2 while sendFeedback() : ", e);
    }
  }

  /**
  * Method to get the Editor instance.
  * 
  * @param id - Editor element id
  */
  getEditorInstance(id: string): ExpTinyComponent {
    let exp: ExpTinyComponent;
    this.editorList.forEach(div => {
      if (div && div.elementId == id)
        exp = div;
    });
    return exp;
  }
  /**
   * Method to reset Help Dialog.
   */
  resetHelp(): void {
    this.parentForm.reset();
  }
  /**
   * Method to showattachments.
   */
  showRemainingAttchment(event, overlaypanel: OverlayPanel, attachmentList: any): void {
    this.attachmentsOptions = [];
    for (let i = this.attachmentLength; i < attachmentList.length; i++) {
      let attachInfo: attachmentOption = {
        name: attachmentList[i].name,
        id: attachmentList[i].id,
        fileInfo: attachmentList[i]
      };
      this.attachmentsOptions.push(attachInfo);
    }
    overlaypanel.toggle(event);
  }
  /**
   * download attachment
   */
  downloadAttachment(docId: string, docName: string, convId:number, groupId: number, hasNominatedOwnership:boolean): void {
    this.inboxService.downloadAttachment(docId, docName, convId, groupId, hasNominatedOwnership);
  }
  /**
   * delete attached file when click on delete icon 
    */
  deleteAttachedFile(id) {
    let elem = document.getElementById(id);
    elem.remove();
    if (this.postDataForAttachmentFile.length === this.attachmentLength + 1) {
      this.isElipsisShow = true;
    }
    for (let i = 0; i < this.postDataForAttachmentFile.length; i++) {
      if (this.postDataForAttachmentFile[i].id === id) {
        this.postDataForAttachmentFile.splice(i, 1);
      }
    }

    this.parentForm.get('attachments').setValue(this.postDataForAttachmentFile);
    console.log('attached files :', this.postDataForAttachmentFile);
  }
  /**
   * Check overflow width 
    */
  checkOverFlow(element) {
    if (element.clientWidth < element.scrollWidth) {
      this.attachmentLength = Math.floor(element.clientWidth / 127);
      return true;
    }
  }
  uploadFile() {
    this.uploadFiles.nativeElement.click();
  }

  fileEvent(e) {
    try {
      this.files = e.target.files;
      let data = new FormData();
      let hasInvalidFiles = false;
      let restrictedFilesList = [];
       // C170665-255 Restrict specific file extensions in QMA 2.0
       let QMA_VALID_FILE_EXTENSIONS = this.userDataService.loggedInUserInfo.allowedFileExtensions? this.userDataService.loggedInUserInfo.allowedFileExtensions:
       QmaConstant.QMA_VALID_FILE_EXTENSIONS;
      if (this.files) {
        for (let i = 0; i < this.files.length; i++) {
           // C170665-255 Restrict specific file extensions in QMA 2.0
        if(this.files.item(i).type && QMA_VALID_FILE_EXTENSIONS.includes(this.files.item(i).name.split('.').pop().toLowerCase()))
        {
          data.append('files', this.files.item(i), this.files.item(i).name);
        }else { // if invalid files show error message
          hasInvalidFiles = true;
          restrictedFilesList.push(this.files.item(i).name);
        }
        }
        
      }
      if(hasInvalidFiles) {
        this.setRestrictedFileNames(restrictedFilesList);
       }
      this.newMessageService.uploadMultipleFiles(data).subscribe(result => {
        this.postDataForAttachmentFile = result;
        this.parentForm.get('attachments').setValue(this.postDataForAttachmentFile);
      },
        (error) => {
          console.log('error while feedback file upload :', error);
        }
      );
    } catch (e) {
      console.error("Exception in QMA 2 while fileEvent() : ", e);
    }
  }

  /** 
   * Method to close Panel  
   */ 
  closePanel(){
    this.rightNavigationService.close();
  }
  showErrorDialog() {
    this.displayError = true;
  }
  showUploadFilesPanel(event, overlaypanel: OverlayPanel): void {
    overlaypanel.toggle(event);
  }
  closeModal(_str: any) {
    if (_str == "error") {
      this.displayError = false;
      this.errMsg = '';
    }
  }
  setRestrictedFileNames(restrictedFilesList) {
    this.errMsg  = QmaConstant.QMA_INVALID_FILES_MSG;
    for (let i = 0; i < restrictedFilesList.length; i++) {
      this.errMsg  = this.errMsg + "<br>" + restrictedFilesList[i];
      }
      this.showErrorDialog();
  }
}

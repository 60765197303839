<ng-template #customLoadingTemplate>
        <div class="custom-class-loader">
            <div class="spinner-container">
                <svg class="loader" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340 340">
                    <circle cx="170" cy="170" r="160" stroke="#021e48" />
                    <circle cx="170" cy="170" r="135" stroke="#00bdf2" />
                    <circle cx="170" cy="170" r="110" stroke="#021e48" />
                    <circle cx="170" cy="170" r="85" stroke="#00bdf2" />
                </svg>
            </div>
        </div>
</ng-template>
<ngx-loading [show]="showSpinner" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate"></ngx-loading>
<div>
    <div class="mailbox-container inbox-container mailbox-container-symphony" ><!-- [ngStyle]="getMailboxContainerStyle()" -->
        <as-split unit="percent" [direction]="splitdirection" #split="asSplit"  [gutterSize]="5"> <!-- [gutterSize]="30" --> <!-- (dragEnd)="dragEnd('percent', $event)"  -->
                <as-split-area class="mailcontaine-wdth" [size]="splitAreaMailboxDefaultSize" #asSplitAreaMailbox minSize="35"> <!-- C153176-5191: split percentage, C153176-5251: disable splitter while in inlineReply-->
                    <div class="pl-3 symphony-top-border" [ngClass]="{'inbox-view':inboxView === 'vertical','inbox-view-horizontal':inboxView === 'horizontal'}"></div>
                    <div class="pl-3" [ngClass]="{'inbox-view':inboxView === 'vertical','inbox-view-horizontal':inboxView === 'horizontal'}"> <!-- [ngClass]="{'inbox-view':inboxView === 'vertical','inbox-view-horizontal':inboxView === 'horizontal'}" -->

                        <div class="d-flex align-items-center w-25 py-2" [ngClass]="{'inbox-options-wide':filterIconHide,'mailbox-options-container': !filterIconHide,'horizontal-view-inbox-options': inboxView == 'horizontal','inbox-options-wide-horizontal':filterIconHideHorizontal}">
                                <span class="inbox-options-item inbox-search-option " [ngClass]="{'hide':filterIconHide}"><img class="inbox-search-option"
                                        src='assets/Inbox/search_icon_selected_u42.svg' alt='Kiwi standing on oval' pTooltip="search" tooltipPosition="right" tooltipStyleClass='rightTooltip p-tooltip-text'></span>
                                <!-- <input class="inbox-filter-txtbox" type="text" id="filter-text-box" (keyup)="onKey($event)" placeholder="Filter..." [ngClass]="{'inbox-filter-txtBox-hide':filtertxtBoxHide}"/> -->
                                <div class="inbox-input" [ngClass]="{'hide':!filterIconHide}">
                                    <input class="inbox-filter-txtbox" type="text" placeholder="Filter..."
                                        [(ngModel)]="filterText" />
                                    <img src="assets/Inbox/close_icon_u73.svg" width=25 class="mail-box-filter-close-img" />
                                </div>
                                <span>
                                        <!-- Jira - 4578 - Tooltip has been added -->
                                    <img src="assets/Inbox/filter_u1.svg" class="qma-mailbox-filter" pTooltip="Inquiry filter" tooltipPosition="right" tooltipStyleClass='rightTooltip p-tooltip-text'>
                                    <span class="notify-badge"></span>
                                </span>
                                <span class="inbox-options-item inbox-column-chooser-option" pTooltip="Choose columns" tooltipPosition="right" tooltipStyleClass='rightTooltip p-tooltip-text'>
                                    <img class="inbox-column-chooser-option" src='assets/Inbox/list.svg' alt='Kiwi standing on oval'
                                       >
                                </span>

                                <span class="inbox-options-item inbox-column-chooser-option" pTooltip="Choose Secondary Columns" tooltipPosition="right" tooltipStyleClass='rightTooltip p-tooltip-text'>
                                    <img class="inbox-column-chooser-option" src='assets/Inbox/secondary-selection icon.svg' alt='Select Secondary Columns'
                                        >
                                </span>
                                <!-- <span class="inbox-options-item inbox-group-by-option"><img class="inbox-group-by-option" src='assets/Inbox/group_by_icon_u69.svg' alt='Kiwi standing on oval' (click)="inboxGroupBy()"></span> -->
                                <!--  <div class="dropdown inbox-options-item inbox-column-chooser-option mailbox-hide-grp-by" [ngClass]="{'grid-option-disabled':disableGrpBy}">
                                            <img class="dropdown-toggle inbox-group-by-option" id="dropdownMenuButton" data-toggle="dropdown"
                                                aria-haspopup="true" aria-expanded="false" src='assets/Inbox/table-for-data.svg' alt='Kiwi standing on oval'
                                                (click)="imgGroupByClick($event, opColumnChooser)">
                                            <div class="dropdown-menu grp-by-radio-container pl-2" aria-labelledby="dropdownMenuButton" [ngClass]="{'hide':hideGrpByMenu}">
                                                <div>
                                                    <label for="" class="qma-group-by-text">Group By</label>

                                                    <app-checkbox-list [placeholder]="'Quick Search...'" [name]="'inboxName'" [search]="false"
                                                        [items]="lstmailBoxGrpByChooser" [listStyle]="{'height':'154px'}" [styleClass]="'grp-by-chooser-first'"
                                                        (onItemSelect)="getSelectedBox($event)" [controlType]="radio"></app-checkbox-list>

                                                    </div>
                                            </div>
                                        </div> -->

                                <span class="inbox-options-item inbox-download-option">
                                    <!-- Jira - 4458 - Download excel correction done -->
                                <img class="inbox-download-option" name="imgExportToExcel" src='assets/Inbox/download_icon_u70.svg' alt='Kiwi standing on oval' pTooltip="Download" tooltipPosition="right" tooltipStyleClass='rightTooltip p-tooltip-text'>
                            </span>
                                <!-- C153176-4796: Do NOT show Save Column icon on MyViews-->
                                <span class="inbox-options-item" pTooltip="Save Columns" tooltipPosition="right" tooltipStyleClass='rightTooltip p-tooltip-text'>
                                    <img class="inbox-column-chooser-option" src='assets/Inbox/SAVE COL.svg' alt='Save Columns'>
                                </span>
                                <span  *ngIf="showCreateSympIcon" class="inbox-options-item" pTooltip="Create Chat" tooltipPosition="right" tooltipStyleClass='rightTooltip p-tooltip-text'>
                                        <img class="inbox-column-chooser-option" src='assets/symphony/create_chat_icon.png' alt='Create Chat' (click)="showSympCreateChatPopup()">
                                        <app-symphony-create-chat  #sympcrtChatComp></app-symphony-create-chat>
                                </span>

                            </div>

                        <div #mailBoxDesktopGridContainer class ='grid-container-vertical'>
                           <!-- ag grid -->
                           <ag-grid-angular #inboxGrid id="inboxGrid" class="ag-theme-balham inbox-grid mailbox-grid-unique-class mailbox-grid-tyle context-menu-style-override"
                                [rowData]="rowData"
                                [columnDefs]="columnDefs" [headerHeight]="30" [defaultColDef]="defaultColDef"
                                (gridReady)="onGridReady($event)" (selectionChanged)="onSelectionChanged($event)"
                                [rowSelection]="rowSelection" (rowDataChanged)="rowDataChanged($event)"
                                [getContextMenuItems]="getChatGridContextMenu" [rowClassRules]="rowClassRules"
                                (firstDataRendered)="firstDataRendered($event)" [rowGroupPanelShow]="rowGroupPanelShow"
                                (bodyScroll)="onBodyScroll($event)"
                                >

                            </ag-grid-angular>
                        </div>
                        <div class="d-flex justify-content-end qma-mailbox-pagination-container">
                          <!-- Pagination -->
                        </div>
                    </div>
                </as-split-area>
                <as-split-area class="mailcontaine-wdth" [size]="splitAreaChatViewDefaultSize" #asSplitAreaDetailView minSize="30">
                        <div class="detail-view-container-with-sidebar symphony-top-border"></div>
                    <div class="detail-view-container-with-sidebar">
                        <div class = "detail-view-container">
                     <!--  Symphony Chat View  -->
                     <!-- <app-symphony-chat-view #symphonyChatViewComp>

                     </app-symphony-chat-view> -->
                     <!-- *ngIf="!fromInbox" -->
                    <!--  <app-symphony-chat-view
                     [assignedGroup]="assignedGroup"
                     [setChatText]="chatText"
                     (attachmentEvent)="onAttachment($event)"
                      (notesEvent)="onNote($event)"
                      (auditsEvent)="onAudit($event)"
                      (clearChatTextFromInbox)="clearChatText()"
                      [tabNameforChatView]="tabNameforChatView"
                       [parentComponent]="thisComponent"
                       [selectedMail]="selectedMail"
                       [selectedMailRequest]="inqData" #chatViewComponant>
                     </app-symphony-chat-view> -->
                        </div>
                    </div>
               </as-split-area>
        </as-split>
      </div>
    </div>

    <!-- popup to Add Member */ -->
<!-- popup to Add Member */ -->
<p-dialog [(visible)]="displayAddMemberPopUp" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'" [styleClass]="'symphonyCreateChatRoom'"
    [closable]="false" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" [style]="{'width':'40em'}">
    <p-header>
        <div class="d-flex flex-row align-items-center justify-content-between mx-2 mt-1">
            <h6 class="gfid-title" style="font-weight: bold;color:white">Add Members to :- {{selectedRoomName}}</h6>
        </div>
    </p-header>
    <form #addMemberForm="ngForm" (ngSubmit)="actionOnChatRoom('add',addMemberForm)" (keydown.enter)="$event.preventDefault()">
    <div class="d-flex flex-row align-items-start pt-4 row p-2 editmember" style="width: 40.6em">

    <label for="to" class="input pr-3" style="margin-left: 50px;margin-right: 9px"><span>Add Members</span></label>


    <p-autoComplete [(ngModel)]="toUsersList" [suggestions]="filteredToUsersMultiple" (completeMethod)="filterUsers($event)"
    [minLength]="1" field="text" [multiple]="true" [styleClass]="'symphonyAutoComplete override override-newmessage-autocomplete'"
     [style]="{'width':'360px'}" id="toUsersList"  name="toUsersList" #chatMember="ngModel" required (onKeyUp)="onKeyUp($event, 'to')" >
    </p-autoComplete>
    <span class="mobile-view-cross" style ="margin-left: 4px;" *ngIf="(!chatMember.valid && chatMember.touched) || (!chatMember.valid && addMemberForm.submitted)">
        <img src="assets/newMessage/error.svg" class="error" title="Atleast one member is required">
    </span>

</div>
    <p-footer>
        <div class="d-flex flex-row flex-wrap px-3 pb-2 pt-3 justify-content-end">
            <button type="submit" class="btn save-btn mr-4" style="width:80px !important">Add</button>
            <button type="button" class="btn clear-btn" (click)="actionOnChatRoom('cancel',null)" style="width:80px !important">Cancel</button>
        </div>
    </p-footer>
</form>
</p-dialog>
<!--remove member-->
<!-- popup to Add Member */ -->
<p-dialog [(visible)]="displayRemoveMemberPopUp" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'" [styleClass]="'symphonyCreateChatRoom'"
    [closable]="false" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" [style]="{'width':'33em'}">
    <p-header>
        <div class="d-flex flex-row align-items-center justify-content-between mx-2 mt-1">
            <h6 class="gfid-title" style="font-weight: bold;color:white">Remove Members from :- {{selectedRoomName}}</h6>
        </div>
    </p-header>
    <form #removeMemberForm="ngForm" (ngSubmit)="actionOnChatRoom('remove',removeMemberForm)">
    <div class="d-flex flex-row align-items-start pt-4 row p-2 editmember" style="width: 38.6em">

          <label for="to" class="input pr-3" style="margin-left: 50px;margin-right: 9px;"><span>Remove Members:</span></label>

        <p-autoComplete [(ngModel)]="memberToBeRemoved" [suggestions]="filteredToUsersMultiple" (completeMethod)="filterUsers($event)"
        [minLength]="1" field="text" [multiple]="false" [styleClass]="'symphonyAutoComplete'" [style]="{'width':'100%'}"
        id="memberToBeRemoved" name="memberToBeRemoved" #memRemove="ngModel" required>
        </p-autoComplete>
        <span class="mobile-view-cross"  *ngIf="(!memRemove.valid && memRemove.touched) || (!memRemove.valid && removeMemberForm.submitted)">
            <img src="assets/newMessage/error.svg" class="error" title="Atleast one member is required">
        </span>

    </div>
    <p-footer>
        <span *ngIf="onlyOwnerOfChat" style="color:red">*{{onlyOwnerOfChatName}} is only owner of chat therefore can't be removed </span>
        <div class="d-flex flex-row flex-wrap px-3 pb-2 pt-3 justify-content-end">
            <button type="submit" class="btn save-btn mr-4"[disabled]="onlyOwnerOfChat">Submit</button>
            <button type="button" class="btn clear-btn" (click)="actionOnChatRoom('cancel',null)">Cancel</button>
        </div>
    </p-footer>
    </form>
</p-dialog>
<!--to show service response message-->
<p-dialog [(visible)]="displayResponse" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'"
    [styleClass]="'contextMenuModal successModal ModelClose'" [closable]="true" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true"
    [style]="{width: '20%'}">
    <p-header>
        <div class="d-flex flex-row align-items-center justify-content-between">
            <h6 class="success-title">{{responseMessage === " "?'Success Confirmation':responseMessage}}</h6>
        </div>
    </p-header>
    <!-- <div class="success-msg">{{responseMessage}}</div> -->
    <div class="d-flex flex-row align-items-center justify-content-between mx-2 mt-1 success-msg" *ngIf="responseMessage && responseMessage.title">
        <h6 class="gfid-title" style="font-weight: bold;">{{responseMessage.title}}</h6>
    </div>
</p-dialog>
<!-- Create Inq -->
<app-symphony-create-inquiry #sympCreateInqComp></app-symphony-create-inquiry>

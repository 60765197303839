
export class WebSocketUtil {
  static wsWorkerInstance = (): void => {

    let qmaConstant;
    const processWebSocketUpdate = (wsObj): Array<object> => {
      let inquiryBatch = wsObj.inqIdArray;
      let gridDetailsObject = wsObj.gridDetailsObject;
      let rowData = wsObj.rowData;
      qmaConstant = wsObj.qmaConstant;
      let inquiryList: Array<object> = [];
      for (let batch = 0; batch < inquiryBatch.length; batch++) {
        let inqIdArray = inquiryBatch[batch].wsInquiryList;
        //let inqIdArray = wsObj.inqIdArray.wsInquiryList;
        let delayWorkerResponseExecutionInMs = wsObj.delayWorkerResponseExecutionInMs;
        let currTabName = gridDetailsObject.name;
        console.debug(`webworker thread: inside wsWorkerInstance tab: ${currTabName}`);
        //this.printInquiryIdsReceived(inqIdArray);

        if (!isUndefinedOrNull(inqIdArray) && inqIdArray.length > 0) {
          // will be used in logging
          let unProcessedInqIds = inqIdArray.map(inq => inq._id);
          //TODO:Performance :: If criteria is blank/empty/null then stop further ws processing and notify user
          let criteria = gridDetailsObject.criteria ? gridDetailsObject.criteria : "criteria is blank";
          // get grid status before ws update
          //TODO:Performance :: Please remove below method call as this is currently not needed.
          //this.gridStateBeforeWsUpdate();

          try {
            // step 1 create subset of matching inq id ws update
            //TODO:Performance :: Why deep copy and create subset here, why not directly updating wss inquiries in the data source itself
            let wsMatchingInqSubset = [];
            if (rowData && rowData.length > 0) {

              // for bulk opearations with diff inq ids
              rowData.forEach(rd => {
                var inqData = inqIdArray.filter((inq) => {
                  return rd._id == inq._id;
                });
                if (inqData.length > 0) {
                  let inqCopy = Object.assign({}, rd);//$.extend(true, {}, rd);//deep copy of inquiry
                  inqCopy.markForDeletion = true;
                  // step 2 mark subset of matching inq to delete
                  wsMatchingInqSubset.push(inqCopy);
                }
              });
            }

            for (let inqIndex = 0; inqIndex < inqIdArray.length; inqIndex++) {

              let inq = inqIdArray[inqIndex];
              inq.id = inq._id;
              // symphonyChanges
              // explicitly mark chat as read
              /*  if(this.symphonyEnabled && inq.isSymphonyChatroom) {
                 inq.readBy = [this.userDataService.loggedInUserInfo.userId];
               } */
              //this method evaluate the criteria, on true adds the inquiries to grid
              multiLineProcessMessage(inq,
                gridDetailsObject.viewType,
                /*  allGridDetailsArray[i].grid, */
                gridDetailsObject.criteria,
                rowData, 0, wsMatchingInqSubset, inquiryList);
              /* this.gridApi.applyTransaction(this.rowData); */
              // remove processed ids
              if (unProcessedInqIds && unProcessedInqIds.length > 0 && inq._id) {
                let indexToRemove = unProcessedInqIds.indexOf(inq._id);
                if (indexToRemove > -1) {
                  unProcessedInqIds.splice(unProcessedInqIds.indexOf(inq._id), 1)
                }
              }

            }

            wsMatchingInqSubset.forEach(wsInq => {
              let existingInqIndex = rowData.findIndex(rd => rd._id === wsInq._id &&
                rd.workflows[0].assignedGroupId === wsInq.workflows[0].assignedGroupId
                && rd.workflows[0].direction === wsInq.workflows[0].direction);
              // update existing  Unqualified inq
              if (!wsInq.markForDeletion) {

                delete wsInq.markForDeletion;

                let responseData = {
                  existingInqIndex: existingInqIndex,
                  wsInq: wsInq,
                  action: 'update'
                };
                inquiryList.push(responseData);
                // @ts-ignore
                //self.postMessage(responseData);

                // this.updateGridAsynch(existingInqIndex, wsInq);


              } else if (wsInq.markForDeletion) { // remove existing inq from grid which is no more qualifies

                let responseData = {
                  existingInqIndex: existingInqIndex,
                  wsInq: wsInq,
                  action: 'remove'
                };
                rowData.splice(existingInqIndex, 1);
                inquiryList.push(responseData);
                // @ts-ignore
                //self.postMessage(responseData);

                //this.removeInqFromGrid(wsInq, existingInqIndex)
              }
            });

            //return inquiryList;
            //wait for 5 sec before post to main thread
            //console.debug(`webworker: waiting for ${delayWorkerResponseExecutionInMs} ms before sending data to main thread ${new Date()}`)
            //setTimeout(self.postMessage,delayWorkerResponseExecutionInMs,inquiryList);
            // @ts-ignore
            //self.postMessage(inquiryList);

          }
          catch (err) {
            // set processing error flag at batch level
            inqIdArray.push({ hasWsBatchProcessingError: true, error: err.stack });
            let currentTab = gridDetailsObject.name;//this.strMailboxView;
            console.error("QMA2: Websocke webworkert:: onMessage, error in processing data:::  " + "Input Ids"
              + JSON.stringify(inqIdArray)
              + "<br> and the ERROR is " + err.stack + "Processing tab->" + currentTab + "  Unprocessed Ids:-"
              + JSON.stringify(unProcessedInqIds) + " criteria evaluated causing error:->"
              + criteria);
            /*this.inboxService.saveUILog("QMA2.0 : Websocket:: onMessage, error in processing data:::  " +
              "Processing tab->" + currentTab + "  Unprocessed Ids:-" + JSON.stringify(unProcessedInqIds) +
              "Input Ids:-" + JSON.stringify(inqIdArray) + "<br> and the ERROR is " + err.stack
              + " criteria evaluated causing error:->" + criteria).subscribe(res =>
                console.log(res));*/
            // return array with error message
            return inqIdArray;
          }


        }
      }
      return inquiryList;
    }

    const isUndefinedOrNull = (val: any) => {
      return val === undefined || val === null ? true : false;
    }
    const isInquiryMatchingCriteria = (viewType, inq, criteria, gridData) => {
      //Sumanth- This piece is added to filter OUTBOX Resolved Version from Search/Folder Views
      // Both Load View and Pub Sub Flows call this method
      /*var isValidInquiry=!util.isUndefinedOrNull(inq) && !util.isUndefinedOrNull(inq.workflows) && !util.isUndefinedOrNull(inq.workflows[0]);
      if(!util.isUndefinedOrNull(viewType) && (viewType == gVars.typeEnum.GLOBALSEARCH.TYPE || viewType == gVars.typeEnum.FOLDER.TYPE) &&  isValidInquiry &&
          'OUT'==inq.workflows[0].direction && 'Resolved' ==inq.workflows[0].status)
      {
        console.log("Websocket:: ~~@@@ OUTBOX Resolved Row-Filtered in for view type-"+viewType+" & inqId="+inq._id);
        return false;
      }*/

      var expr = [""];
      var op = [];
      //TODO:Performance :: Instead of paring criteria every time here, parse it once when grid gets loaded and save it in json format and use it here.
      convertToBooleanExpression(JSON.parse(criteria), op, expr, inq, viewType, gridData);
      var exprResult = eval(expr[0]);
      //console.log("Websocket:: expr : " + expr[0] + " ---> " + exprResult);

      return exprResult;
    }
    const checkDirectionCriteria = (workflow, value) => {
      var criteriaMatched = false;
      if (!value.direction) {
        criteriaMatched = true;
      }
      else if (workflow.direction && value.direction && Array.isArray(value.direction.$in)) {
        criteriaMatched = value.direction.$in.includes(workflow.direction)
      }
      else {
        criteriaMatched = workflow.direction == value.direction

      }

      return criteriaMatched;
    }

    const compareNumbers = (num1, num2, key1) => {

      //console.log('Websocket::Numbers ---> ' + num1 + ' ' + key1 + ' ' + num2);

      if (key1 == '$gt') return num2 > num1;
      else if (key1 == '$gte') return num2 >= num1;
      else if (key1 == '$lt') return num2 < num1;
      else if (key1 == '$lte') return num2 <= num1;
      else if (key1 == '$eq') return num2 == num1;
      else if (key1 == '$ne') return num2 != num1;
    }
    const compareDates = (date1, date2, key1) => {

      //console.log('Websocket::Dates Before ---> ' + date1 + ' ' + key1 + ' ' + date2);

      date1 = date1.split('/')
      date1 = new Date(date1[0], date1[1], date1[2]); // date1 format  - "yyyy/mm/dd"

      date2 = new Date(date2); // date2 format - long number
      date2 = new Date(date2.getUTCFullYear(), date2.getUTCMonth() + 1, date2.getUTCDate());

      //console.log('Websocket::Dates After ---> ' + date1 + ' ' + key1 + ' ' + date2);

      if (key1 == '$gt') return date2 > date1;
      else if (key1 == '$lt') return date2 < date1;
      else if (key1 == '$gte') return +date2 >= +date1;
      else if (key1 == '$lte') return +date2 <= +date1;
      else if (key1 == '$eq') return +date2 == +date1;
      else if (key1 == '$ne') return +date2 != +date1;
    }

    const compareWorkflowStringField = (workflowVal, ctrVal, booleanArray) => {
      // not equal case
      if (!isUndefinedOrNull(ctrVal.$ne)) {
        if (!isUndefinedOrNull(workflowVal)) {
          let regx = new RegExp(ctrVal.$ne.$regex, ctrVal.$ne.$options);
          let match = workflowVal.match(regx);
          booleanArray.push(isUndefinedOrNull(match) || match.length == 0);
        }
        else {
          booleanArray.push(true);
        }
      }
      else {
        if (!isUndefinedOrNull(workflowVal)) {
          var regx = new RegExp(ctrVal.$regex, ctrVal.$options);
          var match = workflowVal.match(regx);
          booleanArray.push(!isUndefinedOrNull(match) && match.length > 0);
        }
        else {
          booleanArray.push(false);
        }
      }
    }

    const compareWorkflowIntegerField = (workflowVal, ctrVal, booleanArray) => {
      if (!isUndefinedOrNull(workflowVal)) {
        if (!isUndefinedOrNull(ctrVal.$gt)) {
          booleanArray.push(workflowVal > ctrVal.$gt);
        }
        if (!isUndefinedOrNull(ctrVal.$lt)) {
          booleanArray.push(workflowVal < ctrVal.$lt);
        }
        if (!isUndefinedOrNull(ctrVal.$eq)) {
          booleanArray.push(workflowVal == ctrVal.$eq);
        }
      }
      else {
        booleanArray.push(false);
      }

    }

    const compareUserViewDate = (workflowDtVal, ctrDtVal, booleanArray) => {
      if (!isUndefinedOrNull(workflowDtVal) && !isUndefinedOrNull(ctrDtVal)) {
        if (!isUndefinedOrNull(ctrDtVal.$lt)) {
          booleanArray.push(workflowDtVal.$date < ctrDtVal.$lt.$date);
        }
        if (!isUndefinedOrNull(ctrDtVal.$gt)) {
          booleanArray.push(workflowDtVal.$date > ctrDtVal.$gt.$date);
        }

        if (!isUndefinedOrNull(ctrDtVal.$lte)) {
          booleanArray.push(workflowDtVal.$date <= ctrDtVal.$lte.$date);
        }
        if (!isUndefinedOrNull(ctrDtVal.$gte)) {
          booleanArray.push(workflowDtVal.$date >= ctrDtVal.$gte.$date);
        }
      }
    }


    const checkUserViewctr = (workflow, ctrValue, userOperator, viewType) => {

      var isUserCtrSucess = false;
      var booleanArray = [];
      if (!isUndefinedOrNull(workflow) && !isUndefinedOrNull(ctrValue)) {
        if (!isUndefinedOrNull(ctrValue.assignedUserName)) {///[C153176-436]Case Insensitive is not working for Begins with and Contains condition for Request Type & Tag
          compareWorkflowStringField(workflow.assignedUserName, ctrValue.assignedUserName, booleanArray);
        }
        if (!isUndefinedOrNull(ctrValue.requestType)) {
          compareWorkflowStringField(workflow.requestType, ctrValue.requestType, booleanArray);
        }
        if (!isUndefinedOrNull(ctrValue.resolver)) {
          compareWorkflowStringField(workflow.resolver, ctrValue.resolver, booleanArray);
        }
        if (!isUndefinedOrNull(ctrValue.assignedGroupName)) {
          compareWorkflowStringField(workflow.assignedGroupName, ctrValue.assignedGroupName, booleanArray);
        }
        if (!isUndefinedOrNull(ctrValue.tag)) {
          compareWorkflowStringField(workflow.tag, ctrValue.tag, booleanArray);
        }
        if (!isUndefinedOrNull(ctrValue.linkId)) {
          compareWorkflowIntegerField(workflow.linkId, ctrValue.linkId, booleanArray)

        }
        if (!isUndefinedOrNull(ctrValue.queryCount)) {
          compareWorkflowIntegerField(workflow.queryCount, ctrValue.queryCount, booleanArray);

        }
        if (!isUndefinedOrNull(ctrValue.rootCause)) {
          compareWorkflowStringField(workflow.rootCause, ctrValue.rootCause, booleanArray);
        }
        if (!isUndefinedOrNull(ctrValue.processingRegion)) {
          compareWorkflowStringField(workflow.processingRegion, ctrValue.processingRegion, booleanArray);
        }
        if (!isUndefinedOrNull(ctrValue.inquirySource)) {
          compareWorkflowStringField(workflow.inquirySource, ctrValue.inquirySource, booleanArray);
        }
        if (!isUndefinedOrNull(ctrValue.crtDate))//[C153176-160] Saved Search filter criteria is not working for all columns
        {
          compareUserViewDate(workflow.crtDate, ctrValue.crtDate, booleanArray);
        }
        if (!isUndefinedOrNull(ctrValue.modDate)) {
          compareUserViewDate(workflow.modDate, ctrValue.modDate, booleanArray)
        }
        if (!isUndefinedOrNull(ctrValue.age$gt) || !isUndefinedOrNull(ctrValue.age$lt) || !isUndefinedOrNull(ctrValue.age$eq)) {
          compareUserViewageCalculation(workflow, ctrValue, booleanArray, viewType)
        }
        if (!isUndefinedOrNull(ctrValue.isSubjectEscalation)) {
          compareWorkflowStringField(workflow.isSubjectEscalation, ctrValue.isSubjectEscalation, booleanArray);
        }
        if (!isUndefinedOrNull(ctrValue.isConvCountEscalation)) {
          compareWorkflowStringField(workflow.isConvCountEscalation, ctrValue.isConvCountEscalation, booleanArray);
        }
        if (!isUndefinedOrNull(ctrValue.isClientChaseEscalation)) {
          compareWorkflowStringField(workflow.isClientChaseEscalation, ctrValue.isClientChaseEscalation, booleanArray);
        }
        if (!isUndefinedOrNull(ctrValue.responseTimeEscalationFlag)) {
          compareWorkflowStringField(workflow.responseTimeEscalationFlag, ctrValue.responseTimeEscalationFlag, booleanArray);
        }
        if (!isUndefinedOrNull(ctrValue.isManualEscalation)) {
          compareWorkflowStringField(workflow.isManualEscalation, ctrValue.isManualEscalation, booleanArray);
        }
        if (!isUndefinedOrNull(ctrValue.manualEscalationReason)) {
          compareWorkflowStringField(workflow.manualEscalationReason, ctrValue.manualEscalationReason, booleanArray);
        }
        if (!isUndefinedOrNull(ctrValue.lastActionTime)) {
          compareUserViewDate(workflow.lastActionTime, ctrValue.lastActionTime, booleanArray)
        }
        if (!isUndefinedOrNull(ctrValue.resolveTime)) {
          compareUserViewDate(workflow.resolveTime, ctrValue.resolveTime, booleanArray)
        }
        if (!isUndefinedOrNull(ctrValue.reAgeDate)) {
          compareUserViewDate(workflow.reAgeDate, ctrValue.reAgeDate, booleanArray)
        }
        if (!isUndefinedOrNull(ctrValue.reOpenDate)) {
          compareUserViewDate(workflow.reOpenDate, ctrValue.reOpenDate, booleanArray)
        }
        if (!isUndefinedOrNull(ctrValue.workflowStatus)) {
          compareWorkflowStringField(workflow.workflowStatus, ctrValue.workflowStatus, booleanArray);
        }
        if (!isUndefinedOrNull(ctrValue.suggestionIndicator)) {
          compareWorkflowStringField(workflow.suggestionIndicator, ctrValue.suggestionIndicator, booleanArray);
        }
        for (var idx = 0; idx < booleanArray.length; idx++) {
          if (idx == 0) {
            isUserCtrSucess = booleanArray[idx];
          }
          else {
            if (userOperator == '&&') {
              isUserCtrSucess = isUserCtrSucess && booleanArray[idx];
            }
            else {
              isUserCtrSucess = isUserCtrSucess || booleanArray[idx];
            }
          }
        }
      }

      return isUserCtrSucess;
    }

    //ctrValue is criteria value
    const compareUserViewageCalculation = (workflow, ctrValue, booleanArray, viewType) => {
      var modDate = new Date();
      var crtDate = new Date(workflow.crtDate.$date);
      if (/*QmaConstant.inboxGridViewType.Outbox.viewType*/qmaConstant.Outbox == viewType && workflow.status == 'Resolved') {
        modDate = new Date(workflow.modDate.$date);
      }
      if (!isUndefinedOrNull(ctrValue.age$gt)) {
        booleanArray.push(getInquiryAge(crtDate, modDate) > ctrValue.age$gt.$nin[1]);
      }
      if (!isUndefinedOrNull(ctrValue.age$lt)) {
        booleanArray.push(getInquiryAge(crtDate, modDate) < ctrValue.age$lt.$nin[1]);
      }
      if (!isUndefinedOrNull(ctrValue.age$eq)) {
        booleanArray.push(getInquiryAge(crtDate, modDate) == ctrValue.age$eq.$nin[1]);
      }

    }

    const getInquiryAge = (crtDate, currentDateLong) => {
      let oneDay = 24 * 60 * 60 * 1000;
      let diffDays = Math.floor(Math.abs((currentDateLong - crtDate) / (oneDay)));
      //console.log('Inquiry age : '+diffDays);
      return diffDays;
    }

    const checkEscalationCriteria = (escalationOrCondition, workflow) => {
      var esc1 = escalationOrCondition[0];
      var esc2 = escalationOrCondition[1];
      var esc3 = escalationOrCondition[2];
      var esc4 = escalationOrCondition[3];
      var esc5 = escalationOrCondition[5];
      return (esc1.isConvCountEscalation === workflow.isConvCountEscalation
        || esc2.isClientChaseEscalation === workflow.isClientChaseEscalation || esc3.isSubjectEscalation === workflow.isSubjectEscalation
        || esc4.responseTimeEscalationFlag === workflow.responseTimeEscalationFlag
        || esc5.isManualEscalation === workflow.isManualEscalation);
    }
    const checkFollowUpCriteria = (workflow) => {
      var criteriaMatched = false;
      if (workflow.followUp && "Generic" === workflow.followUp.flag) {
        criteriaMatched = true;
      }
      return criteriaMatched;
    }

    const evalIndividualExpr = (key, value, inq, userOperator, viewType, gridData) =>///[C153176-160] changes for saved search with work flow Criteria query
    {
      var inqArray = [inq];

      //console.log(key+' ----> '+value);
      if (value instanceof Object)  // like regex, date etc, not simple comparison (i.e not just key, value
      {
        var key1 = Object.keys(value)[0];
        var value1 = value[key1];
        var stringValue = JSON.stringify(value1);
        if (isUndefinedOrNull(stringValue)) {
          stringValue = " ";
        }
        //console.log("key: " + key + ", value: " + value +", key1: " + key1 + ", value1: " + value1 +" , userOperator: "+ userOperator + ", viewType: " + viewType);
        //console.log(key1+' ----> '+value1);


        if (key1 == '$elemMatch')  // SPECIFIC EMBEDDED OBJECT FIELDS
        {

          // inner level filter
          inqArray = inqArray.filter((inq) => {
            var wf = inq.workflows.filter((workflow) => {
              //Required to show updates in Tags tab..
              //Code to evaluate workflow tags in the incoming inquiry.
              //If tags are present, basicCriteria is built based on them. Else, it follows the already present logic.
              var hasValidBasicCriteria = false;
              if (!isUndefinedOrNull(value1.tag) && stringValue.indexOf("-1000100") == -1) {
                hasValidBasicCriteria = workflow.tag == value1.tag
                  && value1.assignedGroupId === workflow.assignedGroupId;
              }
              else if (stringValue.indexOf("-1000100") == -1) {
                //C153176-368-Resolved Inquiry Cannot be found with OUT version
                if (!isUndefinedOrNull(viewType) && viewType == qmaConstant.Outbox/*QmaConstant.inboxGridViewType.Outbox.viewType*/) {
                  //hasValidBasicCriteria=workflow.direction == value1.direction
                  hasValidBasicCriteria = checkDirectionCriteria(workflow, value1)
                    && value1.assignedGroupId.$in.includes(workflow.assignedGroupId);
                }
                //This will be used in future when we will enable escalation for all the box types right now only enabling it for Inbox.
                //							else if(!util.isUndefinedOrNull(viewType) && viewType == gVars.typeEnum.ESCALATION.TYPE){
                //								hasValidBasicCriteria =  workflow.status == value1.status
                //								&& _.contains(value1.assignedGroupId.$in, workflow.assignedGroupId);
                //								if(hasValidBasicCriteria && value1 && value1.$or){
                //									hasValidBasicCriteria = checkEscalationCriteria(hasValidBasicCriteria, value1, value1.$or,workflow);
                //								}
                //							}
                else {
                  hasValidBasicCriteria = workflow.status == value1.status
                    //&& workflow.direction == value1.direction
                    && checkDirectionCriteria(workflow, value1)
                    && value1.assignedGroupId.$in.includes(workflow.assignedGroupId);
                  if (hasValidBasicCriteria && value1 && value1.$or) {
                    hasValidBasicCriteria = checkEscalationCriteria(value1.$or, workflow);
                  }
                  if (hasValidBasicCriteria && value1 && value1.followUp) {
                    hasValidBasicCriteria = checkFollowUpCriteria(workflow);
                  }
                }
              }

              //Rules only apply for Inbox Types of View. It doent apply on Resolvedbox , Outbox and Peding Approval Box.
              if (workflow.status == 'Open' && workflow.direction == 'IN' && isUndefinedOrNull(value1.tag) && stringValue.indexOf("-1000100") == -1) {
                if (value1.snoozeAction && value1.snoozeAction.$exists === false) {
                  if (workflow.snoozeAction && workflow.snoozeAction === "Snooze") {
                    hasValidBasicCriteria = false;
                  }
                }
                else if (value1.snoozeAction) {
                  if (workflow.snoozeAction && workflow.snoozeAction === "Snooze") {
                    hasValidBasicCriteria = true;
                  } else {
                    hasValidBasicCriteria = false;
                  }
                }
                //the changes to incorporate rules requirement
                var hasValidRulesCriteria = false;

                if (value1.rulesFlag) //Case - Views : Inbox Types
                {
                  if (value1.rulesFlag.$exists == false) {
                    hasValidRulesCriteria = isUndefinedOrNull(workflow.rulesFlag);
                  }
                }
                else if (value1["rulesFlag.markAsNonInquiry"] && value1["rulesFlag.markAsNonInquiry"] == true) //Case - Viwe : Non Inquiry Inbox
                {
                  if (workflow.rulesFlag && workflow.rulesFlag.markAsNonInquiry) {
                    hasValidRulesCriteria = workflow.rulesFlag.markAsNonInquiry;
                  }
                }
                else if (value1["rulesFlag.markForDeletion"] && value1["rulesFlag.markForDeletion"] == true) //Case - Viwe : Delete Inquiry Inbox
                {
                  if (workflow.rulesFlag && workflow.rulesFlag.markForDeletion) {
                    hasValidRulesCriteria = workflow.rulesFlag.markForDeletion;
                  }
                }
                //end - rules requirement

                return hasValidBasicCriteria && hasValidRulesCriteria;//return combined evaluation
              } else if (stringValue.indexOf("-1000100") > -1) {
                hasValidBasicCriteria = checkUserViewctr(workflow, value1, userOperator, viewType)
              }

              return hasValidBasicCriteria;
            });
            return wf.length > 0;
          });
        }
        // Any column filter of type Date needs to be added here inorder to be matched and shown in the UI
        else if (key == 'crtDate' || key == 'modDate' || key == 'lastActionTime' || key == 'resolveTime' || key == 'reAgeDate' || key == 'ackEscalationTime')  // DATES
        {
          inqArray = inqArray.filter(function (obj) {
            return compareDates(value1, obj[key], key1)
          });

          var length = Object.keys(value).length;

          if (length > 1) //BETWEEN OPERATOR
          {
            var key2 = Object.keys(value)[1];
            var value2 = value[key2];
            inqArray = inqArray.filter(function (obj) {
              return compareDates(value2, obj[key], key2)
            });
          }
        }
        else if (key1 == '$regex')  // STRINGS
        {
          inqArray = inqArray.filter(function (obj) {
            if (obj[key]) {
              //below line is commented as just matching is not case insensitive
              //return obj[key].match(value1);

              //ignore case matching is handled by converting to lower case and matching
              var inqValue = obj[key].toLowerCase();
              var crtValue = value1.toLowerCase();;
              return inqValue.match(crtValue);
            }
          });
        }
        else if (key1 == '$regularExpression')  // STRINGS
        {
          inqArray = inqArray.filter(function (obj) {
            if (obj[key]) {
              //below line is commented as just matching is not case insensitive
              //return obj[key].match(value1);

              //ignore case matching is handled by converting to lower case and matching
              var inqValue = obj[key].toLowerCase();
              var crtValue = value1.pattern.toLowerCase();
              return inqValue.match(crtValue);
            }
          });
        }
        else if (key1 == '$ne')  // STRINGS
        {
          inqArray = inqArray.filter((obj) => {
            return obj[key] != value1;
          });
        }
        else if (key1 == '$nin')  // STRINGS
        {
          return !(value1.includes(inq.readyBy));
        }
        else if (key1 == '$exists')  // STRINGS
        {
          inqArray = inqArray.filter((obj) => {
            var exists;

            if (key == 'workflows.assignedUserId') {
              var userId = obj['workflows'][0].assignedUserId;

              exists = !isUndefinedOrNull(userId);
            }
            else {
              exists = !isUndefinedOrNull(obj[key]);
            }

            return exists === value1;
          });
        }
        /*else if (key == '_id' && key1 == '$in')  // SOLR Search
        {
          return value1.indexOf(inq._id) > -1;
        }*/
        else if (key == 'workflows.assignedGroupId' && key1 == '$in')  // Home SOLR Search
        {
          inqArray = inqArray.filter((inq) => {
            var wf = inq.workflows.filter((workflow) => {
              return value1.indexOf(workflow.assignedGroupId) > -1;
            });
            return wf.length > 0;
          });
        } else if (key == 'workflows.requestType' && key1 == '$in')  // Home SOLR Search
        {
          inqArray = inqArray.filter((inq) => {
            var wf = inq.workflows.filter((workflow) => {
              return value1.indexOf(workflow.requestType) > -1;
            });
            return wf.length > 0;
          });
        } else if (key == 'workflows.tag' && key1 == '$in')  // Home SOLR Search
        {
          inqArray = inqArray.filter((inq) => {
            var wf = inq.workflows.filter((workflow) => {
              return value1.indexOf(workflow.tag) > -1;
            });
            return wf.length > 0;
          });
        } else if (key == 'workflows.status' && key1 == '$in')  // Home SOLR Search
        {
          inqArray = inqArray.filter((inq) => {
            var wf = inq.workflows.filter((workflow) => {
              return value1.indexOf(workflow.status) > -1;
            });
            return wf.length > 0;
          });
        } else if (key == 'workflows.assignedUserId' && key1 == '$in')  // Home SOLR Search
        {
          inqArray = inqArray.filter(function (inq) {
            var wf = inq.workflows.filter(function (workflow) {
              return value1.indexOf(workflow.assignedUserId) > -1;
            });
            return wf.length > 0;
          });
        } else if (key == 'workflows.resolver' && key1 == '$in')  // Home SOLR Search
        {
          inqArray = inqArray.filter(function (inq) {
            var wf = inq.workflows.filter(function (workflow) {
              return value1.indexOf(workflow.resolver) > -1;
            });
            return wf.length > 0;
          });
        } else if (key == 'workflows.inquirySource' && key1 == '$in')  // Home SOLR Search
        {
          inqArray = inqArray.filter(function (inq) {
            var wf = inq.workflows.filter(function (workflow) {
              return value1.indexOf(workflow.inquirySource) > -1;
            });
            return wf.length > 0;
          });
        } else if (key == 'workflows.rootCause' && key1 == '$in')  // Home SOLR Search
        {
          inqArray = inqArray.filter(function (inq) {
            var wf = inq.workflows.filter(function (workflow) {
              return value1.indexOf(workflow.rootCause) > -1;
            });
            return wf.length > 0;
          });
        } else if (key == 'workflows.processingRegion' && key1 == '$in')  // Home SOLR Search
        {
          inqArray = inqArray.filter(function (inq) {
            var wf = inq.workflows.filter(function (workflow) {
              return value1.indexOf(workflow.processingRegion) > -1;
            });
            return wf.length > 0;
          });
        }
        else if (key == 'workflows.attchFlag' && key1 == '$eq') // Home SOLR Search 
        {
          inqArray = inqArray.filter(function (inq) {
            var wf = inq.workflows.filter(function (workflow) {
              return workflow.attchFlag == value1;
            });
            return wf.length > 0;
          });
        }
        else  // INTEGERS
        {
          inqArray = inqArray.filter((obj) => {
            return compareNumbers(value1, obj[key], key1);
          });
        }
      }
      else { // just key , value

        if (key == 'userFolders.folderName')  // inner level filter
        {
          inqArray = inqArray.filter(function (inq) {
            var wf = inq.userFolders.filter(function (userFolder) {
              return userFolder.folderName == value;
            });
            return wf.length > 0;
          });
        }
        else if (key == 'workflows.assignedUserId')  // inner level filter
        {
          inqArray = inqArray.filter(function (inq) {
            var wf = inq.workflows.filter(function (workflow) {
              return workflow.assignedUserId == value;
            });
            return wf.length > 0;
          });
        } else if (key == 'workflows.queryCount')  // inner level filter
        {
          inqArray = inqArray.filter(function (inq) {
            var wf = inq.workflows.filter(function (workflow) {
              return workflow.queryCount == value;
            });
            return wf.length > 0;
          });
        } else if (key == 'workflows.status')  // inner level filter
        {
          inqArray = inqArray.filter(function (inq) {
            var wf = inq.workflows.filter(function (workflow) {
              return workflow.status == value;
            });
            return wf.length > 0;
          });
        } else if (key == 'workflows.convCount')  // inner level filter
        {
          inqArray = inqArray.filter(function (inq) {
            var wf = inq.workflows.filter(function (workflow) {
              return workflow.convCount == value;
            });
            return wf.length > 0;
          });
        }
        else if (key == 'urgentFlag')  // inner level filter
        {
          return "Y" == value;
        }
        else if (key == 'attchFlag')  // inner level filter
        {
          return "Y" == value;
        }
        else if (key == 'suggestionIndicator')  // inner level filter
        {
          return "O" == value || "G" == value || "R" == value || "B" == value;
        }
        else if (key == 'workflows.clientCategory.categoryName')  // inner level filter
        {
          if (wsAlreadyUpdatedData(inqArray, gridData)) {
            return false;
          }
          inqArray = inqArray.filter(function (inq) {
            var wf = inq.workflows.filter(function (workflow) {
              if (workflow && workflow.clientCategory) {
                return workflow.clientCategory.categoryName == value;
              }
            });
            return wf.length > 0;
          });
        }
        else if (key == 'workflows.customClientCategory.categoryName')  // inner level filter
        {
          inqArray = inqArray.filter(function (inq) {
            var wf = inq.workflows.filter(function (workflow) {
              let customClientCategoryList = workflow.customClientCategory;
              let cc = customClientCategoryList.filter(function (customClientCategory) {
                return customClientCategory.categoryName == value;
              });
              return cc.length > 0;
            });
            return wf.length > 0;
          });
        }
        else if (key == 'workflows.direction') // inner level filter
        {
          inqArray = inqArray.filter(function (inq) {
            var wf = inq.workflows.filter(function (workflow) {
              return workflow.direction == value;
            });
            return wf.length > 0;
          });
        }
        else if (key == 'workflows.requestType') // inner level filter
        {
          inqArray = inqArray.filter(function (inq) {
            var wf = inq.workflows.filter(function (workflow) {
              return workflow.requestType == value;
            });
            return wf.length > 0;
          });
        }
        else if (key == 'workflows.ageInDays') // inner level filter
        {
          inqArray = inqArray.filter(function (inq) {
            if (value == "0-1 day") {
              return inq.age >= 0 && inq.age <= 1;
            } else if (value == "2 days") {
              return inq.age = 2;
            } else if (value == "3-5 days") {
              return inq.age >= 3 && inq.age <= 5;
            } else if (value == "6-10 days") {
              return inq.age >= 6 && inq.age <= 10;
            } else if (value == "11-30 days") {
              return inq.age >= 11 && inq.age <= 30;
            } else if (value == "30+ days") {
              return inq.age > 30;
            }
          });
        }
        else {
          inqArray = inqArray.filter(function (obj) {
            return obj[key] == value;
          });
        }

      }

      return inqArray.length > 0;
    }

    const wsAlreadyUpdatedData = (inqArray, wsMatchingInqSubset) => {
      var flag = wsMatchingInqSubset.find(function (row) {
        return (row._id == inqArray[0]._id)
          && (row.workflows[0].assignedGroupId == inqArray[0].workflows[0].assignedGroupId
            && row.markForDeletion == false);
      });
      return flag;
    }

    const tokenizeArrayExpr = (objects, op, expr, inq, viewType, gridData) => {

      Object.keys(objects).forEach((key) => {
        let value = objects[key];
        if (value instanceof Array) {

          if (key == '$and') op.push('&&');
          else if (key == '$or') op.push('||');

          expr[0] = expr[0] + ' ' + ' (';
          tokenizeArrayExpr(value, op, expr, inq, viewType, gridData); // in QMA1.0 viewType not passed here.
          expr[0] = expr[0] + ' ' + ')';
          if (op.length > 0) {
            let operator = op[op.length - 1];
            op.pop(key);// remove the group operator on encounter of close bracket
            expr[0] = expr[0].replace(operator + ' ' + ')', ')'); // remove extra operator added while adding last operand
          }
        }
        else if (value instanceof Object) {
          convertToBooleanExpression(value, op, expr, inq, viewType, gridData)
        }
      });
    }

    const convertToBooleanExpression = (criteria, op, expr, inq, viewType, gridData) => {
      // Object.keys(value).forEach((keydata) => {
      /* for (let key in criteria) */
      Object.keys(criteria).forEach((key) => {


        let value = criteria[key];

        if (value instanceof Array) // [ case
        {
          if (key == '$and') op.push('&&');
          else if (key == '$or') op.push('||');

          expr[0] = expr[0] + ' ' + ' (';
          tokenizeArrayExpr(value, op, expr, inq, viewType, gridData); // Tokenize Array expression
          expr[0] = expr[0] + ' ' + ')';

          if (op.length > 0) {
            var operator = op[op.length - 1];
            op.pop(key);// remove the group operator on encounter of close bracket
            expr[0] = expr[0].replace(operator + ' ' + ')', ')'); // remove extra operator added while adding last operand
          }
        }
        else {
          ///[C153176-160] changes for saved search with work flow Criteria query
          expr[0] = expr[0] + ' ' + evalIndividualExpr(key, value, inq, op[op.length - 1], viewType, gridData) + ' ' + op[op.length - 1];
        }
      })
      //console.log("Websocket:: convertToBooleanExpression "+expr[0]);
    }

    const multiLineProcessMessage = (grpInq, viewType, criteria, rowData, originalDataSelectIndex, wsMatchingInqSubset, inquiryList) => {
      let isMatchingCriteria = isInquiryMatchingCriteria(viewType, grpInq, criteria, wsMatchingInqSubset);
      //Find the original select row index before applying the pub sub
      if (isMatchingCriteria) // add the record if the criteria true
      {
        //TODO:Performance :: please remove below index if not getting used
        let indexKey = grpInq._id.toString()
          + grpInq.workflows[0].assignedGroupId.toString();

        let existingInqIndex = -1;
        if (rowData) {
          existingInqIndex = rowData.findIndex(rd => rd._id === grpInq.id &&
            rd.workflows[0].assignedGroupId === grpInq.workflows[0].assignedGroupId
            && rd.workflows[0].direction === grpInq.workflows[0].direction);
        }

        if (existingInqIndex > -1) {
          // step 3 matching criteria tru and inq already exists mark subst inq to delete false
          // Its an update scenario

          wsMatchingInqSubset.forEach((inq, index) => {
            if (inq._id === grpInq.id &&
              inq.workflows[0].assignedGroupId === grpInq.workflows[0].assignedGroupId
              && inq.workflows[0].direction === grpInq.workflows[0].direction) {

              // replace inq with ws inq
              //TODO:Performance :: Below two lines are not needed as it already contains same deep copied object. or avoid deep copy below
              let inqCopy = Object.assign({}, grpInq);//$.extend(true, {}, grpInq);//deep copy of inquiry
              wsMatchingInqSubset[index] = inqCopy;
              wsMatchingInqSubset[index].markForDeletion = false;
            }
          });

        } else {
          // ignore search folder
          if (viewType === qmaConstant.GLOBALSEARCH/*QmaConstant.typeEnum.GLOBALSEARCH.TYPE*/) {
            return;
          }
          let responseData = {
            grpInq: grpInq,
            index: 0,
            action: 'add'
          };
          rowData.unshift(grpInq);
          inquiryList.push(responseData);
          // @ts-ignore
          //self.postMessage(responseData);
          // Add Inq
          //this.AddInqToGrid(grpInq, 0);

          // push as new row in case of new inquiry etc

        }

      }

    }
    // @ts-ignore
    self.onmessage = (evt) => {
      let currTabName = evt.data.gridDetailsObject.name;
      let delayWorkerResponseExecutionInMs = evt.data.delayWorkerResponseExecutionInMs;
      let webworkerPerformanceRecieveWsUpdatePostWebworkerStart = performance.now();
      //console.debug(`webworker performance: recieved total ${evt.data.inqIdArray.wsInquiryList.length} inquiries to process tab: ${currTabName} : webSocketUtil.onMessage`);
      console.debug(`webworker performance: recieved total ${evt.data.inqIdArray.length} batches to process tab: ${currTabName} : webSocketUtil.onMessage`);
      for (let i = 0; i < evt.data.inqIdArray.length; i++) {
        console.debug(`webworker performance: recieved total ${evt.data.inqIdArray[0].wsInquiryList.length} inquiries from main thread tab: ${currTabName}`);
      }

      let inquiryList = processWebSocketUpdate(evt.data);
      //setTimeout(self.postMessage,delayWorkerResponseExecutionInMs,inquiryList);
      // @ts-ignore
      self.postMessage(inquiryList);
      let webworkerPerformanceRecieveWsUpdatePostWebworkerStop = performance.now() - webworkerPerformanceRecieveWsUpdatePostWebworkerStart;
      console.debug(`webworker performance: webwroker thread took ${webworkerPerformanceRecieveWsUpdatePostWebworkerStop} ms to process data tab: ${currTabName}`);
    };
    // END OF WORKER THREAD CODE
  }
}
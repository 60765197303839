import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { QmaConstant } from 'src/app/constant/qma-constant';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  })
};

@Injectable({
  providedIn: 'root'
})
export class SymphonyNotificationService {
  extInqUrl: string;
  private listners = new Subject<any>();
  private listnersInquiry = new Subject<any>();
  notificationArray: Array<object> = [];
  ViewNotificationArray: Array<object> = [];
  NotificationInquiry: Array<object> = [];

  constructor(private http: HttpClient) {
    this.extInqUrl = QmaConstant.BASE_URL + '/extInquiry';
  }

  private subject = new BehaviorSubject(this.notificationArray);
  notificationData = this.subject.asObservable();
  private viewData = new BehaviorSubject(this.ViewNotificationArray);
  ViewNotificationData = this.viewData.asObservable();
  inquiry: any;
  loggedInUser: any;

  setNotificationCount(notificationArray: any): void {
    this.subject = new BehaviorSubject(notificationArray);
    this.notificationData = this.subject.asObservable();
  }
  getNotificationCount(): Observable<any> {
    return this.subject.asObservable();
  }
  setViewNotification(notificationArray: any): void {
    this.viewData = new BehaviorSubject(this.ViewNotificationArray);
    this.ViewNotificationData = this.viewData.asObservable();
  }
  getViewNotification(): Observable<any> {
    return this.viewData.asObservable();
  }
  setNotificationInquiry(notificationArray: any): void {
    this.inquiry = notificationArray;
  }
  getNotificationInquiry(): any {
    return this.inquiry;
  }
  setLoginUserId(loggedInUser: any): void {
    this.loggedInUser = loggedInUser;
  }
  getLoginUserId(): void {
    return this.loggedInUser;
  }
  dismissNotification(item: any) {
    this.notificationArray.forEach(function(val: any, index) {
      if (val._id === item._id) {
        this.splice(index, 1);
      }
    }, this.notificationArray);

    this.subject.next(this.notificationArray);
  }

  dismissAllNotifications() {
    this.notificationArray.splice(0, this.notificationArray.length);
    this.subject.next(this.notificationArray);
  }


  listen(): Observable<any> {
    return this.listnersInquiry.asObservable();
  }

  filter(filterBy: any) {
    this.listnersInquiry.next(filterBy);
  }
  listenCount(): Observable<any> {
    return this.listners.asObservable();
  }

  filterCount(filterBy: any) {
    this.listners.next(filterBy);
  }
  viewNotification() {
  }
  notifyUserInquiry(requestObj: any): Observable<any> {
    return this.http.post(this.extInqUrl + '/notifyUserInquiry', requestObj, httpOptions);
  }
  getNotifyUserInquiry(){
    return this.http.get<any>(this.extInqUrl+ '/getAllUserNotifications', httpOptions);      
  } 
  updateNotifyUserInquiry(requestObj: any): Observable<any> {
    return this.http.post(this.extInqUrl + '/updateUserNotification', requestObj, httpOptions);
  }
}

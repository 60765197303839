<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Logged Out</title>
</head>
<body>
    <div class="container">
        <h1>Logged Out Successfully</h1>
        <p>You have been logged out of the application.</p>
        <p>Click below to log in again or return to the home page.</p>
        <a href="/" class="btn">Log In</a>
    </div>
</body>
</html>

import { Component, OnInit, Output, EventEmitter, Input, Renderer2, Inject } from '@angular/core';
import { SymphonyChatServiceService } from '../service/symphony-chat-service.service';
import { GroupAdminService } from 'src/app/services/group-admin/group-admin.service';
import { UserDataService } from 'src/app/services/user-data.service';
import { AllToCcDBUser } from 'src/app/model/LoginUserInfo/AllToCcDBUser';
import { DOCUMENT } from '@angular/common';
declare var window: any;

@Component({
  selector: 'app-symphony-side-bar',
  templateUrl: './symphony-side-bar.component.html',
  styleUrls: ['./symphony-side-bar.component.scss']
})
export class SymphonySideBarComponent implements OnInit {
  @Output() streamId = new EventEmitter();
  chatRooms: Array<Object> = [];
  filteredChatRooms: Array<Object> = [];
  inputChatRoomName: string = '';
  inputMemberSoeids: string = '';
  memebersList: string='';
  displayChatRoomPopUp: boolean = false;
  selectedGroupList: any[];
  selectedGroup: string = '';
  filteredToUsersMultiple: any[] = [];
  allToCcDBUser: AllToCcDBUser[] = [];
  userList: AllToCcDBUser[] = [];

  contextmenu = false;
  contextmenuX= 0;
  contextmenuY= 0;
  itemId = "";
  memberSelectionType: string = 'group';

  //add or remove member
  displayAddMemberPopUp: boolean = false;
  memberToBeAdded: AllToCcDBUser = null;
  selectedRoomId: string = '';
  selectedRoomName: string = '';
//to show response message
displayResponse: boolean = false;
responseMessage: string = '';
loggedInUser: string;
@Input() listStyle:any ;
loggedInUserObj : any;
  constructor(private symphonyChatServiceService: SymphonyChatServiceService,
    private groupAdminService: GroupAdminService,
    private userDataService: UserDataService,
    private _renderer2: Renderer2, 
    @Inject(DOCUMENT) private _document: Document) { }

  ngOnInit() {
    if(!window['symphony']){
      // this.loadSymphonyScript();
    }
    this.loadUserSymphonyChatRoom();
    this.loadGroups();
    
  }

  openSymphonyView(streamId: string){
    this.streamId.emit(streamId);
  }

  createChatRoom() {
    console.log('creating chat room'+this.inputChatRoomName);
    if(this.inputChatRoomName && this.inputChatRoomName.length>0){
      this.getRoomMember();
    }
  }

  loadUserSymphonyChatRoom() {
    console.log('creating chat room');
    this.symphonyChatServiceService.loadUserSymphonyChatRoom({}).subscribe(
      (data: Array<Object>)=> {
        this.chatRooms = [];
        console.log(data);
        data.forEach((v,i,data)=>{
          console.log(v);
          if(v['streamType']['type']=='ROOM'){
            let name = v['roomAttributes']['name'];
            let id = v['id'];
            let obj = {};
            obj['name']= name;
            obj['id'] = id;
            this.chatRooms.unshift(obj);
          }
        });
        this.filteredChatRooms = Object.assign([], this.chatRooms);
      },
      (error)=>{
        console.log(error);
      }
    );
  }

  displayChatRoomPopup(){
    this.displayChatRoomPopUp = true;
  }

  cancelPopup(){
    this.displayChatRoomPopUp = false;
  }

  getRoomMember(): void{
    let memebersList: Array<string> = new Array<string>();
    console.log(this.userList);
    
    if(this.memberSelectionType === 'soeId' && this.userList.length>0){
      this.userList.forEach((user)=>{
        memebersList.push(user.id);
      });
      this.saveChatRoom(memebersList);
    } else if(this.selectedGroup){
      this.groupAdminService.getGroupDataEntitlement(this.selectedGroup['name'])
      .subscribe((userList: Array<Object>) => {
      userList.forEach((v,i,arr)=>{
        memebersList.push(v['userId']);
      });
      this.saveChatRoom(memebersList);
      },
      err => {
        console.log(err);
      });
    }
  }

  private saveChatRoom(memebersList: Array<string>) {
    this.memebersList = memebersList.toString();
      let reqParam = {};
      reqParam['name'] = this.inputChatRoomName;
      reqParam['roomMembers'] = this.memebersList;
    this.symphonyChatServiceService.createSymphonyChatRoom( this.loggedInUserObj, this.loggedInUser, reqParam).subscribe((data) => {
      this.displayChatRoomPopUp = false;
      console.log(data);
      data['message'] = 'Room created';
      this.showResponseMessage(data);
      this.loadUserSymphonyChatRoom();
    }, (error) => {
      console.log(error);
      this.displayChatRoomPopUp = false;
    });
  }

  loadGroups(){
    this.userDataService.LocalGetLoginUserInfo().subscribe(loginUserInfo => {
      const groups = loginUserInfo.myGroups;
      this.selectedGroupList = [];
      this.loggedInUser = loginUserInfo.userId;
      this.loggedInUserObj = loginUserInfo;
      groups.forEach(element => {
        this.selectedGroupList.push({ 'name': element.groupName });
      });
      this.selectedGroup = this.selectedGroupList[0];
      loginUserInfo.allToCcDBUsers.forEach(toCcBccUsers => {
        let individualRecipent: AllToCcDBUser = { ...toCcBccUsers };
        individualRecipent.email = individualRecipent.value;
        this.allToCcDBUser.push(individualRecipent);
      });
    });
  }

  getFilteredToCCUsers(query) {
    let filtered = [];

    this.allToCcDBUser.forEach(record => {
      if ((record.text.toLowerCase().indexOf(query.toLowerCase()) >= 0) || (record.id.toLowerCase().indexOf(query.toLowerCase()) >= 0)) {
        filtered.push(record);
      }
    });

    return filtered;
  }

  filterUsers(event) {
    let query = event.query;
    this.filteredToUsersMultiple = this.getFilteredToCCUsers(query);
  }

  onRightClick(event, roomId, roomName) {
    this.selectedRoomId = roomId;
    this.selectedRoomName = roomName;
    this.contextmenuX = event.clientX;
    this.contextmenuY = event.clientY;
    this.itemId = event.currentTarget.id;
    this.contextmenu = true;
  }

  disableContextMenu() {
    this.contextmenu = false;
  }

  filterItem(value:string){
    if(!value){
      this.filteredChatRooms = Object.assign([], this.chatRooms);
    }
    this.filteredChatRooms = Object.assign([], this.chatRooms).filter(
       item => item.name.toLowerCase().indexOf(value.toLowerCase()) > -1
    )
  }

  getUserSymphonyId(selectedSoeId: string, callback: Function) {
    console.log('get user symphonu id');
    let reqParam = {};
    reqParam['memberSoeId']=selectedSoeId;
    this.symphonyChatServiceService.getUserSymphonyId(reqParam).subscribe(
      (data: Object)=> {
        console.log(data);
        let userSymphonyId = data['payload']['id'];
        callback.call(this,userSymphonyId);
;      },
      (error)=>{
        console.log(error);
      }
    );
  }

  openAddMemberPopup(){
    this.displayAddMemberPopUp = true;
  }

  actionOnChatRoom(action: string){
    switch(action){
      case 'add':
        this.getUserSymphonyId(this.memberToBeAdded.id, this.addMember);
        break;
      case 'remove':
        this.getUserSymphonyId(this.memberToBeAdded.id, this.removeMember);
        break;
      case 'cancel':
        this.displayAddMemberPopUp = false;
        break;
    }
  }

  addMember(userSymphonyId: string){
    let reqParam = {};
    reqParam['memberSymphonyId'] = userSymphonyId;
    reqParam['streamId'] = this.selectedRoomId;
    this.symphonyChatServiceService.addMember(reqParam).subscribe(
      (data: Object)=> {
        console.log(data);
        this.actionOnChatRoom('cancel');
        this.showResponseMessage(data);
;      },
      (error)=>{
        console.log(error);
      }
    );
  }

  removeMember(userSymphonyId: string){
    let reqParam = {};
    reqParam['memberSymphonyId'] = userSymphonyId;
    reqParam['streamId'] = this.selectedRoomId;
    this.symphonyChatServiceService.removeMember(reqParam).subscribe(
      (data: Object)=> {
        console.log(data);
        this.actionOnChatRoom('cancel');
        this.showResponseMessage(data);
;      },
      (error)=>{
        console.log(error);
      }
    );
  }

  showResponseMessage(data: Object){
    this.displayResponse = true;
    this.responseMessage = data['message'];
  }

}

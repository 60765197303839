import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ContextMenuService } from 'src/app/services/contextMenu/context-menu.service';
import { CheckboxListComponent } from '../checkbox-list/checkbox-list.component';
import { Subscription } from 'rxjs';
import { NotificationService } from "src/app/common/services/notifications.service";
import { UserDataService } from "src/app/services/user-data.service";

@Component({
  selector: 'app-notify',
  templateUrl: './notify.component.html',
  styleUrls: ['./notify.component.scss']
})
export class NotifyComponent  implements OnInit,OnDestroy {
  display: boolean = false;
  ownershipModel: any;
  selectedMenuItem: any;
  contextServiceObject: any;
  groupsData: Array<any>;
  group: any;
  filteredGroups: any[];
  filteredOwnerGroups: any[];
  assignedUsersList: any[];
  tabArray: Array<boolean> = [true, false];
  nominateOwner: any;
  notifyGroup: any;
  ownerGroup: any;
  subManager = new Subscription();
  groups: any = [];
  firstGroup: any = [];
  userId: Array<any> = [];
  reason  = '';
  isReason : boolean = false;
  isOwnerSelected : boolean = false;
  notifyMe : boolean = false;
  selectedGroup : any = [];

  @ViewChild('owner', { static: true }) checkboxListComponent: CheckboxListComponent;
  @ViewChild('groupname', { static: true }) checkboxlistcomp: CheckboxListComponent;

  constructor(private contextMenuService: ContextMenuService, private notificationService:NotificationService, private userDataService: UserDataService) {
    this.contextServiceObject = contextMenuService;
  }
  ngOnInit() {
    
  }

  ngOnDestroy() {
    this.subManager.unsubscribe();
  }

  filterGroups(event) {
    this.filteredGroups = [];
    for (let i = 0; i < this.groupsData.length; i++) {
      let grp = this.groupsData[i].text;
      if (grp.toLowerCase().indexOf(event.query.toLowerCase()) !== "-1") {
        this.filteredGroups.push(grp);

      }
    }
  }

  triggerFalseClick() {
    if (!this.groupsData) {
      const sub1 = this.contextServiceObject.httpGetAllGroups().subscribe(res => {
        this.groupsData = res;
        this.groups.push({label: "Select All", name: "Select All", value: "Select All"});
        this.filteredOwnerGroups = [];
        for(let i = 0; i < this.notifyGroup.length; i++) {
            let grp = this.notifyGroup[i].groupName;
            this.groups.push({label: grp, name: grp, value: grp});
        }
        this.checkboxlistcomp.items = this.groups;
        this.checkboxlistcomp.assignCopy();
        this.subManager.add(sub1);
      });
    }
    this.display = true;
  }

  hideModal() {
    this.display = false;
    this.resetFields();
    this.subManager.unsubscribe();
  }

  selectedTab(index) {
    this.tabArray.fill(false);
    this.tabArray[index] = true;
  }

  getSelectedGroup(val: any) {
    let smallestGroup : String = "";
    let tempArr: any = [];    
    if (val.checkedStatus) {
      if (val.value === "Select All") {
        for (const groups of this.groups) {
          groups.binary = true;
          if (groups.value !== "Select All") {
            this.selectedGroup.push(groups.value);
          }
        }
      }else{
        const temp = this.notifyGroup.find(item => item.groupName === val.value);
        let indx = this.groups.findIndex((item:any) => item.value == val.value);
        this.groups[indx].binary = true;        
        if (temp) {
        this.selectedGroup.push(val.value);
          this.assignedUsersList = temp.groupOwner.map(obj => {
            const rObj = {};
            rObj["label"] = obj.text;
            rObj["value"] = obj.text;
            rObj["name"] = obj.value;
            return rObj;
          });
        }
      }
      if(this.selectedGroup.length > 0) {
        this.firstGroup = this.getFirstGroupOwner(this.selectedGroup, this.notifyGroup);
        for(let i = 0; i < this.selectedGroup.length; i++) {
          const temp1 = this.notifyGroup.find(item => item.groupName === this.selectedGroup[i]);
          tempArr = temp1.groupOwner.map(obj => {
            const rObj = {};
            rObj["label"] = obj.text;
            rObj["value"] = obj.text;
            rObj["name"] = obj.value;
            return rObj;
          });
          const array = this.getIntersectionOfArray(this.firstGroup, tempArr);
          this.assignedUsersList = array;
          this.firstGroup = this.assignedUsersList;
        }
      }
      if ((this.groups.length - 1) == this.selectedGroup.length) {          
        this.groups[0].binary = true;
        this.groups[0].checkedStatus = true;
      }
      this.assignedUsersList.unshift({value: "Select All", label: "Select All", name: "Select All"});          
    } else {
      if (val.value === "Select All") {
        for (const groups of this.groups) {
          groups.binary = false;
          if(groups.value !== "Select All") {
            const index = this.selectedGroup.indexOf(groups.value);
            this.selectedGroup.splice(index, 1);
          }
        }
        this.assignedUsersList = [];
        this.selectedGroup = []; 
      }else{
        const index = this.selectedGroup.indexOf(val.value);
        this.selectedGroup.splice(index, 1);
        let indx = this.groups.findIndex((item:any) => item.value == val.value);
        this.groups[indx].binary = false;
        this.groups[indx].checkedStatus = false;      
        if(this.selectedGroup.length > 0) {         
          this.firstGroup = this.getFirstGroupOwner(this.selectedGroup, this.notifyGroup);
          for(let i = 0; i < this.selectedGroup.length; i++) {
            const temp1 = this.notifyGroup.find(item => item.groupName === this.selectedGroup[i]);
            tempArr = temp1.groupOwner.map(obj => {
              const rObj = {};
              rObj["label"] = obj.text;
              rObj["value"] = obj.text;
              rObj["name"] = obj.value;
              return rObj;
            });
            const array = this.getIntersectionOfArray(this.firstGroup, tempArr);
            this.assignedUsersList = array;
            this.firstGroup = this.assignedUsersList;
            
          }
        }
        if (this.groups.length !== this.selectedGroup.length) {          
          this.groups[0].binary = false;
          this.groups[0].checkedStatus = false;
        }        
      }
      if(this.selectedGroup.length < 1) {
        this.assignedUsersList = [];
      }
    }
    //this.firstGroup = [this.assignedUsersList];
    this.checkboxListComponent.items = this.assignedUsersList;
    this.checkboxListComponent.assignCopy();
    this.checkboxListComponent.clearFilterText();
  }
  getIntersectionOfArray(array1, array2) {
    const array3 = [];
    array1.forEach( item1 => {
      array2.forEach( item2 => {
        if (item1.name === item2.name) {
          array3.push(item1);
        }
      });
    });
    return array3;
  }
  getFirstGroupOwner(selectedGroup, notifyGroup){
    const smallestGroup = this.getSmallestGroup(selectedGroup,notifyGroup);
    const temp2 = notifyGroup.find(item => item.groupName === smallestGroup);
    let firstGroup = temp2.groupOwner.map(obj => {
      const rObj = {};
      rObj["label"] = obj.text;
      rObj["value"] = obj.text;
      rObj["name"] = obj.value;
      return rObj;
    });
    return firstGroup;
  }  
  getSmallestGroup(selectedGroup, notifyGroup){
    let first = 0;
    let second = 0;
    let tempArr: any = [];
    let smallestGroup : String = "";
      for (var i=0; i < selectedGroup.length; i++) {
          const temp1 = notifyGroup.find(item => item.groupName === selectedGroup[i]);
          if (!temp1) {
            continue;
          }
          tempArr = temp1.groupOwner.map(obj => {
            const rObj = {};
            rObj["label"] = obj.text;
            rObj["value"] = obj.text;
            rObj["name"] = obj.value;
            return rObj;
          });
          if (first === 0) {
            first = tempArr.length;
          }
          second = tempArr.length;
          if (first >= second) {
            first = second;
            smallestGroup = selectedGroup[i];
          }
      }
      return smallestGroup;
  }
  getSelectedItems(val: any) {
    if(val.checkedStatus) {
      if(val.value === "Select All") {
        this.userId = [];
        for( var i = 0; i < this.assignedUsersList.length; i++){
          this.assignedUsersList[i].binary = true;
          this.assignedUsersList[i].checkedStatus = true;
          if(this.assignedUsersList[i].name !== "Select All") {
            this.userId.push(this.assignedUsersList[i].name);
          }
        }
      }else{        
          this.userId.push(val.name);        
      }
      this.isOwnerSelected = false;
    }else{
      if(val.value === "Select All") {
        for( var i = 0; i < this.assignedUsersList.length; i++){
          this.assignedUsersList[i].binary = false;
          this.assignedUsersList[i].checkedStatus = false;
        }
      this.userId = [];
      }else{
        this.assignedUsersList[0].binary = false;
        this.assignedUsersList[0].checkedStatus = false;
        const ind = this.assignedUsersList.findIndex( item => item.name === val.name);
        this.assignedUsersList[ind].binary = false;
        this.assignedUsersList[ind].checkedStatus = false;
        const index = this.userId.indexOf(val.name);        
        this.userId.splice(index, 1);
      }  
    }    
  }
  compare(a, b) {
    if (a.userId === b.userId) {
      return true;
    } else {
      return false;
    }
  }
  addLoginUser(e) {
    this.notifyMe = e.target.checked;
    const userId = this.notificationService.getLoginUserId();
    this.userId.push(userId);
  }
  setNotification() {
    let inqiryId: any;
    console.log("reason", this.reason);
    let inquiry = this.notificationService.getNotificationInquiry();
    inqiryId = inquiry? inquiry._id : null;
    if ((this.reason !== null && this.reason !== '') && this.userId.length > 0) {
          const inquiryData = {
          inquiryId: [inqiryId],
          users: this.userId,
          reason: this.reason
      };
          this.notificationService.notifyUserInquiry(inquiryData).subscribe(inquiryDetail => {
          });
          this.hideModal();
    } else {
      if (this.userId.length === 0) {
        this.isOwnerSelected = true;
      } else {
        this.isReason = true;
        this.isOwnerSelected = false;
      }
    }
  }

  resetFields() {
    this.nominateOwner = "";
    this.ownerGroup = "";
    this.group = "";
    this.assignedUsersList = [];
    this.checkboxListComponent.items = this.assignedUsersList;
    this.checkboxListComponent.assignCopy();
    this.firstGroup = [];
    this.checkboxListComponent.resetCheckList();
    this.checkboxlistcomp.resetCheckList();
    this.checkboxListComponent.clearFilterText();
    this.checkboxlistcomp.clearFilterText();
    this.isReason = false;
    this.isOwnerSelected = false;
    this.notifyMe = false;
    this.reason = "";
  }

  /**
   *find all the groups associated with an inquiry
   */
  applyNotifyGroup(inquiry) {
    if (!inquiry) {
      return;
    }
    //handle first element if inquiry is an array
    if (Array.isArray(inquiry) && inquiry.length) {
      inquiry = inquiry[0];
    }
    let loginUserInfo = this.userDataService.loggedInUserInfo;
    if (!loginUserInfo) {
      return;
    }
    // collect groups in the inquiry
    let igrps = new Set();
    if (inquiry.allToCCGrps) {
      let grps = inquiry.allToCCGrps.split(';');
      if (grps) {
        grps.forEach(g => igrps.add(g));
      }
    }
    if (inquiry.allToCCGrps) {
      let grps = inquiry.openGroups.split(';');
      if (grps) {
        grps.forEach(g => igrps.add(g));
      }
    }
    if (inquiry.workflows && inquiry.workflows.length) {
      inquiry.workflows.forEach(wf => {
        if (wf && wf.assignedGroupName) {
          igrps.add(wf.assignedGroupName);
        }
      });
    }
    let notifyGrps = [];
    for (let i = 0; i < loginUserInfo.context.length; i++) {
      let groupOwnerList = [], uniqueData = [], assignMenuAvailable = [];
      for (var item = 0; item < loginUserInfo.context[i].assignOwnerList.length; item++) {
        if (loginUserInfo.context[i].assignOwnerList[item].active) {
          groupOwnerList.push(loginUserInfo.context[i].assignOwnerList[item]);
        }
      }

      for (var idx = 0; idx < loginUserInfo.myGroups.length; idx++) {
        if (loginUserInfo.myGroups[idx].id == loginUserInfo.context[i].groupId &&
            igrps.has(loginUserInfo.myGroups[idx].groupName)) { // filter aginst myGfr
          notifyGrps.push({
            groupOwner: groupOwnerList,
            groupName: loginUserInfo.myGroups[idx].groupName,
            id: loginUserInfo.myGroups[idx].id
          })
        }
      }
    }
    // assign notify groups and reset the group and user list
    this.notifyGroup = notifyGrps;
    this.groupsData = null;
    this.groups = [];
    this.assignedUsersList = [];
    this.selectedGroup = [];
    }
}

import { Directive, Output, Input, EventEmitter, HostBinding, HostListener } from '@angular/core';
import { InboxService } from '../../services/inbox.service';
import {forkJoin} from 'rxjs';
import { Observable } from 'rxjs';
import * as fileSaver from 'file-saver';
import { AppUtils } from 'src/app/common/utility/appUtil';
import { QmaConstant } from 'src/app/constant/qma-constant';

@Directive({
  selector: '[appDragDrop]'
})
export class DragDropDirective {
  @Output() onFileDropped = new EventEmitter<any>();
  @HostBinding('style.background-color') private background = 'rgba(0,45,114,0)'
  @HostBinding('style.opacity') private opacity = '1'
  //keep track of tabName of each directive
  tabName: string;
  
  constructor(private inboxService : InboxService) {
    this.tabName = AppUtils.getCurrentTab();
  }
  //Dragover listener
  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#9ecbec';
    this.opacity = '0.8'
  }
  //Dragleave listener
  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = 'rgba(0,45,114,0)'
    this.opacity = '1'
  }
  //Drop listener
  @HostListener('drop', ['$event']) public ondrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = 'rgba(0,45,114,0)'
    this.opacity = '1'
    
    let files = evt.dataTransfer.files;
    // clear file drag state
    this.inboxService.setFileDragState(false);
    //check whether file drag/drop from file system from windows folder explorer
    if (files.length > 0) {
      this.onFileDropped.emit(files)
    }
    else {
      //check for mail item drag drop 
      this.download();
    }
  }

  @HostListener('window:keydown',['$event'])
  onKeyPress($event: KeyboardEvent) {
    //copy paste issue
    let target: any = $event.target;
    let x = document.getElementsByClassName("ui-dialog ui-widget");

    if($event.ctrlKey  && $event.keyCode == 86) {
        //copy paste issue
        // - make sure this directive is in the currently selected tab, before proceeding Ctrl-V handling
        if(!x.length && target.tagName.toLowerCase() !== "input" && this.tabName === AppUtils.getCurrentTab()) {
          console.log('CTRL +  V', $event, 'tabName=' + this.tabName);
          this.download();
        }
    }
  }

download() {
  var convId = sessionStorage.getItem("curConvId");
  var inqSub = sessionStorage.getItem("curInqSubject");
  var copiedattch = sessionStorage.getItem("copiedattch");
  let groupId = parseInt(sessionStorage.getItem("groupId"));
  let hasNominatedOwnership: boolean =  eval(sessionStorage.getItem("hasNominatedOwnership"));
  if (
      (
        convId == null || 
        inqSub == null
      )  && 
        copiedattch  == null
    ) {
    //- clear the sessionStorage even one of the two attrs are present.
    sessionStorage.removeItem("curConvId");
    sessionStorage.removeItem("curInqSubject");
    sessionStorage.removeItem("groupId");
    sessionStorage.removeItem("hasNominatedOwnership");
    return;
  }
  if (convId != null && inqSub != null) {
    this.inboxService.downloadAsEmailAndReturnFile(convId, inqSub, groupId, hasNominatedOwnership).subscribe ( response => {
    let files = [];
    let subject = sessionStorage.getItem("curInqSubject");
    // avoid 'null' as download filename
    if (subject === null || subject === undefined || subject === 'null') {
      subject = QmaConstant.NO_SUBJECT;
    }
    let filename = '' + subject;
    let respfile = new File([response],filename + ".eml"); // Handle special characters in backend   
    console.log("downloading converstion as " + filename + ".eml" );
    // avoid 'null' as download filename
    files.push(respfile);
    sessionStorage.removeItem("curConvId");
    sessionStorage.removeItem("curInqSubject");

    this.onFileDropped.emit(files);
  
   });
  }
   if (copiedattch != null)
   {
     //check for the list of files marked for new attachment copy
    
     var attchlist = copiedattch.substring(1).split('~');
     var attchFiles = [];
    let observables :Observable<Blob>[] = [];
     for (var index=0; index < attchlist.length; index++) {
      var curattach = attchlist[index];
      if (curattach != '') {
        
        observables.push(this.inboxService.downloadAttachmentForReupload(curattach));
     }
       
     forkJoin(observables).subscribe( responseArray =>  {

      let files = [];
      var attchlist = copiedattch.substring(1).split('~');
      if (responseArray.length === attchlist.length) {
            for (var index1=0; index1<responseArray.length;index1++)
            {
               var response = responseArray[index1];
               
              // If the the attachment consist : in name it doesn't upload.
              let attachment = attchlist[index1];
              let attachmentName= attachment.substring(attachment.indexOf(':') + 1, attachment.length).trim();
              var filename = '' + attachmentName;
              var respfile = new File([response],filename);
              files.push(respfile);
             
             }

            this.onFileDropped.emit(files);
        }
      });

        
     }
   
     sessionStorage.removeItem("copiedattch");
   }  
  } 
}

<div id="adv" #advSearchContainer> <!-- Jira : C153176-5045 : Advance search pop up issue -->
<form [formGroup]="advSearchForm" class="advance-search" (ngSubmit)="submitSearch(advSearchForm.value)">
  <div>
    <div>ADVANCED CRITERIA
      <button type="button" (click)="closeAdvanceSearch()" class="d-flex flex-row close qma-text cross-button">
        <img src="assets/Inbox/close_icon_u73.svg" style="opacity:1" alt="Close">
      </button>
    </div>
    <div class="bottom-container w-100 mb-3">
      <div class="d-flex flex-row flex-nowrap align-items-end w-100">
        <label class="mobile-view-flex2">Content:</label>
        <div class="w-100 pl-2 mobile-view-flex3">
          <input type="text" class="w-100" formControlName="content">
        </div>
      </div>
      <div class="d-flex flex-row flex-nowrap mt-4 w-100 justify-content-between mobile-view-flex">
        <div class="d-flex flex-row flex-nowrap align-items-end w-50 pr-2 mobile-view-flex1 mobile-view-whitespace">
          <label class="mobile-view-flex2">Subject:</label>
          <div class="w-75 pl-2 mobile-view-flex1">
            <input type="text" class="w-100" formControlName="subject">
          </div>
        </div>
        <div class="d-flex flex-row flex-nowrap justify-content-end align-items-end w-50 pl-2 mobile-view-flex1">
          <label class="mobile-view-flex2">To or From:</label>
          <div class="w-75 pl-2 form-width mobile-view-flex1">
            <input type="text" class="w-100" formControlName="originator">
          </div>
        </div>
      </div>
      <div class="d-flex flex-row flex-nowrap mt-4 w-100 justify-content-between mobile-view-flex">
        <div class="d-flex flex-row flex-nowrap align-items-end w-50 pr-2 mobile-view-flex1 mobile-view-whitespace">
          <label class="mobile-view-flex2">Date From:</label>
          <div class="w-75 pl-2 form-width mobile-view-flex1">
            <p-calendar dateFormat="dd/mm/yy"   formControlName="startDt" [showOtherMonths]="true" [hideOnDateTimeSelect]="true" [selectOtherMonths]="false"
              [monthNavigator]="true" [yearNavigator]="true"   [yearRange]="'2010:2030'" [styleClass]="'advance-calender'" [showButtonBar]="true"
              [readonlyInput]="false"   [showIcon]="true"  (onSelect)="startDateSelect($event)" >
            </p-calendar>
          </div>
        </div>
        <div class="d-flex flex-row flex-nowrap align-items-end w-50 pl-2 justify-content-end mobile-view-flex1">
            <label class="mobile-view-flex2">Date To:</label>
              <div class="w-75 pl-2 d-flex flex-column justify-content-left mobile-view-flex1">
                <p-calendar dateFormat="dd/mm/yy" formControlName="endDt" [showOtherMonths]="true" [hideOnDateTimeSelect]="true" [selectOtherMonths]="false"
                  [readonlyInput]="false" [monthNavigator]="true" [yearNavigator]="true"   [yearRange]="'2010:2030'"
                    [styleClass]="'advance-calender2'" [showIcon]="true" [showButtonBar]="true" (onSelect)="endDateSelect($event)"></p-calendar>
                  </div>
        </div>
      </div>
      <div class="d-flex flex-row flex-nowrap mt-4 w-100 justify-content-between mobile-view-flex">
        <div class="d-flex flex-row flex-nowrap align-items-end w-50 pr-2 mobile-view-flex1 mobile-view-whitespace">
          <label class="mobile-view-flex2">ID:</label>
          <div class="w-75 pl-2 form-width  mobile-view-flex1">
            <input type="text" class="w-100" formControlName="inquiryId">
          </div>
        </div>
        <div class="d-flex flex-row flex-nowrap align-items-end w-50 pl-3 justify-content-end mobile-view-flex1">
          <label class="mobile-view-flex2">Attachments:</label>
          <div class="w-75 pl-2 d-flex flex-column justify-content-left mobile-view-flex1">
            <p-inputSwitch [styleClass]="'advance-inputSwitch'" formControlName="attachment"></p-inputSwitch>
          </div>
        </div>
      </div>
      <div class="w-100">
        <div *ngIf="error.isError"  class="d-flex flex-row flex-nowrap justify-content-end text-danger error-calender">
          <img src="assets/newMessage/error.svg"  class="error" alt="Error">{{ error.errorMessage }}
        </div>
      </div>
      <div *ngIf="advSearchError" class="mt-4 pt-2 d-flex flex-row flex-nowrap align-items-center invalid-feedback">
        <div><img src="assets/newMessage/error.svg" class="error" alt="Error"> Please select valid search criteria</div>
      </div>
    </div>
    <div class="more-options">
      <div class="chevron-holder">
        More Options
        <em class="fa " (click) = toggleDisplayDiv() [ngClass]="!showMoreOptions ? 'fa-chevron-up': 'fa-chevron-down'"></em>
      </div>
      <div class="d-flex flex-row flex-wrap justify-content-between top-adv-container py-2" *ngIf = "showMoreOptions">
        <div class="d-flex flex-column mobile-attachment">
          <div class="mb-2">
            <ins>Search In</ins>
                <em *ngIf="shouldShow" class="fa fa-angle-up dropdown-section" (mouseenter)="showSlide(this)" style="font-size:26px;"></em>
                <em *ngIf="!shouldShow" class="fa fa-angle-down dropdown-section" (mouseenter)="showSlide(this)" style="font-size:26px;"></em>
          </div>
          <div class="d-flex flex-column flex-nowrap advance-search-elem mobile-search-height" [ngClass]="{showFilterContent: shouldShow, hideFilterContent: !shouldShow}">
            <div class="d-flex justify-content-left flex-column flex-nowrap">
                <!-- Jira : C153176-4540 -->
              <div class="searchRadio" > 
                <ul class="list-disp pl-2 pr-1 searchIn">
                    <li *ngFor="let item of mailboxesJson" class="d-flex flex-row flex-wrap mb-1 pr-1 subMenu" style="padding-bottom:10px;">
                        <p-radioButton name="mailboxes" value="{{item.value}}" label="{{item.value}}" (onClick)="getSelectedBox(item.value)" 
                        [styleClass]="['advance-SearchIn-Items',  'listUpperCase']" formControlName="mailboxes"></p-radioButton>
                    </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex flex-column">
          <div class="mb-2">
            <ins>Assigned Groups</ins>
                <em *ngIf="assignedShouldShow" class="fa fa-angle-up dropdown-section" (mouseenter)="assignedShowSlide(this)" style="font-size:26px;"></em>
                <em *ngIf="!assignedShouldShow" class="fa fa-angle-down dropdown-section" (mouseenter)="assignedShowSlide(this)" style="font-size:26px;"></em>
          </div>
          <div class="d-flex flex-column flex-nowrap advance-search-elem" [ngClass]="{showFilterContent: assignedShouldShow, hideFilterContent: !assignedShouldShow}">
            <div>
              <app-checkbox-list [placeholder]="'Quick Search...'" [name]="'groups'" [items]="assignedGroups"
                [listStyle]="{'height':'134px'}" [scroll]="scroll" checked = "true" (onItemSelect)="getAssignedGroups($event)"></app-checkbox-list>
            </div>
          </div>
        </div>
        <div class="d-flex flex-column mobile-attachment">
          <div class="mb-2">
            <ins>Request Types</ins>
                <em *ngIf="requestShouldShow" class="fa fa-angle-up dropdown-section" (mouseenter)="requestShowSlide(this)" style="font-size:26px;"></em>
                <em *ngIf="!requestShouldShow" class="fa fa-angle-down dropdown-section" (mouseenter)="requestShowSlide(this)" style="font-size:26px;"></em>
          </div>
          <div class="d-flex flex-column flex-nowrap advance-search-elem" [ngClass]="{showFilterContent: requestShouldShow, hideFilterContent: !requestShouldShow}">
            <app-checkbox-list [placeholder]="'Quick Search...'" [name]="'request'" [items]="requestTypes" [listStyle]="{'height':'134px'}"
              [scroll]="scroll" (onItemSelect)="getRequestGroups($event)"></app-checkbox-list>
          </div>
        </div>
        <div class="d-flex flex-column">
          <div class="mb-2">
            <ins>Tags</ins>
                <em *ngIf="tagsShouldShow" class="fa fa-angle-up dropdown-section" (mouseenter)="tagsShowSlide(this)" style="font-size:26px;"></em>
                <em *ngIf="!tagsShouldShow" class="fa fa-angle-down dropdown-section" (mouseenter)="tagsShowSlide(this)" style="font-size:26px;"></em>
          </div>
          <div class="d-flex flex-column flex-nowrap advance-search-elem" [ngClass]="{showFilterContent: tagsShouldShow, hideFilterContent: !tagsShouldShow}">
            <app-checkbox-list [placeholder]="'Quick Search...'" [name]="'tag'" [items]="tags" [scroll]="scroll"
              [listStyle]="{'height':'134px'}" (onItemSelect)="getTags($event)"></app-checkbox-list>
          </div>
        </div>
        
        <div class="d-flex flex-column mobile-attachment">
          <div class="mb-2">
            <ins>Status</ins>
                <em *ngIf="statusShouldShow" class="fa fa-angle-up dropdown-section" (mouseenter)="statusShowSlide(this)" style="font-size:26px;"></em>
                <em *ngIf="!statusShouldShow" class="fa fa-angle-down dropdown-section" (mouseenter)="statusShowSlide(this)" style="font-size:26px;"></em>
          </div>
          <div class="d-flex flex-column flex-nowrap advance-search-elem mobile-status-height" [ngClass]="{showFilterContent: statusShouldShow, hideFilterContent: !statusShouldShow}">
            <div class="padding-10">
              <app-checkbox-list [placeholder]="'Quick Search...'" [name]="'status'" [search]="false" [items]="statusJson"
                [listStyle]="{'height':'203px'}" [scroll]="scroll" [styleClass]="['advance-SearchIn-Items', 'listUpperCase']"
                (onItemSelect)="getStatusBox($event)"></app-checkbox-list>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex flex-row flex-wrap bottom-button-holder p-3 justify-content-end mobile-holder">
    <button type="submit" class="btn search-btn mr-5">Search</button>
    <button type="button" class="btn clear-btn" (click)='clearSearch()'>Clear</button>
  </div>
</form> 
</div><!-- Jira : C153176-5045 : Advance search pop up issue -->
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { CalendarEvent } from 'calendar-utils';

const httpFormGetOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': 'true'
  })
};

@Injectable({
  providedIn: 'root'
})
export class PersonalCalendarService {
  baseURL: string;
  calendarData: any;

  constructor(private httpClient: HttpClient) {  }

  httpGetCalendarData() {
    return this.httpClient.get<CalendarEvent[]>("/QMA2/addon/getEvents", httpFormGetOptions);
  }

  httpPostCreateEvent(reqObject) {
    return this.httpClient.post<Boolean>("/QMA2/addon/createEvent", reqObject, httpFormGetOptions);
  }

  httpPostDeleteEvent(reqObject) {
    return this.httpClient.post<Boolean>("/QMA2/addon/deleteEvent", reqObject, httpFormGetOptions);
  }

}

import { Component, OnInit, Input, Output, EventEmitter, ViewChildren, QueryList, ElementRef, ViewChild, OnChanges, ChangeDetectorRef} from '@angular/core';


@Component({
  selector: 'app-checkbox-list',
  templateUrl: './checkbox-list.component.html',
  styleUrls: ['./checkbox-list.component.scss']
})


export class CheckboxListComponent implements OnInit, OnChanges{

  @Input() placeholder:String;
  @Input() items:Array<object>;  
  @Input() listStyle:any;
  @Input() maxSelection:number = -1;
  @Input() name:string = "demo";
  @Input() search:boolean = true;
  @Input() scroll:any = 'native';
  @Input() styleClass:any = 'search-list';
  @Input() controlType:string='checkbox';
  @Input() enableSelectAll:boolean = false;
  @Input() searchIcon:boolean = false;
  @Input() resetBehaviour:boolean = false;
  @Output() onItemSelect = new EventEmitter(); 
  @Output() onMaxSelection = new EventEmitter();
  @Output() onSelectAll = new EventEmitter();

  //Added for Column chooser
  @Output() onItemChecked = new EventEmitter(); 
  selectedCheckBox:any;
  selectedItemLabel:any;
  selectAllBinary: boolean; //selectAll checkbox flag

  shown: 'native' | 'hover' | 'always' = 'native';
  @ViewChildren("checkboxes") checkboxes: QueryList<ElementRef>;
  @ViewChildren("radioboxes") radioboxes: QueryList<ElementRef>;
    
  filteredItems:Array<object>;
  @Input() selectedValue:Array<object> = [];
  checkState:boolean = true;
  @ViewChild('myInput') myInputRef: ElementRef;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.assignCopy();
  }

  //  take into account of outbox labels: Sent vs. Outbox
  compareLabel(l1, l2) {
    return (l1 === l2 || (l1 === 'Sent' && l2 === 'Outbox') || (l1=== 'Outbox' && l2 === 'Sent') || 
    (l1 === 'FollowUp' && l2 === 'Follow Up') || (l1=== 'Follow Up' && l2 === 'FollowUp'));
  }

  applySelection(selected) {
    if(selected && selected.length) {
      this.selectedValue = [];
      // check whether all options are selected
      let _selectedAll = true;
      this.filteredItems.forEach((item:any) => {
        if(selected.findIndex(s => this.compareLabel(s.label, item.label)) < 0) {  //call refactor method
          item.binary = false;
          _selectedAll = false; 
        }
      });
      selected.forEach((s) => {
        let index = this.filteredItems.findIndex((item:any) => this.compareLabel(s.label, item.label)); //call refactor method
        if (index >= 0) {
          this.filteredItems[index]['binary'] = true;
          this.selectedValue.push(this.filteredItems[index]);
          this.selectedItemLabel = this.filteredItems[index]['label'];
        } else {
          console.log("Didn't find selected item:", s);
          _selectedAll = false; 
        }
      });
      //set selectAll flag
      if (this.checkboxes && this.checkboxes.first) {
        this.checkboxes.first.nativeElement.checked = _selectedAll;
      }
      this.selectAllBinary = _selectedAll;
      this.onItemSelect.emit(this.selectedValue);
      console.log('checkbox-list, name =' + this.name, this.filteredItems)
    } else {
      console.log("Invalid selected items!");
    }
    this.cdr.detectChanges(); // force detecting changes
  }

  syncSelectedValue() {
    if(this.controlType === 'checkbox' && this.selectedValue && this.selectedValue.length && this.filteredItems) {
      this.selectedValue.forEach((s:any) => {
        let index = this.filteredItems.findIndex((item:any) => item.label === s.label);
        if (index >= 0) {
          this.filteredItems[index]['binary'] = true;
        } else {
          console.log("syncSelectedValue(): Didn't find selected item:", s);
        }
      });
    }
  }
  /* To get selected items */
  getSelection(e:any){
    if(!this.checkMaxSelection(e)){
      if (this.controlType === 'radio') {
        this.selectedValue = [];
      }
      if(e.target.checked){
        let index = this.items.findIndex((item:any) => item.label == e.target.value);
        this.selectedValue.push(this.items[index]);
        this.selectedItemLabel = this.items[index]['label'];
      } else {
        let ind = this.selectedValue.findIndex((item:any)=> item.label === e.target.value);
        this.selectedValue.splice(ind, 1);
      }
    }
    else{
      this.onMaxSelection.emit(this.selectedValue);
    }
    this.onItemSelect.emit(this.selectedValue);
    //to check uncheck select All based on selection is selet all is enabled
    if (this.enableSelectAll) {
      if (this.selectedValue.length === this.items.length) {
        //deseleecting select all checkbox
        this.checkboxes.first.nativeElement.checked = true;
        this.selectAllBinary = true; 
      } else {
        this.checkboxes.first.nativeElement.checked = false;
        this.selectAllBinary = false;
      } 
    }
  }

  ngOnChanges() {
    if(this.resetBehaviour){
      this.assignCopy();
      if(this.checkboxes){
        this.resetCheckList();  
      }
    }   
  }

  updateSelectedItems(e:any){
    let index = this.items.findIndex((item:any) => item.label == e.target.value);   
    if(e.target.checked){           
      this.selectedCheckBox=this.items[index];
      this.selectedCheckBox.checkedStatus=true;
      this.onItemChecked.emit(this.selectedCheckBox);
      this.selectedItemLabel = this.items[index]['label'];
    }
    else{
      this.selectedCheckBox=this.items[index];
      this.selectedCheckBox.checkedStatus=false;
      this.onItemChecked.emit(this.selectedCheckBox);
    }
    
  }

  /* TO check the max number selection */
  checkMaxSelection(e:any){
    if (this.selectedValue.length >= this.maxSelection && e.target.checked && this.maxSelection !== -1) {
      e.target.checked = false;
      e.preventDefault();
      e.stopPropagation(); 
      return true;
    }else{
      return false;
    }
  }
  
/* Create new copy of items not to alter current item while search */
  assignCopy(){   
    this.filteredItems = Object.assign([], this.items);  
  }

  assignDynamicId(_item:any, i:number){
    return this.name +"-"+ _item.label + i;
  }

  /* Filter to searched Records */

  // as it is has impact in search-list.component,recipent-data.component etc
  filterItem(value:string){
    if(!value){
        this.assignCopy();
    }
    if(value && value.length>=2){ // search start only after type 3 character regarding C170665-1013
      this.filteredItems = Object.assign([], this.items).filter(
        item => item.label.toLowerCase().indexOf(value.toLowerCase()) > -1
     )
     this.syncSelectedValue();
    }
  }

  public resetCheckList(){
    if(this.controlType !== 'radio') {
      this.checkboxes.forEach((element) => {
        element.nativeElement.checked = false;
      });
      this.assignCopy();
    } else {
      //set radiobox initial checked flag
      this.radioboxes.forEach((element) => {
        element.nativeElement.checked = false;
      });
      this.filteredItems.forEach((item: any) => {
        item.binary = false;
      });
    }
    this.selectedValue.length = 0;
    this.cdr.detectChanges();
    this.onItemSelect.emit(this.selectedValue);
    
  }

  // method to check all checkboxes
  public selectAllCheckBoxws() {
    if(this.controlType !== 'radio') {
      this.checkboxes.forEach((element) => {
        element.nativeElement.checked = true;
      });
      this.assignCopy();
    }
  }
  /** This Method is added for reset the Single Checkbox */
  public resetCheckBox(e:any){
    this.checkboxes.forEach((element) => {     
        if(element.nativeElement.value == e.value){
          element.nativeElement.checked = false;
        } 
    });
    this.selectedValue.length = 0;
    this.assignCopy();
    this.cdr.detectChanges();
    this.onItemSelect.emit(this.selectedValue);
  }

  public getCheckedList(){
    return this.checkboxes;
  }

  public clearFilterText() {
    if (this.myInputRef && this.myInputRef.nativeElement) {
      this.myInputRef.nativeElement.value = "";
    }
  }

  // setting passed array values only, rest will be unchecked from check list
  // typeCompaire is to define whethere "value" of "lable"

  setCheckedOnly(selected) {
    if(selected && selected.length) {
      this.selectedValue = [];
    
      this.items.forEach((s:any, idx) => {
        let index = selected.findIndex((item:any) => item == s["label"]);
        if (index >= 0) {
          this.items[idx]['binary'] = true;
          this.selectedValue.push(this.items[idx]);
          this.selectedItemLabel = this.items[idx]["label"];
          this.setCheckByValue(this.selectedItemLabel, true);
        } else {
          this.items[idx]['binary'] = false;
          this.setCheckByValue(this.items[idx]["label"], false);
        }
      });
      
      this.onItemSelect.emit(this.selectedValue);      
     // console.log('checkbox-list, name =' + this.name, this.filteredItems)
    } else {
      this.resetCheckList();
    }
  }

  setCheckByValue(val, state:boolean){
    this.checkboxes.forEach((element) => {     
      if(element.nativeElement.value == val){
        element.nativeElement.checked = state;
      }
    });
  }


  onSelectAllClick(e:any){
    let bool = e.currentTarget.checked;
    this.checkboxes.forEach((element) => {    
      element.nativeElement.checked = bool;
    });
    this.onSelectAll.emit(bool);
    this.triggerAllSelection(bool);
    this.syncSelectedValue(); //force sync up
  }

  triggerAllSelection(_bool:boolean){
    if(_bool){
    this.items.forEach((val:any)=>{
      let idx = this.selectedValue.findIndex((item:any) => item.label === val.label);   
      if(idx == -1){
        let obj:any = val;
        obj.binary = _bool; //assign _bool instead of false
        obj.checkedStatus = true;
        this.selectedValue.push(obj);
      }
    });
  }
  else{
    this.selectedValue = [];
  }
   // this.onItemSelect.emit(temp)
  }
  //Select All option for Column choosers
  // if all checkboxes are checked then check selelectasll Option
  selectAllCheckboxOption() {
    // to check uncheck select All based on selection is selet all is enabled
    if (this.enableSelectAll) {
      if (this.items.length > 0 && this.items.filter((i: any)=> !i.binary).length === 0) {
        // deseleecting select all checkbox
        this.checkboxes.first.nativeElement.checked = true;
        this.selectAllBinary = true; // update flag
      } else {
        this.checkboxes.first.nativeElement.checked = false;
        this.selectAllBinary = false; //update flag
      }
    }
  }
}

<p-dialog [(visible)]="display" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'"
  [styleClass]="'contextMenuModal'" [closable]="false" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true"
  [style]="{'max-width':'1100px','width':'100%','max-height' : '100%'}" [resizable]="false"><!--GFID popup width fix  -->
  <p-header>
    <div class="d-flex flex-row align-items-center justify-content-between search-padding">
      <h6 class="gfid-title">SEARCH GFPID / GFCID</h6>
      <button type="button" (click)="hideModal(gfcForm)" class="d-flex flex-row close qma-text">
        <img src="assets/Inbox/close_icon_u73.svg" alt="Close">
        <h6>CLOSE</h6>
      </button>
    </div>
  </p-header>
  <div class="gfid-body">
    <form #gfcForm="ngForm" (ngSubmit)="gfcForm.valid && validateAndSearch(gfcForm)">
        <div *ngIf="enableAmcarIntegration" class="d-flex flex-row align-items-center pr-4">
          <div class="mr-3" style="font-weight: bold;">Enable account level search:</div>
          <input type="checkbox" name="accCriteria" value="gfcModel.accCriteria" [(ngModel)]="gfcModel.accCriteria" 
            style="width: 18px; height: 18px;" (change)="onAccountLvlSearch($event)">
        </div>
      <div class="d-flex flex-row align-items-center mt-3 pr-4">
        <div class="mr-3" style="font-weight: bold;">Search by:</div>
        <div *ngFor="let item of radioButtons; let i = index" class="custom-control custom-radio custom-control-inline">
          <input type="radio" class="form-control custom-control-input" [attr.id]="item.name+'_'+i" name="filCriteria"
            [value]="item.val" [(ngModel)]="gfcModel.filCriteria" (change)="onSelectCriteria(item)">
          <label class="custom-control-label" [attr.for]="item.name+'_'+i">{{item.name}}</label>
        </div>
        <div *ngIf="gfcModel.accCriteria" class="d-flex flex-row align-items-center flex-grow-1">
          <div class="ml-4 mr-4 pt-2">
            <label for="from">Branch</label>
          </div>
          <div class="pt-1">
            <input type="text" placeholder="Enter branch..." [(ngModel)]="gfcModel.acctBranch" #acctBranch="ngModel" name="acctBranch"
             class="w-75 t-box" />
          </div>
        </div>
      </div>
      <div class="d-flex flex-row mt-4">
        <div>
          <select class="form-control custom-select mr-4 qma-select" id="filterType" name="filterType" [(ngModel)]="gfcModel.filterType">
              <option value="contains">Select...</option>
            <option value="contains">Contains</option>
            <option value="beginsWith">Begins With</option>
            <option value="equals">Equals</option>
          </select>
        </div>
        <div class="search-holder">
          <div class="input-group search">
            <input type="text" class="form-control" required #filterVal="ngModel" minlength="3" placeholder="search..."
              name="filterVal" [(ngModel)]="gfcModel.filterVal">
            <div class="input-group-btn searchBox">
              <button type="submit" class="btn btn-default">
                <span>
                  <img style="width:18px;height:24px;" src='assets/core/layout/header/search_active.svg' alt="Search">
                </span>
              </button>
            </div>
          </div>
          <div class="form-control-feedback text-danger" *ngIf="filterVal.errors && (filterVal.dirty || filterVal.touched )">
            <p class="text-danger" *ngIf="filterVal.errors.required">Input is required</p>
            <p class="text-danger" *ngIf="filterVal.errors.minlength">Please enter atleast 3 character</p>
          </div>
        </div>
      </div>
    </form>
    <div class="grid-holder mt-4">
      <div *ngIf="firstLoad">
      <ag-grid-angular *ngIf="!gfcModel.accCriteria" style="height:350px;" class="ag-theme-balham inbox-grid gfid-grid"
        [gridOptions]="gridOptions" [rowData]="rowData" [columnDefs]="columnDefs" (gridReady)="onGridReady($event)" (suppressHorizontalScroll)="true"
        (rowClicked)='onRowClicked($event)' [suppressPaginationPanel]="true" (paginationChanged)="onPaginationChanged($event)" [modules]="modules">
      </ag-grid-angular>
      <ag-grid-angular *ngIf="gfcModel.accCriteria" style="height:350px;" class="ag-theme-balham inbox-grid gfid-grid"
        [gridOptions]="gridOptions" [rowData]="rowData" [columnDefs]="accountColumnDefs" (gridReady)="onGridReady($event)" (suppressHorizontalScroll)="true"
        (rowClicked)='onRowClicked($event)' [suppressPaginationPanel]="true" (paginationChanged)="onPaginationChanged($event)" [modules]="modules">
      </ag-grid-angular>
      <div style="margin-top: 1%;height:3vh!important" class="d-flex justify-content-end qma-mailbox-pagination-container">
        <span class="value" id="lbPageSize">{{lbPageSize}}</span> <span class="separator"> of </span>
        <span class="value separator" id="lbTotalRecords">{{lbTotalRecords}}</span>
        <span class="separator separator-right"> Inquiries </span>
      
        <span>
          <img [ngClass]="{'pagination-img-disabled':disablePaginationPrevFirst}" (click)="onBtFirst()" id="btFirst"
            class="pagination-img" src="assets/Inbox/pagination-arrow-first.svg" alt="First"/>
        </span>
        <span class="separator-right">
          <img id="btPrevious" [ngClass]="{'pagination-img-disabled':disablePaginationPrevFirst}" (click)="onBtPrevious()"
            class="pagination-img" src="assets/Inbox/pagination-arrow-prev.svg" alt="Previous" />
        </span>
      
        <span class="separator-right pagination-text-padding">Page </span>
        <span id="lbCurrentPage" class="separator-right pagination-text-padding">{{lbCurrentPage}}</span>
        <span class="separator-right pagination-text-padding">of</span>
        <span id="lbTotalPages" class="separator-right pagination-text-padding">{{lbTotalPages}}</span>
      
        <span>
          <img [ngClass]="{'pagination-img-disabled':disablePaginationNextLast}" id="btNext" (click)="onBtNext()"
            class="pagination-img" src="assets/Inbox/pagination-arrow-dark.svg" alt="Next"/>
        </span>
        <span class="separator-right">
          <img [ngClass]="{'pagination-img-disabled':disablePaginationNextLast}" id="btLast" (click)="onBtLast()"
            class="pagination-img" src="assets/Inbox/pagination-arrow-dark1.svg" alt="Last"/>
        </span>
      </div>
    </div>
    </div>
    <p class="text-danger" *ngIf="maxRecords">Results limited to first 5000 search items!</p>
  </div>
  <p-footer>
    <div class="d-flex flex-row flex-wrap bottom-button-holder px-3 pb-2 pt-3 justify-content-end gfpid-footer">
      <button type="submit" class="btn search-btn mr-4 mobile-btn" [class.disableBtn]="!isSelected" (click)="addGFID(gfcForm)">Add</button>
      <button type="reset" class="btn clear-btn mobile-btn" (click)="hideModal(gfcForm)">Cancel</button>
    </div>
  </p-footer>
</p-dialog>
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ContextMenuService } from 'src/app/services/contextMenu/context-menu.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'reject-inquiry',
  templateUrl: './reject-inquiry.component.html',
  styleUrls: ['./reject-inquiry.component.scss']
})
export class RejectInquiryComponent implements OnInit,OnDestroy {
  display:boolean = false;  
  linkExceptionModel:any;
  selectedMenuItem:any;
  contextServiceObject:any;
  isMessage:boolean = false;
  subManager = new Subscription();
  headerText = "Error";
  labelText: any;

  // Rejection reason
  requestObj: any;
  type: string;
  rejectionReason: string;
  isReasonFlag: boolean = false;

  constructor(private contextMenuService: ContextMenuService){}

  ngOnInit() {
  }
  
  triggerFalseClick(str: any) {
    this.headerText = "Error";
    this.labelText = str;
    this.isMessage = false;
    this.display = true;
  }

  hideModal(){
    this.display = false;
  }

  triggerReasonPopup(request: any, type: string): void {
    this.requestObj = request;
    this.type = type;
    this.headerText = "Ownership Nomination Reject";
    this.display = true;
    this.isMessage = true;
  }

  onRejectionSubmit(): void {
    if (this.rejectionReason && this.rejectionReason.length > 0) {
      this.contextMenuService.onRejectInquiry(this.requestObj, this.type, this.rejectionReason).subscribe(result => {
        if (result.processingstatus) {
          this.requestObj = null;
          this.type = '';
          this.rejectionReason = '';
          console.log('Nomination Ownership Rejection is Successful');
        } else {
          console.log('Fail to reject nomination ownership');
        }
      }, error => {
        throw new Error('Service failed to update inquiry for nomination ownership rejection');
      });
      this.display = false;
    }
    else {
      this.isReasonFlag = true;
      setTimeout(() => { this.isReasonFlag = false; }, 3000);
    }
  }

  ngOnDestroy(){
    this.subManager.unsubscribe();
  }
}

<p-dialog [(visible)]="displaySuggestion" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'"
    [styleClass]="'contextMenuModal'" [closable]="false" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true"
    [style]="{width: '75vw'}">
    <p-header>
        Check Names
        <div *ngIf="suggestionRecordCount>40" style="font-size: small;color: red;">Your search matches {{suggestionRecordCount}} records. Currently displaying Top 20 records for User search and Top 20 for DL search. Please narrow your search criteria or use corporate address book search.</div>
      </p-header> 
    <div class="d-flex flex-row flex-nowrap align-items-end w-100">
        <div class="w-100">
            <div class="grid-container header-grid">
              <div>Soeid</div>
              <div>Name</div>
              <div>Email Id</div>
            </div>
            <div class="suggestion-content-container">
                <div  *ngFor="let i of suggestionRecords; let index=index;" (click)="selectedSuggestion = i;selectedSuggestionIndex=index;" >
                  <div *ngIf="i['EmailAddress']['_text']" class="grid-container grid-row" [ngClass]="{'highlight': selectedSuggestionIndex === index}">
                    <div>{{i['SoeId']['_text']}}</div>
                  <div>{{i['Name']['_text']}}</div>
                  <div>{{i['EmailAddress']['_text']}}</div>
                  </div>
                  
                </div>
              </div>
    </div>  
    </div>
  <p-footer>
      <div class="p-2 d-flex flex-row w-100 justify-content-center footer-btn-container suggestion-footer">
        <button class="mr-4" (click)="addSuggestionToRecipient()">Ok</button>
        <button (click)="cancelSuggestion()">Cancel</button>   
      </div>   
    </p-footer>
</p-dialog>
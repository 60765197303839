import { Injectable } from '@angular/core';
import { UiConsoleLogConfig } from 'src/app/model/user-profile/uiConsoleLogConfig';
import { UserDataService } from '../user-data.service';

@Injectable({
  providedIn: 'root'
})
export class LoggingService {

  constructor(private userDataService: UserDataService) { }

  /* logError(message: string, stack: string) {
    console.log('LoggingService :' + message);
  } */

  enableDebugLog(uiConsoleLogConfig: UiConsoleLogConfig) {
    if (uiConsoleLogConfig) {
      let old_console_debug_log = console.debug;
      /*let old_console_log_log = console.log;
      let old_console_info_log = console.info;
      let old_console_warn_log = console.warn;
      let old_console_error_log = console.error;*/
      console.debug = function () {
        if (!uiConsoleLogConfig.disableDebug) {
          old_console_debug_log.apply(this, arguments);
        }
      }
      /*console.log = function () {
        if (!uiConsoleLogConfig.disableLog) {
          old_console_log_log.apply(this, arguments);
        }
      }
      console.warn = function () {
        if (!uiConsoleLogConfig.disableWarn) {
          old_console_warn_log.apply(this, arguments);
        }
      }
      console.info = function () {
        if (!uiConsoleLogConfig.disableInfo) {
          old_console_info_log.apply(this, arguments);
        }
      }
      console.error = function () {
        if (!uiConsoleLogConfig.disableError) {
          old_console_error_log.apply(this, arguments);
        }
      }*/
    }

  }
}

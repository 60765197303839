import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy, SimpleChanges, ChangeDetectorRef, AfterViewInit} from '@angular/core';
import { ClcService } from '../../services/clc/clc.service';
import { NewMessageService } from '../../services/newMessage/new-message.service';
import { TabDataService } from '../../services/tab-data.service';
import { AppUtils } from 'src/app/common/utility/appUtil';
import { UserDataService } from 'src/app/services/user-data.service';
import { PiwikProUtils } from 'src/app/common/utility/piwikProUtils';
import { QmaConstant } from 'src/app/constant/qma-constant';

@Component({
  selector: 'contact-sidebar',
  templateUrl: './contact-sidebar.component.html',
  styleUrls: ['./contact-sidebar.component.scss']
})
export class ContactSidebarComponent implements AfterViewInit {
  taskizeEnabled: boolean;
  hideContactsForTaskize: boolean;
  ngAfterViewInit(): void {
    this.ref.detectChanges();
  }
  inquiryId: number;
  addNote: any = [];

  slideVisible = false;
  activeIndex = 0;

  // Input properties for Attachment, Notes and Audit
  @Input() attachment?: any = [];
  @Input() note?: any = [];
  @Input() audit?: any = [];
  @Input() clc?: any;
  @Input() messageInstance?: any;
  @Input() isTaskizeInquiry?: any;
  @Input() checkTaskizeInquiry?: any;

  @Input() selectedInquiryId?: any;
  @Input() screen?: string = "newmsg";
  @Output() noteAdded: any = new EventEmitter();
  @Output() tradeAdded: any = new EventEmitter();
  
  // C153176-4229 | Emitter which add the contact from contact list to new-message recipent
  @Output() contactEvent: any = new EventEmitter();

  attachmentList: any[] = [];// Attachments
  notes: any[] = []; // Notes
  audits: any[] = [];  // Audits

  @Input() inboxViewMode?: string = 'vertical';
  @Input() rightSideBar?: string = 'default';
  displayClc: boolean = false;
  currentTab: string;

  // C153176-4903: Selected Inquiry assigned group Name
  @Input() assignedGroupName?: any;
  noteCount: number = 0;  // C153176-4634: Note count should increase once new one added.
  // C153176-4998 - Top Contacts not appearing for New Message and reply/reply all
  groupName: any = ""; 
  @Input() assignFromGrp?:any = "";

  // C153176-5746 : New contact added to Top Contact using right click.
  @Input() topContactAdd: any;
  topContactAdded: any;

  constructor(
    private userDataService: UserDataService,
    private tabDataService: TabDataService,
    private ref: ChangeDetectorRef
    ) {
    let currTabName = AppUtils.getCurrentTab();
    if (currTabName) {
        this.currentTab = currTabName.toUpperCase();
    }
    this.detach();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.attachmentList = [];
    this.notes = [];
    this.audits = [];
    // the notes are getting disappeared -C153176-4653
    if (this.selectedInquiryId != this.inquiryId) {
      this.addNote = [];
    }
    this.inquiryId = null;
    let noteData = this.note.length == 0 ? this.addNote : this.note   
    Array.prototype.push.apply(this.attachmentList, this.attachment);
    Array.prototype.push.apply(this.notes, noteData);
    Array.prototype.push.apply(this.audits, this.audit);

      if(this.selectedInquiryId){
      this.inquiryId = this.selectedInquiryId;
    }
    if (this.userDataService.loggedInUserInfo.isTaskizeEnabledForUser && this.userDataService.loggedInUserInfo.isTaskizeEnabledForUser?.toUpperCase() == 'Y') {
      this.taskizeEnabled = true
    } else {
      this.taskizeEnabled = false
    }

    if (this.taskizeEnabled && ((this.messageInstance && this.messageInstance.startsWith(QmaConstant.QMA_NEW_BUBBLE)) || (this.messageInstance && this.messageInstance.startsWith(QmaConstant.REPLY_ALL) && (this.isTaskizeInquiry || this.checkTaskizeInquiry) || this.isTaskizeInquiry))) {
      this.hideContactsForTaskize = true
    } else {
      this.hideContactsForTaskize = false
    }
    // C153176-4634: Get the note count.
    this.noteCount = this.notes.length;
    // C153176-4998 - Top Contacts not appearing for New Message and reply/reply all
    if (changes.assignFromGrp) {
      this.groupName = changes.assignFromGrp.currentValue;
    }

    // C153176-5746 : New contact added to Top Contact using right click.
    if (changes.topContactAdd && JSON.stringify(changes.topContactAdd.currentValue) !== JSON.stringify(changes.topContactAdd.previousValue)) {
      this.topContactAdded = changes.topContactAdd.currentValue;
    }
    this.reattach();
  }

  slideOpen(tabToOpen) {
    switch (tabToOpen) {
      case 'docs':
        this.activeIndex = 0;
        this.userDataService.userUsageStats("New Message", "Sidebar Docs").subscribe(result => console.log("StartTime:"+new Date()));
        if(this.currentTab.startsWith("NEW MESSAGE")) {
          PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Menu", "New message - Sidebar Docs", "Click", "New message - Sidebar Docs", 0);
        }else{
          PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Mailboxes", "Sidebar Docs", "Click", "Sidebar Docs", 0);
        }
        break;
      case 'audit':
        this.activeIndex = 1;
        this.userDataService.userUsageStats("New Message", "Sidebar User Audits").subscribe(result => console.log("StartTime:"+new Date()));
        if(this.currentTab.startsWith("NEW MESSAGE")) {
          PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Menu", "New message - Sidebar User Audits", "Click", "New message - Sidebar User Audits", 0);
        }else{
          PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Mailboxes", "Sidebar User Audits", "Click", "Sidebar User Audits", 0);
        }
        break;
      case 'notes':
        this.activeIndex = 2;
        this.userDataService.userUsageStats("New Message", "Sidebar Notes").subscribe(result => console.log("StartTime:"+new Date()));
        if(this.currentTab.startsWith("NEW MESSAGE")) {
          PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Menu", "New message - Sidebar Notes", "Click", "New message - Sidebar Notes", 0);
        }else{
          PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Mailboxes", "Sidebar Notes", "Click", "Sidebar Notes", 0);
        }
        break;
      case 'contacts':
        this.activeIndex = 3;
        this.userDataService.userUsageStats("New Message", "Sidebar Contacts").subscribe(result => console.log("StartTime:"+new Date()));
        if(this.currentTab.startsWith("NEW MESSAGE")) {
          PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Menu", "New message - Sidebar Contacts", "Click", "Sidebar Contacts", 0);
        }else{
          PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Mailboxes", "Sidebar Contacts", "Click", "Sidebar Contacts", 0);
        }
        break;
    }
    this.slideVisible = true;
    this.reattach();
  }

  reattach() {
    this.ref.reattach();
  }
  detach() {
    this.ref.detach();
  }
  detechChanges() {
    this.ref.detectChanges();
  }

  /**
   * Method to open the CLC window.
   */
  openClc(): void { 
    this.displayClc = this.currentTab !== 'PENDING APPROVAL'; // C153176-4594 : #8 - CLC should be disabled for 'Pending Approval' folder.
    this.reattach();
  }

  /**
   * Method to close CLC window.
   * @param event 
   */
  closeClc(event: any): void {   
    this.displayClc = event;
  }

  /**
   * Method to be called when new note is added.
   */
  onNotesAdded(addedNote: any): void {
    
    console.log('Newly added note : ', JSON.stringify(addedNote));
    // the notes are getting disappeared -C153176-4653
    if (addedNote.note != "") {
      this.addNote.push(addedNote);
      this.note.push(addedNote);
      // C153176-4634: increase the note count on newly added.
      this.noteCount = this.noteCount + 1;
      this.noteAdded.emit(addedNote);
    }
    PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Mailboxes ", "Sidebar - Note added", "Click", "Sidebar - Note added", 0);
}

  /**
   * Method to handle the emitted selected trades from the child
   * @param trades 
   */
  onSelectedTrades(trades: any) : void {
    this.tradeAdded.emit(trades);
    this.displayClc = false;
    PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Mailboxes ", "Sidebar - Add selected trade to reply", "Click", "Sidebar - Add selected trade to reply", 0);
  }

  /**
   * C153176-4565: Return CSS classes per 'rightSideBar' attribute or tab row count.
   * Allowed values of 'rightSideBar': 'auto' or 'default'
   */
  getSidebarStyle() {
    if(this.rightSideBar === 'auto') {
      return { height: 'auto'};
    } else {
      // C153176-4565: compute height based on tab header row height
      let tabRows = this.tabDataService.checkTabHeaderRows();
      if (tabRows > 1) {
          const delta = 130 + (tabRows -1) * 31;
          return { height: 'calc(100vh - ' + delta + 'px'};  // C153176-5191: no change on contact bar css
      }
    }
    return {};
  }

  /**
   * C153176-4229: Method to handle the contact push from contact list to new-message
   */
  onPushContact(pushContact: any): void {
    console.log("Pushed Contact : " + JSON.stringify(pushContact));
    // C153176-4229 | Let new-message know new contact should added to recpient field
    this.contactEvent.emit(pushContact);
    PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Mailboxes", "Sidebar - Push contact to recepient", "Click", "Sidebar - Push contact to recepient", 0);
  }

  /**
   * C153176-4648: Method to update the selected tab on change
   * @param e 
   */
  onTabChange(e) {
    this.activeIndex = e.index;
  }
  // performance change
  hideSlideBar(e) {
    this.slideVisible = false;
    // first close tab panel then detach
    setTimeout(() => {
      this.detach();
    }, 0);
    
  }
}

import { Injectable } from '@angular/core';
import * as Rx from "rxjs";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})

export class GridUpdateService {


  deletedDraftIds = new Rx.BehaviorSubject([0]);
  unreadInqIds = new Rx.BehaviorSubject([{ inqId: 0, agrpId: 0 }]);
  readInqIds = new Rx.BehaviorSubject([{ inqId: 0, agrpId: 0 }]);
  unreadConvInqIds = new Rx.BehaviorSubject([{ inqId: 0, agrpId: 0 }]);
  readConvInqIds = new Rx.BehaviorSubject([{ inqId: 0, agrpId: 0 }]);
  readUnreadInqIds = new Rx.BehaviorSubject([{ inqId: 0, status: 0 }]);

  getdeletedDraftIds(): Observable<any> {
    return this.deletedDraftIds.asObservable();
  }

  setdeletedDraftIds(deletedDraftIds) {
    this.deletedDraftIds.next(deletedDraftIds);
  }

  getunreadInqIds(): Observable<any> {
    return this.unreadInqIds.asObservable();
  }

  setunreadInqIds(unreadInqIds) {
    this.unreadInqIds.next(unreadInqIds);
  }

  getreadInqIds(): Observable<any> {
    return this.readInqIds.asObservable();
  }

  setreadInqIds(readInqIds) {
    this.readInqIds.next(readInqIds);
  }

  getReadUnreadConvInqIds(): Observable<any> {
    return this.unreadConvInqIds.asObservable();
  }

  setReadUnreadConvInqIds(unreadInqIds) {
    this.unreadConvInqIds.next(unreadInqIds);
  }

  getReadUnreadInqIds(): Observable<any> {
    return this.readUnreadInqIds.asObservable();
  }

  setReadUnreadInqIds(readUnreadInqIds) {
    this.readUnreadInqIds.next(readUnreadInqIds);
  }

}

import { Directive, Output, Input, EventEmitter, HostBinding, HostListener } from '@angular/core';
import { InboxService } from '../../services/inbox.service';
@Directive({
  selector: '[tabDragDrop]'
})
export class TabDragDropDirective {
  @Output() onRowDragEventOver = new EventEmitter<any>();
  @Output() onRowDragEventDrop = new EventEmitter<any>();
  //@HostBinding('style.background-color') private background = '#021e48'
  //@HostBinding('style.opacity') private opacity = '1'
  private background = '#021e48';
  private opacity = '1';

  constructor(private inboxService : InboxService) {

  }
  //Dragover listener
  @HostListener('dragover', ['$event']) onDragOver(evt) {
    this.background = '#9ecbec';
    this.opacity = '0.8';
    const innerText = evt.srcElement.innerText;
    if (innerText) {
        const filtered = [ 'NEW MESSAGE', 'REPLY', 'FORWARD'].filter((e)=> {
          return innerText.indexOf(e) > -1;
        });
        if (filtered && filtered.length) {
          this.onRowDragEventOver.emit({innerText: innerText});
          // prevent default here only to allow drop
          evt.preventDefault();
        }
    }
  }

  //Dragleave listener
  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#f5fcff'
    this.opacity = '1'
  }

  //Drop listener
  @HostListener('drop', ['$event']) public ondrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#f5fcff';
    this.opacity = '1';
    const data = evt.dataTransfer.getData('text');
    console.log('tab-drag-drop.directive:', data);
    const innerText = evt.srcElement.innerText;
    if (innerText && data) {
        const filtered = [ 'NEW MESSAGE', 'REPLY', 'FORWARD'].filter((e)=> {
          return innerText.indexOf(e) > -1;
        });
        if (filtered && filtered.length) {
          this.onRowDragEventDrop.emit({data: data, innerText: innerText});
        }
    }
  }

}

import { Component, OnInit, ViewChild, ElementRef, Input, OnChanges, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef, HostListener } from '@angular/core';
import { DatePipe} from '@angular/common';
import { TabViewModule, TabView } from 'primeng/tabview';
import { InboxItem } from 'src/app/inbox/mobile/mobile-inbox-item/InboxItem';
import { ContextMenuComponent } from '../context-menu/context-menu.component';
import { InboxViewComponent } from '../../inboxView/inboxView.component';
import { MailBoxGridColdefs } from '../../inboxView/inboxGridColumnDefs';
import { InboxService} from '../.../../../../services/inbox.service';
import { AppUtils } from 'src/app/common/utility/appUtil';
import { QmaConstant } from 'src/app/constant/qma-constant';
import { GridMenuItems } from "src/app/common/utility/gridMenuItems";
import { ContextMenu } from 'src/app/model/Common/ContextMenu';
import { ContextMenuService } from "src/app/services/contextMenu/context-menu.service";
import { TabDataService } from 'src/app/services/tab-data.service';
import { MobileSearchHandler } from './search-handler';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { LeftNavigationService } from 'src/app/core/leftnav/leftnav.service';
import * as $ from 'jquery';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subscription } from 'rxjs';
import { DraftGridColumnDefs } from '../../inboxView/draftsColumnDefs';


@Component({
  selector: 'app-mobile-inbox-view',
  templateUrl: './mobile-inbox-view.component.html',
  styleUrls: ['./mobile-inbox-view.component.scss'],
  changeDetection:ChangeDetectionStrategy.OnPush
})
export class MobileInboxViewComponent implements OnInit, OnChanges {

  @ViewChild('contextmenu') contextMenuComponent: ContextMenuComponent;
  @ViewChild('mobileContentView') mobileContentView: ElementRef;
  @Input() rowData:Array<any>;
  @Input() columnConfig: any;
  @Output() onRowClick = new EventEmitter();
  @Output() onMobileViewReady = new EventEmitter<any>();
  selectedItems: Array<any> = [];
  selectedItemCount: number = 0;
  isSelected:boolean = false;
  nodes: Array<any> = [];
  nodeCount: number = 0;
  origRowData: Array<any>;
  DEFAULT_SORT_ATTR: string = "Date";
  DEFAULT_SORT_DIRECTION: boolean = false;
  DEFAULT_SWIPER_INDEX: number = 1; // C153176-4796 right swipe
  sortAttr:string = "Date";
  sortDirection:boolean = false;
  sortOrigRowData: Array<any>;

  private assignMenu: any;
  private swiperInquiry: any;
  
  annexCellRenderer: any;
  customClientCategoryRenderer:any;
  itemSeletionEnabled = false;

  regex = /<img[^>]+src="([^">]+)/;
  regex2 = /[ \w-]+?(?=\.)/;
  search: MobileSearchHandler;
  inSearch: boolean;
  currentTab: string;
  // C153176-4700: virtual scrolling
  scrollTopDone: boolean = false;
  scrollBottomDone: boolean = false;
  // C153176-4796: flag controlling whether all the slides are enabled (allowed to be shown)
  enableAllSlides: boolean = false;
  // C153176-5076: ngx-ui-loader configuration
  ngxConfig: any = {
    loaderId: undefined,
    fgsType: 'fading-circle',
    fgsColor: '#004d92',
    fgsSize: 80,
    overlayColor: "rgba(200, 200, 200, 0.25)",
    text: "Refreshing",
    textColor: "#002d72",
    defaultTaskId: "fg-default" // !important taskId name
  };
  timeoutId: any;
  dashboardCountSubs$: Subscription;
  
  constructor(public inboxViewComponent: InboxViewComponent, public inboxService: InboxService, private cdr: ChangeDetectorRef,
    private contextMenu: ContextMenu, private contextMenuService: ContextMenuService, private tabDataService:TabDataService,
    private dashboardService: DashboardService, private leftNavigationService: LeftNavigationService, private ngxLoader: NgxUiLoaderService,) {
    this.search = new MobileSearchHandler();
    this.search.viewComponent = this;

    // C153176-6095 | Mobile- Application get hanged when user opens drafts folder
    // Load draft specific columns to load draft. Draft doesn't have annex columns.
    let columnDefsObj = null;
    let cDefs = null;

    let tabName = AppUtils.getCurrentTab();
    console.log("Current Tab Name : " + tabName);
    if (tabName.toUpperCase() == "DRAFTS") {
      columnDefsObj = new DraftGridColumnDefs();
      cDefs = columnDefsObj.draftsGridDefs();
    } else {
      columnDefsObj = new MailBoxGridColdefs();
      cDefs = columnDefsObj.getMailboxColDefs();
    }

    // C153176-5076: set loaderId for ngx-ui
    this.ngxConfig.loaderId = 'loader_' + this.inboxViewComponent.strMailboxView;

    const index = cDefs.findIndex(elem => elem.headerName === 'ANNEX');
    if (index !== -1) {
      this.annexCellRenderer = cDefs[index].cellRenderer;
    } else {
      console.log('Failed to identify annex renderer!');
    }
    this.customClientCategoryRenderer = columnDefsObj.getClientCategory;
    this.updateMailboxCount();
    // C153176-5267: if the view is search, set the inSearch flag to true
    if (this.inboxViewComponent.strMailboxView === 'SEARCH') {
      this.inSearch = true;
    }
  }

  ngOnInit() {
    this.currentTab = AppUtils.getCurrentTab().toUpperCase();
    this.inboxService.mobileSelectionEnabledEvent.subscribe(flag => {
      this.itemSeletionEnabled = flag;
    });

    this.inboxService.selectAllEvent.subscribe(flag =>{
      this.isSelected = flag;
    });

    this.inboxService.mobileSearchEnabledEvent.subscribe(flag => {
      // entering / leaving search mode
      this.inSearch = flag;
      if (flag) {
        this.search.initSearchPanel();
      } else {
        this.search.cleanupSearchPanel();
        // C153176-5043: reset search flag
        this.inSearch = false;
      }
      this.cdr.detectChanges();
    });
    this.inboxService.onKeySearchTextEvent.subscribe(event => {
      let text = event.text;
      let forced = event.forced;
      if (this.inSearch && text !== undefined) {
        this.search.searchText(text, forced);
        // C153176-5267: if text clears up, reset All Mailboxes data and switch to Current Mailbox
        if (text === '') {
          this.resetAllMailboxData();
          if (this.search.searchTabIndex === 1) {
            this.onSearchTabChange({index: 0});
          }
        }
      }
    });
    this.inboxService.mobileSearchTriggerEvent.subscribe(event => {
      if (event && event.search === 'adv-search') {
        this.triggerAdvSearch();
      } else {
        // global search triggered from external sources (topnav)
        this.triggerGlobalSearch();
      }
    });
    this.inboxService.mobileSortTriggerEvent.subscribe(event => {
      /// sorting
      this.sortRowData(event.attr, event.direction);
    });
    this.inboxService.mobileGridDataReadyEvent.subscribe(event => {
      if (event && event.tabName === this.inboxViewComponent.strMailboxView) {
        // C153176-5076: cancel and hide the refresh spinner if any
        if (this.timeoutId) {
          this.ngxLoader.stopLoader(this.ngxConfig.loaderId, this.ngxConfig.defaultTaskId);
          let elem = $('.mobile-inbox-refresh-panel');
          if (elem && elem.length) {
            elem.addClass('mobile-collapsible');
          }
          clearTimeout(this.timeoutId);
          this.timeoutId = null;
        }
        if (this.inSearch) {
          // C153176-5019: if updateOnly is true, skip calling search.updateData()
          if (event.updateOnly || this.search.updateData()) {
            if (this.origRowData) {
              this.rowData.splice(0, this.rowData.length);
              this.rowData.push.apply(this.rowData, this.origRowData);
            }
            // C153176-5043: update all items (rows)
            this.updateAllItems();
          }
        } else {
          this.updateRowData();
        }
        // C153176-4925: clear swiping item when grid opened anew
        this.clearSwipingItem();
      }
    });
    // C153176-4925: mobile websocket update event
    this.inboxService.mobileGridDataUpdateEvent.subscribe(event => {
      if (event && event.tabName === this.inboxViewComponent.strMailboxView) {
        this.updateRowData();
      }
    });
    this.onMobileViewReady.emit({viewComponent: this});
    $(document).ready(() => {
      let elem = $('.mobile-mailbox-grid');
      if (elem && elem.length) {
        $('.mobile-mailbox-grid').scroll((evt) => {
          this.onScroll(evt);
        });
      }
    });
    // C153176-4930: when a new view is opened, reset bulk selection state
    this.inboxService.mobileCheckboxSelected = false;
    this.inboxService.notifyMobileCheckboxSelection();
    this.inboxService.onSelectAll(false);

    this.inboxService.onMobileSearchChangedEvent.subscribe(ids => {
      this.updateRowData();
    });
  }

  ngOnChanges(){
    console.debug('rowData length=', this.rowData ? this.rowData.length: -1);
  }

  updateMailboxCount(){
    this.dashboardCountSubs$ = this.dashboardService.getDashboardCountsServiceSubject().subscribe(dashSnapshotCnts => {
      if (dashSnapshotCnts) {
        if(dashSnapshotCnts.boxCounts){
          dashSnapshotCnts.boxCounts.forEach((val:any) =>{
            // C153176-4796: refactoed footMailboxArray to under tabDataService
            let t = this.tabDataService.footerMailboxArray.find(item => item.name.toUpperCase() == Object.keys(val)[0].toUpperCase());
            if(t){
              t.count = val[Object.keys(val)[0]].unReadCount.$numberLong;
              t.isActiveTab = (t.label.toLowerCase() == sessionStorage.getItem("currentTab").toLowerCase()); 
            } 
          })
        }
      }
    }) 
    
  }

  onContextMenuOpen(event: any) {
    if (this.contextMenuComponent) {
      this.contextMenuComponent.open(event);
    }
  }

  /**
   * Render anndex HTML
   */
  renderAnnex(item) {
    if (this.annexCellRenderer) {
      return this.annexCellRenderer.call(this, {data: item});
    } else {
      return '';
    }
  }

  /**
   * Render Client Category
   */
  renderClientCategory(item:any){
    if(this.customClientCategoryRenderer){
      return this.customClientCategoryRenderer.call(this, {data: item});
    }else{
      return '';
    }
  }

  /**
   * Convert date to app-level specified format
   */
  convertDate(date) {
    return this.inboxViewComponent.dateFormatPipe.transform(date, this.inboxViewComponent.guserPrefDateFormat);
  }

  /**
   * get all the context menu items of the specified inquiries items
   */
  getContextMenuItems(selectedItems: any) {
    let selectedRows = selectedItems;
    let compObj = {
      gfidComp: this.inboxViewComponent.gfidSearchComponent,
      linkExceptionComp: this.inboxViewComponent.linkExceptionComponent,
      grpDetailsComp: this.inboxViewComponent.groupDetailsComponent,
      reAgeComp: this.inboxViewComponent.reAgeComponent,
      //updateMemoComp:this.updateMemoComponent,
      ownershipComp: this.inboxViewComponent.ownershipComponent,
      resolveInquiryComp: this.inboxViewComponent.resolveInquiryComponent,
      actionPopupComp: this.inboxViewComponent.actionPopupComponent,
      rejInq: this.inboxViewComponent.rejInqComponent,
      notifyComp: this.inboxViewComponent.notifyComponent,
      inquiryComp: this.inboxViewComponent.createRuleComponent,
      snoozeInq: this.inboxViewComponent.snoozeInqComponent
    };
    let selectedMail = undefined; // no inquiry details, no selectedMail
    if (!this.assignMenu) {
      this.getUserGroups(this.inboxViewComponent.loginUserInfo);
    }

    //Function accepting Duplicate parameters for UserInfo
    // C153176-5319 - Prepare context menu for Pending approval while solr search
    let gridContextMenu = this.contextMenu.getAllContextMenuItems(selectedRows, 
      this.inboxViewComponent.loginUserInfo, 
      compObj,
      selectedRows,
      this.inboxViewComponent.getViewType(this.getViewType(this.currentTab, selectedRows)),  /* JIRA C153176-4624 Mobile (CR) - Snooze; 153176-4946: Taking into account myviews */
      this.inboxViewComponent.loginUserInfo, //C153176-4935 - FIx for null value
      selectedMail, // no selected mail data
      this.assignMenu);
    // C153176-4946: Taking into account myviews
    let contextM = this.prepareContextMenuForTab(gridContextMenu, this.inboxViewComponent.getViewType(this.getViewType(this.inboxViewComponent.strMailboxView, selectedRows)))
    return contextM.map(item => this.mapNodesToSlide(item))
  }

  /**
   * C153176-4796: build the right-swipe context menu items of the specified inquiries items
   */
  getRSwipeMenuItems(selectedItems: any) {
    let selectedRows = selectedItems;
    let compObj = {
      linkExceptionComp: this.inboxViewComponent.linkExceptionComponent,
      rejInq: this.inboxViewComponent.rejInqComponent,
    };
    let selectedMail = undefined; // no inquiry details, no selectedMail
    if (!this.assignMenu) {
      this.getUserGroups(this.inboxViewComponent.loginUserInfo);
    }

    // C153176-5319 - Prepare context menu for Pending approvals inquiry for solr search.
    let strMailboxView = this.getViewType(this.inboxViewComponent.strMailboxView, selectedItems);

    let gridContextMenu = this.contextMenu.getRSwipeContextMenuItems(selectedRows, 
      this.inboxViewComponent.loginUserInfo, 
      compObj,
      selectedRows,
      strMailboxView, 
      this.inboxViewComponent.loginUserInfo, //C153176-4935 - FIx for null value
      selectedMail, // no selected mail data
      this.assignMenu);
    // C153176-4952: differentiate left vs. right swipe, with optional arg
    let contextM = this.prepareContextMenuForTab(gridContextMenu, strMailboxView, true);
    return contextM.map(item => this.mapNodesToSlide(item))
  }


  /**
   * Prepare Context Menu items for Mobile
   * @param contextArray Grid Context Menu
   * @param mailbox  Current Tab
   */
  prepareContextMenuForTab(contextArray:Array<any>, mailbox:string, rightSwipe = false){ // C153176-4952: differentiate left vs. right swipe
    let ref: any = QmaConstant.ContextMenuMobile;
    // C153176-4952: differentiate left vs. right swipe
    if (rightSwipe && QmaConstant.ContextMenuMobileRSwipe.hasOwnProperty(mailbox)) {
      ref = QmaConstant.ContextMenuMobileRSwipe;
    }
    // C153176-4952: if the 'mailbox' is not pre-defined for context menu, check whether it's one of the escalation types
    // and assign accordingly
    if (!ref.hasOwnProperty(mailbox) && AppUtils.isEscalationType(mailbox)) {
      mailbox = "POTENTIAL ESCALATIONS";
    }
    let getContextMenuArray:Array<any> = ref.hasOwnProperty(mailbox)? ref[mailbox] : ref['DEFAULT'];
    let filteredArray = []
    for(let index in getContextMenuArray){   
      let t = contextArray.find(val => (val.name == getContextMenuArray[index]))
      if(t){filteredArray.push(t)};      
    }
    return filteredArray;
  }

  /**
   * Map AGGRiD Context Menu to Primeng menuSlide Model
   * @param item AG-GRID Context Menu Object
   */
  mapNodesToSlide(item:any){
    let obj:any = {}
    if(!item){
      return "";
    }
    obj.label = item.name;
    if(item.icon){
      let src = this.regex.exec(item.icon)[1];    
      let fileName = this.regex2.exec(src);
      obj.icon = fileName[0];
    }
    
    if(item.action){ obj.command = item.action }
    if(item.subMenu){ obj.items = item.subMenu.map(i => this.mapNodesToSlide(i));}
    return obj;
 }

  getUserGroups(_loginUserInfo: any) {
    this.assignMenu = [];
    for (let i = 0; i < _loginUserInfo.context.length; i++) {
      let groupOwnerList = [], uniqueData = [], assignMenuAvailable = [];
      for (var item = 0; item < _loginUserInfo.context[i].assignOwnerList.length; item++) {
        if (_loginUserInfo.context[i].assignOwnerList[item].active) {
          groupOwnerList.push(_loginUserInfo.context[i].assignOwnerList[item]);
        }
      }

      for (let idx = 0; idx < _loginUserInfo.myGroups.length; idx++) {
        if (_loginUserInfo.myGroups[idx].id == _loginUserInfo.context[i].groupId) {
          this.assignMenu.push({
            groupOwner: groupOwnerList,
            groupName: _loginUserInfo.myGroups[idx].groupName,
            id: _loginUserInfo.myGroups[idx].id
          })
        }

      }

    }
  }

  
  /**
   * Emit selected row
   * @param  {any} row
   */
  onRowSelections(row:any){
    this.onRowClick.emit(row);
    // C153176-4925: clear swiping item if any if user tapped on a row
    this.clearSwipingItem();
  }

  /**
   * Open new messaga on Click
   * @param  {any} event
   */
  openNewMessage(){
    this.tabDataService.sendTabData('NEW MESSAGE', 0, 'leftNav');
  }

  /**
   * Add selected item
   * @param item
   */
  addSelectedItem(item: any) {
    if (!item || !item._id) {
      return;
    }
    //C153176-3479 - bulk select issue
    let index = this.selectedItems.findIndex(elem => elem && elem._id === item._id && elem.openGroups === item.openGroups); 
    if (index === -1) {
      this.selectedItems.push(item);
      this.selectedItemCount = this.selectedItems.length;
    }
  }

  /**
   * Remove selected item
   * @param item
   */
  removeSelectedItem(item: any) {
    if (!item || !item._id) {
      return;
    }
    //C153176-3479 - bulk select issue
    let index = this.selectedItems.findIndex(elem => elem && elem._id === item._id  && elem.openGroups === item.openGroups); // && 
    if (index !== -1) {
      this.selectedItems.splice(index, 1);
      this.selectedItemCount = this.selectedItems.length;
    }
  }

  /**
   * Register mailbox item
   * C153176-4925: cache change detector ref
   */
  registerItem(item, itemIndex, cdr:any) {
    if (!item || itemIndex === undefined || itemIndex >= this.rowData.length) {
      return;
    }
    if (!this.nodes.length && this.rowData.length > 0) {
      this.nodes = new Array(this.rowData.length);
    }
    if (this.nodes[itemIndex] === undefined) {
      this.nodeCount++;
      if (this.nodeCount === this.rowData.length && this.rowData.length > 0) {
        this.onMobileViewReady.emit({viewComponent: this});
      }
    }
    // set or update the item with 'rowIndex'
    item.rowIndex = itemIndex;
    // update node elem in all cases
    // C153176-4577: row node mockup: add 'selected' and 'setSelected'; C153176-4925: cache change detector ref
    this.nodes[itemIndex] = { data: item, rowIndex: itemIndex, cdr: cdr, selected: false, setSelected: (flag) => {
      this.nodes[itemIndex].selected = flag;
    }};
  }

  /**
   * Register swiper item
   */
  registerSwiperItem(inq) {
    if (!inq || !inq.item || inq.item.rowIndex === undefined || inq.item.rowIndex === null) {
      return;
    }
    if (this.swiperInquiry && inq.item.rowIndex !== this.swiperInquiry.item.rowIndex) {
      this.swiperInquiry.resetSwiperIndex(this.swiperInquiry.getDefaultIndex()); // C153176-4796 calling getDefaultIndex() to get the actual index
    }
    this.swiperInquiry = inq;
  }

  clearSwipingItem() {
    if (this.swiperInquiry) {
      this.swiperInquiry.resetSwiperIndex(this.swiperInquiry.getDefaultIndex()); // C153176-4796 calling getDefaultIndex() to get the actual index
    }
    this.swiperInquiry = undefined;
  }

  markup(value) {
    let result = value;
    // C153176-4796: refactoed footMailboxArray to under tabDataService
    if (this.inSearch && this.search.contextSearchTarget && result) {
      result.replace(new RegExp(this.search.contextSearchTarget, "gi"),
        (match) => `<mark>${match}</mark>`);
    }
    if (result) {
      result = result.split(/\r?\n/);
    }
    if (result && result.length) {
      return result[0];
    } else {
      return result;
    }
  }

  /**
   * Handler of tab selection change
   * @param event
   */
  onSearchTabChange(event: any) {
    this.search.onSearchTabChange(event);
    this.cdr.detectChanges();
  }

  /**
   * Triger global search from external (topnav)
   */
  triggerGlobalSearch() {
    if (this.search.searchTabIndex !== 1) {
      this.onSearchTabChange({index: 1});
    } else {
      // re-attempt of another global search
      this.search.globalSearch(false);
    }
  }

  /**
   * Actually sending global search request
   */
  doGlobalSearch(text, indexChanged) {
    if (!indexChanged) {
      this.origRowData = [...this.rowData];
    }
    const reqObj = { viewName: "Search", solrSearchText: text, from: 'search' };
    this.inboxService.requestObj = reqObj;
    // C153176-5043: reset request since it is a new search
    this.inboxService.resetRequest = true;
    // C153176-4540: clear searchBoxes at inbox service
    this.inboxService.searchBoxes = undefined;
    this.tabDataService.sendTabData("SEARCH", 0);
  }

  /**
   * C153176-4700: server-side pagination - virtual scrolling
   */
  onScroll(evt: any) {
    // C153176-5076: pick up the right scroll elem
    let elems = $('.mobile-mailbox-grid');
    let tab: string = evt.currentTarget.id;
    const isEventForCurrentTab = tab.includes(this.inboxViewComponent.strMailboxView);
    /* Continue operation only if current tab event 
    is being processed, ignore all other tab requests */
    if (isEventForCurrentTab) {        
      if (!elems || !elems.length) {
        this.scrollTopDone = false;
        this.scrollBottomDone = false;
          return;
      }
      let elem = undefined;
      for (let i = 0; i < elems.length; i++) {
        if (elems[i] && elems[i].id === this.inboxViewComponent.strMailboxView) {
          elem = elems[i];
          break;
        }
      }
      if (elem && this.inboxViewComponent && this.rowData && this.rowData.length) {
        const diff = elem.scrollHeight - (elem.scrollTop + elem.offsetHeight);
        let  isAtBottom = false;
        if ((diff == 0) || (diff > 0 && diff < 2)) {
          isAtBottom = true;
        }
        if (isAtBottom && this.scrollTopDone === false) {
          console.log('bottom detected');
          this.inboxViewComponent.requestPage(false, this.rowData.length -1);
          this.scrollTopDone = true;
          return;
        } else if (elem.scrollTop === 0 && this.scrollBottomDone === false) {
          let requestMade = this.inboxViewComponent.requestPage(true, 0);
          console.log('top detected, request made=', requestMade);
          if (!requestMade) {
            this.refreshView();
          }
          this.scrollBottomDone = true;
          return;
        }
      }
      this.scrollTopDone = false;
      this.scrollBottomDone = false;
    }
  }

  /**
   * XXXXX-5076: refresh mailbox view
   */
  refreshView() {
    let elem = $('.mobile-inbox-refresh-panel');
    if (elem && elem.length) {
      elem.removeClass('mobile-collapsible');
      console.log('removed mobile-collapsible');
      this.ngxLoader.startLoader(this.ngxConfig.loaderId, this.ngxConfig.defaultTaskId);
      this.timeoutId = setTimeout(() => {
        if (this.timeoutId) {
          this.ngxLoader.stopLoader(this.ngxConfig.loaderId, this.ngxConfig.defaultTaskId);
          elem.addClass('mobile-collapsible');
          this.timeoutId = null;
        }
      }, 60000);
      this.reloadGrid();
    }
  }

  reloadGrid() {
    if (this.inboxViewComponent && this.inboxViewComponent.dataCache) {
      let requestObj = this.inboxViewComponent.dataCache.requestObj;
      if (requestObj) {
        let clonedRequest = Object.assign({}, requestObj);
        clonedRequest.pageNum = undefined;
        clonedRequest.pageSize = undefined;
        this.inboxService.requestObj = clonedRequest;
        this.inboxService.resetRequest = true;
        // reset current request's viewName so that it becomes invalid, and trigger the inboxView comp to refetch request from service
        requestObj.viewName = null;
        this.inboxViewComponent.dataCache.resetParams(true);
        // request data anew
        let requestMade = this.inboxViewComponent.loadGridData(true);
      } else {
        console.debug("cannot reload mobile grid, invalid current request!");
      }
    }
  }

  /**
   * Sorting row data per  user request
   */
  sortRowData(attr, direction) {
    if (attr === this.sortAttr && direction === this.sortDirection) {
      return;
    } else {
      console.debug('sortRowData, attr =' + attr, direction);
      this.saveCurrentRowData();
      this.sortAttr = attr;
      this.sortDirection = direction;
      setTimeout(() => {
        this.handleSorting();
      }, 0);
    }
  }

  /**
   * Check whether the sorting mode is default
   */
  isDefaultSorting() {
    return this.sortAttr === this.DEFAULT_SORT_ATTR && this.sortDirection === this.DEFAULT_SORT_DIRECTION;
  }

  /**
   * Save current row data if it is default sorting
   */
  saveCurrentRowData() {
    if (!this.rowData || !this.isDefaultSorting()) {
      return;
    }
    this.sortOrigRowData = [...this.rowData];
  }

  /**
   * C153176-4696: actual sorting method
   */
  handleSorting() {
    if (!this.rowData || !this.rowData.length) {
      return;
    }
    let rowData;
    if (this.sortAttr === "Date" && !this.sortDirection) {
      rowData = this.rowData.sort((a, b) => {
        return (new Date(b.modDate.$date)).getTime() - (new Date(a.modDate.$date)).getTime();
      });
    } else if (this.sortAttr === "Date" && this.sortDirection) {
      rowData = this.rowData.sort((a, b) => {
        return (new Date(a.modDate.$date)).getTime() - (new Date(b.modDate.$date)).getTime();
      });
    } else if (this.sortAttr === "Subject" && this.sortDirection) {
      rowData = this.rowData.sort((a, b) => {
        return a.subject ? a.subject.localeCompare(b.subject) : ''.localeCompare(b.subject);
      });
    } else if (this.sortAttr === "Subject" && !this.sortDirection) {
      rowData = this.rowData.sort((a, b) => {
        return b.subject ? b.subject.localeCompare(a.subject) : ''.localeCompare(a.subject);
      });
    } else if (this.sortAttr === "From" && this.sortDirection) {
      rowData = this.rowData.sort((a, b) => {
        if (this.currentTab === "DRAFTS"){
          return a.recipients[0].displayName ? a.recipients[0].displayName.localeCompare(b.recipients[0].displayName)
            : ''.localeCompare(b.recipients[0].displayName);
        } else {
          return a.latestUserName ? a.latestUserName.localeCompare(b.latestUserName)
            : ''.localeCompare(b.latestUserName);
        }
      });
    } else if (this.sortAttr === "From" && !this.sortDirection) {
      rowData = this.rowData.sort((a, b) => {
        if (this.currentTab === "DRAFTS"){
          return b.recipients[0].displayName ? b.recipients[0].displayName.localeCompare(a.recipients[0].displayName)
            : ''.localeCompare(a.recipients[0].displayName);
        } else {
          return b.latestUserName ? b.latestUserName.localeCompare(a.latestUserName)
            : ''.localeCompare(a.latestUserName);
        }
      });
    }
    if (rowData && rowData.length) {
      console.log('updating data after sorting, row count=', this.rowData.length);
      this.rowData = rowData;
      this.cdr.detectChanges();
    }
  }

  /**
   * C153176-4696 - handling sorting along with virtual scrolling
   */
  updateRowData() {
    if (this.isDefaultSorting()) {
      // C153176-5011 check whether the item has been removed
      if (!this.cdr['destroyed']) {
        this.cdr.detectChanges();
      }
    } else {
      setTimeout(() => {
        this.handleSorting();
      }, 0);
    }
    console.debug('updateRowData done!', this.isDefaultSorting());
    // C153176-4796: enable all slides only after getting data (first time)
    if (!this.enableAllSlides) {
      setTimeout(() => {
        this.enableAllSlides = true;
      }, 200);
    }
  }

  /**
   * C153176-4676 - Mobile Search, display adv search in All Mailbox tab
   */
  triggerAdvSearch() {
    if (this.search.searchTabIndex !== 1) {
      // C153176-5267: populate current search data
      this.search.initCurrentSearchData(true);
      // switch to all mailboxes tab
      this.onSearchTabChange({index: 1});
      this.origRowData = [...this.rowData];
      this.cdr.detectChanges();
    }
  }

  /**
   * C153176-5043: update all items (rows)
   */
  updateAllItems() {
    if (this.nodes) {
      this.nodes.forEach(node => {
        if (node && node.cdr) {
          // C153176-5011 check whether the item has been removed
          if (!node.cdr['destroyed']) {
            node.cdr.detectChanges();
          }
        }
      });
    }
    // C153176-5011 check whether the item has been removed
    if (!this.cdr['destroyed']) {
      this.cdr.detectChanges();
    }
  }

 /**
   * C153176-5267: reset data in All Mailboxes
   */
  resetAllMailboxData() {
    this.search.resetAllMailboxData();
    if (this.search.searchTabIndex === 1) {
      // if current index is All Mailboxes, update the view
      if (!this.cdr['destroyed']) {
        this.cdr.detectChanges();
      }
    }
  }

  /**
   * C153176-5319 : Method to get the view type.
   * 
   * @param currentView 
   * @param selectedItems 
   */
  getViewType(currentView: string, selectedItems: any): string {
    // C153176-5319 - Prepare context menu for Pending approvals inquiry for solr search.
    let strMailboxView = currentView;
    let sRow = selectedItems ? selectedItems[0] : null;
    if (sRow && sRow.direction) {
      strMailboxView = ["PENDINGAPPROVAL", "PND_REAGE", "NOMINATE_OWNERSHIP"].includes(sRow.direction) ? 'PENDING APPROVAL' : strMailboxView;
    }
    return strMailboxView;
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    if(this.dashboardCountSubs$){
      this.dashboardCountSubs$.unsubscribe();
    }
  }
}
 <!-- Jira : C153176-4959 Mobile : Schedule for later -->
<p-dialog [(visible)]="displaySchedule" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'"
    [styleClass]="'contextMenuModal schedule-Modal scheduleLater mobile-scheduleLater'" [closable]="false" [dismissableMask]="true" [baseZIndex]="10000"
    [closeOnEscape]="true">
    <p-header>
        <div class="d-flex flex-row align-items-center justify-content-between">
            <h6 class="sch-title">Schedule For Later</h6>
            <button type="button" (click)="cancelSchedule($event)" class="d-flex flex-row close qma-text">
                <img src="assets/Inbox/close_icon_u73.svg">
                <h6>CLOSE</h6>
            </button>
        </div>
    </p-header>
     <!-- Jira : C153176-4959 Mobile : Schedule for later -->
    <div #schBody class="sch-body d-flex flex-row flex-wrap align-items-center justify-content-start w-100">
        <div class="d-flex flex-row align-items-center mt-3 pr-4">
            <label class="">Select Date & Time:</label>
            <div class="d-flex flex-row flex-nowrap justify-content-between">
                <div class="d-flex flex-row flex-nowrap align-items-end pr-2">
                    <!-- <label class="">Date From:</label> -->
                    <div class="pl-2">
                       <p-calendar dateFormat="dd/mm/yy" [hideOnDateTimeSelect]="true"
                            [styleClass]="'advance-calender schedule-cal'" [readonlyInput]="false" [showIcon]="true"
                            [(ngModel)]="scheduleTime" [showTime]="true" [baseZIndex]="10000">
                        </p-calendar>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <label style="color: red" *ngIf='validationMsg !== ""'><img src="assets/newMessage/error.svg"> {{validationMsg}}</label>
    <p-footer>
        <div class="d-flex flex-row flex-unwrap bottom-button-holder px-3 pb-2 pt-3 justify-content-end"><!-- C153176-4809:Footer button width fix -->
            <button type="submit" class="btn search-btn mr-4" (click)="onScheduleSubmit($event)">Schedule</button>
            <button type="reset" class="btn clear-btn" (click)="cancelSchedule($event)">Cancel</button>
        </div>
    </p-footer>
</p-dialog>
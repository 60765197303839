import { RequestTypeMapping } from "../LoginUserInfo/RequestTypeMapping";
import { HeirarchyData } from "../LoginUserInfo/HeirarchyData";
import { ExchangeFolder } from '../LoginUserInfo/ExchangeFolder';

export class GroupAdminDetails {
    groupName: string;
    requestTypeList: string[] = [];
    requestTypeMappings: RequestTypeMapping[] = [];
    analystAddList: string[] = [];
    adminAddList: string[] = [];
    peerRevAddList: string[] = [];
    analystRemoveList: string[] = [];
    adminRemoveList: string[] = [];
    peerRevRemoveList: string[] = [];
    domainReqd: boolean = false;
    attachmentIncluded: boolean = false;
    domainList: string[] = [];
    escKeywordList: string[] = [];
    disclaimer: string;
    predictiveRecipients: boolean = false;
    enableAutoAssignment: boolean = false;
    enableAutoReplySuggestion: boolean = false;
    addGroupTags: string[] = [];
    editGroupTags: any[] = [];
    deleteGroupTags: string[] = [];
    isEmailSharingBlocked: boolean = false
    autoResponseDetails: AutoResponse[] = [];
    userAssignmentExclusionList: string[] = [];
    rootCauseList: string[] = [];
    isRootCauseMandatory: boolean = false;
    isProcessingRegionMandatory: boolean = false
    processingRegionList: string[] = [];
    isInquirySourceMandatory: boolean = false
    isInqReferenceIdReq: boolean = false;
    autoReplyEnable: boolean = false;
    isRootCauseMandatoryWOReply: boolean = false
    isGfidMandatory: boolean = false
    isTagMandatory: boolean = false
    convCountThresholdForEscalation: number;
    responseTimeThresholdForEscalation: number;
    paResponseTimeThresholdForEscalation: number;
    excludeMyGroupWhileReplyAll: boolean = false;
    hideClientList: boolean = false;
    supervisiorApprovalForReage: boolean = false;
    requestTypeRootCauseFilter: boolean = false; // C170665-10 : Request Type and Root Cause flag
    isHolidayBasedAgeCalculation: boolean = false;
    groupCountryValue: string;
    groupTimeZoneValue: string;
    groupShiftStartTime: string;
    groupShiftEndTime: string;
    weekelyOff: string[] = [];
    supervisorAddList: string[] = [];
    supervisorRemoveList: string[] = [];
    version: number;
    heirarchyData: HeirarchyData;
    gfcid: string[] = [];
    subjectEscalationList: string[] = [];
    customClientCategory: any[] = [];
    requestTypeRootCauseMapping: {}; // C170665-10 : Request Type and Root Cause Mapping object
    exchFolders: ExchangeFolder[];
    enableOrganizationMetaData: boolean;  //C170665-185 flag for enable organization meta data 
    enableInquirySubStatus: boolean;
    isGroupManagerAboveL7: boolean;
    managerSoeId: string;
}
export interface AutoResponse {
    key: string;
    value: string;
}

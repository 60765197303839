import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import { QmaConstant } from "src/app/constant/qma-constant";
import { AssignInquirySource, RequestObject, SaveAssignUser, AssignProcessingRegion, AssignRequestType, AssignTag, AssignGfid, LockUnlockInquiry, ReadUnReadInquiry, DeleteDraft, AssignLinkException, ReAgeInquiry, FollowUpInquiry, UnFollowInquiry, UpdateMemo, UpdatePendingInquiry, RejectInquiry, Reopen, SnoozeInquiry, deEscalateInquiry, Ownership, TakeOwnership, ReadUnReadConv, MarkAsNonInquiry, MarkAsInquiry, DeleteEWSMail, AssignInquirySubStatus, AssignRootCause} from "./context.model";
import { Observable, forkJoin, from, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import  * as _ from 'underscore';
import { UserDataService } from '../user-data.service';
import { PiwikProUtils } from 'src/app/common/utility/piwikProUtils';

const httpFormPostOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': 'true'
  })
};
@Injectable({
  providedIn: 'root'
})
export class ContextMenuService {
  baseURL: string;
  groupURL: string;
  inquiryURL: string;

  assignInquirySourceURL: string = "/assignInquirySource";
  assignInquirySubStatusURL: string = "/assignInquirySubStatus";
  assignUserURL: string = "/assignToOwnerInquiry";
  assignProcessingRegionURL: string = "/assignProcessingRegion";
  assignRequestTypeURL: string = "/assignRequestType";
  assignRootCauseURL: string = "/assignRootCause";
  assignTagURL: string = "/moveToTag";
  assignGfidURL: string = "/assignGfpidGfcid";
  lockUnlockInquiryURL: string = "/lockUnlockInquiry";
  // readUnreadInquiryURL: string = "/markInquiryRead";
  readUnreadInquiryURL: string = "/markInquiryReadUnread ";
  deleteDraftURL: string = "/deleteDraft";
  exceptionId: string = "/linkException";
  groupDetails: string = "/getWorkflowsById";
  reAgeInquiry: string = "/reageInquiry";
  snoozeInquiry: string = "/snoozeInquiry";
  groupData: string = "/getGroupData";
  //assignSelfURL:string = "/assignToOwnerInquiry";
  followUpInquiry: string = "/followUpInquiry";
  updateMemo:string = "/updateMemo";
  takeOwnership:string = "/takeOwnership";
  nominateOwnership:string = "/nominateOwnership"
  getAllGroups:string = "/getAllGroups";
  resolveInquiry = "/resolveInquiry";
  updatePendingInquiry = "/updatePendingInquiry";
  reOpenInquiryURL:string = "/reOpenInquiry";
  deEscalateInquiry: string = "/deescalateInquiry"
  linkDelinkInquiry = "/linkDelinkInquiry";
  nonInquiryUrl: string = "/markAsNonInquiry";
  deleteEWSMailURL: string = "/deleteEWSEmail";
  constructor(private userDataService: UserDataService,private httpClient: HttpClient) {
    this.baseURL = QmaConstant.BASE_URL + "/inquiry";
    this.groupURL = QmaConstant.BASE_URL + "/group";
    this.inquiryURL = QmaConstant.BASE_URL + "/extInquiry";
  }

  httpSetAssignInquirySource(_data: any, _val: string): Observable<any> {
    PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Inquiry view", "Context menu - Assign Inquiry Source", "Click", "Context menu - Assign Inquiry Source", 0);
    let reqObj = this.getRequestData(_data, _data, _data);
    this.isMultiGrp(reqObj.inquiryIds,reqObj.groupIds) ? reqObj.isMultiGroupAction = true : reqObj.isMultiGroupAction = false;
    let body = JSON.stringify(new AssignInquirySource(reqObj.inquiryIds, reqObj.groupIds, _val, reqObj.isMultiGroupAction, reqObj.commonGroupId))
    return this.httpClient.post<AssignInquirySource>(this.baseURL + this.assignInquirySourceURL, body, httpFormPostOptions)
      .pipe(map(res => res))
  }

  httpSetAssignInquirySubStatus(_data: any, _val: string): Observable<any> {
    PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Inquiry view", "Context menu - Assign Inquiry Sub-Status", "Click", "Context menu - Assign Inquiry Sub-Status", 0);
    let reqObj = this.getRequestData(_data, _data, _data);
    this.isMultiGrp(reqObj.inquiryIds,reqObj.groupIds) ? reqObj.isMultiGroupAction = true : reqObj.isMultiGroupAction = false;
    let body = JSON.stringify(new AssignInquirySubStatus(reqObj.inquiryIds, reqObj.groupIds, _val, reqObj.isMultiGroupAction, reqObj.commonGroupId))
    return this.httpClient.post<AssignInquirySubStatus>(this.baseURL + this.assignInquirySubStatusURL, body, httpFormPostOptions)
      .pipe(map(res => res))
  }

  httpSetAssignOwner(_data: any, _val: string): Observable<any> {
    PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Inquiry view", "Context menu - Assign Owner", "Click", "Context menu - Assign Owner", 0);
    let reqObj = this.getRequestData(_data);
    let body = JSON.stringify(new SaveAssignUser(reqObj.inquiryIds, _val, reqObj.groupName));
    return this.httpClient.post<SaveAssignUser>(this.baseURL + this.assignUserURL, body, httpFormPostOptions)
      .pipe(map(res => res))
  }

  httpSetProcessingOwner(_data: any, _val: string): Observable<any> {
    PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Inquiry view", "Context menu - Assign Processing Region", "Click", "Context menu - Assign Processing Region", 0);
    let reqObj = this.getRequestData(_data);
    let body = JSON.stringify(new AssignProcessingRegion(reqObj.inquiryIds, reqObj.groupIds, _val, reqObj.isMultiGroupAction, reqObj.commonGroupId));
    return this.httpClient.post<AssignProcessingRegion>(this.baseURL + this.assignProcessingRegionURL, body, httpFormPostOptions)
      .pipe(map(res => res))
  }

  httpSetRequestType(_data: any, _val: string): Observable<any> {
    PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Inquiry view", "Context menu - Assign Request Type", "Click", "Context menu - Assign Request Type", 0);
    let reqObj = this.getRequestData(_data);
    let body = JSON.stringify(new AssignRequestType(reqObj.inquiryIds, _val, reqObj.groupName));
    return this.httpClient.post<AssignRequestType>(this.baseURL + this.assignRequestTypeURL, body, httpFormPostOptions)
      .pipe(map(res => res))
  }

  httpSetRootCause(_data: any, _val: string): Observable<any> {
    PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Inquiry view", "Context menu - Assign Root Cause", "Click", "Context menu - Assign Root Cause", 0);
    let reqObj = this.getRequestData(_data);
    let body = JSON.stringify(new AssignRootCause(reqObj.inquiryIds, _val, reqObj.groupName));
    return this.httpClient.post<AssignRootCause>(this.baseURL + this.assignRootCauseURL, body, httpFormPostOptions)
      .pipe(map(res => res))
  }

  httpReopenInquiry(_data: any, _val: Array<string>): Observable<any> {
    PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Inquiry view", "Context menu - Reopen Inquiry", "Click", "Context menu - Reopen Inquiry", 0);
    let reqObj = this.getRequestData(_data);
    let body = JSON.stringify(new Reopen(reqObj.inquiryIds, _val, reqObj.groupIds));
    return this.httpClient.post<Reopen>(this.baseURL + this.reOpenInquiryURL, body, httpFormPostOptions)
      .pipe(map(res => res))
  }

  httpSetTags(_data: any, _val: string): Observable<any> {
    PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Inquiry view", "Context menu - Assign Tags", "Click", "Context menu - Assign Tags", 0);
    let reqObj = this.getRequestData(_data);
    let body = JSON.stringify(new AssignTag(_val, reqObj.inquiryIds, reqObj.groupName));
    body = "request=" + encodeURIComponent(body); /* As service require encoded data */

    return this.httpClient.post<AssignTag>(this.baseURL + this.assignTagURL, body, httpFormPostOptions)
      .pipe(map(res => res))
  }

  httpSetGfid(_data: any, _val: any): Observable<any> {
    PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Inquiry view", "Context menu - Assign GFPID/GFCID", "Click", "Context menu - Assign GFPID/GFCID", 0);
    let reqObj = this.getRequestData(_data,_val,_data);
    this.isMultiGrp(reqObj.inquiryIds,reqObj.groupIds) ? reqObj.isMultiGroupAction = true : reqObj.isMultiGroupAction = false;
    let assignGFCDetails : AssignGfid = new AssignGfid(reqObj.inquiryIds, reqObj.groupIds, _val.gfpId, _val.gfpName, _val.gfcId, _val.gfcName, reqObj.isMultiGroupAction, reqObj.commonGroupId);

    // C170665-5 | Add Account and Branch
    if (_val.skAccountNo && _val.branch) {
      assignGFCDetails.skAccountNo = _val.skAccountNo;
      assignGFCDetails.branch = _val.branch;
    }
    let body = JSON.stringify(assignGFCDetails);
    return this.httpClient.post<AssignGfid>(this.baseURL + this.assignGfidURL, body, httpFormPostOptions)
      .pipe(map(res => res))
  }

  httpSetLinkExceptionID(_data: any, _val: any): Observable<any> {
    let reqObj = this.getRequestData(_data,_val,_data);
    let inquiryInfo: any;
    let body = JSON.stringify(new AssignLinkException(reqObj.inquiryIds, reqObj.groupIds, inquiryInfo, reqObj.isMultiGroupAction, reqObj.commonGroupId, _val));
    body = "request=" + encodeURIComponent(body); /* As service require encoded data */
    return this.httpClient.post<AssignLinkException>(this.baseURL + this.exceptionId, body, httpFormPostOptions)
      .pipe(map(res => res))
  }

  httpGetGroupDetails(_data:any):Observable<any>{
    PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Inquiry view", "Context menu - Group Details", "Click", "Context menu - Group Details", 0);
    let body ='{' + 'id' + ':' + _data[0]._id + '}';
    return this.httpClient.post(this.baseURL + this.groupDetails, body, httpFormPostOptions)
      .pipe(map(res => res))
  }

  httpLockUnlockInquiry(_data: any, _val: any): Observable<any> {
    PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Inquiry view", "Context menu - Lock/Unlock inquiry", "Click", "Context menu - Lock/Unlock inquiry", 0);
    // fix for JIRA C153176-4483
    let reqObj = this.getRequestData(_data, _data, _data);
    let body = JSON.stringify(new LockUnlockInquiry(reqObj.inquiryIds, reqObj.groupIds, _val.action, reqObj.isMultiGroupAction, reqObj.commonGroupId));
    console.log("Lock Unlock Body" + body);
    return this.httpClient.post<LockUnlockInquiry>(this.baseURL + this.lockUnlockInquiryURL, body, httpFormPostOptions)
      .pipe(map(res => res))
  }

  httpReAgeInquiry(_data: any, _val: any): Observable<any> {
    PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Inquiry view", "Context menu - Reage inquiry", "Click", "Context menu - Reage inquiry", 0);
    let reqObj = this.getRequestData(_data, _val, _data);
    let body = JSON.stringify(new ReAgeInquiry(reqObj.inquiryIds, reqObj.groupIds, _val, reqObj.isMultiGroupAction, reqObj.commonGroupId));
    return this.httpClient.post<ReAgeInquiry>(this.baseURL + this.reAgeInquiry, body, httpFormPostOptions)
      .pipe(map(res => res))
  }

  httpDeEscalateInquiry(_data: any, _val: any): Observable<any> {
    PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Inquiry view", "Context menu - Escalate inquiry", "Click", "Context menu - Escalate inquiry", 0);
    let reqObj = this.getRequestData(_data);
    let body = JSON.stringify(new deEscalateInquiry(reqObj.inquiryIds, reqObj.groupIds, _val, reqObj.isMultiGroupAction, reqObj.commonGroupId));
    console.log("Marked de escalate" + body);
    return this.httpClient.post<deEscalateInquiry>(this.baseURL + this.deEscalateInquiry, body, httpFormPostOptions)
  }
  httpSnoozeInquiry(_data: any, _action:any, _val: any, _selectedRows: any): Observable<any> {
    PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Inquiry view", "Context menu - Snooze inquiry", "Click", "Context menu - Snooze inquiry", 0);
    let reqObj = this.getRequestData(_data,_val,_selectedRows);
    // for snooz inq set isMultiGroupAction flag
    this.isMultiGrp(reqObj.inquiryIds,reqObj.groupIds) ? reqObj.isMultiGroupAction = true : reqObj.isMultiGroupAction = false;
    let body = new SnoozeInquiry(reqObj.inquiryIds,reqObj.groupIds, _action, reqObj.isMultiGroupAction, reqObj.commonGroupId, _val);
    // for unsnooz inq request remove snooz property from request
    _action === "Un-Snooze" ? delete body.snooze : "";

    return this.httpClient.post<SnoozeInquiry>(this.baseURL + this.snoozeInquiry, body, httpFormPostOptions)
      .pipe(map(res => res));
  }

  httpTakeOwnership(_data: any,_grp:any,_owner:any): Observable<any> {
    PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Inquiry view", "Context menu - Take ownership", "Click", "Context menu - Take ownership", 0);
    let reqObj = this.getRequestData(_data);
    let grpId = reqObj.inquiryIds.map((_val)=>{
      return _val+":"+_grp.toString();
    });
    let ownerSOID = _owner;
    let body = new TakeOwnership(reqObj.inquiryIds, grpId, "takeOwership", ownerSOID);
    return this.httpClient.post<TakeOwnership>(this.inquiryURL + this.takeOwnership, body, httpFormPostOptions)
      .pipe(map(res => res))
  }

  httpNominateOwnership(_data: any, _grp:any, _msg:string): Observable<any> {
    PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Inquiry view", "Context menu - Nominate ownership", "Click", "Context menu - Nominate ownership", 0);
    let reqObj = this.getRequestData(_data);
    let grpId = reqObj.inquiryIds.map((_val)=>{
      return _val+":"+_grp.toString();
    });
    // [C170665-3131] Nominate onwership not working when user doesnt have access to nominated group
    // let body = new Ownership(reqObj.inquiryIds, grpId, reqObj.groupIds, _msg);
    let body = new Ownership(reqObj.inquiryIds, reqObj.groupIds, grpId, _msg);
    return this.httpClient.post<Ownership>(this.inquiryURL + this.nominateOwnership, body, httpFormPostOptions)
      .pipe(map(res => res));
  }

  httpUpdatePendingInquiry(_data: any, _val: any): Observable<any> {
    let body = JSON.stringify(new UpdatePendingInquiry(_data.inquiryIds,
      _data.action, _data.assignedGroupName, _data.type));
    return this.httpClient.post<UpdatePendingInquiry>(this.baseURL + this.updatePendingInquiry, body, httpFormPostOptions)
      .pipe(map(res => res))
  }

  private getRequestData(_requestObj: any, _val?: string, _selectedRows?: any) {
    let requestObj = (_requestObj instanceof Array) ? _requestObj : [_requestObj];
    requestObj.forEach(
      req => {
        if (!req._id) {
          req._id = req.id;
        }
      }
    )
    // fix for JIRA C153176-4003 Start
    // to remove duplicates
    const inquiryIds: Array<number> = requestObj.map(obj => obj._id).filter((elem, index, self) => {
      return index === self.indexOf(elem);
    });
    // fix for JIRA C153176-4003 End
    let groupIds: Array<string> = new Array();
    let isMultiGroupAction: boolean = false;
    let commonGroupId: any = new Array();
    let groupName: string = "";
    let arrayinquiryIds: Array<number> = new Array();
    let draftIdList: Array<number> = new Array();
    let ewsIdList: Array<number> = new Array();

    if (requestObj[0].workflows) {
      groupIds = requestObj.map(obj => obj._id + ":" + obj.workflows[0].assignedGroupId);
      commonGroupId = requestObj.map(obj => obj.workflows[0].assignedGroupId);
      commonGroupId = _.uniq(commonGroupId);
    }
    if (_requestObj[0].openGroups) {
      groupName = _requestObj[0].openGroups;
    }

    if (_selectedRows) {
      groupIds = [];
      _selectedRows.forEach(selectedRow => {
        arrayinquiryIds.push(selectedRow._id);
        if (selectedRow.workflows) {
          let assignedGroupId = selectedRow.workflows[0].assignedGroupId;
          // fix for JIRA C153176-4003 Start
          if (groupIds.length > 0 && groupIds.findIndex(groupId => groupId.includes(selectedRow._id)) > -1) {
            let existingGrpIndex = groupIds.findIndex(groupId => groupId.includes(selectedRow._id));
            groupIds[existingGrpIndex] = groupIds[existingGrpIndex] + "," + assignedGroupId;
          }
          else {
            groupIds.push(selectedRow._id + ":" + assignedGroupId);
          }
          // fix for JIRA C153176-4003 End
        }
      });
      _selectedRows.forEach(selectedRow => {
        draftIdList.push(selectedRow._id);
      });

      // Populate EWSMail Id
      _selectedRows.forEach(selectedRow => {
        ewsIdList.push(selectedRow._id);
      });
    }
    // C170665-323 | isMultiGroupAction - It should be calculated based on group not on inquiry.
    isMultiGroupAction = commonGroupId.length > 1 ? true : false;
    return (new RequestObject(inquiryIds, groupIds, isMultiGroupAction, commonGroupId[0], groupName, inquiryIds /* fix for JIRA C153176-4003 */, draftIdList, ewsIdList))
  }

  httpMarkUnreadInquiry(_data: any, _val: any, _selectedRows: any): Observable<any> {
    PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Inquiry view", "Context menu - Mark unread", "Click", "Context menu - Mark unread", 0);
    let reqObj = this.getRequestData(_data, _val, _selectedRows);

    // C153176-5120: add 'groupIds' field
    let body = JSON.stringify(new ReadUnReadInquiry(reqObj.arrayinquiryIds, reqObj.groupIds, "N"));
    console.log("Marked Unread Body", body);  // C153176-5120
    return this.httpClient.post<ReadUnReadInquiry>(this.inquiryURL + this.readUnreadInquiryURL, body, httpFormPostOptions)
      .pipe(map(res => res))
  }  

  httpMarkReadInquiry(_data: any, _val: any, _selectedRows: any, _loginUserInfo: any): Observable<any> {
    PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Inquiry view", "Context menu - Mark read", "Click", "Context menu - Mark read", 0);
    // C170665-544 2 additional calls post getAllInquiryConversation
    if (this.checkIfInqAlreadyRead(_loginUserInfo.userId,_data[0])) {
     
      return EMPTY;
    }
    let reqObj = this.getRequestData(_data, _val, _selectedRows);
    // C153176-5120: add 'groupIds' field
    let body = JSON.stringify(new ReadUnReadInquiry(reqObj.arrayinquiryIds, reqObj.groupIds, "Y"));
    console.log("Marked Unread Body", body); // C153176-5120
    return this.httpClient.post<ReadUnReadInquiry>(this.inquiryURL + this.readUnreadInquiryURL, body, httpFormPostOptions)
      .pipe(map(res => res))
  }  

  httpMarkConvHistoryReadInquiry(_data: any, _val: any, _selectedRows: any, markAsRead): Observable<any> {
    PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Inquiry view", "Context menu - Conv History Read Inquiry", "Click", "Context menu - Conv History Read Inquiry", 0);
    let convId = [];
    let inqueryGroupData = [];
    _data.forEach(element => {
      convId.push(element.id);
      inqueryGroupData.push(element.workflows[0].assignedGroupId);
    });
    let markAsReadFlag = (markAsRead) ? 'Y' : 'N';
    
    let body = JSON.stringify(new ReadUnReadConv([_data[0].inquiryId], markAsReadFlag, convId,inqueryGroupData));
    return this.httpClient.post<ReadUnReadInquiry>(QmaConstant.BASE_URL + "/extInquiry/markConversationReadUnread", body, httpFormPostOptions)
    //return this.httpClient.post<ReadUnReadInquiry>(this.baseURL + this.readUnreadInquiryURL, body, httpFormPostOptions)
      .pipe(map(res => res))
  }

  httpDeleteInquiry(_data: any, _val: any, _selectedRows: any): Observable<any> {
    PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Inquiry view", "Context menu - Delete inquiry", "Click", "Context menu - Delete inquiry", 0);
    let reqObj = this.getRequestData(_data, _val, _selectedRows);

    let body = JSON.stringify(new DeleteDraft(reqObj.draftIdList));
    console.log("Delete Inquiry Body" + body);
    return this.httpClient.post<ReadUnReadInquiry>(this.baseURL + this.deleteDraftURL, body, httpFormPostOptions)
      .pipe(map(res => res))
  }

  // C153176-5632 | Provide user with option to mark relevant Inquiry as Non-Inquires.
  httpMarkAsNonInquiry(_data: any, _val: any, _selectedRows: any): Observable<any> {
    PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Inquiry view", "Context menu - Mark as non-inquiry", "Click", "Context menu - Mark as non-inquiry", 0);
    let reqObj = this.getRequestData(_data, _val, _selectedRows);
    let body = JSON.stringify(new MarkAsNonInquiry(reqObj.arrayinquiryIds, "Mark as Non-Inquiry", { "markAsNonInquiry": true }, reqObj.groupIds, reqObj.isMultiGroupAction, reqObj.commonGroupId));
    console.log("Mark as Non-Inquiry" + body);
    return this.httpClient.post<MarkAsNonInquiry>(this.inquiryURL + this.nonInquiryUrl, body, httpFormPostOptions).pipe(map(res => res))
  }

  // C153176-5632 | Provide user with option to mark as Inquiry in the non-inquiries folder.
  httpMarkAsInquiry(_data: any, _val: any, _selectedRows: any): Observable<any> {
    PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Inquiry view", "Context menu - Mark as inquiry", "Click", "Context menu - Mark as inquiry", 0);
    let reqObj = this.getRequestData(_data, _val, _selectedRows);
    let body = JSON.stringify(new MarkAsInquiry(reqObj.arrayinquiryIds, "Mark as Inquiry", reqObj.groupIds, reqObj.isMultiGroupAction, reqObj.commonGroupId));
    console.log("Mark as Inquiry" + body);
    return this.httpClient.post<MarkAsInquiry>(this.inquiryURL + this.nonInquiryUrl, body, httpFormPostOptions).pipe(map(res => res))
  }

  httpFollowUpInquiry(_data: any, _val: any, _selectedRows: any): Observable<any> {
    PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Inquiry view", "Context menu - Followup", "Click", "Context menu - Followup", 0);
    let reqObj = this.getRequestData(_data, _val, _selectedRows);

    let body = JSON.stringify(new FollowUpInquiry(reqObj.arrayinquiryIds, "FollowUp", { "flag": "Generic" }, reqObj.groupIds, reqObj.isMultiGroupAction, reqObj.commonGroupId));
    console.log("Follow Up Inquiry Body" + body);
    return this.httpClient.post<FollowUpInquiry>(this.baseURL + this.followUpInquiry, body, httpFormPostOptions)
      .pipe(map(res => res))
  }

  httpUnFollowInquiry(_data: any, _val: any, _selectedRows: any): Observable<any> {
    PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Inquiry view", "Context menu - UnFollow", "Click", "Context menu - UnFollow", 0);
    let reqObj = this.getRequestData(_data, _val, _selectedRows);

    let body = JSON.stringify(new UnFollowInquiry(reqObj.arrayinquiryIds, "RemoveFollowUp", reqObj.groupIds, reqObj.isMultiGroupAction, reqObj.commonGroupId));
    console.log("Un Follow Inquiry Body" + body);
    return this.httpClient.post<UnFollowInquiry>(this.baseURL + this.followUpInquiry, body, httpFormPostOptions)
      .pipe(map(res => res))

  }

  httpGetGroupData(_grp: any): Observable<any> {
    PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Inquiry view", "Context menu - Get group data", "Click", "Context menu - Get group data", 0);
    let body = "request=" + encodeURIComponent(JSON.stringify({ groupName: _grp }));
    return this.httpClient.post(this.groupURL + this.groupData, body, httpFormPostOptions).pipe(map(res => res)); 
  }

  httpApproveInquiry(_data: any, _val: any, _selectedRows: any, loginUserData?:any, _selectedMail?:any): Observable<any> {
    PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Inquiry view", "Context menu - Approve", "Click", "Context menu - Approve", 0);
    let reqObj = this.getRequestData(_data, _val, _selectedRows);

    let body = JSON.stringify(new UnFollowInquiry(reqObj.arrayinquiryIds, "RemoveFollowUp", reqObj.groupIds, reqObj.isMultiGroupAction, reqObj.commonGroupId));
    console.log("Un Follow Inquiry Body" + body);
    return this.httpClient.post<UnFollowInquiry>(this.baseURL + this.followUpInquiry, body, httpFormPostOptions)
        .pipe(map(res => res));
  }

  httpRejectInquiry( _compObj: any, _data: any, _val: any, _selectedRows: any, loginUserData?:any, rejectionReason?:string): Observable<any> {
    PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Inquiry view", "Context menu - Reject", "Click", "Context menu - Reject", 0);
    //validations
    let validationMsg="";
    let type="";
    let nominationRejectionReason="";
    let resp = this.validateCommonGroupAction(_data);
    let makercheckerResp = this.makerCheckerValidations(_data, loginUserData);
    if (resp.isCommonGroupAction) {
      console.log("Reject ValidateCommonGroupAction failed" + resp.message);
      _compObj.rejInq.triggerFalseClick(resp.message);
      return of(null);
    }
    else if( makercheckerResp.isMakerChecker ) {
      console.log("Reject MakerCheckerValidations failed" + makercheckerResp.message);
      _compObj.rejInq.triggerFalseClick(makercheckerResp.message);
      return of(null);
    }
    
    let reqObj = this.getRequestData(_data, _val, _selectedRows);
    for (let dataItem of _data) {
      if (dataItem.direction == 'PENDINGAPPROVAL') {
        type = "Email Approval";
      }
      else if (dataItem.direction == 'NOMINATE_OWNERSHIP') {
        type = "NOMINATE_OWNERSHIP";
        if (rejectionReason) {
          nominationRejectionReason = rejectionReason;
        } else {
          _compObj.rejInq.triggerReasonPopup(reqObj, type);
          return of(null);
        }
      }
      else if (dataItem.direction == 'PND_REAGE') {
        type = "Re Age Inquiry";
      }
    }
    return this.onRejectInquiry(reqObj, type, nominationRejectionReason);
  }

  onRejectInquiry(reqObj: any, type: string, reason?: string): Observable<any> {
    let body = JSON.stringify(new RejectInquiry(reqObj.arrayinquiryIds, "Reject", reqObj.groupName, type, reason));
    console.log("Reject Inquiry Body" + body);
    return this.httpClient.post<RejectInquiry>(this.baseURL + this.updatePendingInquiry, body, httpFormPostOptions).pipe(map(res => res));
  }

  _httpRejectInquiry( rejInq: any, selectedRow: any, _val: any, _selectedRows: any, loginUserData?:any): Observable<any> {
    //validations
    let validationMsg="";
    let type="";
    let resp = this.validateCommonGroupAction(selectedRow);
    let makercheckerResp = this.makerCheckerValidations(selectedRow, loginUserData);
    if (resp.isCommonGroupAction) {
      console.log("Reject ValidateCommonGroupAction failed" + resp.message);
      rejInq.triggerFalseClick(resp.message);
      return;
    }
    else if( makercheckerResp.isMakerChecker ) {
      console.log("Reject MakerCheckerValidations failed" + makercheckerResp.message);
      rejInq.triggerFalseClick(makercheckerResp.message);
      return;
    }
    
    let reqObj = this.getRequestData(selectedRow, _val, _selectedRows);
    for (let dataItem of selectedRow) {
      if (dataItem.direction == 'PENDINGAPPROVAL') {
        type = "Email Approval";
      }
      else if (dataItem.direction == 'PND_REAGE') {
        type = "Re Age Inquiry";
      }
    }

    let body = JSON.stringify(new RejectInquiry(reqObj.arrayinquiryIds, "Reject", reqObj.groupName , type));
    console.log("Reject Inquiry Body" + body);
    return this.httpClient.post<RejectInquiry>(this.baseURL + this.updatePendingInquiry, body, httpFormPostOptions)
      .pipe(map(res => res))

  }


  httpUpdateMemo(_data: any, _val: any): Observable<any> {
    PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Inquiry view", "Context menu - Update memo", "Click", "Context menu - Update memo", 0);
    let reqObj = this.getRequestData(_data);
    let body = JSON.stringify(new UpdateMemo(reqObj.inquiryIds, reqObj.groupIds, _val));
    body = "request=" + encodeURIComponent(body); /* As service require encoded data */
    return this.httpClient.post<UpdateMemo>(this.baseURL + this.updateMemo, body, httpFormPostOptions)
      .pipe(map(res => res))
  }

  httpGetAllGroups(): Observable<any> {
    //let reqObj = this.getRequestData(_data);
    //let body = JSON.stringify(new UpdateMemo(reqObj.inquiryIds, reqObj.groupIds, _val));
    //body = "request=" + encodeURIComponent(body); /* As service require encoded data */
    return this.httpClient.post(this.groupURL + this.getAllGroups, httpFormPostOptions)
      .pipe(map(res => res))
  }

  /**
 * This method checks whether user can approve the request or not
 */
private checkRole(selectedInqGrp, gUserData,role) {
  let canApprove = false;
  if ( selectedInqGrp && role) {
      for (let i = 0; i < gUserData.groupRoles.length; i++) {
          var currentGrpRoleDetails = gUserData.groupRoles[i];
          if (currentGrpRoleDetails
              && (currentGrpRoleDetails.groupName.trim() == selectedInqGrp.trim())
              && (currentGrpRoleDetails.groupRole.indexOf(role) > -1)) {
              canApprove = true;
          }
      }
  }
  return canApprove;
}

httpResolveInquiry(_data: any): Observable<any> {
  let body = "request=" + encodeURIComponent(JSON.stringify(_data));
  return this.httpClient.post(this.baseURL + this.resolveInquiry, body, httpFormPostOptions).pipe(map(res => res)); 
}

httpDeleteEWSMail(_data: any, _val: any, _selectedRows: any): Observable<any> {
  let reqObj = this.getRequestData(_data, _val, _selectedRows);

  let body = JSON.stringify(new DeleteEWSMail(reqObj.ewsIdList));
  console.log("Delete EWS Mail Id Body : " + body);
  return this.httpClient.post(this.baseURL + this.deleteEWSMailURL, body, httpFormPostOptions).pipe(map(res => res))
}

private multipleInqError(){

}
private validateCommonGroupAction(_data: any): any{
  let msg="";
  let validationFlag = false;
  let numberOfGroupsSelected = Array.from(new Set(_data.map((dataItem: any) => dataItem.openGroups)));
  let numberOfWorkflowTypes = Array.from(new Set(_data.map((dataItem: any) => dataItem.direction)));
  if (numberOfGroupsSelected.length > 1) {
		console.log("Bulk reject for multiple groups is not supported");
		validationFlag = true;
		msg = "Reject action is not applicable for bulk selection with different groups.";
	} else  if (numberOfWorkflowTypes.length > 1) {
		console.log("Bulk reject for multiple workflow status is not supported");
		validationFlag = true;
		 msg = "Reject action is not applicable for bulk selection with different workflow status.";
  }
  return { isCommonGroupAction: validationFlag, message: msg };
}
private makerCheckerValidations(_data: any, _loginUserData: any): any{
  let validationSuccess = false;
  let msg="";
	if(this.checkSameApprover(_data, _loginUserData)){
    validationSuccess = true;
    console.log('You cannot approve/reject your own inquiry.');
    msg ="You cannot approve/reject your own inquiry.";
	} else {    
    if(this.checkPendingApprovalType("PENDINGAPPROVAL",_data)  && !this.checkPeerReviewer(_data, _loginUserData)) {
      validationSuccess = true;
      msg ="User is not authorized to review the Inquiries.";
    }
    else if (this.checkPendingApprovalType('NOMINATE_OWNERSHIP',_data) && !this.checkSupervisorReviewer(_data, _loginUserData)) {
      validationSuccess = true;
      msg="User is not authorized to approve/reject the Request.";
    } else if (this.checkPendingApprovalType("PND_REAGE",_data) && !this.checkSupervisorReviewer(_data, _loginUserData)) {
      validationSuccess = true;
      msg="User is not authorized to approve/reject the Request.";
    }
	}
  return { isMakerChecker: validationSuccess, message: msg };
}

private checkSameApprover(_data: any, _loginUserData: any): boolean {
	let isCreatorApproverSame = false;
  let isMakerNull = true;
  for (let dataItem of _data) {
    let workflows = dataItem.workflows;
    if (workflows != null && workflows.length > 0) {
      for (let data of workflows){
        if((data.assignedGroupName == dataItem.openGroups) && 
        (dataItem.direction == 'PENDINGAPPROVAL' || dataItem.direction == 'PND_REAGE')){
          let isMakerDataSame = data.maker == _loginUserData.userId;
          if(data.maker != null && isMakerDataSame){
            isCreatorApproverSame = true;
          } else if (data.maker == null && (dataItem.latestUserId == _loginUserData.userId)){
            // Case where maker is not present.
						isCreatorApproverSame = true;
          }
        }
      }
    }
    console.log(dataItem);
  }
  return isCreatorApproverSame;
}

/**
 * This method checks whether inquiry is requested type
 */
private checkPendingApprovalType(directionType: any, _data: any): boolean {

	let isApproverRoleRequired = false;
	let numberOfDirections = [];
      for (let dataItem of  _data) {
        if(dataItem.direction != null) {
          numberOfDirections.push(dataItem.direction);
        } else if(dataItem.workflows[0] != null && dataItem.workflows[0].direction != null) {
          numberOfDirections.push(dataItem.workflows[0].direction);
        }
      }
			
    numberOfDirections = Array.from(new Set(numberOfDirections));
		if( numberOfDirections.length == 1 && numberOfDirections[0] == directionType ) {
			isApproverRoleRequired = true;
		}
  return isApproverRoleRequired;
}

//Maker Checker Validation Helper method
private checkPeerReviewer(_data: any, _loginUserData: any): boolean { 
	let isMakerCheckerReviewer = this.checkApproverRole("Peer Reviewer", _data, _loginUserData);
	if (!isMakerCheckerReviewer) {
		console.log('User is not authorized to review the Inquiries');
	} 
  return isMakerCheckerReviewer;
}

/**
 * This method checks whether user have supervisor role or not
 */
private checkSupervisorReviewer(_data: any, _loginUserData: any): boolean {
	let isMakerCheckerSupervisor = this.checkApproverRole("Supervisor", _data, _loginUserData);
	if (!isMakerCheckerSupervisor) {
		console.log('User is not authorized to approve/reject the Request.');
	}
	return isMakerCheckerSupervisor;
}

/**
 * This method identified group of selected inquiry and send request further
 * for role checking. 
 */
private checkApproverRole(role: any, _data: any, _loginUserData: any): any{
	let canApprove = false;
	let numberOfGroups = [];
	for (let dataItem of _data) {

    if(dataItem.openGroups != null){
      numberOfGroups.push(dataItem.openGroups);
    } else if(dataItem.workflows[0] != null && dataItem.workflows[0].assignedGroupName !=null){
      numberOfGroups.push(dataItem.workflows[0].assignedGroupName);
    }
  }
  numberOfGroups = Array.from(new Set(numberOfGroups));
  if(numberOfGroups.length == 1) {
		canApprove = this.checkRole(numberOfGroups[0], _loginUserData, role); // C153176-4952: refactor - change method name to be more generic
	}
	return canApprove;
  }

  httpLinkInquiry(_data: any): Observable<any> {
    PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Inquiry view", "Context menu - Link inquiry", "Click", "Context menu - link inquiry", 0);
    let body = JSON.stringify(_data);
    return this.httpClient.post(this.baseURL + this.linkDelinkInquiry, body, httpFormPostOptions).pipe(map(res => res)); 
  }
  
  // incase inq ids are different or grpids are different return true
  private isMultiGrp(inqs: any, groups: any): boolean {
    const distinctInqs = [... new Set(inqs)];
    const distinctGrps = [... new Set([...groups.map(grp => grp.split(":")[1])].map(gr => gr.split(",")))][0].length;
    return (distinctInqs.length > 1 || distinctGrps > 1) ? true : false;
  }

  // C153176-4952 check common group and have supervisor role
  public checkCommonGroupAndRole(role: any, _data: any, _loginUserData) {
    if (!_data || !_data.length) {
      return false;
    }
    let groupNames = [];
    for (let dataItem of _data) {
      if(dataItem.isEscalated){//C170665-121 check inquiry is escalated or not
      if(dataItem.openGroups != null){
        if (!groupNames.includes(dataItem.openGroups)) {
          groupNames.push(dataItem.openGroups);
        }
      } else if(dataItem.workflows[0] != null && dataItem.workflows[0].assignedGroupName !=null){
        if (!groupNames.includes(dataItem.workflows[0].assignedGroupName)) {
          groupNames.push(dataItem.workflows[0].assignedGroupName);
        }
      }
    }
    }
    // only if the selected items share one common group will the role be checked / allowed
    if (groupNames.length === 1) {
      return this.checkRole(groupNames[0], _loginUserData, role);
    } else {
      return false;
    }
  }
  // C170665-544 2 additional calls post getAllInquiryConversation
  checkIfInqAlreadyRead(loginUserId, InqId) {
    if (InqId && InqId.readBy && Array.isArray([InqId.readBy])) {
      return InqId.readBy.includes(loginUserId);
    } else {
      return false;
    }
  }
}
import { Component, OnInit, HostBinding, HostListener, Input, ElementRef } from '@angular/core';

@Component({
  selector: 'app-mobile-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss']
})
export class ContextMenuComponent implements OnInit {

  @HostBinding("style.top") y = "0px"
  @HostBinding("style.left") x = "0px"
 // @HostBinding("style.visibility") visibility = "hidden"
  //@Input() @HostBinding("style.width") width = "275px"
  @Input() @HostBinding("style.height") height = "";
  visibility:boolean = false;
  items:[];
  currentItem:string;

  
  constructor(private eRef: ElementRef) { }
  ngOnInit() {
  }

  open(event) {
    this.x = `${0}px`;
    this.visibility = true;
    let items = event.items;
    
    items = (!(items instanceof Array)) ? [items] : items;

   // this.currentItem = items[0].label;
    this.items = items;
 }
  close() {
    this.visibility = false;
  }

  @HostListener('document:click', ['$event'])
  public onDocumentClick(event) {
    if(!this.eRef.nativeElement.contains(event.target)) {
      this.close();
    }

    
   
    //C153176-4716 - To display header on top of Selected context Menu Item
    let target = (event.target as Element);
    //C153176-5105: null check
    if((target.className && typeof target.className === 'string' && target.className.includes('p-slidemenu-backward')) ||
      (target.parentElement && target.parentElement.className && typeof target.parentElement.className === 'string' &&
       target.parentElement.className.includes('p-slidemenu-backward'))){ 
      let t = document.querySelector('.p-slidemenu-backward').lastElementChild;
        t.textContent = this.currentItem;
    }
    
    //C153176-5105: null check
    if((target.className && typeof target.className === 'string' && target.className.includes('p-menuitem')) ||
      (target.parentElement && target.parentElement.className && typeof target.parentElement.className === 'string' &&
       target.parentElement.className.includes('p-menuitem'))){
      let t = document.querySelector('.p-slidemenu-backward').lastElementChild;
      this.currentItem = t.textContent;
      if(target.nodeName == 'SPAN'){
        t.textContent =  target.parentElement.querySelector('.p-menuitem-text').textContent;
      }
      else{
        t.textContent = target.textContent;
      }    
    }
  }

}
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AllToCcDBUser } from 'src/app/model/LoginUserInfo/AllToCcDBUser';
import { UserDataService } from 'src/app/services/user-data.service';
import { AppUtils } from '../../utility/appUtil';

@Component({
  selector: 'app-global-directory-suggestion',
  templateUrl: './global-directory-suggestion.component.html',
  styleUrls: ['./global-directory-suggestion.component.scss']
})
export class GlobalDirectorySuggestionComponent implements OnInit {
//display suggestion popup
@Input() displaySuggestion: boolean = false;
@Input() suggestionRecords: [];
@Input() suggestionRecordCount: number = 0;
selectedSuggestion;
selectedSuggestionIndex: Number;
@Output() citiUserEvent = new EventEmitter<AllToCcDBUser>();
  constructor(private userDataService: UserDataService) { }

  ngOnInit() {
  }

  addSuggestionToRecipient(){
    console.log(this.selectedSuggestion);
    let citiUser = new AllToCcDBUser();
    citiUser.value = citiUser.email = this.selectedSuggestion['EmailAddress']['_text'];
    if(citiUser.value.length === 0){
      citiUser = null;
    }
    citiUser.id = this.selectedSuggestion['SoeId']['_text'] || citiUser.email;
    // C170665-1897 Recipient Missing in Reply All for Non QMA User
    
    const isNonQMAUser = this.isNonQMAUser(this.userDataService.loggedInUserInfo.allToCcDBUsers,citiUser.id);
    if (isNonQMAUser) {
      // if user is NON QMA user set its id to null so when 
      // modify recepient replace value with id for QMA user NON QMA users will be skipped
      // for non QMA users keep value as email id.
      citiUser.id = null;
    }
    citiUser.active = false;
    citiUser.text = this.selectedSuggestion['Name']['_text'] || citiUser.email;
    //add external flag to item
    citiUser.isExternal = AppUtils.isExternalEmailInToCc(citiUser.email, this.userDataService.loggedInUserInfo.citiDomainList);
    this.displaySuggestion = false;
    this.citiUserEvent.emit(citiUser);
  }

  cancelSuggestion(){
    this.displaySuggestion = false;
    this.citiUserEvent.emit(null);
  }

  isNonQMAUser(qmaOnBoardedUsers, soeId) {
    if (!qmaOnBoardedUsers || !soeId) {
      return false;
    }
    if (Array.isArray(qmaOnBoardedUsers) && typeof soeId === "string") {
      return !qmaOnBoardedUsers.some(ou => ou.id === soeId.toLowerCase());
    } else {
      return false;
    }

  }

}

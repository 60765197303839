import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgGridModule } from '@ag-grid-community/angular';
import { InboxViewComponent } from "./inboxView/inboxView.component";
import { MailDetailViewComponent } from "./mailDetailView/mailDetailView.component";
import { DetailCellRenderer } from "./inboxView/detail-cell-renderer.component";
import { MainComponent } from '../core/main/main.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ListboxModule } from 'primeng/listbox';
import { SharedModule } from "src/app/common/shared-module";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NewMessageModule } from "src/app/new-message/new-message.module";
import { TabViewModule } from 'primeng/tabview';

import { ColDefsService } from '../services/mailbox/get-ColDefsService.service';
import { GroupRowInnerRenderer } from './inboxView/group-row-inner-renderer.component';
import { DragGripCellRenderer } from './inboxView/drag-grip-cell-renderer.component';
import { ChatViewComponent } from './chat-view/chat-view.component'
import { DraftDetailViewComponent } from './draft-detail-view/draft-detail-view.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { DropdownModule } from 'primeng/dropdown';
import { DialogModule } from 'primeng/dialog';
import { NlpViewComponent } from './nlp-view/nlp-view.component';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TooltipModule } from 'primeng/tooltip';
import { AngularSplitModule } from 'angular-split';
/* import { Ng2LoadingSpinnerModule } from 'ng2-loading-spinner'
 */
import { NgxLoadingModule, ngxLoadingAnimationTypes } from '@dchtools/ngx-loading-v18';
import { QuickReplyComponent } from './quick-reply/quick-reply.component';
import { MobileInboxViewComponent } from './mobile/mobile-inbox-view/mobile-inbox-view.component';
import { MobileInboxItem } from './mobile/mobile-inbox-item/mobile-inbox-item.component';
import { UserNamePipe } from './mobile/mobile-inbox-item/username.pipe';
import { GenericTransformerPipe } from './mobile/mobile-inbox-item/generictransformer.pipe';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { ContextMenuComponent } from './mobile/context-menu/context-menu.component';
import { SlideMenuModule } from 'primeng/slidemenu';
import { MenuModule } from 'primeng/menu';
import { MenuItem } from 'primeng/api';
import { ClcSuggestionComponent } from './clc-suggestion/clc-suggestion.component';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { SymphonyModule } from '../symphony/symphony.module';
import { AutoAssignComponent } from './auto-assign/auto-assign.component';
import { CheckboxModule } from 'primeng/checkbox';
import { CidViewComponent } from './cid-view/cid-view.component';
/* import { SymphonyCreateChatComponent } from 'src/app/symphony/symphony-create-chat/symphony-create-chat.component' */


@NgModule({
  declarations: [
    MainComponent,
    InboxViewComponent,
    MailDetailViewComponent,
    DetailCellRenderer,
    GroupRowInnerRenderer,
    DragGripCellRenderer,
    ChatViewComponent,
    DraftDetailViewComponent,
    NlpViewComponent,
    QuickReplyComponent,
    MobileInboxViewComponent,
    MobileInboxItem,
    ContextMenuComponent,
    UserNamePipe,
    GenericTransformerPipe,
    ClcSuggestionComponent,
    AutoAssignComponent,
    CidViewComponent,
    /* SymphonyCreateChatComponent */
  ],
  imports: [
    SymphonyModule,
    CommonModule,
    AgGridModule,
    NgScrollbarModule,
    OverlayPanelModule,
    ListboxModule,
    TabViewModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NewMessageModule,
    AutoCompleteModule,
    DropdownModule,
    DialogModule,
    ConfirmDialogModule,
    TooltipModule,
    AngularSplitModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.circleSwish,
      backdropBackgroundColour: 'rgba(0,0,0,0.1)',
      backdropBorderRadius: '4px',
      primaryColour: '#021e48',
      secondaryColour: '#021e48',
      tertiaryColour: '#021e48',
      fullScreenBackdrop: true
    }),
    SlideMenuModule,
    MenuModule,
    CheckboxModule,
    ToastModule,
    NgxUiLoaderModule // C153176-5076, add ngx-ui-loader to inbox module
    /*  Ng2LoadingSpinnerModule.forRoot({}) */
  ],
  exports: [
    InboxViewComponent,
    MailDetailViewComponent,
    QuickReplyComponent,
    DetailCellRenderer,
    NgScrollbarModule,
    OverlayPanelModule,
    ListboxModule,
    SharedModule,
    FormsModule,
    DialogModule,
    GroupRowInnerRenderer,
    TooltipModule,
    DragGripCellRenderer,
    UserNamePipe,
    GenericTransformerPipe,
    CheckboxModule,
    NlpViewComponent,
    ClcSuggestionComponent,
    AutoAssignComponent,
    CidViewComponent
  ],
  providers: [ColDefsService, MessageService],

})

export class InboxModule { }

<p-tabView class="innerTab" (onChange)="handleChange($event)">
    <p-tabPanel header="INDIVIDUALS">
        <ng-template pTemplate="content">
            <a (click)="showCreateNewDialog()" class="createNewLink mt-2"><img src="assets/core/layout/nav/add_icon.svg"
            alt=""> <span style="cursor: pointer">Create New</span></a>


            <div class="search-list">
                <div class="input-group">
                    <input type="text" placeholder='Search...' [(ngModel)]="query">
                    <div class="input-group-btn searchBox">
                        <button class="btn btn-default" type="submit">
                        <span><span><img src="assets/core/layout/header/search_white.svg" style="width:18px;height:18px;"></span></span>
                    </button>
                    </div>
                </div>
            </div>


            <ul class="listOfContacts">
                  <!--   <ng-scrollbar class="my-scrollbar" [invertY]="false" [trackX]="false" [compact]="false" [shown]="'native'" [autoUpdate]="true"> -->
                <li class="contacts" *ngFor="let groupcontact of groupContacts | search:'name,email':query; trackBy: trackById">
                    <div class="hoverButtonGroup">
                        <div class="d-flex align-items-center justify-content-around wrapper">
                            <a href=".#" (click)="pushToRecipent(groupcontact, 'to')">TO</a>
                            <a href=".#" (click)="pushToRecipent(groupcontact, 'cc')">CC</a>
                            <a href=".#" (click)="pushToRecipent(groupcontact, 'bcc')">BCC</a>
                            <a href=".#" class="ml-auto p-2" (click)="showEditTopContactDialog(groupcontact,groupcontact.index)"><i
                                    class="fa fa-external-link"></i></a>
                        </div>
                    </div>
                    <div class="d-flex align-items-start flex-row">
                        <div>
                            <img src="assets/core/layout/header/person.jpg" alt="" class="profileImage">
                        </div>

                        <div class="d-flex flex-column ml-1" [class.boldMe]="groupcontact.email.indexOf(';') > -1">
                            <div class="name">{{groupcontact.name}}</div>
                            <div class="email">{{groupcontact.email}}</div>
                            <div class="number">{{groupcontact.contactPhNumber}}</div>
                        </div>
                    </div>
                </li>
                 <!--  </ng-scrollbar> -->
            </ul>
        </ng-template>
    </p-tabPanel>
    <p-tabPanel header="GROUPS">
        <ng-template pTemplate="content">
            <div class="search-list">
                <div class="input-group">
                    <input type="text" placeholder='Search...' [(ngModel)]="groupQuery">
                    <div class="input-group-btn searchBox">
                        <button class="btn btn-default" type="submit">
                        <span><span><img src="assets/core/layout/header/search_white.svg" style="width:18px;height:18px;"></span></span>
                    </button>
                    </div>
                </div>
            </div>
            <div class="panel-group" id="accordionSide">
                <cdk-virtual-scroll-viewport itemSize="60">
                <div *cdkVirtualFor="let activeGroup of allActiveGroups | search:'text':groupQuery; trackBy: trackById" class="panel mb-2 panel-default">
                    <div class="panel-heading" (click)="getGroupMemberList(activeGroup)">
                        <div class="d-flex flex-row justify-content-between flex-unwrap panel-title p-1 accordion-section">
                            <a class="accordion-toggle d-flex align-items-center justify-content-between w-100">
                                <div>{{activeGroup.text}}</div>
                                <!-- //C153176-4430 - group contact add to 'to,cc,bcc'//C153176-4430 - group contact add to 'to,cc,bcc' -->
                                <span class="pull-right fa fa-plus  pr-1" (click)="openToolTipForGroup($event,groupAddToCC,activeGroup)"></span>
                                <span class="pull-right"><img src="assets/core/layout/nav/group_tag_chevron.svg" class="tag-chevron" [ngClass]="{'chevron-tranform': activeGroup.toggle }"></span>
                            </a>
                        </div>
                    </div>
                    <div [hidden]="activeGroup.toggle" class="panel-collapse in pl-1">
                        <div class="panel-body">
                            <ul class="listOfContacts ">
                                <li class="contacts" *ngFor="let groupMember of groupMembers; trackBy: trackById">
                                    <!-- Jira : 5002 : the deleted email is reappearing in to/cc/bcc on click of resolve or add contact -->
                                    <div *ngIf="groupMember.groupName == activeGroup.text" class="hoverButtonGroup">
                                        <div class="d-flex align-items-center wrapper">
                                            <a href=".#" (click)="pushToRecipent(groupMember, 'to')">TO</a>
                                            <a href=".#" (click)="pushToRecipent(groupMember, 'cc')">CC</a>
                                            <a href=".#" (click)="pushToRecipent(groupMember, 'bcc')">BCC</a>
                                        </div>
                                    </div>
                                    <!-- Jira : 5002 : the deleted email is reappearing in to/cc/bcc on click of resolve or add contact -->
                                    <div *ngIf="groupMember.groupName == activeGroup.text" class="d-flex align-items-start flex-row">
                                        <div>
                                            <img src="assets/core/layout/header/person.jpg" alt="" class="profileImage">
                                        </div>
                                        <div class="d-flex flex-column ml-1">
                                            <div class="name">{{groupMember.name}}</div>
                                            <div class="email">{{groupMember.email}}</div>
                                            <div class="number">{{groupMember?.contact}}</div>
                                        </div>
                                    </div>
                                </li>
                                <li class="contacts" *ngIf="groupMembers.length===0 && !loading">
                                    <div class="d-flex ml-1 mt-3">
                                        <div class="name">No Member Found</div>
                                    </div>
                                </li>
                                <li class="contacts" *ngIf="loading">
                                    <div class="d-flex ml-1 mt-3">
                                        <div class="name">loading...</div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                </cdk-virtual-scroll-viewport>
            </div>
        </ng-template>
    </p-tabPanel>
    <p-tabPanel header="CLIENT">
        <ng-template pTemplate="content">
            <div class="search-list">
                <div class="input-group">
                    <input type="text" placeholder='Search...' [(ngModel)]="clientQuery">
                    <div class="input-group-btn searchBox">
                        <button class="btn btn-default" type="submit">
                        <span><span><img src="assets/core/layout/header/search_white.svg" style="width:18px;height:18px;"></span></span>
                    </button>
                    </div>
                </div>
            </div>
            <ul class="listOfContacts clientList">
                <cdk-virtual-scroll-viewport itemSize="50">
                    <ng-container *cdkVirtualFor="let clientcontact of clientContacts | search:'email':clientQuery; trackBy: trackById">
                        <li class="contacts">
                            <div class="hoverButtonGroup">
                                <div class="d-flex align-items-center wrapper"> <a href=".#" (click)="pushToRecipent(clientcontact, 'to')">TO</a>
                                    <a href=".#" (click)="pushToRecipent(clientcontact, 'cc')">CC</a>
                                    <a href=".#" (click)="pushToRecipent(clientcontact, 'bcc')">BCC</a>
                                    <a href="#" class="ml-auto p-2" *ngIf="clientcontact.isAdmin" (click)="onEditClient(clientcontact)"><i class="fa fa-external-link"></i></a>
                                </div>
                            </div>
                            <div class="d-flex align-items-start flex-row">
                                <div> <img src="assets/core/layout/header/person.jpg" alt="" class="profileImage"> </div>
                                <div class="d-flex flex-column ml-1">
                                    <div class="name">{{clientcontact.name}}</div>
                                    <div class="email">{{clientcontact.email}}</div>
                                </div>
                            </div>
                        </li>
                    </ng-container>
                </cdk-virtual-scroll-viewport>
            </ul>
        </ng-template>
    </p-tabPanel>
    `
</p-tabView>

<!-- //C153176-4430 - group contact add to 'to,cc,bcc'//C153176-4430 - group contact add to 'to,cc,bcc' -->
<p-overlayPanel [appendTo]="'body'" #groupAddToCC [styleClass]="'addToToolTip'">
    <div (click)="this.pushToRecipent(tempSingleGroup, 'to', true);">Add to 'TO'</div>
    <div (click)="this.pushToRecipent(tempSingleGroup, 'cc', true);">Add to 'CC'</div>
    <div (click)="this.pushToRecipent(tempSingleGroup, 'bcc', true);">Add to 'BCC'</div>
</p-overlayPanel>
<!-- C153176-4618 Responsive Design-->
<p-dialog [(visible)]="displayCreateNew" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'" [styleClass]="'contextMenuModal createModal'"
    [closable]="false" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" [style]="{'max-width':'600px','width':'100%'}"
    [resizable]="false">

    <p-header>
        <div class="d-flex flex-row align-items-center justify-content-between header-new-contact">
            <h6 class="contact-list-title">CREATE NEW TOP CONTACT</h6>
            <button type="button" (click)="cancelTopContact('save')" class="d-flex flex-row close qma-text">
                <img src="assets/Inbox/close_icon_u73.svg">
                <h6>CLOSE</h6>
            </button>
        </div>
    </p-header>
    <form class="form-horizontal " [formGroup]="addTopContactForm" (ngSubmit)="onSaveSubmit()">
        <div id="createnew" class="mt-1">
            <div class="d-flex flex-row align-items-start">
                <label class="lblTopContact">Name:</label>
                <div class="top-contact-form">
                    <input type="text" placeholder="Start typing..." pInputText size="25" formControlName="name" class="w-100 form-control" [ngClass]="{ 'is-invalid': submitted && add.name.errors }"
                    />
                    <div *ngIf="submitted && add.name.errors" class="invalid-feedback">
                        <div *ngIf="add.name.errors.required"><img src="assets/newMessage/error.svg" class="error"> Name is required</div>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-row align-items-start">
                <label class="lblTopContact">Email:</label>
                <div class="top-contact-form">
                    <input type="text" placeholder="Start typing..." pInputText size="25" formControlName="email" class="w-100 form-control"
                        [ngClass]="{ 'is-invalid': submitted && add.email.errors }" />
                    <div *ngIf="submitted && add.email.errors" class="invalid-feedback">
                        <div *ngIf="add.email.errors.required"><img src="assets/newMessage/error.svg" class="error"> Email is required</div>
                        <div *ngIf="add.email.errors.email"><img src="assets/newMessage/error.svg" class="error"> Email must be a valid email address</div>
                    </div>
                </div>
            </div>

            <div class="d-flex flex-row align-items-start">
                <label class="lblTopContact">Phone:</label>
                <div class="top-contact-form">
                    <input type="text" placeholder="Start typing..." pInputText size="25" formControlName="phone" class="w-100 form-control"
                    />
                </div>
            </div>

            <div class="d-flex flex-row align-items-start">
                <label class="lblTopContact">Address:</label>
                <div class="top-contact-form">
                    <input type="text" placeholder="Start typing..." pInputText size="25" formControlName="address" class="w-100 form-control"
                    />
                </div>
            </div>

            <div class="d-flex flex-row align-items-start">
                <label class="lblTopContact">Note:</label>
                <div class="top-contact-form">
                    <input type="text" placeholder="Start typing..." pInputText size="25" formControlName="note" class="w-100 form-control" />
                </div>
            </div>

            <div class="new-top-contact">
                <div class="d-flex flex-row align-items-start" style="margin-bottom:25px;margin-top:5px;">
                    <label class="lblTopContact" id="lblTopContact2">Map:</label>
                    <div class="d-flex flex-row align-items-center input-holder">
                        <div class="d-flex flex-row w-100 align-items-center">
                            <p-autoComplete formControlName="mapToGroup" [suggestions]="filteredGroups" (completeMethod)="filterGroups($event)" [minLength]="1"
                                [multiple]="true" [styleClass]="'ownership-dropdown contact-pac'" placeholder="Start typing..."
                                class="w-100" [dropdown]="true" [(ngModel)]="selectedGroup">
                                <ng-template let-grp pTemplate="myGroups.label">
                                    <div class="ui-helper-clearfix">
                                        <div class="search-text">{{grp}}</div>
                                    </div>
                                </ng-template>
                            </p-autoComplete>
                        </div>
                    </div>
                </div>
            </div>

            <div class="d-flex flex-row align-items-start" style="margin-bottom:0px">
                <label class="lblTopContact" id="lblTopContact2">Share To:</label>
                <div class="d-flex flex-row align-items-center input-holder">
                    <div class="d-flex flex-row w-100 align-items-center">
                        <p-autoComplete formControlName="sharedToGroup" [suggestions]="filteredGroups" (completeMethod)="filterGroups($event)" [minLength]="1"
                            [multiple]="true" [styleClass]="'ownership-dropdown contact-pac'" placeholder="Start typing..." class="w-100"
                            [dropdown]="true">
                            <ng-template let-grp pTemplate="myGroups.label">
                                <div class="ui-helper-clearfix">
                                    <div class="search-text">{{grp}}</div>
                                </div>
                            </ng-template>
                        </p-autoComplete>
                    </div>
                </div>
            </div>

        </div>
        <p-footer>
            <div class="d-flex flex-row flex-wrap bottom-button-holder px-3 footer-buttons-holder justify-content-end">
                <button type="submit" class="btn search-btn mr-4" [disabled]="contactSaveClicked">Save</button>
                <button type="reset" class="btn clear-btn" (click)="cancelTopContact('save')">Cancel</button>
            </div>
        </p-footer>
    </form>
</p-dialog>
<!-- C153176-4618 Responsive Design-->
<p-dialog [(visible)]="displayEdit" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'" [styleClass]="'contextMenuModal editModal'"
    [closable]="false" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" [style]="{'max-width':'600px','width':'100%'}"
    [resizable]="false">

    <p-header>
        <div class="d-flex flex-row align-items-center justify-content-between header-new-contact">
            <h6 class="contact-list-title">EDIT TOP CONTACT</h6>
            <button type="button" (click)="cancelTopContact('edit')" class="d-flex flex-row close qma-text">
                <img src="assets/Inbox/close_icon_u73.svg">
                <h6>CLOSE</h6>
            </button>
        </div>
    </p-header>
    <form class="form-horizontal " [formGroup]="editTopContactForm" (ngSubmit)="onEditSubmit()">
        <input type="hidden" name="index">
        <input type="hidden" name="createdBy">
        <div id="createnew" class="mt-1" [class.readOnlyControl]="!isUserGroupAdmin">
            <div class="d-flex flex-row align-items-start">
                <label class="lblTopContact">Name:</label>
                <div class="top-contact-form">
                    <input type="text" pInputText size="25" formControlName="nameEdit" class="w-100 form-control" [ngClass]="{ 'is-invalid': submitted && edit.nameEdit.errors }"
                        value="{{nameEdit}}" />
                    <div *ngIf="submitted && edit.nameEdit.errors" class="invalid-feedback">
                        <div *ngIf="edit.nameEdit.errors.required"><img src="assets/newMessage/error.svg" class="error"> Name is required</div>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-row align-items-start">
                <label class="lblTopContact">Email:</label>
                <div class="top-contact-form">
                    <input type="text" placeholder="Start typing..." pInputText size="25" formControlName="emailEdit" class="w-100 form-control"
                        [ngClass]="{ 'is-invalid': submitted && edit.emailEdit.errors }" value="{{emailEdit}}" />
                    <div *ngIf="submitted && edit.emailEdit.errors" class="invalid-feedback">
                        <div *ngIf="edit.emailEdit.errors.required"><img src="assets/newMessage/error.svg" class="error"> Email is required</div>
                        <div *ngIf="edit.emailEdit.errors.email"><img src="assets/newMessage/error.svg" class="error"> Email must be a valid email address</div>
                    </div>
                </div>
            </div>

            <div class="d-flex flex-row align-items-start">
                <label class="lblTopContact">Phone:</label>
                <div class="top-contact-form">
                    <input type="text" placeholder="Start typing..." pInputText size="25" formControlName="phoneEdit" class="w-100 form-control"
                        value="{{phoneEdit}}" />
                </div>
            </div>

            <div class="d-flex flex-row align-items-start">
                <label class="lblTopContact">Address:</label>
                <div class="top-contact-form">
                    <input type="text" placeholder="Start typing..." pInputText size="25" formControlName="addressEdit" class="w-100 form-control"
                        value="{{addressEdit}}" />
                </div>
            </div>

            <div class="d-flex flex-row align-items-start">
                <label class="lblTopContact">Note:</label>
                <div class="top-contact-form">
                    <input type="text" placeholder="Start typing..." pInputText size="25" formControlName="noteEdit" class="w-100 form-control"
                        value="{{noteEdit}}" />
                </div>
            </div>

            <div class="d-flex flex-row align-items-start" style="margin-bottom:15px;margin-top:5px;">
                <label class="lblTopContact" id="lblTopContact2">Map:</label>
                <div class="d-flex flex-row align-items-center input-holder">
                    <div class="d-flex flex-row w-100 align-items-center">
                        <p-autoComplete formControlName="mapToGroupEdit" [suggestions]="filteredGroups" (completeMethod)="filterGroups($event)" [minLength]="1"
                            [multiple]="true" [styleClass]="'ownership-dropdown contact-pac'" placeholder="Start typing..." class="w-100"
                            [dropdown]="true">
                            <ng-template let-grp pTemplate="myGroups.label">
                                <div class="ui-helper-clearfix">
                                    <div class="search-text">{{grp}}</div>
                                </div>
                            </ng-template>
                        </p-autoComplete>
                    </div>
                </div>
            </div>

            <div class="d-flex flex-row align-items-start" style="margin-bottom:0px">
                <label class="lblTopContact" id="lblTopContact2">Share To:</label>
                <div class="d-flex flex-row align-items-center input-holder">
                    <div class="d-flex flex-row w-100 align-items-center">
                        <p-autoComplete formControlName="sharedToGroupEdit" [suggestions]="filteredGroups" (completeMethod)="filterGroups($event)"
                            [minLength]="1" [multiple]="true" [styleClass]="'ownership-dropdown contact-pac'" placeholder="Start typing..."
                            class="w-100" [dropdown]="true">
                            <ng-template let-grp pTemplate="myGroups.label">
                                <div class="ui-helper-clearfix">
                                    <div class="search-text">{{grp}}</div>
                                </div>
                            </ng-template>
                        </p-autoComplete>
                    </div>
                </div>
            </div>

        </div>
        <p-footer>
            <div class="d-flex flex-row flex-wrap bottom-button-holder px-3 footer-buttons-holder justify-content-end">
                <button type="submit" class="btn search-btn mr-4 edit-btn" [disabled]="contactSaveClicked" *ngIf="isUserGroupAdmin">Save</button>
                <button type="button" class="btn search-btn mr-4 edit-btn" (click)="deleteTopContact()" *ngIf="isUserGroupAdmin">Delete</button>
                <button type="reset" class="btn clear-btn edit-btn" (click)="cancelTopContact('edit')">Cancel</button>
            </div>
        </p-footer>
    </form>
</p-dialog>


<p-dialog [(visible)]="displaySuccess" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'" [styleClass]="'contextMenuModal successModal'"
    [closable]="false" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" [style]="{'width': '30%'}">
    <p-header>
        <div class="d-flex flex-row align-items-center justify-content-between">
            <h6 class="success-title">Success Confirmation</h6>
            <button type="button" (click)="closeModal('success')" class="d-flex flex-row close qma-text">
                <img src="assets/Inbox/close_icon_u73.svg">
                <h6>CLOSE</h6>
            </button>
        </div>
    </p-header>
    <div class="success-msg"> Contacts has been saved successfully</div>
</p-dialog>

<!-- C153176-4618 Responsive Design-->
<p-dialog [(visible)]="displayError" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'" [styleClass]="'contextMenuModal failureModal'"
    [closable]="false" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" [style]="{'width': '30%'}">
    <p-header>
        <div class="d-flex flex-row align-items-center justify-content-between">
            <h6 class="success-title">Information</h6>
            <button type="button" (click)="closeModal('error')" class="d-flex flex-row close qma-text">
                <img src="assets/Inbox/close_icon_u73.svg">
                <h6>CLOSE</h6>
            </button>
        </div>
    </p-header>
    <div class="success-msg">Technical issue has occurred! Please contact Support!</div>
</p-dialog>

<!--  C153176-5630 : Client Address Contact update popup-->
<p-dialog [visible]="editClientAddr" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'" [styleClass]="'contextMenuModal editModal'"
    [closable]="false" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" [style]="{'max-width':'600px','width':'100%'}"
    [resizable]="false">

    <p-header>
        <div class="d-flex flex-row align-items-center justify-content-between header-new-contact">
            <h6 class="contact-list-title">Edit email</h6>
            <button type="button" (click)="cancelTopContact('editClient')" class="d-flex flex-row close qma-text">
                <img src="assets/Inbox/close_icon_u73.svg">
                <h6>CLOSE</h6>
            </button>
        </div>
    </p-header>
    <form class="form-horizontal " [formGroup]="clientContactForm">
        <div class="mt-1 px-4" [class.readOnlyControl]="false">
            <div class="d-flex flex-row align-items-center">
                <div class="grp-header">Group:</div>
                <div class="top-contact-form">
                    <label class="w-100 form-control grp-name">{{currClientInfo?.groupname}}</label>
                </div>
            </div>
            <div class="d-flex flex-row align-items-center">
                <div class="grp-header">Email:</div>
                <div class="top-contact-form">
                    <input type="text" placeholder="Start typing..." pInputText size="25" formControlName="emailAddr" class="w-100 form-control email-input-field"
                        [ngClass]="{ 'is-invalid': submitted && client.email && client.email.errors }" />
                    <!-- C153176-5831 | Unable to close the topcontact dialog-->
                    <div *ngIf="submitted && client?.emailAddr.errors" class="invalid-feedback">
                        <div *ngIf="client.emailAddr.errors.required"><img src="assets/newMessage/error.svg" class="error"> Email is required</div>
                        <div *ngIf="client.emailAddr.errors.email"><img src="assets/newMessage/error.svg" class="error"> Email must be a valid email address</div>
                    </div>
                </div>
            </div>
        </div>
        <p-footer>
            <div class="d-flex flex-row flex-wrap bottom-button-holder px-3 footer-buttons-holder justify-content-end">
                <button type="submit" class="btn search-btn mr-4 edit-btn" (click)="onEmailSubmit(currClientInfo)">Save</button>
                <button type="button" class="btn search-btn mr-4 edit-btn" (click)="onDelete(currClientInfo)">Delete</button>
                <button type="reset" class="btn clear-btn edit-btn" (click)="cancelTopContact('editClient')">Cancel</button>
            </div>
        </p-footer>
    </form>
</p-dialog>
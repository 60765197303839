<p-dialog [(visible)]="display" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'"
  [styleClass]="'contextMenuModal ownershipModal'" [closable]="false" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true"
  [style]="{'max-width':'700px','width':'100%'}" (onShow)="onShowDialog()" [resizable]="false">

  <p-header>
    <div class="d-flex flex-row align-items-center justify-content-between mx-2">
      <h6 class="ownership-title">OWNERSHIP</h6>
      <button type="button" (click)="hideModal()" class="d-flex flex-row close qma-text">
        <img src="assets/Inbox/close_icon_u73.svg" alt="Close">
        <h6>CLOSE</h6>
      </button>
    </div>
  </p-header>
  <div class="ownership-body mx-2" [class.assignToMe]="assignToMe">
    <div class="d-flex flex-row align-items-center input-holder my-2">
      <div (click)="selectedTab(0)" [ngClass]="['mr-4']" [class.isInactive]="!tabArray[0]">
        <ins>TAKE OWNERSHIP</ins>
      </div>
      <div (click)="selectedTab(1)" [class.isInactive]="!tabArray[1]">
        <ins>NOMINATE OWNERSHIP</ins>
      </div>
    </div>
    <div [hidden]="!tabArray[0]" class="my-4">
      <div class="mt-4" *ngIf = "!isMultiGroupSelected">
        <div class="w-100 pull-left mb-4">
            <div class="custom-control custom-checkbox" [class.disable]="disableAssignToMe">
              <input class="custom-control-input" type="checkbox" [(ngModel)]="assignToMe" id="assigntome" name="assigntome" (change)="onAssignToMe($event)">
              <label class="custom-control-label" for="assigntome">Assign to me</label>
            </div>
            <div [hidden]="!disableAssignToMe">
              already Assinged to you 
            </div>
        </div>
        <div class="Owner-checkbox nominate-ownership mobileView-ownership">
          <div class="mb-1">Assigned group</div>
          <app-checkbox-list [searchIcon]="true" [placeholder]="'Quick Search...'" [name]="'user'" [items]="userGroups"
            [listStyle]="{'height':'198px'}" [controlType]="'checkbox'" [resetBehaviour]="true" (onItemSelect)="getSelectedItems($event)"></app-checkbox-list>
        </div>
        <div class="Owner-checkbox nominate-ownership ml-4 mobileView-ownership checkbox-mobile">
          <div class="mb-1">Assigned Owner</div>
          <app-checkbox-list [searchIcon]="true" [placeholder]="'Quick Search...'" [name]="'allOwner'" [items]="ownerList"
            [listStyle]="{'height':'198px'}" [controlType]="'radio'" [resetBehaviour]="true" (onItemSelect)="getSelectedOwner($event)"></app-checkbox-list>        
        </div>
      </div>
      <div *ngIf="takeOwnershipValidation" class="text-danger required-before pull-left">Please uncheck Take Ownership to
          select Nominate Ownership</div>
      <div *ngIf = "!!isMultiGroupSelected" class="text-danger required-before">
        This tab can not be seen, because inquiries has different groups
      </div>
    </div>
    <div [hidden]="!tabArray[1]" class="my-4 ">
      <div class="mt-4 nominate-ownership1 ">
        <div class="mb-1">Assigned group</div>
        <div class="Owner-checkbox mobileView-ownership">
          <app-checkbox-list [searchIcon]="true" [placeholder]="'Quick Search...'" [name]="'allGroup'" [items]="allGroups"
            [listStyle]="{'height':'158px'}" [controlType]="'checkbox'" [resetBehaviour]="true" (onItemSelect)="getSelectedItems($event)"></app-checkbox-list>
        </div>
      </div>
      <div class="w-100 pull-left">
        <form #nominateOwnership="ngForm">
          <div class="d-flex flex-column align-items-start mt-4 pt-2">
            <div class="t-header required">Reason</div>
            <div class="d-flex flex-row w-75 align-items-center nominate-holder mobileView-ownership">
              <div>
                <textarea rows="4" cols="50" name="nominateOwner" [(ngModel)]="nominateOwner"></textarea>
              </div>
            </div>
          </div>
          <div *ngIf="nominateOwnershipValidation" class="text-danger required-before">Please uncheck Nominate
            Ownership to select Take Ownership</div>
          <div *ngIf="isReasonBlank" class="text-danger required-before">Please provide a reason to
            proceed</div>
        </form>
      </div>
    </div>
  </div>
  <p-footer>
    <div class="d-flex flex-row flex-wrap bottom-button-holder px-3 pb-2 pt-3 justify-content-end mobile-center-button ownership-footer">
      <button type="submit" class="btn search-btn mr-4 mobile-btn" (click)="onSubmit()">Submit</button>
      <button type="reset" class="btn clear-btn mobile-btn" (click)="resetFields()">Reset</button>
    </div>
  </p-footer>
</p-dialog>
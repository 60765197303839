<div class="search-list" (click)="disableContextMenu()">
  <div class="input-group">
    <input type="text" #myInput placeholder='{{placeholder}}' (input)="filterItem(myInput.value)">
    <div class="input-group-btn searchBox">
      <button class="btn btn-default" type="submit">
        <img src="assets/core/layout/header/search_white.svg" style="width:18px;height:18px;" alt="Search">
      </button>
    </div>
  </div>
  <ng-scrollbar class="my-scrollbar" [ngStyle]="listStyle">
    <ul class="list-disp" oncontextmenu="return false;">
      <li *ngFor="let item of filteredItems" class="mb-2 show-item">
        <span><a id={{item.name}} class='item-open' (click)="itemSelected($event)"
            (contextmenu)="onRightClick($event)">{{item.label}}</a></span>
        <span *ngIf="item.count" class="custom-badge pull-right">{{item.counts !== undefined? item.counts:
          item.count}}</span>
      </li>
    </ul>
  </ng-scrollbar>
  <div *ngIf="contextmenu==true">
    <app-search-list-contextmenu [x]="contextmenuX" [y]="contextmenuY" [target]="itemId"></app-search-list-contextmenu>
  </div>
</div>
<!-- Send User Notification popup Start-->
<p-dialog [(visible)]="isOpen" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'"
  [styleClass]="'attachmentModal'" [closable]="false" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true"
  [style]="{'max-width':'535px','width':'40%'}">
  <p-header>
    <div class="d-flex flex-row align-items-center justify-content-between attachment-header">
      <h6 class="attachment-title">Send User Notification</h6>
      <button type="button" (click)="cancelSendUSerNotifiactionDialog()" class="d-flex flex-row close qma-text">
    <img src="assets/Inbox/close_icon_u73.svg" alt="Close">
    <h6>CLOSE</h6>
</button>
    </div>
  </p-header>
  <form class="form-horizontal" [formGroup]="sendUserNotificationForm">
    <div id="createnew" class="mt-1">
      <div class="d-flex flex-row align-items-start">
        <label class="lblTopContact" style="padding-left:10%">Inquiry Id:</label>
        <div class="top-contact-form">
          <input type="text" placeholder="Start typing..." size="25" formControlName="inquiryid" class="w-50 t-box inp-txt-mng group-details-input-mng"
          />
          <span *ngIf="sendUserNotificationForm.controls.inquiryid.errors && (sendUserNotificationForm.controls.inquiryid.dirty || sendUserNotificationForm.controls.inquiryid.touched)"
            class="mobile-view-cross">
            <img src="assets/newMessage/error.svg" class="error" title="Inquiry Id is Required" alt="Error">
        </span>
        </div>
      </div>
      <div class="d-flex flex-row align-items-start">
        <label class="lblTopContact">Conversation Id:</label>
        <div class="top-contact-form">
          <input type="text" placeholder="Start typing..."  size="25" formControlName="convid" class="w-50 t-box inp-txt-mng group-details-input-mng"
          />
          <span *ngIf="sendUserNotificationForm.controls.convid.errors && (sendUserNotificationForm.controls.convid.dirty || sendUserNotificationForm.controls.convid.touched)"
          class="mobile-view-cross">
          <img src="assets/newMessage/error.svg" class="error" title="Conversation Id is Required" alt="Error">
        </span>
        </div>
      </div>
      <div class="d-flex flex-row align-items-start">
        <label class="lblTopContact" style="padding-left:12%">Subject:</label>
        <div class="top-contact-form">
          <input type="text" placeholder="Start typing..."  size="25" formControlName="subject" class="w-50 t-box inp-txt-mng group-details-input-mng"
          />
        </div>
      </div>
      <div class="d-flex flex-row align-items-start">
        <label class="lblTopContact" style="padding-left:16%">From:</label>
        <div class="top-contact-form">
          <input type="text" placeholder="Start typing..."  size="25" formControlName="from" class="w-50 t-box inp-txt-mng group-details-input-mng" />
        </div>
      </div>
      <div class="d-flex flex-row align-items-start">
        <label class="lblTopContact" style="padding-left:20%">To:</label>
        <div class="top-contact-form">
          <input type="text" placeholder="Start typing..." size="25" formControlName="to" class="w-50 t-box inp-txt-mng group-details-input-mng"
          />
        </div>
      </div>
      <div class="d-flex flex-row align-items-start">
        <label class="msg" style="padding-left:2%">* If 'To' recipients are not specified, notification will sent to all active usrs:</label>
      </div>
    </div>
    <p-footer>
      <div class="d-flex flex-row flex-wrap bottom-button-holder px-3 footer-buttons-holder justify-content-end">
        <button type="submit" [disabled]="!sendUserNotificationForm.valid" class="btn search-btn mr-4" (click)="sendUserNotification()">Continue</button>
        <button type="reset" class="btn clear-btn" (click)="cancelSendUSerNotifiactionDialog()">Cancel</button>
      </div>
    </p-footer>
  </form>
</p-dialog>
<!-- Send User Notification popup End-->
<p-confirmDialog header="Confirm Action" width="425" rejectIcon="No" acceptIcon="No" [appendTo]="'body'"></p-confirmDialog>
<p-dialog [(visible)]="displayError" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'"
[styleClass]="'contextMenuModal successModal ModelClose'" [closable]="true" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true"
[style]="{width: '30%'}">
<p-header>
    <div class="d-flex flex-row align-items-center justify-content-between">
        <h6 class="success-title">Information</h6>
    </div>
</p-header>
<div class="success-msg" [innerHTML]="errMsg"></div>
</p-dialog>

import { Injectable } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PersonalEmailService {
  private selectedItemId: string;

  constructor(private http: HttpClient) { }

  getFiles(fileName: string, prop: string) {
    return this.http.get(fileName).toPromise().then(res => <TreeNode[]>res[prop]);
  }

  setSelectedFolder(folderNodeId: string): void {
    this.selectedItemId = folderNodeId;
  }

  getSelectedFolder(): string {
    return this.selectedItemId
  }
}

export class ContextMenuModel{
    name:string;
    subMenu?:Array<ContextSubMenuModel>;
    icon?:any;
    constructor(name:string, subMenu?:Array<ContextSubMenuModel>, icon?:any){
        this.name = name;
        this.subMenu = subMenu;
        this.icon = icon;
    }
}

export class ContextSubMenuModel{
    name:string;
    action:Function;
    icon?:any;
    constructor(name:string, action?:Function, icon?:any){
        this.name = name;
        this.action = action;
        this.icon = icon;
    }
}

export class RequestObject {
    inquiryIds:Array<number>;
    groupIds:Array<string>;    
    isMultiGroupAction:boolean;
    commonGroupId:any;
    groupName:string;    
    arrayinquiryIds:Array<number>;
    draftIdList:Array<number>;
    ewsIdList: Array<number>;

    constructor(inquiryIds:Array<number>,
        groupIds:Array<string>,        
        isMultiGroupAction:boolean, 
        commonGroupId:any,
        groupName:string,
        arrayinquiryIds:Array<number>,
        draftIdList:Array<number>,
        ewsIdList: Array<number>     
        ) {
        this.inquiryIds = inquiryIds;
        this.groupIds = groupIds;       
        this.isMultiGroupAction = isMultiGroupAction;
        this.commonGroupId = commonGroupId;
        this.groupName = groupName;
        this.arrayinquiryIds=arrayinquiryIds;
        this.draftIdList=draftIdList;
        this.ewsIdList = ewsIdList;
    }
}

export class AssignInquirySource {
    inquiryIds:Array<number>;
    groupIds:Array<string>;
    inquirySource:string;
    isMultiGroupAction:boolean;
    commonGroupId:any;

    constructor(inquiryIds:Array<number>,groupIds:Array<string>, inquirySource:string, isMultiGroupAction:boolean, commonGroupId:any) {
        this.inquiryIds = inquiryIds;
        this.groupIds = groupIds;
        this.inquirySource = inquirySource;
        this.isMultiGroupAction = isMultiGroupAction;
        this.commonGroupId = commonGroupId;
    }
}

export class AssignInquirySubStatus {
    inquiryIds:Array<number>;
    groupIds:Array<string>;
    inquirySubStatus:string;
    isMultiGroupAction:boolean;
    commonGroupId:any;

    constructor(inquiryIds:Array<number>,groupIds:Array<string>, inquirySubStatus:string, isMultiGroupAction:boolean, commonGroupId:any) {
        this.inquiryIds = inquiryIds;
        this.groupIds = groupIds;
        this.inquirySubStatus = inquirySubStatus;
        this.isMultiGroupAction = isMultiGroupAction;
        this.commonGroupId = commonGroupId;
    }
}

export class SaveAssignUser {
    inquiryIds:Array<number>;    
    assignToUserId:string;
    groupName:string;

    constructor(inquiryIds:Array<number>, assignToUserId:string, groupName:any) {
        this.inquiryIds = inquiryIds;
        this.assignToUserId = assignToUserId;
        this.groupName = groupName;        
    }
}

export class AssignProcessingRegion {
    inquiryIds:Array<number>;    
    groupIds:Array<string>;
    processingRegion:string;
    isMultiGroupAction:boolean;
    commonGroupId:any;

    constructor(inquiryIds:Array<number>, groupIds:Array<string>, processingRegion:string, isMultiGroupAction:boolean, commonGroupId:any) {
        this.inquiryIds = inquiryIds;
        this.groupIds = groupIds;
        this.processingRegion = processingRegion;
        this.isMultiGroupAction = isMultiGroupAction;
        this.commonGroupId = commonGroupId;    
    }
}

export class AssignRequestType {
    inquiryIds:Array<number>;    
    requestType:string;    
    groupName:string;

    constructor(inquiryIds:Array<number>, requestType:string, groupName:string) {
        this.inquiryIds = inquiryIds;
        this.requestType = requestType;
        this.groupName = groupName;
    }
}

export class AssignRootCause {
    inquiryIds:Array<number>;    
    rootCause:string;    
    groupName:string;

    constructor(inquiryIds:Array<number>, rootCause:string, groupName:string) {
        this.inquiryIds = inquiryIds;
        this.rootCause = rootCause;
        this.groupName = groupName;
    }
}

export class AssignTag {
    folderName:string;
    inquiryIds:Array<number>;    
    groupName:string;

    constructor(folderName:string, inquiryIds:Array<number>, groupName:string) {
        this.folderName = folderName;
        this.inquiryIds = inquiryIds;
        this.groupName = groupName;
    }
}

export class AssignGfid{
    inquiryIds:Array<number>;    
    groupIds:Array<string>;
    gfpid:string;
    gfpidName:string;
    gfcid:string;
    gfcidName:string;
    isMultiGroupAction:boolean;
    commonGroupId:any;
    skAccountNo? : string;
    branch? : string;

    constructor(inquiryIds:Array<number>, groupIds:Array<string>, gfpid:string, gfpidName:string, gfcid:string, gfcidName:string, isMultiGroupAction:boolean, commonGroupId:any, skAccountNo? : string, branch? : string) {
        this.inquiryIds = inquiryIds;
        this.groupIds = groupIds;
        this.gfpid = gfpid;
        this.gfpidName = gfpidName;
        this.gfcid = gfcid;
        this.gfcidName = gfcidName;
        this.isMultiGroupAction = isMultiGroupAction;
        this.commonGroupId = commonGroupId;
        this.skAccountNo = skAccountNo;
        this.branch = branch;
    }
}

export class AssignLinkException {
    inquiryIds:Array<number>;    
    groupIds:Array<string>;
    inquiryInfo:any;
    isMultiGroupAction:boolean;
    commonGroupId:any;
    ExceptionId:any;

    constructor(inquiryIds:Array<number>, groupIds:Array<string>, inquiryInfo:any, isMultiGroupAction:boolean, commonGroupId:any, ExceptionId:any) {
        this.inquiryIds = inquiryIds;
        this.groupIds = groupIds;
        this.inquiryInfo = inquiryInfo;
        this.isMultiGroupAction = isMultiGroupAction;
        this.commonGroupId = commonGroupId;
        this.ExceptionId = ExceptionId;
    }
}

export class LockUnlockInquiry {   
    inquiryIds:Array<number>;    
    groupIds:Array<string>;
    action:string;
    isMultiGroupAction:boolean;
    commonGroupId:string;

    constructor(inquiryIds:Array<number>, groupIds:Array<string>,action,isMultiGroupAction:boolean,commonGroupId) {
        this.action = action;
        this.inquiryIds = inquiryIds;
        this.groupIds = groupIds;
        this.isMultiGroupAction = isMultiGroupAction;
        this.commonGroupId=commonGroupId;
    }
}

export class ReAgeInquiry{
    inquiryIds:Array<number>;    
    groupIds:Array<string>;
    action:string;
    isMultiGroupAction:boolean;
    commonGroupId:string;

    constructor(inquiryIds:Array<number>, groupIds:Array<string>,action,isMultiGroupAction:boolean,commonGroupId) {
        this.action = action;
        this.inquiryIds = inquiryIds;
        this.groupIds = groupIds;
        this.isMultiGroupAction = isMultiGroupAction;
        this.commonGroupId=commonGroupId;
    }
}


export class SnoozeInquiry {
    inquiryIds: Array<number>;
    groupIds:Array<string>;
    action:string;
    isMultiGroupAction:boolean;
    commonGroupId:string;
    snooze: any;
    constructor(inquiryIds: Array<number>,groupIds:Array<string>, action, isMultiGroupAction:boolean, commonGroupId:string, snooze: any) {
        this.inquiryIds = inquiryIds;
        this.groupIds = groupIds;
        this.action = action;
        this.isMultiGroupAction = isMultiGroupAction;
        this.commonGroupId = commonGroupId;
        this.snooze = snooze;
    }
}
export class ReadUnReadConv {   
    inquiryIds:Array<number>;    
    readFlag:string;   
    convIds:Array<number>;
    inqGrpId: Array<number>;

    constructor(inquiryIds:Array<number>, readFlag:string, convIds:Array<number>, inqGrpId:Array<number>)
    {
        this.inquiryIds=inquiryIds;
        this.readFlag=readFlag;
        this.convIds=convIds;
        this.inqGrpId = inqGrpId;
    }
}
export class ReadUnReadInquiry {   
    inquiryIds:Array<number>;
    // C153176-5120: add 'groupIds' field
    groupIds : Array<string>; 
    readFlag:string;

    constructor(inquiryIds:Array<number>, groupIds : Array<string>, readFlag:string)
    {
        this.inquiryIds=inquiryIds;
        // C153176-5120: add 'groupIds' field
        this.groupIds = groupIds;
        this.readFlag=readFlag;
    }
}

export class deEscalateInquiry {   
    inquiryIds:Array<number>;    
    groupIds:Array<string>;
    action:string;
    isMultiGroupAction:boolean;
    commonGroupId:string;

    constructor(inquiryIds:Array<number>, groupIds:Array<string>,action,isMultiGroupAction:boolean,commonGroupId) {
        this.action = action;
        this.inquiryIds = inquiryIds;
        this.groupIds = groupIds;
        this.isMultiGroupAction = isMultiGroupAction;
        this.commonGroupId=commonGroupId;
    }
}

export class UpdatePendingInquiry {
    inquiryIds: Array<number>;
    action: string;
    assignedGroupName: string;
    type: string;
    constructor(inquiryIds: Array<number>, action: string, assignedGroupName: string, type: string) {
        this.inquiryIds = inquiryIds;
        this.action = action;
        this.assignedGroupName = assignedGroupName;
        this.type = type;
    }
}


export class DeleteDraft {   
    draftIdList:Array<number>;    
    
    constructor(draftIdList:Array<number>)
    {
        this.draftIdList=draftIdList;
    }
}

export class DeleteEWSMail {
    ewsIdList: Array<number>;
    constructor(ewsIdList: Array<number>) {
        this.ewsIdList = ewsIdList;
    }
}

export class FollowUpInquiry {   
    inquiryIds:Array<number>;    
    action:string;
    followUp:any;
    groupIds:Array<string>;  
    isMultiGroupAction:boolean;
    commonGroupId:number;

    constructor(inquiryIds:Array<number>, action:string,followUp:any, groupIds:Array<string>, isMultiGroupAction:boolean, commonGroupId:number)
    {
        this.inquiryIds=inquiryIds;
        this.action=action;
        this.followUp=followUp;
        this.groupIds=groupIds;
        this.isMultiGroupAction=isMultiGroupAction,
        this.commonGroupId=commonGroupId;
    }
}

export class UnFollowInquiry {   
    inquiryIds:Array<number>;    
    action:string;
    groupIds:Array<string>;  
    isMultiGroupAction:boolean;
    commonGroupId:number;

    constructor(inquiryIds:Array<number>, action:string, groupIds:Array<string>, isMultiGroupAction:boolean, commonGroupId:number)
    {
        this.inquiryIds=inquiryIds;
        this.action=action;
        this.groupIds=groupIds;
        this.isMultiGroupAction=isMultiGroupAction,
        this.commonGroupId=commonGroupId;
    }
}

export class RejectInquiry {   
    inquiryIds:Array<number>;    
    action:string;  
    assignedGroupName:string;
    type:string;
    nominationRejectionReason:string;

    constructor(inquiryIds:Array<number>, action:string, assignedGroupName:string, type:string, nominationRejectionReason?:string)
    {
        this.inquiryIds=inquiryIds;
        this.action=action;
        this.assignedGroupName=assignedGroupName;
        this.type=type;
        this.nominationRejectionReason=nominationRejectionReason;
    }
}

export class UpdateMemo {   
    inquiryIds:Array<number>;
    groupIds:Array<string>;  
    memo:string;

    constructor(inquiryIds:Array<number>, groupIds:Array<string>, memo:string)
    {
        this.inquiryIds=inquiryIds;        
        this.groupIds=groupIds;
        this.memo=memo;
    }
}

export class TakeOwnership {   
    inquiryIds:Array<number>;
    groupIds:Array<string>;
    assingedOwner?:string;
    action: string;
    constructor(inquiryIds:Array<number>, groupIds:Array<string>,action, assingedOwner?:string)
    {
        this.inquiryIds=inquiryIds;
        this.groupIds=groupIds;
        this.assingedOwner = assingedOwner;
        this.action = action;
    }
}

export class Ownership {   
    inquiryIds:Array<number>;
    groupIds:Array<string>;
    assingedOwner:string;
    // nominatingGroupId:Array<string>;
    nominatedGroupId:Array<string>;
    reason:string;
    constructor(inquiryIds:Array<number>, groupIds:Array<string>,nominatedGroupId?:Array<string>,reason?:any)
    {
        this.inquiryIds=inquiryIds;
        this.groupIds=groupIds;
        // [C170665-3131] Nominate onwership not working when user doesnt have access to nominated group
        // this.nominatingGroupId=nominatingGroupId;
        this.nominatedGroupId = nominatedGroupId;
        this.reason = reason;
    }
}

export class Reopen {   
    inquiryIds:Array<number>;
    from:Array<string>;  
    allInqGroupIds:Array<string>;
   
    constructor(inquiryIds:Array<number>, from:Array<string>,allInqGroupIds:Array<string>)
    {
        this.inquiryIds=inquiryIds;        
        this.from=from;
        this.allInqGroupIds=allInqGroupIds;        
    }
}

export class MarkAsNonInquiry {
    inquiryIds: Array<number>;
    action: string;
    nonInquiry: any;
    groupIds: Array<string>;
    isMultiGroupAction: boolean;
    commonGroupId: number;

    constructor(inquiryIds: Array<number>, action: string, nonInquiry:any, groupIds: Array<string>, isMultiGroupAction: boolean, commonGroupId: number) {
        this.inquiryIds = inquiryIds;
        this.action = action;
        this.nonInquiry = nonInquiry;
        this.groupIds = groupIds;
        this.isMultiGroupAction = isMultiGroupAction;
        this.commonGroupId = commonGroupId;
    }
}

export class MarkAsInquiry {
    inquiryIds: Array<number>;
    action: string;
    groupIds: Array<string>;
    isMultiGroupAction: boolean;
    commonGroupId: number;

    constructor(inquiryIds: Array<number>, action: string, groupIds: Array<string>, isMultiGroupAction: boolean, commonGroupId: number) {
        this.inquiryIds = inquiryIds;
        this.action = action;
        this.groupIds = groupIds;
        this.isMultiGroupAction = isMultiGroupAction;
        this.commonGroupId = commonGroupId;
    }
}
<div class="search-list" (click)="disableContextMenu()">
  <div class="d-flex align-items-center mb-2 mt-2 createChatRoom" (click)="displayChatRoomPopup()">
        <img src="assets/core/layout/nav/add_icon.svg" >
        <span><ins>Create Chat Room</ins></span>
    </div>
  <div class="input-group">
        <input type="text" #myInput placeholder='Search chat room...' (input)="filterItem(myInput.value)">
        <div class="input-group-btn searchBox">
        <button class="btn btn-default" type="submit">
          <img src="assets/core/layout/header/search_white.svg" style="width:18px;height:18px;">
        </button>
        </div>
    </div>
  <ng-scrollbar class="my-scrollbar"
      [ngStyle]="listStyle">
  <ul *ngIf="this.chatRooms && this.chatRooms.length>0" oncontextmenu="return false;">
<li style="cursor: pointer;" *ngFor="let room of this.filteredChatRooms">
  <a (click)="openSymphonyView(room['id'])" (contextmenu)="onRightClick($event,room['id'],room['name'])" id="{{room['id']}}">{{room['name']}}</a>
</li>
  </ul>
</ng-scrollbar>
</div>
<div *ngIf="contextmenu" class="contextmenu" [ngStyle]="{'left.px': contextmenuX, 'top.px': contextmenuY}">
        <ul class="popup-menu-items">
            <li class="popup-menu-item"><a class="item-edit" (click)="openAddMemberPopup()">Add Member</a></li>
            <li class="popup-menu-item"><a class="item-edit" (click)="openAddMemberPopup()">Remove Member</a></li>
            <li class="popup-menu-item"><a class="item-edit" (click)="removeYourSelf()">Remove yourself</a></li>
        </ul>
    </div>
<!-- added to create chat room */ -->
<p-dialog [(visible)]="displayChatRoomPopUp" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'" [styleClass]="'symphonyCreateChatRoom'"
    [closable]="false" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" [style]="{'width':'44%','height':'400px'}">
    <p-header>
        <div class="d-flex flex-row align-items-center justify-content-between mx-2 mt-1">
            <h6 class="gfid-title" style="font-weight: bold;">Create Chat Room</h6>
        </div>
    </p-header>
    <div class="d-flex flex-row align-items-start" style="width: 38.6em">
        <label for="to" class="input pr-4"><span>Name(required):</span></label>
        <input id="input" type="text" size="30" pInputText [(ngModel)]="inputChatRoomName">
              </div>

    <div class="d-flex flex-row align-items-start pt-4" style="width: 38.6em">
            <p-radioButton name="group" value="soeId" label="" [styleClass]="'radioButton'" [(ngModel)]="memberSelectionType"></p-radioButton>
    <label for="to" class="input pr-4"><span>Add Members by SOEID:</span></label>
    <p-autoComplete [(ngModel)]="userList" [suggestions]="filteredToUsersMultiple" (completeMethod)="filterUsers($event)"
    [minLength]="1" field="text" [multiple]="true" [styleClass]="'symphonyAutoComplete'"
            ></p-autoComplete>
          </div>

    <div class="d-flex flex-row align-items-start pt-4" style="width: 38.6em">
            <p-radioButton name="group" value="group" label="" [styleClass]="'radioButton'" [(ngModel)]="memberSelectionType"></p-radioButton>
        <label for="from"  class="input pr-4">Add Members by Group Name: </label>
        <p-dropdown [styleClass]="'prime-dd-override group-details-width'" class="drop-down group-details-inp drop-admin" optionLabel="name"
            [(ngModel)]="selectedGroup" [options]="selectedGroupList">
        </p-dropdown>
    </div>
    <p-footer>
        <div class="d-flex flex-row flex-wrap bottom-button-holder px-3 pb-2 pt-3 justify-content-end">
            <button type="button" class="btn save-btn mr-4" (click)="createChatRoom()">Submit</button>
            <button type="button" class="btn clear-btn" (click)="cancelPopup()">Cancel</button>

        </div>
    </p-footer>

</p-dialog>

<!-- popup to Add Member */ -->
<p-dialog [(visible)]="displayAddMemberPopUp" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'" [styleClass]="'symphonyCreateChatRoom'"
    [closable]="false" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" [style]="{'width':'44%','height':'400px'}">
    <p-header>
        <div class="d-flex flex-row align-items-center justify-content-between mx-2 mt-1">
            <h6 class="gfid-title" style="font-weight: bold;">Add Member to {{selectedRoomName}}</h6>
        </div>
    </p-header>

    <div class="d-flex flex-row align-items-start pt-4" style="width: 38.6em">
    <label for="to" class="input pr-4"><span>Add Members by SOEID:</span></label>
    <p-autoComplete [(ngModel)]="memberToBeAdded" [suggestions]="filteredToUsersMultiple" (completeMethod)="filterUsers($event)"
    [minLength]="1" field="text" [multiple]="false" [styleClass]="'symphonyAutoComplete'"
            ></p-autoComplete>
          </div>

    <p-footer>
        <div clNameass="d-flex flex-row flex-wrap bottom-button-holder px-3 pb-2 pt-3 justify-content-end">
            <button type="button" class="btn save-btn mr-4" (click)="actionOnChatRoom('add')">Add</button>
            <button type="button" class="btn save-btn mr-4" (click)="actionOnChatRoom('remove')">Remove</button>
            <button type="button" class="btn clear-btn" (click)="actionOnChatRoom('cancel')">Cancel</button>

        </div>
    </p-footer>

</p-dialog>

<!--to show service response message-->
<p-dialog [(visible)]="displayResponse" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'"
    [styleClass]="'contextMenuModal successModal ModelClose'" [closable]="true" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true"
    [style]="{width: '20%'}">
    <p-header>
        <div class="d-flex flex-row align-items-center justify-content-between">
            <h6 class="success-title">Success Confirmation</h6>

        </div>
    </p-header>
    <div class="success-msg">{{responseMessage}}</div>
</p-dialog>
import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ContextMenuService } from 'src/app/services/contextMenu/context-menu.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-action-popup',
  templateUrl: './action-popup.component.html',
  styleUrls: ['./action-popup.component.scss']
})
export class ActionPopupComponent implements OnInit,OnDestroy {
  display:boolean = false;  
  isMessage:boolean = true;
  contextServiceObject:any;
  subManager = new Subscription();
  headerText = "ERROR";
  labelText = "Unknown Error";
  showConfirm: boolean;
  showOK: boolean;
  showCancel: boolean;
  showYes: boolean;
  showNo: boolean;
  data: any;
  callbackName: string;
  callback;

  constructor(private contextMenuService:ContextMenuService,
    private ref: ChangeDetectorRef) {
    this.contextServiceObject = contextMenuService;
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subManager.unsubscribe();
  }

  open(header: string, label: string, showConfirm?: boolean,
        showOK?: boolean, showCancel?: boolean, showYes?: boolean, showNo?: boolean, callbackName?: string, callback?) {
    this.headerText = header? header : this.headerText;
    this.labelText = label? label: this.labelText;
    this.isMessage = true;
    this.display = true;
    this.showConfirm = showConfirm;
    this.showOK = showOK;
    this.showCancel = showCancel;
    this.showYes = showYes;
    this.showNo = showNo;
    this.callbackName = callbackName;
    this.callback = callback;
  }

  reset() {
      this.display = false;
      this.showConfirm = false;
      this.showOK = false;
      this.showCancel = false;
      this.showYes = false;
      this.showNo = false;
      this.ref.detectChanges();
    }

  confirm() {
    if (this.callback && this.callbackName && this.callbackName === "confirm") {
      this.callback();
    }
    this.reset();
  }

  ok(){
    if (this.callback && this.callbackName && this.callbackName === "ok") {
      this.callback();
    }
    this.reset();
  }

  cancel(){
    this.reset();
  }

  yes(){
    if (this.callback && this.callbackName && this.callbackName === "yes") {
      this.callback();
      this.reset();
    } else {
      this.updatePendingInquiry();
    }
  }

  no(){
    if (this.callback && this.callbackName && this.callbackName == "no") {
      this.callback();
    }
    this.reset();
  }

  hideModal() {
    this.reset();
  }

  updatePendingInquiry() {
    try {
      let sub1 = this.contextServiceObject.httpUpdatePendingInquiry(this.data).subscribe((result) => {
        if(result) {
          this.labelText = "Pending Inquiry [" + (this.data.successMessage? this.data.successMessage.slice(0, -1) : '')
            + "] changed to [" + this.data.action + "].";
          this.isMessage = false;
          this.headerText = "INFO";
          this.display = true;
        } else {
          this.labelText = result;
          this.headerText = "Update Pending Inquiry Failure";
          this.isMessage = true;
          this.showOK = true;
          this.display = true;
        }
      });
      this.subManager.add(sub1);
    } catch(err) {
      if (err && err.statusText) {
        this.headerText = "Update Pending Inquiry Failure";
      } else {
        this.labelText = 'Unknown Error';
      }
      this.isMessage = true;
      this.showOK = true;
      this.display = true;
    }
}

updateRejectInquiry() {
 try {
    let sub1 = this.contextServiceObject.httpUpdatePendingInquiry(this.data).subscribe((result) => {
      if(result) {
        this.labelText = "Inquiry [" + (this.data.successMessage? this.data.successMessage.slice(0, -1) : '')
          + "] changed to [" + this.data.action + "].";
        this.isMessage = false;
        this.headerText = "INFO";
        this.display = true;
      } else {
        this.labelText = result;
        this.headerText = "Update Reject Inquiry Failure";
        this.isMessage = true;
        this.showOK = true;
        this.display = true;
      }
    });
    this.subManager.add(sub1);
  } catch(err) {
    if (err && err.statusText) {
      this.headerText = "Update Reject Inquiry Failure";
    } else {
      this.labelText = 'Unknown Error';
    }
    this.isMessage = true;
    this.showOK = true;
    this.display = true;
  }
}

}

<span (click)="setConfirmation()">
	<img src="assets/Inbox/close_icon_u73.svg" style="opacity:5" height="20" weight="20" alt="Close">
</span>

<p-dialog [(visible)]="showPopUpDelete" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'" [styleClass]="'contextMenuModal'"
[closable]="false" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" [style]="{'max-width':'500px','width':'50%'}">
    <p-header>
    <div class="d-flex flex-row align-items-center justify-content-between mx-2 mt-1">
        <h6 class="reage-title">CONFIRM ACTION</h6>
        <!-- Jira:4703 : close button on dialog for confirmation of delete -->
        <button type="button" (click)="onCancel()" class="d-flex flex-row close qma-text">
                <img src="assets/Inbox/close_icon_u73.svg" alt="Close">               
        </button>    
    </div>
    </p-header>
    <div class="reage-body mx-2">      
        <div class="d-flex flex-row align-items-center mb-4 pb-4">
            <label>{{confirmMsgText}}</label>
        </div>
    </div>
    <p-footer>
    <div class="d-flex flex-row flex-wrap bottom-button-holder px-3 pb-2 pt-3 justify-content-end">
        <button class="btn search-btn mr-4" (click)="onOk()">Ok</button>
        <button type="reset" class="btn clear-btn" (click)="onCancel()">Cancel</button>
    </div>
    </p-footer>
</p-dialog>
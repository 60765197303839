import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { environment } from 'src/environments/environment';
import { QmaConstant } from 'src/app/constant/qma-constant';
import { UserDataService } from "src/app/services/user-data.service";
import { xml2json } from 'xml-js';

const httpFormPostOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  }),  responseType:"text" as "json"
};

@Injectable({
  providedIn: 'root'
})

export class GlobalDirectoryService {

  constructor(private http: HttpClient) {
  }

  getDetailsBySoeID(soeId:string, infoType:string, auId:string, contactInfoEndPoint: string){
    let requestObj = {};
    requestObj['soeId'] = soeId;
    requestObj['infoType'] = infoType;
    requestObj['auId'] = auId;
    requestObj['contactInfoEndPoint'] = contactInfoEndPoint;
    return this.http.post(QmaConstant.BASE_URL + '/users/getContactInfo',requestObj, httpFormPostOptions);
  }
  
  getMgmtHeirarchyBySoeID(soeId:string, infoType:string, auId:string, contactInfoEndPoint: string) {
    let requestObj = {};
    requestObj['id'] = soeId;
    requestObj['infoType'] = infoType;
    requestObj['auId'] = auId;
    requestObj['contactInfoEndPoint'] = contactInfoEndPoint;
    return this.http.post(QmaConstant.BASE_URL + '/users/getMgmtHeirarchy',requestObj, httpFormPostOptions);
  }

  getContactSuggestion(searchText:string, infoType:string, auId:string, contactInfoEndPoint: string){
    let requestObj = {};
    requestObj['searchText'] = encodeURIComponent(searchText);
    requestObj['infoType'] = infoType;
    requestObj['auId'] = auId;
    requestObj['contactInfoEndPoint'] = contactInfoEndPoint;
    return this.http.post(QmaConstant.BASE_URL + '/users/getContactSuggestion',requestObj, httpFormPostOptions);
  }

  getUserDataBySoeId(userIdList:Array<string>){
    let requestObj = {};
    requestObj['userIdList'] = userIdList;
    return this.http.post(QmaConstant.BASE_URL + '/addressBook/getUserDataBySoeId',requestObj, httpFormPostOptions);
  }

  // responseType is Text, parse XML keys from the text data and get values
  parseKey(textToParse: string, key: string) {
    if (textToParse.indexOf('<' + key + '/>') != -1) return ''; // if XML key doesnt have data it will be self closing e.g. <Name/>
    let subString = textToParse.substr(textToParse.indexOf(key) + key.length + 1, textToParse.length);
    let value = subString.substr(0, subString.indexOf('</' + key + '>'));
    return value;
  }

  parseXmlToJson(textToParse: string){
    let x = this.parseKey(textToParse, 'people');
    x = "<people>"+x+"</people>";
    var result = xml2json(x, {compact: true});
    let jsonObj = JSON.parse(result);
    return jsonObj;
  }


}

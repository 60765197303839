import { Injectable } from '@angular/core';
import { HttpClientService } from '../../utility/http-client.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { serviceUrl } from '../../utility/serviceUrl';
import { AdminModule } from '../../admin/admin.module';
import { Observable } from 'rxjs';
import { QmaConstant } from 'src/app/constant/qma-constant';


const httpFormPostOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  })
};

@Injectable()

export class GroupSetupService extends HttpClientService {

  constructor(http: HttpClient) {
    super(http);
  }

  getGroupsList() {
    let url: string = QmaConstant.BASE_URL + '/' + serviceUrl.getAllGroups;
    return this.http.post(url, {}, httpFormPostOptions);

  }

  getManagementHeirarchy() {

    let url: string = QmaConstant.BASE_URL + '/' + serviceUrl.getManagementHeirarchy;
    return this.http.post(url, {}, httpFormPostOptions);
  }

  getGroupData(formData) {
    let body = "request=" + encodeURIComponent(JSON.stringify(formData));
    let url: string = QmaConstant.BASE_URL + '/' + serviceUrl.getGroupData;
    return this.http.post(url, body, httpFormPostOptions);
  }

  getGroupsDataById(formData: any) {
    let url: string = serviceUrl.getGroupDataById;
    return this.post(url, {});
  }

  getUserData(formData) {
    let url: string = QmaConstant.BASE_URL + '/' + serviceUrl.getUserData;
    return this.http.post(url, formData, httpFormPostOptions)
  }

}
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ContextMenuService } from 'src/app/services/contextMenu/context-menu.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-update-memo',
  templateUrl: './update-memo.component.html',
  styleUrls: ['./update-memo.component.scss']
})

export class UpdateMemoComponent implements OnInit,OnDestroy {
  display:boolean = false;
  memoModel:any;
  selectedMenuItem:any;
  contextServiceObject:any;
  subManager = new Subscription();
  
  constructor(private contextMenuService:ContextMenuService) {
    this.contextServiceObject = contextMenuService;
   }
  ngOnInit() {}

  
  triggerFalseClick() {
    this.display = true;
    this.memoModel = "";
  }

  hideModal(){
    this.display = false;
  }

  updateMemo(form:any){    
    let sub1 = this.contextServiceObject.httpUpdateMemo(this.selectedMenuItem, form.value.memoModel).subscribe(res => console.log(res));;
    this.subManager.add(sub1);
    this.hideModal();
  }

  ngOnDestroy(){
    this.subManager.unsubscribe();
  }

}

import { Component, ViewChild, OnInit, Input, ElementRef, OnDestroy, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { DomSanitizer} from '@angular/platform-browser';
import {DialogModule} from 'primeng/dialog';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
//import * as $ from 'jquery';
declare var $ : any;
@Component({
  selector: 'app-attachment-dialog',
  templateUrl: './attachmentviewer.dialog.component.html',
  styleUrls: [ './attachmentviewer.dialog.component.css' ],
  providers: [DialogModule]
})
export class AttachmentViewerDialogComponent implements OnInit, OnDestroy  {
  name = 'Attachment Viewer';
  dlgref: any
  attchBytArr: any;
  display = false
  enabled = false
  objectUrl: string;
  @Input('DocUrl1') sDocUrl1;
  @ViewChild('attchcontainer') elem : ElementRef;
  @Input() isMobile: boolean = false;
  @Output() onPdfViewerOpen = new EventEmitter<string>();
  constructor(private sanitizer: DomSanitizer, private http:HttpClient, private dialogservice : DialogModule, 
    private changeDetector: ChangeDetectorRef, private titleService: Title) { 
      }
    
      displayDialog() {
        this.enabled = false;
    // now notify angular to check for updates
    this.changeDetector.detectChanges();
    // change detection should remove the component now
    // then we can enable it again to create a new instance
    this.enabled = true; 
        this.display = true;
        return true;
      }
      showDialog(sUrl: any, fileextn: string) {
        this.elem.nativeElement.innerHTML = "";
        this.http.get(sUrl, { responseType: 'blob' }).subscribe(
          (data) => {
            this.elem.nativeElement.innerHTML = "";
            this.objectUrl = URL.createObjectURL(data);
            $('#attchcontainer').officeToHtml({
              url: this.objectUrl,
              pdfSetting: {
                setLang: "",
                setLangFilesPath: "assets/include/pdf/locale/"
              },
              extension: fileextn
            });
            this.onPdfViewerDlgOpen();
       
          },
          (error) => {
            console.error('Error fetching the file:', error);
          }
        );
      }
      
    
    
  ngOnDestroy() {
    if (this.elem && this.elem.nativeElement) {
      this.elem.nativeElement.innerHTML = "";
    }
  }
  ngOnInit() {
    const originalTitle = this.titleService.getTitle();
    const observer = new MutationObserver(() => {
    if (document.title !== originalTitle) {
          this.titleService.setTitle(originalTitle);
        }
      });
     
      observer.observe(document.querySelector('title')!, { subtree: true, characterData: true, childList: true });
    }

  /**
   *  whether the dialog is shown
   */
  isVisible() {
    return this.display;
  }

  /**
   * close the dialog
   */
  closeDialog() {
    this.display = false;
    this.changeDetector.detectChanges();
    URL.revokeObjectURL(this.objectUrl); 
    this.objectUrl='';     
  }

  /**
   *  customize styles for mobile
   */
  viewStyleObject() {
    if (!this.isMobile) {
      return {width: '800px', minWidth: '800px', height:'600px'};
    } else {
      return {width: '100%', minWidth: '100%', height:'calc(100vh - 66px)'};
    }
  }

  /**
   *  customize styles for mobile
   */
  contentStyleObject() {
    if (!this.isMobile) {
      return {overflow: 'hidden', width:'100%'};
    } else {
      return {overflow: 'auto', width:'100%'};
    }
  }
  onPdfViewerDlgOpen() {
     this.onPdfViewerOpen.emit('onPdfViewerOpen');
  }
} 

import { QmaConstant } from 'src/app/constant/qma-constant';
export class PiwikProUtils {

  static initalizePiwikPro(pivotProConfigEnabled, renderer, document) {
    try {
      if (pivotProConfigEnabled === true) {
        var ENV = PiwikProUtils.getEnvironmentDetails();
        if (!window['GaTracker']) {
          this.loadGaTrackerScript(ENV, renderer, document);
        }
        setTimeout(() => {
          if (window['piwikTracking']) {
            window['piwikTracking'].init(QmaConstant.QMA_IDSITE, ENV);
          }
        }, 1000);
      }
    } catch (ex) {
      console.log("Error while initialising piwik tracking", ex);
    }
  }
  static loadGaTrackerScript(ENV, renderer, document) {
    let script = renderer.createElement('script');
    script.type = "text/javascript";
    if (ENV === "PROD") {
      script.src = "xxxx";
    } /*else {
      script.src = "xxxx";
      script.src = "https://XXXXX";
    }*/ else {
      script.src = "https://XXXXX";
    }
    script.id = "GoTracker";
    renderer.appendChild(document.head, script);
  }

  static getEnvironmentDetails(): any {
    var ENV = "PROD";
    const origin = document.location.origin;
    // if the origin contains port number. It is local or dev server according to current configuration.
    if (origin.indexOf(':') > -1 || origin.indexOf('qa') > -1) {
      ENV = "UAT";
    }
    if (origin.indexOf('uat') > -1) {
      ENV = "UAT";
    }
    return ENV;
  }
  static trackJsonDataForTab(pivotProConfigEnabled, loginId, tabname, menuCode) {
    try {
      if (pivotProConfigEnabled === true) {
        var trackingJsonData: any = {};
        var trackingData: any = {};
        var menuNavigationPath = menuCode;
        trackingData.menuNavigationPath = menuNavigationPath;
        trackingData.loginId = loginId;
        trackingData.idSite = QmaConstant.QMA_IDSITE;
        trackingJsonData.trackingData = trackingData;

        var customVariable: any = [];
        customVariable.push({ 'trackingSource': 16 });
        customVariable.push({ 'menuCode': menuCode });
        customVariable.push({ 'tabName': tabname });
        trackingJsonData.customVariable = customVariable;
        window['piwikTracking'].trackData(0, trackingJsonData);
      }
    } catch (ex) {
      console.log("Error while sending track data to piwik tracking", ex);
    }
  }

  static trackJsonDataForPage(loginId, menuCode) {
    try {
      var trackingJsonData: any = {};
      var trackingData: any = {};
      var menuNavigationPath = menuCode;
      trackingData.menuNavigationPath = menuNavigationPath;
      trackingData.loginId = loginId;
      trackingData.idSite = QmaConstant.QMA_IDSITE;
      trackingJsonData.trackingData = trackingData;

      var customVariable: any = [];
      customVariable.push({ 'trackingSource': 1 });
      customVariable.push({ 'menuCode': menuCode });
      trackingJsonData.customVariable = customVariable;
      window['piwikTracking'].trackData(0, trackingJsonData);
    } catch (ex) {
      console.log("Error while sending track data to piwik tracking", ex);
    }
  }
  static trackJsonDataForEvent(pivotProConfigEnabled, loginId, menuCode, eventCategory, eventAction, eventLabel, eventValue) {
    try {
      if (pivotProConfigEnabled === true) {
        this.trackJsonDataForPage(loginId, eventCategory);
        var trackingJsonData: any = {};
        var trackingData: any = {};
        trackingData.idSite = QmaConstant.QMA_IDSITE;
        trackingData.menuNavigationPath = menuCode;
        trackingData.loginId = loginId;
        trackingJsonData.trackingData = trackingData;

        var customVariable: any = [];
        var properties: any = {};
        properties.eventCategory = menuCode;
        properties.eventAction = eventAction;
        properties.eventLabel = eventLabel;
        properties.eventValue = eventValue;
        customVariable.push({ 'properties': properties });
        customVariable.push({ 'trackingSource': 100 });
        customVariable.push({ 'menuCode': menuCode });

        trackingJsonData.customVariable = customVariable;
        window['piwikTracking'].trackData(0, trackingJsonData);
      }
    } catch (ex) {
      console.log("Error while sending track data to piwik tracking", ex);
    }
  }
}
import { timer as observableTimer, BehaviorSubject, timer, Subscription } from 'rxjs';
import { Injectable, OnInit } from '@angular/core';
import { UserDataService } from './user-data.service';
import { OAuthService, OAuthEvent } from 'angular-oauth2-oidc';
import { Router } from '@angular/router';

@Injectable()
export class SessionTimeOut implements OnInit{

  private sessionTimer = 0;
  private sessionAlertTimer = 0;
  private userSessionTimeout = 0;

  sessionWarning = new BehaviorSubject<boolean>(false);
  showSessionRefreshAlert = this.sessionWarning.asObservable();

  private sessionTimedoutAlert = new BehaviorSubject<boolean>(false);
  showSessionTimedoutAlert = this.sessionTimedoutAlert.asObservable();
  private smLogOutUrl: string;
  private isRefreshing = false;
  private timerSubscription: Subscription | null = null;
  private isLogoutFlow: boolean = false;

  constructor(private userDataService: UserDataService, private oauthService: OAuthService, private router: Router) {
  }
  ngOnInit(): void {
    
    // Token expiration handling
    this.oauthService.events.subscribe(event => {
      if (event.type === 'token_expires' && !this.isLogoutFlow) {
        console.log('Token is about to expire. Renewing...');
        this.oauthService.silentRefresh()
          .then(() => {
            console.log('Silent refresh succeeded. Token renewed successfully.');
            if(this.isLogoutFlow){
              localStorage.clear();
              sessionStorage.clear();
              console.log('Cleared data as user is logged out');
            }
          })
          .catch(err => {
            console.error('Silent refresh failed. Logging out...', err);
            // this.oauthService.logOut(); 
          });
      }
    });

    // Log token expiry details
    const token = this.oauthService.getAccessToken();
    if (token) {
      const decodedToken = this.oauthService.getIdentityClaims() as any;
      const expiry = decodedToken.exp * 1000; // Convert to milliseconds
      console.log('Token expires at:', new Date(expiry));
    }
  }

  initializeSessionConfig(sessionTimeoutInterval: number, sessionAlertInterval: number, smLogOutUrl: string) {
    this.sessionTimer = sessionTimeoutInterval || 86400; 
    this.sessionAlertTimer = sessionAlertInterval || 300; 
    this.smLogOutUrl = smLogOutUrl || '';
    this.userSessionTimeout = sessionTimeoutInterval || 86400;
  }

  startSessionTimer() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }

    this.timerSubscription = observableTimer(0, 1000).subscribe(() => {
      --this.sessionTimer;
      if (this.sessionTimer === this.sessionAlertTimer) {
        this.sessionWarning.next(true);
      }
      if (this.sessionTimer <= 0) {
        this.cvLogout();
      }
    });
  }

  stopSessionTimer(){
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
      this.timerSubscription = null;
    }
  }

  resetCounter() {
    console.log('Resetting session timer.');
    this.sessionTimer = this.userSessionTimeout || 86400;
  }

  changeWarning(warn: boolean) {
    this.sessionWarning.next(warn);
  }

  changeAlert(display: boolean) {
    this.sessionTimedoutAlert.next(display);
  }
  refresh() {
    if (this.isRefreshing) {
      console.log('Silent refresh already in progress. Skipping...');
      return;
    }
    this.isRefreshing = true;
    this.oauthService
      .silentRefresh()
      .then(() => {
        console.log('Silent refresh successful');
        this.isRefreshing = false;
      })
      .catch((err) => {
        console.error('Silent refresh failed:', err);
        this.isRefreshing = false;
        this.promptLogin();
      });
  }
  promptLogin() {
    this.oauthService.initLoginFlow();
  }

  isSessionTimedout() {
    return this.sessionTimer <= 0;
  }

  cvLogout(): void {
    this.isLogoutFlow=true;
    console.log('Capturing CV logout activity in DB');
    this.userDataService.logoutUserSession().subscribe({
      next: data => {
        console.log(data);
        console.log('Calling CV logout URL after capturing in DB');
        this.oauthLogout();
      },
      error: error => {
        console.log(error);
        console.log('Calling CV logout URL without capturing in DB');
        this.oauthLogout();
      }
    });
  }

  oauthLogout(): void{
    this.oauthService.stopAutomaticRefresh(); 
    this.oauthService.logOut(true);  
    localStorage.clear();
    sessionStorage.clear();
    this.stopSessionTimer()
    this.router.navigate(['/logout'])
  }
  
}

export class TextValue {
    text: string;
    label?: string;
    value: any;
    listItem?:Array<any>;
    constructor(text:string, label:string, value:any, listItem?:Array<any>) {
        this.text = text;
        this.label = label;
        this.value = value;
        this.listItem = listItem;
    }
}
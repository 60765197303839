import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-search-list',
  templateUrl: './search-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./search-list.component.scss']
})
export class SearchListComponent implements OnInit {
  @Input() placeholder: String;
  @Input() name: String;
  @Input() items: Array<object>;
  @Output() onItemSelect = new EventEmitter();
  @Input() listStyle: any;
  filteredItems: Array<object>;
  native: any;

  contextmenu = false;
  contextmenuX = 0;
  contextmenuY = 0;
  itemId = "";

  ngOnInit() {
    this.assignCopy(); //On Server call
  }

  assignCopy() {
    this.filteredItems = Object.assign([], this.items);
  }

  filterItem(value: string) {
    if (!value) {
      this.assignCopy();
    }
    this.filteredItems = Object.assign([], this.items).filter(
      item => item.name.toLowerCase().indexOf(value.toLowerCase()) > -1
    )
  }

  updateItemList(viewname, type, count) {
    if (this.items) {
      if (type === 'add') {
        let index = this.items.findIndex((item: any) => item.name === viewname);
        if (index < 0) {
          this.items.push({ name: viewname, count: count });
        }
      } else if (type === 'remove') {
        this.items = Object.assign([], this.items).filter(
          item => item.name !== viewname);
      } else {
        console.log('searchList.updateItemList: Unknown type:', type);
      }
      this.assignCopy();
    }
  }

  itemSelected(event: any) {
    this.onItemSelect.emit(event);
  }

  onRightClick(event) {
    event.preventDefault();
    this.contextmenuX = event.clientX;
    this.contextmenuY = event.clientY;
    this.itemId = event.currentTarget.id;
    this.contextmenu = true;
  }

  disableContextMenu() {
    this.contextmenu = false;
  }
}

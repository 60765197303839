import { Component, OnInit, Input, Output, EventEmitter, ViewChildren, QueryList, ElementRef, ViewChild, OnChanges, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-checkbox-list-input',
  templateUrl: './checkbox-list-input.component.html',
  styleUrls: ['./checkbox-list-input.component.scss']
})
export class CheckboxListInputComponent implements OnInit {
  @Input() placeholder: String;
  @Input() placeholderin: String;
  @Input() items: Array<object>;
  @Input() tags: Array<object>;
  @Input() listStyle: any;
  @Input() maxSelection: number = -1;
  @Input() name: string = "demo";
  @Input() search: boolean = true;
  @Input() scroll: any = 'native';
  @Input() styleClass: any = 'search-list';
  @Input() controlType: string = 'checkbox';
  @Input() searchIcon: boolean = false;
  @Output() onItemSelect = new EventEmitter();
  @Output() onMaxSelection = new EventEmitter();
  @Output() onInputChange: EventEmitter<any> = new EventEmitter<any>();
  //Added for Column chooser
  @Output() onItemChecked = new EventEmitter();
  selectedCheckBox: any;

  shown: 'native' | 'hover' | 'always' = 'native';
  @ViewChildren("checkboxes") checkboxes: QueryList<ElementRef>;

  filteredItems: Array<object>;
  filteredTags: Array<object>;
  @Input() selectedValue: Array<object> = [];
  checkState: boolean = true;
  @ViewChild('myInput') myInputRef: ElementRef;

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.assignCopy();
  }
  /* To get selected items */
  getSelection(e: any) {
    if (!this.checkMaxSelection(e)) {
      if (e.target.checked) {
        let index = this.items.findIndex((item: any) => item.label == e.target.value);
        this.selectedValue.push(this.items[index]);
      }
      else {
        let ind = this.selectedValue.findIndex((item: any) => item.value === e.target.value);
        this.selectedValue.splice(ind, 1);
      }
    }
    else {
      this.onMaxSelection.emit(this.selectedValue)
    }
    this.onItemSelect.emit(this.selectedValue);
  }
  getChangeInput(e: any, name: string) {
    //let index = this.items.findIndex((item:any) => item.name == name);
    let tagInfo = {
      tagLimit: e.target.value,
      tagName: name
    }
    //this.items[index][tagLimit] = e.target.value;
    this.onInputChange.emit(tagInfo);
  }

  updateSelectedItems(e: any) {
    let index = this.items.findIndex((item: any) => item.label == e.target.value);
    if (e.target.checked) {
      this.selectedCheckBox = this.items[index];
      this.selectedCheckBox.checkedStatus = true;
      this.onItemChecked.emit(this.selectedCheckBox);
    }
    else {
      this.selectedCheckBox = this.items[index];
      this.selectedCheckBox.checkedStatus = false;
      this.onItemChecked.emit(this.selectedCheckBox);
    }

  }

  /* TO check the max number selection */
  checkMaxSelection(e: any) {
    if (this.selectedValue.length >= this.maxSelection && e.target.checked && this.maxSelection !== -1) {
      e.target.checked = false;
      e.preventDefault();
      e.stopPropagation();
      return true;
    } else {
      return false;
    }
  }

  /* Create new copy of items not to alter current item while search */
  assignCopy() {
    this.filteredItems = Object.assign([], this.items);
  }

  assignDynamicId(_item: any, i: number) {
    return this.name + "-" + _item.label + i;
  }

  /* Filter to searched Records */
  filterItem(value: string) {
    if (!value) {
      this.assignCopy();
    }
    this.filteredItems = Object.assign([], this.items).filter(
      item => item.label.toLowerCase().indexOf(value.toLowerCase()) > -1
    )
  }

  public resetCheckList() {
    this.checkboxes.forEach((element) => {
      element.nativeElement.checked = false;
    });
    this.selectedValue.length = 0;
    this.assignCopy();
    this.onItemSelect.emit(this.selectedValue);
  }
  public resetCheckBox(e: any) {
    this.checkboxes.forEach((element) => {
      if (element.nativeElement.value == e.value) {
        element.nativeElement.checked = !element.nativeElement.checked;
      }
    });
    this.selectedValue.length = 0;
    this.assignCopy();
    this.cdr.detectChanges();
    this.onItemSelect.emit(this.selectedValue);
  }

  public getCheckedList() {
    return this.checkboxes;
  }

  public clearFilterText() {
    this.myInputRef.nativeElement.value = "";
  }

}

import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { QmaConstant } from '../constant/qma-constant';

export class AuthenticationInterceptor implements HttpInterceptor {

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Soeid is not required to be sent in header. In old UI, prod - soeid is taken from city velocity
        // for non-prod env - soeid of Pramod is taken.
        // Refer old code project communicator-ui - com.citi.icg.xsc.server.util.GenericUtility
        // public static String getPortalUserSoeid(HttpHeaders headers)

        if (request) {
            let token = sessionStorage.getItem('access_token')
            if (token) {
                request = request.clone({
                    setHeaders: {
                        'originContext': QmaConstant.BASE_URL.substring(1),
                        'Authorization': 'Bearer ' + token
                    }
                });
            }

        }
        return next.handle(request);
    }
}


export class SaveDraft {
    draftId?: any;
    to: any[];
    cc: any[];
    from: string;
    urgentFlag: string;
    subject: string;
    requestType: string;
    attachment: any[];
    action: string;
    makerCheckerRqd: boolean;
    approvalBypassed: boolean;
    token: string;
    isUserEditorPrefUpdated: boolean;
    editorFontNameId: string;
    editorFontSizeId: string;
    inquirySource: string;
    inquirySubStatus: string;
    rootCause: string;
    processingRegion: string;
    queryCount: string;
    GFPID: string;
    GFCID: string;
    GFPIDName: string;
    GFCIDName: string;
    bcc: any[];
    notes: string[];
    tag: string;
    skAccountNo? : string;
    branch? : string;
    constructor(draftId: any, to: any[], cc: any[], from: string, urgentFlag: string, subject: string, requestType: string,
        attachment: any[], action: string, makerCheckerRqd: boolean, approvalBypassed: boolean, token: string,
        isUserEditorPrefUpdated: boolean, editorFontNameId: string, editorFontSizeId: string, inquirySource: string,
        rootCause: string, processingRegion: string, tag: string, queryCount: string, GFPID: string, GFCID: string, GFPIDName: string,
        GFCIDName: string, bcc: any[], notes?: string[], inquirySubStatus?: string,skAccountNo? : string, branch? : string, ) {
        this.draftId = draftId;
        this.to = to;
        this.cc = cc;
        this.from = from;
        this.urgentFlag = urgentFlag;
        this.subject = subject;
        this.requestType = requestType;
        this.attachment = attachment;
        this.action = action;
        this.makerCheckerRqd = makerCheckerRqd;
        this.approvalBypassed = approvalBypassed;
        this.token = token;
        this.isUserEditorPrefUpdated = isUserEditorPrefUpdated;
        this.editorFontNameId = editorFontNameId;
        this.editorFontSizeId = editorFontSizeId;
        this.inquirySource = inquirySource;
        this.inquirySubStatus = inquirySubStatus;
        this.rootCause = rootCause;
        this.processingRegion = processingRegion;
        this.tag = tag;
        this.queryCount = queryCount;
        this.GFPID = GFPID;
        this.GFCID = GFCID;
        this.GFPIDName = GFPIDName;
        this.GFCIDName = GFCIDName;
        this.bcc = bcc;
        this.notes = notes;
        this.skAccountNo = skAccountNo;
        this.branch = branch;
    }
}
export class ScheduleModel extends SaveDraft {
    scheduleForLater: string;
    inquiryId: number;
    isCancelled: boolean;
    constructor(draftId: any, scheduleForLater: string, inquiryId: number, to: any[], cc: any[], from: string, urgentFlag: string, subject: string, requestType: string,
        attachment: any[], action: string, makerCheckerRqd: boolean, approvalBypassed: boolean, token: string,
        isUserEditorPrefUpdated: boolean, editorFontNameId: string, editorFontSizeId: string, inquirySource: string,
        rootCause: string, processingRegion: string, tag: string, queryCount: string, GFPID: string, GFCID: string, GFPIDName: string,
        GFCIDName: string, bcc: any[], notes?: string[], isCancelled?: boolean) { 
            super(draftId, to, cc, from, urgentFlag, subject, requestType,
                attachment, action, makerCheckerRqd, approvalBypassed, token,
                isUserEditorPrefUpdated, editorFontNameId, editorFontSizeId, inquirySource,
                rootCause, processingRegion, tag, queryCount, GFPID, GFCID, GFPIDName,
                GFCIDName, bcc, notes);
        this.scheduleForLater = scheduleForLater;
        this.inquiryId = inquiryId;
        this.isCancelled = isCancelled;
    }
     
}

export class SendInquiry {
    draftId?: any;
    to: any[];
    cc: any[];
    from: string;
    urgentFlag: string;
    subject: string;
    requestType: string;
    attachment: any[];
    inquiryAction: string;
    Resolve: boolean;
    makerCheckerRqd: boolean;
    approvalBypassed: boolean;
    token: string;
    isUserEditorPrefUpdated: boolean;
    editorFontNameId: string;
    editorFontSizeId: string;
    inquirySource: string;
    inquirySubStatus: string;
    resolveRootCause: string;
    processingRegion: string;
    tag : string;
    queryCount: string;
    GFPID: string;
    GFCID: string;
    GFPIDName: string;
    GFCIDName: string;
    bcc: any[];
    memo: string;
    parentConversation : string;
    inquiryId: number;
    suggestionConvId: number;
    followUp: string;
    scheduleTime: string;
    notes: string[];
    suggestion?: boolean; // C153176-4459 | Set flag for NLP suggestion is accepted
    selectedCLCTrades?: any[]; // C153176-4594 | CLC Selected trades should be included in request.
    clcSuggestion?: boolean; // C153176-4594 | Set flag for CLC suggestion is accepted
    viewName? : string; // C153176-5849 | Pass view name from where this action is taken care - Inbox, Sent etc.
    viewType? : string; // C153176-5849 | Pass view type from where this action is taken care - Normal or ChatView.
    symphonyStreamId?: string;
    skAccountNo? : string;
    branch? : string;
    responseEditedFlag?: boolean; 
    constructor(draftId: any, to: any[], cc: any[], from: string, urgentFlag: string, subject: string, requestType: string,
        attachment: any[], inquiryAction: string, Resolve: boolean, makerCheckerRqd: boolean, approvalBypassed: boolean, token: string,
        isUserEditorPrefUpdated: boolean, editorFontNameId: string, editorFontSizeId: string, inquirySource: string,
        resolveRootCause: string, processingRegion: string, tag: string, queryCount: string, GFPID: string, GFCID: string, GFPIDName: string,
        GFCIDName: string, bcc: any[], memo:string, parentConversation: string, inquiryId: number, suggestionConvId: number,
         followUp: string, scheduleTime: string,inquirySubStatus?: string, notes?: string[],symphonyStreamId?:string, skAccountNo? : string, branch? : string) {
        this.draftId = draftId;
        this.to = to;
        this.cc = cc;
        this.from = from;
        this.urgentFlag = urgentFlag;
        this.subject = subject;
        this.requestType = requestType;
        this.attachment = attachment;
        this.inquiryAction = inquiryAction;
        this.Resolve = Resolve;
        this.makerCheckerRqd = makerCheckerRqd;
        this.approvalBypassed = approvalBypassed;
        this.token = token;
        this.isUserEditorPrefUpdated = isUserEditorPrefUpdated;
        this.editorFontNameId = editorFontNameId;
        this.editorFontSizeId = editorFontSizeId;
        this.inquirySource = inquirySource;
        this.inquirySubStatus = inquirySubStatus;
        this.resolveRootCause = resolveRootCause;
        this.processingRegion = processingRegion;
        this.tag = tag;
        this.queryCount = queryCount;
        this.GFPID = GFPID;
        this.GFCID = GFCID;
        this.GFPIDName = GFPIDName;
        this.GFCIDName = GFCIDName;
        this.bcc = bcc;
        this.memo = memo;
        this.parentConversation = parentConversation;
        this.inquiryId = inquiryId;
        this.suggestionConvId = suggestionConvId;
        this.followUp = followUp;
        this.scheduleTime = scheduleTime;
        this.notes = notes;
        this.symphonyStreamId = symphonyStreamId;
        this.skAccountNo = skAccountNo;
        this.branch = branch;
    }
}
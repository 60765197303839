import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';

// Root Cause Headers
const AVAILABLE_ROOTCAUSES: string = "Available Root Causes";
const SELECTED_ROOTCAUSES: string = "Selected Root Causes";
const COMMON_AVAILABLE_ROOTCAUSES: string = "Common Available Root Causes";
const COMMON_SELECTED_ROOTCAUSES: string = "Common Selected Root Causes";

@Component({
  selector: 'app-reqtype-rootcause',
  templateUrl: './reqtype-rootcause.component.html',
  styleUrls: ['./reqtype-rootcause.component.scss']
})
export class ReqtypeRootcauseComponent implements OnInit, OnChanges {

  @Input() requestTypes: string[] = [];
  @Input() requestCauses: string[] = [];
  @Input() reqTypeRootCauseList: any[] = [];

  @Output() updateMapping = new EventEmitter<any[]>();

  requestTypeList: any = [];
  selectRequestType: any[];
  availableRootCauses = [];
  selectedRootCauses = [];
  originalRootCauses = [];
  requestTypeRootCauses = [];
  orgRequestTypeRootCauses = [];
  currentRequestType: string[] = [];
  isApplied: boolean = false;
  commonRootCauses: string[] = [];

  // Label
  srcHeader: string = AVAILABLE_ROOTCAUSES;
  targetHeader: string = SELECTED_ROOTCAUSES;

  constructor() { }

  ngOnInit() {  }

  ngOnChanges(changes: SimpleChanges): void {
    // Initially no request type is selected, so passing 0.
    this.updatePicklistHeaders(0);

    if (changes.reqTypeRootCauseList.currentValue) {
      this.requestTypeRootCauses = [...changes.reqTypeRootCauseList.currentValue];
      this.orgRequestTypeRootCauses = [...changes.reqTypeRootCauseList.currentValue];
    }

    if (changes.requestCauses.currentValue) {
      this.originalRootCauses = [...changes.requestCauses.currentValue];
    }

    if (changes.requestTypes.currentValue) {
      this.requestTypeList = [];
      changes.requestTypes.currentValue.forEach(requestType => {
        this.requestTypeList.push({ label: requestType, value: requestType });
      });

      if (this.requestTypeList.length > 0) {
        this.populateRootCauseWhenNoRequestType();
      }
    }
  }

  /**
   * Method to update the root causes based on request type update.
   * 
   * @param value 
   */
  onRequestTypeChange(value: any[]): void {
    if (value.length == 1) {
      this.populateRootCause(value[0]);
    }
    else if (value.length > 1) {
      this.populateCommonRootCause(value);
    }
    else {
      this.populateRootCauseWhenNoRequestType();
    }
    this.currentRequestType = value;
    this.updatePicklistHeaders(value.length);
  }

  /**
   * Method to update the Root Cause Pick list header names.
   * 
   * @param selectedCount 
   */
  updatePicklistHeaders(selectedCount: number): void {
    if (selectedCount > 1) {
      this.srcHeader = COMMON_AVAILABLE_ROOTCAUSES;
      this.targetHeader = COMMON_SELECTED_ROOTCAUSES;
    } 
    else {
      this.srcHeader = AVAILABLE_ROOTCAUSES;
      this.targetHeader = SELECTED_ROOTCAUSES;
    }
  }

  /**
   * Method to populate selected and available root causes for the selected request type.
   * 
   * @param requestType 
   */
  populateRootCause(requestType: string): void {
    if (this.originalRootCauses.length > 0 && this.requestTypeList.length > 0) {
      this.availableRootCauses = [...this.originalRootCauses];
      this.selectedRootCauses = [];
      let idx = this.requestTypeRootCauses.findIndex(reqType => reqType.requestType === requestType);
      if (idx > -1) {
        let rootcauseList = this.requestTypeRootCauses[idx].rootCause;
        this.selectedRootCauses = [...rootcauseList];
        this.selectedRootCauses.sort();
      }
      this.availableRootCauses = this.availableRootCauses.filter(rc => !this.selectedRootCauses.includes(rc));
      this.availableRootCauses.sort();
    }
  }

  /**
   * Method to populate the common selected root cause for the selected request type. 
   * Other are populated for available root causes.
   * 
   * @param requestTypes 
   */
  populateCommonRootCause(requestTypes: string[]): void {
    if (this.originalRootCauses.length > 0 && this.requestTypeList.length > 0 && requestTypes.length > 0) {
      this.availableRootCauses = [...this.originalRootCauses];
      this.selectedRootCauses = [];
      this.commonRootCauses = [];
      requestTypes.forEach((requestType, index) => {
        let idx = this.requestTypeRootCauses.findIndex(reqType => reqType.requestType === requestType);
        if (idx > -1) {
          let rootcauseList = this.requestTypeRootCauses[idx].rootCause;
          if (this.commonRootCauses.length == 0 && index == 0) {
            this.commonRootCauses = [...rootcauseList];
          }
          let commonRootCausesList = this.commonRootCauses.filter(rootcause => rootcauseList.includes(rootcause));
          this.commonRootCauses = [...commonRootCausesList];
        } // If any selected request type has no rootcause mapping, then there is no common root cause.
        else {
          this.commonRootCauses = [];
        }
      });
      this.selectedRootCauses = [...this.commonRootCauses].sort();
      this.availableRootCauses = this.availableRootCauses.filter(rc => !this.selectedRootCauses.includes(rc));
      this.availableRootCauses.sort();
    }
  }

  /**
   * Method to populate the populate root cause in selected and available if No Request Type is selected.
   */
  populateRootCauseWhenNoRequestType(): void {
    if (this.originalRootCauses.length > 0 && this.requestTypeList.length > 0) {
      this.availableRootCauses = [...this.originalRootCauses];
      this.availableRootCauses.sort();

      this.selectedRootCauses = [];
    }
  }

  /**
   * Method to update the updateReqTypeRootCauseMapping on click of apply button.
   * 
   * @param requestType 
   */
  onApply(requestTypes: string[]): void {
    // This logic should not executed if no request type is selected.
    if (requestTypes && requestTypes.length > 0) {
      if (requestTypes.length == 1) {
        let requestType = requestTypes[0];
        this.updateReqTypeRootCauseMapping(requestType, this.selectedRootCauses);
      }
      else if (requestTypes.length > 1) {
        requestTypes.forEach(requestType => {
          // Get the removed root causes.
          let removedRootCauses = this.commonRootCauses.filter(rootCause => !this.selectedRootCauses.includes(rootCause));

          let idx = this.requestTypeRootCauses.findIndex(reqType => reqType.requestType === requestType);
          let mappedRootCauses = (idx > -1) ? [...this.requestTypeRootCauses[idx].rootCause] : [];
          let rootcauseList = [...new Set([...mappedRootCauses, ...this.selectedRootCauses])];

          // Removed the Root Causes from the list.
          if (removedRootCauses && removedRootCauses.length > 0) {
            rootcauseList = rootcauseList.filter(rootCause => !removedRootCauses.includes(rootCause));
          }
          this.updateReqTypeRootCauseMapping(requestType, rootcauseList);
        });
      }
      this.isApplied = true;
      setTimeout(() => { this.isApplied = false; }, 3000);
    }
  }

  /**
   * Method to reset the request type - root cause mapping same as saved in db.
   * 
   * @param requestType 
   */
  onReset(requestType: string): void {
    let orgIdx = this.orgRequestTypeRootCauses.findIndex(reqType => reqType.requestType === requestType);
    let rootcauseList = this.orgRequestTypeRootCauses[orgIdx].rootCause;
    this.updateReqTypeRootCauseMapping(requestType, rootcauseList);

    // Revert the changes to acutal or original in UI.
    this.availableRootCauses = [...this.originalRootCauses];
    this.selectedRootCauses = [];
    this.selectedRootCauses = [...rootcauseList];
    this.availableRootCauses = this.availableRootCauses.filter(rc => !this.selectedRootCauses.includes(rc));
  }

  /**
   * Method to update the updateReqTypeRootCauseMapping.
   * 
   * @param requestType 
   * @param rootcauseList 
   */
  updateReqTypeRootCauseMapping(requestType: string, rootcauseList: any[]): void {
    let idx = this.requestTypeRootCauses.findIndex(reqType => reqType.requestType === requestType);

    if (idx > -1) { // Update the existing
      this.requestTypeRootCauses[idx].rootCause = [...rootcauseList];
    }
    else { // Add new mapping
      this.requestTypeRootCauses.push({ 'requestType': requestType, 'rootCause': rootcauseList });
    }

    // Remove the mapping if the root cause list is blank.
    this.requestTypeRootCauses = this.requestTypeRootCauses.filter(item => item['rootCause'].length > 0);

    // Let the parent know the updated request type and root cause changes
    let updatedMapping = [...this.requestTypeRootCauses];
    this.updateMapping.emit(updatedMapping);
  }
}

import { Directive, Output, HostListener, ElementRef, EventEmitter, Input } from '@angular/core';

@Directive({
  selector: '[appOuterClick]'
})
export class OuterClickDirective {

  @Output() outsideClick = new EventEmitter<MouseEvent>(); 
  @Input() appOuterClick:any;

  constructor(private elementRef: ElementRef) {}
 
  @HostListener('document:click', ['$event'])
  public onDocumentClick(event: MouseEvent): void {
    const targetElement = event.target as HTMLElement;

      // Check if the click was outside the element
      if (targetElement && !this.elementRef.nativeElement.contains(targetElement)) { 
         this.outsideClick.emit(event);
      }
  }
}

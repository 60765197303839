
<p-dialog [(visible)]="displayChatRoomPopUp" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'" [styleClass]="'symphonyCreateChatRoom'"
    [closable]="false" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" [style]="{'width':'42em'}" >
    <p-header>
        <div class="d-flex flex-row align-items-center justify-content-between mx-2 mt-1">
            <h6 class="gfid-title create-chat-popup-header-text" style="font-weight: bold;text-transform: capitalize;">Create Chat Room</h6>
        </div>
    </p-header>
    <form #newChatRoomForm="ngForm" (ngSubmit)="createChatRoom(newChatRoomForm)" (keydown.enter)="$event.preventDefault()">

    <div *ngIf="false" style="font-size: 14px;font-family:interstate;font-weight: bold;color: #ec0a0a;">
            <span >QMA is currently working on improvements to create new Symphony chat rooms from within QMA. While improvements are ongoing, please create new chat rooms from within Symphony and add QMASymphony as member. During this time users will still be able to access and interact with their chats in QMA</span>
        </div>
     <div class="d-flex flex-row align-items-start mt-3" style="width: 42.6em;">
        <label for="to" class="input pl-4 pr-4"><span>Name (required):</span></label>
        <input id="input" type="text"pInputText [(ngModel)]="inputChatRoomName" id="inputChatRoomName"  name="inputChatRoomName"
        required style="width:25.8em"
         #chatName="ngModel">
        <span class="mobile-view-cross" *ngIf="(!chatName.valid && chatName.touched) || (!chatName.valid && newChatRoomForm.submitted)">
            <img src="assets/newMessage/error.svg" class="error" title="Chat room name is required">
        </span>
     </div>

    <div class="flex-row align-items-start  control-group mob-to mobile-toccbcc" style="margin-top: 10px;">
            <label for="from" class="input pr-3" style="margin-left: 50px;margin-right: 9px;">Add Members: </label>
            <p-autoComplete [suggestions]="filteredToUsersMultiple" (completeMethod)="filterUsers($event)" [minLength]="1"
                field="text" [multiple]="true" [styleClass]="'symphonyAutoComplete override override-newmessage-autocomplete'"
                [(ngModel)]="toUsersList" (onKeyUp)="onKeyUp($event, 'to')"
                   size="10000" name="toUsersList"  [style]="{'display': 'inline-flex','height':'34px','width':'360px'}">

            </p-autoComplete>
            <p-overlayPanel *ngIf="selectedContactId?.length>0" #opContactInfo [appendTo]="'body'" [dismissable]="true" [showTransitionOptions]="'0ms'"
                [hideTransitionOptions]="'0ms'">
                <gdir-contact-details [soeId]="selectedContactId"></gdir-contact-details>
            </p-overlayPanel>

        </div>

    <p-footer>
            <div class="form-check symphony-disclaimer mt-3">
                    <input class="form-check-input" type="checkbox" value=""
                    (change)="true" #c id="chkDisclaimer" name="chkDisclaimer"
                    [(ngModel)]="disclaimer">
                    <label class="form-check-label">
                     For live updates between QMA and Symphony, {{botName}} will be added as a member of your chat group
                    </label>
             </div>
        <div class="d-flex flex-row  bottom-button-holder pb-2 pt-1
         ml-3 align-items-center justify-content-end">
            <button type="submit" class="btn save-btn mr-3"  style="width: 67px !important;height: 35px;padding: 0px;" [disabled]="!disclaimer">Submit</button>
            <button type="button" class="btn clear-btn" (click)="cancelPopup()" style="width: 67px !important;height: 35px;padding: 0px;">Cancel</button>
        </div>
    </p-footer>
</form>
</p-dialog>

<!-- popup to Add Member */ -->
<p-dialog [(visible)]="displayAddMemberPopUp" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'" [styleClass]="'symphonyCreateChatRoom'"
    [closable]="false" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" [style]="{'width':'44%','height':'400px'}">
    <p-header>
        <div class="d-flex flex-row align-items-center justify-content-between mx-2 mt-1">
            <h6 class="gfid-title" style="font-weight: bold;">Add Member to {{selectedRoomName}}</h6>
        </div>
    </p-header>

    <div class="d-flex flex-row align-items-start pt-4" style="width: 38.6em">
    <label for="to" class="input pr-4"><span>Add Members by SOEID:</span></label>
    <p-autoComplete [(ngModel)]="memberToBeAdded" [suggestions]="filteredToUsersMultiple" (completeMethod)="filterUsers($event)"
    [minLength]="1" field="text" [multiple]="false" [styleClass]="'symphonyAutoComplete'"
            ></p-autoComplete>
          </div>

    <p-footer>
        <div clNameass="d-flex flex-row flex-wrap bottom-button-holder px-3 pb-2 pt-3 justify-content-end">
            <button type="button" class="btn save-btn mr-4" (click)="actionOnChatRoom('add')">Add</button>
            <button type="button" class="btn save-btn mr-4" (click)="actionOnChatRoom('remove')">Remove</button>
            <button type="button" class="btn clear-btn" (click)="actionOnChatRoom('cancel')">Cancel</button>

        </div>
    </p-footer>

</p-dialog>

<!--to show service response message-->
<p-dialog [(visible)]="displayResponse" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'"
    [styleClass]="'contextMenuModal successModal ModelClose symphony-create-chat-model'" [closable]="true"
    [baseZIndex]="10000" [closeOnEscape]="true"
    [style]="{width: '20%','max-height':'400px',overflow:'auto'}">
    <p-header>
        <div class="d-flex flex-row align-items-center justify-content-between">
            <h6 class="success-title" *ngIf="responseMessage && responseMessage.header" >{{responseMessage.header}}</h6>
        </div>
    </p-header>
    <div class="mx-2 mt-1 success-msg" *ngIf="responseMessage.title && responseMessage.excludedMembers.length>0">
            <h6 class="gfid-title" style="font-weight: bold;" >{{responseMessage.title}}</h6>
            <ul *ngIf="responseMessage && responseMessage.excludedMembers">
                <li *ngFor="let exUsers of responseMessage.excludedMembers" class="qma-inbox-row-read">
                    {{exUsers}}
                </li>
            </ul>
    </div>
</p-dialog>
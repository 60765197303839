import { ErrorHandler, Injectable, Injector } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { ErrorService } from "./services/global-error/error.service";
import { LoggingService } from "./services/global-error/logging.service";
import { NotificationService } from "./services/global-error/notification.service";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

    constructor(private injector: Injector) { }

    handleError(error: any | HttpErrorResponse): void {
        const errorService = this.injector.get(ErrorService);
        const logger = this.injector.get(LoggingService);
        const notifier = this.injector.get(NotificationService);

        let message;
        let stackTrace;

        if (error instanceof HttpErrorResponse) {
            // Server Error
            if (error.status === 500) {
                message = errorService.getServerErrorMessage(error);
                //notifier.showError(message); // TODO: Show error when any service got failed.
            }
        }
        else {
            // Client Message
            message = errorService.getClientErrorMessage(error);
            // notifier.showError(message);
        }
        let stack = error.stack;
         if (stack && stack.indexOf('at NgScrollbar.get') < 0 && stack.indexOf("reading 'getElementRef'") < 0) {
            console.error(error);
         }
    }
}
import { Component, OnInit, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { UserDataService } from '../../services/user-data.service';
import { AppUtils } from '../../common/utility/appUtil';
import { ConfirmationService } from 'primeng/api';
import { NewMessageService } from '../../services/newMessage/new-message.service';

@Component({
  selector: 'app-send-user-notification',
  templateUrl: './send-user-notification.component.html',
  styleUrls: ['./send-user-notification.component.scss'],
  providers: [ConfirmationService]
})
export class SendUserNotificationComponent implements OnInit {
  @Output() modalClosed = new EventEmitter<boolean>();
  @Input() isOpen: boolean = false;
  sendUserNotificationForm: FormGroup;
  email: string = '';
  errMsg: string = 'Invalid inquiryId/conversationId. Please verify.';
  displayError: boolean = false;

  constructor(private builder: FormBuilder, private userDataService: UserDataService, private confirmationService: ConfirmationService, private newMessageService: NewMessageService) { }

  ngOnInit() {
    try{
    this.sendUserNotificationForm = this.builder.group({
      inquiryid: new FormControl('', [Validators.required]),
      convid: new FormControl('', [Validators.required]),
      subject: new FormControl('', []),
      from: new FormControl('', [Validators.required, Validators.email]),
      to: new FormControl('', [])
    });
    this.getUserData();
  }
  catch(error)
  {
    console.log('Exception in ngOnInit()'+error.message);
  }
  }

  getUserData() {
    this.userDataService.LocalGetLoginUserInfo().subscribe(loginUserInfo => {
      this.email = loginUserInfo.email;
      this.sendUserNotificationForm.get("from").setValue(this.email);
    });
  }

  cancelSendUSerNotifiactionDialog() {
    this.modalClosed.emit();
    this.resetSendUserNotification(this.sendUserNotificationForm);
  }

  sendUserNotification() {
    try{
    this.validateAllFormFields(this.sendUserNotificationForm);
    let notificationTo = this.sendUserNotificationForm.get("to").value;
    if (AppUtils.isUndefinedNullOrBlankStrAfterTrim(notificationTo)) {
      this.showMessageForTo();
    }
    else {
      this.addUserNotificationToDB();
    }
  }
  catch(error)
  {
  console.log('Exception in sendUserNotification'+error.message);
  }
  }

  validateAllFormFields(formGroup: FormGroup) {
    try{
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
  catch(error)
  {
    console.log('Exeception in validateAllFormFields:'+error.message);
  }
  }

  resetSendUserNotification(form: FormGroup) {
    form.reset();
    Object.keys(form.controls).forEach(key => {
      form.get(key).setErrors(null);
    });
  }

  showMessageForTo() {
    try{
    this.confirmationService.confirm({
      message: 'The notification will be sent to all active users. </br>Do you want to proceed?</b>',
      accept: () => {
        this.addUserNotificationToDB();
      },
      acceptLabel: 'Yes',
      rejectLabel: 'No'
    });
  }catch(error)
  {
    console.log('Exception in showMessageForTo()'+error.message);
  }
  }

  addUserNotificationToDB() {
    try{
    console.log('Posting to DB');
    let inquiryId = this.sendUserNotificationForm.get("inquiryid").value;
    let conversationId = this.sendUserNotificationForm.get("convid").value;
    let notificationSubject = this.sendUserNotificationForm.get("subject").value;
    let notificationFrom = this.sendUserNotificationForm.get("from").value;
    let notificationTo = this.sendUserNotificationForm.get("to").value;
    let data = {
      action: "USERNOTIFICATION", inquiryId: inquiryId, conversationId: conversationId, notificationSubject: notificationSubject, notificationFrom: notificationFrom, notificationTo: notificationTo
    };
    this.newMessageService.sendNotification(data).subscribe(result => {
      console.log('result of sending user not=ification', result);
      if (result.processingstatus === "true") {
        //this.isOpen = false;
        this.closeModal();
        this.resetSendUserNotification(this.sendUserNotificationForm);
      }
      else {
        this.displayError = true;
        setTimeout(() => { this.displayError = false;}, 9000);
      }
    },
      (error) => {
        console.log('error while sending user notification:', error);
      }
    );
    }
    catch(error)
    {
      console.log('Exception in addUserNotificationToDB()'+error.message);
    }
  }

  closeModal() {
    this.modalClosed.emit();
  }

}

import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { GfcModel } from "./gfid-search.model";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { QmaConstant } from 'src/app/constant/qma-constant';
import { GridOptions, Module } from "@ag-grid-community/core";
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ContextMenuService } from 'src/app/services/contextMenu/context-menu.service';
import { UserDataService } from 'src/app/services/user-data.service';
import * as _ from 'underscore';
import { PiwikProUtils } from '../../utility/piwikProUtils';

const httpFormPostOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': 'true'
  })
};

@Component({
  selector: 'app-gfid-search',
  templateUrl: './gfid-search.component.html',
  styleUrls: ['./gfid-search.component.scss']
})


export class GfidSearchComponent implements OnInit {
  modules: Module[] = [ClientSideRowModelModule];

  @Input() isNewMessage: boolean = false;
  @Output() addClicked = new EventEmitter();
  getAllGfcURL: string = "/inquiry/getAllGfcIds";
  contextServiceObject: any;

  private gridApi: any;
  private gridColumnApi: any;
  public gridOptions: GridOptions;
  public lbTotalRecords: number = 0;
  public lbPageSize: number = 0;
  public lbCurrentPage: number = 0;
  public lbTotalPages: number = 0;
  public disablePaginationNextLast: boolean = false;
  public disablePaginationPrevFirst: boolean = false;

  baseURL: string;
  firstLoad: boolean = false;
  selectedMenuItem: any;
  maxRecords: boolean = false;
  isSelected: boolean = false;
  display: boolean = false;
  gfcModel = new GfcModel();
  rowData: any;
  defaultVal: any = {};
  groupName: string;

  radioButtons = [];

  radioButtonsAmcar = [
    { name: 'GFP ID', val: 'gfpId', ignoreCase: false, filterOnGfc: false },
    { name: 'GFC ID', val: 'gfcId', ignoreCase: false, filterOnGfc: true },
    { name: 'GFP Name', val: 'gfpName', ignoreCase: true, filterOnGfc: false },
    { name: 'GFC Name', val: 'gfcName', ignoreCase: true, filterOnGfc: true }
  ];

  radioButtonsDefault = [
    { name: 'GFP ID', val: '_id', ignoreCase: false, filterOnGfc: false },
    { name: 'GFC ID', val: 'gfcIds.gfcId', ignoreCase: false, filterOnGfc: true },
    { name: 'GFP Name', val: 'gfpName', ignoreCase: true, filterOnGfc: false },
    { name: 'GFC Name', val: 'gfcIds.gfcName', ignoreCase: true, filterOnGfc: true }
  ];
  /*Columns width adjustment to display more content */

  /**
   * This is the column definition for non-custody groups or as existing
   */
  columnDefs = [
    { headerName: 'GFPID', field: 'gfpId', width: 50, suppressMenu: true, sortable: true, tooltipField: "gfpId" },
    { headerName: 'GFPNAME', field: 'gfpName', width: 150, suppressMenu: true, sortable: true, tooltipField: "gfpName" },
    { headerName: 'GFCID', field: 'gfcId', width: 50, suppressMenu: true, sortable: true, tooltipField: "gfcId" },
    { headerName: 'GFC Name', field: 'gfcName', width: 150, suppressMenu: true, sortable: true, tooltipField: "gfcName" }
  ];

  /**
   * This is the column definations for custory group which has account and branch details.
   */
  accountColumnDefs = [
    { headerName: 'GFPID', field: 'gfpId', width: 80, suppressMenu: true, sortable: true, tooltipField: "gfpId" },
    { headerName: 'GFPNAME', field: 'gfpName', width: 150, suppressMenu: true, sortable: true, tooltipField: "gfpName" },
    { headerName: 'GFCID', field: 'gfcId', width: 80, suppressMenu: true, sortable: true, tooltipField: "gfcId" },
    { headerName: 'GFC Name', field: 'gfcName', width: 150, suppressMenu: true, sortable: true, tooltipField: "gfcName" },
    { headerName: 'Account', field: 'skAccountNo', width: 80, suppressMenu: true, sortable: true, tooltipField: "skAccountNo" },
    { headerName: 'Branch', field: 'branch', width: 50, suppressMenu: true, sortable: true, tooltipField: "branch" },
  ];

  // Flag to enable the amcar changes
  enableAmcarIntegration: boolean = false;

  constructor(private userDataService: UserDataService, private httpClient: HttpClient, private contextMenuService: ContextMenuService) {
    this.baseURL = QmaConstant.BASE_URL;
    this.gridOptions = <GridOptions>{};
    this.contextServiceObject = this.contextMenuService;
    this.enableAmcarIntegration = this.userDataService.isAmcarIntegrationEnable();
  }

  ngOnInit() {
  }

  triggerFalseClick() {
    this.rowData = [];
    this.gfcModel = new GfcModel();
    this.maxRecords = false;
    this.display = true;
    this.firstLoad = false; // Hide the grid.


    //Enable AMCAR integration with Account level search only
    let isAccLvlSearch = this.userDataService.isCustodyGroup(this.groupName);
    if (!this.isObjectNull(this.defaultVal)) {
      isAccLvlSearch = false;
      if (this.defaultVal['skAccountNo'] && this.defaultVal['branch']) {
        isAccLvlSearch = true;
      }
    }

    // This will populate the search options.
    // Add account level search condition.
    if (this.enableAmcarIntegration && isAccLvlSearch) {
      this.setCriteriaWithAmcar();
    } else {
      this.setDefaultCriteria();
    }
    this.userDataService.userUsageStats("New Message", "GFPID/GFCID Search").subscribe(result => console.log("GFPID/GFCID Search StartTime:" + new Date()));
    PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled, this.userDataService.loggedInUserInfo.userId, "Menu", "New message - GFPID/GFCID Search", "Click", "New message - GFPID/GFCID Search", 0);
  }

  /**
   * Method to set the defaut criteria options for UI.
   */
  setDefaultCriteria() {
    this.radioButtons = [...this.radioButtonsDefault];

    if (this.isNewMessage && !this.isObjectNull(this.defaultVal)) {
      this.updateDefault(this.defaultVal);
    }
  }

  /**
   * Method to set the default search criteria.
   *
   * @param defaultVal
   */
  updateDefault(defaultVal: any) {
    let filterVal = null;
    if (defaultVal['gfcId']) {
      filterVal = defaultVal['gfcId'];
    }

    this.gfcModel.field = "GFCID";
    this.gfcModel.filCriteria = "gfcIds.gfcId";
    this.gfcModel.filterOnGfc = true;
    this.gfcModel.filterType = "contains";
    this.gfcModel.filterVal = filterVal;
    this.gfcModel.ignoreCase = false;
    this.getDataFromDB(this.gfcModel);
  }

  /**
   * Method to set the criteria options for UI when integration with amcar is enable.
   */
  setCriteriaWithAmcar() {
    this.gfcModel.accCriteria = this.userDataService.isCustodyGroup(this.groupName);
    this.populateSearchOptions(this.gfcModel.accCriteria);
    if (this.isNewMessage && !this.isObjectNull(this.defaultVal)) {
      this.updateDefaultWithAmcar(this.defaultVal)
    } else {
      this.gfcModel.filCriteria = "gfpId";
    }
  }

  /**
   * Method to set default search criteria considering amcar integartion.
   *
   * @param defaultVal
   */
  updateDefaultWithAmcar(defaultVal: any) {
    let field = "GFCID";
    let filCriteria = "gfcId";
    let filterVal = null;

    if (defaultVal['gfcId'] && defaultVal['skAccountNo'] && defaultVal['branch']) {
      field = filCriteria = "skAccountNo";
      filterVal = defaultVal['skAccountNo'];
      this.gfcModel.acctBranch = defaultVal['branch'];

      // If account number is available. It is on account level search.
      this.gfcModel.accCriteria = true;
      this.populateSearchOptions(this.gfcModel.accCriteria);
    }
    else if (defaultVal['gfcId']) {
      filterVal = defaultVal['gfcId'];
    }

    this.gfcModel.field = field;
    this.gfcModel.filCriteria = filCriteria;
    this.gfcModel.filterVal = filterVal;
    this.gfcModel.filterOnGfc = true;
    this.gfcModel.filterType = "contains";
    this.gfcModel.ignoreCase = false;
    this.getDataFromDB(this.gfcModel);
  }

  isObjectNull(valObject: any): boolean {
    return Object.values(valObject).every(o => (o === null || o === ''));
  }

  hideModal(_gfcForm: any) {
    this.display = false;
    _gfcForm.reset();
    this.userDataService.userUsageStats("New Message", "New Message").subscribe(result => console.log("GFPID/GFCID Search StartTime:" + new Date()));
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.showLoadingOverlay;
    params.api.sizeColumnsToFit();

  }

  validateAndSearch(form: any) {
    let selectedItem = this.radioButtons.find(val => val.val == this.gfcModel.filCriteria);
    this.gfcModel.field = selectedItem.name.replace(/\s/g, "");
    this.gfcModel.filterOnGfc = selectedItem.filterOnGfc;
    this.gfcModel.ignoreCase = selectedItem.ignoreCase;
    this.gfcModel.filterVal = this.gfcModel.filterVal;
    this.isSelected = false;
    this.getDataFromDB(this.gfcModel);
  }

  getDataFromDB(postData: any) {
    this.maxRecords = false;
    let body = "request=" + JSON.stringify(postData);
    this.rowData = [];

    this.applyGridOptions();
    if (this.gridApi) {
      this.gridApi.showLoadingOverlay();
    }
    this.httpClient.post(this.baseURL + this.getAllGfcURL, body, httpFormPostOptions)
      .subscribe((res: []) => {
        if (res.length >= 5000) {
          this.maxRecords = true;
        }
        this.rowData = res;

        if (this.gridApi) {
          this.gridApi.hideOverlay();
        }
      });
  }

  applyGridOptions() {
    if (!this.firstLoad) {
      this.gridOptions.pagination = true;
      this.gridOptions.paginationPageSize = 50;
      this.gridOptions.rowHeight = 35;
      this.gridOptions.rowSelection = 'single';
      this.gridOptions.suppressDragLeaveHidesColumns = true;
      this.gridOptions.suppressContextMenu = true;
      this.gridOptions.suppressRowDeselection = true;
      this.gridOptions.suppressHorizontalScroll = true;
    }
    this.firstLoad = true;
  }

  addGFID(_gfcForm: any) {

    let selectedItem = this.gridApi.getSelectedRows()[0];
    if (!this.isSelected) {
      return;
    }

    // Don't consider account and branch if the search is not on account level.
    if (!this.gfcModel.accCriteria) {
      selectedItem.skAccountNo = null;
      selectedItem.branch = null;
    }

    if (this.isNewMessage) {
      this.addClicked.emit(selectedItem)
    } else {
      this.contextServiceObject.httpSetGfid(this.selectedMenuItem, selectedItem).subscribe(res => console.log(res));
    }
    this.hideModal(_gfcForm);
  }

  onRowClicked(evt: any) {
    this.isSelected = true;
  }

  onPaginationChanged() {
    if (this.gridApi) {
      this.lbTotalRecords = this.gridApi.paginationGetRowCount();
      this.lbPageSize = this.gridApi.paginationGetPageSize();
      this.lbCurrentPage = this.gridApi.paginationGetCurrentPage() + 1;
      this.lbTotalPages = this.gridApi.paginationGetTotalPages();

      let currentPage = this.gridApi.paginationGetCurrentPage() + 1;
      let lastPage = this.gridApi.paginationGetTotalPages();

      if (currentPage == lastPage) {
        this.disablePaginationNextLast = true;
      }
      else {
        this.disablePaginationNextLast = false;
      }

      if (currentPage == 1) {
        this.disablePaginationPrevFirst = true;
      }
      else {
        this.disablePaginationPrevFirst = false;
      }

      this.lbPageSize = this.gridApi.paginationGetPageSize() * this.lbCurrentPage;
      if (this.lbPageSize > this.lbTotalRecords) {
        this.lbPageSize = this.lbTotalRecords;
      }
    }
  }

  onBtNext() {
    this.gridApi.paginationGoToNextPage();
  }
  onBtPrevious() {
    this.gridApi.paginationGoToPreviousPage();
  }
  onBtLast() {
    this.gridApi.paginationGoToLastPage();
  }
  onBtFirst() {
    this.gridApi.paginationGoToFirstPage();
  }

  /**
   * Method to call on click of radio button.
   *
   * @param event
   */
  onSelectCriteria(item: any): void {
    this.gfcModel.acctBranch = null;
  }

  /**
   * Call back method triggered on account level search action.
   *
   * @param event
   */
  onAccountLvlSearch(event: any): void {
    // C170665-834 | If account level search,  then call GFCDetailsMapping Collection, otherwise GFCIDMapping Collection.
    let isAccLevelSearch = event.target.checked;
    // Hide the error and hide the grid.
    this.firstLoad = false;
    this.maxRecords = null;

    if (isAccLevelSearch) {
      this.populateSearchOptions(isAccLevelSearch);
      this.gfcModel.filCriteria = "gfcId";
    } else {
      this.radioButtons = [...this.radioButtonsDefault];
      this.gfcModel.filCriteria = "gfcIds.gfcId";
    }
    this.gfcModel.filterVal = null;
    this.rowData = [];
  }

  /**
   * Method to update the search options based on the account level search or not.
   *
   * @param isAccountSearch
   */
  populateSearchOptions(isAccountSearch: boolean): void {
    this.radioButtons = [...this.radioButtonsAmcar];
    if (isAccountSearch) {
      this.radioButtons.push({ name: 'Safekeeping Account', val: 'skAccountNo', ignoreCase: true, filterOnGfc: true });
    } else {
      if ("skAccountNo" == this.gfcModel.filCriteria) {
        this.gfcModel.filCriteria = "gfcId";
      }
    }
    this.radioButtons = _.uniq(this.radioButtons, 'val');
  }
}

import { GroupInquiryActions } from './groupInquiryActions';

export class GroupInquiryCriteriaList {
    from: string;
    fromOperator: string;
    subject: string;
    subjectOperator: string;
    to: string;
    toOperator: string;
    actions: GroupInquiryActions;
    ruleOrder: number;
    attachment: boolean;
}

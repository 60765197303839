import { OrgPreferences } from './OrgPreferences';
import { RequestTypeMapping } from '../LoginUserInfo/RequestTypeMapping';
export class OrganizationAuditTrail{
    orgName:string;
    requestTypes:string[]=[];
    highLevelRequestTypeMapping: RequestTypeMapping[] = [];
    rootCause:string[]=[];
    requestTypeRootCauseMapping: {};
    enableGroupLevelOverride:boolean;
    workflowStatus:string;
    maker:string;
    checker:string[]=[];
    comment:string;
    modBy:string;
    crtBy:string;
    modDate:Date;
    crtDate:Date;
    enableOrgLevelMetaData:boolean;
    orgLevelInquirySubStatusEnabled:boolean;
}
export interface MyFavoriteContact {
    name: string;
    email: string;
    mapToGroupsName: string;
    sharedToGroupsName: string;
    index? : number;
    crtDate?: any;
    modDate?: any;
    createdBy: string;
    contactAddress: string;
    contactNote: string;
    contactPhNumber: string;
}

export interface GroupMemberContact {
    name: string;
    email: string;
}
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
  })
export class InquiryCache {

    private cacheInquiries = true;

    private inquiries: Map<any, any>;

    private indexMapByTab: Map<any, any>;

    private startIndexByTab: Map<any, number>;

    constructor() {
        this.inquiries = new Map();
        this.indexMapByTab = new Map();
        this.startIndexByTab = new Map();
    }

    /**
     * Initialize the cache for the specified tabName
     * @param tabName
     */
    init(tabName: string) {
        let indexMap = this.indexMapByTab.get(tabName);
        if (!indexMap) {
            indexMap = new Map();
            this.indexMapByTab.set(tabName, indexMap);
        } else {
            indexMap.clear();
        }
        // do not clear 'this.inquiries' as it may keep entries of other tabs
        this.startIndexByTab.delete(tabName);
    }

    /**
     * Clean up the submaps of the specified tabName
     * @param tabName
     */
    cleanMap(tabName: string) {
        let indexMap = this.indexMapByTab.get(tabName);
        if (!indexMap) {
            indexMap = new Map();
            this.indexMapByTab.set(tabName, indexMap);
        } else {
            // clear all the entries for this tab
            indexMap.clear();
        }
        this.startIndexByTab.delete(tabName);
        return indexMap;
    }

    /**
     * Add all the inquiries of the specified tab to the cache
     * @param tabName 
     * @param elements 
     */
    addAll(tabName: string, elements: any[]) {
        if (!elements || !elements.length) {
            // always clean up the map before adding all the entries to cache
            this.cleanMap(tabName);
            return;
        }
        let indexMap = this.cleanMap(tabName);
        elements.forEach((elem, index) => {
            if(elem && elem._id !== undefined) {
                if (this.cacheInquiries) {
                    this.inquiries.set(elem._id, elem);
                }
                indexMap.set(elem._id, index);
            }
        });
    }

    /**
     * Add an inquiry element of the specified tabName to the cahce
     */
    add(tabName: string, element: any, index: number, size: number) {
        if (!element || index < 0 || element._id === undefined || element._id === null) {
            return;
        }
        let indexMap = this.indexMapByTab.get(tabName);
        if (!indexMap) {
            indexMap = new Map();
            this.indexMapByTab.set(tabName, indexMap);
        }
        if (this.cacheInquiries) {
            this.inquiries.set(element._id, element);
        }
        if (index === 0 && indexMap.size > 0) {
            // if the element is added to the head of the array,
            // populate or rest the 'startIndex' (circular array head).
            let startIndex = this.startIndexByTab.get(tabName);
            if (startIndex === undefined) {
                startIndex = 1;
            } else {
                startIndex++;
            }
            this.startIndexByTab.set(tabName, startIndex);
            indexMap.set(element._id, Math.max(0, size -1));
        } else {
            indexMap.set(element._id, index);
        }
    }

    /**
     * Find the index of the specified id and tabName
     */
    findIndex(tabName: string, id: any) {
        const indexMap = this.indexMapByTab.get(tabName);
        if (!indexMap) {
            return -1;
        } else {
            const index = indexMap.get(id);
            if (index !== undefined) {
                const startIndex = this.startIndexByTab.get(tabName);
                if (startIndex === undefined || startIndex === null) {
                    return index;
                } else {
                    return Math.max(0, index - startIndex);
                }
            } else {
                return -1;
            }
        }
    }

    /**
     * Get inquiry of the specified id and tabName
     * @param tabName
     * @param id 
     */
    getInquiry(tabName: string, id: any) {
        return this.inquiries.get(id);
    }

    /**
     * Remove inquiry of the specified id from the cache
     * @param tabName 
     * @param id 
     */
    remove(tabName: string, id: any) {
        const indexMap = this.indexMapByTab.get(tabName);
        if (indexMap) {
            let index = indexMap.get(id);
            indexMap.delete(id);
            if (index >= 0) {
                let startIndex = this.startIndexByTab.get(tabName);
                if (startIndex > 0 && index < startIndex) {
                    startIndex--;
                    if (startIndex === 0) {
                        this.startIndexByTab.delete(tabName);
                    }
                }
            }
        }
        if (this.cacheInquiries) {
            this.inquiries.delete(id);
        }
    }
}
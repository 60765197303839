import {UserDataService} from "src/app/services/user-data.service";
import { LoginUserInfo } from 'src/app/model/LoginUserInfo/LoginUserInfo';

export class GridMenuItems{
    public  assignMenu = [];
	public uniqueData = [];
	public assignToMeGroup = [];
	public assignRequest = [];
	public processingRegionMenu = [];
	public groupTagList = [];
	public tagMenu = [];
    public groupManualEscList = [];
    public assignMenuAvailable=[];
    
    public gUserDataContext:LoginUserInfo;

    constructor(gUserDataContext:LoginUserInfo) {
        this.gUserDataContext = gUserDataContext
    }
    
    public generateDynamicMenu(){
        var result=this.getAssignMenu();
        this.getMenuItems();
    }

    public getAssignMenu(){
           
           let gUserData= this.gUserDataContext;
           if (gUserData.context) {
            for (var i = 0; i < gUserData.context.length; i++) {
                var groupOwnerList = [];
                if (gUserData.context) {
                    for (var item = 0; item < gUserData.context[i].assignOwnerList.length; item++) {
                        if (gUserData.context[i].assignOwnerList[item].active){
                            groupOwnerList.push(gUserData.context[i].assignOwnerList[item].text);
                        }
                        if (this.uniqueData.indexOf(gUserData.context[i].assignOwnerList[item].text) == -1) {
                            this.assignMenuAvailable.push(gUserData.context[i].assignOwnerList[item]);
                            this.uniqueData.push(gUserData.context[i].assignOwnerList[item].text);
                        }
                    }
                }
                if (gUserData.myGroups) {
                    for (var idx = 0; idx < gUserData.myGroups.length; idx++) {
                        //get assigned group id
                        //var assignedGroup
                        if (gUserData.myGroups[idx].id == gUserData.context[i].groupId) {
                            this.assignMenu.push({
                                groupOwner : groupOwnerList,
                                groupName : gUserData.myGroups[idx].groupName,
                                id : gUserData.myGroups[idx].id
                            })
                        }
    
                    }
                }
            }
        }
    }

    public getMenuItems(){
        let gUserData=this.gUserDataContext;
        if (gUserData.myGroups) {
            for (var i = 0; i < gUserData.myGroups.length; i++) {
                //if(gUserData.myGroups[i].groupName == assignedOwnerGroupName) {
                this.assignRequest.push({
                    groupName : gUserData.myGroups[i].groupName,
                    requestType : gUserData.myGroups[i].requestTypes,
                    id : gUserData.myGroups[i].id
                })
                this.assignToMeGroup.push({
                    text : gUserData.myGroups[i].groupName,
                    cssClass : gUserData.myGroups[i].id
                });
    
                this.tagMenu.push({
                    groupTag : gUserData.myGroups[i].tags,
                    groupName : gUserData.myGroups[i].groupName,
                    id : gUserData.myGroups[i].id
                });
                this.groupManualEscList.push({
                    escKeywords : gUserData.myGroups[i].escKeywords,
                    groupName : gUserData.myGroups[i].groupName,
                    id : gUserData.myGroups[i].id
                });
                this.processingRegionMenu.push({
                    groupName : gUserData.myGroups[i].groupName,
                    processingRegionList : gUserData.myGroups[i].processingRegionList,
                    id : gUserData.myGroups[i].id
                })
    
                //break;
                //}
            }
        }

    }

     getAssignOwnerMenu() {
        var stime = new Date();
        let gUserData= this.gUserDataContext;
        var setAssignOwnerMenu = [];
        if (this.assignMenu) {
            for (var item = 0; item < this.assignMenu.length; item++) {
                var selectOwnerNames = [];
                if (gUserData.myGroups) {
                    if (this.assignMenu[item].groupOwner) {
                        for (var i = 0; i < this.assignMenu[item].groupOwner.length; i++) {
                            selectOwnerNames.push({
                                text : this.assignMenu[item].groupOwner[i],
                                cssClass : this.assignMenu[item].id
                            });
                        }
                    }
                }
                if (this.assignMenu.length == 1) {
                    setAssignOwnerMenu = selectOwnerNames;
                } else {
                    setAssignOwnerMenu.push({
                        text : this.assignMenu[item].groupName,
                        cssClass : this.assignMenu[item].id,
                        items : selectOwnerNames
                    })
                }
            }
    
        }
        setAssignOwnerMenu.push({
            text : "No common user found for multiple groups",
            cssClass : "noCommonGroupErrorMsg"
        })
      
        return setAssignOwnerMenu;
    }

}
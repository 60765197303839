import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpUrlEncodingCodec } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { QmaConstant } from 'src/app/constant/qma-constant';

@Injectable({
  providedIn: 'root'
})
export class HttpUtilService {

  constructor(protected http: HttpClient) {
  }

  protected getBaseUrl(): string {
    return QmaConstant.BASE_URL + "/";
  }

  private getDefaultHeaders() {
    return {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'Access-Control-Allow-Origin': '*',
    };
  }

  protected methodBuilder<T>(method: string, resUrl: string, oBody: any = null, opts: Object = {}): Observable<T> {
    let url: string = "";
    if (resUrl.indexOf("http://") > -1 || resUrl.indexOf("https://") > -1) {
      url = resUrl;
    } else {
      url = this.getBaseUrl() + resUrl;
    }

    let headers: HttpHeaders = this.createHeaders(opts['headers'], this.getDefaultHeaders());

    let body;
    if (opts['headers']) {
      body = this.createBody(oBody, headers);
    } else {
      body = this.createBody(oBody);
    }
    let params: HttpParams = this.createParams(opts['params']);
    // Request options
    let options = {
      body: body,
      headers: headers,
      params: params,
      reportProgress: opts['reportProgress'] || true,
      responseType: opts['responseType'] || 'json',
      withCredentials: opts['withCredentials'] || true,
    };
    // make the request and store the observable for later transformation
    let observable: Observable<T> = this.http.request<T>(method, url, options);
    return observable;
  }

  protected createHeaders(pHeader: object, defaultHeaders: any): HttpHeaders {
    let headers = new HttpHeaders(defaultHeaders);
    // set parameter specific headers
    if (pHeader) {
      for (let k in pHeader) {
        if (pHeader.hasOwnProperty(k)) {
          headers = headers.set(k, pHeader[k]);
        }
      }
    }
    return headers;
  }

  protected createBody(pBody: Object, pHeaders: any = null): any {
    let header = this.getDefaultHeaders();

    if (pHeaders) {
      let headerContentType = pHeaders.get('content-type');
      if (typeof headerContentType !== 'undefined' && headerContentType === 'application/x-www-form-urlencoded') {
        return this.createParams(pBody).toString();
      } else {
        return pBody ? JSON.stringify(pBody) : null;
      }
    } else {
      if (typeof header['Content-Type'] !== 'undefined' &&
        header['Content-Type'] === 'application/x-www-form-urlencoded') {
        return this.createParams(pBody).toString();
      } else {
        return pBody ? JSON.stringify(pBody) : null;
      }
    }
  }


  protected createParams(pParams: object): HttpParams {
    let params = new HttpParams({ encoder: new HttpUrlEncodingCodec() });

    if (pParams) {
      for (let k in pParams) {
        if (pParams.hasOwnProperty(k)) {
          params = params.append(k, pParams[k]);
        }
      }
    }

    return params;
  }
}

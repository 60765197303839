import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Subject, Observable } from 'rxjs';
import * as Rx from 'rxjs';
import { QmaConstant } from 'src/app/constant/qma-constant';
import { UserDataService } from "src/app/services/user-data.service";

@Injectable()
export class WebsocketService {

  TOKEN_KEY_PREFIX: string ='?&token='; //C153176-4881 websocket on close issue
  errorCodeForWebSocketRestartList: number[];
  maxWebSocketOpenRetry: number ;
  loginUserInfo: any;
  url: any;
  ws: WebSocket;
  obs: any;
  token: any; //C153176-4881 websocket on close issue
  private subject: Rx.Subject<MessageEvent>;

  private websocketConnectionSubject: Rx.Subject<MessageEvent> = new Rx.Subject<MessageEvent>();
  // This is behavior when user new websocket update comes in.
  webSocketUpdateSubject$ = new Rx.BehaviorSubject([]);
  // C153176-4881: flag indicating whether a previous reconnect attempt failed
  tokenRenewTime: number;
  // C153176-5082: latest websocket message timestamp
  private latestMessageTimestamp: number = Date.now();
  // reconnect idle timer
  private reconnectIdleTimer: any;

  // set flag to true as default will be reset on failure
 isWsConnectionEstablished$ = new Rx.BehaviorSubject<boolean>(true);

  constructor(private userDataService: UserDataService, private httpClient: HttpClient) {
    this.maxWebSocketOpenRetry = QmaConstant.MAX_WEBSOCKET_RECONNECT_TRY_TIMES;
    this.userDataService.LocalGetLoginUserInfo().subscribe(loginUserData => {
      this.loginUserInfo = loginUserData;
    });
  }

  /* only notification component will call connect method all other components
     will subscribe to webSocketUpdateSubject of this service for websocket update */
  public connect(url): Rx.Subject<MessageEvent> {
    if (!this.subject) {
      //this.subject = this.create(url);
      this.subject = this.createWebSocketConnection(url);
      console.log("Successfully connected: " + url);
      if (this.userDataService && this.userDataService.loggedInUserInfo) {
        this.errorCodeForWebSocketRestartList = this.userDataService.loggedInUserInfo.errorCodeForWebSocketRestartList;
      }
      // reset retry times
      this.maxWebSocketOpenRetry = QmaConstant.MAX_WEBSOCKET_RECONNECT_TRY_TIMES;//C153176-4881 websocket on close issue
      // C153176-5082: check whether the websocket connection has been idle
      // need to uncomment
      if (this.reconnectIdleTimer === undefined) {
        this.reconnectIdleTimer = setInterval(()=> {
            this.checkIdleConnection()
        }, 1 * 60 * 1000); //  need ws timeout from db
      }
    }
    return this.subject;
  }

  private create(url): Rx.Subject<MessageEvent> {
    try {
      this.url = url;
      this.ws = new WebSocket(this.url);
      const svc = this;
      let observable = Rx.Observable.create((obs: Rx.Observer<MessageEvent>) => {
        console.debug('websocket.service: create() obs=', obs);
        svc.obs = obs;
        svc.ws.onmessage = obs.next.bind(obs);
        svc.ws.onerror = obs.error.bind(obs);
        svc.ws.onclose = ((evt: CloseEvent) => {
          console.debug(`websocketupdate: closing ws connection websocket.service.create.onclose()`);
          svc.onclose(evt);
        }); //obs.complete.bind(obs);
        // set wsConnection status to on if its succesful
        svc.ws.onopen = ((evt: any) => {
          console.debug(`websocketupdate: opening ws connection websocket.service.create.onopen()`);
          svc.onWsConnectionOpen(evt);
        });
        return svc.ws.close.bind(svc.ws);
      });
      let observer = {
        next: (data: Object) => {
          console.debug(`websocketupdate: ws observer next data transmit`);
          if (svc.ws && svc.ws.readyState === WebSocket.OPEN) {
            console.debug(`websocketupdate: ws observer next data transmit on websocket open status`);
            svc.ws.send(JSON.stringify(data));
          }
        }
      };
      return Rx.Subject.create(observer, observable);
    } catch(err) {
      this.maxWebSocketOpenRetry--;
      throw err;
    }
  }

  createWebSocketConnection(url): Rx.Subject<MessageEvent> {
    try {
      this.url = url;
      this.ws = new WebSocket(this.url);
      
      this.ws.onopen=(e)=>{
        console.debug(`websocket connection: opening web socket connection websocker.service createWebSocketConnection() onopen() `,e);
      }
      this.ws.onmessage=(e)=>{
        console.debug(`websocket connection: receiving web socket message  websocker.service createWebSocketConnection() onmessage()`,e);
        this.websocketConnectionSubject.next(e);
      }
      this.ws.onerror=(e)=>{
        console.debug(`websocket connection: error web socket connection websocker.service createWebSocketConnection() onerror()`,e);
      }
      this.ws.onclose=(e)=>{
        console.debug(`websocket connection: closing web socket connection websocker.service createWebSocketConnection() onclose() `,e);
        this.onclose(e);
      }
      return this.websocketConnectionSubject;
    } catch(err) {
      this.maxWebSocketOpenRetry--;
      throw err;
    }
  }

  setwebSocketUpdate(webSocketUpdate: any): void {
    // C153176-5082: set latest message timestamp
    console.debug("websocketupdate: setting ws update to ws subject in websocket.service.setwebSocketUpdate()");
    this.latestMessageTimestamp = Date.now();
    this.webSocketUpdateSubject$.next(webSocketUpdate);
  }

  getwebSocketUpdate(): Observable<any> {
    console.debug("websocketupdate: getting subject from websocket.service.getwebSocketUpdate()");
    return this.webSocketUpdateSubject$.asObservable();
  }

  /**
   * Handling websocket connection close
   */
  onclose(event: CloseEvent) {
    if (event) {
      let reason = "Unknown reason";
      console.debug("websocket.service: Error Code List enabled for Websocket restart from DB : " +  this.errorCodeForWebSocketRestartList);
      if (event.code == 1000) {
          reason = "Normal closure, meaning that the purpose for which the connection was established has been fulfilled.";
      } else if(event.code == 1001) {
          reason = "An endpoint is \"going away\", such as a server going down or a browser having navigated away from a page.";
      } else if(event.code == 1002) {
          reason = "An endpoint is terminating the connection due to a protocol error";
      } else if(event.code == 1003) {
        reason = "An endpoint is terminating the connection because it has received a type of data it cannot accept (e.g., an endpoint that understands only text data MAY send this if it receives a binary message).";
      } else if(event.code == 1004) {
        reason = "Reserved. The specific meaning might be defined in the future.";
      } else if(event.code == 1005) {
        reason = "No status code was actually present.";
      } else if(event.code == 1006) {
        reason = "The connection was closed abnormally, e.g., without sending or receiving a Close control frame";
      } else if(event.code == 1007) {
        reason = "An endpoint is terminating the connection because it has received data within a message that was not consistent with the type of the message (e.g., non-UTF-8 [http://tools.ietf.org/html/rfc3629] data within a text message).";
      } else if(event.code == 1008) {
        reason = "An endpoint is terminating the connection because it has received a message that \"violates its policy\". This reason is given either if there is no other sutible reason, or if there is a need to hide specific details about the policy.";
      } else if(event.code == 1009) {
        reason = "An endpoint is terminating the connection because it has received a message that is too big for it to process.";
      } else if(event.code == 1010) { // Note that this status code is not used by the server, because it can fail the WebSocket handshake instead.
        reason = "An endpoint (client) is terminating the connection because it has expected the server to negotiate one or more extension, but the server didn't return them in the response message of the WebSocket handshake. <br /> Specifically, the extensions that are needed are: " + event.reason;
      } else if(event.code == 1011) {
        reason = "A server is terminating the connection because it encountered an unexpected condition that prevented it from fulfilling the request.";
      } else if(event.code == 1015) {
        reason = "The connection was closed due to a failure to perform a TLS handshake (e.g., the server certificate can't be verified).";
      } else {
        reason = "Unknown reason";
      }
      console.debug('websocket.service: connection closed. code ::'+ event.code +', reason ::' + reason);
    }
    this.maxWebSocketOpenRetry--;
    // C153176-4881: if there was a reconnect failure, renew token and reconnect
    this.checkTokenAndReconnect();
    // rseset flag to false if reconnection fails for any reason
    this.setWSConnectionStatus(false);
  }

  reconnectOld() {
    if (this.maxWebSocketOpenRetry < 0) {
      console.debug("websocket.service: Skip attempting to reopen Websocket connection due to max retry limit reached.");
      return;
    }
    try {
      this.updateUrl();//C153176-4881 websocket on close issue
      this.ws = new WebSocket(this.url);
      if (this.obs) {
        // reset the reconnectFailed flag when connect is open
        this.ws.onmessage = this.obs.next.bind(this.obs);
        this.ws.onerror = this.obs.error.bind(this.obs);
        this.ws.onclose = ((evt: CloseEvent) => {
          console.debug(`websocketupdate: closing ws connection in websocket.service.reconnect.close()`);
          this.onclose(evt);
        }); //obs.complete.bind(obs);
        // reset retry times
        // set wsConnection status to on if its succesful
        this.ws.onopen = ((evt: any) => {
          console.debug(`websocketupdate: reopening ws connection in websocket.service.reconnect.open() and observer is ${this.obs}`);
          this.onWsConnectionOpen(evt);
        });
        this.maxWebSocketOpenRetry =QmaConstant.MAX_WEBSOCKET_RECONNECT_TRY_TIMES;
        console.log('websocket.service: reconnected, url = ', this.url, 'rebinding to', this.obs);
        // C153176-5082: set latest message timestamp
        this.latestMessageTimestamp = Date.now();
      } else {
        console.debug('websocket.service: recoonect failed, ERROR: websocket subscriber is NOT present!');
    }
  } catch(err) {
      this.maxWebSocketOpenRetry--;
      // C153176-4881: attempt to reconnect with token renewal
      this.onclose(null);
      throw err;
    }
  }

  reconnect() {
    if (this.maxWebSocketOpenRetry < 0) {
      console.debug("websocket.service: Skip attempting to reopen Websocket connection due to max retry limit reached.");
      return;
    }
    try {
      this.updateUrl();//C153176-4881 websocket on close issue
      this.ws = new WebSocket(this.url);
      
      this.ws.onopen=(e)=>{
        console.debug(`websocket connection: reopening web socket connection websocker.service reconnect() onopen() `,e);
        this.onWsConnectionOpen(e);
      }
      this.ws.onmessage=(e)=>{
        console.debug(`websocket connection: receiving web socket message  websocker.service reconnect() onmessage()`,e);
        this.websocketConnectionSubject.next(e);
      }
      this.ws.onerror=(e)=>{
        console.debug(`websocket connection: error web socket connection websocker.service reconnect() onerror() `,e);
      }
      this.ws.onclose=(e)=>{
        console.debug(`websocket connection: closing web socket connection websocker.service reconnect() onclose() `,e);
        this.onclose(e);
      }
      this.maxWebSocketOpenRetry =QmaConstant.MAX_WEBSOCKET_RECONNECT_TRY_TIMES;
        this.latestMessageTimestamp = Date.now();
  } catch(err) {
      this.maxWebSocketOpenRetry--;
      // C153176-4881: attempt to reconnect with token renewal
      this.onclose(null);
      throw err;
    }
  }
//C153176-4881 websocket on close issue
  getConnectionToken() {
    this.handleTokenUpdateAndReconnect(false);
  }

  checkTokenAndReconnect() {
    this.handleTokenUpdateAndReconnect(true);
  }

  // C153176-4881: handle token renewal and/or reconnect
  handleTokenUpdateAndReconnect(reconnect = false) {
    console.debug('websocket.service:handleTokenUpdateAndReconnect() ...');
    let hasError = false;
    // check if cvStreamingToken enebled
    if (this.loginUserInfo && this.loginUserInfo.cvWebsocketConfig && this.loginUserInfo.cvWebsocketConfig.isCVWebSocketUpdateEnabled) {
      // C153176-4881: renew the token before reconnection
      let wsUrl: string = this.loginUserInfo.cvWebsocketConfig.qmaWebSocketEndPoint;
      if (wsUrl.indexOf('streaming') > -1) {
        let tokenUrl: string = this.loginUserInfo.cvWebsocketConfig.cvStreamingTokenURL;
        const headers = new HttpHeaders().set("X-Accept", "application/json");
        // on server using cvStreamingTokenURL
        const svc = this;
        this.httpClient.get(tokenUrl, { headers }).subscribe(data => {
          if (data && data['token']) {
            this.token = data['token'];
            // reset token renew time
            this.tokenRenewTime = Date.now();
            console.debug('websocket.service:handleTokenUpdateAndReconnect(), updated token = ', this.token);
          } else {
            console.log('websocket.service:handleTokenUpdateAndReconnect(), fail to retrieve token, next token update in 15 min.');
          }
          if (reconnect) {
            this.reconnect();
          }
        }, error => {
          // On local development environment cvStreamingTokenURL will not work.
          console.log('websocket.service:handleTokenUpdateAndReconnect(), error = ', error);
          // C153176-5082 attempt to reconnnect even token is not renewed
          if (reconnect) {
            this.reconnect();
          }
        });
      } else if (reconnect) {
        this.reconnect();
      }
    } else if (reconnect) {
      // direct connect without token renewal if isCVWebSocketUpdateEnabled is false
      this.reconnect();
    }
  }

  updateUrl() {
    if (!this.token || !this.url) {
      console.log('websocket.service: invalid token or url, cannot update url');
      return;
    }
    if (!this.loginUserInfo || !this.loginUserInfo.cvWebsocketConfig || !this.loginUserInfo.cvWebsocketConfig.isCVWebSocketUpdateEnabled) {
      // no need to update URL if it doesn't use token
      this.url += this.loginUserInfo.cvWebsocketConfig.isWebSocketRedesignEnable ? "&qma2" : "";
      return;
    }

    let parts = this.url.split(this.TOKEN_KEY_PREFIX);
    if (parts && parts.length) {
      const appendQMA2 = this.loginUserInfo.cvWebsocketConfig.isWebSocketRedesignEnable &&
                         !parts[0].includes("&qma2") ? "&qma2" : "";
      if (appendQMA2 === "") {
        this.url = parts[0] + "&token=" + this.token ;
      } else{
        this.url = parts[0] + this.TOKEN_KEY_PREFIX + this.token  + appendQMA2;
      }
      
    }
  }

  /**
   * C153176-5082: check whether the websocket connection has been idle
   */
  checkIdleConnection() {
    if (!this.ws) {
      return;
    }
    // reconnect based on db flag
    let idleTime = Date.now() - this.latestMessageTimestamp;
    if (idleTime > this.userDataService.loggedInUserInfo.cvWebsocketConfig.wsReconnectIntervalWhenIdleInSeconds  *1000) {
    console.debug('websocket.service: reconnect time = ' + this.loginUserInfo.cvWebsocketConfig.wsReconnectIntervalWhenIdleInSeconds);
    this.maxWebSocketOpenRetry--;
    try {
      // close the connection
      this.ws.close();
    } catch (err) {
      console.log(err);
    }
    }
  }

  getWSConnectionStatus() {
    return this.isWsConnectionEstablished$.asObservable();
  }
  setWSConnectionStatus(status) {
    this.isWsConnectionEstablished$.next(status);
  }
  onWsConnectionOpen(openEvent:any) {
    this.setWSConnectionStatus(true);
  }
}
<div class="help-bar">
    <div class='logo px-4 pt-4'>
        <p class="px-4 ml-3 active">HELP</p>
    </div>
    <p-toast key="myKey1"></p-toast>
    <div class="mt-3">
        <ul class="nav py-3 px-4 d-flex align-items-start flex-column flex-nowrap">
            <li class="support-list mb-4" (click)="openFeedbackDialog();closePanel();">
                <!-- <i class="fa fa-life-bouy mr-3"></i> -->
                <img [src]="'assets/core/layout/header/support.svg'" alt="Feedback">
                <a class="ml-3">Support & feedback</a>
            </li>
            <li class="guide-list mb-4" (click)="closePanel()">
                <!-- <i class="fa fa-file-text-o mr-3" ></i> -->
                <img [src]="'assets/core/layout/header/user_guide.svg'" alt="User Guide">
                <!-- C170665-1970 Update user guide link on QMA 2 right panel -->
                <a class="ml-3" rel="noopener" href="https://XXXXX">User Guide</a>
            </li>
            <li class="release-list mb-4" (click)="closePanel()">
                <!-- <i class="fa fa-pencil-square-o mr-3"></i> -->
                <img [src]="'assets/core/layout/header/release.svg'" alt="Release Notes">
                <a class="ml-3" rel="noopener" href="XXXXX" target="_blank">Release notes</a>
            </li>
            <li class="faq-list mb-4" (click)="closePanel()">
                <!-- <i class="fa fa-language mr-3"></i> -->
                <img [src]="'assets/core/layout/header/faq.svg'" alt="FAQs">
                <a class="ml-3" rel="noopener" href="XXXX" target="_blank">FAQs</a>
            </li>
        </ul>
    </div>
</div>

<!-- Support & feedback -->
<p-dialog [(visible)]="display" #supportDialog [modal]="true" [responsive]="true" [maximizable]="false"
    [appendTo]="'body'" [styleClass]="'contextMenuModal support-color helpModal'" [closable]="false"
    [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" [style]="{'height':'auto','width':'66%'}"
    [resizable]="false">
    <form [formGroup]="parentForm">
        <p-header>
            <div class="d-flex flex-row align-items-center justify-content-between" style="margin-bottom: 15px;">
                <h6 class="gfid-title" style="font-size: 16px">SUPPORT & FEEDBACK</h6>

                <button (click)="hideModal()" type="button" class="d-flex flex-row close qma-text"
                    style="border:none !important;opacity:1">
                    <img src="assets/Inbox/close_icon_u73.svg" style="opacity:1" alt="close">
                    <h6 style="font-size: 14px;">CLOSE</h6>
                </button>
            </div>
        </p-header>

        <div class="gfid-body" style="border:1px solid #002D72;">
            <div class="help-section ">
                <div class="d-flex flex-row justify-content-between  mt-4 mobile-form control-group">
                    <div class="w-20 d-flex flex-row align-items-start" style="width:50%;">
                        <label for="from" class="flex-grow" style="padding-right: 74px;">Subject: </label>
                        <input type="text" placeholder="Start typing..."
                            class="w-100 t-box inp-txt-mng group-details-input-mng group-section"
                            formControlName="subject" />
                    </div>
                </div>
                <div class="d-flex flex-row justify-content-between  mt-4 mobile-form control-group">
                    <div class="w-20 d-flex flex-row align-items-start" style="width:50%;">
                        <label for="from" class="flex-grow inbox-group" style="padding-right: 35px;">Request Type:
                        </label>
                        <p-dropdown class="drop-down w-100" [options]="requestTypes" formControlName="requestType"
                            [styleClass]="'override'">
                        </p-dropdown>
                    </div>
                </div>
                <div class="d-flex flex-row justify-content-between  mt-4 mobile-form control-group">
                    <div class="w-20 d-flex flex-row align-items-start" style="width:50%;">
                        <label for="from" class="flex-grow inbox-group" style="padding-right: 17px;">Request Priority:
                        </label>
                        <p-dropdown class="drop-down w-100" [options]="priorities" formControlName="priority"
                            [styleClass]="'override'">
                        </p-dropdown>
                    </div>
                </div>
                <div #overflowdiv class="w-100 d-flex flex-row align-items-start control-group ellipsis-overflow">
                    <div *ngIf="postDataForAttachmentFile" class="pull-left" id="listOffileAttached">
                        <div *ngFor="let attachment of postDataForAttachmentFile"
                            style="display:inline-flex; font-size: 12px; font-family: arial; margin-top:10px; margin-right: 14px;">
                            <div class="elipsis-attachment">
                                <span id="{{attachment.id}}"><img src="assets/newMessage/notes.svg"
                                        class="attachment-img" alt="Notes">
                                    <a href="#" title="{{attachment.name}}" class="attachment-font"
                                        (click)="downloadAttachment(attachment.id,attachment.name)">{{attachment.name}}</a>
                                </span>
                            </div>
                            <span (click)='deleteAttachedFile(attachment.id)'>
                                <img src="assets/newMessage/attachdelete.svg" class="p-1 attchment-cross" alt="Delete">
                            </span>

                        </div>
                    </div>

                    <input type="hidden" name="attachments">
                </div>
                <a class="d-flex flex-column justify-content-center ellipsis-btn"
                    *ngIf="checkOverFlow(overflowdiv) && !isElipsisShow"
                    (click)="showRemainingAttchment($event, attachmentOptions, postDataForAttachmentFile)">
                    <img src="assets/newMessage/ellipse.svg" class="p-1 ellipse-size" alt="More">
                </a>
            </div>

            <div class="support-section">
                <div *ngIf="editorFlag" style="margin: 2em 3em 3em 3em;">
                    <editor-app [elementId]="'emailDisc'" [height]="300"></editor-app>
                </div>
            </div>
            <p-header>
                <div class="d-flex flex-row align-items-center justify-content-between">
                    <button type="button" class="d-flex flex-row close qma-text"
                        style="border:none !important;opacity:1;margin-left: 45px;margin-top: 1em;">
                        <em class="fa fa-envelope-o" style="font-size: 18px;margin: -1px 6px 0px 0px;"></em>
                        <h6 style="font-family: Interstatelight;color: #002D72;font-size: 14px;text-decoration: underline;"
                            (click)="mailSupport()">&#64;Support</h6>
                    </button>

                </div>
            </p-header>

            <p-footer>
                <div class="d-flex flex-row footer-btn-container"></div>
                <div class="d-flex flex-row flex-wrap bottom-button-holder px-3 pb-2 pt-3 justify-content-end"
                    style="margin-top:20px;border-top: 1px solid #002D72;">
                    <input name="files" id="files" type="file" multiple="true" style="display:none" #uploadFiles
                        (change)="fileEvent($event)" />
                    <button type="button" class="btn clear-btn" (click)="uploadFile()" style="margin-right: 50px;">
                        <span class="mt-auto"><img src="assets/newMessage/attach.svg" class="p-1"
                                alt="Attach">ATTACH</span>
                        <!-- <span class="mt-auto">ATTACH</span> -->
                    </button>
                    <button class="btn save-btn" type="button" (click)="sendFeedback()">Send</button>
                    <button class="btn clear-btn" type="reset" (click)='resetHelp()'>Reset</button>
                </div>
            </p-footer>
        </div>
    </form>



</p-dialog>

<p-overlayPanel [style]="{'margin-top': '-2em', 'border': '1px solid #007BC2'}" #attachmentOptions appendTo="body"
    styleClass="attachmentDropdown" [showTransitionOptions]="'1ms ease-out'" [hideTransitionOptions]="'1ms ease-in'">
    <p-listbox [listStyle]="{'max-height':'154px', 'max-width':'121px,', 'overflow-x': 'hidden'}"
        [options]="attachmentsOptions" [styleClass]="'newborder-section'">
        <ng-template let-attachOpt pTemplate="item">
            <div class="opt-items tooltip-text" style="display:inline-flex">
                <div class="elipsis-attachment">
                    <span class="elipsis-attachment">
                        <span><img src="assets/newMessage/notes.svg" class="attachment-img" alt="Notes">
                            <a href="#" title="{{attachOpt.name}}" class="attachment-font"
                                (click)="downloadAttachment(attachOpt.id,attachOpt.name)">{{attachOpt.name}}</a>
                        </span>
                    </span>
                </div>
                <span>
                    <a (click)='deleteAttachedFilePanel(attachOpt.id)'>
                        <img src="assets/newMessage/attachdelete.svg" class="p-1 attchment-cross" alt="Delete">
                    </a>
                </span>
            </div>
        </ng-template>
    </p-listbox>
</p-overlayPanel>

<p-dialog [(visible)]="displayError" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'"
    [styleClass]="'blacklistModel'" [closable]="false" [dismissableMask]="true" [baseZIndex]="10000"
    [closeOnEscape]="true" [style]="{width: '30%'}">
    <p-header>
        <div class="d-flex flex-row align-items-center justify-content-between">
            <h6 class="success-title">Information</h6>
            <div class="d-flex">
                <div class="d-flex">
                    <div class="d-flex">
                        <img src="assets/Inbox/info-icon.svg" class="icon-setting-out"
                            (mouseover)="showUploadFilesPanel($event, info_upload_files)"
                            (mouseout)="info_upload_files.hide()" alt="Info" />
                        <p-overlayPanel [styleClass]="'icon-info-upload-files inquiry_rules_info'" [appendTo]="'body'"
                            #info_upload_files [baseZIndex]="9999">
                            <div class="d-flex flex-row mb-0.3">
                                <div class="info-content">{{qmaConstant?.QMA_UPLOAD_FILES_INFO}}</div>
                            </div>
                        </p-overlayPanel>
                    </div>
                    <button type="button" (click)="closeModal('error')" class="d-flex flex-row close qma-text">
                        <img src="assets/Inbox/close_icon_u73.svg" alt="Close">
                        <h6>CLOSE</h6>
                    </button>
                </div>

            </div>
        </div>
    </p-header>
    <div class="success-msg" [innerHTML]="errMsg"></div>
</p-dialog>
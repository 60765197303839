<div class="gfid-body">
    <div class="d-flex flex-row mt-3 pr-4">
        <form [formGroup]="myViewsForm" (ngSubmit)="submitMyViews(myViewsForm.value)">
          
            <!-- <p-tabView [scrollable]="false" class="myviews-dialog"
              [style]="{'text-decoration': 'underline', 'height':'100%', 'max-height':'580px', 'overflow-y':'auto', 'box-sizing':'border-box'}" 
              (onChange)="criteriaClick($event)"> -->
              <p-tabView class="myViewTab" #adminTabs (onChange)="criteriaClick($event)" [styleClass]="'admin-tabview'" [scrollable]="false"
              [style]="{'height':'auto','width':'70em','margin-left': '27px', overflow: 'hidden'}">
                <p-tabPanel header="DEFINITION">
                  <div class="d-flex flex-row flex-nowrap align-items-end w-100 mt-3 pl-2">
                    <div class="pr-3 myview-view-label">My view name:</div>
                    <input id="myviews-view-name" type="text" placeholder="Start typing..." formControlName="viewName" [readonly]="!isNew"/>
                  </div>
                  <div class="d-flex flex-row flex-nowrap align-items-start w-100 pt-3 myview-view-label mar-top-3">
                    <div class="d-flex flex-column pl-3 pb-7 align-top">
                      <div class="mb-2">
                        SEARCH IN
                      </div>
                      <div class="d-flex flex-column flex-nowrap myviews-elem">
                        <div class="d-flex justify-content-end flex-column flex-nowrap pl-2 pt-2">
                          <app-checkbox-list [placeholder]="'Quick Search...'" [name]="'mail'" [controlType]="'radio'" [search]="false" [items]="mailboxesJson"
                            [listStyle]="{'height':'193px'}" [scroll]="scroll" [styleClass]="['advance-SearchIn-Items',  'listUpperCase']" (onItemSelect)="getSelectedBox($event)"></app-checkbox-list>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="false" class="d-flex flex-column ml-5 pl-5 pb-2">
                      <div class="mb-2">
                        GRIDVIEW PRIMARY COLUMNS
                      </div>
                      <div class="d-flex flex-column flex-nowrap myviews-elem">
                        <div>
                          <app-checkbox-list [placeholder]="'Quick Search...'" [name]="'groups'" [items]="myViewColumns" [listStyle]="{'height':'280px'}"
                            [scroll]="scroll" (onItemSelect)="getAssignedGroups($event)" [enableSelectAll]="true"
                            [search]="true"></app-checkbox-list>
                        </div>
                      </div>
                    </div>
                  </div>
                </p-tabPanel>

                <p-tabPanel header="CRITERIA">
                  <div class="flex-row flex-nowrap align-items-end w-100 pt-3">
                    <div style="font-weight: normal;font-size: 12px;font-family: 'InterstateLight'; color:#002d72;text-transform: uppercase;">
                      <img _ngcontent-bnh-c182="" src="assets/Inbox/info-icon.svg" alt="" class="icon-setting-out ng-tns-c182-10"> 
                        Last {{allowedMonthsInMyViews}} months data accessible from My view. Use QMA search function for old records.
                  </div>
                    <div id="builder-criteria"></div>
                  </div>
                </p-tabPanel>
                <!--
                <p-tabPanel header="SHARE">
                  SHARE
                </p-tabPanel>
                -->

                <p-footer style=" width: 69.7%; margin-left: -5px;">
                    <div class="d-flex flex-row footer-btn-container"></div>
                    <div class="d-flex flex-row flex-wrap bottom-button-holder px-3 pb-2 pt-3 justify-content-end">        
                        <button type="submit" [disabled]="disableSubmit" class="btn save-btn mr-4">Save</button>        
                        <button type="button" class="btn clear-btn" (click)="clearAndClose()">Reset</button>
                    </div>
                </p-footer>
          </p-tabView>
          
          
          </form>
          <app-action-popup></app-action-popup>    
    </div>
</div>





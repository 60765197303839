import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import * as Rx from "rxjs";
import { QmaConstant } from '../../constant/qma-constant';
import { AllToCcDBUser } from 'src/app/model/LoginUserInfo/AllToCcDBUser';
import { AppUtils } from 'src/app/common/utility/appUtil';
import { UserDataService } from '../user-data.service';

const httpFormPostOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  })
};

const httpOptions = {
  headers: new HttpHeaders({
    'Accept': '*/*',
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
  })
};
@Injectable({
  providedIn: 'root'
})
export class NewMessageService {
  index = 0;
  usersURL: string;
  inquiryURL: string;
  draftId: string;
  cmcURL: string;
  extInqUrl: string;
  resolveEnq = new Rx.BehaviorSubject<string>(null);

  queryCountValidation = new Rx.BehaviorSubject<string>(null);

  attachment = new Rx.BehaviorSubject([]);

  draftSubject = new Rx.BehaviorSubject([0]);

  insertedDraftIds = new Rx.BehaviorSubject([]);

  secureBtn = new Rx.BehaviorSubject([0]);

  addTopContacts = new Rx.BehaviorSubject([]);

  clcSource = new Rx.BehaviorSubject<boolean>(false);

  inlineReply = new Rx.BehaviorSubject<boolean>(false);

  sentDraftId = new Rx.BehaviorSubject(0);

  editorFocus = new Rx.BehaviorSubject<boolean>(false); // C153176-5273
// C153176-5262: refacor email validation regex
updateSecure = new Rx.BehaviorSubject<boolean>(false);
editorDefaultFontNameId = "";
editorDefaultFontSizeId = "";

email_regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  constructor(private http: HttpClient, private userDataService: UserDataService) {
    this.inquiryURL = QmaConstant.BASE_URL + '/inquiry';
    this.usersURL = QmaConstant.BASE_URL + '/users';
    this.cmcURL = QmaConstant.BASE_URL + '/cmc';
    this.extInqUrl = QmaConstant.BASE_URL + '/extInquiry';
    // get default font details from user profile
    this.userDataService.LocalGetLoginUserInfo().subscribe(loginUserInfo => {
      if (loginUserInfo && loginUserInfo.preferences 
        && loginUserInfo.preferences.find(p => p.key === QmaConstant.EDITOR_FONT_NAME_ID)
         && loginUserInfo.preferences.find(p => p.key === QmaConstant.EDITOR_FONT_SIZE_ID)) {
        this.editorDefaultFontNameId = loginUserInfo.preferences.find(p => p.key === QmaConstant.EDITOR_FONT_NAME_ID).value;
        this.editorDefaultFontSizeId = loginUserInfo.preferences.find(p => p.key === QmaConstant.EDITOR_FONT_SIZE_ID).value;
      }
    });
  }
  

  addIndex() {
    this.index++;
  }
  getIndex() {
    return this.index;
  }

  setResolveEnquiry(data) {
    this.resolveEnq.next(data);
  }

  getResolveEnquiry(): Observable<any> {
    return this.resolveEnq.asObservable();
  }

  setDraftSubject(data) {
    this.draftSubject.next(data);
  }

  getDraftSubject(): Observable<any> {
    return this.draftSubject.asObservable();
  }
  saveTopContact(topContact): Observable<any> {
    return this.http.post(this.usersURL + '/saveUserFavorites', JSON.stringify(topContact), httpFormPostOptions);
  }

  encodeBase64WithKey(body: string) {
    console.log('encodeBase64WithKey body =', body);
    let output = '';
    const key = QmaConstant.BASE64_SECRET_KEY;
    for (let i = 0; i < body.length; i++) {
      output += String.fromCharCode(body.charCodeAt(i) ^ key.charCodeAt(i % key.length));
    }
    console.log('encodeBase64WithKey keyed body =', output);
    let encoded = window.btoa(output);
    console.log('encodeBase64WithKey encoded =', encoded);
    return encoded;
  }

  encodeBase64(body: string): string {
    let encoded = window.btoa(body);
    return encoded;
  }

  saveInquiry(data, editorData): Observable<any> {
    // set default font details from profile settings
    if(this.editorDefaultFontNameId && this.editorDefaultFontNameId !== ""
    && this.editorDefaultFontSizeId && this.editorDefaultFontSizeId !== "") {
      data.editorFontNameId = this.editorDefaultFontNameId;
      data.editorFontSizeId = this.editorDefaultFontSizeId;
    }
    let req = "request=" + encodeURIComponent(JSON.stringify(data))
    let body = req + ":::editorData:" + encodeURIComponent(editorData);
    let encodedBody: string = this.encodeBase64(body);
    return this.http.post(this.inquiryURL + '/saveInquiry', encodedBody, httpFormPostOptions); //httpOptions);
  }

  saveDraft(data, editorData): Observable<any> {
    let req = "request=" + encodeURIComponent(JSON.stringify(data))
    let body = req + ":::editorData:" + encodeURIComponent(editorData);
    let encodedBody: string = this.encodeBase64(body);
    return this.http.post(this.inquiryURL + '/saveDraft', encodedBody, httpFormPostOptions);//C153176-4712 
  }
  saveScheduleForLater(data, editorData): Observable<any> {
    let req = "request=" + encodeURIComponent(JSON.stringify(data))
    let body = req + ":::editorData:" + encodeURIComponent(editorData);
    let encodedBody: string = this.encodeBase64(body);
    return this.http.post(this.extInqUrl + '/scheduleForLater', encodedBody, httpFormPostOptions); // C153176-5050: base64 on scheduleForLater
  }
  uploadMultipleFiles(fileUpload): Observable<any> {
    return this.http.post(this.inquiryURL + '/uploadMultipleFiles', fileUpload);
  }

  getDraftById(draftId): Observable<any> {
    let reqObj = { 'draftId': draftId };
    return this.http.post(this.inquiryURL + '/getDraftById', reqObj, httpFormPostOptions);
  }

  deleteDraftById(draftId): Observable<any> {
    let draftIdList = [];
    draftIdList.push(draftId);
    let reqObj = { 'draftIdList': draftIdList };
    return this.http.post(this.inquiryURL + '/deleteDraft', JSON.stringify(reqObj), httpFormPostOptions);
  }

  getDraftId(): string {
    return this.draftId;
  }

  setDraftId(draftId: string) {
    this.draftId = draftId;
  }

  setsentDraftId(draftId) {
    this.sentDraftId.next(draftId);
  }
  getsentDraftId() {
    return this.sentDraftId;
  }
  /** Behavior Subject for Conversation reply actions */
  replyActionSubject = new Rx.BehaviorSubject({});
  /* 
    setReplyContents(conversationDetails){
      this.replyActionSubject.next(conversationDetails);
    }
  
    getReplyContents(): Observable<any>{
     return this.replyActionSubject.asObservable();
    } */

  //TODO:Performance :: Need to update to Subject instead of Behavior Subject.
  inquiryReplySubject = new Rx.BehaviorSubject({});

  setInquiryReplyData(data) {
    this.inquiryReplySubject.next(data);
  }

  getInquiryReplyData(): Observable<any> {
    return this.inquiryReplySubject.asObservable();
  }

  /*  setAttachmentData(data){
     this.attachment.next(data);
   }
 
   getAttachmentData(): Observable<any>{
    return this.attachment.asObservable();
   } */
  //to update draft grid
  getInsertedDraftIds(): Observable<any> {
    return this.insertedDraftIds.asObservable();
  }
  //to update draft grid
  setInsertedDraftIds(inserteddDraftIds) {
    this.insertedDraftIds.next(inserteddDraftIds);
  }

  checkSecureUploadedFiles(fileUpload): Observable<any> {
    return this.http.post(this.inquiryURL + '/checkSecureUploadedFiles', fileUpload);
  }

  setSecureBtn(data) {
    this.secureBtn.next(data);
  }

  getSecureBtn(): Observable<any> {
    return this.secureBtn.asObservable();
  }

  setQueryCountValidation(data) {
    this.queryCountValidation.next(data);
  }

  getQueryCountValidation(): Observable<any> {
    return this.queryCountValidation.asObservable();
  }

  getContactListFromCMC(request): Observable<any> {
    return this.http.post(this.cmcURL + '/getContactListFromCMC', request);
  }

  getContactListFromTCL(request): Observable<any> {
    return this.http.post( QmaConstant.BASE_URL + '/contacts/getContactList', request);
  }

  getAddToTopContact(): Observable<any> {
    return this.addTopContacts.asObservable();
  }
  //to update draft grid
  setAddToTopContact(selectedContacts) {
    this.addTopContacts.next(selectedContacts);
  }

  setCLC(clc: boolean) {
    this.clcSource.next(clc)
  }
  getCLC(): Observable<boolean> {
    return this.clcSource.asObservable();
  }

  // C153176-5273: notify of editor focus
  setEditorFocus(focus: boolean) {
    this.editorFocus.next(focus)
  }
  getEditorFocus(): Observable<boolean> {
    return this.editorFocus.asObservable();
  }

  setupdateSecure(focus: boolean) {
    this.updateSecure.next(focus);
  }
  getupdateSecure(): Observable<boolean> {
    return this.updateSecure.asObservable();
  }
  /**
   * C153176-5445 : Method to get the user data based on name.
   * @param request
   */
  getUserDataByName(request): Observable<any> {
    return this.http.post(QmaConstant.BASE_URL + '/addressBook/getUserDataByName', request);
  }

  /**
   * C153176-5445 : Method to get the DL data based on name.
   * @param request 
   */
  getDLDataByName(request): Observable<any> {
    return this.http.post(QmaConstant.BASE_URL + '/addressBook/getDLDataByName', request);
  }
  /**
   * Method to send user notification.
   */
  addNotification(request): Observable<any> {
    return this.http.post(this.inquiryURL + '/addNotification', request, httpFormPostOptions);
  }
  /** 
  * method for send notification
  */
  sendNotification(request): Observable<any> {
    return this.http.post(this.inquiryURL + '/sendNotification', request, httpFormPostOptions);
  }

  async nonQmaCitiUser(element: string, field: string, componentRef): Promise<AllToCcDBUser> {
    let citiUser = null;
    if (componentRef && componentRef.parentComponent && !AppUtils.isUndefinedOrNull(componentRef.parentComponent.showSpinner)) {
      componentRef.parentComponent.showSpinner = true;
      console.debug(":SPINNER:: " + componentRef.parentComponent.showSpinner + " ::chat-view.nonQmaCitiUser");
    } else {
      componentRef.showSpinner = true;
      console.debug(":SPINNER:: " + componentRef.showSpinner + " ::new-message-recipent.nonQmaCitiUser");
    }

    try {
      let auId = componentRef.userDataService.loggedInUserInfo.globalDirectoryConfig.contactInfoConfig.auid;
      let contactInfoEndPoint = componentRef.userDataService.loggedInUserInfo.globalDirectoryConfig.contactInfoConfig.contactInfoEndPoint;
      const suggestionData = await componentRef.globalDirectoryService.getContactSuggestion(element.toUpperCase(), "BASIC", auId, contactInfoEndPoint).toPromise();
      let jsonObj = componentRef.globalDirectoryService.parseXmlToJson(suggestionData.toString());
      if (jsonObj && jsonObj.people && jsonObj.people.Person && jsonObj.people.Person instanceof Array) {
        // limit records to 20
        let totalCount = jsonObj.people.Person.length;
        if (jsonObj.people.Person.length > 20) {
          jsonObj.people.Person = jsonObj.people.Person.slice(0, 20);
        }

        //calling cims
        let userIdList: Array<string> = new Array<string>();
        jsonObj.people.Person.forEach(personObj => {
          userIdList.push(personObj['SoeId']['_text']);
        });
        const suggestionDataSoeID: any = await componentRef.globalDirectoryService.getUserDataBySoeId(userIdList).toPromise();
        let suggestionDataSoeIdObject = JSON.parse(suggestionDataSoeID);
        let userList: Array<object> = suggestionDataSoeIdObject['userDetailsList'];
        jsonObj.people.Person.forEach(personObj => {
          let userData = userList.filter(value => value['userId'].toUpperCase() === personObj['SoeId']['_text'].toUpperCase());
          personObj['Name']['_text'] = null != userData[0]['longDesc'] ? userData[0]['longDesc'] : personObj['Name']['_text'];
        });


        componentRef.suggestionField = field;
        let records = jsonObj['people']['Person'];
        //calling DL search on CIMS 
        /**/
        let reqObj = { dlName: encodeURIComponent(element.trim()) };
        let jsonObjDL = await componentRef.newMessageService.getDLDataByName(reqObj).toPromise();

        if (jsonObjDL instanceof Array) {
          // limit records to 20
          totalCount += jsonObjDL.length;
          if (jsonObjDL.length > 20) {
            jsonObjDL = jsonObjDL.slice(0, 20);
          }

          let dlList: Array<object> = [];
          jsonObjDL.forEach(personObj => {
            let dlObj = {};
            dlObj['Name'] = {};
            dlObj['EmailAddress'] = {};
            dlObj['SoeId'] = {};
            dlObj['Name']['_text'] = personObj['name'];
            dlObj['EmailAddress']['_text'] = personObj['email'];
            dlObj['SoeId']['_text'] = '';
            dlList.push(dlObj);
          });
          //this.displaySuggestion = true;
          //this.suggestionField = field;
          if (dlList.length > 0) {
            records.push(...dlList);
          }

        }
        console.log('show popup');
        componentRef.suggestionRecordCount = totalCount;
        componentRef.suggestionRecords = [...records];
        componentRef.displaySuggestion = true;
        /**/
      } else if (jsonObj && jsonObj.people && jsonObj.people.Person && jsonObj.people.Person instanceof Object) {
        let personObj = jsonObj['people']['Person'];
        let userIdList: Array<string> = new Array<string>();
        userIdList.push(personObj['SoeId']['_text']);
        const suggestionDataSoeID: any = await componentRef.globalDirectoryService.getUserDataBySoeId(userIdList).toPromise();
        let suggestionDataSoeIdObject = JSON.parse(suggestionDataSoeID);
        let userList: Array<object> = suggestionDataSoeIdObject['userDetailsList'];
        personObj['Name']['_text'] = null != userList[0]['longDesc'] ? userList[0]['longDesc'] : personObj['Name']['_text'];

        citiUser = new AllToCcDBUser();
        citiUser.value = citiUser.email = personObj['EmailAddress']['_text'];
        if (citiUser.value.length === 0) {
          citiUser = null;
          return citiUser;
        }
        citiUser.id = personObj['SoeId']['_text'];
        citiUser.active = false;
        citiUser.text = personObj['Name']['_text'];
      }
    } catch (error) {
      console.error('Error in nonQmaCitiUser ', error);
      citiUser = null;
      if (componentRef && componentRef.parentComponent && !AppUtils.isUndefinedOrNull(componentRef.parentComponent.showSpinner)) {
        componentRef.parentComponent.showSpinner = false;
        console.debug(":SPINNER:: " + componentRef.parentComponent.showSpinner + " ::chat-view.nonQmaCitiUser");
      } else {
        componentRef.showSpinner = false;
        console.debug(":SPINNER:: " + componentRef.showSpinner + " ::new-message-recipent.nonQmaCitiUser");
      }
    }
    if (componentRef && componentRef.parentComponent && !AppUtils.isUndefinedOrNull(componentRef.parentComponent.showSpinner)) {
      componentRef.parentComponent.showSpinner = false;
      console.debug(":SPINNER:: " + componentRef.parentComponent.showSpinner + " ::chat-view.nonQmaCitiUser");
    } else {
      componentRef.showSpinner = false;
      console.debug(":SPINNER:: " + componentRef.showSpinner + " ::new-message-recipent.nonQmaCitiUser");
    }
    return citiUser;
  }

  async searchDLName(element: string, field: string, componentRef): Promise<AllToCcDBUser> {
    let citiUser = null;
    if (componentRef && componentRef.parentComponent && !AppUtils.isUndefinedOrNull(componentRef.parentComponent.showSpinner)) {
      componentRef.parentComponent.showSpinner = true;
      console.debug(":SPINNER:: " + componentRef.parentComponent.showSpinner + " ::new-message-recipent.searchDLName");
    } else {
      componentRef.showSpinner = true;
      console.debug(":SPINNER:: " + componentRef.showSpinner + " ::new-message-recipent.searchDLName");
    }

    try {
      let reqObj = { dlName: encodeURIComponent(element.trim()) };
      let jsonObj = await componentRef.newMessageService.getDLDataByName(reqObj).toPromise();
     
      if (jsonObj instanceof Array) {
        // limit records to 20
        componentRef.suggestionRecordCount = jsonObj.length;
        if (jsonObj.length > 20) {
          jsonObj = jsonObj.slice(0, 20);
        }
        if (jsonObj.length === 0) {
          if (componentRef && componentRef.parentComponent && !AppUtils.isUndefinedOrNull(componentRef.parentComponent.showSpinner)) {
            componentRef.parentComponent.showSpinner = false;
            console.debug(":SPINNER:: " + componentRef.parentComponent.showSpinner + " ::new-message-recipent.searchDLName");
          } else {
            componentRef.showSpinner = false;
            console.debug(":SPINNER:: " + componentRef.showSpinner + " ::new-message-recipent.searchDLName");
          }
          return null;
        }
        else if (jsonObj.length === 1) {
          citiUser = new AllToCcDBUser();
          citiUser.value = citiUser.email = jsonObj[0]['email'];
          citiUser.id = jsonObj[0]['name'];
          citiUser.active = false;
          citiUser.text = jsonObj[0]['name'];
          citiUser.isFromDLSearch = true;
          if (componentRef && componentRef.parentComponent && !AppUtils.isUndefinedOrNull(componentRef.parentComponent.showSpinner)) {
            componentRef.parentComponent.showSpinner = false;
            console.debug(":SPINNER:: " + componentRef.parentComponent.showSpinner + " ::new-message-recipent.searchDLName");
          } else {
            componentRef.showSpinner = false;
            console.debug(":SPINNER:: " + componentRef.showSpinner + " ::new-message-recipent.searchDLName");
          }
          return citiUser;
        }

        let dlList: Array<object> = [];
        jsonObj.forEach(personObj => {
          let dlObj = {};
          dlObj['Name'] = {};
          dlObj['EmailAddress'] = {};
          dlObj['SoeId'] = {};
          dlObj['Name']['_text'] = personObj['name'];
          dlObj['EmailAddress']['_text'] = personObj['email'];
          dlObj['SoeId']['_text'] = '';
          dlList.push(dlObj);
        });

        console.log('show popup');
        componentRef.displaySuggestion = true;
        componentRef.suggestionField = field;
        componentRef.suggestionRecords = dlList;
      } else if (jsonObj instanceof Object) {
        let personObj = jsonObj;
        citiUser = new AllToCcDBUser();
        citiUser.value = citiUser.email = jsonObj['email'];
        if (citiUser.value.length === 0) {
          citiUser = null;
          if (componentRef && componentRef.parentComponent && !AppUtils.isUndefinedOrNull(componentRef.parentComponent.showSpinner)) {
            componentRef.parentComponent.showSpinner = false;
            console.debug(":SPINNER:: " + componentRef.parentComponent.showSpinner + " ::new-message-recipent.searchDLName");
          } else {
            componentRef.showSpinner = false;
            console.debug(":SPINNER:: " + componentRef.showSpinner + " ::new-message-recipent.searchDLName");
          }
          return citiUser;
        }
        citiUser.id = personObj['name'];
        citiUser.active = false;
        citiUser.text = personObj['name'];
      }
    } catch (error) {
      console.error('Error in searchDLName ', error);
      citiUser = null;
      if (componentRef && componentRef.parentComponent && !AppUtils.isUndefinedOrNull(componentRef.parentComponent.showSpinner)) {
        componentRef.parentComponent.showSpinner = false;
        console.debug(":SPINNER:: " + componentRef.parentComponent.showSpinner + " ::new-message-recipent.searchDLName");
      } else {
        componentRef.showSpinner = false;
        console.debug(":SPINNER:: " + componentRef.showSpinner + " ::new-message-recipent.searchDLName");
      }

    }
    if (componentRef && componentRef.parentComponent && !AppUtils.isUndefinedOrNull(componentRef.parentComponent.showSpinner)) {
      componentRef.parentComponent.showSpinner = false;
      console.debug(":SPINNER:: " + componentRef.parentComponent.showSpinner + " ::new-message-recipent.searchDLName");
    } else {
      componentRef.showSpinner = false;
      console.debug(":SPINNER:: " + componentRef.showSpinner + " ::new-message-recipent.searchDLName");
    }

    return citiUser;
  }

  public handleEmail(tokenInput, Temail, field, componentRef): boolean {
    componentRef.filteredToUsersMultiple = [];
    //Temail = Temail.split(';');
    let invalid = [];
    let email = Temail.split(';');//[];
    let isNonQmaCitiUser: boolean = false;
    let isDLSearch: boolean = false;
    let isExternalEmailExist = false;
    if (email.length) {
      email.forEach(async element => {
        element = element.trim();
        if (/\<([^\>]+)\>/g.test(element)) {
          let tempArr = /\<([^\>]+)\>/g.exec(element);
          element = tempArr[1];
        }
        if (element) {
          let item = new AllToCcDBUser();

          // search in QMA onboarded user list
          if (AppUtils.isUndefinedNullOrBlankStrAfterTrim(item.email) && componentRef.allToCcDBUser) {
            const appUser = componentRef.allToCcDBUser.find(user => user.id === element);
            if (appUser) {
              item = { ...appUser };
              item.isExternal = false;
            }
          }
          // search in QMA onboarded DL list
          if (AppUtils.isUndefinedNullOrBlankStrAfterTrim(item.email) && componentRef.allActiveGroups) {
            const appDl = componentRef.allActiveGroups.find(dl => dl.text === element);
            if (appDl) {
              item.text = appDl.text;
              item.email = appDl.email;
              item.value = appDl.value;
              item.id = appDl.id;
              item.isExternal = false;
            }
          }
          // search in QMA onboarded external email list
          if (AppUtils.isUndefinedNullOrBlankStrAfterTrim(item.email) && componentRef.extRecipients) {
            const appExternalContact = componentRef.extRecipients.find(ec => ec.text === element);
            if (appExternalContact) {
              {
                item.text = appExternalContact.text;
                item.email = appExternalContact.email;
                item.value = appExternalContact.value;
                item.id = appExternalContact.id;
              }
            }
          }
          //search for users in GD
          //check for non QMA citi user
          isNonQmaCitiUser = AppUtils.validateNonQmaCitiUserSoeId(element);
          if (AppUtils.isUndefinedNullOrBlankStrAfterTrim(item.email) && isNonQmaCitiUser) {
            // check for non QMA and Citi user
            const nonQmaCitiUser = await this.nonQmaCitiUser(element, field, componentRef);
            if (nonQmaCitiUser) {
              item = { ...nonQmaCitiUser };
              // C170665-1897 Recipient Missing in Reply All for Non QMA User
              // check for Citi Non QMA user
              if (this.isNonQMAUser(componentRef.userDataService.allToCcDBUser ,item.id)) {
                nonQmaCitiUser.id = null;
                item.id = null;
              }
            }
            else {
              if (!componentRef.displaySuggestion) {
                //item = { text: element, value: element, email: element, id: '', active: false };
                const dlName = await this.searchDLName(element, field, componentRef);
                if (dlName) {
                  item = { ...dlName };
                } else {
                  if (!componentRef.displaySuggestion) {
                    item = { text: element, value: element, email: element, id: '', active: false };
                  } else {
                    tokenInput.value = ""
                    return;
                  }
                }

              } else {
                tokenInput.value = ""
                return;
              }

            }
          }
          isDLSearch = AppUtils.validateDLName(element);
          if (AppUtils.isUndefinedNullOrBlankStrAfterTrim(item.email) && isDLSearch) { //search for DL in CIMS
            const dlName = await this.searchDLName(element, field, componentRef);
            if (dlName) {
              item = { ...dlName };
            } else {
              if (!componentRef.displaySuggestion) {
                item = { text: element, value: element, email: element, id: '', active: false };
              } else {
                tokenInput.value = ""
                return;
              }
            }

          }
          if (AppUtils.isUndefinedNullOrBlankStrAfterTrim(item.email)) {
            item = { text: element, value: element, email: element, id: '', active: false };
          }
          //verify if email id is correct
          if (item && item.email !== "" && !this.email_regex.test(item.email)) { // C153176-5262: refactor regex
            invalid.push(item.email);
          }
          if (invalid.length > 0) {
            // tokenInput.value = "";
            componentRef.closeFlagInvalid = true;
            setTimeout(() => { componentRef.closeFlagInvalid = false; }, 3000);
             // C170665-1290 Reply :Email Addresses drop off without notifiing user.
            // validate while sending add in receipient at this stage
            // return false;
          }
          //add external flag to item
          item.isExternal = AppUtils.isExternalEmailInToCc(item.email, componentRef.userDataService.loggedInUserInfo.citiDomainList);
          if (item.isExternal) {
            isExternalEmailExist = true;
          }
          switch (field) {
            case "to":
              if (!AppUtils.isDuplicate(item, componentRef.toUsersList)) {
                componentRef.toUsersList.push(item);
              }
              if (componentRef.parentForm) {
                componentRef.parentForm.get('toUsers').patchValue(componentRef.toUsersList);
              } else if (componentRef.chatForm) {
                componentRef.chatForm.get('toUsers').patchValue(componentRef.toUsersList);
              } else if (componentRef.templateForm) {
                componentRef.templateForm.get('toList').patchValue(componentRef.toUsersList);
              }
              tokenInput.value = "";
              break;
            case "cc":
              if (!AppUtils.isDuplicate(item, componentRef.ccUsersList)) {
                componentRef.ccUsersList.push(item);
              }
              if (componentRef.parentForm) {
                componentRef.parentForm.get('ccUsers').patchValue(componentRef.ccUsersList);
              } else if (componentRef.chatForm) {
                componentRef.chatForm.get('ccUsers').patchValue(componentRef.ccUsersList);
              } else if (componentRef.templateForm) {
                componentRef.templateForm.get('ccList').patchValue(componentRef.ccUsersList);
              }
              tokenInput.value = "";
              break;
            case "bcc":
              if (!AppUtils.isDuplicate(item, componentRef.bccUsersList)) {
                componentRef.bccUsersList.push(item);
              }
              if (componentRef.parentForm) {
                componentRef.parentForm.get('bccUsers').patchValue(componentRef.bccUsersList);
              } else if (componentRef.chatForm) {
                componentRef.chatForm.get('bccUsers').patchValue(componentRef.bccUsersList);
              } else if (componentRef.templateForm) {
                componentRef.templateForm.get('bccList').patchValue(componentRef.bccUsersList);
              }
              tokenInput.value = "";
              break;
          }
        }
      });
    }
    return isExternalEmailExist;
  }

  resolveFullDisplayName(field, componentRef) {
    let citiDomains = componentRef.userDataService.loggedInUserInfo.citiDomainList;
    switch (field) {
      case "to":
        componentRef.toUsersList.forEach(async user => {
          console.log(user);
          //if user does not have long desc and is citi user
          if (user && AppUtils.isUndefinedNullOrBlank(user.id) && user.text.indexOf('[') < 0 && user.text.indexOf(']') < 0 && user.email
            && AppUtils.isCitiDomainEmail(user.email, citiDomains)) {
            const nonQmaCitiUser = await this.nonQmaCitiUser(user.email, field, componentRef);
            console.log(nonQmaCitiUser);
            user.text = nonQmaCitiUser ? nonQmaCitiUser.text : user.text;
          }
        });
        break;
      case "cc":
        componentRef.ccUsersList.forEach(async user => {
          console.log(user);
          //if user does not have long desc and is citi user
          if (user && AppUtils.isUndefinedNullOrBlank(user.id) && user.text.indexOf('[') < 0 && user.text.indexOf(']') < 0 && user.email
            && AppUtils.isCitiDomainEmail(user.email, citiDomains)) {
            const nonQmaCitiUser = await this.nonQmaCitiUser(user.email, field, componentRef);
            console.log(nonQmaCitiUser);
            user.text = nonQmaCitiUser ? nonQmaCitiUser.text : user.text;
          }
        });
        break;
      case "bcc":
        componentRef.bccUsersList.forEach(async user => {
          console.log(user);
          //if user does not have long desc and is citi user
          if (user && AppUtils.isUndefinedNullOrBlank(user.id) && user.text.indexOf('[') < 0 && user.text.indexOf(']') < 0 && user.email
            && AppUtils.isCitiDomainEmail(user.email, citiDomains)) {
            const nonQmaCitiUser = await this.nonQmaCitiUser(user.email, field, componentRef);
            console.log(nonQmaCitiUser);
            user.text = nonQmaCitiUser ? nonQmaCitiUser.text : user.text;
          }
        });
        break;
    }
  }

  isNonQMAUser(qmaOnBoardedUsers, soeId) {
    if (!qmaOnBoardedUsers || !soeId) {
      return false;
    }
    if (Array.isArray(qmaOnBoardedUsers) && typeof soeId === "string") {
      return !qmaOnBoardedUsers.some(ou => ou.id === soeId.toLowerCase());
    } else {
      return false;
    }

  }
}

import {Component} from "@angular/core";
import {ICellRendererAngularComp} from "@ag-grid-community/angular";
import {SliderModule} from 'primeng/slider';
import { OverlayPanel } from 'primeng/overlaypanel';
import { ExcelService, InqRulesReorderInfo } from 'src/app/services/common/excel.service';

@Component({
  selector: 'app-color-picker',
  template: `<span class="notify-badge-inq-rules" (click)="openInqReorderPanel($event, opInqReorder)" >{{inqRuleOrder}}</span>
            <p-overlayPanel #opInqReorder  [appendTo]="'body'" [styleClass]="'qma-inq-rules-reorder'">
            <div class="inq-rules-popup-header-container">
            <span class="qma-inq-rule-popup-header">Current Order <input style ="color: #00B0B9;" [(ngModel)]="sliderInput" class="inq-rule-reorder-input"></span>
            <span class="qma-inq-rules-reorder-close" (click)="rearrangeInqRules($event, opInqReorder)">
            <img [src]="'assets/Inbox/close_secondary_columns.svg'" 
             class="qma-secondary-close-icon">
            <span>
            CLOSE
            </span>
           </span>
           
           </div>
            <p-slider [(ngModel)]="sliderInput" [min]="1" [max]="maxRange"></p-slider>
            <div class ="d-flex flex-row mt-3" style ="color: #002D72;" >
            <h6>1</h6>
            <h6 style = "margin-left: 31.5em;">{{this.maxRange}}</h6>
            </div>
            </p-overlayPanel>
            `,
  styleUrls: ['./inquiry-order-component.scss']
})


export class InquiryOrderComponent implements ICellRendererAngularComp {
  public params: any;
  public gridApi : any;
  public sliderInput: number;
  public inqRuleOrder: number;
  public value: any;
  public maxRange: number;

  agInit(params: any): void {
      this.params = params;
      this.gridApi = params.api;
      this.sliderInput = (this.params.value) ? this.params.value : '';
      this.inqRuleOrder = (this.params.value) ? this.params.value : '';
      //console.log("COLOR="+this.color);
      this.maxRange = this.gridApi.getDisplayedRowCount();

  }

  constructor(private excelService: ExcelService) {

  }

  onKeyDown(event): void {
    if (event.keyCode == 13) {
      this.updateValue();
    }
  }

  updateValue() {
    //this.params[this.fieldname] = this.getValue();
    //this.params.data.colorCode = this.colorCode;
  }
  getValue(): any {
   // return this.colorCode;
  }

  refresh(): boolean {
   // console.log("COLOR="+this.colorCode);
      return true;
      
  }

  openInqReorderPanel(event, overlayPanel) {
    // overlay should be closed only by using close buttom of popup
    if(!overlayPanel.visible) {
      overlayPanel.toggle(event);
    }
    
  }

  rearrangeInqRules(event, overlayPanel) {
    overlayPanel.hide();
    let inqRulesReorderInfo = new InqRulesReorderInfo();
    inqRulesReorderInfo.originalOrderNumber = this.inqRuleOrder;
    inqRulesReorderInfo.newOrderNumber = this.sliderInput;
    this.excelService.setinqRulesReorderInfo(inqRulesReorderInfo);

  }
}
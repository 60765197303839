<div id="inquiries-by-group-box" class="input-holder">
    <div id="inquiry-by-group" [hidden]="!showbyAge" class="dashboardChart"></div>
    <div class="drillDownCard" [hidden]="showbyAge">
        <div class="card">
            <div>
                <div class="drillDownWrapper">
                    <div id="inquiry-by-group-age"  class="dashboardChart"></div>
                </div>
            </div>
        </div>
    </div>
    <fading-dialog [chartId]= "'assignedGroupDialog'" #assignedGroupDialog></fading-dialog>
</div>
import { Component, OnInit, Input, OnChanges, EventEmitter, Output } from '@angular/core';
import { InboxService } from "src/app/services/inbox.service";
import { UserDataService } from "src/app/services/user-data.service";
import { ExcelService, ExcelExport } from "src/app/services/common/excel.service";
import { DatePipe } from "@angular/common";
import  * as _ from 'underscore';

const NOTES_DETAILS = {
  FILE_NAME: "Export Notes Details.xls",
  SHEET_NAME: "Notes Details",
  HEADERS: ["Date & Time", "Note","Updated By"]
}

@Component({
  selector: 'app-notes-details',
  templateUrl: './notes-details.component.html',
  styleUrls: ['./notes-details.component.scss']
})
export class NotesDetailsComponent implements OnInit, OnChanges {
  
  @Input() notes: any = [];
  @Input() selectedInquiryId: any;
  @Input() assignedGroupName: any; // C153176-4903: Pass selected group name while new notes added
  @Output() noteAdded: any = new EventEmitter();

  notesList: any = [];
  noteArr:any = [];
  noteCount: number = 0;
  toggleAdd: boolean = false;
  note: string = '';
  inquiryId: number;
  userName: string ='';

  constructor(private inboxService: InboxService, private userDataService: UserDataService, private excelService: ExcelService, private datePipe: DatePipe) { }

  ngOnInit() { 
    this.userDataService.LocalGetLoginUserInfo().subscribe(loginUserData => {
      this.userName = loginUserData.userName;
    });
  }

  ngOnChanges(): void {
    this.notesList = [];
    this.noteCount = 0;
    // the notes are getting disappeared -C153176-4653
    if (this.selectedInquiryId != this.inquiryId) {
      this.noteArr = [];
    }
    this.inquiryId = null;
    this.toggleAdd = false;
    // C153176-6039 - This loop should execute if count is greater than 0.
    if (this.notes && this.notes.length > 0) {
      let noteData = this.noteArr.length == 0 ? this.notes : this.noteArr;
      this.noteCount = noteData.length;

      // C153176-4424 | Sort the notes list to descending order.
      // C153176-6039 | Add note model change for date while WS re-design
      // When we fetch the date from DB it is in form of 2020-10-21T04:39:34.872Z, we need to change into 1603255174872 (i.e in seconds)
      let noteList = noteData.map(note => {
        note.crtDate = new Date(note.commentDate.$date).getTime();
        return note;
      });
      noteList = _.sortBy(noteList, function (i) { return -i.crtDate; });
      Array.prototype.push.apply(this.notesList, noteList);
    }
    if (this.selectedInquiryId) {
      this.inquiryId = this.selectedInquiryId;
    }
  }

  /**
   * Method to toggle add note text area.
   */
  showHideAddNote(): void {
    this.toggleAdd = !this.toggleAdd;
  }

  /**
   * Method to handle cancel action for Note
   */
  cancelAddNote(): void {
    this.note = '';
    this.toggleAdd = false;
  }

  addNote(): void {
    console.log("Entered note : ", this.note);
    this.toggleAdd = false;
    const inquiryIds = [];
    inquiryIds.push(this.inquiryId);

    let noteDetails = {
      inquiryIds:inquiryIds,
      userNote:this.note
    }

    // Call service to add notes to the inquiry
    this.inboxService.addNoteToInquiry(noteDetails).subscribe(result => {
      if (result.processingstatus) {        
        console.log(this.note, 'is added successfully');

        let currentDate = new Date();
        //C153176-6039 | Add note model change for date while WS re-design
        let addNote = {
          commentDate: {
            $date: currentDate.getTime()
          },
          userName: this.userName,
          comments: this.note,
          origin: 'inbox'
        };
        // the notes are getting disappeared -C153176-4653
        if(this.note){
          this.notesList.unshift(addNote); //  C153176-4424 | Add new note at the beginning.
        }        
        this.noteArr = this.notesList; 
        this.noteCount = this.notesList.length;
        this.note='';
        // C153176-4903: Pass selected group name while new notes added
        // C153176-6039 | Add note model change for date while WS re-design
        this.noteAdded.emit({ comments: addNote.comments, userName: addNote.userName, commentDate: { $date: addNote.commentDate.$date }, inquiryId: this.inquiryId, assignedGroupName: this.assignedGroupName, origin: addNote.origin });
      } else {
        console.log('Failed to add notes due to ', result);
      }
    }, error => {
      console.log('Failed to add notes due to ', error);
    });
  }

  /**
   * Method to download all the notes to excel
   */
  downloadNotes(): void {
    const notesData: any = [];

    this.notesList.forEach(note => {
      let notesArr: string[] = [];
      notesArr[0] = this.datePipe.transform(note.commentDate.$date, 'M/d/yyyy h:mm:ss a'); // C153176-6039
      notesArr[1] = note.comments;
      notesArr[2] = note.userName === null ? "" : note.userName;      
      notesData.push(notesArr);
    });

    const notesDetails: ExcelExport = {
      fileName: NOTES_DETAILS.FILE_NAME,
      sheetName: NOTES_DETAILS.SHEET_NAME,
      header: NOTES_DETAILS.HEADERS,
      data: notesData,
      width: [30, 60, 20],
      height: 20
    };

    console.log("Request to generate note trail report");
    this.excelService.generateExcel(notesDetails);
  }
}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { SliderModule } from 'primeng/slider';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { PanelModule } from 'primeng/panel';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { MultiSelectModule } from 'primeng/multiselect';
import { DropdownModule } from 'primeng/dropdown';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { PickListModule } from 'primeng/picklist';
import { DialogModule } from 'primeng/dialog';
import { TabViewModule } from 'primeng/tabview';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from '@dchtools/ngx-loading-v18';
import { AngularSplitModule } from 'angular-split';
import { GroupSetupComponent } from './group-setup/group-setup.component';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ListboxModule } from 'primeng/listbox'
import { MessageModule } from 'primeng/message'
import { FileUploadModule } from 'primeng/fileupload'
import { RadioButtonModule } from 'primeng/radiobutton'
import { InputTextareaModule } from 'primeng/inputtextarea';
import { UserAdminComponent } from './user-admin/user-admin.component';
import { SendUserNotificationComponent } from './send-user-notification/send-user-notification.component';
import { DatabaseAdminComponent } from './database-admin/database-admin.component';
import { AgGridModule } from '@ag-grid-community/angular';
import { MailboxDlMappingComponent } from './mailbox-dl-mapping/mailbox-dl-mapping.component';
import { UserGroupAssignmentComponent } from './user-group-assignment/user-group-assignment.component';
import { AdminSetupComponent } from './admin-setup/admin-setup.component';
import { SuperAdminSetupComponent } from './super-admin-setup/super-admin-setup.component';

@NgModule({
  declarations: [
    GroupSetupComponent,
    UserAdminComponent,
    SendUserNotificationComponent,
    DatabaseAdminComponent,
    MailboxDlMappingComponent,
    UserGroupAssignmentComponent,
    AdminSetupComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    OverlayPanelModule,
    ButtonModule,
    InputTextModule,
    TableModule,
    ToastModule,
    SliderModule,
    CalendarModule,
    CheckboxModule,
    InputSwitchModule,
    PanelModule,
    DialogModule,
    FileUploadModule,
    PickListModule,
    MessageModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.circleSwish,
      backdropBackgroundColour: 'rgba(0,0,0,0.1)',
      backdropBorderRadius: '4px',
      primaryColour: '#021e48',
      secondaryColour: '#021e48',
      tertiaryColour: '#021e48',
      fullScreenBackdrop: true
    }),
    TabViewModule,
    ConfirmDialogModule,
    MultiSelectModule,
    DropdownModule,
    ListboxModule,
    ProgressSpinnerModule,
    AngularSplitModule,
    InputTextareaModule,
    RadioButtonModule,
    // RouterModule.forChild(routes)
    AgGridModule,
  ],
  exports: [
    GroupSetupComponent, UserAdminComponent, SendUserNotificationComponent, DatabaseAdminComponent, MailboxDlMappingComponent, UserGroupAssignmentComponent, AdminSetupComponent
  ],
  providers: [MessageService, ConfirmationService]
})
export class AdminModule { }

import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CheckboxListComponent } from './component/checkbox-list/checkbox-list.component';
import { CheckboxListInputComponent } from './component/checkbox-list-input/checkbox-list-input.component';
import { CollapsibleListComponent } from './component/collapsible-list/collapsible-list.component';
import { GfidSearchComponent } from "./component/gfid-search/gfid-search.component";
import { LinkExceptionComponent } from "./component/link-exception/link-exception.component";
import { GroupDetailsComponent } from './component/group-details/group-details.component';
import { ReAgeComponent } from './component/re-age/re-age.component';
import { UpdateMemoComponent } from "./component/update-memo/update-memo.component";
import { ResolveInquiryComponent } from './component/resolve-inquiry/resolve-inquiry.component';
import { NotifyComponent } from './component/notify/notify.component';
import { ActionPopupComponent } from './component/action-popup/action-popup.component';

import { DialogModule } from 'primeng/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AgGridModule } from '@ag-grid-community/angular';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { SearchPipe } from './pipes/searchPipe';
import { OwnershipComponent } from './component/ownership/ownership.component';
import { DropdownModule } from 'primeng/dropdown';
import { RejectInquiryComponent } from "src/app/common/component/reject-inquiry/reject-inquiry.component";
import { ExpTinyComponent } from './component/exp-tiny/exp-tiny.component';
import { CreateRuleComponent } from './component/create-rule/create-rule.component';
import { OverlayPanel, OverlayPanelModule } from "primeng/overlaypanel";
import { DebounceClickDirective } from './directives/app-debounce-click.directive';
import { SnoozeInquiryComponent } from 'src/app/common/component/snooze/snooze-inquiry.component';
import { ListboxModule } from 'primeng/listbox';
import { MultiSelectModule } from 'primeng/multiselect';
import { TooltipModule } from 'primeng/tooltip';
import { GdirContactDetailsComponent } from './component/gdir-contact-details/gdir-contact-details.component';
//Attachment Viewer Dialog
import { AttachmentViewerDialogComponent } from '../common/component/attachment-viewer/attachmentviewer.dialog.component'
import { ImagePlaceholderComponent } from './image-placeholder/image-placeholder.component';
import { SafePipe } from '../common/pipes/safepipe.pipe';
import { SafeHtmlPipe } from "src/app/common/pipes/safe-html.pipe";
import { GlobalDirectorySuggestionComponent } from './component/global-directory-suggestion/global-directory-suggestion.component';
import { SymphonyCreateChatComponent } from 'src/app/symphony/symphony-create-chat/symphony-create-chat.component'
import { RadioButtonModule } from 'primeng/radiobutton';
import { CheckboxModule } from 'primeng/checkbox';
import { SafeUrlPipe } from '../common/pipes/safeUrlPipe.pipe';
import {ErrorPageComponent} from "./error-page/error-page.component";
@NgModule({
  declarations: [CheckboxListComponent, SearchPipe, CollapsibleListComponent, GfidSearchComponent, GroupDetailsComponent, LinkExceptionComponent, ReAgeComponent, UpdateMemoComponent, OwnershipComponent, ResolveInquiryComponent, ActionPopupComponent, RejectInquiryComponent, NotifyComponent, ExpTinyComponent, DebounceClickDirective, CheckboxListInputComponent, CreateRuleComponent, SnoozeInquiryComponent, AttachmentViewerDialogComponent, SafePipe, SafeUrlPipe, SafeHtmlPipe, ImagePlaceholderComponent,
    GdirContactDetailsComponent, GlobalDirectorySuggestionComponent, SymphonyCreateChatComponent],
  imports: [BrowserModule, NgScrollbarModule, AgGridModule, FormsModule, DialogModule,
    AutoCompleteModule, DropdownModule, ReactiveFormsModule, OverlayPanelModule,
    MultiSelectModule, TooltipModule, RadioButtonModule, CheckboxModule, ErrorPageComponent],
  exports: [CheckboxListComponent, SearchPipe, CollapsibleListComponent, GfidSearchComponent, LinkExceptionComponent, GroupDetailsComponent, ReAgeComponent, UpdateMemoComponent, OwnershipComponent, ResolveInquiryComponent, ActionPopupComponent, RejectInquiryComponent, NotifyComponent, ExpTinyComponent, DebounceClickDirective, CheckboxListInputComponent, CreateRuleComponent, SnoozeInquiryComponent, AttachmentViewerDialogComponent, SafePipe, SafeUrlPipe, SafeHtmlPipe, ImagePlaceholderComponent
    , GdirContactDetailsComponent, GlobalDirectorySuggestionComponent, SymphonyCreateChatComponent, ErrorPageComponent]
})
export class SharedModule { }

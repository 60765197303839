import { Component, OnInit, AfterViewInit, ViewChild, Input, EventEmitter, Output, ChangeDetectionStrategy, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import { InboxItem } from './InboxItem';
import { SwiperComponent, SwiperDirective, SwiperConfigInterface,
  SwiperScrollbarInterface, SwiperPaginationInterface } from 'ngx-swiper-wrapper';
import { ContextMenu } from 'src/app/model/Common/ContextMenu';
import { UserDataService } from 'src/app/services/user-data.service';
import { MobileInboxViewComponent } from '../mobile-inbox-view/mobile-inbox-view.component';
import * as $ from 'jquery';
import { TabDataService } from 'src/app/services/tab-data.service';
import { InboxService } from 'src/app/services/inbox.service';
import { AppUtils } from 'src/app/common/utility/appUtil';
import {formatDate} from '@angular/common';
import  * as _ from 'underscore';
import { NewMessageService } from 'src/app/services/newMessage/new-message.service';
import * as moment from 'moment';

const EMAIL_ADDR = "EMAIL_ADDR";
const USER_NAME = "USER_NAME";
const WILD_CHARACTERS = ['&lt;![endif]--&gt;&lt;![endif]--&gt;', '&lt;![endif]--&gt;', '<![endif]--><![endif]-->', '<![endif]-->'];
@Component({
  selector: 'app-mobile-inbox-item',
  templateUrl: './mobile-inbox-item.component.html',
  styleUrls: ['./mobile-inbox-item.component.scss'],
  changeDetection:ChangeDetectionStrategy.OnPush
})
export class MobileInboxItem implements OnInit {

  @Input() item: any;
  @Input() selectedItems: Array<any> = [];
  @Input() selectedItemCount: number;
  @Input() itemIndex: number;
  @Input() selectionEnabled: boolean = false;
  @Input() contextMenuOnly: boolean = false;
  // C153176-5043: insearch flag relayed to item level
  @Input() inSearch: boolean = false;
  @Input() selected = false;
  @Input() enableAllSlides: boolean = false;
  @Output() onContextMenu= new EventEmitter();
  @Output() onRowSelect = new EventEmitter();
  @ViewChild(SwiperComponent) componentRef: SwiperComponent;
  @ViewChild(SwiperDirective) directiveRef: SwiperDirective;
  
  loginUserInfo:any;
  currentTab:string;
  selectAll:boolean = false;
  isDraft:boolean = false;
  //C153176-5101: flag whether the view is pending approval
  isPendingApproval: boolean = false;
  private color = ['#4DD8FF','#4DD8FF', '#4DD8FF', '#4DD8FF']; //COntext Menu items color for row
  private rowId: number = -1; 
  menuitems:Array<any>;
  rmenuitems:Array<any>;
  public indexes = [ "rswipe", "grid", "slider" ];
  parser: any; //C153176-3479 - parser issue
  // C153176-4796: flag whether to allow right swipe
  allowRSwipe: boolean = true;
  activeGroups: any[] = [];
  assignedTo : boolean = false

  public swipeConfig: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 1,
    keyboard: true,
    mousewheel: true,
    scrollbar: false,
    navigation: false,
    pagination: false
  };

  constructor( private contextMenu: ContextMenu, private userDataService: UserDataService,private inboxService:InboxService,
    private viewComponent: MobileInboxViewComponent, private cdr: ChangeDetectorRef,private tabDataService:TabDataService, private newMessageService:NewMessageService) {
    this.userDataService.LocalGetLoginUserInfo().subscribe(loginUserData => {
      this.loginUserInfo = loginUserData;
      this.activeGroups = this.loginUserInfo.allActiveGroups;
    });
    this.currentTab = this.viewComponent.inboxViewComponent.strMailboxView; // C153176-4796: fetch tabName from parent
    this.allowRSwipe = this.currentTab !== "DRAFTS" && this.currentTab !== "SENT";
    if (!this.allowRSwipe) {
      this.indexes = [ "grid", "slider" ];
    }
    this.isDraft = (this.currentTab.toUpperCase() == "DRAFTS");
    if(this.isDraft){
      this.color.shift();
    }
    //C153176-5101: set flag whether the view is pending approval
    this.isPendingApproval = (this.currentTab.toUpperCase() == "PENDING APPROVAL");
    this.parser = new DOMParser(); //C153176-3479 - parser issue
  }


  ngOnInit() {
  }

  // C153176-4796: adjust default swipe index
  getDefaultIndex() {
    return this.allowRSwipe? this.viewComponent.DEFAULT_SWIPER_INDEX: this.viewComponent.DEFAULT_SWIPER_INDEX -1;
  }

  ngAfterViewInit() {
    // C153176-4796: flag whether to allow right swipe
    this.currentTab = this.viewComponent.inboxViewComponent.strMailboxView; // C153176-4796: fetch tabName from parent
    this.allowRSwipe = this.currentTab !== "DRAFTS" && this.currentTab !== "SENT";
    if (!this.allowRSwipe) {
      this.indexes = [ "grid", "slider" ];
      // C153176-5011 check whether the item has been removed
      if (!this.cdr['destroyed']) {
        this.cdr.detectChanges();
      }
    }
    if (!this.contextMenuOnly) {
      this.viewComponent.registerItem(this.item, this.itemIndex, this.cdr);
      // C153176-4796: reset the swiper index to default index (showing inbox grid instead of right-swipe slide)
      this.resetSwiperIndex(this.getDefaultIndex());
      // C153176-5003: set all slides flag
      this.enableAllSlides = this.viewComponent.enableAllSlides;
    }
    if (this.viewComponent.inboxService) {
      this.viewComponent.inboxService.onMobileSearchChangedEvent.subscribe(ids => {
        if (!this.cdr['destroyed'] && this.item && ids && ids.includes(this.item._id)) {
          this.cdr.detectChanges(); // C153176-4796 force update
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selected) {
      if (changes.selected.currentValue) {
        this.viewComponent.addSelectedItem(this.item);
      } else {
        this.viewComponent.removeSelectedItem(this.item);
      }
    }
  }

  buildContextMenu(item:any) {
    return 'assets/context/'+ item + "_mobile.svg";
  }

  getMenuItemName(item) {
    return item.name;
  }

  onIndexChange(event:any) {
    if (this.contextMenuOnly) {
      // do nothing if this view displays context menu only
      return;
    }
    if (this.viewComponent) {
      this.viewComponent.registerSwiperItem(this);
    }
  }

  resetSwiperIndex(index = 0) {
    if (this.componentRef) {
      this.componentRef.directiveRef.setIndex(index);
    } else if (this.directiveRef) {
      this.directiveRef.setIndex(index);
    }
  }

  onMenuItem(event: MouseEvent, item:any, triggeredFrom?:string) {
    console.debug('onMenuItem, event=', event);
    let items;
    if (event.srcElement['id'] === 'Others') {
       items = this.contextMenuOnly ? this.createContextArray(this.selectedItems) : this.createContextArray(item);
       this.triggerContextMenu(event, items);
    }
    else{
      if(item.items){
        items = item.items;
        this.triggerContextMenu(event, items);
      }
      else{
        item.command();
      }      
    }
    setTimeout(() => {
      // C153176-4796: reset swiper to grid after contextmenu  action
      this.resetSwiperIndex(this.getDefaultIndex());
      setTimeout(() => {
        // C153176-4925: force detect changes, C153176-4952: handle exception
        try {
          // C153176-5011 check whether the item has been removed
          if (!this.cdr['destroyed']) {
            this.cdr.detectChanges();
          }
        } catch (err) {
          console.log(err);
        }
      }, 100);
    }, 1000);
    event.stopPropagation();
  }

  triggerContextMenu(event:MouseEvent, items:any){
    this.onContextMenu.emit({
      x: event.x, 
      y: event.y - 50, 
      id: event.srcElement['id'],
      items: items
    });
  }

  onItemClick(row: any) {
    this.onRowSelect.emit(row);
    //C153176-4985: set 'selectedRowData' at inboxView component
    if (this.viewComponent.inboxViewComponent) {
      this.viewComponent.inboxViewComponent.selectedRowData = [this.item];
    }
  }

  /**
   * Generate first three context menu items
   * @param value: addition parameter to be used, for now ignored.
   */
  getRowMenuItems(value) {
    if (!this.menuitems || !this.menuitems.length) {
      let selectedRow = (!(this.item instanceof Array)) ? [this.item] : this.item;
      this.buildContextMenuItems(selectedRow);
    }
    return this.menuitems;
  }

  /**
   * C153176-4796: Generate right-swipe menu items
   * @param value: addition parameter to be used, for now ignored.
   */
  getRSwipeRowMenuItems(value) {
    // C153176-4796: update context menu per item current status
    let selectedRow = (!(this.item instanceof Array)) ? [this.item] : this.item;
    this.buildRSwipeMenuItems(selectedRow);
    return this.rmenuitems;
  }

  /**
   * Generate first three items for buck-select context menu
   * @param value: addition parameter to be used, for now ignored.
   */
  getBuckSelectMenuItems(value) {
    if (!this.menuitems || !this.menuitems.length) {
      this.buildContextMenuItems(this.selectedItems);
    }
    return this.menuitems;
  }

  /**
   * Method to populate context menu opened from ellipsis
   */
  createContextArray(item:any) {
    let selectedRow = (!(item instanceof Array)) ? [item] : item;
    let contextMenuItems = this.viewComponent.getContextMenuItems(selectedRow);
    console.log('createContextArray: item=', item, 'context menu=', contextMenuItems);
    if (contextMenuItems && contextMenuItems.length > 3) {
      contextMenuItems = contextMenuItems.slice(3, contextMenuItems.length);
    }
    // TBD here it returns an array of names, this is not enough, it should
    // also return the image for constructing popup menu with icons
    return contextMenuItems;//.map(e => e.name);
  }

  /**
   * Generate annex inner HTML
   * @param value specify additional annex data
   */
  getAnnex(value) {
    // ignore 'value' for now
    return this.viewComponent.renderAnnex(this.item);
  }

  /**
   * Get Client Category Color
   * @param value 
   */
  getClientCategory(value:any) {
    let style = this.viewComponent.renderClientCategory(value);
    let result = {};
    if (style) {
      result['border-left-color'] = style.colorCode;
    } 
    if (this.selected){
     // result['background-color'] = '#B7E4FF';
    }
    return result;
  }
  
  /**
   * Generate date string per required format
   * @param value specify additional date data (such as prefix/format)
   */
  getDate(value) {
    // ignore 'value' for now
    //return this.viewComponent.convertDate(this.item.modDate.$date); //Commented as for mobile we needto follow specific format irrespective of user preference
    /*Start  C153176-4795 Mobile changes after review 6th Jan*/  
    let currentDate = this.item.modDate.$date;
    let inquiryDate = moment(this.item.modDate.$date);
    let today = moment().startOf('day');   //Date for today
    let week_old = moment().subtract(6, 'days').startOf('day'); //Date week earlier
   
    let formatDate;
    switch(true){
      case inquiryDate.isSame(today, 'd'):  //For Today
        formatDate = moment(currentDate).format('hh:mm A');
      break;      
      case inquiryDate.isAfter(week_old):  //For Last week
        formatDate = moment(currentDate).format('ddd'); // ddd hh:mm A
      break;
      default:  //Before Last week
        formatDate = moment(currentDate).format('DD MMM hh:mm A');
      break;
    }

    return formatDate;
  }
  /*End  C153176-4795 Mobile changes after review 6th Jan*/  
  /**
   * Mark up / transofrm subject
   * @param value unused (reserved)
   */
  getSubject(value) {
    return this.markup(this.item.subject);
  }

  /**
   * Mark up / transofrm latestUserName
   * @param value unused (reserved)
   */
  getLatestUserName(value) {
    if(this.isDraft){
      if(this.item.recipients){
        return this.markup(this.item.recipients[0].displayName);
      }
      else{
        return "";
      }      
    }
    else{
      return this.markup(this.item.latestUserName);
    }
  }    
  

  /**
   * Mark up / transofrm headline
   * @param value unused (reserved)
   */
  getHeadline(value) {
    //C153176-3479 - parser issue
    let content = this.item.latestConversationPreviewText;
    if (content) {
      // Set Message content for collapsed converstaions        
      let parsedHtml = this.parser.parseFromString(content, 'text/html');
      let viewContent = parsedHtml.querySelector('body').innerText || "";
      let mailContent = viewContent;
      content = mailContent.length < 50 ? mailContent : mailContent.substring(0, 80) + '...';
      // null check
      if (!content) {
        content = '';
      }
    } else {
      content = "<p>&nbsp;</p>"; // C153167-4660 : Add blank to show mail body if content is blank.
    }
    
     return this.markup(content); //Uncomment while deployment
  }

  /**
   * C153176-5043: mark up method, refactored from view component
   */
  markup(value) {
    let result = value;
    if (this.inSearch && this.viewComponent.search.contextSearchTarget && result) {
      // C153176-5043: replace() returns a new string, reassign it to 'result'
      result = result.replace(new RegExp(this.viewComponent.search.contextSearchTarget, "gi"),
        (match) => `<mark>${match}</mark>`);
    }
    if (result) {
      result = result.split(/\r?\n/);
    }
    if (result && result.length) {
      return result[0];
    } else {
      return result;
    }
  }

  /**
   * C153176-5191 : create recipent model
   * @param closure Inquiry Object
   */
  getUserType(closure: any) {
    let userId: string;
    let displayName: string;
    let groupId: number = null;

    if (this.isDraft) {
      userId = this.item.userId;
      displayName = this.item.userId;
    }
    else {
      userId = this.item.latestUserId;
      displayName = this.item.latestUserName;
    }

    const group = this.activeGroups.find(group => userId == group.email);
    const qmaUser = this.loginUserInfo.allToCcDBUsers.find(user => userId == user.id);
    groupId = group ? +group.value : (qmaUser ? 0 : null);

    return {
      userId: userId,
      emailAddr: userId,
      groupId: groupId,
      displayName: displayName
    }
  }

  applyColor(index:number){
    return {'color':this.color[index]};
  }

  onClickCheckbox(event?:any) {
    this.selected = !this.selected;
    if (this.selected) {
      this.viewComponent.addSelectedItem(this.item);
      this.viewComponent.clearSwipingItem();
    } else {
      this.viewComponent.removeSelectedItem(this.item);
    }
    event.preventDefault();
    event.stopPropagation();
  }

  getCheckboxCls(sel) {
    return this.selected ? ['item-checkbox', 'fa', 'fa-check-square-o', 'fa-2x']:
    ['item-checkbox', 'fa', 'fa-square-o', 'fa-2x'];
  }

  getSelectedItemCls(sel) {
    return this.selected ? ['isSelected']:
    ['isUnSelected'];
  }

  private buildContextMenuItems(selectedItems: any) {
    let contextMenuItems = this.viewComponent.getContextMenuItems(selectedItems);
    let menuItems = contextMenuItems.slice(0, contextMenuItems.length > 3? 3: contextMenuItems.length);
    
    this.menuitems = menuItems;
    let maxLength = 4;

    // C153176-5319 - Prepare context menu for Pending approvals inquiry for solr search.
    let sRow = selectedItems ? selectedItems[0] : null;
    this.isPendingApproval = false;
    if (sRow && sRow.direction) {
      this.isPendingApproval = ["PENDINGAPPROVAL", "PND_REAGE", "NOMINATE_OWNERSHIP"].includes(sRow.direction);
    }
    //C153176-5101: do not add replyAll item in draft or pending approval
    if(this.isDraft || this.isPendingApproval){
      maxLength = 3;
    }
    else{
      this.addReplyAllContextMenuItem();
    }
    
    if(contextMenuItems.length > maxLength){
      let t = {label:'Others'};
      this.menuitems.push(t);
    }  
  }

  private buildRSwipeMenuItems(selectedItems: any) {
    let contextMenuItems = this.viewComponent.getRSwipeMenuItems(selectedItems);
    let menuItems = contextMenuItems.slice(0, contextMenuItems.length > 3? 3: contextMenuItems.length);
    
    this.rmenuitems = contextMenuItems;    
  }

  private addReplyAllContextMenuItem(){
    let obj = {
      label:'Reply All',
      icon:'replyAll',
      command:()=>{return this.openReplyAll()}
    }
    this.menuitems.unshift(obj);
  }

  private openReplyAll(){
    let selGridRow = this.item;
    if (selGridRow) {
      let selectInqAssignedGroupId = [];

      //[C153176-419]User should see only conversations for selected  groups only
      if (selGridRow.workflows) {
        selGridRow.workflows.forEach(workflow => {
          let assignedGroupId = workflow.assignedGroupId;
          if (this.userDataService.userGroupIdList.indexOf(assignedGroupId) > -1) {
            selectInqAssignedGroupId.push(assignedGroupId);
          }
        });
      }

      const assignedGroup = selGridRow.openGroups;

      // C153176-5223 | conversation loading issue for nomination
      let hasNominatedOwnership = selGridRow.workflows[0].hasNominatedOwnership !== undefined ? selGridRow.workflows[0].hasNominatedOwnership : false;

      // Workflow properties
     /*  let workflow = {
        queryCount: selGridRow.queryCount,
        requestType: selGridRow.requestTypeStr || null,
        inquirySource: selGridRow.inquirySource,
        processingRegion: selGridRow.processingRegion,
        rootCause: selGridRow.rootCause,
        tag: selGridRow.tag,
        groupId: selectInqAssignedGroupId[0]
      }; */
      let curInq = this.item;
      let inquiryData = {
        id: curInq._id,
        selectInqAssignedGroupId:selectInqAssignedGroupId,
        isAllConvContent: false, // C153176-4660: metadata only, for now
        hasNominatedOwnership: hasNominatedOwnership
      };

      // Send request to get the all the inquiry conversation for the selected inquiry Id.
      this.inboxService.getInquiryIdExtSelected(inquiryData, curInq).subscribe(inquiryDetail => {
        this.onReplyConversation(inquiryDetail)
      },
        error => {
          console.error('Error while getting response from getInquiryIdExtSelected()');
        });
    }
  }
  

  private unSelectBulkSelection(){
    this.inboxService.mobileCheckboxSelected = !this.inboxService.mobileCheckboxSelected;
    this.inboxService.notifyMobileCheckboxSelection();
    this.inboxService.onSelectAll(false);
  }

  private onSelectAll(val:boolean){
    this.selectAll = val;
    this.inboxService.onSelectAll(val);  
  }

  private getReadUnreadCls(val:boolean){
    return this.isRead(this.item) ? "isRead-cls" : "isUnread-cls";    
  }

  
  private isRead(data)  {
      let isReadby = false;
      //Removing read / unread , Jira 4572
      if (data && data.readBy && this.currentTab !== 'SENT') {
        for (let i = 0; i < data.readBy.length; i++) {
          if (this.loginUserInfo.userId == data.readBy[i]) {
            isReadby = true;
          }
        }
        if (isReadby) {
          return isReadby;
        }
      } else if(this.currentTab === 'SENT'){
        return true;
      }
    }

    /**
     
     */
   
    markInquiryAsRead(data) {
      if (!data || !this.contextMenu || !this.loginUserInfo) {
        return;
      }
      // to mark inquiry as unread only first inquiry will be marked as unread
      // on initial load first selected inquiry should not be marked as read
      this.contextMenu.markInquiryAsRead([data], this.loginUserInfo, [data], this.cdr);
    }
    
    /* 
      Method to replace DP with checkbox on bulk selection
    */
    hideDisplayPicture(){
      return !this.selected;
    }

    /**
     * Method to perform action on reply action based on type for a conversation
     * 
     * @param conversation - Conversation object
     */
    onReplyConversation(conversationObj: any): void {
      let tabName = "NEW MESSAGE";
      let conversation = conversationObj.conversationList[0];
      const recipients = conversation.recipients;
      let subject = this.updateSubject(conversation.subject);
      let content = this.replaceWildCharacters(conversation.content);
      let assignedGrp = this.item.openGroups;
      let selWorkFlow = this.item.workflow;
      let urgentFlag = "N";
      let subPrefix = "RE: ";

      let toRecipient: any = [];
      let ccRecipient: any = [];
      let bccRecipient: any = [];
      let attachments = (this.isNull(conversation.attachments)) ? [] : conversation.attachments;
      let nlpSuggestion: boolean = false;

      // C153176-4594 : Flag to set if reply action is initiated from CLC Suggestion acceptance.
      let clcSuggestion: boolean = false;

      let from: any = this.getRecipients(recipients, "FROM");
      let replyContent: string = '';
      Array.prototype.push.apply(toRecipient, this.getRecipients(recipients, "FROM"));
      Array.prototype.push.apply(toRecipient, this.getRecipients(recipients, "TO"));
      Array.prototype.push.apply(ccRecipient, this.getRecipients(recipients, "CC"));
      //bccRecipient = this.getRecipients(recipients, "BCC");
      tabName = 'Reply All';
      attachments = [];
      // Get Unique values
      if (toRecipient.length > 0) {
          toRecipient = _.uniq(toRecipient, 'email');
      }
      if (ccRecipient.length > 0) {
          ccRecipient = _.uniq(ccRecipient, 'email');
      }
      if (bccRecipient.length > 0) {
          bccRecipient = _.uniq(bccRecipient, 'email');
      }

      // C153176-4224 : If group exculed is true,  We should not included assigned group in toRecipient.
      if (this.userDataService.isGroupExcluded(assignedGrp)) {
          toRecipient = toRecipient.filter(rec => rec.text !== assignedGrp);
      }

      // Message content with last mail header
      //content = replyContent + this.getHeader(conversation) + content;
      subject = subPrefix + subject;

      const templateData = [
          {
              templateContent: content,              
              templateSubject: subject,
              toList: toRecipient,
              ccList: ccRecipient,
              bccList: bccRecipient,
              inquiryId: conversation.inquiryId
          }
      ];

      const workflow = conversationObj.inquiry.workflows[0];
      const recipientData = {
          from: assignedGrp,
          queryCount: workflow.queryCount,
          requestType: workflow.requestType,
          inquirySource: workflow.inquirySource,
          processingRegion: workflow.processingRegion,
          rootCause: workflow.rootCause,
          tag: workflow.tag,
          gfcName: this.item.gfcName,
          gfcid: this.item.gfcid,
          gfpName: this.item.gpName,
          gfpid: this.item.gpNum,
          fromWhere: 'messages'
      };

      const replyActionObj = {
          inquiryId: conversation.inquiryId,
          inquiryAction: "ReplyAll",
          suggestionConvId: conversation.id,
          userNotes: [],
          templateData: templateData,
          content: content,
          recipientData: recipientData,
          urgentFlag: urgentFlag,
          attachments: attachments,
          groupId: workflow.groupId,
          nlpSuggestion: nlpSuggestion, // C153176-4459 : Pass nlpSuggestion flag to new-message 
          clcSuggestion: clcSuggestion, // C153176-4594 : Pass clcSuggestion flag to new-message 
          selectedCLCTrades: '' // C153176-4594 | Need selected CLC trades
      };
      tabName = tabName + ": " + conversation.inquiryId;
      this.newMessageService.setInquiryReplyData(replyActionObj);
      this.tabDataService.sendTabData(tabName, 0);
  }

  /**
     * Method to update the subject while reply or forward.
     */
    updateSubject(conversationSubject: string): string {
      let subject = conversationSubject;
      if (subject.indexOf('RE:') === 0) {
          subject = subject.replace(/RE:/gi, '');
      }
      if (subject.indexOf('FW:') === 0) {
          subject = subject.replace(/FW:/gi, '');
      }
      return subject.trim();
  }

  /**
     * Method to remove unwanted wild characters in the conversation content.
     * 
     * @param conversationContent 
     */
    replaceWildCharacters(conversationContent: string): string {
      let content = conversationContent;
      if (content) {
          WILD_CHARACTERS.forEach(char => {
              if (content.includes(char)) {
                  content = content.replace(char, '');
              }
          });
      }
      return content;
  }

  /**
     * Method to get the recipient list based on type of recipient
     * 
     * @param recipients - Mail Recipients 
     * @param type - Type of recipient
     */
    getRecipients(recipients: any, type: string): any {
      return recipients.filter(recipient => recipient.toFrom === type).map(recipient => {
          
          let emailAddr = recipient.emailAddr;
          if (emailAddr === null) {
              emailAddr = AppUtils.extractEmailAddrOrName(EMAIL_ADDR, recipient.displayName);
          }

          // C153176-4429 : Need to show alias name on reply
          let displayName = recipient.displayName;
          if (displayName.indexOf('<') > -1) {
              displayName = AppUtils.extractEmailAddrOrName(USER_NAME, recipient.displayName);
          }
          return {
              text: displayName,
              value: emailAddr,
              email: emailAddr,
              id: recipient.groupId,
              country: '',
              timeZone: '',
              active: true
          };
      });
  }

  // Null value check function
  isNull(input) {
    if (input === "" || input === undefined || input == null) {
        return true;
    }
    return false;
  }

  checkIfDraft(idx:number){
    if(!this.isDraft){
      return (idx == 0);
    }
    return false;
  }

  /**
   * C153176-4795 #10 - Method to check the conversation count with unread count and apply class accordingly
   */
  //Websocket Implementation is pending for the same
  checkAllRead(){
    return (this.item.convCount == this.item.unreadCount);  //unreadCount to be replaced with node on service once service is Ready
  }

  //C170665-1758 code for showing Assgined To User in Mobile View
  getAssignedTo() {
    let formatUserName = ''
    this.assignedTo = true
    formatUserName = this.item.workflows[0].assignedUserName.substring(0,this.item.workflows[0].assignedUserName.indexOf('('))
    formatUserName = formatUserName.length > 12 ? (formatUserName.substring(0,10) + '..') : formatUserName
    return formatUserName
  }
 }

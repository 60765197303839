<div class="newMsgWrapper">
  <ng-template #customLoadingTemplate>
    <div class="custom-class-loader">
        <div class="spinner-container">
            <svg class="loader" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340 340">
                <circle cx="170" cy="170" r="160" stroke="#021e48" />
                <circle cx="170" cy="170" r="135" stroke="#00bdf2" />
                <circle cx="170" cy="170" r="110" stroke="#021e48" />
                <circle cx="170" cy="170" r="85" stroke="#00bdf2" />
            </svg>
        </div>
    </div>
</ng-template>

<ngx-loading [show]="showSpinner" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate"></ngx-loading>

     <!-- C153176-5343: enable attach icon highlight when external files drag in  -->
    <div fileDragEnter class="form-horizontal mobile-whitespace" [formGroup]="parentForm" (drop)="drop($event, 'parent')" (dragover)="allowDrop($event)">
      <div class="d-flex flex-column control-group">
        <div class="d-flex flex-row align-items-start mobile-toccbcc"> <!-- C153176-5194:To/CC/ BCC changes  -->
          <label for="from" class="min-width from from mr-2">From: </label>
  
          <p-dropdown class="drop-down w-100 from-section " [options]="groupNames" formControlName="groupName" [styleClass]="'prime-dd-override'"
            [filter]="true" (onChange)="onGroupNameChange($event)" [disabled]="!fromNewMessage"> <!--C153176-5068 | From field is enable only for new message-->
          </p-dropdown>
          <div *ngIf="parentForm.controls.groupName.errors && (parentForm.controls.groupName.dirty || parentForm.controls.groupName.touched)" class="">
            <img src="assets/newMessage/error.svg" class="error" title="GroupName is Required">
        </div>
        </div>
         <!-- Jira : C153176-4601 Mobile: Reply /Reply All -->
          <!-- Jira : C153176-5194 Mobile -To/CC/ BCC changes -->
        <div class="d-flex flex-row align-items-start  control-group mob-to mobile-toccbcc" [ngClass]="isAutoCompleteDisabled ? 'disableSuggestion' : ''">
          <!-- Jira : C170665-1962 Mobile -formatting issue for To mobile -->
          <label *ngIf="!isMobile" for="to" class="min-width mt5" (click)="showPopup($event, 'to', op)"><span class="to-cc-bcc">To... :</span></label>
          <label *ngIf="isMobile" for="to" class="min-width" (click)="showPopup($event, 'to', op)"><span>To:</span></label>
          <p-autoComplete #toUsersData formControlName="toUsers" [suggestions]="filteredToUsersMultiple"
            (completeMethod)="filterUsers($event)" [minLength]="3" field="text" [multiple]="true" styleClass="override override-newmessage-autocomplete mob-mt5"
            class="w-100" [(ngModel)]="toUsersList" (onKeyUp)="onKeyUp($event, 'to')" (drop)="drop($event, 'to')" (dragover)="allowDrop($event)"
            (onBlur)="onBlur($event, 'to')" (onUnselect)="onRemoveRecipient($event)" (onSelect)="onSelectRecipient($event)"> <!--C153176-4930 -->
            <ng-template let-value pTemplate="selectedItem">
                <span [ngClass]="value.isExternal?'external':''" oncontextmenu="return false;" class="p-autocomplete-token-label new-message-token" 
                (contextmenu)="addToTop($event, addtotop, value , 'to')" draggable="true" (dragstart)="drag($event, 'to', value)" 
                (click)="displayContactInfo($event, opContactInfo, value)"
                title="{{value.email}}">{{value.text}}</span>
            </ng-template>
          </p-autoComplete>
          <p-overlayPanel #opContactInfo [appendTo]="'body'" [dismissable]="true" [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'">
              <gdir-contact-details *ngIf="selectedContactId?.length>0" [soeId]="selectedContactId"></gdir-contact-details>
          </p-overlayPanel>
          <div class="clear-input" *ngIf="toUsersList.length" (click)="clearToCcBcc('to')">x</div>
          <div *ngIf="parentForm.controls.toUsers.errors && (parentForm.controls.toUsers.dirty || parentForm.controls.toUsers.touched)"><!--C153176-5055 'To' validation in mobile-->
              <img src="assets/newMessage/error.svg" class="error" title="TO is Required">
          </div>
           <!-- Jira : C153176-5194 Mobile -To/CC/ BCC changes -->
          <div *ngIf="isMobile" class="w-45 d-flex flex-row align-items-start control-group">
            <label for="from" class="flex-grow not-bold chevronToccbcc" (click)="showccbcc()">
              &nbsp;<i [ngClass]="toggleccbcc ? 'fa-chevron-up' : 'fa-chevron-down'" class="fa "></i></label>
          </div>
        </div>
        <div *ngIf="toggleccbcc && isMobile" class="d-flex flex-row align-items-start control-group mobile-toccbcc" [ngClass]="isAutoCompleteDisabled ? 'disableSuggestion' : ''">
          <label for="cc" class="min-width" (click)="showPopup($event, 'cc', op)"><span>Cc:</span></label>
          <p-autoComplete formControlName="ccUsers" [suggestions]="filteredToUsersMultiple"
            (completeMethod)="filterUsers($event)" [minLength]="3" field="text" [multiple]="true" styleClass="override override-newmessage-autocomplete mob-mt7"
            class="w-100" [(ngModel)]="ccUsersList" (onKeyUp)="onKeyUp($event, 'cc')"  (drop)="drop($event, 'cc')" (dragover)="allowDrop($event)"
            (onBlur)="onBlur($event, 'cc')" (onUnselect)="onRemoveRecipient($event)" (onSelect)="onSelectRecipient($event)"> <!--C153176-4930 -->
            <ng-template let-value pTemplate="selectedItem">
                <span [ngClass]="getContactStatus(value.value)" oncontextmenu="return false;" class="p-autocomplete-token-label new-message-token"
                (contextmenu)="addToTop($event, addtotop, value, 'cc')" draggable="true" (dragstart)="drag($event, 'cc', value)" pTooltip="{{value.email}}" tooltipPosition="bottom">{{value.text}}</span>
              </ng-template>
          </p-autoComplete>
          <div class="clear-input" *ngIf="ccUsersList.length" (click)="clearToCcBcc('cc')">x</div>
        </div>
        <div *ngIf="toggleccbcc && isMobile" class="d-flex flex-row align-items-start control-group  bccField mobile-toccbcc" [ngClass]="isAutoCompleteDisabled ? 'disableSuggestion' : ''">
          <label for="bcc" class="min-width mobile-width" (click)="showPopup($event, 'bcc', op)"><span class="bcc-width">Bcc:</span></label>
          <p-autoComplete formControlName="bccUsers" [suggestions]="filteredToUsersMultiple"
            (completeMethod)="filterUsers($event)" [minLength]="3" field="text" [multiple]="true" styleClass="override override-newmessage-autocomplete"
            class="w-100" [(ngModel)]="bccUsersList" (onKeyUp)="onKeyUp($event, 'bcc')"  (drop)="drop($event, 'bcc')" (dragover)="allowDrop($event)"
            (onBlur)="onBlur($event, 'bcc')" (onUnselect)="onRemoveRecipient($event)" (onSelect)="onSelectRecipient($event)"> <!--C153176-4930 -->
            <ng-template let-value pTemplate="selectedItem">
                <span [ngClass]="getContactStatus(value.value)" oncontextmenu="return false;" class="p-autocomplete-token-label new-message-token" 
                (contextmenu)="addToTop($event, addtotop, value, 'bcc')" draggable="true" (dragstart)="drag($event, 'bcc', value)" pTooltip="{{value.email}}" tooltipPosition="bottom">{{value.text}}</span>
            </ng-template>
          </p-autoComplete>
          <div class="clear-input" *ngIf="bccUsersList.length" (click)="clearToCcBcc('bcc')">x</div>
        </div>

        <div *ngIf="!isChevronUp && !isMobile" class="d-flex flex-row align-items-start control-group mobile-toccbcc" [ngClass]="isAutoCompleteDisabled ? 'disableSuggestion' : ''">
          <label for="cc" class="min-width" (click)="showPopup($event, 'cc', op)"><span class="to-cc-bcc">Cc... :</span></label>
          <p-autoComplete #ccUsersData formControlName="ccUsers" [suggestions]="filteredToUsersMultiple"
            (completeMethod)="filterUsers($event)" [minLength]="3" field="text" [multiple]="true" styleClass="override override-newmessage-autocomplete mob-mt7"
            class="w-100" [(ngModel)]="ccUsersList" (onKeyUp)="onKeyUp($event, 'cc')"  (drop)="drop($event, 'cc')" (dragover)="allowDrop($event)"
            (onBlur)="onBlur($event, 'cc')" (onUnselect)="onRemoveRecipient($event)" (onSelect)="onSelectRecipient($event)"> <!--C153176-4930 -->
            <ng-template let-value pTemplate="selectedItem">
                <span [ngClass]="value.isExternal?'external':''" oncontextmenu="return false;" class="p-autocomplete-token-label new-message-token"
                (contextmenu)="addToTop($event, addtotop, value, 'cc')" draggable="true" (dragstart)="drag($event, 'cc', value)" 
                (click)="displayContactInfo($event, opContactInfo, value)"
                title="{{value.email}}">{{value.text}}</span>
              </ng-template>
          </p-autoComplete>
          <div class="clear-input" *ngIf="ccUsersList.length" (click)="clearToCcBcc('cc')">x</div>
        </div>
        <!-- Jira : C153176-5194 Mobile -To/CC/ BCC changes -->
        <div *ngIf="!isChevronUp && !isMobile" class="d-flex flex-row align-items-start control-group  bccField mobile-toccbcc" [ngClass]="isAutoCompleteDisabled ? 'disableSuggestion' : ''">
          <label for="bcc" class="min-width mobile-width" (click)="showPopup($event, 'bcc', op)"><span class="bcc-width to-cc-bcc">Bcc... :</span></label>
          <p-autoComplete #bccUsersData formControlName="bccUsers" [suggestions]="filteredToUsersMultiple"
            (completeMethod)="filterUsers($event)" [minLength]="3" field="text" [multiple]="true" styleClass="override override-newmessage-autocomplete"
            class="w-100" [(ngModel)]="bccUsersList" (onKeyUp)="onKeyUp($event, 'bcc')"  (drop)="drop($event, 'bcc')" (dragover)="allowDrop($event)"
            (onBlur)="onBlur($event, 'bcc')" (onUnselect)="onRemoveRecipient($event)" (onSelect)="onSelectRecipient($event)"> <!--C153176-4930 -->
            <ng-template let-value pTemplate="selectedItem">
                <span [ngClass]="value.isExternal?'external':''" oncontextmenu="return false;" class="p-autocomplete-token-label new-message-token" 
                (contextmenu)="addToTop($event, addtotop, value, 'bcc')" draggable="true" (dragstart)="drag($event, 'bcc', value)" 
                (click)="displayContactInfo($event, opContactInfo, value)"
                title="{{value.email}}">{{value.text}}</span>
            </ng-template>
          </p-autoComplete>
          <div class="clear-input" *ngIf="bccUsersList.length" (click)="clearToCcBcc('bcc')">x</div>
        </div>
        <!-- Jira : C153176-4601 Mobile: Reply /Reply All -->
        <div *ngIf="!isChevronUp  && !isMobile" class="d-flex flex-row align-items-start control-group subjectField">
          <label for="subject" class="min-width mobile-label-width">Subject:</label>
          <input type="text" pInputText class="w-100 sub-width subject-font"  [ngClass]="taskizeInquirySubjectReadonly ? 'taskize-field-disabled' : ''" formControlName="subject"[readonly]="taskizeInquirySubjectReadonly">
        </div>
         <!-- Jira : C153176-5194 Mobile -To/CC/ BCC changes -->
         <!-- Jira : C170665-1754 Mobile - Subject line should come always -->
        <div *ngIf="isMobile" class="d-flex flex-row align-items-start control-group mobile-toccbcc">
            <label for="subject" class="min-width mobile-label-width">Subject:</label>
            <input type="text" pInputText class="w-100 sub-width" formControlName="subject">
        </div>
      </div>
      <p-overlayPanel #op [appendTo]="'body'" styleClass="contactPopup" (onShow)="onShowContactPopUp($event)"  (onHide)="onHideContactPopUp($event)">
         <app-recipent-data [allToCcDBUser]="allToCcDBUser" [isClosed]='isClosed' (pushToTo)="pushToTo($event, popup)" (loadCmc)="callCmcSearchWindow()"></app-recipent-data>
      </p-overlayPanel>
       <!-- Jira : C153176-5194 Mobile -To/CC/ BCC changes -->
      <!-- For Mobile code -->
      <div *ngIf="isMobile" class="d-flex flex-row justify-content-between  mobile-form control-group inbox-formgroup">
        
        <div *ngIf="!isChevronUp" class="w-20 d-flex flex-row align-items-start inbox-formgroup1 mob-order1 mobile-toccbcc mobile-row-height">
          <!--C153176-4475 Mobile New-mesaage tags order change-->
          <label for="from" class="flex-grow inbox-group">Request Type: </label>
          <p-dropdown class="drop-down w-100" [options]="requestTypes" formControlName="requestType" [styleClass]="'override'" (onChange)="onRequestTypeChange($event)">
          </p-dropdown>
          <div *ngIf="parentForm.controls.requestType.errors && (parentForm.controls.requestType.dirty || parentForm.controls.requestType.touched)"
            class="">
            <img src="assets/newMessage/error.svg" class="error" title="RequestType is Required">
          </div>
        </div>

        <div *ngIf="!isChevronUp" class="w-20 d-flex flex-row align-items-start inbox-formgroup1 mob-order2 mobile-toccbcc mobile-row-height"><!-- C153176-5194:To/CC/ BCC changes -->
          <!--C153176-4475 Mobile New message tags order change-->
          <label for="from" class="flex-grow inbox-group1">Root Cause: </label>
          <p-dropdown class="drop-down w-100" [options]="rootCauses" formControlName="rootCause" [styleClass]="'override'">
          </p-dropdown>
          <div *ngIf="parentForm.controls.rootCause.errors" class="">
            <img src="assets/newMessage/error.svg" class="error" title="Root Cause is Required">
          </div>
        </div>

        <div *ngIf="!isChevronUp" class="w-20 d-flex flex-row align-items-start inbox-formgroup1 mobile-toccbcc mobile-row-height"><!-- C153176-5194:To/CC/ BCC changes -->
          <!-- C153176-4934 Added inquiry source & gfpid/gfcid tags -->
          <label for="from" class="flex-grow">Processing Region: </label>
          <p-dropdown class="drop-down w-100" [options]="processingRegions" formControlName="processingRegion" [styleClass]="'override'">
          </p-dropdown>
          <div *ngIf="parentForm.controls.processingRegion.errors" class="">
            <img src="assets/newMessage/error.svg" class="error" title="Processing Region is Required">
          </div>
        </div>

        <div *ngIf="!isChevronUp" class="w-20 d-flex flex-row align-items-start inbox-formgroup1 mobile-toccbcc mobile-row-height"><!-- C153176-5194:To/CC/ BCC changes -->
          <!-- C153176-4934 Added inquiry source & gfpid/gfcid tags -->
          <label for="from" class="flex-grow">Inquiry Source: </label>
          <p-dropdown class="drop-down w-100" [options]="inquirySourceList" formControlName="inquirySource" [styleClass]="'override'">
          </p-dropdown>
          <div *ngIf="parentForm.controls.inquirySource.errors" class="">
            <img src="assets/newMessage/error.svg" class="error" title="Inquiry Source is Required">
          </div>
        </div>

        <!-- If  mobile view then display Group tag below inquiry source-->
        <div class="w-20 d-flex flex-row align-items-start inbox-formgroup1 mobile-toccbcc mobile-row-height" *ngIf="isMobile && !isChevronUp && !showFieldOnlyForTaskize"> <!-- C153176-5194:To/CC/ BCC changes -->
          <label for="from" class="flex-grow inbox-group1">Group Tag: </label>
          <p-dropdown class="drop-down w-100" [options]="tags" formControlName="tags" [styleClass]="'override'">
          </p-dropdown>
          <div *ngIf="parentForm.controls.tags.errors" class="">
            <img src="assets/newMessage/error.svg" class="error" title="Tags is Required">
          </div>
        </div>

        <!-- C153176-4934 Added inquiry source & gfpid/gfcid tags -->
        <!-- Jira : C153176-5196 Mobile -GFPID should come in same line -->
        <div class="w-90 d-flex flex-row align-items-start align-items-baseline inbox-formgroup1 mob-order3 mobile-row-height" *ngIf="isMobile && !isChevronUp"> <!-- C153176-5194:To/CC/ BCC changes -->
          <i class="fa fa-search"></i><a href="#" class="TextGFP" (click)="callGFIDWindow()">GFPID / GFCID :</a>
          <div [innerHTML]="gfpidgfcid"></div>
          <input type="hidden" formControlName="gfcId" />
          <input type="hidden" formControlName="gfcName" />
          <input type="hidden" formControlName="gfpId" />
          <input type="hidden" formControlName="gfpName" />
          <input type="hidden" formControlName="skAccountNo" />
          <input type="hidden" formControlName="branch" />
          <div *ngIf="parentForm.controls.gfpId.errors && parentForm.controls.gfcId.errors" class="">
            <img src="assets/newMessage/error.svg" class="error"><span class="errorMsg"> GFPID/GFCID is mandatory for resolve</span>
          </div>
        </div>
        <div *ngIf="!isChevronUp && enableInquirySubStatusFlag" class="w-20 d-flex flex-row align-items-start inbox-formgroup1 mob-order1">   
          <label for="from" class="flex-grow">Inquiry Sub-Status: </label>
          <p-dropdown class="drop-down w-100" [options]="inquirySubStatusList" formControlName="inquirySubStatus"
          [styleClass]="'override'">
          </p-dropdown>
          <div *ngIf="parentForm.controls.inquirySubStatus.errors && (parentForm.controls.inquirySubStatus.dirty || parentForm.controls.inquirySubStatus.touched)" class="">
            <img src="assets/newMessage/error.svg" class="error" title="Inquiry Sub-Status is Required">
         </div>
         </div>

      </div>

      <!-- For Web code -->
     <!-- C153176-5343: enable attach icon highlight when external files drag in  -->
     <div fileDragEnter *ngIf="!isMobile && isrevamped" class="d-flex flex-row justify-content-between  mobile-form control-group inbox-formgroup">
        <div *ngIf="!isChevronUp  && !showFieldOnlyForTaskize" class="w-20 d-flex flex-row align-items-start inbox-formgroup1 mob-order1">  <!--C153176-4475 Mobile New-mesaage tags order change-->
          <label for="from" class="flex-grow inbox-group">Request Type: </label>
          <p-dropdown class="drop-down w-100" [options]="requestTypes" formControlName="requestType" (onChange)="onRequestTypeChange($event)"
          [styleClass]="'override'">
          </p-dropdown>
          <div *ngIf="parentForm.controls.requestType.errors && (parentForm.controls.requestType.dirty || parentForm.controls.requestType.touched)" class="">
            <img src="assets/newMessage/error.svg" class="error" title="RequestType is Required">
        </div>
        </div>
        <div *ngIf="!isChevronUp && !showFieldOnlyForTaskize" class="w-20 d-flex flex-row align-items-start inbox-formgroup1 mob-order2">  <!--C153176-4475 Mobile New message tags order change-->
          <label for="from" class="flex-grow inbox-group1">Root Cause: </label>
          <p-dropdown class="drop-down w-100" [options]="rootCauses" formControlName="rootCause" [styleClass]="'override'">
          </p-dropdown>
          <div *ngIf="parentForm.controls.rootCause.errors" class="">
            <img src="assets/newMessage/error.svg" class="error" title="Root Cause is Required">
          </div>
        </div>
        <div *ngIf="!isChevronUp && !showFieldOnlyForTaskize" class="w-20 d-flex flex-row align-items-start inbox-formgroup1">   <!-- C153176-4934 Added inquiry source & gfpid/gfcid tags -->
          <label for="from" class="flex-grow">Processing Region: </label>
          <p-dropdown class="drop-down w-100" [options]="processingRegions" formControlName="processingRegion"
          [styleClass]="'override'">
          </p-dropdown>
          <div *ngIf="parentForm.controls.processingRegion.errors" class="">
            <img src="assets/newMessage/error.svg" class="error" title="Processing Region is Required">
          </div>
        </div>
        <div *ngIf="!isChevronUp" class="w-20 d-flex flex-row align-items-start inbox-formgroup1">    <!-- C153176-4934 Added inquiry source & gfpid/gfcid tags -->
          <label for="from" class="flex-grow">Inquiry Source: </label>
          <p-dropdown class="drop-down w-100" [options]="inquirySourceList" formControlName="inquirySource"
          [styleClass]="showFieldOnlyForTaskize ? 'override taskize-field-readOnly' : 'override'" [readonly]="showFieldOnlyForTaskize">
          </p-dropdown>
          <div *ngIf="parentForm.controls.inquirySource.errors" class="">
            <img src="assets/newMessage/error.svg" class="error" title="Inquiry Source is Required">
         </div>
        </div>
         <!-- If  mobile view then display Group tag below inquiry source-->
        <div class="w-20 d-flex flex-row align-items-start inbox-formgroup1" *ngIf="isMobile && !isChevronUp && !showFieldOnlyForTaskize">
          <label for="from" class="flex-grow inbox-group1">Group Tag: </label>
          <p-dropdown class="drop-down w-100" [options]="tags" formControlName="tags" [styleClass]="'override'">
          </p-dropdown>
          <div *ngIf="parentForm.controls.tags.errors" class="">
            <img src="assets/newMessage/error.svg" class="error" title="Tags is Required">
          </div>
        </div>  

        <!-- C153176-4934 Added inquiry source & gfpid/gfcid tags -->
         <!-- Jira : C153176-5196 Mobile -GFPID should come in same line -->
        <div class="w-90 d-flex flex-row align-items-start align-items-baseline inbox-formgroup1 mob-order3" *ngIf="isMobile && !isChevronUp && !showFieldOnlyForTaskize">
          <i class="fa fa-search"></i><a href="#" class="TextGFP" (click)="callGFIDWindow()">GFPID / GFCID :</a>
          <div [innerHTML]="gfpidgfcid"></div>
          <input type="hidden" formControlName="gfcId"  />
          <input type="hidden" formControlName="gfcName" />
          <input type="hidden" formControlName="gfpId"  />
          <input type="hidden" formControlName="gfpName" />
          <input type="hidden" formControlName="skAccountNo" />
          <input type="hidden" formControlName="branch" />
          <div *ngIf="parentForm.controls.gfpId.errors && parentForm.controls.gfcId.errors" class="">
            <img src="assets/newMessage/error.svg" class="error"><span class="errorMsg"> GFPID/GFCID is mandatory for resolve</span>
         </div>
        </div>

      </div>
      <div  *ngIf="isrevamped && !showFieldOnlyForTaskize" class="w-20 d-flex flex-row align-items-start control-group mobile-view-field">
        <label for="from" class="flex-grow not-bold" (click)="showHideMoreFields()">
          <i [ngClass]="toggleMoreFields ? 'fa-chevron-up' : 'fa-chevron-down'" class="fa "></i> More
          Fields</label>
      </div>
     <!-- C153176-5343: enable attach icon highlight when external files drag in  -->
     <div fileDragEnter  *ngIf="isrevamped" [ngClass]="borderDragDropHighlight? 'more-field-drop-target' : (parentComponent.dropInProcessing? 'more-field-drop-inprogress' : '')">
        <div *ngIf="borderDragDropHighlight" class="more-field-drop-box">Drag and drop here to attach</div>
      <div [ngClass]="toggleMoreFields ? '' : 'hide'">
        <div class="moreItemsWrapper d-flex flex-row align-items-start mobile-view-field" *ngIf = "!showFieldOnlyForTaskize">

          <div class="w-20 d-flex flex-row align-items-start">
            <label for="from" class="flex-grow">Query Count: </label>
            <input class="w-100" type="text" formControlName="queryCount" maxlength="5" (keypress)="isNumber($event)">
          </div>
          <!-- If no mobile view then display Group tag inside More fields -->
          <div class="w-20 d-flex flex-row align-items-start inbox-formgroup1 mobile-grouptag" *ngIf="!isMobile">
              <label for="from" class="flex-grow inbox-group1">Group Tag: </label>
              <p-dropdown class="drop-down w-100" [options]="tags" formControlName="tags" [styleClass]="'override'">
              </p-dropdown>
              <div *ngIf="parentForm.controls.tags.errors" class="">
                <img src="assets/newMessage/error.svg" class="error" title="Tags is Required">
              </div>
            </div>  

          <div class="w-50 d-flex flex-row align-items-start ml-4 align-items-baseline">
            <i class="fa fa-search"></i><a href="#" class="TextGFP" (click)="callGFIDWindow()">GFPID / GFCID Search:</a>
            <div [innerHTML]="gfpidgfcid"></div>
            <input type="hidden" formControlName="gfcId"  />
            <input type="hidden" formControlName="gfcName" />
            <input type="hidden" formControlName="gfpId"  />
            <input type="hidden" formControlName="gfpName" />
            <input type="hidden" formControlName="skAccountNo" />
            <input type="hidden" formControlName="branch" />
            <div *ngIf="parentForm.controls.gfpId.errors || parentForm.controls.gfcId.errors" class="">
              <img src="assets/newMessage/error.svg" class="error"><span class="errorMsg"> GFPID/GFCID is mandatory for resolve</span>
           </div>
          </div>
        </div>
      </div>
          <!-- CLC ICON WIll COME HERE -->
      <!-- <a href="#" class="pull-left  mobile-view-field" (click)="clcAssist(true)"><img src="assets/clc/CLC_Icon_blue.svg" style="width:16px;height:19px;padding-right:5px;" alt="CLC"></a> -->
      <!-- Jira: Mobile : C153176-4476 - Conversation View -->
     <!-- new implimentation of attachement -->
    <!--  #overflowdiv --> <div style="display: none!important" *ngIf="postDataForAttachmentFile && postDataForAttachmentFile.length > 0"
       class="new-attachement w-100 d-flex flex-row align-items-start control-group ellipsis-overflow attachement-grid-container">
      <div *ngIf="postDataForAttachmentFile" class="pull-left" id="listOffileAttached" 
      [ngClass]="{'attachement-grid-verticle': inboxView === 'vertical','attachement-grid-horizontal': inboxView !== 'vertical'}">
        <div *ngFor="let attachment of postDataForAttachmentFile" 
        style="display:inline-flex; font-size: 12px; font-family: arial;" class="attachement-item" (mouseenter)="showAttachments(null, null, postDataForAttachmentFile)">
        <!-- Start -->
        
        <div class="new-attach-container box" *ngIf="attachment.showTitleNew" (mouseenter)="onMouseEnterNewAttTitle(attachment)" (mouseleave)="onMouseLeaveNewAttTitle(attachment)">
        <div class="elipsis-attachment">  
          <span id="{{attachment.id}}">
            <img [src]="getAttachementImageByExtension(attachment)"
            class="download-icon">
            <!-- <a href="{{downloadUrl}}?docId={{attachment.id}}" title="{{attachment.name}}">{{attachment.name}}</a> -->
            <a href="#" title="{{attachment.name}}" class="attachment-font" (click)="downloadAttachment(attachment.id,attachment.name)">{{attachment.name}}</a>
          </span>
          </div>
        
        <!-- End -->
      </div>
      <!-- Menu  -->
      <div class="menu" *ngIf="attachment.showMenuNew" 
      (mouseenter)="onMouseEnterNewAttMenu(attachment)"
       (mouseleave)="onMouseLeaveNewAttMenu(attachment)" ><!-- (mouseleave)="onMouseLeaveNewAttTitle(attachment)" -->
        <div class="eye-icon">
                    <img class="eye-img" 
                    (click)="onViewctionRedesigned(attachment)" 
                    title="View"
                    [src]="menuIconImageConfig.viewIconSrc"
                    (mouseover)="menuIconImageConfig.viewIconSrc = menuIconImageConfig.viewIconmouseOn"
                    (mouseout)="menuIconImageConfig.viewIconSrc = menuIconImageConfig.viewIconmouseOut">
         </div>
         <div class="menu-icons-border"></div>   
        <div class="image-container"> <!-- [src]="'assets/Inbox/download_icon_u70.svg'" -->
            <img class="download-icon" 
             (click)="onDownloadAttachment(attachment)"  title="Download"
             [src]="menuIconImageConfig.downloadIconSrc"
(mouseover)="menuIconImageConfig.downloadIconSrc = menuIconImageConfig.downloadIconmouseOn"
(mouseout)="menuIconImageConfig.downloadIconSrc = menuIconImageConfig.downloadIconmouseOut">
        </div>
        <div class="menu-icons-border">

        </div>
        <div class="image-container">
            <img class="copy-icon" [src]="menuIconImageConfig.copyIconSrc" name="copy"
             (click)="onCopyActionRedesigned(attachment)" title="Copy"
             (mouseover)="menuIconImageConfig.copyIconSrc = menuIconImageConfig.copyIconmouseOn"
             (mouseout)="menuIconImageConfig.copyIconSrc = menuIconImageConfig.copyIconmouseOut"></div>
    </div>
    <span (click)='deleteAttachedFile(attachment.id)'>
        <img src="assets/newMessage/attachdelete.svg" class="p-1 attchment-cross">
    </span>
        </div>
      </div>
      
      <input type="hidden" name="attachments">
    </div>
    <div class="expand-icon" *ngIf = "!isMobile">
      <a href="#" *ngIf="isInlineReply && !isMobile" class="pull-right" (click)="expand()"><img src="assets/newMessage/ExpandIcon.png" class="p-1"></a>
    </div>
  <!--  existing attachements -->
  <div class="select-all align-items-center d-flex" *ngIf="existingConvAttachements && existingConvAttachements.length 
  && existingConvAttachements.length > 0">
    <div style="margin-right: 5px;">
      Select All
    </div>
    <input type="checkbox" class="" (click)="selectDeselectAllAttachements()" [checked]="selectAllAttach">
    <div class="remaining-attach-count d-flex" *ngIf="remainingAttachementCount>0 && selectAllAttach" (click)="showAttchPopup()"
      [innerHTML] = "processAttachementMoreLink()">
      <!-- +({{remainingAttachementCount}}) -->
    </div>
  </div>
 
  <div 
  *ngIf="!isMobile && existingConvAttachements && existingConvAttachements.length && existingConvAttachements.length>0"
  class="attachement-grid-container">
  <div class="attachement-grid-container attachement-inner-grid-container">
 <div id="existingAttachementGrid" 
 [ngClass]="{'attachement-grid-verticle': inboxView === 'vertical','attachement-grid-horizontal': inboxView !== 'vertical'}">
     
     <ng-container *ngFor="let attachment of existingConvAttachements;let i = index">
         <div class="attachement-item existing-attachement-list-inactive d-flex" 
         [ngClass]="{'existing-attachement-list-active': attachment.isSselected}"
         (mouseenter)="showAttachments(null, null, existingConvAttachements)"> <!-- *ngIf="i<3" -->
           <div style="padding-right:4px;height: 100%;
           align-items: center;
           background: white;" class="d-flex">
              <input type="checkbox" value="" [checked]="attachment.isSselected" (change)="onExistingAttachementSelected(attachment)" class="attach-select-checkbox">
           </div>
             <div class="box" *ngIf="attachment.showTitle" (mouseenter)="onMouseEnterAttTitle(attachment)" (mouseleave)="onMouseLeaveAttTitle(attachment)">
                 <!-- (mouseenter)="onMouseEnterAttTitle(attachment)" (mouseleave)="onMouseLeaveAttTitle(attachment)" -->
                 <!-- {{i}}  -->
                 <img class="download-icon" [src]="getAttachementImageByExtension(attachment)" style="margin-top:0px;"/>
                 <div class="attachement-title" style="color: #021E4A;font-family: 'Interstate';font-size: 12px;">
                     {{attachment?.name}}
                 </div>
     
             </div>
            <div class="menu" *ngIf="attachment.showMenu" 
            (mouseenter)="onMouseEnterAttMenu(attachment)" 
            (mouseleave)="onMouseLeaveAttMenu(attachment)"
            [attr.title]="attachment?.name">
              <!-- (mouseleave)="onMouseLeaveAttMenu(attachment)" -->
              <div class="eye-icon">
                <img class="eye-img" (click)="onViewctionRedesigned(attachment)" title="View" [src]="menuIconImageConfig.viewIconSrc"
                  (mouseover)="menuIconImageConfig.viewIconSrc = menuIconImageConfig.viewIconmouseOn" (mouseout)="menuIconImageConfig.viewIconSrc = menuIconImageConfig.viewIconmouseOut">
              </div>
              <div class="menu-icons-border"></div>
              <div class="image-container">
                <!-- [src]="'assets/Inbox/download_icon_u70.svg'" -->
                <img class="download-icon" (click)="onDownloadAttachment(attachment)" title="Download" [src]="menuIconImageConfig.downloadIconSrc"
                  (mouseover)="menuIconImageConfig.downloadIconSrc = menuIconImageConfig.downloadIconmouseOn" (mouseout)="menuIconImageConfig.downloadIconSrc = menuIconImageConfig.downloadIconmouseOut">
              </div>
              <div class="menu-icons-border">
            
              </div>
              <div class="image-container">
                <img class="copy-icon" [src]="menuIconImageConfig.copyIconSrc" name="copy" 
                (click)="onCopyActionRedesigned(attachment)"
                  title="Copy" (mouseover)="menuIconImageConfig.copyIconSrc = menuIconImageConfig.copyIconmouseOn" (mouseout)="menuIconImageConfig.copyIconSrc = menuIconImageConfig.copyIconmouseOut"></div>
            </div>
         </div>
        
     </ng-container>
       
</div>
</div>
<div class="remaining-attach-count d-flex" 
*ngIf="remainingAttachementCount>0 && !selectAllAttach" (click)="showAttchPopup()"
[innerHTML] = "processAttachementMoreLink()">
  +({{remainingAttachementCount}})
</div>
<div class="remaining-attach-count d-flex ml-1" *ngIf="remainingAttachementCount>0 && selectAllAttach">
    <img class="download-icon-pop-up"  style ="margin-right: 12px;" title="Download" 
    [src]="menuIconImageConfig.downloadIconSrc"
      (mouseover)="menuIconImageConfig.downloadIconSrc = menuIconImageConfig.downloadIconmouseOn" 
      (mouseout)="menuIconImageConfig.downloadIconSrc = menuIconImageConfig.downloadIconmouseOut"
      (click)="onDownloadAttachment(existingConvAttachements)">
</div>
</div>
      <div class="d-flex flex-column align-items-start addNoteWrapper attach-space mobile-view-field">
          <div *ngIf="queryCountMsg">
              <img src="assets/newMessage/error.svg" class="error"> <span class="errorMsg">{{ queryCountMsg}}</span>
          </div>
        <div (click)="showHideAddNote()" class="addNoteField" style="cursor: pointer;text-decoration: underline;font-size:10px;    font-family: 'InterstateLight';">
          <span style="text-decoration: none; display: inline-block;">+</span>Add Note
        </div>       
        <div class="w-100" [ngClass]="toggleAdd ? '' : 'hide'" style="border: 1px solid #ccc;">
          <textarea name="note" id="note" cols="30" rows="10" class="inputBox"  maxlength="255" formControlName="note"></textarea>
          <div class="bottom-button-group d-flex flex-row justify-content-end">
            <button class="d-flex flex-column button justify-content-center" (click)="addNote()">
              <img src="assets/newMessage/add-blue.svg" class="p-1">
              <span>ADD NOTE</span>
            </button>
            <button class="d-flex flex-column justify-content-center button" (click)="cancelAddNote()">
              <img src="assets/newMessage/add-gray.svg" class="p-1">
              <span style="color:gray">CANCEL</span>
            </button>
          </div>
        </div>
  
      </div>
      </div> <!-- more-field-drop-target-->
      <!-- Start with new revamped-->
      <div fileDragEnter *ngIf="!isMobile && !isrevamped" class="d-flex boxfor-newline flex-row inbox-formgroup">
        <div *ngIf="!isChevronUp && !showFieldOnlyForTaskize" class="w-20 d-flex flex-row align-items-start inbox-formgroup1 mob-order1">       
          <label for="from" class="flex-grow inbox-group">Request Type: </label>
          <p-dropdown class="drop-down w-100" [options]="requestTypes" formControlName="requestType"
            (onChange)="onRequestTypeChange($event)" [styleClass]="'override'">
          </p-dropdown>
          <div *ngIf="parentForm.controls.requestType.errors && (parentForm.controls.requestType.dirty || parentForm.controls.requestType.touched)"  class="">
            <img src="assets/newMessage/error.svg" class="error" title="RequestType is Required">
          </div>
        </div>
        <div *ngIf="!isChevronUp && isRootCauseMandatoryab && !showFieldOnlyForTaskize"
          class="w-20 d-flex flex-row align-items-start inbox-formgroup1 mob-order2">       
          <label for="from" class="flex-grow inbox-group1">Root Cause: </label>
          <p-dropdown class="drop-down w-100" [options]="rootCauses" formControlName="rootCause"
            [styleClass]="'override'">
          </p-dropdown>
          <div *ngIf="parentForm.controls.rootCause.errors" class="">
            <img src="assets/newMessage/error.svg" class="error" title="Root Cause is Required">
          </div>
        </div>
        <div *ngIf="!isChevronUp && isProcessingRegionMandatoryab && !showFieldOnlyForTaskize"
          class="w-20 d-flex flex-row align-items-start inbox-formgroup1 justify-content-between">        
          <label for="from" class="flex-grow">Processing Region: </label>
          <p-dropdown class="drop-down w-100" [options]="processingRegions" formControlName="processingRegion"
            [styleClass]="'override'">
          </p-dropdown>
          <div *ngIf="parentForm.controls.processingRegion.errors" class="">
            <img src="assets/newMessage/error.svg" class="error" title="Processing Region is Required">
          </div>
        </div>
        <div *ngIf="!isChevronUp && isInqSourceMandatoryab"
        class="w-20 d-flex flex-row align-items-start inbox-formgroup1 justify-content-between">     
        <label for="from" class="flex-grow">Inquiry Source: </label>
        <p-dropdown class="drop-down w-100" [options]="inquirySourceList" formControlName="inquirySource"
          [styleClass]="showFieldOnlyForTaskize ? 'override taskize-field-readOnly' : 'override'" [readonly]="showFieldOnlyForTaskize">
        </p-dropdown>
        <div *ngIf="parentForm.controls.inquirySource.errors" class="">
          <img src="assets/newMessage/error.svg" class="error" title="Inquiry Source is Required">
        </div>
      </div>
      
        <!-- <div *ngIf="!isChevronUp && isGfidMandatoryab && !isRootCauseMandatoryab"
      class="w-50 d-flex flex-row align-items-start  align-items-baseline">
      <i class="fa fa-search"></i><a href="#" class="TextGFP" (click)="callGFIDWindow()">GFPID / GFCID Search:</a>
      <div [innerHTML]="gfpidgfcid"></div>
      <input type="hidden" formControlName="gfcId" />
      <input type="hidden" formControlName="gfcName" />
      <input type="hidden" formControlName="gfpId" />
      <input type="hidden" formControlName="gfpName" />
      <input type="hidden" formControlName="skAccountNo" />
      <input type="hidden" formControlName="branch" />
      <div *ngIf="parentForm.controls.gfpId.errors || parentForm.controls.gfcId.errors" class="">
        <img src="assets/newMessage/error.svg" class="error"><span class="errorMsg"> GFPID/GFCID is mandatory for
          resolve</span>
      </div>
       </div>  -->
          <div *ngIf="!isChevronUp &&  enableInquirySubStatus && enableInquirySubStatusFlag" class="w-20 d-flex flex-row align-items-start inbox-formgroup1 mob-order1">   
            <label for="from" class="flex-grow">Inquiry Sub-Status: </label>
            <p-dropdown class="drop-down w-100" [options]="inquirySubStatusList" formControlName="inquirySubStatus"
            [styleClass]="'override'">
            </p-dropdown>
            <div *ngIf="parentForm.controls.inquirySubStatus.errors && (parentForm.controls.inquirySubStatus.dirty || parentForm.controls.inquirySubStatus.touched)" class="">
              <img src="assets/newMessage/error.svg" class="error" title="Inquiry Sub-Status is Required">
           </div>
           </div>
        <div class="w-20 d-flex flex-row align-items-start inbox-formgroup1" *ngIf="!isChevronUp && isTagMandatoryab && !showFieldOnlyForTaskize">
          <label for="from" class="flex-grow ">Group Tag: </label>
          <p-dropdown class="drop-down w-100" [options]="tags" formControlName="tags" [styleClass]="'override'">
          </p-dropdown>
          <div *ngIf="parentForm.controls.tags.errors" class="">
            <img src="assets/newMessage/error.svg" class="error" title="Tags is Required">
          </div>
        </div> 
        <div *ngIf="!isChevronUp && isGfidMandatoryab && !showFieldOnlyForTaskize"
          class="w-50 d-flex flex-row align-items-start  align-items-baseline">
          <i class="fa fa-search"></i><a href="#" class="TextGFP" (click)="callGFIDWindow()">GFPID / GFCID Search:</a>
          <div [innerHTML]="gfpidgfcid"></div>
          <input type="hidden" formControlName="gfcId" />
          <input type="hidden" formControlName="gfcName" />
          <input type="hidden" formControlName="gfpId" />
          <input type="hidden" formControlName="gfpName" />
          <input type="hidden" formControlName="skAccountNo" />
          <input type="hidden" formControlName="branch" />
          <div *ngIf="parentForm.controls.gfpId.errors || parentForm.controls.gfcId.errors" class="">
            <img src="assets/newMessage/error.svg" class="error"><span class="errorMsg"> GFPID/GFCID is mandatory for
              resolve</span>
          </div>
        </div>
  
      <!-- </div> -->
      </div>
      <div  *ngIf="!isrevamped && !showFieldOnlyForTaskize" class="w-20 d-flex flex-row align-items-start control-group mobile-view-field">
        <label for="from" class="flex-grow not-bold" (click)="showHideMoreFields()">
          <i [ngClass]="toggleMoreFields ? 'fa-chevron-up' : 'fa-chevron-down'" class="fa "></i> More
          Fields</label>
      </div>
      <!-- C153176-5343: enable attach icon highlight when external files drag in  -->
      <div fileDragEnter *ngIf="!isrevamped"  [ngClass]="borderDragDropHighlight? 'more-field-drop-target' : (parentComponent.dropInProcessing? 'more-field-drop-inprogress' : '')">
        <div *ngIf="borderDragDropHighlight" class="more-field-drop-box">Drag and drop here to attach</div>
        <div [ngClass]="toggleMoreFields ? '' : 'hide'">
          <div  *ngIf="!isrevamped" class="moreItemsWrapper d-flex boxfor-newline flex-row align-items-start mobile-view-field">
            <div *ngIf="!isChevronUp && !isRootCauseMandatoryab && !showFieldOnlyForTaskize"
              class="w-20 d-flex flex-row align-items-start inbox-formgroup1 mob-order2">
              <!--C153176-4475 Mobile New message tags order change-->
              <label for="from" class="flex-grow inbox-group1">Root Cause: </label>
              <p-dropdown class="drop-down w-100" [options]="rootCauses" formControlName="rootCause"
                [styleClass]="'override'">
              </p-dropdown>
              <div *ngIf="parentForm.controls.rootCause.errors" class="">
                <img src="assets/newMessage/error.svg" class="error" title="Root Cause is Required">
              </div>
            </div>
            <div *ngIf="!isChevronUp && !isProcessingRegionMandatoryab && !showFieldOnlyForTaskize"
              class="w-20 d-flex flex-row align-items-start inbox-formgroup1">            
              <label for="from" class="flex-grow">Processing Region: </label>
              <p-dropdown class="drop-down w-100" [options]="processingRegions" formControlName="processingRegion"
                [styleClass]="'override'">
              </p-dropdown>
              <div *ngIf="parentForm.controls.processingRegion.errors" class="">
                <img src="assets/newMessage/error.svg" class="error" title="Processing Region is Required">
              </div>
            </div>
            <div *ngIf="!isChevronUp && !isInqSourceMandatoryab"
              class="w-20 d-flex flex-row align-items-start inbox-formgroup1">
              <label for="from" class="flex-grow">Inquiry Source: </label>
              <p-dropdown class="drop-down w-100" [options]="inquirySourceList" formControlName="inquirySource"
              [styleClass]="showFieldOnlyForTaskize ? 'override taskize-field-readOnly' : 'override'" [readonly]="showFieldOnlyForTaskize">
              </p-dropdown>
              <div *ngIf="parentForm.controls.inquirySource.errors" class="">
                <img src="assets/newMessage/error.svg" class="error" title="Inquiry Source is Required">
              </div>
            </div>
            <div class="w-20 d-flex flex-row align-items-start" *ngIf = "!showFieldOnlyForTaskize">
              <label for="from" class="flex-grow">Query Count: </label>
              <input class="w-100" type="text" formControlName="queryCount" maxlength="5" (keypress)="isNumber($event)">
            </div>
            <div class="w-20 d-flex flex-row align-items-start inbox-formgroup1 mobile-grouptag" *ngIf="!isMobile && !isTagMandatoryab && !showFieldOnlyForTaskize">
              <label for="from" class="flex-grow inbox-group1">Group Tag: </label>
              <p-dropdown class="drop-down w-100" [options]="tags" formControlName="tags" [styleClass]="'override'">
              </p-dropdown>
              <div *ngIf="parentForm.controls.tags.errors" class="">
                <img src="assets/newMessage/error.svg" class="error" title="Tags is Required">
              </div>
            </div> 
            <div *ngIf="!isChevronUp  && !enableInquirySubStatus && enableInquirySubStatusFlag" class="w-20 d-flex flex-row align-items-start inbox-formgroup1">   
              <label for="from" class="flex-grow">Inquiry Sub-Status: </label>
              <p-dropdown class="drop-down w-100" [options]="inquirySubStatusList" formControlName="inquirySubStatus"
              [styleClass]="'override'">
              </p-dropdown>
              <div *ngIf="parentForm.controls.inquirySubStatus.errors && (parentForm.controls.inquirySubStatus.dirty || parentForm.controls.inquirySubStatus.touched)" class="">
                <img src="assets/newMessage/error.svg" class="error" title="Inquiry Sub-Status is Required">
             </div>
             </div>
             <!-- <div class="w-20 d-flex flex-row align-items-start inbox-formgroup1 mobile-grouptag" *ngIf="!isMobile && !isTagMandatoryab && !isRootCauseMandatoryab">
              <label for="from" class="flex-grow inbox-group1">Group Tag: </label>
              <p-dropdown class="drop-down w-100" [options]="tags" formControlName="tags" [styleClass]="'override'">
              </p-dropdown>
              <div *ngIf="parentForm.controls.tags.errors" class="">
                <img src="assets/newMessage/error.svg" class="error" title="Tags is Required">
              </div>
            </div>  -->
           
            <div *ngIf="!isChevronUp && !isGfidMandatoryab && !showFieldOnlyForTaskize"
              class="w-50 d-flex flex-row align-items-start  align-items-baseline">
              <i class="fa fa-search"></i><a href="#" class="TextGFP" (click)="callGFIDWindow()">GFPID / GFCID Search:</a>
              <div [innerHTML]="gfpidgfcid"></div>
              <input type="hidden" formControlName="gfcId" />
              <input type="hidden" formControlName="gfcName" />
              <input type="hidden" formControlName="gfpId" />
              <input type="hidden" formControlName="gfpName" />
              <input type="hidden" formControlName="skAccountNo" />
              <input type="hidden" formControlName="branch" />
              <div *ngIf="parentForm.controls.gfpId.errors || parentForm.controls.gfcId.errors" class="">
                <img src="assets/newMessage/error.svg" class="error"><span class="errorMsg"> GFPID/GFCID is mandatory for
                  resolve</span>
              </div>
            </div>
          </div>
        </div>
     
        <!-- CLC ICON WIll COME HERE -->
        <!-- <a href="#" class="pull-left  mobile-view-field" (click)="clcAssist(true)"><img src="assets/clc/CLC_Icon_blue.svg" style="width:16px;height:19px;padding-right:5px;" alt="CLC"></a> -->
        <!-- Jira: Mobile : C153176-4476 - Conversation View -->
        <!-- new implimentation of attachement -->
        <!--  #overflowdiv -->
        <div style="display: none!important" *ngIf="postDataForAttachmentFile && postDataForAttachmentFile.length > 0"
          class="new-attachement w-100 d-flex flex-row align-items-start control-group ellipsis-overflow attachement-grid-container">
          <div *ngIf="postDataForAttachmentFile" class="pull-left" id="listOffileAttached"
            [ngClass]="{'attachement-grid-verticle': inboxView === 'vertical','attachement-grid-horizontal': inboxView !== 'vertical'}">
            <div *ngFor="let attachment of postDataForAttachmentFile"
              style="display:inline-flex; font-size: 12px; font-family: arial;" class="attachement-item"
              (mouseenter)="showAttachments(null, null, postDataForAttachmentFile)">
              <!-- Start -->
  
              <div class="new-attach-container box" *ngIf="attachment.showTitleNew"
                (mouseenter)="onMouseEnterNewAttTitle(attachment)" (mouseleave)="onMouseLeaveNewAttTitle(attachment)">
                <div class="elipsis-attachment">
                  <span id="{{attachment.id}}">
                    <img [src]="getAttachementImageByExtension(attachment)" class="download-icon">
                    <!-- <a href="{{downloadUrl}}?docId={{attachment.id}}" title="{{attachment.name}}">{{attachment.name}}</a> -->
                    <a href="#" title="{{attachment.name}}" class="attachment-font"
                      (click)="downloadAttachment(attachment.id,attachment.name)">{{attachment.name}}</a>
                  </span>
                </div>
  
                <!-- End -->
              </div>
              <!-- Menu  -->
              <div class="menu" *ngIf="attachment.showMenuNew" (mouseenter)="onMouseEnterNewAttMenu(attachment)"
                (mouseleave)="onMouseLeaveNewAttMenu(attachment)">
                <!-- (mouseleave)="onMouseLeaveNewAttTitle(attachment)" -->
                <div class="eye-icon">
                  <img class="eye-img" (click)="onViewctionRedesigned(attachment)" title="View"
                    [src]="menuIconImageConfig.viewIconSrc"
                    (mouseover)="menuIconImageConfig.viewIconSrc = menuIconImageConfig.viewIconmouseOn"
                    (mouseout)="menuIconImageConfig.viewIconSrc = menuIconImageConfig.viewIconmouseOut">
                </div>
                <div class="menu-icons-border"></div>
                <div class="image-container">
                  <!-- [src]="'assets/Inbox/download_icon_u70.svg'" -->
                  <img class="download-icon" (click)="onDownloadAttachment(attachment)" title="Download"
                    [src]="menuIconImageConfig.downloadIconSrc"
                    (mouseover)="menuIconImageConfig.downloadIconSrc = menuIconImageConfig.downloadIconmouseOn"
                    (mouseout)="menuIconImageConfig.downloadIconSrc = menuIconImageConfig.downloadIconmouseOut">
                </div>
                <div class="menu-icons-border">
  
                </div>
                <div class="image-container">
                  <img class="copy-icon" [src]="menuIconImageConfig.copyIconSrc" name="copy"
                    (click)="onCopyActionRedesigned(attachment)" title="Copy"
                    (mouseover)="menuIconImageConfig.copyIconSrc = menuIconImageConfig.copyIconmouseOn"
                    (mouseout)="menuIconImageConfig.copyIconSrc = menuIconImageConfig.copyIconmouseOut">
                </div>
              </div>
              <span (click)='deleteAttachedFile(attachment.id)'>
                <img src="assets/newMessage/attachdelete.svg" class="p-1 attchment-cross">
              </span>
            </div>
          </div>
  
          <input type="hidden" name="attachments">
        </div>
        <div [ngClass]="showFieldOnlyForTaskize ? 'taskize-expand-icon' : 'expand-icon'">
          <a href="#" *ngIf="isInlineReply && !isMobile" class="pull-right" (click)="expand()"><img
              src="assets/newMessage/ExpandIcon.png" class="p-1"></a>
        </div>
        <!--  existing attachements -->
        <div class="select-all align-items-center d-flex" *ngIf="existingConvAttachements && existingConvAttachements.length 
    && existingConvAttachements.length > 0">
          <div style="margin-right: 5px;">
            Select All
          </div>
          <input type="checkbox" class="" (click)="selectDeselectAllAttachements()" [checked]="selectAllAttach">
          <div class="remaining-attach-count d-flex" *ngIf="remainingAttachementCount>0 && selectAllAttach"
            (click)="showAttchPopup()" [innerHTML]="processAttachementMoreLink()">
            <!-- +({{remainingAttachementCount}}) -->
          </div>
        </div>
  
        <div
          *ngIf="!isMobile && existingConvAttachements && existingConvAttachements.length && existingConvAttachements.length>0"
          class="attachement-grid-container">
          <div class="attachement-grid-container attachement-inner-grid-container">
            <div id="existingAttachementGrid"
              [ngClass]="{'attachement-grid-verticle': inboxView === 'vertical','attachement-grid-horizontal': inboxView !== 'vertical'}">
  
              <ng-container *ngFor="let attachment of existingConvAttachements;let i = index">
                <div class="attachement-item existing-attachement-list-inactive d-flex"
                  [ngClass]="{'existing-attachement-list-active': attachment.isSselected}"
                  (mouseenter)="showAttachments(null, null, existingConvAttachements)">
                  <!-- *ngIf="i<3" -->
                  <div style="padding-right:4px;height: 100%;
             align-items: center;
             background: white;" class="d-flex">
                    <input type="checkbox" value="" [checked]="attachment.isSselected"
                      (change)="onExistingAttachementSelected(attachment)" class="attach-select-checkbox">
                  </div>
                  <div class="box" *ngIf="attachment.showTitle" (mouseenter)="onMouseEnterAttTitle(attachment)"
                    (mouseleave)="onMouseLeaveAttTitle(attachment)">
                    <!-- (mouseenter)="onMouseEnterAttTitle(attachment)" (mouseleave)="onMouseLeaveAttTitle(attachment)" -->
                    <!-- {{i}}  -->
                    <img class="download-icon" [src]="getAttachementImageByExtension(attachment)"
                      style="margin-top:0px;" />
                    <div class="attachement-title" style="color: #021E4A;font-family: 'Interstate';font-size: 12px;">
                      {{attachment?.name}}
                    </div>
  
                  </div>
                  <div class="menu" *ngIf="attachment.showMenu" (mouseenter)="onMouseEnterAttMenu(attachment)"
                    (mouseleave)="onMouseLeaveAttMenu(attachment)" [attr.title]="attachment?.name">
                    <!-- (mouseleave)="onMouseLeaveAttMenu(attachment)" -->
                    <div class="eye-icon">
                      <img class="eye-img" (click)="onViewctionRedesigned(attachment)" title="View"
                        [src]="menuIconImageConfig.viewIconSrc"
                        (mouseover)="menuIconImageConfig.viewIconSrc = menuIconImageConfig.viewIconmouseOn"
                        (mouseout)="menuIconImageConfig.viewIconSrc = menuIconImageConfig.viewIconmouseOut">
                    </div>
                    <div class="menu-icons-border"></div>
                    <div class="image-container">
                      <!-- [src]="'assets/Inbox/download_icon_u70.svg'" -->
                      <img class="download-icon" (click)="onDownloadAttachment(attachment)" title="Download"
                        [src]="menuIconImageConfig.downloadIconSrc"
                        (mouseover)="menuIconImageConfig.downloadIconSrc = menuIconImageConfig.downloadIconmouseOn"
                        (mouseout)="menuIconImageConfig.downloadIconSrc = menuIconImageConfig.downloadIconmouseOut">
                    </div>
                    <div class="menu-icons-border">
  
                    </div>
                    <div class="image-container">
                      <img class="copy-icon" [src]="menuIconImageConfig.copyIconSrc" name="copy"
                        (click)="onCopyActionRedesigned(attachment)" title="Copy"
                        (mouseover)="menuIconImageConfig.copyIconSrc = menuIconImageConfig.copyIconmouseOn"
                        (mouseout)="menuIconImageConfig.copyIconSrc = menuIconImageConfig.copyIconmouseOut">
                    </div>
                  </div>
                </div>
  
              </ng-container>
  
            </div>
          </div>
          <div class="remaining-attach-count d-flex" *ngIf="remainingAttachementCount>0 && !selectAllAttach"
            (click)="showAttchPopup()" [innerHTML]="processAttachementMoreLink()">
            +({{remainingAttachementCount}})
          </div>
          <div class="remaining-attach-count d-flex ml-1" *ngIf="remainingAttachementCount>0 && selectAllAttach">
            <img class="download-icon-pop-up" style="margin-right: 12px;" title="Download"
              [src]="menuIconImageConfig.downloadIconSrc"
              (mouseover)="menuIconImageConfig.downloadIconSrc = menuIconImageConfig.downloadIconmouseOn"
              (mouseout)="menuIconImageConfig.downloadIconSrc = menuIconImageConfig.downloadIconmouseOut"
              (click)="onDownloadAttachment(existingConvAttachements)">
          </div>
        </div>
        <div class="d-flex flex-column align-items-start addNoteWrapper attach-space mobile-view-field">
          <div *ngIf="queryCountMsg">
            <img src="assets/newMessage/error.svg" class="error"> <span class="errorMsg">{{ queryCountMsg}}</span>
          </div>
          <div (click)="showHideAddNote()" class="addNoteField"
            style="cursor: pointer;text-decoration: underline;font-size:10px;    font-family: 'InterstateLight';">
            <span style="text-decoration: none; display: inline-block;">+</span>Add Note
          </div>
          <div class="w-100" [ngClass]="toggleAdd ? '' : 'hide'" style="border: 1px solid #ccc;">
            <textarea name="note" id="note" cols="30" rows="10" class="inputBox" maxlength="255"
              formControlName="note"></textarea>
            <div class="bottom-button-group d-flex flex-row justify-content-end">
              <button class="d-flex flex-column button justify-content-center" (click)="addNote()">
                <img src="assets/newMessage/add-blue.svg" class="p-1">
                <span>ADD NOTE</span>
              </button>
              <button class="d-flex flex-column justify-content-center button" (click)="cancelAddNote()">
                <img src="assets/newMessage/add-gray.svg" class="p-1">
                <span style="color:gray">CANCEL</span>
              </button>
            </div>
          </div>
  
        </div>
      </div> <!-- more-field-drop-target-->
      <div *ngIf="!isChevronUp">  <!--C-4475-new-message hide attachment in mobile -->
        <!-- old implimentation of attachement -->
      <div #overflowdiv class="w-100 d-flex flex-row align-items-start control-group ellipsis-overflow" >
          <div *ngIf="postDataForAttachmentFile" class="pull-left" id="listOffileAttached" style="display: none">
            <div *ngFor="let attachment of postDataForAttachmentFile" style="display:inline-flex; font-size: 12px; font-family: arial;">
            <div class="elipsis-attachment">  
              <span id="{{attachment.id}}"><img src="assets/newMessage/notes.svg" class="attachment-img">
                <!-- <a href="{{downloadUrl}}?docId={{attachment.id}}" title="{{attachment.name}}">{{attachment.name}}</a> -->
                <a href="#" title="{{attachment.name}}" class="attachment-font" (click)="downloadAttachment(attachment.id,attachment.name)">{{attachment.name}}</a>
              </span>
              </div>
              <span (click)='deleteAttachedFile(attachment.id)'>
                <img src="assets/newMessage/attachdelete.svg" class="p-1 attchment-cross">
            </span>
    
            </div>
          </div>
          
          <input type="hidden" name="attachments">
        </div>
       
        <a class="d-flex flex-column justify-content-center ellipsis-btn" *ngIf="checkOverFlow(overflowdiv) && !isElipsisShow"
        (click)="showRemainingAttchment($event, attachmentOptions, postDataForAttachmentFile)">
          <img src="assets/newMessage/ellipse.svg" class="p-1 ellipse-size">
        </a>
      </div>
      <input type="hidden"  formControlName="hasPrevAttach"  [(ngModel)]="hasPrevAttach">
    </div>
    <div class="mobile-chevron">
      <span class="mobile-bar" (click) = "hideCcBcc()">See More</span>
      <i *ngIf="!isChevronUp" class='fa fa-angle-up' style='font-size:20px' (click) = "hideCcBcc()"></i>  <!-- C153176-5194:To/CC/ BCC changes -->
        <i *ngIf="isChevronUp" class='fa fa-angle-down' style='font-size:20px' (click) = "hideCcBcc()"></i>
    </div>
  </div>
  
  <p-overlayPanel [style]="{'margin-top': '-2em', 'border': '1px solid #007BC2'}" #attachmentOptions appendTo="body" styleClass="attachmentDropdown" [showTransitionOptions]="'1ms ease-out'" [hideTransitionOptions]="'1ms ease-in'">
    <p-listbox [listStyle]="{'max-height':'154px', 'max-width':'121px,', 'overflow-x': 'hidden'}" [options]="attachmentsOptions" [styleClass]="'newborder-section'"> 
        <ng-template let-attachOpt pTemplate="item">                       
            <div class="opt-items tooltip-text" style="display:inline-flex" >
              <div class="elipsis-attachment">
                  <span class="elipsis-attachment">  
                      <span ><img src="assets/newMessage/notes.svg" class="attachment-img">
                        <a href="#" title="{{attachOpt.name}}" class="attachment-font" (click)="downloadAttachment(attachOpt.id,attachOpt.name)">{{attachOpt.name}}</a>
                      </span>
                    </span>
              </div>
                  <span>
                    <a (click)='deleteAttachedFilePanel(attachOpt.id)'>
                    <img src="assets/newMessage/attachdelete.svg" class="p-1 attchment-cross">
                  </a>
                </span>
          </div>
        </ng-template>
    </p-listbox>
  </p-overlayPanel>
  <app-gfid-search [isNewMessage]="true" (addClicked)="getGFID($event)"></app-gfid-search> 
  
<p-toast position="center" (onClose)="onReject()" styleClass="custom-toast" key="custom" [baseZIndex]="5000"></p-toast>
<app-cmcsearch (cmcContactData)="getCmcContactData($event)"></app-cmcsearch>

<p-overlayPanel  class="addandshow" #addtotop appendTo="body" [styleClass]="'addandshow'" [dismissable]="true">
    <p-listbox (mouseleave) ="addtotop.hide()" [listStyle]="{'max-height':'200px', 'overflow-x': 'hidden'}" [options]="addOptions" class="dashboardsettinglist" [styleClass]="'dashboarsettingListStyle'">  
      <ng-template let-addOpt pTemplate="item">
        <a *ngIf="addOpt.name=='Add To Top Contacts'" (click)="addTopContacts($event, value);" title="{{addOpt.name}}" class="attachment-font"> {{addOpt.name}}</a>
        <a *ngIf="addOpt.name=='Show Member List'" (click)="showMemberList($event, value);" title="{{addOpt.name}}" class="attachment-font"> {{addOpt.name}}</a>
    </ng-template>
  </p-listbox>
</p-overlayPanel>

<p-dialog [(visible)]="displaySuccess" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'"
    [styleClass]="'contextMenuModal'" [closable]="false" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true"
    [style]="{height: noMember ? '18%':'40%',width: '25%'}">
    <p-header>
        <div class="d-flex flex-row align-items-center justify-content-between" style="display: inline !important">Show/Add Members            
        </div>
        <button type="button" (click)="cancelTopContact()" class="d-flex flex-row close qma-text">
          <img src="assets/Inbox/close_icon_u73.svg">
          <h6>CLOSE</h6>
      </button>
    </p-header>
    <div *ngIf="!noMember" class="d-flex flex-column flex-nowrap advance-search-elem">
      <div>
        <app-checkbox-list #groupMember [name]="'groups'" [search]="false" [items]="groupMemberList" [listStyle]="{'width':'25em','height':'25em', 'margin-top': '0.5em','font-size':'16px !important'}"
        (onItemChecked)="getSelectedMember($event)" [enableSelectAll]="true"></app-checkbox-list>
      </div>
    </div>
    <div *ngIf="noMember" class="d-flex flex-column flex-nowrap advance-search-elem">
      No Members Found
    </div>
    <p-footer class="showmember">
      <div class="d-flex flex-row footer-btn-container showmember">
      <div class="d-flex flex-row bottom-button-holder px-3 footer-buttons-holder justify-content-end">
          <button type="submit" class="btn search-btn mr-4" (click)="addToTopContact()">Add</button>
          <button type="reset" class="btn clear-btn" (click)="cancelTopContact()">Cancel</button>
      </div>
    </div>
  </p-footer>
</p-dialog>

<p-dialog [(visible)]="closeFlagInvalid" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'"
[styleClass]="'contextMenuModal errorModal ModelClose'" [closable]="true" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true"
[style]="{width: '15%'}">
    <p-header>
        <div class="d-flex flex-row align-items-center justify-content-between">
            <h6 class="success-title">Information</h6>        
        </div>
    </p-header>
    <div class="success-msg">Invalid email entered.</div>
</p-dialog>

<p-dialog [(visible)]="displayError" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'"
    [styleClass]="'contextMenuModal'" [closable]="false" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true"
    [style]="{height: '10%',width: '25%'}">
    <p-header>        
        <div class="d-flex flex-row align-items-center justify-content-between" style="display: inline !important">No Member Found            
        </div>
        <button type="button" (click)="OkToClose()" class="d-flex flex-row close qma-text">
            <img src="assets/Inbox/close_icon_u73.svg">
            <h6>CLOSE</h6>
        </button>
    </p-header> 
    <div class="d-flex flex-column flex-nowrap advance-search-elem">
      Technical issue has occurred! Please contact Support!
    </div>  
    <p-footer class="showmember">
      <div class="d-flex flex-row footer-btn-container showmember">
      <div class="d-flex flex-row bottom-button-ok px-3 footer-buttons-holder justify-content-end">
          <button type="submit" class="btn search-btn mr-4" (click)="OkToClose()">Ok</button>         
      </div>
    </div>
  </p-footer>
</p-dialog>

<!-- Show dialog box on save contact to top contact -->
<p-dialog [(visible)]="contactSuccess" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'" [styleClass]="'contextMenuModal successModal'"
    [closable]="false" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" [style]="{'width': '30%'}">
    <p-header>
        <div class="d-flex flex-row align-items-center justify-content-between">
            <h6 class="success-title">Success Confirmation</h6>
            <button type="button" (click)="closeModal('success')" class="d-flex flex-row close qma-text">
                <img src="assets/Inbox/close_icon_u73.svg">
                <h6>CLOSE</h6>
            </button>
        </div>
    </p-header>
    <div class="success-msg"> Contacts has been saved successfully</div>
</p-dialog>

<p-dialog [(visible)]="contactError" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'" [styleClass]="'contextMenuModal failureModal'"
    [closable]="false" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" [style]="{'width': '30%'}">
    <p-header>
        <div class="d-flex flex-row align-items-center justify-content-between">
            <h6 class="success-title">Information</h6>
            <button type="button" (click)="closeModal('error')" class="d-flex flex-row close qma-text">
                <img src="assets/Inbox/close_icon_u73.svg">
                <h6>CLOSE</h6>
            </button>
        </div>
    </p-header>
    <div class="success-msg">Technical issue has occurred! Please contact Support!</div>
</p-dialog>

<app-global-directory-suggestion *ngIf="displaySuggestion" [displaySuggestion]="displaySuggestion" 
[suggestionRecords]="suggestionRecords" [suggestionRecordCount]="suggestionRecordCount"
(citiUserEvent)="addSuggestionToRecipient($event)"></app-global-directory-suggestion>
<app-attachment-dialog #attachvwr [isMobile]="isMobile"(onPdfViewerOpen)="onPdfViewerOpen($event)"></app-attachment-dialog>


  <!-- Attachement List Popup -->
<p-dialog [(visible)]="showAttchementPopup" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'" 
[styleClass]="'contextMenuModal resolveModal attachement-grid-popup'"
[closable]="false" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" 
[style]="{'max-width':'600px','width':'100%'}" 
[resizable]="false">

<p-header>
<div class="d-flex flex-row align-items-center justify-content-between mx-2 mt-1">
    <div style="margin-top: 0.5em">
        <h6 class="wht-color attachement-header">List of Attachements</h6>
    </div>
    <div>
        <button type="button" (click)="hideModal()" class="d-flex flex-row close qma-text" style="opacity: 1;">
            <img src="assets/Inbox/close_icon_white.svg">
        </button>
    </div>
</div>
</p-header>
<div class="attch-body-pop-up mx-2">
    <div class="grid-options-row d-flex justify-content-between" style="display: none !important">
        <div class="left">
            <div class="info-text" style="visibi">
                ** Select attachements **
            </div>
        </div>
    </div>
    <div class="align-items-center d-flex justify-content-between">
      <div class="select-all-left d-flex align-items-center" style="margin-top: 15px;margin-bottom: 15px;">
      <div class="align-items-center d-flex" *ngIf="existingConvAttachements && existingConvAttachements.length 
      && existingConvAttachements.length > 0">
          <input type="checkbox" class="" [checked]="selectAllAttach" (click)="selectDeselectAllAttachements()"
          >
      </div>
      <div class="align-items-center d-flex" style="margin-left: 12px;">
          Select All
      </div>
    </div>
    <div class="select-all-right d-flex align-items-center">
        <div class="eye-icon" style="visibility:hidden">
            <img class="eye-img"  title="View" [src]="menuIconImageConfig.viewIconSrc"
              (mouseover)="menuIconImageConfig.viewIconSrc = menuIconImageConfig.viewIconmouseOn" 
              (mouseout)="menuIconImageConfig.viewIconSrc = menuIconImageConfig.viewIconmouseOut"
              >
        </div>
          <div class="menu-icon-border-pop-up" style="visibility:hidden"></div>
          <div class="image-container">
            <!-- [src]="'assets/Inbox/download_icon_u70.svg'" -->
            <img class="download-icon-pop-up"  style ="margin-right: 20px;" title="Download" 
            [src]="menuIconImageConfig.downloadAllIconSrc"
              (mouseover)="menuIconImageConfig.downloadAllIconSrc = menuIconImageConfig?.downloadAllIconmouseOn" 
              (mouseout)="menuIconImageConfig.downloadAllIconSrc = menuIconImageConfig?.downloadAllIconmouseOut"
              (click)="onDownloadAttachment(existingConvAttachements)">
              <!-- <img class="download-icon-pop-up"  style ="margin-right: 12px;" title="Download" 
              [src]="getDownloadIconImage(attachment)" 
              (mouseover)="onMouseOverDownloadIcon(attachment)"
                (mouseout)="onMouseOutDownloadIcon(attachment)"
              (click)="onDownloadAttachment(existingConvAttachements)"> -->
          </div>
    </div>
    </div>
  <!--  attachements List for select deselect -->
  <div class="attachment-lst-pop-up-container">
  <div class="attach-list-container">
  <ng-container *ngFor="let attachment of existingConvAttachements;let i = index">
    <div (mouseenter)="showAttachments(null, null, existingConvAttachements)" class="attachement d-flex align-items-center">
      <!-- *ngIf="i<3" -->
        <div class="pop-up-list-left d-flex" style="width: 85%">
            <div class="d-flex align-items-center" style="padding-right: 10px;background: white;">
                <input type="checkbox" value="" [checked]="attachment.isSselected" (change)="onExistingAttachementSelected(attachment)"
                  class="">
              </div>
              <div class="d-flex align-items-center" 
              [ngClass]="{'selected-attach': attachment.isSselected}"
              style="width: 100%;border-radius: 4px;height: 30px;justify-content: space-between;">
                  <!-- (mouseenter)="onMouseEnterAttTitle(attachment)" (mouseleave)="onMouseLeaveAttTitle(attachment)" -->
                  <!-- {{i}}  -->
                 
                  <div class="attachement-title d-flex align-items-center" 
                  style="color: #021E4A !important;font-size: 12px;font-family: 'Interstate';width: 75%;overflow: hidden;">
                      <img class="download-icon d-flex align-items-center" [src]="getAttachementImageByExtension(attachment)" style="margin-top: 0%;margin-left: 5px;
                      margin-right: 5px;" />
                    <div style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis;" [attr.title]="attachment?.name">
                        {{attachment?.name}}
                    </div>
                   
                  </div>
                  <div style="color:#9E9FA0;font-size: 12px;font-family: 'Interstate';width: 40%;" class="d-flex justify-content-end mr-1">
                      {{attachment?.time}}
                  </div>
                </div>
        </div>
    
      <div class="menu-pop-up" >
      <div class="eye-icon">
       <!--  <img class="eye-img" title="View" [src]="menuIconImageConfig.viewIconSrc" 
        (mouseover)="menuIconImageConfig.viewIconSrc = menuIconImageConfig.viewIconmouseOn"
          (mouseout)="menuIconImageConfig.viewIconSrc = menuIconImageConfig.viewIconmouseOut"
          (click)="onViewctionRedesigned(attachment)"> -->
          <img class="eye-img" title="View" [src]="getViewIconImage(attachment)" 
          (mouseover)="onMouseOverViewIcon(attachment)"
          (mouseout)="onMouseOutViewIcon(attachment)"
          (click)="onViewctionRedesigned(attachment)">
      </div>
      <div class="menu-icon-border-pop-up">
          <img src="assets/Inbox/divider-line.svg">
      </div>
      <div class="image-container">
        <!-- [src]="'assets/Inbox/download_icon_u70.svg'" -->
        <!-- <img class="download-icon-pop-up" style="margin-right: 8px;" title="Download" [src]="menuIconImageConfig.downloadIconSrc" 
        (mouseover)="menuIconImageConfig.downloadIconSrc = menuIconImageConfig.downloadIconmouseOn"
          (mouseout)="menuIconImageConfig.downloadIconSrc = menuIconImageConfig.downloadIconmouseOut"
          (click)="onDownloadAttachment(attachment)"> -->
          <img class="download-icon-pop-up" style="margin-right: 8px;" title="Download"
          [src]="getDownloadIconImage(attachment)" 
        (mouseover)="onMouseOverDownloadIcon(attachment)"
          (mouseout)="onMouseOutDownloadIcon(attachment)"
          (click)="onDownloadAttachment(attachment)">
      </div>
      </div>
    </div>
  
  </ng-container>
  </div>
</div>
</div>
<p-footer class="showmember">
    <div class="d-flex justify-content-end">
    <div class="d-flex" style="margin-top:10px;margin-bottom:25px;">
        <button  class="btn search-btn mr-4 pop-up-attch-button" (click)="btnAttachClick()">Attach</button>
        <button  class="pop-up-cancel-button " (click)="btnAttachCancelClick()">Cancel</button>
    </div>
  </div>
</p-footer>
</p-dialog>
<p-dialog [(visible)]="displayErrorForBlockedChars" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'" [styleClass]="'blacklistModel'"
  [closable]="false" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" [style]="{width: '30%'}">
  <p-header>
    <div class="d-flex flex-row align-items-center justify-content-between">
      <h6 class="success-title">Information</h6>
      <div class="d-flex">
        <div class="d-flex">
          <div class="d-flex">
            <img src="assets/Inbox/info-icon.svg" class="icon-setting-out"  (mouseover)="showUploadFilesPanel($event, info_upload_files)" (mouseout)="info_upload_files.hide()"/> <!-- (mouseout)="info_upload_files.hide()" -->
            <p-overlayPanel [styleClass]="'icon-info-upload-files inquiry_rules_info'" [appendTo]="'body'" #info_upload_files [baseZIndex]="9999">
                <div class="flex-row mb-0.3" style="font-family: 'Interstate'">
                  <div class="info-content" style="font-family: 'Interstate';font-weight: bold;">
                    List of extensions restricted to upload:-
                  </div>
                    <div class="info-content">{{qmaConstant?.QMA_UPLOAD_FILES_INFO}}</div>
                   
                </div>
                <div class="flex-row mb-0.3  mt-2"  style="font-family: 'Interstate';">
                  <div class="info-content" style="font-family: 'Interstate';font-weight: bold;">
                    List of  restricted characters in file name to upload:-
                  </div>
                  <div style="font-family: 'Interstate';" class="info-content">
                    {{Qma_blocked_file_name_chars}}
                  </div>
                </div>
            </p-overlayPanel>
          </div>
            <button type="button" (click)="closeModalBlockedChars('error')" class="d-flex flex-row close qma-text">
                <img src="assets/Inbox/close_icon_u73.svg">
                <h6>CLOSE</h6>
            </button>
        </div>
       
    </div>
    </div>
  </p-header>
  <div class="success-msg" style="font-weight: bold;" *ngIf="errMsgRestrcitedCharsInFilename">
    Below uploaded file name contains special characters which is replaced by _  Please refer to information icon for more details
  </div>
  <div class="success-msg" [innerHTML]="errMsgRestrcitedCharsInFilename"></div>
</p-dialog>

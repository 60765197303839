import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DashboardViewComponent } from './dashboard-view/dashboard-view.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { InquiriesByAssignedGroupComponent } from './inquiries-by-assigned-group/inquiries-by-assigned-group.component';
import { InquiriesByAssignedOwnerComponent } from './inquiries-by-assigned-owner/inquiries-by-assigned-owner.component';
import { InquiriesByRequestTypeComponent } from './inquiries-by-request-type/inquiries-by-request-type.component';
import { DialogModule } from 'primeng/dialog';
import { ListboxModule } from 'primeng/listbox';
import { TabViewModule } from 'primeng/tabview';
import { AgGridModule } from '@ag-grid-community/angular';
import { MultiSelectModule } from 'primeng/multiselect';
import { CheckboxModule } from 'primeng/checkbox';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { SharedModule } from '../common/shared-module';
import { DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InquiriesByTrendComponent } from './inquiries-by-trend/inquiries-by-trend.component';
import { IntensityHeatmapComponent } from './intensity-heatmap/intensity-heatmap.component';
import { DashboardSettingComponent } from './dashboard-setting/dashboard-setting.component';
import { MailboxStatisticsComponent } from './mailbox-statistics/mailbox-statistics.component';
import { ButtonModule } from 'primeng/button';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { FadingDialogComponent } from './fading-dialog/fading-dialog.component';
import { InboxModule } from 'src/app/inbox/inbox.module';
import { TooltipModule } from 'primeng/tooltip';
import { ToastModule } from 'primeng/toast';


@NgModule({
  declarations: [DashboardViewComponent, InquiriesByAssignedGroupComponent,
    InquiriesByAssignedOwnerComponent, InquiriesByRequestTypeComponent,
    InquiriesByTrendComponent, IntensityHeatmapComponent, DashboardSettingComponent, MailboxStatisticsComponent, FadingDialogComponent,
  ],
  imports: [
    CommonModule,
    OverlayPanelModule,
    ListboxModule,
    TabViewModule,
    DialogModule,
    FormsModule,
    AgGridModule,
    ReactiveFormsModule,
    MultiSelectModule,
    CheckboxModule,
    SharedModule,
    DropdownModule,
    InputSwitchModule,
    AutoCompleteModule,
    ButtonModule,

    InboxModule,
    TooltipModule,
    ToastModule
  ],
  exports: [
    DashboardViewComponent,
    SharedModule,
    FadingDialogComponent
  ]

})
export class DashboardModule { }

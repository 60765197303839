import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, ChangeDetectorRef, HostListener } from '@angular/core';
import { DashboardService } from '../../services/dashboard/dashboard.service';
import * as HighCharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import OfflineExport from 'highcharts/modules/offline-exporting';
import ExportData from 'highcharts/modules/export-data';
import { DashboardLocalService } from "../../services/dashboard/dashboard-local.service";
import { InboxService } from 'src/app/services/inbox.service';
import { UserDataService } from 'src/app/services/user-data.service';
import { FadingDialogComponent } from 'src/app/dashboard/fading-dialog/fading-dialog.component';
import * as $ from 'jquery';
import { PiwikProUtils } from 'src/app/common/utility/piwikProUtils';

HC_exporting(HighCharts);
OfflineExport(HighCharts);
ExportData(HighCharts);

@Component({
  selector: 'app-inquiries-by-assigned-owner',
  templateUrl: './inquiries-by-assigned-owner.component.html',
  styleUrls: ['./inquiries-by-assigned-owner.component.scss']/* ,
  host: {
    '(document:keydown)': 'onKeydown($event)',
    '(window:resize)': 'onResize($event)'
  } */,
  host: {
    '(window:resize)': 'onResize($event)'
  }
})

export class InquiriesByAssignedOwnerComponent implements OnInit, AfterViewInit {
  ngAfterViewInit(): void {
   /*  this.cdr.detach() */
  }
  rootNode: any;
  protected chartWidth;
  protected chartHeight;
  HighCharts = HighCharts; // required
  protected inquiriesByOwner: any = {
    chart: {
      type: 'column',
      backgroundColor: '#FCFCFC',
      events: {
        redraw(e) {
            if (this.options && this.options.ngComponent) {
              this.options.ngComponent.enableLegendToggle(this, true);
            } else {
              console.log('Cannot find ng component for request type chart!');
            }
        }
      }
    },
    scrollbar: {
      barBackgroundColor: '#002D72',
      barBorderColor: '#002D72',
      barBorderRadius: 7,
      barBorderWidth: 0,
      buttonBackgroundColor: '#FCFCFC',
      buttonBorderColor: '#FCFCFC',
      buttonBorderWidth: 0,
      buttonArrowColor: '#FCFCFC',
      buttonBorderRadius: 7,
      rifleColor: '#FCFCFC',
      trackBackgroundColor: '#FCFCFC',
      trackBorderWidth: 1,
      trackBorderColor: '#FCFCFC',
      trackBorderRadius: 7,
      height: 6,
      minWidth: 30, // C153176-4981: ensure minimal width of scrollbar
      enabled: true
    },
    colors: ['#FFE57E', '#004785', '#D40E8C', '#F7921E', '#2E64AF', '#9361A8', '#00BDF2', '#00B0B9', '#D1D1D1',
      '#FFD600', '#EF7B87', '#56D9FE', '#FFBF00', '#65C8AE', '#54B1ED', '#185E99', '#FFC559', '#E65168'],
    title: {
      useHTML: true,
      text: '<span class="title" style="line-height: 15px;font-size: 15px;font-family:'
        + ' \'InterstateLight\';">OPEN INQUIRIES BY OWNER</span>',
      align: 'left'
    },
    subtitle: {
      useHTML: true,
      text: '<div><span>HIGHEST </span><span style="font-size:12px;color:#1FA6F4;padding:0px 5px;" class="highestOwner"></span>' +
        '<span style="font-size:16px;color:red;padding-right:5px;" class="highestCount"></span><span style="margin-right:15px" class="lineBreak">&nbsp;inquiries</span>' +
        '<span>&nbsp;&nbsp;<br class="d-block d-sm-none">LOWEST&nbsp;</span><span style="font-size:12px;color:#1FA6F4;padding:0px 5px;" class="lowestOwner"></span>' +
        '<span style="font-size:16px;color:green;padding-right:5px;" class="lowestCount"></span><span> inquiries</span></div>',
      align: 'right',
      verticalAlign: 'top',
      x: -30
    },
    xAxis: {
      categories: [],
      crosshair: true,
      min: 0,
      max: 12,
      labels: {
        formatter() {
          let str = this.value;
          if (!isNaN(str)) {
            str = '';
          }
          return str;
        }
      }
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Inquiries'
      }
    },
    tooltip: {
      headerFormat: '<div style="font-size:10px">{point.key}</div><table>',
      pointerFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td> '
        + '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
      footerFormat: '</table>',
      shared: false,
      backgroundColor: 'rgba(252,252, 252, 1)',
      useHTML: true
    },
    legend: {
      align: 'center',
      layout: 'horizontal',
      verticalAlign: 'bottom',
      itemMarginRight: 12,
      symbolRadius: 0,
      symbolPadding: 0,
      symbolWidth: 0.1,
      symbolHeight: 0.1,
      labelFormatter() {
        const value = this.name;
        const temp = value.length > 25 ? value.slice(0, 25) + '...' : value; // #3
        return '<div class="legendLabel" title="' + value + '"> <span class="custom-marker" style="border-color:'
          + this.color + '"></span>' + temp + '</div>'; // #3
      },
      useHTML: true
    },
    plotOptions: {
      column: {
        borderWidth: 0,
        dataLabels: {
          formatter() {
            let value = this.y;
            if (isNaN(value) || !value) { // hide 0
                value = '';
            }
            return value;
          },
          enabled: true,
          crop: false,
          color: '#2E64AF',
          overflow: 'none',
          fontSize: 10
        }
      },
      series: {
        pointWidth: 10,
        pointPadding: 0,
        groupPadding: .05,
        dataLabels: '{"align": "center", "formatter": function () { return H.numberFormat(this.y, -1); }, ' +
          '"padding": 5, "style": {"fontSize": "10px", "font-family": "InterstateLigh", "color": "contrast", "textOutline": ' +
          '"1px contrast"}, "verticalAlign": "bottom", "x":0, "y": 0}',
          events:{ /* C153176-5538 */
            legendItemClick:function(){
              let s = this.chart.series,
              index = this.index;
              let isHidden = s.some((obj)=>!obj.visible);
  
              if(isHidden){
                s[index].setVisible(!s[index].visible);
                if(s.every(obj => !obj.visible)){ //C153176-5756:Show all when none selected
                  for(let i = 0; i < s.length; i++) {
                    s[i].setVisible(true);
                  }
                }
                return false
              }
              
              for(let i = 0; i < s.length; i++) {
                s[i].setVisible(i == index);
              }
              return false;
            }
          }
      }
    },
    series: [
    ],
    navigation: {
      buttonOptions: {
        enabled: true,
        symbolSize: 28,
        symbolX: 25,
        symbolY: 22
      }
    },
    // C153176-4458 - width and overalp issue
    responsive: {
      rules: [{
          condition: {
              maxWidth: 767
          },
          chartOptions: {
            
             plotOptions: {
              xAxis: {
                min: 0,
                max: 2
              },
              
              series: {
                pointWidth: 10,
                pointPadding: 1,
                //groupPadding: 2,
                //groupPixelWidth: 30
              }
            }
          }
      }]
  },
    exporting: {
      // C153176-4479: support larger print max width
      printMaxWidth: 3000,
      menuItemDefinitions: {
        maximize: {
          onclick() {
            if (this.options && this.options.ngComponent) {
              this.options.ngComponent.enterFullScreen(this);
            } else {
              console.log('Failed to enter full screen');
            }
          },
          text: 'Maximize'
        }
      },
      buttons: {
        contextButton: {
          menuItems: [{
            textKey: 'printChart',
            onclick() {
              this.print();
            }
          }, {
            textKey: 'downloadPNG',
            onclick: () => { this.localService.download("inquiries-by-owner", "png"); }
            // function () {
            //     this.exportChart();
            // }
          }, {
            textKey: 'downloadJPEG',
            onclick: () => { this.localService.download("inquiries-by-owner", "jpg"); }
            // function () {
            //     this.exportChart({
            //         type: 'image/jpeg'
            //     });
            // }
          }, {
            textKey: 'downloadPDF',
            onclick: () => { this.localService.download("inquiries-by-owner", "pdf"); }
            // function () {
            //     this.exportChart({
            //         type: 'application/pdf'
            //     });
            // }
          }, {
            textKey: 'downloadCSV',
            onclick() {
              this.downloadCSV();
            }
          }, "maximize"], // "viewFullscreen"],
          symbol: 'url(assets/dashboard/contextmenu.png)'
        }
      },
      csv: {
        decimalPoint: ":"
      }

    },
    credits: {
      enabled: false
    },
    ngComponent: null
  };
  protected ownerChart: any;
  protected chartData: any;
  protected selectedGroup: string;
  protected fullScreen = false;
  @ViewChild(FadingDialogComponent, { static: true }) fadingDialogComponent;
  protected ownerNameIds = {};

  constructor(private dashboardService: DashboardService, private localService: DashboardLocalService,
    private inboxService: InboxService, private userDataService: UserDataService, private cdr: ChangeDetectorRef) {
    this.selectedGroup = null;
    this.inquiriesByOwner.ngComponent = this;
  }

  ngOnInit() {
    // subscribing data from local variable to reduce server hits.
    this.dashboardService.getDashboardChartData.subscribe((data: any) => {
      if (this.handleAssignedOwnersByAgeBand(data && data.assignedOwnersByAgeBand ? data.assignedOwnersByAgeBand : undefined)) {
        const elem = $('#inquiries-by-owner');
        if (elem && elem.length) {
          HC_exporting(HighCharts);
          OfflineExport(HighCharts);
          ExportData(HighCharts);
          HighCharts.setOptions({ lang: { thousandsSep: "," } });
          this.inquiriesByOwner.xAxis.max = this.maxCateogryOnload();
          this.ownerChart = HighCharts.chart('inquiries-by-owner', this.inquiriesByOwner);
          this.ownerChart.options.ngComponent = this;
          this.setupEventHandler();
          this.enableLegendToggle(this.ownerChart, false);
        }
      }
    });
    this.localService.getSelectedGroupName.subscribe(group => {
      if (!group || group === this.selectedGroup) {
        this.selectedGroup = null;
      } else {
        this.selectedGroup = group;
      }
      if (this.updateChart(false)) {
        const elem = $('#inquiries-by-owner');
        if (elem && elem.length) {
          this.ownerChart = HighCharts.chart('inquiries-by-owner', this.inquiriesByOwner);
          this.ownerChart.redraw();
          this.setupEventHandler();
          this.enableLegendToggle(this.ownerChart, false);
        }
      }
    });
  }
  /*C153176-4795 Mobile change */
  private maxCateogryOnload() {
    if($(window).width() > 767)
      return Math.round(($(window).width() - 205 ) / 120);
    else{
      return 3
    }
  }
  /* End C153176-4795 Mobile change */
  private handleAssignedOwnersByAgeBand(jsonData: string): boolean {
    if (!jsonData) {
      return false;
    }
    this.chartData = JSON.parse(jsonData);
    return this.updateChart(true);
  }

  protected updateChart(refresh: boolean): boolean {
    if (!this.chartData) {
      return false;
    }
    // reduce the array to two-layer map keyed by group and requestType
    const data = this.chartData;
    const currentGroup = this.selectedGroup;
    // reduce the array to two-layer map keyed by ageBand and assignedOwner
    const allOwners = new Set();
    const ownersByAgeBrand = data.reduce((map: {}, item) => {
      if (item) {
        const group: string = item.group;
        if (currentGroup && group !== currentGroup) {
          return map;
        }
        const ageBand: string = item.ageBand;
        const assignedOwner: string = item.assignedOwner;
        const itemCount: number = item.count;
        const assignedOwnerId: string = item.assignedOwnerId;
        // C153176-4447: build owner name-to-id mappings
        if (assignedOwner && assignedOwnerId) {
          this.ownerNameIds[assignedOwner] = assignedOwnerId;
        }
        const ownerItem = map[ageBand];
        if (ageBand && assignedOwner) {
          if (ownerItem) {
            if (itemCount) {
              allOwners.add(assignedOwner);
              let existingCount: number = ownerItem[assignedOwner];
              if (existingCount !== undefined) {
                existingCount += itemCount;
                ownerItem[assignedOwner] = existingCount;
              } else {
                ownerItem[assignedOwner] = itemCount;
              }
            }
          } else {
            // do not assign zero counts
            map[ageBand] = {};
            map[ageBand][assignedOwner] = itemCount ? itemCount : 0;
          }
        }
      }
      return map;
    }, {});
    // get min/max counts

    let filterData = data;
    if (this.selectedGroup) {
      filterData = data.filter(val => val.group === this.selectedGroup);
    }
    const totalCounts = filterData.reduce((map: {}, item) => {
      if (item) {
        const assignedOwner: string = item.assignedOwner;
        const itemCount: number = item.count;
        let existingCount = map[assignedOwner];
        if (existingCount) {
          if (itemCount) {
            existingCount += itemCount;
            map[assignedOwner] = existingCount;
          }
        } else {
          if (itemCount) {
            map[assignedOwner] = itemCount;
          } else {
            map[assignedOwner] = 0;
          }
        }
      }
      return map;
    }, {});

    if (!this.isEmpty(totalCounts)) {
      // get the highest count entry
      const highestOwner = Object.keys(totalCounts).reduce((prev, cur) =>
        totalCounts[prev] > totalCounts[cur] ? prev : cur
      );
      const highestCount = highestOwner ? totalCounts[highestOwner] : 0;
      // get the lowest count entry
      const lowestOwner = Object.keys(totalCounts).reduce((prev, cur) =>
        totalCounts[prev] < totalCounts[cur] ? prev : cur
      );
      const lowestCount = lowestOwner ? totalCounts[lowestOwner] : 0;

      const tempDom = $.parseHTML(this.inquiriesByOwner.subtitle.text);
      this.inquiriesByOwner.subtitle.style = { "visibility": "visible" };
      $('.highestOwner', tempDom).text(highestOwner);
      $('.lowestOwner', tempDom).text(lowestOwner);
      $('.highestCount', tempDom).text(highestCount);
      $('.lowestCount', tempDom).text(lowestCount);

      this.inquiriesByOwner.subtitle.text = $(tempDom).prop('outerHTML');
    } else {
      const tempDom = $.parseHTML(this.inquiriesByOwner.subtitle.text);
      this.inquiriesByOwner.subtitle.style = { "visibility": "hidden" };
      this.inquiriesByOwner.subtitle.text = $(tempDom).prop('outerHTML');
    }
    /* this.inquiriesByOwner.subtitle.text = this.inquiriesByOwner.subtitle.text
      .replace('${highestOwner}', highestOwner)
      .replace('${highestCount}', highestCount)
      .replace('${lowestOwner}', lowestOwner)
      .replace('${lowestCount}', lowestCount);
    // get list of sorted ageBands */
    const ageBands: Array<string> = Object.keys(ownersByAgeBrand).sort(); // .reverse();
    const allOwnerArray = Array.from(allOwners).sort();
    // fill in empty slots with zero counts and populate series
    this.inquiriesByOwner.series = [];
    ageBands.map(ageBand => {
      let ownerItem = ownersByAgeBrand[ageBand];
      if (!ownerItem) {
        console.log("Invalid owner item on ageBand=" + ageBand);
      }
      // generate a serie from 'ownerItem'
      const serie = { name: undefined, data: undefined };
      serie.name = ageBand.split(". ")[1];
      serie.data = [];
      let augmented = false;
      ownerItem = allOwnerArray.reduce((item: {}, owner:string) => {
        if (!item[owner]) {
          item[owner] = 0;
          augmented = true;
        }
        serie.data.push(item[owner]);
        return item;
      }, ownerItem);
      if (augmented) {
        ownersByAgeBrand[ageBand] = ownerItem;
      }
      // push this serie to chart.series
      this.inquiriesByOwner.series.push(serie);
      return ageBand;
    });
    this.inquiriesByOwner.xAxis.categories = allOwnerArray;
    const width: number = Math.max(600, allOwnerArray.length * 130);
    // this.inquiriesByOwner.chart.scrollablePlotArea.minWidth = width;
    return true;
  }

  isEmpty(obj) {
    return !obj || Object.keys(obj).length === 0;
  }
  setupEventHandler() {
    if (this.ownerChart) {
      this.ownerChart.series.forEach((series) => {
        if (series && series.points) {
          series.points.forEach((p) => {
            if (p && p.graphic) {
              const point = p;
              p.graphic.on('dblclick', () => {
                if (point && point.series && point.series.chart && point.series.chart.options
                  && point.series.chart.options.ngComponent) {
                  point.series.chart.options.ngComponent.handleDoubleClick(point.category, point.color, point.y, point.series.name);
                }
              });
            }
          });
        }
      });
    }
  }

  handleDoubleClick(name, color, count, age) {
    this.requestMessageView(name, color, count, age);
    PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId,  "Dashboard", "Open Inquiries by user bar", "Double Click", "Open Inquiries by user bar", 1);
  }

  requestMessageView(name, color, count, age) {
    // C153176-4447: request with 'groupId', 'assignedOwnerId', 'ageBand' and 'isReqFromQma2Dashboard'
    const groupId = this.selectedGroup && this.userDataService && this.userDataService.allActiveGroupIds ?
      this.userDataService.allActiveGroupIds[this.selectedGroup]: "all";
    this.inboxService.startViewLoading({color});
        // C153176-4408: 'Owner' --> 'Owners' to align with service API
        this.inboxService.requestView({viewName: "Open Inquiries By Assigned Owners", count, type: "assignedUserName",
      value: name, subtype: 'age', subvalue: age, groupId: groupId, ageBand: age, assignedOwnerId: this.ownerNameIds[name],
      isReqFromQma2Dashboard: true});
  }

  // handle ESC in case of full screen
  @HostListener('document:keydown.esc', ['$event'])
  onKeydown(event) {
    event.stopPropagation();
    event.preventDefault();
    if (this.fullScreen && event && event.code === 'Escape') {
      this.exitFullScreen(this.ownerChart);
    }
  }

  enterFullScreen(chart: any) {
    const elem = $('#assigned-owner-box');
    if (elem && elem.length) {
      elem.toggleClass('col-sm-12');
      elem.toggleClass('dashboardModal');
    }
    this.fullScreen = true;
    if (chart.exportContextMenu && chart.exportContextMenu.childNodes) {
      chart.exportContextMenu.childNodes.forEach(node => {
        if (node && node.childNodes) {
          node.childNodes.forEach(n => {
            if (n && n.innerText === 'Maximize') {
              n.style.display = 'none';
            }
          });
        }
      });
    }
    chart.reflow();
    if (this.fadingDialogComponent) {
      this.fadingDialogComponent.show($('#inquiries-by-owner-box')[0]);
    }
  }

  exitFullScreen(chart: any) {
    if (!chart) {
      return;
    }
    const elem = $('#assigned-owner-box');
    if (elem && elem.length) {
      elem.toggleClass('col-sm-12');
      elem.toggleClass('dashboardModal');
    }
    this.fullScreen = false;
    if (this.fadingDialogComponent) {
      this.fadingDialogComponent.hide($('#inquiries-by-owner-box')[0]);
    }
    if (chart.exportContextMenu && chart.exportContextMenu.childNodes) {
      chart.exportContextMenu.childNodes.forEach(node => {
        if (node && node.childNodes) {
          node.childNodes.forEach(n => {
            if (n && n.innerText === 'Maximize') {
              n.style.display = '';
            }
          });
        }
      });
    }
    chart.reflow();
  }
  enableLegendToggle(chart, resize) {
    if (!chart) {
      return;
    }
    if (this.chartWidth === undefined || chart.chartWidth !== this.chartWidth || chart.chartHeight !== this.chartHeight) {
      this.chartWidth = chart.chartWidth;
      this.chartHeight = chart.chartHeight;
    } else if (resize) {
      // request due to resize, but size hasn't changed, do nothing
      return;
    }
    if (!resize && chart.toggleButton && chart.toggleLabel) {
      // if not resizing but chart already has a toggle button, do nothing
      return;
    }
    // re-create the toggle label and button
    if (chart.toggleButton) {
      chart.toggleButton.destroy();
      chart.toggleButton = null;
    }
    if (chart.toggleLabel) {
      chart.toggleLabel.destroy();
      chart.toggleLabel = null;
    }
    const lWidth = Math.max(0, Math.floor(this.chartWidth - 160));
    chart.toggleLabel = chart.renderer.text(
      '<span class="mobileView" style="font-size:13px;font-family:\'InterstateLight\';text-align:right;min-width:90px;">HIDE LEGEND</span>',
      lWidth,
      20,
      true).css({
        textAlign: 'right',
        minWidth: '90px'
      }).add();
    const bbox = chart.toggleLabel.getBBox();
    chart.toggleButton = chart.renderer.label('<label class="switch-rt"><input id="assigned-owner-legend-toggle"'
      + ' type="checkbox" checked><span class="slider-rt round-rt"></span></label>',
      lWidth + bbox.width + 5,
      5,
      'button',
      null,
      null,
      true).on('change', (e) => {
        // do nothing here
      }).add();

    setTimeout(() => {
      const elem = $("#assigned-owner-legend-toggle");
      elem.val(true);
      if (elem && elem.length) {
        elem.change(e => {
          // C153176-4458 - event issue show hide
          //const val = !$("#assigned-owner-legend-toggle").prop('checked');
          //$('#assigned-owner-legend-toggle').val(val);
          if (this.ownerChart) {
            this.toggleLegend(this.ownerChart);
          }
        });
      } else {
        console.log('Cannot find #assigned-owner-legend-toggle');
      }
    }, 100);
    setTimeout(() => {
      if (window.innerWidth < 767) {
        $("#assigned-owner-legend-toggle").prop('checked', false);
        this.hideLegend(this.ownerChart);
      }
    }, 800);
  }
  toggleLegend(chart) {
    if (!chart) {
      return;
    }
    const legend = chart.legend;
    let text;
    let html;
    if (legend.display) {
      chart.legend.update({
        enabled: false
      });
      //C153176-5157 mobile hide issue changes
      legend.display = false;
      text = 'SHOW LEGEND';
      html = '<span class="mobileView" style="font-size:13px;font-family:\'InterstateLight\';text-align:right;min-width:90px;">SHOW LEGEND</span>';
    } else {
      chart.legend.update({
        enabled: true
      });
      legend.display = true;
      text = 'HIDE LEGEND';
      html = '<span class="mobileView" style="font-size:13px;font-family:\'InterstateLight\';text-align:right;min-width:90px;">SHOW LEGEND</span>';
    }
    if (chart.toggleLabel && chart.toggleLabel.element) {
      chart.toggleLabel.element.textContext = text;
      chart.toggleLabel.element.innerText = text;
      chart.toggleLabel.element.innerHTML = html;
    }
    chart.redraw();
  }
  hideLegend(chart) {
    if (!chart) {
      return;
    }
    const legend = chart.legend;
    let text;
    let html;
    chart.legend.update({
      enabled: false
    });
    legend.display = false;
    text = 'SHOW LEGEND';
    html = '<span class="mobileView" style="font-size:13px;font-family:\'InterstateLight\';text-align:right;min-width:90px;">SHOW LEGEND</span>';

    chart.toggleLabel.element.textContext = text;
    chart.toggleLabel.element.innerText = text;
    chart.toggleLabel.element.innerHTML = html;

    chart.redraw();
  }
  showLegend(chart) {
    if (!chart) {
      return;
    }
    const legend = chart.legend;
    let text;
    let html;
    chart.legend.update({
      enabled: true
    });
    legend.display = true;
    text = 'HIDE LEGEND';
    html = '<span class="mobileView" style="font-size:13px;font-family:\'InterstateLight\';text-align:right;min-width:90px;">SHOW LEGEND</span>';
    chart.toggleLabel.element.textContext = text;
    chart.toggleLabel.element.innerText = text;
    chart.toggleLabel.element.innerHTML = html;

    chart.redraw();
  }
  // C153176-4458 - event issue show hide
  onResize(event) {
  //   if (event.target.innerWidth < 767) // window width
  //   {
  //     this.hideLegend(this.ownerChart);
  //   } else {
  //     this.showLegend(this.ownerChart);
  //   }
  }
}

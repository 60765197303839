<div [ngSwitch]="type" [ngClass]="styleClass" *ngIf="!symphonyRedesign">
  <div *ngSwitchCase="userType.GROUP"  pTooltip="{{content}}" tooltipPosition="bottom">
    <img class="display-pic-bubble mr-1" [src]="imageSource" alt="">
  </div>
  <div *ngSwitchCase="userType.EXTERNAL"  pTooltip="{{content}}" tooltipPosition="bottom">
    <img class="display-pic-bubble mr-1" [src]="imageSource" alt="">
  </div>
  <div *ngSwitchCase="userType.INDIVIDUAL"  pTooltip="{{content}}" tooltipPosition="bottom">
    <div class="display-individual">{{initials}}</div>
  </div>
</div>

<div [ngSwitch]="type" [ngClass]="styleClass"  *ngIf="symphonyRedesign">
    <div *ngSwitchCase="userType.GROUP"  pTooltip="{{content}}" tooltipPosition="bottom">
      <img class="display-pic-bubble mr-1" [src]="imageSource" style="height: 40px;width: 40px;border-radius:0%" alt="">
    </div>
    <div *ngSwitchCase="userType.EXTERNAL"  pTooltip="{{content}}" tooltipPosition="bottom">
      <img class="display-pic-bubble mr-1" [src]="imageSource" style="height: 40px;width: 40px;border-radius: 0%" alt="">
    </div>
    <div *ngSwitchCase="userType.INDIVIDUAL"  pTooltip="{{content}}" tooltipPosition="bottom">
     <!--  <div class="display-individual">{{initials}}</div> -->
      <div class="container">
          <img [src]="imageSource"  style="height: 40px;width: 40px" alt="">
          <div class="centered" style="color:white;text-transform: uppercase" >{{initials}}</div>
      </div>
    </div>
  </div>
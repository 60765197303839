export let SelectedMailModel = {
    "id": 11231537,
    "selectInqAssignedGroupId": [
      2533
    ],
    "conversationList": [
        2533
    ],
    "isAllConvContent": false,
    "hasNominatedOwnership": false
  }
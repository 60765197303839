import { Injectable } from '@angular/core';
import * as Rx from "rxjs";
import { Observable } from "rxjs";
import { MailBoxGridColdefs } from "src/app/inbox/inboxView/inboxGridColumnDefs";

// subscriber 2
@Injectable({
  providedIn: 'root'
})

export class ColDefsService {

  private arrayColDef = [];
  public arrPrevConv = [];
  private arrCurrentConv = [];
  private cachedWidths: any = {}; // C153176-4399: cached original width before adjustment
  //myMap = new Map();
  constructor() {
    //this.myMap.set("default",new MailBoxGridColdefs().getMailboxColDefs());
    sessionStorage.setItem("default", JSON.stringify(new MailBoxGridColdefs().getMailboxColDefs()));

  }
  convGridData = new Rx.BehaviorSubject([{ conv: 0 }]);
  // C153176-4456: added subject to trigger closing of Conv History
  closeConvGridData = new Rx.BehaviorSubject([{ conv: 0 }]);
  colDefs = new Rx.BehaviorSubject("default");
  currentConv = new Rx.BehaviorSubject([]);
  convRowDraw = new Rx.BehaviorSubject([]);

  /* getcolDefs(): Observable<any> {
    return this.colDefs.asObservable();
  } */
  getcolDefs(): Observable<any> {
    return this.colDefs.asObservable();
  }

  setcolDefs(currentTab, colDefs) {
    this.arrayColDef[currentTab] = colDefs;
    //this.myMap.set(currentTab,colDefs);
    sessionStorage.setItem(currentTab, JSON.stringify(colDefs));
    this.colDefs.next(currentTab);
  }

  getStoredColDefs(currentTab) {
    return JSON.parse(sessionStorage.getItem(currentTab));
    //return this.myMap.get(currentTab);
  }

  getStoredArrColDefs(currentTab) {
    //return JSON.parse(sessionStorage.getItem(currentTab));
    return this.arrayColDef[currentTab];
    //return this.myMap.get(currentTab);
  }

  setOpenConvData(convGridData) {
    this.convGridData.next(convGridData);
  }

  getOpenConvData(): Observable<any> {
    return this.convGridData.asObservable();
  }

  setPrevConv(convRecord) {
    this.arrPrevConv = convRecord;
  }

  getPrevConv() {
    return this.arrPrevConv;
  }

  /**
   * Pass data to inbox componenet on conversation change trigger
   */

  setChangeConv(currentConvData) {
    this.currentConv.next(currentConvData);
  }

  getChangeConv(): Observable<any> {
    return this.currentConv.asObservable();
  }

  /**
   * subscribe function to draw conversation grid
   */
  setConvRow(convRow){
    this.convRowDraw.next(convRow);
  }
  
  getConvRow(){
    return this.convRowDraw.asObservable();
  }

  setCloseConvData(convGridData) {
    this.closeConvGridData.next(convGridData);
  }

  getCloseConvData(): Observable<any> {
    return this.closeConvGridData.asObservable();
  }

  /**
   * C153176-4399: adjust horizontal extra space if any
   */
  adjustColumnWidths(tabName, totalWidth, gridColumnApi, horizontal) {
    let columnDefs = gridColumnApi.getColumnState();
    if (horizontal) {
      let c_width = 0;
      let c_count = 0;
      let has_annex = false;
      let origWidths: any = {};
      columnDefs.forEach(x => {
        if (x.hide || x.colId == "cheveron" || x.colId == "expander" ||  x.colId == "cheveron_1" || x.colId == "isSymphonyChatGridCol") {
          return;
        }
        if (x.colId == "followUp") {
          has_annex = true;
          return;
        }
        c_width += x.width;
        c_count++;
      });
      let delta = has_annex ? 205 : 5;
      if (c_width < (totalWidth - delta) && c_count > 0) {
        let i_width = Math.round(Math.max(0, (totalWidth - delta - c_width) / c_count));
        columnDefs.forEach(x => {
          if (x.hide || x.colId === "followUp" || x.colId === "cheveron" || x.colId === "expander" ||  x.colId === "cheveron_1" || x.colId == "isSymphonyChatGridCol") {
            return;
          }
          origWidths[x.colId] = x.width;
          x.width += i_width;
        });
        gridColumnApi.applyColumnState({
          state:columnDefs,
          applyOrder: true,
        });
        this.cachedWidths[tabName] = origWidths;
      }
    } else {
      let origWidths = this.cachedWidths[tabName];
      if (origWidths) {
        // vertical case - restore original width
        columnDefs.forEach(x => {
          if (x.hide || x.colId === "followUp" || x.colId === "cheveron" || x.colId === "expander" ||  x.colId === "cheveron_1" || x.colId == "isSymphonyChatGridCol") {
            return;
          }
          if (origWidths[x.colId]) {
            x.width = origWidths[x.colId];
          }
        });
        gridColumnApi.applyColumnState({
          state:columnDefs,
          applyOrder: true,
        });

        // clear cached width
        delete this.cachedWidths[tabName];
        this.cachedWidths[tabName] = undefined;
      } else {
        // C153176-5191: adjust vertical grid widths per current width, in particular, adjust FROM and SUBJECT if they are present
        let c_width = 0;
        let c_count = 0;
        let has_annex = false;
        let special_column_count = 0;
        columnDefs.forEach(x => {
          if (x.hide || x.colId == "cheveron" || x.colId == "expander" ||  x.colId == "cheveron_1" || x.colId == "isSymphonyChatGridCol") {
            return;
          }
          if (x.colId == "followUp") {
            has_annex = true;
            return;
          }
          if (x.colId === 'latestUserName' || x.colId === 'subject') {
            special_column_count++;
          }
          c_width += x.width;
          c_count++;
        });
        let delta = has_annex ? 265 : 5;
        let gridWidth = totalWidth;
        if (c_width < (gridWidth - delta)) {
          if (special_column_count > 0) {
            let s_width = Math.round(Math.max(0, (gridWidth - delta - c_width) / (2 * special_column_count)));
            let i_width = Math.round(Math.max(0, (gridWidth - delta - c_width) / (Math.max(1, c_count - special_column_count))));
            columnDefs.forEach(x => {
              if (x.hide || x.colId == "cheveron" || x.colId == "expander" ||  x.colId == "cheveron_1" || x.colId == "isSymphonyChatGridCol") {
                return;
              }
              if (x.colId !== "latestUserName" && x.colId !== "subject") {
                x.width += i_width;
              } else {
                x.width += s_width;
                if (x.width < 150) {
                  x.width = 150;
                } else if (x.width < 250) {
                  x.width = 250;
                }
              }
            });
          } else {
            let i_width = Math.round(Math.max(0, (totalWidth - delta - c_width) / c_count));
            columnDefs.forEach(x => {
              if (x.hide || x.colId === "followUp" || x.colId === "cheveron" || x.colId === "expander" ||  x.colId === "cheveron_1" || x.colId == "isSymphonyChatGridCol") {
                return;
              }
              x.width += i_width;
            });
          }
          gridColumnApi.applyColumnState({
            state:columnDefs,
            applyOrder: true,
          });
        } else {
          // C153176-5191: check FROM and SUBJECT only
          let updated = false;
          columnDefs.forEach(x => {
            if (!x.hide && (x.colId === "latestUserName" || x.colId === "subject")) {
              if (x.width < 150) {
                x.width = 150;
                updated = true;
              }
              // DO NOT check x.width > max threshold since it was augmented
            }
          });
          if (updated) {
            gridColumnApi.applyColumnState({
              state:columnDefs,
              applyOrder: true,
            });
          }
        }
      }
    }
  }
}

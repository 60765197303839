import { Component, OnInit, Input, SimpleChanges, OnChanges, Output, EventEmitter } from '@angular/core';
import { OverlayPanel } from "primeng//overlaypanel";
import { QmaConstant } from "src/app/constant/qma-constant";
import { DomSanitizer } from "@angular/platform-browser";
import { InboxService } from "src/app/services/inbox.service";
import { UserDataService } from "src/app/services/user-data.service";
import { AppUtils } from "src/app/common/utility/appUtil";
import { DateFormatPipe } from '../../common/pipes/date-format.pipe';
import { SaveDraft, ScheduleModel } from '../../model/NewMessage/SaveDraft';
import { NewMessageService } from '../../services/newMessage/new-message.service';
import { PiwikProUtils } from 'src/app/common/utility/piwikProUtils';

interface ConversationOption {
  name: string,
  code: string
}

interface attachmentOption {
  name: string,
  id: string,
  fileInfo: any,
  imageName: string // C153176-4708	display toaster message on click 

}

interface Recipient {
  displayName: string,
  emailAddr: string,
  groupId: number,
  toFrom: string,
  userId: string,
  isExternal: boolean
}

@Component({
  selector: 'app-draft-detail-view',
  templateUrl: './draft-detail-view.component.html',
  styleUrls: ['./draft-detail-view.component.scss']
})
export class DraftDetailViewComponent implements OnInit, OnChanges {
  dateFormatPipe: DateFormatPipe;
  @Input() selectedMail: any;
  @Input() inboxViewMode = 'vertical';

  // Emitter for Attachments, Notes and Audits
  @Output() attachmentEvent = new EventEmitter();
  @Output() notesEvent = new EventEmitter();
  @Output() backClick = new EventEmitter();

  draft: any = {};
  conversation: any = {};
  totalConversations: number = 1;
  isExpanded: boolean = false;

  attachments: any[] = [];// Attachments
  scheduleForLater: string;
  options: ConversationOption[];
  attachmentsOptions: attachmentOption[] = [];

  recipientShow: boolean = false;

  isAttachemntPanelCollapsed = true;
  isSelectAllAttchClicked = false;
  extensionImgIconMapping :any = [
    { extension:'xlsx', imgUrl:'assets/Inbox/icon-excel.svg'},
    { extension:'xls', imgUrl:'assets/Inbox/icon-excel.svg'},
    {extension:'svg', imgUrl:'assets/Inbox/icon-img.svg'},
    {extension:'pdf', imgUrl:'assets/Inbox/icon-pdf.svg'},
    {extension:'pptx', imgUrl:'assets/Inbox/icon-power-point.svg'},
    {extension:'ppt', imgUrl:'assets/Inbox/icon-power-point.svg'},
    {extension:'svg', imgUrl:'assets/Inbox/icon-txt.svg'},
    {extension:'docx', imgUrl:'assets/Inbox/icon-word.svg'},
    {extension:'doc', imgUrl:'assets/Inbox/icon-word.svg'},
    {extension:'zip', imgUrl:'assets/Inbox/icon-zip.svg'},
    {extension:'unkown', imgUrl:'assets/Inbox/icon_unknown_file.svg'},
    {extension:'eml', imgUrl:'assets/Inbox/icon-email.svg'},
    {extension:'html', imgUrl:'assets/Inbox/icon-html.svg'},

    {extension:'gif', imgUrl:'assets/Inbox/GIF_icon.svg'},
    {extension:'jpg', imgUrl:'assets/Inbox/JPG_icon.svg'},
    {extension:'jpeg', imgUrl:'assets/Inbox/JPG_icon.svg'},
    {extension:'png', imgUrl:'assets/Inbox/PNG_icon.svg'},
    {extension:'jfif', imgUrl:'assets/Inbox/GIF_icon.svg'},
    {extension:'tiff', imgUrl:'assets/Inbox/TIFF_icon.svg'},
    {extension:'txt', imgUrl:'assets/Inbox/icon_text_attachment.svg'},
   
 ];
  constructor(private inboxService: InboxService, private sanatized: DomSanitizer, private userDataService: UserDataService,
    private newMessageService: NewMessageService) {
    this.options = [
      { name: 'Print', code: QmaConstant.PRINT },
      { name: 'Download As PDF', code: QmaConstant.PDF },
      { name: 'Download As Email', code: QmaConstant.EMAIL }
    ];
    this.dateFormatPipe = new DateFormatPipe();
  }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges): void {
    this.attachments = [];

    if (this.selectedMail) {
      this.getConversationDraftView(this.selectedMail);
    }
  }

  sanetize(value: any): any {
    return this.sanatized.bypassSecurityTrustHtml(value);
  }

  /**
   * Method to populate the Conversation Draft View.
   * @param selectedMail - Selected Drafted Inquiry
   */
  getConversationDraftView(selectedMail: any): void {
    this.draft = selectedMail.draft;
    
    this.scheduleForLater = this.dateFormatPipe.transform(selectedMail.draft.scheduleForLater,'dd MMM hh:mm a');
    //this.scheduleForLater = this.dateFormatPipe.transform('2016-09-13T23:30:52.123Z','dd/MMM/yy h:mm:ss tt');
    //2016-09-13T23:30:52.123Z
    this.conversation = this.getConversationRecipients(selectedMail.draft);

    // Pass attachments to accumulate all the attachments
    this.getAttachmentList(selectedMail.draft);
    this.notesEvent.emit(this.draft.userNotes);
  }

  cancelSchedule(){
      let urgentFlag = (this.isNull(this.draft.urgentFlag)) ? '' : this.draft.urgentFlag;
      let inquirySource = (this.isNull(this.draft.inquirySource)) ? '' : this.draft.inquirySource;
      let rootCause = (this.isNull(this.draft.rootCause)) ? '' : this.draft.rootCause;
      let processingRegion = (this.isNull(this.draft.processingRegion)) ? '' : this.draft.processingRegion;
      let tags = (this.isNull(this.draft.tags)) ? '' : this.draft.tags;
      let queryCount = (this.isNull(this.draft.queryCount)) ? '' : this.draft.queryCount;
      let gfpId = (this.isNull(this.draft.gfpId)) ? '' : this.draft.gfpId;
      let gfpName = (this.isNull(this.draft.gfpName)) ? '' : this.draft.gfpName;
      let gfcId = (this.isNull(this.draft.gfcId)) ? '' : this.draft.gfcId;
      let gfcName = (this.isNull(this.draft.gfcName)) ? '' : this.draft.gfcName;
      let subject = (this.isNull(this.draft.subject)) ? '' : this.draft.subject;
      let requestType = (this.isNull(this.draft.requestType)) ? '' : this.draft.requestType;
      let draftId = this.selectedMail.draft.id
      let attachments = (this.isNull(this.draft.attachments)) ? [] : this.draft.attachments;

      let recipients = this.draft.recipients;
      let from = '';
      let toUsersList = [];
      let ccUsersList = [];
      let bccUsersList = [];
      for (let rec of recipients) {
        if (rec.toFrom === "TO")
          toUsersList.push({ text: rec.displayName, value: rec.emailAddr, email: rec.emailAddr, id: rec.groupId, country: '', timeZone: '', active: true });
        if (rec.toFrom === "CC")
          ccUsersList.push({ text: rec.displayName, value: rec.emailAddr, email: rec.emailAddr, id: rec.groupId, country: '', timeZone: '', active: true });
        if (rec.toFrom === "BCC")
          bccUsersList.push({ text: rec.displayName, value: rec.emailAddr, email: rec.emailAddr, id: rec.groupId, country: '', timeZone: '', active: true });
        if (rec.toFrom === "FROM")
          from = rec.displayName;
      }
      let actionStr = this.draft.action;
      if(actionStr && actionStr.includes('Scheduled For Later')) {
        actionStr = actionStr.replace('Scheduled For Later', '').substr(3);
        console.log('actionStr = ' + actionStr);
      }
      
      // On click of cancel we need to pass schedule for later with scheduleForLater as null
      const scheduleForLater = null;
      const cancelled: ScheduleModel = new ScheduleModel(draftId, scheduleForLater, null, toUsersList, ccUsersList, from, urgentFlag, subject,
        requestType, attachments, actionStr, false, this.draft.approvalBypassed, AppUtils.getToken(), false, "Times New Roman", "14pt",
        inquirySource, rootCause, processingRegion, tags, queryCount, gfpId, gfcId, gfpName, gfcName, bccUsersList, this.draft.userNotes, true);

      this.newMessageService.saveScheduleForLater(cancelled, this.draft.content).subscribe(result => {
        console.log('Scheduled Message is cancelled', result);
        this.scheduleForLater = null;
      },
        (error) => {
          console.log('error while saving draft:', error);
        });
  }
  
  /**
   * Method to get recipients (FROM, TO, CC) description and return to render in html
   *      * 
   * @param type - It might FROM, TO and CC
   * @param isRecent - It is recent Conversation
   */
  getConversationRecipients(conversation: any): any {
    let toList: string[] = [];
    let ccList: string[] = [];
    let bccList: string[] = [];
    let from = '';

    // Recipients with external flag
    let fromRecipient: Recipient; 
    let toRecipients: Recipient[] = [];
    let ccRecipients: Recipient[] = [];
    let bccRecipients: Recipient[] = [];

    let conversationCopy = { ...conversation };
    conversation.recipients.forEach(recipient => {
      if (recipient.toFrom === QmaConstant.FROM) {
        from = recipient.displayName;
        fromRecipient = this.getModifiedRecipient(recipient);
      }
      else if (recipient.toFrom === QmaConstant.TO) {
        toList.push(recipient.displayName);
        toRecipients.push(this.getModifiedRecipient(recipient));
      }
      else if (recipient.toFrom === QmaConstant.CC) {
        ccList.push(recipient.displayName);
        ccRecipients.push(this.getModifiedRecipient(recipient));
      }
      else if (recipient.toFrom === QmaConstant.BCC) {
        bccList.push(recipient.displayName);
        bccRecipients.push(this.getModifiedRecipient(recipient));
      }
    });

    conversationCopy.from = from;
    conversationCopy.to = toList.join(", ");
    conversationCopy.cc = ccList.join(", ");
    conversationCopy.bcc = bccList.join(", ");

    // Recipients List
    conversationCopy.toRecipients = toRecipients;
    conversationCopy.ccRecipients = ccRecipients;
    conversationCopy.bccRecipients = bccRecipients;
    conversationCopy.fromRecipient = fromRecipient;

    // C153167-4660 : Add blank to show mail body if content is blank.
    const content = conversation.content
    conversationCopy.content = this.isNull(content) ? "<p>&nbsp;</p>" : content;
    
    // Add properties for attachment
    conversationCopy.attachmentCount = conversationCopy.attachments ? conversationCopy.attachments.length : 0;
    return conversationCopy;
  }

  /**
   * Method to get list of attachment of each conversation and populate the attachment list
   */
  getAttachmentList(conversation: any): void {
    if (conversation.attachments) {
      let attachmentList = conversation.attachments;

      let inquiryId = conversation.inquiryId;
      let convId = conversation.id;

      attachmentList.forEach(attachment => {
        let attmnt = attachment;
        attmnt.time = conversation.modDate;
        attmnt.inquiryId = inquiryId;
        attmnt.convId = convId;
      });
      Array.prototype.push.apply(this.attachments, attachmentList);

      // Pass attachment list to parent
      this.attachmentEvent.emit(this.attachments);
    }
    if (this.attachments && this.attachments.length > 0) {
      this.attachments.forEach((att: any) => {
      att.showTitle = true;
      att.showMenu = false;
      });
  }
  }

  /**
   * Method to change the view of message to expanded or collapsed.
   */
  changeMessageView(): void {
    this.isExpanded = !this.isExpanded;
  }

  /**
   * Method to show option for conversation
   * 
   * @param event - Operation
   * @param overlaypanel  - Option panel
   */
  showOptions(event, overlaypanel: OverlayPanel): void {
    overlaypanel.toggle(event);
  }

  /**
   * Method to handle the type of request made on options
   * 
   * @param event - Selected option
   * @param overlaypanel - Option panel
   */
  onAction(event, overlaypanel: OverlayPanel): void {
    const type = this.options.find(option => option.name === event.target.innerText);

    if (QmaConstant.EMAIL === type.code) {
      this.inboxService.downloadAsEmail(this.conversation.id, this.draft.subject, null, false,true);
    }
    overlaypanel.hide();
  }

  /**
    * Method to handle to download the attachment name.
    * 
    * @param event - Selected option
    * @param overlaypanel - Option panel
    */
  onDownloadAction(event, overlaypanel: OverlayPanel): void {
    const selectedAttachment = this.attachmentsOptions.find(option => option.name === event.target.title);
    overlaypanel.hide();

    this.onDownloadAttachment(selectedAttachment.fileInfo);
  }

  /**
     * Method to download attachments attached to conversation
     * 
     * @param attachments - Conversation Attachment list
     */
  onDownloadAttachment(attachments: any): void {
    if (Array.isArray(attachments)) {
      if (attachments.length === 1) {
        console.log('Only one attachment : ', attachments[0].name);
        this.inboxService.downloadAttachment(attachments[0].id, attachments[0].name, attachments[0].convId, attachments[0].groupId, attachments[0].hasNominatedOwnership,true);
        PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Mailboxes ", "Download attachment - conversation", "Click", "Download attachment - conversation", 0);
      }
      else if (attachments.length > 1) {
        console.log('More than one attachment : ', attachments.length);
        const inquiryId = attachments[0].inquiryId;
        const convId = attachments[0].convId;
        this.inboxService.downloadAllAttachments(inquiryId, convId);
        PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Mailboxes ", "Download all attachments - conversation", "Click", "Download all attachments - conversation", 0);
      }
    }
    else {
      console.log('Only one attachment from option: ', attachments.name);
      this.inboxService.downloadAttachment(attachments.id, attachments.name, attachments.convId, attachments.groupId, attachments.hasNominatedOwnership,true);
      PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Mailboxes ", "Download attachment - conversation", "Click", "Download attachment - conversation", 0);
    }
  }

  /**
     * Method to show the list of attachments available to conversation
     * 
     * @param event - Operation
     * @param overlaypanel - Overlay Panel
     * @param attachmentList - Conversation Attachments list
     */
  showAttachments(event, overlaypanel: OverlayPanel, attachmentList: any): void {
    this.attachmentsOptions = [];
    attachmentList.forEach(attachment => {
      let attachInfo: attachmentOption = {
        name: attachment.name,
        id: attachment.id,
        fileInfo: attachment,
        imageName: "copy_icon.svg"// C153176-4708	display toaster message on click 
      };
      this.attachmentsOptions.push(attachInfo);
    });
    if (overlaypanel) {
      overlaypanel.toggle(event);
    }
    PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Mailboxes ", "Show list of attachments - conversation", "Click", "Show list of attachments - conversation", 0);
  }

  /**
 * Method to determine Internal or External. Add flag to recipient object.
 */
  getModifiedRecipient(recipient: any): Recipient {
    let emailId = recipient.emailAddr === null ? recipient.displayName : recipient.emailAddr;
    if (emailId.indexOf('<') > 0) {
      let id = emailId.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi);
      emailId = id ? id[0] : '';
    }
    let isExternal: boolean = this.isExternalEmailInToCc(emailId);
    isExternal = recipient.groupId ? false : isExternal;
    let rec: Recipient = {
      displayName: recipient.displayName,
      emailAddr: recipient.emailAddr,
      groupId: recipient.groupId,
      toFrom: recipient.toFrom,
      userId: recipient.userId,
      isExternal: isExternal
    };
    return rec;
  }

  /**
     * Method to check if the entered email is an external.
     * 
     * @param email - Entered Email Id
     */
  isExternalEmailInToCc(email: any): boolean {
    if (!AppUtils.isUndefinedOrNull(email)) {
      if (email && !AppUtils.isCitiDomainEmail(email, this.userDataService.loggedInUserInfo.citiDomainList))
        return true;
    }
      return false;
  }
  // Null value check function
  isNull(input) {
    if (input === "" || input === undefined || input == null) {
      return true;
    }
    return false;
  }

  /**
     * Method to hide and show recipients
     */
    onManageRecipent(): void {
      this.recipientShow = !this.recipientShow;
  }
  /* C153176-4795 Mobile changes */
  onBackClick(evt:any){
    this.backClick.emit();
  }
  onMouseEnterAttTitle(attachment) {
    this.attachments.forEach((att) => {
        if (attachment.id === att.id) {
            attachment.showMenu = true;
            attachment.showTitle = false;
        } else {
            att.showMenu = false;
            att.showTitle = true;
        }
});
}
onMouseLeaveAttTitle(attachment) {
    this.attachments.forEach((att) => {
        if (attachment.id === att.id) {
            attachment.showMenu = false;
            attachment.showTitle = true;
        } else {
            att.showMenu = false;
            att.showTitle = true;
        }
});
}
onMouseEnterAttMenu(attachment) {
    this.attachments.forEach((att) => {
        if (attachment.id === att.id) {
            att.showMenu = true;
            att.showTitle = false;
        } else {
            att.showMenu = false;
            att.showTitle = true;
        }
});
}
onMouseLeaveAttMenu(attachment) {
    this.attachments.forEach((att) => {
            att.showMenu = false;
            att.showTitle = true;
    });
}
downloadAttachement(attachement) {
    //let event = {};
    

}
onAttachementMoreClick() {
    this.isAttachemntPanelCollapsed = !this.isAttachemntPanelCollapsed;
}
getAttachementImageByExtension(attachement) {
    let extension = attachement && attachement.name ? attachement.name.split('.').pop():'unkown';
    let configObj = this.extensionImgIconMapping.find(i =>i.extension === extension.toLowerCase());
    const imgUrl = configObj && configObj.imgUrl ? configObj.imgUrl : "assets/Inbox/icon_unknown_file.svg";
    return imgUrl;
}
onCopyActionRedesigned(attachement) {
let event = {target:{name:'copy',title:attachement.name}};
this.onDownloadAction(event,null);
}

onViewctionRedesigned(attachement) {
    let event = {target:{name:'view',title:attachement.name}};
    this.onDownloadAction(event,null);
    }
getAttachementCopyIconRedesigned(attachement) {
   
    return "assets/Inbox/" + this.attachmentsOptions.find(ao => ao.id === attachement.id).imageName;
}

}

import { Component, OnInit, AfterViewInit } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { PersonalEmailService } from './personal-email.service';
import { TabDataService } from '../services/tab-data.service';
import { InboxService } from '../services/inbox.service';
import { UserDataService } from '../services/user-data.service';
import { QmaConstant } from '../constant/qma-constant';
import  * as _ from 'underscore';

@Component({
  selector: 'app-personal-email',
  templateUrl: './personal-email.component.html',
  styleUrls: ['./personal-email.component.scss']
})
export class PersonalEmailComponent implements OnInit, AfterViewInit {
 
  testData: TreeNode[];
  exchangeData: any;
  selectedItem: TreeNode;

  constructor(private personalEmailService: PersonalEmailService, private tabDataService: TabDataService,
    private inboxService: InboxService, private userDataService: UserDataService) { }

  ngOnInit() {
    this.personalEmailService.getFiles('assets/data-json/tree-data.json', 'data').then(files => {
      this.testData = files;
      console.log('tree data', this.testData);
    });

    let exchangeData = this.userDataService.getExchangeFolderDetails();
    console.log('exchange data : ', exchangeData);
    this.transformExchangeData(exchangeData);
  }

  ngAfterViewInit(): void {
    let selectedFolderId = this.personalEmailService.getSelectedFolder();
    if (selectedFolderId) {
      let item = this.exchangeData.filter(item => item.folderId == selectedFolderId);
      this.selectedItem = item[0];
    }
  }

  isParentFolderAvailable(item: any, exchangeData): boolean {
    let flag = false;
    let parentFolderId = item['parentFolderId'];
    exchangeData.forEach(exchangeItem => {
      if (exchangeItem['folderId'] == parentFolderId) {
        flag = true;
        return;
      }
    });
    return flag;
  }

  transformExchangeData(exchangeData) {
    let transformedData = [];
    exchangeData.forEach(item => {
      if (!this.isParentFolderAvailable(item, exchangeData)) {
        let treeItem = this.createTreeItem(item);
        if (this.isChildFolderAvailable(item)) {
          let childFolder = this.getChildFolderItem(item, exchangeData);
          treeItem['children'] = [...childFolder];
        }
        transformedData.push(treeItem);
      }

    });

    // Maintain the order of default folders at the starting.
    const personalMailFolders = [...transformedData];
    let defaultFolders = personalMailFolders.filter(folder => folder.default);
    let customFolders = personalMailFolders.filter(folder => !folder.default);

    // Sort the default personal mail box folders as per predefined order.
    defaultFolders = _.sortBy(defaultFolders, function (i) { return i.odr; });
    this.exchangeData = [...defaultFolders, ...customFolders];
  }

  createTreeItem(item) {
    let treeItem = {
      "folderId": item['folderId'],
      "label": item['displayName'],
      "parentFolderId": item['parentFolderId'],
      "totalCount": item['totalCount'],
      "data": "Documents Folder",
      "expandedIcon": "pi pi-folder-open",
      "collapsedIcon": "pi pi-folder",
      "default": QmaConstant.DEFAULT_PERSONAL_MAIL_FOLDERS.includes((item['displayName']).toUpperCase()),
      "odr": QmaConstant.DEFAULT_PERSONAL_MAIL_FOLDERS.indexOf((item['displayName']).toUpperCase())
    }
    return treeItem;
  }

  getChildFolderItem(item: any, exchangeData) {
    let folderId = item['folderId'];
    let childFolder = [];
    exchangeData.forEach(element => {
      if (element['parentFolderId'] == folderId) {
        let treeItem = this.createTreeItem(element);
        childFolder.push(treeItem);
      }
    });
    return childFolder;
  }

  isChildFolderAvailable(item: any): boolean {
    if (item['childFolderCount'] > 0) {
      return true;
    }
    return false;
  }

  nodeSelect(event) {
    const selectedFolder = event.node;
    this.personalEmailService.setSelectedFolder(selectedFolder.folderId);

    let requestObj: any = {};
    requestObj.isPersonal = "Y";
    requestObj.viewName = "Inbox";
    requestObj.viewType = 0;
    requestObj.personalFolderName = selectedFolder.label;
    requestObj.personalFolderId = selectedFolder.folderId;
    requestObj.pageNum = 1;
    requestObj.pageSize = 100;

    this.inboxService.requestObj = requestObj;
    this.inboxService.resetRequest = true; // If your click this node again and again fresh request will trigger.
    this.tabDataService.sendTabData(QmaConstant.QMA_PERSONAL, 0, 'leftNav');
  }
}

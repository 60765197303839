import { Injectable } from '@angular/core';
import { HttpUtilService } from './http-util.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpClientService extends HttpUtilService {
  
    constructor(protected http: HttpClient) {
      super(http);
    }
  
    delete(url: string, options: Object = {}) {
      return this.methodBuilder('Delete', url, options);
    }
  
    
    get<T>(url: string, options: Object = {}): Observable<T> {
      return this.methodBuilder<T>('Get', url, {}, options);
    }
  
    
    head(url: string, options: Object = {}) {
      return this.methodBuilder('Head', url, options);
    }
  
    
    options(url: string, options: Object = {}) {
      return this.methodBuilder('Options', url, options);
    }
  
    
    patch<T>(url: string, body: any, options: Object = {}): Observable<T> {
      return this.methodBuilder('Patch', url, body, options);
    }
  
    
    post<T>(url: string, body: any, options: Object = {}): Observable<T> {
      return this.methodBuilder('Post', url, body, options);
    }
  
    
    put<T>(url: string, body: any, options: Object = {}): Observable<T> {
      return this.methodBuilder('Put', url, body, options);
    }
  
  }

<div class="container attachment-section" *ngIf="attachmentCount > 0">
  <div class="d-flex down-icon-section">
    <div class="p-1 mr-auto align-self-end attach-download-link" (click)="onDownloadAllAttachment()"> Download All </div>
    <div class="p-1 attach-download">
      <img [src]="'assets/Inbox/download_icon_artifact.svg'" alt="" (click)="onDownloadAllAttachment()">
    </div>
  </div>
</div>
<div class="container attachment-list" *ngIf="attachmentCount > 0">
  <div class="d-flex flex-column">
    <ng-template ngFor let-attachment [ngForOf]="attachmentList">
      <div class="d-flex mb-4">
        <div class="d-flex p-1 pt-2 attach-doc">
          <img [src]="'assets/Inbox/notes.svg'" alt="">
        </div>
        <div class="d-flex flex-column align-content-start mr-auto">
          <div class="p-1 mr-auto attach-name" title="{{attachment.name}}"> {{ (attachment.name.length>14) ? (attachment.name | slice:0:14)+'...':(attachment.name) }} </div>
          <div class="pl-1 mr-auto attach-time">{{attachment.time | date:'dd MMM hh:mm a'}}</div>
        </div>
        <div class="d-flex p-1 pt-2 attach-download">
          <img [src]="'assets/Inbox/download_icon_artifact.svg'" alt="" (click)="onDownloadAttachment(attachment)">
        </div>
        <div class="d-flex p-1 pt-2 attach-download">
          <img src='assets/Inbox/{{attachment.imageName}}' alt="" name="copy" (click)="onCopyAttachment(attachment)" title="{{attachment?.copiedMsg}}">
        </div>
      </div>
    </ng-template>
  </div>
</div>
import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'schedule-message',
  templateUrl: './schedule-message.component.html',
  styleUrls: ['./schedule-message.component.scss']
})
export class ScheduleMessageComponent {

  @Output() timeEvent = new EventEmitter<Date>();
  displaySchedule: boolean;
  public scheduleTime: Date;
  private currentDate: Date;
  public validationMsg: string;

  onScheduleSubmit($event) {
    this.currentDate = new Date();
    if(this.scheduleTime < this.currentDate){
      // this.timeEvent.emit("Invalid date selected");
      this.validationMsg="Please select valid date and time";
    } else {
      this.displaySchedule = false;
      this.timeEvent.emit(this.scheduleTime);
    }
  }

  cancelSchedule($event) {
    this.displaySchedule = false;
  }
}
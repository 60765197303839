<div *ngIf="selectedMail && !loadingMailInProgress" [attr.data-view-id]="myTabName">
    <app-re-age #reAge></app-re-age>
    <div *ngIf="!isMobile" class="d-flex flex-row justify-content-between align-items-center conv-header flex-unwrap">
        <!-- Jira: Mobile : C153176-4476 - Conversation View -->
        <div class="conv-subject">
            <div class="conv-subject-ellipse" title="{{inquirySubject}}">{{inquirySubject}}</div>
            <!-- C153176-5191: enable ellipsis, tooltip-->
        </div>
        <div class="d-flex align-content-end conv-info">
            <div class="d-flex justify-content-end ml-auto">
                <div class="mr-4 conv-container" *ngIf="isResolveApplicable" (click)="markResolved()"> <!--C170665-325 | Revert Changes-->
                    <!-- Jira: C153176-5064 - Add Resolve Button -->
                    <img [src]="'assets/Inbox/resolve-symphony.svg'" alt="Resolve" pTooltip="Resolve" tooltipPosition="left" tooltipStyleClass='leftTooltip p-tooltip-text'
                        style="cursor: pointer; width: 24px;">
                </div>

                <div class="mr-4 conv-container" *ngIf="inquiryIcon">
                    <!-- Jira: Mobile : C153176-4476 - Conversation View -->
                    <img [src]="'assets/Inbox/outlook-symphony.svg'" alt="NLP" style="cursor: pointer; width: 1.94em">
                </div>

                <div class="mr-4 conv-container">
                    <!-- Jira - 4578 - Tooltip has been added -->
                    <img [src]="'assets/Inbox/new-chat-icon-symphony.svg'" alt="Conversation Count" pTooltip="Conversation Count" tooltipPosition="left"
                        tooltipStyleClass='leftTooltip p-tooltip-text'>
                    <span class="centered" style="color: #fff;">{{inquiryReportedConvCount}}</span>
                </div>
                <div class="mr-4 conv-container" (click)="onReAge()">
                    <img [src]="'assets/context/re-age.svg'"
                    style="cursor: pointer; width: 25px;" pTooltip="Re-Age" alt="Re-Age" tooltipPosition="left" tooltipStyleClass='leftTooltip p-tooltip-text'>
                </div>
                <div class="mr-4 coll-expd">
                    <!-- Jira - 4578 - Tooltip has been added -->
                    <img [src]="'assets/Inbox/expand_all_icon_u110_symphony.svg'" *ngIf="!isExpanded" (click)="changeMessageView()" alt="Expand"
                        pTooltip="Expand all conversation" tooltipPosition="left" tooltipStyleClass='leftTooltip p-tooltip-text'>
                    <img [src]="'assets/Inbox/collapse_all_icon_u111-symphony.svg'" *ngIf="isExpanded" (click)="changeMessageView()" alt="Collapse"
                        pTooltip="Collapse all conversation" tooltipPosition="left" tooltipStyleClass='leftTooltip p-tooltip-text'>
                </div>
                <div class="mr-2 header-option ellipsis-icon">
                    <!-- Jira - 4578 - Tooltip has been added -->
                    <img [src]="'assets/Inbox/ellipsis_icon_u91_symphony.svg'" (click)="showOptions($event,conversationOptions)" alt="" pTooltip="Print, Download as PDF and download as Email"
                        tooltipPosition="left" tooltipStyleClass='leftTooltip ui-tooltip-text' *ngIf="ismailboxGridAndDetailviewinSynch(conversationList[0]?.inquiryId)">
                        <img [src]="'assets/Inbox/ellipsis_icon_u91_symphony.svg'" alt="" pTooltip="Inquiry selected in grid not matching with detail view please change selection and try again"
                        tooltipPosition="left" tooltipStyleClass='leftTooltip ui-tooltip-text' *ngIf="!ismailboxGridAndDetailviewinSynch(conversationList[0]?.inquiryId)">
                </div>
            </div>
        </div>
    </div>
    <!-- Jira: Mobile : C153176-4476 - Conversation View -->
    <div *ngIf="isMobile" class="d-flex flex-row justify-content-between align-items-start conv-header flex-unwrap mob-subject-wrapper">
        <div (click)="onBackClick($event)" class="mob-img">
            <img class="backward-icon" src="assets/Inbox/right_chevron_icon.svg" alt="">
        </div>
        <!-- Jira : C153176-4601 : Mobile: Reply /Reply All -->
        <div *ngIf="!visibleQuickReply" class="mob-subject" [ngClass]="{'mob-conv-subject-reply': visibleQuickReply}">
            <div class="mob-subject-long conv-subject-ellipse" title="{{inquiry?.subject}}">{{inquiry?.subject}}</div>
            <!-- C153176-5191: enable tooltip-->
        </div>
        <div *ngIf="visibleQuickReply" class="conv-subject mob-conv-subject-reply">
            <div class="conv-subject-ellipse" title="{{inquiry?.subject}}">{{inquiry?.subject}}</div>
            <!-- C153176-5195: Changed to subject from REPLY, C153176-5191: refactor css class between mobile and web, add tooltip -->
        </div>
        <div *ngIf="!visibleQuickReply" class="d-flex align-content-end conv-info mob-wt-30">
            <div class="d-flex justify-content-end ml-auto">
                <div class="mr-3 conv-container" *ngIf="isResolveApplicable" (click)="markResolved()">
                    <!-- Jira: C170665-1752 - Add Resolve Button for Mobile -->
                    <img [src]="'assets/Inbox/resolve-symphony.svg'" alt="Resolve" pTooltip="Resolve" tooltipPosition="left" tooltipStyleClass='leftTooltip p-tooltip-text'
                        style="cursor: pointer; width: 24px;">
                </div>
                <div class="mr-3 conv-container">
                    <!-- Jira - 4578 - Tooltip has been added -->
                    <img [src]="'assets/Inbox/new-chat-icon.svg'" alt="Conversation Count" pTooltip="Conversation Count" tooltipPosition="left"
                        tooltipStyleClass='leftTooltip p-tooltip-text'>
                    <span class="centered" style="color:#fff;">{{inquiryReportedConvCount}}</span>
                </div>
                <div class="mr-2 coll-expd">
                    <!-- Jira - 4578 - Tooltip has been added -->
                    <!-- Jira C170665-1963 fix expand button issue -->
                    <img [src]="'assets/Inbox/expand_all_icon_u110_symphony.svg'" *ngIf="!isExpanded" (click)="changeMessageView()" alt="Expand" pTooltip="Expand all conversation"
                    tooltipPosition="left" tooltipStyleClass='leftTooltip p-tooltip-text'>
                    <img [src]="'assets/Inbox/collapse_all_icon_u111-symphony.svg'" *ngIf="isExpanded" (click)="changeMessageView()" alt="Collapse" pTooltip="Collapse all conversation"
                    tooltipPosition="left" tooltipStyleClass='leftTooltip p-tooltip-text'>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="displayNominationConfirmBox" class="nominate-ownership-confirm d-flex flex-column">
        <div class="nominate-ownership-confirm-message d-flex flex-row">
            <div class="pr-1"><img src="assets/common/ownership.svg" alt=""></div>
            <div class="d-flex flex-column w-100">
                <div class="nominate-ownership-header">
                    OWNERSHIP NOMINATION
                </div>
                <div class="d-flex flex-row w-100">
                    <div class="d-flex flex-column nominate-reason-text">
                        <div class="notification-sub-header">Reason</div>
                        <div class="notification-text">{{nominationData?.reason}}</div>
                    </div>
                    <div class="d-flex flex-column px-2 nominate-col">
                        <div class="notification-sub-header">Group from</div>
                        <div class="notification-text ellipsis" title="{{nominationData?.groupFrom}}">{{nominationData?.groupFrom}}</div>
                        <!-- C153176-5011-->
                    </div>
                    <div class="d-flex flex-column px-2 nominate-col2">
                        <div class="notification-sub-header">User</div>
                        <div class="notification-text ellipsis">{{nominationData?.user}}</div>
                    </div>
                    <div class="d-flex flex-row btn-holder">
                        <!--C1531756-4897-Ownership allignment issue-->
                        <div (click)="onNominationAction('accept')" class="d-flex flex-column align-items-center px-2" style="margin-top: 3px;">
                            <div><img src="assets/common/check.svg" alt=""></div>
                            <div class="nominate-ownership-header nominate-owner">ACCEPT</div>
                        </div>
                        <div (click)="onNominationAction('reject')" class="d-flex flex-column align-items-center px-2">
                            <div><img src="assets/common/crossGrey.svg" alt=""></div>
                            <div class="nominate-ownership-header grey-text nominate-owner">REJECT</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="rejectReason" class="nomination-reject-holder d-flex flex-column">
            <div class="d-flex flex-column px-4 p-2">
                <div class="notification-sub-header">Rejection Reason</div>
                <div class="d-flex flex-row w-100 align-items-center nominate-holder">
                    <div>
                        <textarea cols="8" name="nominateOwner" [(ngModel)]="nominationRejectionReason" (ngModelChange)="OnEnterReason($event)" rows="4"></textarea>
                    </div>
                </div>
            </div>
            <div class="submit-container p-2 d-flex flex-end justify-content-end">
                <button type="submit" class="btn submit-btn mr-2" [disabled]="!hasRejectReason" (click)="onRejectionSubmit()">Submit</button>
            </div>
        </div>
    </div>
    <div class="d-flex flex-column conversation-container" [ngStyle]="styleObject()"
    [ngClass]="{'':inboxViewMode === 'vertical', 'horizontal-bar-height':inboxViewMode === 'horizontal'}">
        <div class="noPrint" *ngIf="!visibleQuickReply">
            <!-- C170665-51 | QMA Auto assignment : assign ownership without approval-->
            <div class="d-flex bdr-btm mb-1 auto-assignment-indicator" *ngIf="autoAssignmentAvailable">
                <div class="d-flex justify-content-center flex-grow-1">
                    <span class="imp mr-2">!</span>
                    <span class="auto-assigned-text">This email is Auto Assigned</span>
                </div>
                <div class="d-flex ml-auto">
                    <button class="reply-btn" pButton type="button" icon="pi pi-arrow-up" iconPos="right" (click)="openAutoAssign(conversationList[0].inquiryId)"
                        pTooltip="Ownership Reassignment" tooltipPosition="left" tooltipStyleClass='leftTooltip p-tooltip-text'>Re-Assign </button>
                </div>
            </div>
            <ng-template ngFor let-conversation [ngForOf]="conversationList" let-i="index">
                <!-- Expanded view of message -->
                <div class="detail-view-body-container" *ngIf="conversation.isExpandedView">
                    <div class="d-flex  bdr-btm imp-indicator" *ngIf="conversation.urgentFlag ==='Y'">
                        <div class="imp mr-4 ml-4">!</div>
                        <div class="imp-text">Message Sent with the high Importance</div>
                    </div>
                    <div *ngIf="!isMobile" class="d-flex pb-2" [ngClass]="{'external-recipient': conversation.fromRecipient.isExternal}">
                        <div class="profile-icon">
                            <app-image-placeholder [recipient]="conversation.fromRecipient"></app-image-placeholder>
                        </div>
                        <div class="mr-auto pt-2 break-Work" [ngClass]="{'pending-inq-width' : disableNLP}">
                            <div class="fs-12" [ngClass]="{'external': conversation.fromRecipient.isExternal}"
                            title="{{conversation.fromRecipient.emailAddr}}">
                                {{conversation.fromRecipient.displayName}}
                            </div>
                            <div class="fs-10-tocc">To:
                                <ng-template ngFor let-toRecipients [ngForOf]="conversation.toRecipients" let-idx="index">
                                    <span [ngClass]="{'external': toRecipients.isExternal}" (click)="displayContactInfo($event, opContactInfo, toRecipients)"
                                    title="{{toRecipients.emailAddr}}">{{toRecipients.displayName}}</span>
                                    <span *ngIf="idx != conversation.toRecipients.length-1">; </span>
                                </ng-template>
                                <p-overlayPanel #opContactInfo [appendTo]="'body'" [dismissable]="true">
                                    <gdir-contact-details *ngIf="selectedContactId?.length>0" [soeId]="selectedContactId"></gdir-contact-details>
                                </p-overlayPanel>
                                <span class="ml-1"> </span>
                                <span class="rec-icon" *ngIf="conversation.ccRecipients?.length >= 1 || conversation.bccRecipients?.length >= 1">
                                    <img [src]="'assets/Inbox/recipient-expand.svg'" *ngIf="!recipientShow" (click)="onManageRecipent()" alt="Expand">
                                    <img [src]="'assets/Inbox/recipient-collapse.svg'" *ngIf="recipientShow" (click)="onManageRecipent()" alt="Collapse">
                                </span>
                            </div>
                            <div class="fs-10-tocc" *ngIf='conversation.cc !== "" && recipientShow'>CC:
                                <ng-template ngFor let-ccRecipients [ngForOf]="conversation.ccRecipients" let-idx="index">
                                    <span [ngClass]="{'external': ccRecipients.isExternal}" (click)="displayContactInfo($event, opContactInfo, ccRecipients)"
                                    title="{{ccRecipients.emailAddr}}">{{ccRecipients.displayName}}</span>
                                    <span *ngIf="idx != conversation.ccRecipients.length-1">; </span>
                                </ng-template>
                            </div>
                            <div class="fs-10-tocc" *ngIf='conversation.bcc !== "" && recipientShow'>BCC:
                                <ng-template ngFor let-bccRecipients [ngForOf]="conversation.bccRecipients" let-idx="index">
                                    <span [ngClass]="{'external': bccRecipients.isExternal}" (click)="displayContactInfo($event, opContactInfo, bccRecipients)"
                                    title="{{bccRecipients.emailAddr}}">{{bccRecipients.displayName}}</span>
                                    <span *ngIf="idx != conversation.bccRecipients.length-1">; </span>
                                </ng-template>
                            </div>
                        </div>
                        <div class="d-flex flex-column">
                            <!-- Mail Actions -->
                            <div *ngIf="!disableNLP" #replyOverlay class="d-flex justify-content-end pt-2 rply-mr">
                                <div class="mr-3 feedback-icon-size" *ngIf="conversation.intentSuggestionAvailable && isValidGroupForIntent">
                                    <div>
                                        <img [src]="'assets/Inbox/thumbs-up.svg'" alt="Intent Feedback" (click)="savePositiveClientIntentFeedback(conversation)"
                                            style="cursor: pointer" pTooltip="Intent Feedback Yes" tooltipPosition="left" tooltipStyleClass='leftTooltip ui-tooltip-text'>
                                    </div>
                                </div>
                                <div class="mr-3 feedback-icon-size" *ngIf="conversation.intentSuggestionAvailable && isValidGroupForIntent">
                                    <div>
                                        <img [src]="'assets/Inbox/thumbs-down.svg'" alt="Intent Feedback" (click)="showClientIntentFeedback(conversation)"
                                            style="cursor: pointer" pTooltip="Intent Feedback No" tooltipPosition="left" tooltipStyleClass='leftTooltip ui-tooltip-text'>
                                    </div>
                                </div>
                                <div class="mr-3 icon-size" *ngIf="conversation.suggestionAvailable">
                                    <div>
                                        <!-- C153176-4459 | Passing conversation order and conversation order in showConvSuggestion - Order is required NLP suggestion
                                          4578 - Tool tip has been added  -->
                                        <img *ngIf="conversation.suggestionStatus=='blue'" [src]="'assets/Inbox/nlp_icon_selected_u104_blue.svg'" alt="NLP" (click)="showConvSuggestion('orange', $event, i, conversation, nlpPopup)"
                                            style="cursor: pointer" pTooltip="Awaiting Suggestion" tooltipPosition="left" tooltipStyleClass='leftTooltip p-tooltip-text'>
                                        <img *ngIf="conversation.suggestionStatus=='green'" [src]="'assets/Inbox/nlp_icon_selected_u104_green.svg'" alt="NLP" (click)="showConvSuggestion('green', $event, i, conversation, nlpPopup)"
                                            style="cursor: pointer" pTooltip="Suggestion Available" tooltipPosition="left" tooltipStyleClass='leftTooltip p-tooltip-text'>
                                        <img *ngIf="conversation.suggestionStatus=='black'" [src]="'assets/Inbox/nlp_icon_selected_u104_black.svg'" alt="NLP" (click)="showConvSuggestion('black', $event, i, conversation, nlpPopup)"
                                            style="cursor: pointer" pTooltip="No Suggestion" tooltipPosition="left" tooltipStyleClass='leftTooltip p-tooltip-text'>
                                    </div>
                                </div>

                                <div class="mr-3 icon-size" *ngIf="clcSuggestionAvailable">
                                    <div>
                                        <!-- C153176-4594 | Show CLC Suggestion Icon -->
                                        <img [src]="'assets/clc/CLC_Icon_Green.svg'" alt="CLC Suggestion" (click)="clcConvSuggestions(i, conversation, clcSuggestionRecords)"
                                            style="cursor: pointer" pTooltip="CLC Suggestion" tooltipPosition="left" tooltipStyleClass='leftTooltip p-tooltip-text'>
                                    </div>
                                </div>

                                <!-- Jira - 4578 - Tooltip has been added -->
                                <!-- C153176-4983 hide reply/replyall/forward if in Pending Approval-->
                                <div class="mr-3" *ngIf="taskizeInquiryId ==''">
                                    <img [src]="'assets/Inbox/reply.svg'" alt=""
                                    (click)="onReplyConversation('Reply', conversation)"
                                        pTooltip="Reply" tooltipPosition="left"
                                        tooltipStyleClass='leftTooltip p-tooltip-text' *ngIf="ismailboxGridAndDetailviewinSynch(conversation.inquiryId)">
                                        <img [src]="'assets/Inbox/reply.svg'" alt=""
                                        pTooltip="Inquiry selected in grid not matching with detail view please change selection and try again" tooltipPosition="left"
                                        tooltipStyleClass='leftTooltip p-tooltip-text'  *ngIf="!ismailboxGridAndDetailviewinSynch(conversation.inquiryId)">
                                </div>
                                <div class="mr-3">
                                     <img [src]="'assets/Inbox/reply_all.svg'"
                                    alt="Reply All" (click)="onReplyConversation('ReplyAll', conversation)"
                                        pTooltip="ReplyAll" tooltipPosition="left" tooltipStyleClass='leftTooltip p-tooltip-text' *ngIf="ismailboxGridAndDetailviewinSynch(conversation.inquiryId)">
                                        <img [src]="'assets/Inbox/reply_all.svg'"
                                        pTooltip="Inquiry selected in grid not matching with detail view please change selection and try again" tooltipPosition="left"
                                        tooltipStyleClass='leftTooltip p-tooltip-text'  *ngIf="!ismailboxGridAndDetailviewinSynch(conversation.inquiryId)">
                                    </div>
                                <div class="mr-3"*ngIf="taskizeInquiryId ==''">
                                     <img [src]="'assets/Inbox/reassign.svg'" alt="Forward" (click)="onReplyConversation('Forward', conversation)"
                                        pTooltip="Forward" tooltipPosition="left" tooltipStyleClass='leftTooltip p-tooltip-text' *ngIf="ismailboxGridAndDetailviewinSynch(conversation.inquiryId)">
                                        <img [src]="'assets/Inbox/reassign.svg'" alt=""
                                        pTooltip="Inquiry selected in grid not matching with detail view please change selection and try again"
                                        tooltipPosition="left" tooltipStyleClass='leftTooltip p-tooltip-text' *ngIf="!ismailboxGridAndDetailviewinSynch(conversation.inquiryId)">
                                    </div>
                                <div class="mr-2 ellipsis-icon">
                                    <!-- Jira - 4578 - Tooltip has been added -->
                                    <img [src]="'assets/Inbox/ellipsis_icon_selected_u101.svg'" alt="" (click)="showReplyOptions($event, convReplyOptions, conversation)"
                                        pTooltip="Reply Options" tooltipPosition="left" tooltipStyleClass='leftTooltip ui-tooltip-text' *ngIf="ismailboxGridAndDetailviewinSynch(conversation.inquiryId)">
                                        <img [src]="'assets/Inbox/ellipsis_icon_selected_u101.svg'" alt=""
                                        pTooltip="Inquiry selected in grid not matching with detail view please change selection and try again" tooltipPosition="left" tooltipStyleClass='leftTooltip ui-tooltip-text' *ngIf="!ismailboxGridAndDetailviewinSynch(conversation.inquiryId)">
                                        <!-- To show options for Reply in the conversations -->
                                    <p-overlayPanel [styleClass]="'conv-overlay-options reply-overlay'" #convReplyOptions [appendTo]="replyOverlay">
                                        <p-listbox [options]="replyOptions" (click)="onReplyAction($event, convReplyOptions)">
                                            <ng-template let-rplyOpt pTemplate="item">
                                                <div class="reply-items">{{rplyOpt.name}}</div>
                                            </ng-template>
                                        </p-listbox>
                                    </p-overlayPanel>
                                </div>
                            </div>
                            <!--Attachment -->
                            <div #attachOverlay class="d-flex justify-content-end align-items-center mt-2 mb-2 attach-position"
                            *ngIf="conversation.attachmentCount>0" style="display:none!important">
                                <div class="pr-1 align-self-end fs-11">
                                    <span *ngIf="conversation.attachmentCount == 1  && conversation.attachments[0]" title="{{conversation.attachments[0].name}}">
                                        {{ (conversation.attachments[0].name.length>14) ?(conversation.attachments[0].name |slice:0:14)+'...':(conversation.attachments[0].name) }}
                                    </span>
                                </div>
                                <div class="pr-2 attach-icon" *ngIf="conversation.attachmentCount > 1">
                                    <img [src]="'assets/Inbox/attachments_icon_selected_u107.svg'" alt="">
                                    <span class="fs-11"> {{conversation.attachmentCount}}</span>
                                </div>
                                <div *ngIf="!isMobile" class="ellipsis-icon">
                                    <img class="opt-mr" [src]="'assets/Inbox/ellipsis_icon_selected_u101.svg'" alt="" (click)="showAttachments($event, attachmentOptions, conversation.attachments)">
                                    <!-- To show the list og attachments for the conversation -->
                                    <p-overlayPanel [styleClass]="'conv-overlay-options attach-overlay'" #attachmentOptions [appendTo]="attachOverlay" class="attachmentDownload">
                                        <p-listbox [listStyle]="{'max-height':'150px','min-width':'13em','margin-left':'-1em'}" [options]="attachmentsOptions" (click)="onDownloadAction($event, attachmentOptions)">
                                            <ng-template let-attachOpt let-i="index" pTemplate="item">
                                                <div *ngIf="i==0" style="display:inline-flex; margin-bottom: 1em;">
                                                    <span class="download-text" (click)="onDownloadAttachment(conversation.attachments)">Download All </span>
                                                    <div class="pr-2 attach-icon" style="margin-left: 0.5em;">
                                                        <img [src]="'assets/Inbox/download_icon_u70.svg'" alt="" (click)="onDownloadAttachment(conversation.attachments)" title="Download All">
                                                        <img src='assets/Inbox/{{copyAllImage}}' class="copy-all" alt="" (click)="onCopyAllAttachment(conversation.attachments)"
                                                            title="Copy All">
                                                    </div>
                                                    <div style="margin-left: 1.3em;display: none;">
                                                        <img style="height: 1.2em" [src]="'assets/dashboard/ellipses-solid.png'" alt="">
                                                    </div>
                                                </div>
                                                <div class="d-flex flex-row justify-content-between">
                                                    <div class="d-flex flex-row align-items-end">
                                                        <div><img class="notes-icon" [src]="'assets/Inbox/notes_blue.svg'" alt="Notes2"></div>
                                                        <div class="ml-1 attach-name-opt" title="{{attachOpt.name}}">{{attachOpt.name}}</div>
                                                        <div class="ml-1"><img class="download-icon" [src]="'assets/Inbox/download_icon_u70.svg'"
                                                                title="{{attachOpt.name}}" alt="Download1"></div>
                                                        <div class="ml-1"><img class="copy-icon" src='assets/Inbox/{{attachOpt.imageName}}'
                                                                 (click)="onCopyAttachment(attachOpt)" title="{{attachOpt.name}}" alt="Copy1"></div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </p-listbox>
                                    </p-overlayPanel>
                                </div>

                                <div *ngIf="isMobile" class="ellipsis-icon">
                                    <img class="opt-mr" [src]="'assets/Inbox/ellipsis_icon_selected_u101.svg'" alt="" (click)="showAttachments($event, attachmentOptions, conversation.attachments)">
                                    <!-- To show the list of attachments for the conversation -->
                                    <p-overlayPanel [styleClass]="'attach-overlay reply-overlay'" #attachmentOptions [appendTo]="attachOverlay" class="attachmentDownload">
                                        <p-listbox [listStyle]="{'max-height':'150px','min-width':'13em','margin-left':'-1em'}" [options]="attachmentsOptions" (click)="onDownloadAction($event, attachmentOptions)">
                                            <ng-template let-attachOpt let-i="index" pTemplate="item">
                                                <div *ngIf="i==0" style="display:inline-flex; margin-bottom: 1em;">
                                                    <span class="download-text" (click)="onDownloadAttachment(conversation.attachments)">Download All </span>
                                                    <div class="pr-2 attach-icon" style="margin-left: 0.5em;">
                                                        <img [src]="'assets/Inbox/download_icon_u70.svg'" alt="" (click)="onDownloadAttachment(conversation.attachments)">
                                                    </div>
                                                    <div style="margin-left: 1.3em;display: none;">
                                                        <img style="height: 1.2em" [src]="'assets/dashboard/ellipses-solid.png'" alt="">
                                                    </div>
                                                </div>
                                                <div class="d-flex flex-row justify-content-between">
                                                    <div class="d-flex flex-row align-items-end">
                                                        <div><img class="notes-icon" [src]="'assets/Inbox/notes_blue.svg'" alt="Notes3"></div>
                                                        <div class="ml-1 attach-name-opt" title="{{attachOpt.name}}">{{attachOpt.name}}</div>
                                                        <div class="ml-1"><img class="download-icon" [src]="'assets/Inbox/download_icon_u70.svg'"
                                                                title="{{attachOpt.name}}" alt="Download2"></div>
                                                        <div class="ml-1"><img class="copy-icon" src='assets/Inbox/{{attachOpt.imageName}}'
                                                                (click)="onCopyAttachment(attachOpt)" title="{{attachOpt.name}}" alt="Copy2">
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </p-listbox>
                                    </p-overlayPanel>
                                </div>
                            </div>
                            <!--Date Time -->
                            <div class="ml-auto mr-2 mt-auto fs-10">{{conversation.modDate}}</div>
                            <div class="ml-auto mr-2 fs-10" [style.margin-top]="{'auto' : !disableNLP}">ID : {{conversation.inquiryId}}</div>
                        </div>
                    </div>
                    <div *ngIf="!isMobile && taskizeInquiryId !='' " class="d-flex taskize-section" >
                        <div class="taskize-text">
                            Taskize ID : {{taskizeInquiryId}}
                        </div>
                        <div class="ml-auto mr-2">
                            <img class='taskize-grid-mail-icon' src='assets/Inbox/taskize-icon-anex.png'>
                        </div>
                    </div>
                    <!-- attachement panel less or upto 2 -->
                <div [ngClass]="isAttachemntPanelCollapsed ? 'attachement-grid-container-collapsed-view' : 'attachement-grid-container-expanded-view'"
                     *ngIf="!isMobile && conversation?.attachments && conversation?.attachments.length>0 && conversation?.attachments.length<=2">
                    <div class="container collapsed-view-more-than-two"
                    [ngClass]="{'attchement-grid-select-all-view': isSelectAllAttchClicked,
                    'attchement-grid-one-attachement':conversation?.attachments.length === 1,
                    'attchement-grid-one-attachement-with-select-all':conversation?.attachments.length === 1
                    && isSelectAllAttchClicked,
                    'attchement-grid-two-attachement':conversation?.attachments.length === 2,
                    'attchement-grid-two-attachement-with-select-all':conversation?.attachments.length === 2
                    && isSelectAllAttchClicked,
                    'collapsed-view-more-than-two':conversation?.attachments.length > 2
                   }" [attr.id]="conversation?.id +'_' + parentComponent?.strMailboxView">

                        <ng-container *ngFor="let attachment of conversation.attachments;let i = index">

                            <div class="attachement-checkbox" *ngIf="i === 0">
                                <input type="checkbox" value="" [checked]="isSelectAllAttchClicked" (change)="isSelectAllAttchClicked = !isSelectAllAttchClicked">
                            </div>
                            <div class="attachement-checkbox" *ngIf="i !== 0 && i%3 === 0">
                                    <!-- to create empty area below checkbox from second row onwards-->

                             </div>
                            <div class="attachement-item" (mouseenter)="showAttachments(null, null, conversation?.attachments)">

                                <div class="box" *ngIf="attachment.showTitle" (mouseenter)="onMouseEnterAttTitle(attachment)" (mouseleave)="onMouseLeaveAttTitle(attachment)">
                                    <img class="download-icon" [src]="getAttachementImageByExtension(attachment)" alt="Download"/>
                                    <div class="attachement-title">
                                        {{attachment?.name}}
                                    </div>

                                </div>
                                <div class="menu" *ngIf="attachment.showMenu" (mouseenter)="onMouseEnterAttMenu(attachment)" (mouseleave)="onMouseLeaveAttMenu(attachment)">
                                    <div class="eye-icon">
                                                <img class="eye-img"
                                                (click)="onViewctionRedesigned(attachment)"
                                                title="View"
                                                [src]="menuIconImageConfig.viewIconSrc"
                                                (mouseover)="menuIconImageConfig.viewIconSrc = menuIconImageConfig.viewIconmouseOn"
                                                (mouseout)="menuIconImageConfig.viewIconSrc = menuIconImageConfig.viewIconmouseOut" alt="View">
                                     </div>
                                     <div class="menu-icons-border"></div>
                                    <div class="image-container">
                                        <img class="download-icon"
                                         (click)="onDownloadAttachment(attachment)"  title="Download"
                                         [src]="menuIconImageConfig.downloadIconSrc"
 (mouseover)="menuIconImageConfig.downloadIconSrc = menuIconImageConfig.downloadIconmouseOn"
 (mouseout)="menuIconImageConfig.downloadIconSrc = menuIconImageConfig.downloadIconmouseOut" alt="Download">
                                    </div>
                                    <div class="menu-icons-border">

                                    </div>
                                    <div class="image-container"><img class="copy-icon"
                                         [src]="menuIconImageConfig.copyIconSrc"
                                         (click)="onCopyActionRedesigned(attachment,conversation.attachments)" title="Copy"
                                         (mouseover)="menuIconImageConfig.copyIconSrc = menuIconImageConfig.copyIconmouseOn"
                                         (mouseout)="menuIconImageConfig.copyIconSrc = menuIconImageConfig.copyIconmouseOut" alt="Copy"></div>
                                </div>
                            </div>
                            <!-- show menu options when select all checkbox is checked -->

                            <div class="attachement-more" *ngIf="conversation?.attachments.length>3 && i === 2 && isAttachemntPanelCollapsed && !isSelectAllAttchClicked" (click)="onAttachementMoreClick()">
                                +({{conversation?.attachments.length-3}}) More
                            </div>
                            <div class="attachement-more" *ngIf="conversation?.attachments.length>3 && i === 2 && !isAttachemntPanelCollapsed && !isSelectAllAttchClicked" (click)="onAttachementMoreClick()">
                                    - Less
                            </div>

                            <div class="attachement-more" *ngIf="(i+1) >3  && (i+1)%3===0">
                                    <!-- to create empty area below checkbox from second row onwards-->

                             </div>

                        </ng-container>
                        <div class="attachement-select-all-Menu"
                        *ngIf="(conversation?.attachments.length>3 && isSelectAllAttchClicked)
                            || (conversation?.attachments.length === 2  && isSelectAllAttchClicked)
                            || (conversation?.attachments.length === 3  && isSelectAllAttchClicked)
                            || (conversation?.attachments.length === 1  && isSelectAllAttchClicked)">
                            <div *ngIf="conversation?.attachments.length>3 && isAttachemntPanelCollapsed">
                                 + ({{conversation?.attachments.length-3}})
                            </div>

                                    <div class="attachement-menu-item">
                                            <img class="copy-icon"
                                            (click)="onCopyAllAttachment(conversation?.attachments)"
                                            [src]="menuIconImageConfig.copyAllIconSrc" title="copy"
(mouseover)="menuIconImageConfig.copyAllIconSrc = menuIconImageConfig.copyAllIconmouseOn"
(mouseout)="menuIconImageConfig.copyAllIconSrc = menuIconImageConfig.copyAllIconmouseOut" alt="Copy">
                                    </div>
                                    <div class="attachement-menu-item">
                                        <img class="download-icon"
                                        (click)="onDownloadAttachment(conversation?.attachments)"
                                        [src]="menuIconImageConfig.downloadAllIconSrc"
 (mouseover)="menuIconImageConfig.downloadAllIconSrc = menuIconImageConfig.downloadAllIconmouseOn"
 (mouseout)="menuIconImageConfig.downloadAllIconSrc = menuIconImageConfig.downloadAllIconmouseOut" alt="Download">
                                    </div>


                        </div>
                </div>
                </div>
                <!-- Attachement Panel More than 2 Attachement collapsed View-->
                <div [ngClass]="isAttachemntPanelCollapsed ? 'attachement-grid-container-collapsed-view' : 'attachement-grid-container-expanded-view'"
                     *ngIf="!isMobile && conversation?.attachments && conversation?.attachments.length>0
                     && conversation?.attachments.length>2 && !isSelectAllAttchClicked">

                     <div class="attachement-checkbox">
                        <input type="checkbox" value="" [checked]="isSelectAllAttchClicked" (change)="isSelectAllAttchClicked = !isSelectAllAttchClicked">
                    </div>
                <div class="attach-grid-container" [ngClass]="isAttachemntPanelCollapsed ? '':'attach-grid-container-expanded'">
                     <div class="container collapsed-view-more-than-two"
                    [ngClass]="{'attchement-grid-select-all-view': isSelectAllAttchClicked,
                    'attchement-grid-one-attachement':conversation?.attachments.length === 1,
                    'attchement-grid-one-attachement-with-select-all':conversation?.attachments.length === 1
                    && isSelectAllAttchClicked,
                    'attchement-grid-two-attachement':conversation?.attachments.length === 2,
                    'attchement-grid-two-attachement-with-select-all':conversation?.attachments.length === 2
                    && isSelectAllAttchClicked,
                    'collapsed-view-more-than-two':conversation?.attachments.length > 2
                   }" [attr.id]="conversation?.id +'_' + parentComponent?.strMailboxView">

                        <ng-container *ngFor="let attachment of conversation?.attachments;let i = index">

                            <div class="attachement-item" (mouseenter)="showAttachments(null, null, conversation?.attachments)">

                                <div class="box" *ngIf="attachment.showTitle" (mouseenter)="onMouseEnterAttTitle(attachment)" (mouseleave)="onMouseLeaveAttTitle(attachment)">
                                    <img class="download-icon" [src]="getAttachementImageByExtension(attachment)" alt="Download"/>
                                    <div class="attachement-title">
                                        {{attachment?.name}}
                                    </div>
                                </div>
                                <div class="menu" *ngIf="attachment.showMenu" (mouseenter)="onMouseEnterAttMenu(attachment)" (mouseleave)="onMouseLeaveAttMenu(attachment)">
                                    <div class="eye-icon">
                                                <img class="eye-img"
                                                (click)="onViewctionRedesigned(attachment)" title="View"
                                                [src]="menuIconImageConfig.viewIconSrc"
                                                (mouseover)="menuIconImageConfig.viewIconSrc = menuIconImageConfig.viewIconmouseOn"
                                                (mouseout)="menuIconImageConfig.viewIconSrc = menuIconImageConfig.viewIconmouseOut"
                                                alt="View">
                                     </div>
                                     <div class="menu-icons-border"></div>
                                    <div class="image-container">
                                        <img class="download-icon" (click)="onDownloadAttachment(attachment)"
                                        title="Download"
                                         [src]="menuIconImageConfig.downloadIconSrc" alt="Download"
                                        (mouseover)="menuIconImageConfig.downloadIconSrc = menuIconImageConfig.downloadIconmouseOn"
                                        (mouseout)="menuIconImageConfig.downloadIconSrc = menuIconImageConfig.downloadIconmouseOut">
                                    </div>
                                    <div class="menu-icons-border">

                                </div>
                                    <div class="image-container">
                                    	<img class="copy-icon" alt="Copy"
                                         (click)="onCopyActionRedesigned(attachment,conversation.attachments)" title="Copy"
                                         [src]="menuIconImageConfig.copyIconSrc"
                                         (mouseover)="menuIconImageConfig.copyIconSrc = menuIconImageConfig.copyIconmouseOn"
                                         (mouseout)="menuIconImageConfig.copyIconSrc = menuIconImageConfig.copyIconmouseOut" ></div>
                                </div>
                            </div>
                            <!-- show menu options when select all check box is checked -->

                        </ng-container>
                        <div class="attachement-select-all-Menu"
                        *ngIf="(conversation?.attachments.length>3 && isSelectAllAttchClicked)
                            || (conversation?.attachments.length === 2  && isSelectAllAttchClicked)
                            || (conversation?.attachments.length === 3  && isSelectAllAttchClicked)
                            || (conversation?.attachments.length === 1  && isSelectAllAttchClicked)">
                            <div *ngIf="conversation?.attachments.length>3 && isAttachemntPanelCollapsed">
                                 + ({{conversation?.attachments.length-3}})
                            </div>

                                    <div class="attachement-menu-item">
                                            <img class="copy-icon" alt="Copy" (click)="onCopyAllAttachment(attachments)"
                                            [src]="menuIconImageConfig.copyAllIconSrc" title="copy"
(mouseover)="menuIconImageConfig.copyAllIconSrc = menuIconImageConfig.copyAllIconmouseOn"
(mouseout)="menuIconImageConfig.copyAllIconSrc = menuIconImageConfig.copyAllIconmouseOut">
                                    </div>
                                    <div class="attachement-menu-item">
                                        <img class="download-icon" alt="Download"
                                        (click)="onDownloadAttachment(attachments)"
                                        [src]="menuIconImageConfig.downloadAllIconSrc"
                                        (mouseover)="menuIconImageConfig.downloadAllIconSrc = menuIconImageConfig.downloadAllIconmouseOn"
                                        (mouseout)="menuIconImageConfig.downloadAllIconSrc = menuIconImageConfig.downloadAllIconmouseOut">
                                    </div>


                        </div>

                </div>
            </div>
                 <!--  attach grid redesign -->
                 <div class="attachement-more"
                 *ngIf="conversation?.attachments.length>3 && isAttachemntPanelCollapsed && !isSelectAllAttchClicked"
                 (click)="onAttachementMoreClick()"
                 [innerHTML]="getReminingAttachementCount(conversation?.id +'_' + parentComponent?.strMailboxView,conversation)"
                 style="font-size: 12px;
                 font-family: 'Interstate'">
                    +({{conversation?.attachments.length-3}}) More
                </div>
                <div class="attachement-more" style="width:10%"
                *ngIf="conversation?.attachments.length>3 && !isAttachemntPanelCollapsed && !isSelectAllAttchClicked" (click)="onAttachementMoreClick()">
                        - Less
                </div>
                </div>

                <div [ngClass]="isSelectAllAttchClicked ? 'attachement-grid-container-collapsed-view' : 'attachement-grid-container-expanded-view'"
                *ngIf="!isMobile && conversation?.attachments && conversation?.attachments.length>0 && conversation?.attachments.length>2 && isSelectAllAttchClicked">
                <div class="attachement-checkbox">
                    <input type="checkbox" value="" [checked]="isSelectAllAttchClicked" (change)="isSelectAllAttchClicked = !isSelectAllAttchClicked; isAttachemntPanelCollapsed = true">
                </div>

                <div class="container collapsed-view-more-than-two"
               [ngClass]="isSelectAllAttchClicked? 'select-all-view-more-than-two': 'collapsed-view-more-than-two'"
               [attr.id]="conversation?.id +'_' + parentComponent?.strMailboxView">

                   <ng-container *ngFor="let attachment of conversation?.attachments;let i = index">
                       <div class="attachement-item" (mouseenter)="showAttachments(null, null, conversation?.attachments)">
                           <div class="box" *ngIf="attachment.showTitle" (mouseenter)="onMouseEnterAttTitle(attachment)" (mouseleave)="onMouseLeaveAttTitle(attachment)">
                               <img class="download-icon" [src]="getAttachementImageByExtension(attachment)" alt="Download"/>
                               <div class="attachement-title">
                                   {{attachment?.name}}
                               </div>
                           </div>
                           <div class="menu" *ngIf="attachment.showMenu" (mouseenter)="onMouseEnterAttMenu(attachment)" (mouseleave)="onMouseLeaveAttMenu(attachment)">
                               <div class="eye-icon">
                                           <img class="eye-img"
                                           (click)="onViewctionRedesigned(attachment)"
                                           title="View" alt="View"
                                           [src]="menuIconImageConfig.viewIconSrc"
                                           (mouseover)="menuIconImageConfig.viewIconSrc = menuIconImageConfig.viewIconmouseOn"
                                           (mouseout)="menuIconImageConfig.viewIconSrc = menuIconImageConfig.viewIconmouseOut">
                                </div>
                                <div class="menu-icons-border">

                                </div>
                               <div class="image-container">
                                   <img class="download-icon" alt="Download"
                                   (click)="onDownloadAttachment(attachment)"
                                   title="Download"
                                   [src]="menuIconImageConfig.downloadIconSrc"
 (mouseover)="menuIconImageConfig.downloadIconSrc = menuIconImageConfig.downloadIconmouseOn"
 (mouseout)="menuIconImageConfig.downloadIconSrc = menuIconImageConfig.downloadIconmouseOut"
                                   >
                               </div>
                               <div class="menu-icons-border">

                             </div>
                               <div class="image-container"
                               ><img class="copy-icon" alt="Copy" (click)="onCopyActionRedesigned(attachment,conversation.attachments)" title="Copy"
                                [src]="menuIconImageConfig.copyIconSrc"
                                         (mouseover)="menuIconImageConfig.copyIconSrc = menuIconImageConfig.copyIconmouseOn"
                                         (mouseout)="menuIconImageConfig.copyIconSrc = menuIconImageConfig.copyIconmouseOut"></div>
                           </div>
                       </div>
                       <!-- show menu options when select all checkbox is checked -->

                       <div class="attachement-more" *ngIf="conversation?.attachments.length>3 && i === 2 && isAttachemntPanelCollapsed && !isSelectAllAttchClicked" (click)="onAttachementMoreClick()">
                           +({{conversation?.attachments.length-3}}) More
                       </div>
                       <div class="attachement-more" *ngIf="conversation?.attachments.length>3 && i === 2 && !isAttachemntPanelCollapsed && !isSelectAllAttchClicked" (click)="onAttachementMoreClick()">
                               - Less
                       </div>
                   </ng-container>
           </div>
           <div class="attachement-select-all-Menu">
               <div *ngIf="conversation?.attachments.length>2 && isSelectAllAttchClicked"
                    class="pr-3" style="display:flex;align-items: center; height:100%;background-color: white;font-size: 12px;
                    font-family: 'Interstate'"
                    [innerHTML]="getReminingAttachementCount(conversation?.id +'_' + parentComponent?.strMailboxView,conversation)">
                    + ({{conversation?.attachments.length-2}})
               </div>
                   <div class="d-flex" style="height:100%;">
                       <div class="attachement-menu-item">
                               <img class="copy-icon" alt="Copy"
                                (click)="onCopyAllAttachment(conversation?.attachments)"
                                [src]="menuIconImageConfig.copyAllIconSrc" title="copy"
                            (mouseover)="menuIconImageConfig.copyAllIconSrc = menuIconImageConfig.copyAllIconmouseOn"
                            (mouseout)="menuIconImageConfig.copyAllIconSrc = menuIconImageConfig.copyAllIconmouseOut">

                       </div>
                       <div class="attachement-menu-item">
                           <img class="download-icon" alt="Download"
                           (click)="onDownloadAttachment(conversation?.attachments)"
                           [src]="menuIconImageConfig.downloadAllIconSrc"
(mouseover)="menuIconImageConfig.downloadAllIconSrc = menuIconImageConfig.downloadAllIconmouseOn"
(mouseout)="menuIconImageConfig.downloadAllIconSrc = menuIconImageConfig.downloadAllIconmouseOut">
                       </div>
                   </div>

           </div>
           </div>
                    <div class="bdr-btm">

                    </div>
                    <!--======================================Code for Mobile device only =====================================-->
                    <!-- C153176-5319 | No Reply options should be visible for pending approval inquiry -->
                    <div *ngIf="isMobile && !disableNLP" class="d-flex bdr-btm pb-2 mobile-hb">
                        <!-- border:1px solid red; -->
                        <span class="d-flex mobileMlhmt">
                            <!-- Jira - 4578 - Tooltip has been added. C153176-5101: do not show Reply/ReplyAll/Forward on pending approvals -->
                            <div class="mr-3"> <img [src]="'assets/Inbox/reply.svg'" alt=""
                                (click)="onReplyConversation('Reply', conversation)"
                                pTooltip="Reply" tooltipPosition="left" tooltipStyleClass='leftTooltip p-tooltip-text'  *ngIf="ismailboxGridAndDetailviewinSynch(conversation.inquiryId)">
                                <img [src]="'assets/Inbox/reply.svg'" alt=""
                                pTooltip="Inquiry selected in grid not matching with detail view please change selection and try again" tooltipPosition="left"
                                tooltipStyleClass='leftTooltip p-tooltip-text'  *ngIf="!ismailboxGridAndDetailviewinSynch(conversation.inquiryId)">
                            </div>
                            <div class="mr-3">
                                <img [src]="'assets/Inbox/reply_all.svg'" alt="" *ngIf="ismailboxGridAndDetailviewinSynch(conversation.inquiryId)" (click)="onReplyConversation('ReplyAll', conversation)" pTooltip="ReplyAll" tooltipPosition="left" tooltipStyleClass='leftTooltip p-tooltip-text'>
                                <img [src]="'assets/Inbox/reply_all.svg'" alt="" pTooltip="Inquiry selected in grid not matching with detail view please change selection and try again" tooltipPosition="left"
                                tooltipStyleClass='leftTooltip p-tooltip-text'  *ngIf="!ismailboxGridAndDetailviewinSynch(conversation.inquiryId)">
                            </div>
                            <div class="mr-3"> <img [src]="'assets/Inbox/reassign.svg'" alt="" (click)="onReplyConversation('Forward', conversation)"
                                pTooltip="Forward" tooltipPosition="left" tooltipStyleClass='leftTooltip p-tooltip-text' *ngIf="ismailboxGridAndDetailviewinSynch(conversation.inquiryId)">
                                <img [src]="'assets/Inbox/reassign.svg'" alt=""
                                pTooltip="Inquiry selected in grid not matching with detail view please change selection and try again" tooltipPosition="left" tooltipStyleClass='leftTooltip p-tooltip-text' *ngIf="!ismailboxGridAndDetailviewinSynch(conversation.inquiryId)"></div>
                            <div class="mr-2 ellipsis-icon">
                                    <!-- Jira - 4578 - Tooltip has been added -->
                                <img [src]="'assets/Inbox/ellipsis_icon_selected_u101.svg'" alt="" (click)="showReplyOptionsMobile($event, convReplyOptionsMobile, conversation)"
                                 pTooltip="Reply Options" tooltipPosition="left" tooltipStyleClass='leftTooltip ui-tooltip-text' *ngIf="ismailboxGridAndDetailviewinSynch(conversation.inquiryId)">
                                 <img [src]="'assets/Inbox/ellipsis_icon_selected_u101.svg'" alt=""
                                 pTooltip="Inquiry selected in grid not matching with detail view please change selection and try again" tooltipPosition="left" tooltipStyleClass='leftTooltip ui-tooltip-text' *ngIf="!ismailboxGridAndDetailviewinSynch(conversation.inquiryId)">
                            </div>
                               <!-- To show options for Reply in the conversations -->
                            <p-overlayPanel [styleClass]="'conv-overlay-options reply-overlay'" #convReplyOptionsMobile [appendTo]="replyOverlay">
                                    <p-listbox [options]="replyOptions" (click)="onReplyAction($event, convReplyOptions)">
                                        <ng-template let-rplyOpt pTemplate="item">
                                            <div class="reply-items">{{rplyOpt.name}}</div>
                                        </ng-template>
                                    </p-listbox>
                                </p-overlayPanel>
                        </span>
                    </div>
                    <div *ngIf="isMobile" class="d-flex bdr-btm pb-2" [ngClass]="{'external-recipient': conversation.fromRecipient.isExternal}">

                        <div class="profile-icon">
                            <app-image-placeholder [recipient]="conversation.fromRecipient"></app-image-placeholder>
                        </div>
                        <div class="mr-auto pt-2 break-Work" style="max-width:64%">
                            <!-- border:1px solid blue; -->
                            <div class="fs-12" [ngClass]="{'external': conversation.fromRecipient.isExternal}">
                                {{conversation.fromRecipient.displayName}}
                            </div>
                            <div class="fs-10-tocc">To:
                                <span [ngClass]="{'external': isExternalShowingTo() }">{{ getShowingToDisplayName()}}</span>
                                <span class="rec-icon" style="padding-left:5px;" *ngIf="mobileToList && (mobileToList.length >= 1)">
                                        <img [src]="'assets/Inbox/recipient-expand.svg'" *ngIf="!multiToShow" (click)="onManageMultipleTo()" alt="Expand">
                                        <img [src]="'assets/Inbox/recipient-collapse.svg'" *ngIf="multiToShow" (click)="onManageMultipleTo()" alt="Collapse">
                                </span>
                                <div class="fs-10-tocc" *ngIf='mobileToList !== "" && multiToShow'>
                                    <ng-template ngFor let-toR [ngForOf]="mobileToList" let-idx="index">
                                        <span [ngClass]="{'external': toR.isExternal}">{{toR.displayName}}</span>
                                        <span *ngIf="idx != mobileToList.length-1">; </span>
                                    </ng-template>
                                </div>
                                <span class="ml-1"> </span>
                                <span class="rec-icon" *ngIf="conversation.ccRecipients?.length >= 1 || conversation.bccRecipients?.length >= 1">
                                    <img [src]="'assets/Inbox/recipient-expand.svg'" *ngIf="!recipientShow" (click)="onManageRecipent()" alt="Expand">
                                    <img [src]="'assets/Inbox/recipient-collapse.svg'" *ngIf="recipientShow" (click)="onManageRecipent()" alt="Collapse">
                                </span>
                            </div>
                            <div class="fs-10-tocc" *ngIf='conversation.cc !== "" && recipientShow'>CC:
                                <ng-template ngFor let-ccRecipients [ngForOf]="conversation.ccRecipients" let-idx="index">
                                    <span [ngClass]="{'external': ccRecipients.isExternal}">{{ccRecipients.displayName}}</span>
                                    <span *ngIf="idx != conversation.ccRecipients.length-1">; </span>
                                </ng-template>
                            </div>
                            <div class="fs-10-tocc" *ngIf='conversation.bcc !== "" && recipientShow'>BCC:
                                <ng-template ngFor let-bccRecipients [ngForOf]="conversation.bccRecipients" let-idx="index">
                                    <span [ngClass]="{'external': bccRecipients.isExternal}">{{bccRecipients.displayName}}</span>
                                    <span *ngIf="idx != conversation.bccRecipients.length-1">; </span>
                                </ng-template>
                            </div>
                        </div>
                        <div class="d-flex flex-column">
                            <!-- Mail Actions -->
                            <div #replyOverlay class="d-flex pt-2 rply-mr">
                                <div class="icon-size" *ngIf="conversation.suggestionAvailable" style="margin-right:4px;">
                                    <div *ngIf="!disableNLP">
                                        <!-- C153176-4459 -->
                                        <!--  4578 - Tool tip added-->
                                        <img *ngIf="conversation.suggestionStatus=='red'" [src]="'assets/Inbox/nlp_icon_selected_u104_red.svg'" alt="NLP" (click)="showConvSuggestion('red', $event, i, conversation, nlpPopup)"
                                            style="cursor: pointer" pTooltip="Suggestion Not Available" tooltipPosition="left"
                                            tooltipStyleClass='leftTooltip p-tooltip-text'>
                                        <img *ngIf="conversation.suggestionStatus=='orange'" [src]="'assets/Inbox/nlp_icon_selected_u104_orange.svg'" alt="NLP" (click)="showConvSuggestion('orange', $event, i, conversation, nlpPopup)"
                                            style="cursor: pointer" pTooltip="Awaiting Suggestion" tooltipPosition="left" tooltipStyleClass='leftTooltip p-tooltip-text'>
                                        <img *ngIf="conversation.suggestionStatus=='green'" [src]="'assets/Inbox/nlp_icon_selected_u104_green.svg'" alt="NLP" (click)="showConvSuggestion('green', $event, i, conversation, nlpPopup)"
                                            style="cursor: pointer" pTooltip="Suggestion Available" tooltipPosition="left" tooltipStyleClass='leftTooltip p-tooltip-text'>
                                        <img *ngIf="conversation.suggestionStatus=='black'" [src]="'assets/Inbox/nlp_icon_selected_u104_black.svg'" alt="NLP" (click)="showConvSuggestion('black', $event, i, conversation, nlpPopup)"
                                            style="cursor: pointer" pTooltip="No Suggestion" tooltipPosition="left" tooltipStyleClass='leftTooltip p-tooltip-text'>
                                    </div>
                                </div>
                                <!--Date Time -->
                                <div class="ml-auto mr-2 mt-auto fs-10">
                                    <!-- Jira : 4929 : Date is not coming -->
                                    <span>{{conversation.modDate1}}</span><br/>
                                    <span>{{conversation.modDate2}}</span><br/>
                                    <!-- C153176-4928: display inquiryId on mobile conv view-->
                                    <span>ID : {{conversation.inquiryId}}</span>
                                </div>

                            </div>
                            <!--Attachment -->
                            <div #attachOverlay class="d-flex justify-content-end align-items-center mt-2 mb-2 attach-position" *ngIf="conversation.attachmentCount>0">
                                <div class="pr-1 align-self-end fs-11">
                                    <span *ngIf="conversation.attachmentCount == 1  && conversation.attachments[0]" title="{{conversation.attachments[0].name}}">
                                        {{ (conversation.attachments[0].name.length>14) ?(conversation.attachments[0].name |slice:0:14)+'...':(conversation.attachments[0].name) }}
                                    </span>
                                </div>
                                <div class="pr-2 attach-icon" *ngIf="conversation.attachmentCount > 1">
                                    <img [src]="'assets/Inbox/attachments_icon_selected_u107.svg'" alt="">
                                    <span class="fs-11"> {{conversation.attachmentCount}}</span>
                                </div>
                                <div class="ellipsis-icon">
                                    <img class="opt-mr" [src]="'assets/Inbox/ellipsis_icon_selected_u101.svg'" alt="" (click)="showAttachments($event, attachmentOptions, conversation.attachments)">
                                    <!-- To show the list og attachments for the conversation -->
                                    <p-overlayPanel [styleClass]="'conv-overlay-options attach-overlay'" #attachmentOptions [appendTo]="attachOverlay" class="attachmentDownload">
                                        <p-listbox [listStyle]="{'max-height':'150px','min-width':'13em','margin-left':'-1em'}" [options]="attachmentsOptions" (click)="onDownloadAction($event, attachmentOptions)">
                                            <ng-template let-attachOpt let-i="index" pTemplate="item">
                                                <div *ngIf="i==0" style="display:inline-flex; margin-bottom: 1em;">
                                                    <span class="download-text" (click)="onDownloadAttachment(conversation.attachments)">Download All </span>
                                                    <div class="pr-2 attach-icon" style="margin-left: 0.5em;">
                                                        <img [src]="'assets/Inbox/download_icon_u70.svg'" alt="" (click)="onDownloadAttachment(conversation.attachments)">
                                                    </div>
                                                    <div style="margin-left: 1.3em;display: none;">
                                                        <img style="height: 1.2em" [src]="'assets/dashboard/ellipses-solid.png'" alt="">
                                                    </div>
                                                </div>
                                                <div class="d-flex flex-row justify-content-between">
                                                    <div class="d-flex flex-row align-items-end">
                                                        <div><img class="notes-icon" [src]="'assets/Inbox/notes_blue.svg'" alt="Notes1"></div>
                                                        <div class="ml-1 attach-name-opt" title="{{attachOpt.name}}">{{attachOpt.name}}</div>
                                                        <div class="ml-1"><img class="download-icon" [src]="'assets/Inbox/download_icon_u70.svg'"
                                                                title="{{attachOpt.name}}" alt="Download3"></div>
                                                        <div class="ml-1"><img class="copy-icon" [src]="'assets/Inbox/copy_icon.svg'"
                                                                title="{{attachOpt.name}}" alt="Copy"></div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </p-listbox>
                                    </p-overlayPanel>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- ===========END of Mobile Code ========================== -->
                    <div class="d-flex flex-column ml-5 lazy-load-content" [ngClass]="{'':inboxViewMode === 'vertical','msg-content-horizontal-view':inboxViewMode === 'horizontal'}">
                         <div [innerHTML]="conversation.contentExpanded | safeHtml">
                        </div>
                        <!-- C153176-5595: Email chain not loading -->
                        <div *ngIf="conversation.contentCollapsed">
                            <img [src]="'assets/Inbox/view-more-message.svg'" class="expandMsg"
                            (click)="expandOrCollapseMsg(conversation)" alt="Expand/Collapse">
                        </div>
                        <div *ngIf="conversation.messageExpanded" [innerHTML]="conversation.contentCollapsed | safeHtml">
                        </div>
                    </div>
                </div>
                <!-- even if pagination enebled but conv count is lesss than threshold use old pagination logic -->
                <ng-template [ngIf]="!conversation.isExpandedView &&!htmlPaginationEnabled
                || (htmlPaginationEnabled && !conversation.isExpandedView && inquiryReportedConvCount<minConvCountForConvPagination)">
                    <!-- Show the Message count when conversation are collapsed -->
                    <div class="collapse-more-message mt-3" *ngIf="totalConversations > 3 && i === totalConversations-2">
                        <span *ngIf="inquiryReportedConvCount > totalConversations" class="more-msg-icon-text" (click)="changeMessageView()"> See {{totalConversations - 3}}
                                more messages out of {{inquiryReportedConvCount}} in total </span>
                        <span *ngIf="!(inquiryReportedConvCount > totalConversations)" class="more-msg-icon-text" (click)="changeMessageView()"> See {{totalConversations - 3}}
                                more messages </span>
                        <span class="more-msg-icon">
                                <img [src]="'assets/Inbox/grid-chevron-down.svg'" (click)="changeMessageView()" alt="Expand">
                            </span>
                    </div>
                    <!-- Collapse view of message -->
                    <div class="detail-view-body-container mt-3" *ngIf="(i === totalConversations-1 || i === totalConversations-2) && !isMobile">
                        <div class="d-flex align-items-center" [ngClass]="{'external-recipient': conversation.fromRecipient.isExternal}">
                            <div class="profile-icon-collapse">
                                <app-image-placeholder [recipient]="conversation.fromRecipient"></app-image-placeholder>
                            </div>
                            <div class="mr-auto fs-14" [ngClass]="{'external': conversation.fromRecipient.isExternal}">{{conversation.fromRecipient.displayName}} </div>
                            <div class="mr-auto fs-14 collapsedContent">{{conversation.viewContent}}</div>
                            <div class="mr-2 fs-10">{{conversation.modDate}}</div>
                        </div>
                    </div>
                    <!--  Collapse view of message for Mobile  *****  Jira : C153176-4476  : Mobile: Conversation View  : Mail Details View Page  -->
                    <div class="d-flex detail-view-body-container-mobile mt-3" *ngIf="(i === totalConversations-1 || i === totalConversations-2) && isMobile">
                        <div class="d-flex align-items-center mob-width87" [ngClass]="{'external-recipient': conversation.fromRecipient.isExternal}">
                            <div class="profile-icon-collapse">
                                <app-image-placeholder [recipient]="conversation.fromRecipient"></app-image-placeholder>
                            </div>
                            <div class="mr-auto fs-14" [ngClass]="{'external': conversation.fromRecipient.isExternal}">{{conversation.fromRecipient.displayName}} </div>
                            <div class="mr-auto fs-14 collapsedContent">{{conversation.viewContent}}</div>
                        </div>
                        <!--Date Time -->
                        <div class="ml-auto mr-2 fs-10 mob-width11">
                            <!-- Jira : 4929 : Date is not coming -->
                            <span>{{conversation.modDate1}}</span><br/>
                            <span>{{conversation.modDate2}}</span>
                        </div>
                    </div>
                </ng-template>
                 <!-- when pagination is enabled in config and conv count is greater than minconvcount apply new pagination-->
                 <!-- when rendered conversation list is less than inq conv count show pagination -->
                 <!-- disable pagination for conversatio history -->
                <ng-template [ngIf]="(!conversation.isExpandedView && htmlPaginationEnabled &&
                inquiryReportedConvCount>=minConvCountForConvPagination && !isFromCoversationHistoryGrid) ||
                (htmlPaginationEnabled && conversationList.length<inquiryReportedConvCount && !isFromCoversationHistoryGrid)">
                    <!-- Show the Message count with show more in expanded view at bottom of pagination view-->
                    <div class="collapse-more-message mt-3" *ngIf="(i === conversationList.length-1) && (conversation.isExpandedView)">
                        <span *ngIf="(i === conversationList.length-1)"
                             class="more-msg-icon-text"
                            (click)="changeMessageViewWithServerSidePagination()">
                            See {{
                                pazeSizeForConvPagination<(inquiryReportedConvCount - conversationList.length)?
                                pazeSizeForConvPagination:(inquiryReportedConvCount - conversationList.length)
                            }}
                                more messages
                        </span>
                    </div>
                    <!-- Show the Message count in collapsed view-->
                    <div class="collapse-more-message mt-3" *ngIf="(i === conversationList.length-1) && (!conversation.isExpandedView)">
                        <span
                             class="more-msg-icon-text"
                            (click)="changeMessageViewWithServerSidePagination()">
                            See {{
                                pazeSizeForConvPagination
                            }}
                                more messages
                        </span>
                    </div>
                    <!-- Collapse view of message to show first two conversations-->
                    <div class="detail-view-body-container mt-3"
                     *ngIf="(!conversation.isExpandedView) && (i === totalConversations-1 || i === totalConversations-2) && !isMobile">
                        <div class="d-flex align-items-center" [ngClass]="{'external-recipient': conversation.fromRecipient.isExternal}">
                            <div class="profile-icon-collapse">
                                <app-image-placeholder [recipient]="conversation.fromRecipient"></app-image-placeholder>
                            </div>
                            <div class="mr-auto fs-14" [ngClass]="{'external': conversation.fromRecipient.isExternal}">{{conversation.fromRecipient.displayName}} </div>
                            <div class="mr-auto fs-14 collapsedContent">{{conversation.viewContent}}</div>
                            <div class="mr-2 fs-10">{{conversation.modDate}}</div>
                        </div>
                    </div>
                    <!--  Collapse view of message for Mobile  *****  Jira : C153176-4476  : Mobile: Conversation View  : Mail Details View Page  -->
                    <div class="d-flex detail-view-body-container-mobile mt-3"
                     *ngIf="((!conversation.isExpandedView)  && i === totalConversations-1 || i === totalConversations-2) && isMobile">
                        <div class="d-flex align-items-center mob-width87" [ngClass]="{'external-recipient': conversation.fromRecipient.isExternal}">
                            <div class="profile-icon-collapse">
                                <app-image-placeholder [recipient]="conversation.fromRecipient"></app-image-placeholder>
                            </div>
                            <div class="mr-auto fs-14" [ngClass]="{'external': conversation.fromRecipient.isExternal}">{{conversation.fromRecipient.displayName}} </div>
                            <div class="mr-auto fs-14 collapsedContent">{{conversation.viewContent}}</div>
                        </div>
                        <!--Date Time -->
                        <div class="ml-auto mr-2 fs-10 mob-width11">
                            <!-- Jira : 4929 : Date is not coming -->
                            <span>{{conversation.modDate1}}</span><br/>
                            <span>{{conversation.modDate2}}</span>
                        </div>
                    </div>
                </ng-template>
            </ng-template>
        </div>

        <!-- pRINTcODE -->
        <!-- html pagination is disable and eve if  html pagination is enabled and conversaion count is lesst than minConvCountForConvPagination use old print logic-->
        <div class="print" *ngIf="(!htmlPaginationEnabled)">
            <ng-template ngFor let-conversation [ngForOf]="conversationList" let-i="index">
                <!-- Expanded view of message -->
                <div class="detail-view-body-container mt-3">
                    <div class="d-flex bdr-btm pb-2" [ngClass]="{'external-recipient': conversation.fromRecipient.isExternal}">
                        <div class="profile-icon">
                            <app-image-placeholder [recipient]="conversation.fromRecipient"></app-image-placeholder>
                        </div>
                        <div class="mr-auto pt-2 break-Work">
                            <div class="fs-12">{{conversation.from}}</div>
                            <div class="fs-10-tocc">To: {{conversation.to}}</div>
                            <div class="fs-10-tocc" *ngIf='conversation.cc !== ""'>CC: {{conversation.cc}}</div>
                            <div class="fs-10-tocc" *ngIf='conversation.bcc !== ""'>BCC: {{conversation.bcc}}</div>
                        </div>
                        <div class="d-flex flex-column">
                            <!-- Mail Actions -->
                            <div #replyPop class="d-flex justify-content-end pt-2 rply-mr">
                                <div class="mr-3 hidden">
                                    <img [src]="'assets/Inbox/reply.svg'" alt="">
                                </div>
                                <div class="mr-3 hidden">
                                    <img [src]="'assets/Inbox/reply_all.svg'" alt="">
                                </div>
                                <div class="mr-3 hidden">
                                    <img [src]="'assets/Inbox/reassign.svg'" alt="">
                                </div>
                                <div class="mr-2 ellipsis-icon hidden">
                                    <img [src]="'assets/Inbox/ellipsis_icon_selected_u101.svg'" alt="">
                                    <!-- To show options for Reply in the conversations -->
                                </div>
                            </div>
                            <!--Attachment -->
                            <div #attachOverlay class="d-flex justify-content-end align-items-center mt-2 mb-2 attach-position" *ngIf="conversation.attachmentCount>0">
                                <div class="pr-1 align-self-end fs-11">
                                    <span *ngIf="conversation.attachmentCount == 1 && conversation.attachments[0]" title="{{conversation.attachments[0].name}}">
                                            {{ (conversation.attachments[0].name.length>14) ? (conversation.attachments[0].name | slice:0:14)+'...':(conversation.attachments[0].name) }}
                                        </span>
                                </div>
                                <div class="pr-2 attach-icon" *ngIf="conversation.attachmentCount > 1">
                                    <img [src]="'assets/Inbox/attachments_icon_selected_u107.svg'" alt="">
                                    <span class="fs-11"> {{conversation.attachmentCount}}</span>
                                </div>
                                <div class="ellipsis-icon">
                                    <img class="opt-mr" [src]="'assets/Inbox/ellipsis_icon_selected_u101.svg'" alt="" (click)="showAttachments($event, attachmentOptions, conversation.attachments)">
                                    <!-- To show the list og attachments for the conversation -->
                                </div>
                            </div>
                            <!--Date Time -->
                            <div class="ml-auto mr-2 mt-auto fs-10">{{conversation.modDate}}</div>
                            <div class="ml-auto mr-2 mt-auto fs-10">ID : {{conversation.inquiryId}}</div>
                        </div>

                    </div>


                    <div class="d-flex flex-column ml-5" [ngClass]="{'msg-content':inboxViewMode === 'vertical','msg-content-horizontal-view':inboxViewMode === 'horizontal'}">
                        <div [innerHTML]="conversation.contentExpanded | safeHtml"> </div>
                    </div>
                </div>
            </ng-template>
        </div>
        <!-- END CODE PRINT -->
        <!-- if html pagination is enabled use new Logic for Print -->
        <div class="print" *ngIf="(htmlPaginationEnabled)">
            <ng-template ngFor let-conversation [ngForOf]="conversationList" let-i="index">
                <!-- Expanded view of message -->
                <div class="detail-view-body-container mt-3">
                    <div class="d-flex bdr-btm pb-2" [ngClass]="{'external-recipient': conversation.fromRecipient.isExternal}">
                        <div class="profile-icon">
                            <app-image-placeholder [recipient]="conversation.fromRecipient"></app-image-placeholder>
                        </div>
                        <div class="mr-auto pt-2 break-Work">
                            <div class="fs-12">{{conversation.from}}</div>
                            <div class="fs-10-tocc">To: {{conversation.to}}</div>
                            <div class="fs-10-tocc" *ngIf='conversation.cc !== ""'>CC: {{conversation.cc}}</div>
                            <div class="fs-10-tocc" *ngIf='conversation.bcc !== ""'>BCC: {{conversation.bcc}}</div>
                        </div>
                        <div class="d-flex flex-column">
                            <!-- Mail Actions -->
                            <div #replyPop class="d-flex justify-content-end pt-2 rply-mr">
                                <div class="mr-3 hidden">
                                    <img [src]="'assets/Inbox/reply.svg'" alt="">
                                </div>
                                <div class="mr-3 hidden">
                                    <img [src]="'assets/Inbox/reply_all.svg'" alt="">
                                </div>
                                <div class="mr-3 hidden">
                                    <img [src]="'assets/Inbox/reassign.svg'" alt="">
                                </div>
                                <div class="mr-2 ellipsis-icon hidden">
                                    <img [src]="'assets/Inbox/ellipsis_icon_selected_u101.svg'" alt="">
                                    <!-- To show options for Reply in the conversations -->
                                </div>
                            </div>
                            <!--Attachment -->
                            <div #attachOverlay class="d-flex justify-content-end align-items-center mt-2 mb-2 attach-position" *ngIf="conversation.attachmentCount>0">
                                <div class="pr-1 align-self-end fs-11">
                                    <span *ngIf="conversation.attachmentCount == 1 && conversation.attachments[0]" title="{{conversation.attachments[0].name}}">
                                            {{ (conversation.attachments[0].name.length>14) ? (conversation.attachments[0].name | slice:0:14)+'...':(conversation.attachments[0].name) }}
                                        </span>
                                </div>
                                <div class="pr-2 attach-icon" *ngIf="conversation.attachmentCount > 1">
                                    <img [src]="'assets/Inbox/attachments_icon_selected_u107.svg'" alt="">
                                    <span class="fs-11"> {{conversation.attachmentCount}}</span>
                                </div>
                                <div class="ellipsis-icon">
                                    <img class="opt-mr" [src]="'assets/Inbox/ellipsis_icon_selected_u101.svg'" alt="" (click)="showAttachments($event, attachmentOptions, conversation.attachments)">
                                    <!-- To show the list og attachments for the conversation -->
                                </div>
                            </div>
                            <!--Date Time -->
                            <div class="ml-auto mr-2 mt-auto fs-10">{{conversation.modDate}}</div>
                            <div class="ml-auto mr-2 mt-auto fs-10">ID : {{conversation.inquiryId}}</div>
                        </div>
                    </div>
                    <div class="d-flex flex-column ml-5" [ngClass]="{'msg-content':inboxViewMode === 'vertical','msg-content-horizontal-view':inboxViewMode === 'horizontal'}">
                        <div *ngIf="conversation.contentToPrint"  [innerHTML]="conversation.contentToPrint | safeHtml"></div>
                    </div>
                </div>
            </ng-template>
        </div>
        <!-- Quick reply Component -->
        <newMessageview-component class="inlineReplyComponent" [class.shrinked]="inlineComponentShrinked" #inlineCompRef *ngIf="visibleQuickReply"
            [fromNewMessage]="false" [count]="'0'" [messageInstance]="quickReplyActionName" [showContactSidebar]="false" [isInlineReply]="true"
            [singleContact]="singleContact" (cancelInlineReply)="onQuickReplyCancel($event)" (expandEvent)="onExpandEvent($event)"
            [inlineViewMode]="inboxViewMode" (newFormData)="onNewMessageDataChanged($event)" (inlineReplySendData)=onNewMessageSend($event)
            (inlineReplyScheduledData)="onInlineReplyScheduledData($event)" [skip_focus]="true"
            [inboxView] = "inboxView">
            <!-- C153176-4475: show placeholder text-->
        </newMessageview-component>
        <!-- quick reply component end -->

    </div>
</div>

<!-- To show options for the conversations -->
<p-overlayPanel [styleClass]="'conv-overlay-options'" #conversationOptions [appendTo]="'body'">
    <p-listbox [options]="options" (click)="onAction($event, conversationOptions)">
        <ng-template let-conOpt pTemplate="item">
            <div class="opt-items"> {{conOpt.name}}</div>
        </ng-template>
    </p-listbox>
</p-overlayPanel>

<div *ngIf="!selectedMail" class="detail-view-no-message">
    <div>
        Select an item to read
    </div>

</div>

<!-- C153176-4910: conversation loading in progress -->
<div *ngIf="loadingMailInProgress" class="detail-view-loading">
</div>
<p-dialog #nlpPopup [(visible)]="displaySuggestionBox" [modal]="true" [responsive]="true"
    [appendTo]="'body'"
    [closable]="true" [dismissableMask]="true"  [baseZIndex]="10000" [closeOnEscape]="true"
    [style]="{width: '80vw','max-height': '90vh',overflow: 'auto'}"
    [draggable]="true"
    [positionTop]="50"
    [styleClass]="'nlp-modal-pop-up'" header="Auto Response Suggestion">
    <app-nlp-view (replyAll)="replyConvSuggestions($event)" (msgGrowl)="showMessageGrowl($event)" (closeSuggestion)="closeSuggestionModel($event)"
        (noSuggestion)="onNoSugesstion($event)" (nodeAdded)="onNoteAdd($event)">
    </app-nlp-view>
</p-dialog>

<!-- C153176-6021 : When no NLP suggestion is available-->
<p-dialog [(visible)]="displayError" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'"
[styleClass]="'blacklistModel'"
    [closable]="false" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" [style]="{width: '30%'}">
    <p-header>
        <div class="d-flex flex-row align-items-center justify-content-between">
            <h6 class="success-title">Information</h6>
            <button type="button" (click)="closeModal('error')" class="d-flex flex-row close qma-text">
            <img src="assets/Inbox/close_icon_u73.svg" alt="Close">
            <h6>CLOSE</h6>
        </button>
        </div>
    </p-header>
    <div class="success-msg" [innerHTML]="contentNotAvlbelMsg"></div>
</p-dialog>

<!-- C153176-4955: adjust attachment viewer size in mobile -->
<app-attachment-dialog #attachvwr [isMobile]="isMobile"></app-attachment-dialog>
<app-action-popup></app-action-popup>
<reject-inquiry></reject-inquiry>
<app-resolve-inquiry></app-resolve-inquiry>

<!-- CLC Suggestion Content -->
<app-clc-suggestion *ngIf="clcSuggestionRequest" [clcSuggestionRequest]='clcSuggestionRequest' (clcSuggestionReplied)="replyClcConvSuggestions($event)">
</app-clc-suggestion>

<!-- C170665-51 | QMA Auto assignment : assign ownership without approval -->
<app-auto-assign *ngIf="autoRoutedWorkflows" [autoRoutedWorkflows]='autoRoutedWorkflows' [inquiryId]="autoAssignInquiry"
    (msgGrowl)="showMessageGrowl($event)">
</app-auto-assign>
<p-dialog [visible]="!ismailboxGridSelectedInqAndDetailviewinInqInSynch" [styleClass]="'mail-detailview-service-error'"
[style]="{'font-size': '12px !important','font-family':'InterstateLight !important','color': '#002d72 !important','text-transform': 'none !important'}" [closable]="false">

<p-header>
    <div class="d-flex flex-row align-items-center justify-content-between mx-2">
      <h6 class="ownership-title">Inquiry selected in grid not matching with detail view please change selection and try again</h6>
      <button type="button" (click)="hideErrorModal()" class="d-flex flex-row close qma-text">
        <img src="assets/Inbox/close_icon_u73.svg" alt="Close">
        <h6>CLOSE</h6>
      </button>
    </div>
  </p-header>
</p-dialog>

<p-dialog #cidPopup [(visible)]="displayCustomerIntent" [modal]="true" [responsive]="true"
    [appendTo]="'body'"
    [closable]="true" [dismissableMask]="true"  [baseZIndex]="10000" [closeOnEscape]="true"
    [style]="{width: '900px','max-height': '90vh',overflow: 'auto'}"
    [draggable]="true"
    [positionTop]="50"
    [styleClass]="'nlp-modal-pop-up'" header="Client Intent Feedback" (onHide)="cancelClientIntentFeedback()">
    <app-cid-view (closeCustomerIntentModal)="closeCustomerIntentModal($event)"></app-cid-view>
</p-dialog>
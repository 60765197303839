import { Component, OnInit,Input  } from '@angular/core';
import { SearchListComponent} from 'src/app/core/search-list/search-list.component';

@Component({
  selector: 'app-search-list-contextmenu',
  templateUrl: './contextmenu.component.html',
  styleUrls: ['./contextmenu.component.scss']
})
export class ContextMenuComponent implements OnInit {

  @Input() x=0;
  @Input() y=0;
  @Input() target:string ="";

  constructor(private parentComponent: SearchListComponent) {
  }

  ngOnInit() {
  }

  menuItemClick(event) {
      if (event.currentTarget) {
        event.currentTarget.id = this.target;
      } else if (event.srcElement) {
        event.srcElement.id = this.target;
      }
      this.parentComponent.itemSelected(event);
  }
}

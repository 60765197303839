<p-dialog [(visible)]="display" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'"
  [styleClass]="'contextMenuModal'" [closable]="false" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true"
  [style]="{'max-width':'700px','width':'70%'}">

  <p-header>
    <div class="d-flex flex-row align-items-center justify-content-between mx-2 mt-1">
      <h6 class="linkExc-title">LINK EXCEPTION ID</h6>
      <button type="button" (click)="hideModal()" class="d-flex flex-row close qma-text">
        <img src="assets/Inbox/close_icon_u73.svg" alt="Close">
        <h6>CLOSE</h6>
      </button>
    </div>
  </p-header>
  <div class="linkExc-body mx-2">
    <form #linkExceptionForm="ngForm">
      <div class="d-flex flex-row align-items-center input-holder">
        <div class="label-text" for="linkExcept">Link exception ID:</div>
        <div class="d-flex flex-row align-items-center ml-2">
          <input required type="number" maxlength="15" id="linkExcept" name="linkExceptionModel" [(ngModel)]="linkExceptionModel"
            #exceptionInput="ngModel">          
        </div>
        <div class="form-control-feedback text-danger pl-1" *ngIf="exceptionInput.invalid && (exceptionInput.dirty || exceptionInput.touched )">
            <p class="text-danger" *ngIf="exceptionInput.errors.required">*Input is required</p>
            <p class="text-danger" *ngIf="exceptionInput.errors.maxlength">*Exception Id cannot exceed 15 characters.</p>
          </div>
      </div>
    </form>
  </div>
  <p-footer>
    <div class="d-flex flex-row flex-wrap bottom-button-holder px-3 pb-2 pt-3 justify-content-end">
      <button type="submit" class="btn search-btn mr-4 mobile-btn" (click)="saveEnquirySearch(linkExceptionForm)">Submit</button>
      <button type="reset" class="btn clear-btn mobile-btn" (click)="linkExceptionForm.reset()">Reset</button>
    </div>
  </p-footer>
</p-dialog>
import { Component, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter } from '@angular/core';
import { InboxService } from 'src/app/services/inbox.service';

@Component({
  selector: 'app-auto-assign',
  templateUrl: './auto-assign.component.html',
  styleUrls: ['./auto-assign.component.scss']
})
export class AutoAssignComponent implements OnInit , OnChanges{
  
  @Input() autoRoutedWorkflows: any[] = [];
  @Input() inquiryId: number;
  @Output() msgGrowl = new EventEmitter();

  // Suggestion Popup
  display: boolean = false;
  autoRoutedGroupIds: number[] = [];
  autoRouteGroups: any[] = [];

  constructor(private inboxService: InboxService) { }

  ngOnInit() {  }

  ngOnChanges(changes: SimpleChanges): void {
    this.autoRoutedGroupIds = [];
    if (changes.autoRoutedWorkflows.currentValue) {
      this.getAutoRouteGroups(changes.autoRoutedWorkflows.currentValue);
      this.display = true;
    }
  }

  /**
   * Method to get the Auto Route Groups.
   * 
   * @param autoRoutedWorkflows 
   */
  getAutoRouteGroups(autoRoutedWorkflows: any[]): void {
    try {
      this.autoRouteGroups = [];
      autoRoutedWorkflows.forEach(wf => {
        for (const [key, value] of Object.entries(wf)) {
          this.autoRouteGroups.push({ 'value': key, 'label': value });
        }
      });
    }
    catch (e) {
      console.error("Exception in QMA 2 while getAutoRouteGroups() : ", e);
    }
  }

  /**
   * Method to re-assign the auto route work flows.
   * 
   * @param autoRoutedGroupIds 
   * @param inquiryId 
   */
  onReAssign(autoRoutedGroupIds: number[], inquiryId: number): void {
    try {
      let request = {
        "inquiryId": inquiryId,
        "autoRoutedGroupIds": autoRoutedGroupIds
      };
      this.inboxService.reassignAutoAssignedWorkflows(request).subscribe(response => {
        if (response && response.Success) {
          this.closeModal();
          this.msgGrowl.emit({ severity: 'success', summary: '', detail: response.message });
        }
      }, error => {
        console.error('AutoAssignComponent, error while getting response from reassignAutoAssignedWorkflows()');
      });
    } catch (e) {
      console.error("Exception in QMA 2 while onReAssign() : ", e);
    }
  }

  /**
   * Method to close the modal
   */
  closeModal() {
    this.display = false;
  }
}

import { Injectable } from '@angular/core';
import { HttpHeaders } from "@angular/common/http";
import { HttpClient } from "@angular/common/http";
import { QmaConstant } from "src/app/constant/qma-constant";
import { LinkedTradeRequest } from "src/app/model/clc/LinkedTradeRequest";
import { Observable,BehaviorSubject } from "rxjs";
import { CLCSearchRequest } from "src/app/model/clc/CLCSearchRequest";
import { LinkTradesToInquiryRequest } from "src/app/model/clc/LinkTradesToInquiryRequest";

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  })
};

@Injectable({
  providedIn: 'root'
})
export class ClcService {
  userURL: string;
  inquiryURL: string;

  constructor(private http: HttpClient) {
    this.userURL = QmaConstant.BASE_URL + '/qmaclc';
  }
  /**
   * Method to clcSearchAll
   * 
   * @param 
   */
  clcSearchAll(clcSearchRequest: CLCSearchRequest): Observable<any> {
    return this.http.post(this.userURL + '/clcSearchAll', clcSearchRequest, httpOptions);
  }
   /**
   * Method to linkTradesToInquiry
   * 
   * @param LinkTradesToInquiryRequest
   */
  linkTradesToInquiry(linkTradesToInquiryRequest: LinkTradesToInquiryRequest): Observable<any> {
    return this.http.post(this.userURL + '/linkTradesToInquiry', linkTradesToInquiryRequest, httpOptions);
  }

 /**
   * Method to unlinkTradesToInquiry
   * 
   * @param LinkTradesToInquiryRequest
   */
  unlinkTradesToInquiry(unlinkTradesToInquiryRequest: LinkTradesToInquiryRequest): Observable<any> {
    return this.http.post(this.userURL + '/unlinkTradesFromInquiry', unlinkTradesToInquiryRequest, httpOptions);
  }
  /**
   * Method to getLinkedTradesToInquiry
   * 
   * @param inquiryId and groupId
   */
  getLinkedTradesToInquiry(linkedTradeRequest: LinkedTradeRequest): Observable<any> {
    return this.http.post(this.userURL + '/getLinkedTradesToInquiry', linkedTradeRequest, httpOptions);
  }

  /**
   * Method to getClcConfigForUI
   * 
   * @param 
   */
  getClcConfigForUI(): Observable<any> {
    return this.http.get(this.userURL + '/getClcConfigForUI', httpOptions);
  }

 
}

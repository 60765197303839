import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import { GridOptions, Module } from "@ag-grid-community/core";
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { NewMessageService } from 'src/app/services/newMessage/new-message.service';
import { UserDataService } from 'src/app/services/user-data.service';
import { PiwikProUtils } from 'src/app/common/utility/piwikProUtils';
@Component({
  selector: 'app-corp-add-book',
  templateUrl: './corp-add-book.component.html',
  styleUrls: ['./corp-add-book.component.scss']
})
export class CorpAddBookComponent implements OnInit {
  searchForm: FormGroup;
  selectedSeachOption = "dl";
  modules: Module[] = [ClientSideRowModelModule];
  public dlDataColumn: any = [];
  public userDetailsDataColumn: any = [];
  public dlList: any = [];
  public userDetailsList: any = [];
  public searchByDLFlag: boolean = false;
  public searchByUserDetailsFlag: boolean = false;
  public errorMsg: string = '';
  public displayError: boolean = false;
  public gridApiDLSearch;
  public gridColumnApiDLSearch;
  public gridApiUserDetailsSearch;
  public gridColumnApiUserDetailsSearch;
  public showSpinner = false;
  public disablePaginationNextLast: boolean = false;
  public disablePaginationPrevFirst: boolean = false;
  public lbTotalRecords: number = 0;
  public lbPageSize: number = 0;
  public lbCurrentPage: number = 0;
  public lbTotalPages: number = 0;

  public disablePaginationUDContactsNextLast: boolean = false;
  public disablePaginationUDContactsPrevFirst: boolean = false;
  public lbTotalRecordsUDContacts: number = 0;
  public lbPageSizeUDContacts: number = 0;
  public lbCurrentPageUDContacts: number = 0;
  public lbTotalPagesUDContacts: number = 0;
  @Input()
  public toCcBcc: string = '';
  @Output() cmcContactDataCorporate = new EventEmitter<{ selectedContacts: any, toCcBcc: any }>();
  @Output() cmcCrptSearchError = new EventEmitter();
  dlDefaultDataColumn: any
  userDefaultDetailsDataColumn: any;
  constructor(private userDataService: UserDataService, private newMessageService: NewMessageService, private fb: FormBuilder) { }

  ngOnInit() {
    this.searchForm = this.fb.group({
      searchBy: ['dl'],
      dlName: ['', Validators.required],
      firstName: [''],
      lastName: ['']
    });
    this.dlDefaultDataColumn = {
      resizable: true
    }
    this.userDefaultDetailsDataColumn = {
      resizable: true
    }
    this.dlDataColumn = [
      {
        field: "name",
        headerName: 'NAME',
        width: 500,
        headerCheckboxSelection: true,
        checkboxSelection: true
      },
      {
        field: "email",
        headerName: 'EMAIL ID',
        width: 250
      }
    ];
    this.userDetailsDataColumn = [
      {
        field: "name",
        headerName: 'Name',
        width: 300,
        headerCheckboxSelection: true,
        checkboxSelection: true
      },
      {
        field: "department",
        headerName: 'Department',
        width: 200,
      },
      {
        field: "email",
        headerName: 'Email',
        width: 200,
      },
      {
        field: "phone",
        headerName: 'Phone',
        width: 200,
      },
      {
        field: "country",
        headerName: 'Location',
        width: 200,
      }
    ]
  }
  searchByClick(option) {

    this.selectedSeachOption = option;
    this.resetCorporateSearch();
  }
  isFieldInValid(field: string) {
    return !this.searchForm.get(field).valid && this.searchForm.get(field).touched;
  }

  // C153176-5786 | Corporate address book : Last name search does not work
  isBothFieldsInValid(field1: string, field2: string) {
    return this.isNull(this.searchForm.get(field1).value) && this.isNull(this.searchForm.get(field2).value);
  }

  isNull(field: string): boolean {
    return (field == null || field == "" || field == undefined);
  }

  searchByDL() {

    const control = this.searchForm.get('dlName');
    control.markAsTouched({ onlySelf: true });
    if (this.isFieldInValid('dlName')) {
      return;
    }
    this.showSpinner = true;
    console.debug(":SPINNER:: " + this.showSpinner + " ::corp-add-book.searchByDL");
    //let request = { "dlName": control.value };
    let request = { "dlName": encodeURIComponent(control.value) };
    this.dlList = [];
    let qmaContactList = [];
    // this.searchByDLFlag = true;
    this.newMessageService.getDLDataByName(request).subscribe(result => {
      qmaContactList = [...result];
      qmaContactList.forEach(route => {
        let obj: any = {};
        obj.name = route.name;
        obj.email = route.email;
        this.dlList.push(obj);
      });

      if (qmaContactList.length === 0) {
        this.searchByDLFlag = false;
        this.errorMsg = "No contacts found.";
        this.displayError = true;
      } else {
        console.log('routesList:', this.dlList);
        this.gridApiDLSearch.applyTransaction(this.dlList);
        this.searchByDLFlag = true;
      }
      this.showSpinner = false;
      console.debug(":SPINNER:: " + this.showSpinner + " ::corp-add-book.searchByDL");
    }, error => {
      this.showSpinner = false;
      console.debug(":SPINNER:: " + this.showSpinner + " ::corp-add-book.searchByDL");
      // throw new Error('Failed to fetch the getDLDataByName records' + error);
      this.cmcCrptSearchError.emit("Corporate Address Data server call failed");
    });
    PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled, this.userDataService.loggedInUserInfo.userId, "Menu", "New Message - Corporate Address Book - Search by DL", "Click", "New Message - Corporate Address Book - Search by DL", 0);
  }
  searchByUserDetails() {

    const controlfirstName = this.searchForm.get('firstName');
    controlfirstName.markAsTouched({ onlySelf: true });
    const controlLastName = this.searchForm.get('lastName');
    controlLastName.markAsTouched({ onlySelf: true });

    controlfirstName.valueChanges.subscribe(x => {    //for 5798
      this.displayError = false;
    });
    controlLastName.valueChanges.subscribe(x => {
      this.displayError = false;
    });
    if (this.isBothFieldsInValid('firstName', 'lastName')) {
      return;
    }
    this.showSpinner = true;
    console.debug(":SPINNER:: " + this.showSpinner + " ::corp-add-book.searchByUserDetails");
    let request = { "lastName": controlLastName.value, "firstName": controlfirstName.value };

    this.userDetailsList = [];
    let qmaContactList = [];

    this.newMessageService.getUserDataByName(request).subscribe(result => {
      qmaContactList = [...result];
      qmaContactList.forEach(route => {
        let obj: any = {};
        obj.name = route.name;
        obj.department = route.department;
        obj.email = route.email;
        obj.longDesc = route.longDesc;
        obj.phone = route.phone;
        obj.country = route.country;
        this.userDetailsList.push(obj);
      });

      if (qmaContactList.length === 0) {
        this.searchByUserDetailsFlag = false;
        this.errorMsg = "No contacts found.";
        this.displayError = true;
      } else {
        this.gridApiUserDetailsSearch.applyTransaction(this.userDetailsList);
        this.displayError = false;    //for 5798
        this.searchByUserDetailsFlag = true;
      }
      this.showSpinner = false;
      console.debug(":SPINNER:: " + this.showSpinner + " ::corp-add-book.searchByUserDetails");
    }, error => {
      this.showSpinner = false;
      console.debug(":SPINNER:: " + this.showSpinner + " ::corp-add-book.searchByUserDetails");
      this.cmcCrptSearchError.emit("Corporate Address Data server call failed");
    });
    console.log('qma error message status is:' + this.displayError);
    PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled, this.userDataService.loggedInUserInfo.userId, "Menu", "New Message - Corporate Address Book - Search by User Details", "Click", "New Message - Corporate Address Book - Search by User Details", 0);
  }

  onPaginationChangedDL() {
    if (this.gridApiDLSearch) {
      this.lbTotalRecords = this.gridApiDLSearch.paginationGetRowCount();
      this.lbPageSize = this.gridApiDLSearch.paginationGetPageSize();
      this.lbCurrentPage = this.gridApiDLSearch.paginationGetCurrentPage() + 1;
      this.lbTotalPages = this.gridApiDLSearch.paginationGetTotalPages();

      let currentPage = this.gridApiDLSearch.paginationGetCurrentPage() + 1;
      let lastPage = this.gridApiDLSearch.paginationGetTotalPages();
      if (currentPage == lastPage) {
        this.disablePaginationNextLast = true;
      }
      else {
        this.disablePaginationNextLast = false;
      }

      if (currentPage == 1) {
        this.disablePaginationPrevFirst = true;
      }
      else {
        this.disablePaginationPrevFirst = false;
      }

      if (this.lbPageSize > this.lbTotalRecords) {
        this.lbPageSize = this.lbTotalRecords;
      }
      else {
        this.lbPageSize = this.gridApiDLSearch.paginationGetPageSize();
      }
    }
  }
  onPaginationChangedUserDetails() {
    if (this.gridApiUserDetailsSearch) {
      this.lbTotalRecordsUDContacts = this.gridApiUserDetailsSearch.paginationGetRowCount();
      this.lbPageSizeUDContacts = this.gridApiUserDetailsSearch.paginationGetPageSize();
      this.lbCurrentPageUDContacts = this.gridApiUserDetailsSearch.paginationGetCurrentPage() + 1;
      this.lbTotalPagesUDContacts = this.gridApiUserDetailsSearch.paginationGetTotalPages();

      let currentPage = this.gridApiUserDetailsSearch.paginationGetCurrentPage() + 1;
      let lastPage = this.gridApiUserDetailsSearch.paginationGetTotalPages();
      if (currentPage == lastPage) {
        this.disablePaginationUDContactsNextLast = true;
      }
      else {
        this.disablePaginationUDContactsNextLast = false;
      }

      if (currentPage == 1) {
        this.disablePaginationUDContactsPrevFirst = true;
      }
      else {
        this.disablePaginationUDContactsPrevFirst = false;
      }

      if (this.lbPageSizeUDContacts > this.lbTotalRecordsUDContacts) {
        this.lbPageSizeUDContacts = this.lbTotalRecordsUDContacts;
      }
      else {
        this.lbPageSizeUDContacts = this.gridApiUserDetailsSearch.paginationGetPageSize();
      }
    }
  }

  onBtNext() {
    this.gridApiDLSearch.paginationGoToNextPage();
  }
  onBtPrevious() {
    this.gridApiDLSearch.paginationGoToPreviousPage();
  }
  onBtLast() {
    //console.log("here");
    this.gridApiDLSearch.paginationGoToLastPage();
  }
  onBtFirst() {
    this.gridApiDLSearch.paginationGoToFirstPage();
  }


  onBtNextUDContacts() {
    this.gridApiUserDetailsSearch.paginationGoToNextPage();
  }
  onBtPreviousUDContacts() {
    this.gridApiUserDetailsSearch.paginationGoToPreviousPage();
  }
  onBtLastUDContacts() {
    //console.log("here");
    this.gridApiUserDetailsSearch.paginationGoToLastPage();
  }
  onBtFirstUDContacts() {
    this.gridApiUserDetailsSearch.paginationGoToFirstPage();
  }


  onGridReadyDL(params) {
    this.gridApiDLSearch = params.api;
    this.gridColumnApiDLSearch = params.columnApi;
    params.api.sizeColumnsToFit();

  }
  onGridReadyUserDetails(params) {
    this.gridApiUserDetailsSearch = params.api;
    this.gridColumnApiUserDetailsSearch = params.columnApi;
    params.api.sizeColumnsToFit();

  }
  selectContactsDL() {
    let selectedRows = this.gridApiDLSearch.getSelectedRows();
    let selectedContacts = [];
    selectedRows.forEach(row => {
      selectedContacts.push({ text: row.name, value: row.email });
    });
    this.cmcContactDataCorporate.emit({ selectedContacts: selectedContacts, toCcBcc: this.toCcBcc });
    /* this.searchByDL = false;
    this.display = false; */
  }

  selectContactsUserDetsils() {
    let selectedRows = this.gridApiUserDetailsSearch.getSelectedRows();
    let selectedContacts = [];
    selectedRows.forEach(row => {
      selectedContacts.push({ text: row.name, value: row.email });
    });
    this.cmcContactDataCorporate.emit({ selectedContacts: selectedContacts, toCcBcc: this.toCcBcc });
    /* this.searchByDL = false;
    this.display = false; */
  }
  resetCorporateSearch() {
    this.searchByDLFlag = false;
    this.searchByUserDetailsFlag = false;
    this.dlList = [];
    this.gridApiDLSearch.applyTransaction(this.dlList);
    this.userDetailsList = [];
    this.gridApiUserDetailsSearch.applyTransaction(this.userDetailsList);
    this.searchForm.get('dlName').setValue('');
    this.searchForm.get('firstName').setValue('');
    this.searchForm.get('lastName').setValue('');

  }



}

<div *ngIf="selectedMail">
  <div class="d-flex flex-row justify-content-between align-items-center conv-header flex-unwrap">
    <div (click)="onBackClick($event)" class="mob-img">
        <img class="backward-icon" src="assets/Inbox/right_chevron_icon.svg" alt="">
    </div>
    <div class="conv-subject">
      <div>{{draft.subject}}</div>
    </div>
    <div class="d-flex align-content-end conv-info">
      <div class="d-flex justify-content-end ml-auto">       
        <div class="mr-4 conv-container">
          <img [src]="'assets/Inbox/new-chat-icon.svg'" alt="Conversation Count">
          <span class="centered" style="color: #002D72;">{{totalConversations}}</span>
        </div>
        <div class="mr-4 coll-expd">
          <img [src]="'assets/Inbox/expand_all_icon_u110.svg'" *ngIf="!isExpanded" (click)="changeMessageView()" alt="Expand">
          <img [src]="'assets/Inbox/collapse_all_icon_u111.svg'" *ngIf="isExpanded" (click)="changeMessageView()" alt="Collapse">
        </div>
        <div class="mr-2 header-option ellipsis-icon">
          <img [src]="'assets/Inbox/ellipsis_icon_u91.svg'" (click)="showOptions($event,conversationOptions)" alt="">
        </div>
      </div>
    </div>
  </div>

  <div class="d-flex flex-column conversation-container" [ngClass]="{'':inboxViewMode === 'vertical', 'horizontal-bar-height':inboxViewMode === 'horizontal'}">
    <!-- Expanded view of message -->
    <div class="detail-view-body-container mt-3">
        <div class="d-flex flex-row conv-schedule" *ngIf='scheduleForLater'>
          <div style="padding-right: 0.75em">
            <img [src]="'assets/newMessage/schedule.svg'" alt="">
          </div>
          <div style="padding-right: 0.50em">
            <label>SCHEDULED: </label>
          </div>
          <div>
            <!-- {{scheduleForLater | date:'dd MMM h:mm a'}} -->
            {{scheduleForLater}}
          </div>
          <div class="ml-auto">
            <button type="button" (click)="cancelSchedule()" class="close qma-text">
                <img src="assets/Inbox/close_icon_u73.svg" alt="Cancel">
                <h6>CANCEL</h6>
              </button>
          </div>
        </div>
        <div class="d-flex bdr-btm imp-indicator" *ngIf="conversation.urgentFlag ==='Y'">
            <div class="imp mr-4 ml-4">!</div>
            <div class="imp-text">Message Sent with the high Importance</div>
        </div>
        <div class="d-flex pb-2">            
            <div class="profile-icon">
                <app-image-placeholder [recipient]="conversation.fromRecipient"></app-image-placeholder>                            
            </div>
          <div class="mr-auto pt-2">
            <div class="fs-12" [ngClass]="{'external': conversation.fromRecipient.isExternal}">
              {{conversation.fromRecipient.displayName}}                
            </div>
            <div class="fs-10-tocc">To:
                <ng-template ngFor let-toRecipients [ngForOf]="conversation.toRecipients" let-idx="index">
                    <span [ngClass]="{'external': toRecipients.isExternal}">{{toRecipients.displayName}}</span>
                    <span *ngIf="idx != conversation.toRecipients.length-1">; </span>
                </ng-template>
                <span class="ml-1"> </span>
                <span class="rec-icon" *ngIf="conversation.ccRecipients?.length > 1 || conversation.bccRecipients?.length > 1">
                    <img [src]="'assets/Inbox/recipient-expand.svg'" *ngIf="!recipientShow" (click)="onManageRecipent()" alt="Expand">
                    <img [src]="'assets/Inbox/recipient-collapse.svg'" *ngIf="recipientShow" (click)="onManageRecipent()" alt="Collapse">
                </span>
            </div>
            <div class="fs-10-tocc" *ngIf='conversation.cc !== "" && recipientShow'>CC:
                <ng-template ngFor let-ccRecipients [ngForOf]="conversation.ccRecipients" let-idx="index">
                    <span [ngClass]="{'external': ccRecipients.isExternal}">{{ccRecipients.displayName}}</span>
                    <span *ngIf="idx != conversation.ccRecipients.length-1">; </span>
                </ng-template>
            </div>
            <div class="fs-10-tocc" *ngIf='conversation.bcc !== "" && recipientShow'>BCC:
                <ng-template ngFor let-bccRecipients [ngForOf]="conversation.bccRecipients" let-idx="index">
                    <span [ngClass]="{'external': bccRecipients.isExternal}">{{bccRecipients.displayName}}</span>
                    <span *ngIf="idx != conversation.bccRecipients.length-1">; </span>
                </ng-template>
            </div>
        </div>
        <div class="d-flex flex-column">          
          <!--Attachment -->
          <div #attachOverlay class="d-flex justify-content-end align-items-center mt-2 mb-2 attach-position"
           *ngIf="conversation.attachmentCount>0" style="display: none!important">
            <div class="pr-1 align-self-end fs-11">
              <span *ngIf="conversation.attachmentCount == 1" title="{{conversation.attachments[0].name}}">
                             {{ (conversation.attachments[0].name.length>14) ? (conversation.attachments[0].name | slice:0:14)+'...':(conversation.attachments[0].name) }}
                         </span>
              <span *ngIf="conversation.attachmentCount > 1" class="download-text" (click)="onDownloadAttachment(conversation.attachments)"> Download All </span>
            </div>
            <div class="pr-2 attach-icon">
              <img [src]="'assets/Inbox/download_icon_u70.svg'" alt="" (click)="onDownloadAttachment(conversation.attachments)">
            </div>
            <div class="pr-2 attach-icon" *ngIf="conversation.attachmentCount > 1">
              <img [src]="'assets/Inbox/attachments_icon_selected_u107.svg'" alt="">
              <span class="fs-11">{{conversation.attachmentCount}}</span>
            </div>
            <div>
              <img class="opt-mr" [src]="'assets/Inbox/ellipsis_icon_selected_u101.svg'" alt="" (click)="showAttachments($event, attachmentOptions, conversation.attachments)">
              <!-- To show the list og attachments for the conversation -->
              <p-overlayPanel [styleClass]="'conv-overlay-options attach-overlay'" #attachmentOptions [appendTo]="attachOverlay">
                  <p-listbox [listStyle]="{'max-height':'120px'}" [options]="attachmentsOptions" (click)="onDownloadAction($event, attachmentOptions)">
                      <ng-template let-attachOpt pTemplate="item">
                          <div class="d-flex flex-row justify-content-between">
                              <div class="d-flex flex-row align-items-end">
                                  <div><img class="notes-icon" [src]="'assets/Inbox/notes_blue.svg'" alt="Notes"></div>
                                  <div class="ml-1 attach-name-opt" title="{{attachOpt.name}}">{{attachOpt.name}}</div>
                              </div>
                              <div class="ml-1"><img class="download-icon" [src]="'assets/Inbox/download_icon_u70.svg'" title="{{attachOpt.name}}" alt="Download"></div>
                          </div>
                      </ng-template>
                  </p-listbox>
              </p-overlayPanel>
            </div>
          </div>
          <!--Date Time -->
          <div class="ml-auto mr-2 mt-auto fs-10">{{conversation.modDate | date:'dd MMM hh:mm a'}}</div>
        </div>
    
      </div>
   <!--  attachement redesign -->
            <!-- attachement panel less or upto 2 -->
            <div [ngClass]="isAttachemntPanelCollapsed ? 'attachement-grid-container-collapsed-view' : 'attachement-grid-container-expanded-view'"
            *ngIf="!isMobile && conversation?.attachments && conversation?.attachments.length>0 && conversation?.attachments.length<=2">
           <div class="container collapsed-view-more-than-two" 
           [ngClass]="{'attchement-grid-select-all-view': isSelectAllAttchClicked,
           'attchement-grid-one-attachement':conversation?.attachments.length === 1,
           'attchement-grid-one-attachement-with-select-all':conversation?.attachments.length === 1 
           && isSelectAllAttchClicked,
           'attchement-grid-two-attachement':conversation?.attachments.length === 2,
           'attchement-grid-two-attachement-with-select-all':conversation?.attachments.length === 2 
           && isSelectAllAttchClicked,
           'collapsed-view-more-than-two':conversation?.attachments.length > 2
          }">
               
               <ng-container *ngFor="let attachment of conversation.attachments;let i = index">
               
                   <div class="attachement-checkbox" *ngIf="i === 0">
                       <input type="checkbox" value="" [checked]="isSelectAllAttchClicked" (change)="isSelectAllAttchClicked = !isSelectAllAttchClicked">
                   </div>
                   <div class="attachement-checkbox" *ngIf="i !== 0 && i%3 === 0">
                           <!-- to create empty area below checkbox from second row onwards-->
                         
                    </div>
                   <div class="attachement-item" (mouseenter)="showAttachments(null, null, conversation?.attachments)"
                   > <!-- *ngIf="i<3" -->
               
                       <div class="box" *ngIf="attachment.showTitle" (mouseenter)="onMouseEnterAttTitle(attachment)" (mouseleave)="onMouseLeaveAttTitle(attachment)">
                           <!-- (mouseenter)="onMouseEnterAttTitle(attachment)" (mouseleave)="onMouseLeaveAttTitle(attachment)" -->
                           <!-- {{i}}  -->
                           <img class="download-icon" [src]="getAttachementImageByExtension(attachment)" alt="Download"/>
                           <div class="attachement-title">
                               {{attachment?.name}}
                           </div>
               
                       </div>
                       <!-- *ngIf="attachment.showMenu" -->
                       <div class="menu" *ngIf="attachment.showMenu" (mouseenter)="onMouseEnterAttMenu(attachment)" (mouseleave)="onMouseLeaveAttMenu(attachment)" ><!-- (mouseleave)="onMouseLeaveAttMenu(attachment)" -->
                           <div class="eye-icon">
                                      <!--  <img class="eye-img" src="assets/Inbox/eye.svg" 
                                       (click)="onViewctionRedesigned(attachment)" title="View"> -->
                            </div>
                            <div class="menu-icons-border"></div>   
                           <div > <!-- [src]="'assets/Inbox/download_icon_u70.svg'" -->
                               <img class="download-icon" [src]="'assets/Inbox/download_icon_u70.svg'" (click)="onDownloadAttachment(attachment)"  title="Download" alt="Download">
                           </div>
                           <div class="menu-icons-border">

                           </div>
                           <div>
                            <!--  <img class="copy-icon" [src]="getAttachementCopyIconRedesigned(attachment)" name="copy"
                                (click)="onCopyActionRedesigned(attachment,conversation.attachments)" title="Copy"> -->
                              </div>
                       </div>
                   </div>
                   <!-- show menu options when select all checkbox is checked -->

                   <div class="attachement-more" *ngIf="conversation?.attachments.length>3 && i === 2 && isAttachemntPanelCollapsed && !isSelectAllAttchClicked" (click)="onAttachementMoreClick()">
                       +({{conversation?.attachments.length-3}}) More 
                   </div>
                   <div class="attachement-more" *ngIf="conversation?.attachments.length>3 && i === 2 && !isAttachemntPanelCollapsed && !isSelectAllAttchClicked" (click)="onAttachementMoreClick()">
                           - Less
                   </div>
                  
                   <div class="attachement-more" *ngIf="(i+1) >3  && (i+1)%3===0">
                           <!-- to create empty area below checkbox from second row onwards-->
                          
                    </div>
                
               </ng-container>
               <div class="attachement-select-all-Menu" 
               *ngIf="(conversation?.attachments.length>3 && isSelectAllAttchClicked) 
                   || (conversation?.attachments.length === 2  && isSelectAllAttchClicked) 
                   || (conversation?.attachments.length === 3  && isSelectAllAttchClicked)
                   || (conversation?.attachments.length === 1  && isSelectAllAttchClicked)">
                   <div *ngIf="conversation?.attachments.length>3 && isAttachemntPanelCollapsed">
                        + ({{conversation?.attachments.length-3}})
                   </div>
                   
             `              <div class="attachement-menu-item">
                                  <!--  <img class="copy-icon" src='assets/Inbox/copy_icon.svg' name="copy"
                                    (click)="onCopyAllAttachment(conversation?.attachments)"> -->  <!-- (click)="onCopyAllAttachment(conversation.attachments)" -->
                           </div>
                           <div class="attachement-menu-item">
                               <img class="download-icon" [src]="'assets/Inbox/download_icon_u70.svg'" 
                               (click)="onDownloadAttachment(conversation?.attachments)" (mouseover)="imgSrc='assets/Inbox/download_icon_light_blue.svg'"
                               (mouseout)="imgSrc='assets/Inbox/download_icon_u70.svg'" alt="Download">
                           </div>
                  
                   
               </div>    
       </div>
       </div>
       <!-- Attachement Panel More than 2 Attachement collapsed View-->
       <div [ngClass]="isAttachemntPanelCollapsed ? 'attachement-grid-container-collapsed-view' : 'attachement-grid-container-expanded-view'"
            *ngIf="!isMobile && conversation?.attachments && conversation?.attachments.length>0 && conversation?.attachments.length>2 && !isSelectAllAttchClicked">
           <div class="container collapsed-view-more-than-two" 
           [ngClass]="{'attchement-grid-select-all-view': isSelectAllAttchClicked,
           'attchement-grid-one-attachement':conversation?.attachments.length === 1,
           'attchement-grid-one-attachement-with-select-all':conversation?.attachments.length === 1 
           && isSelectAllAttchClicked,
           'attchement-grid-two-attachement':conversation?.attachments.length === 2,
           'attchement-grid-two-attachement-with-select-all':conversation?.attachments.length === 2 
           && isSelectAllAttchClicked,
           'collapsed-view-more-than-two':conversation?.attachments.length > 2
          }">
               
               <ng-container *ngFor="let attachment of conversation?.attachments;let i = index">
               
                   <div class="attachement-checkbox" *ngIf="i === 0">
                       <input type="checkbox" value="" [checked]="isSelectAllAttchClicked" (change)="isSelectAllAttchClicked = !isSelectAllAttchClicked">
                   </div>
                   <div class="attachement-checkbox" *ngIf="i !== 0 && i%3 === 0">
                           <!-- to create empty area below checkbox from second row onwards-->
                         
                    </div>
                   <div class="attachement-item" (mouseenter)="showAttachments(null, null, conversation?.attachments)"
                   > <!-- *ngIf="i<3" -->
               
                       <div class="box" *ngIf="attachment.showTitle" (mouseenter)="onMouseEnterAttTitle(attachment)" (mouseleave)="onMouseLeaveAttTitle(attachment)">
                           <!-- (mouseenter)="onMouseEnterAttTitle(attachment)" (mouseleave)="onMouseLeaveAttTitle(attachment)" -->
                           <!-- {{i}}  -->
                           <img class="download-icon" [src]="getAttachementImageByExtension(attachment)" alt="Download" />
                           <div class="attachement-title">
                               {{attachment?.name}}
                           </div>
               
                       </div>
                       <!-- *ngIf="attachment.showMenu" -->
                       <div class="menu" *ngIf="attachment.showMenu" (mouseenter)="onMouseEnterAttMenu(attachment)" (mouseleave)="onMouseLeaveAttMenu(attachment)"><!-- (mouseleave)="onMouseLeaveAttMenu(attachment)" -->
                           <div class="eye-icon">
                                      <!--  <img class="eye-img" src="assets/Inbox/eye.svg" 
                                       (click)="onViewctionRedesigned(attachment)" title="View"> -->
                            </div>
                            <div class="menu-icons-border"></div>     
                           <div > <!-- [src]="'assets/Inbox/download_icon_u70.svg'" -->
                               <img class="download-icon" [src]="'assets/Inbox/download_icon_u70.svg'" (click)="onDownloadAttachment(attachment)" title="Download" alt="Download">
                           </div>
                           <div class="menu-icons-border">

                       </div>
                           <div>
                            <!--  <img class="copy-icon" [src]="getAttachementCopyIconRedesigned(attachment)" name="copy"
                                (click)="onCopyActionRedesigned(attachment,conversation.attachments)" title="Copy"> -->
                              </div>
                       </div>
                   </div>
                   <!-- show menu options when select all checkbox is checked -->

                   <div class="attachement-more" *ngIf="conversation?.attachments.length>3 && i === 2 && isAttachemntPanelCollapsed && !isSelectAllAttchClicked" (click)="onAttachementMoreClick()">
                       +({{conversation?.attachments.length-3}}) More
                   </div>
                   <div class="attachement-more" *ngIf="conversation?.attachments.length>3 && i === 2 && !isAttachemntPanelCollapsed && !isSelectAllAttchClicked" (click)="onAttachementMoreClick()">
                           - Less
                   </div>
                  
                   <div class="attachement-more" *ngIf="(i+1) >3  && (i+1)%3===0">
                           <!-- to create empty area below checkbox from second row onwards-->
                          
                    </div>
                
               </ng-container>
               <div class="attachement-select-all-Menu" 
               *ngIf="(conversation?.attachments.length>3 && isSelectAllAttchClicked) 
                   || (conversation?.attachments.length === 2  && isSelectAllAttchClicked) 
                   || (conversation?.attachments.length === 3  && isSelectAllAttchClicked)
                   || (conversation?.attachments.length === 1  && isSelectAllAttchClicked)">
                   <div *ngIf="conversation?.attachments.length>3 && isAttachemntPanelCollapsed">
                        + ({{conversation?.attachments.length-3}})
                   </div>
                   
                           <div class="attachement-menu-item">
                                   <!-- <img class="copy-icon" src='assets/Inbox/copy_icon.svg' name="copy" 
                                   (click)="onCopyAllAttachment(attachments)"> --> <!-- (click)="onCopyAllAttachment(conversation.attachments)" -->
                           </div>
                           <div class="attachement-menu-item">
                               <img class="download-icon" [src]="'assets/Inbox/download_icon_u70.svg'" 
                               (click)="onDownloadAttachment(attachments)" (mouseover)="imgSrc='assets/Inbox/download_icon_light_blue.svg'"
                               (mouseout)="imgSrc='assets/Inbox/download_icon_u70.svg'" alt="Download">
                           </div>
               </div>    
       </div>
       </div>

       <div [ngClass]="isSelectAllAttchClicked ? 'attachement-grid-container-collapsed-view' : 'attachement-grid-container-expanded-view'"
       *ngIf="!isMobile && conversation?.attachments && conversation?.attachments.length>0 && conversation?.attachments.length>2 && isSelectAllAttchClicked">
      <div class="container collapsed-view-more-than-two" 
      [ngClass]="isSelectAllAttchClicked? 'select-all-view-more-than-two': 'collapsed-view-more-than-two'">
          
          <ng-container *ngFor="let attachment of conversation?.attachments;let i = index">
          
              <div class="attachement-checkbox" *ngIf="i === 0">
                  <input type="checkbox" value="" [checked]="isSelectAllAttchClicked" (change)="isSelectAllAttchClicked = !isSelectAllAttchClicked; isAttachemntPanelCollapsed = true">
              </div>
              <div class="attachement-checkbox" *ngIf="i !== 0 && (i+1)%3 === 0">
                      <!-- to create empty area below checkbox from second row onwards-->
                    
               </div>
              <div class="attachement-item" (mouseenter)="showAttachments(null, null, conversation?.attachments)"
              > <!-- *ngIf="i<3" -->
          
                  <div class="box" *ngIf="attachment.showTitle" (mouseenter)="onMouseEnterAttTitle(attachment)" (mouseleave)="onMouseLeaveAttTitle(attachment)">
                      <!-- (mouseenter)="onMouseEnterAttTitle(attachment)" (mouseleave)="onMouseLeaveAttTitle(attachment)" -->
                      <!-- {{i}}  -->
                      <img class="download-icon" [src]="getAttachementImageByExtension(attachment)" alt="Download"/>
                      <div class="attachement-title">
                          {{attachment?.name}}
                      </div>
          
                  </div>
                  <!-- *ngIf="attachment.showMenu" -->
                  <div class="menu" *ngIf="attachment.showMenu" (mouseenter)="onMouseEnterAttMenu(attachment)" (mouseleave)="onMouseLeaveAttMenu(attachment)"><!-- (mouseleave)="onMouseLeaveAttMenu(attachment)" -->
                      <div class="eye-icon">
                                 <!--  <img class="eye-img" src="assets/Inbox/eye.svg" 
                                  (click)="onViewctionRedesigned(attachment)" title="View"> -->
                       </div> 
                       <div class="menu-icons-border">

                       </div>  
                      <div > <!-- [src]="'assets/Inbox/download_icon_u70.svg'" -->
                          <img class="download-icon" [src]="'assets/Inbox/download_icon_u70.svg'" (click)="onDownloadAttachment(attachment)" title="Download" alt="Download">
                      </div>
                      <div class="menu-icons-border">

                    </div>
                      <div>
                        <!-- <img class="copy-icon" [src]="getAttachementCopyIconRedesigned(attachment)" 
                       name="copy" (click)="onCopyActionRedesigned(attachment,conversation.attachments)" title="Copy"> -->
                      </div>
                  </div>
              </div>
              <!-- show menu options when select all checkbox is checked -->

              <div class="attachement-more" *ngIf="conversation?.attachments.length>3 && i === 2 && isAttachemntPanelCollapsed && !isSelectAllAttchClicked" (click)="onAttachementMoreClick()">
                  +({{conversation?.attachments.length-3}}) More
              </div>
              <div class="attachement-more" *ngIf="conversation?.attachments.length>3 && i === 2 && !isAttachemntPanelCollapsed && !isSelectAllAttchClicked" (click)="onAttachementMoreClick()">
                      - Less
              </div>
             
              <div class="attachement-more" *ngIf="(i+1) >3  && (i+1)%3===0 && !isAttachemntPanelCollapsed && !isSelectAllAttchClicked">
                      <!-- to create empty area below checkbox from second row onwards-->
                     
               </div>
               <div class="attachement-select-all-Menu" 
               *ngIf="i === 1">
                   <div *ngIf="conversation?.attachments.length>2 && isSelectAllAttchClicked" class="pr-3" style="display:flex;align-items: center; height:100%;background-color: white">
                        + ({{conversation?.attachments.length-2}})
                   </div>
                       <div class="d-flex" style="height:100%;">
                           <div class="attachement-menu-item">
                                   <!-- <img class="copy-icon" src='assets/Inbox/copy_icon.svg' name="copy"
                                    (click)="onCopyAllAttachment(conversation?.attachments)"> --> <!-- (click)="onCopyAllAttachment(conversation.attachments)" -->
                           </div>
                           <div class="attachement-menu-item">
                               <img class="download-icon" [src]="'assets/Inbox/download_icon_u70.svg'" 
                               (click)="onDownloadAttachment(conversation?.attachments)" (mouseover)="imgSrc='assets/Inbox/download_icon_light_blue.svg'"
                               (mouseout)="imgSrc='assets/Inbox/download_icon_u70.svg'" alt="Download">
                           </div>
                       </div>
                   
               </div> 
          </ng-container>
            
  </div>
  
  </div>
  <div class="bdr-btm">

    </div>
      <div class="d-flex flex-column ml-5 msg-content">
        <div [innerHTML]="conversation.content | safeHtml"> </div>
      </div>
    </div>
  </div>

</div>

<div *ngIf="!selectedMail" class="detail-view-no-message">
  <div>
      Select an item to read
  </div>
</div>
<!-- To show options for the conversations -->
<p-overlayPanel [styleClass]="'conv-overlay-options'" #conversationOptions [appendTo]="'body'">
    <p-listbox [options]="options" (click)="onAction($event, conversationOptions)">
        <ng-template let-conOpt pTemplate="item">
            <div class="opt-items"> {{conOpt.name}}</div>
        </ng-template>
    </p-listbox>
</p-overlayPanel>
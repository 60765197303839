import { Component } from '@angular/core';
import {DashboardService} from "../../services/dashboard/dashboard.service";

@Component({
  selector: 'app-error-page',
  standalone: true,
  imports: [],
  templateUrl: './error-page.component.html',
  styleUrl: './error-page.component.scss'
})
export class ErrorPageComponent {
  constructor(public dashboardService: DashboardService) {
  }
}

<div class="mobile-inbox-view-wrapper">
  <!-- C153176-5076 refresh spinner -->
  <div [loaderId]="ngxConfig.loaderId" ngxUiLoaderBlurred blur="0" 
  class="mobile-inbox-refresh-panel mobile-collapsible">
    <!--
    <ngx-ui-loader [loaderId]="ngxConfig.loaderId"
              [overlayColor]="ngxConfig.overlayColor"
              [fgsType]="ngxConfig.fgsType"
              [fgsColor]="ngxConfig.fgsColor"
              [fgsSize]="ngxConfig.fgsSize"
              [text]="ngxConfig.text"
              [textColor]="ngxConfig.textColor">
    </ngx-ui-loader>
    -->
    <div class="mobile-inbox-refresh-label">Refreshing...</div> 
  </div>
  <!--C153176-3674 current mailbox and all mailbox tab position fixed-->
  <p-tabView  #searchTabs class="currentMail" [ngClass]="inSearch ? 'mobile-search-tabs' : 'mobile-mailbox-tabs'" [activeIndex]="search.searchTabIndex"
    (onChange)="onSearchTabChange($event);">
    <p-tabPanel *ngFor="let tab of search.searchTabs" [header]="tab.text" [selected]="tab.text == search.selectedSearchTab"
      [headerStyleClass]="'mobile-search-header'">
      <ng-template pTemplate="header">
        <div [ngClass]="'mobile-search-header-text'">
          {{tab.text}}
        </div>
      </ng-template>
      <div #mobileContentView [ngSwitch]="true" [ngClass]="inSearch ? 'mobile-search-active': ''" (scroll)="onScroll($event)">
          <div [ngSwitch]="true" [ngClass]="inSearch ? 'mobile-search-active': ''">
          <div *ngSwitchCase="tab.text.includes('CURRENT MAILBOX')">
              <!--C153176-5043: inSearch flag relayed to item level-->
              <app-mobile-inbox-item *ngIf="(selectedItems.length > 0) && itemSeletionEnabled" [contextMenuOnly]=true
                  [selectedItems]="selectedItems" [inSearch]= "inSearch" (onContextMenu)="onContextMenuOpen($event)" [selectionEnabled]=false
                  [selected]=isSelected [selectedItemCount]="selectedItemCount" [enableAllSlides]="enableAllSlides"> <!-- C153176-4796: right swiping support-->
              </app-mobile-inbox-item>
              <!--C153176-5043: inSearch flag relayed to item level-->
              <app-mobile-inbox-item *ngFor="let item of (inSearch? search.currentRowData : rowData), let itemIndex = index" [item]="item"
                [itemIndex]="itemIndex" (onContextMenu)="onContextMenuOpen($event, item)"
                [selectionEnabled]="itemSeletionEnabled" (onRowSelect)="onRowSelections($event)" [selected]=isSelected
                [enableAllSlides]="enableAllSlides" [inSearch]= "inSearch"><!-- C153176-4796: right swiping support-->
              </app-mobile-inbox-item>
              <app-mobile-context-menu #contextmenu></app-mobile-context-menu>
              <div class="mailbox-footer-hide mailbox-footer d-flex flex-row align-items-end justify-content-between px-4"></div>
          </div>
          <div *ngSwitchCase="tab.text.includes('ALL MAILBOXES')">
              <!--C153176-5043: inSearch flag relayed to item level-->
              <app-mobile-inbox-item *ngIf="(selectedItems.length > 0) && itemSeletionEnabled" [contextMenuOnly]=true [enableAllSlides]="enableAllSlides"
                  [selectedItems]="selectedItems" (onContextMenu)="onContextMenuOpen($event)" [selectionEnabled]=false [selected]=isSelected [inSearch]= "inSearch">
              </app-mobile-inbox-item>
              <!--C153176-5043: inSearch flag relayed to item level-->
              <app-mobile-inbox-item *ngFor="let item of search.allRowData, let itemIndex = index" [item]="item" [itemIndex]="itemIndex" (onContextMenu)="onContextMenuOpen($event, item)" 
                [selectionEnabled]="itemSeletionEnabled" (onRowSelect)="onRowSelections($event)" [selected]=isSelected
                [enableAllSlides]="enableAllSlides" [inSearch]="inSearch" > <!-- C153176-4796: right swiping support-->
              </app-mobile-inbox-item>
              <app-mobile-context-menu #contextmenu></app-mobile-context-menu>
          </div>
      </div>
      </div>
    </p-tabPanel>
  </p-tabView>
</div>
import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { retry, catchError, tap } from "rxjs/operators";
import { InboxService } from './services/inbox.service';
import { AppUtils } from 'src/app/common/utility/appUtil';
import { environment } from 'src/environments/environment';

@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {
  constructor(private inboxService: InboxService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        console.log("Before making api call : ", request);
        switch(request.url){
          case "/QMA2/inquiry/getGridViewData": {
            const startServiceCall = performance.now();
            return next.handle(request).pipe(
              catchError((error: HttpErrorResponse) => {
                if (error.status === 401) {
                  // refresh token
                } else {
                  return throwError(error);
                }
              }),
              tap((res: any) => {
                if (res.status && res.status === 200) {
                  if (!environment.production) {
                    const endServiceCall = performance.now();
                    const responseTime = endServiceCall - startServiceCall;
                    const minSec =  AppUtils.millisToMinutesAndSeconds(responseTime)
                    console.warn("QMA Call to getGridViewData >" + request.body.viewName + " took " + responseTime + " milliseconds.");
                    console.warn("QMA Call to getGridViewData >" + request.body.viewName + " took " + minSec + " Min:Seconds.");
                  }
                  this.inboxService.triggerHttpSuccess(res.status);
                }
              })

            );
          }
          default:{
            const startServiceCall = performance.now();
            if(request.url.includes("XXXXX") && request.url.includes("XXXXX")) {
              // clone request and replace 'http://' with 'https://' at the same time
              const secureReq = request.clone({
                url: request.url.replace('http://', 'https://')
              });
              // send the cloned, "secure" request to the next handler.
                return next.handle(secureReq);  
            }
            return next.handle(request).pipe(
              
              catchError((error: HttpErrorResponse) => {
                if (error.status === 401) {
                  // refresh token
                } else {
                  return throwError(error);
                }
              }),
              tap((res: any) => {
                if (res.status && res.status === 200) {
                  if (!environment.production) {
                    const endServiceCall = performance.now();
                    const responseTime = endServiceCall - startServiceCall;
                    const minSec =  AppUtils.millisToMinutesAndSeconds(responseTime)
                    console.warn("QMA Call to:- "+ request.url +  " took " + responseTime + " milliseconds.");
                    console.warn("QMA Call to:- " + request.url + " took " + minSec + " Min:Seconds.");
                  }
                  this.inboxService.triggerHttpSuccess(res.status);
                }
              })
  
            );  
          }
        }
          
    }
}
<div fileDragEnter class="new-msg-wrapper" #wrapper>

  <div class="mobile-sidebar" style="width: calc(100% - 80px);float:left;border: 1px solid #ccc;">
    <form class="form-horizontal mobile-order3" [formGroup]="newMessageForm">
      <new-message-recipent class=" mobile-order" [parentForm]="newMessageForm" [editorData]="child"
        (notesEvent)="onNote($event, 'new')" (resizeMailBody)="resizeBody($event)" [fromNewMessage]="fromNewMessage"
        [postDataForAttachment]="postDataForAttachmentFileN" (secure)="updateSecure($event)" [resolve]="setResolveN"
        [toCcBccData]="toBccData" [template]="template" [messageInstance]="messageInstance" [pushContact]="pushContact"
        (messageContent)="setMessageData($event)" (conversationObj)="getConversationObj($event)"
        (disclaimerEvent)="onDisclaimerChange($event)" (group)="onGroupChange($event)" [isInlineReply]="isInlineReply"
        (expandEvent)="onExpandEvent($event)" (window:resize)="resize()"
        (newMessageData)="onNewMessageDataChanged($event)" (topContactAddEvent)="onTopContactAdded($event)"
        (recipientUpdateEvent)="onRecipientUpdate($event)" #newMessageRecipent [inboxView]="inboxView"
        (updatePrevAttach)="updatePrevAttach($event)">
      </new-message-recipent>
      <!--  C170665-473 Performance issue - QMA is slow we removed (keyupChange)="editorDataChange()" for performance improvement
        Save draft will not work have to impliment in better possible way -->
      <div class="mobile-order1">
        <editor-app (onLoad)="onEditorLoad()" [elementId]="'my-editor'+countID" [height]="400"
          [ngClass]="formatFlg ? '': 'hideToolbar'" class="my-editor" (inlineImage)="inlineImagePresent($event)"
          [skip_focus]="skip_focus" (onBlur)="editorDataChange()"> </editor-app>
      </div>
      <new-message-actions class=" mobile-order2" [parentForm]="newMessageForm" [editorData]="child"
        [fromNewMessage]="fromNewMessage" (getAttachments)="getAttachments($event)"
        (notesEvent)="onNote($event, 'reply')" [notes]="notesList" [secure]="secure" (setResolve)="setResolve($event)"
        (toBccData)="getToBccData($event)" (formatTemplate)="getTemplate($event)" [messageInstance]="messageInstance"
        [conversationObj]="conversationObj" [group]="group" [isInlineReply]="isInlineReply"
        (cancelInlineReply)="cancelInlineAction()" (inlineReplySend)="onNewMessageSend($event)"
        (inlineReplyScheduled)="onInlineReplyScheduled($event)" [selectedCLCTrades]="selectedCLCTrades"
        [inlineImageAttached]="inlineImage" [recipientUpdate]="recipientUpdated" [updatePrevAttach]="hasPrevAttach">
      </new-message-actions>
      <!-- C153176-5896 | let child know when new inline images gets pasted -->
    </form>
  </div>

  <contact-sidebar *ngIf="showContactSidebar" [note]="notesList" [clc]="clc" (tradeAdded)="onTradesAdded($event)"
    (contactEvent)="onPushContact($event)" [rightSideBar]="'auto'" [topContactAdd]="topContactAdded"
    [messageInstance]="messageInstance" [checkTaskizeInquiry]="isTaskizeInquiry"></contact-sidebar>

</div>
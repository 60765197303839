import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { MailboxDlMappingService } from './mailbox-dl-mapping.service';
import { TabView } from 'primeng/tabview';
import { AppUtils } from '../../common/utility/appUtil';
import { MailboxOnboardingResponse } from 'src/app/model/mailbox-onboarding/MailboxOnboardingResponse';

@Component({
  selector: 'app-mailbox-dl-mapping',
  templateUrl: './mailbox-dl-mapping.component.html',
  styleUrls: ['./mailbox-dl-mapping.component.scss']
})
export class MailboxDlMappingComponent implements OnInit {
  @Output() modalClosed = new EventEmitter<boolean>();
  @Input() display: boolean;

  // Tab Indx
  actTabIdx: number = 0;
  actTabName: string = 'MAILBOX DETAILS';
  @ViewChild('mailBoxDLMappingTabs', { static: true }) mailBoxDLMappingTabs: TabView;

  //dropdown configuration
  mappingTypeList: any = [{ label: 'PERSONAL', value: 'PERSONAL' }, { label: 'DEDICATED', value: 'DEDICATED' }];
  memoryRequirementInMBList: any = [{ label: '256', value: 256 }, { label: '512', value: 512 }, { label: '1024', value: 1024 }, { label: '2048', value: 2048 }
    , { label: '3072', value: 3072 }, { label: '4096', value: 4096 }];
  isToBeForceProcessList: any = [{ label: 'true', value: true }, { label: 'false', value: false }];

  //form field
  isNewDLOnboarding: boolean = false;
  confirmMailboxLoginSecret: string = '';

  //validation
  soeIdErrorMessage: string = '';
  memoryReqErrorMessage: string = '';
  dlNameErrorMessage: string = '';
  mailboxNameErrorMessage: string = '';
  mailboxLoginSecretErrorMessage: string = '';
  confirmMailboxLoginSecretErrorMessage: string = '';
  //exchange validation
  hostNameeErrorMessage: string = '';
  attachmentPathErrorMessage: string = '';
  waitTimeBWPollErrorMessage: string = '';
  supportEmailErrorMessage: string = '';
  MaxErrorEmailCountErrorMessage: string = '';
  domainErrorMessage: string = '';
  versionErrorMessage: string = '';
  exchangeWebServiceUrlErrorMessage: string = '';
  allowedDomainErrorMessage: string = '';
  readerBatchSizeErrorMessage: string = '';

  //spinner
  showSpinner: boolean = false;

  //response result
  hasSuccess: boolean = false;
  successMessage: string = '';
  hasError: boolean = false;
  errorMessage: string = '';

  // Flag for Add new or Serach operation.
  isNewOrSearch: boolean = false;

  //exchange static data
  exchangeStaticServerData: MailboxOnboardingResponse = new MailboxOnboardingResponse();
  mailboxUIData: MailboxOnboardingResponse = new MailboxOnboardingResponse();

  constructor(private mailboxDlMappingService: MailboxDlMappingService) { }

  ngOnInit() {
    this.initailExchangeDetails();
  }

  initailExchangeDetails() {
    if (AppUtils.isUndefinedNullOrBlankStrAfterTrim(this.exchangeStaticServerData.hostName)) {
      this.showSpinner = true;
      console.debug(":SPINNER:: " + this.showSpinner + " ::mailbox-dl-mapping.initailExchangeDetails");
      this.mailboxDlMappingService.getDefaultExchangeData().subscribe(
        (data: MailboxOnboardingResponse) => {
          console.log(data);
          this.exchangeStaticServerData = { ...data };
          this.populateExchangeDetails();
          this.showSpinner = false;
          console.debug(":SPINNER:: " + this.showSpinner + " ::mailbox-dl-mapping.initailExchangeDetails");
        },
        (error) => {
          console.log(error);
          this.showSpinner = false;
          console.debug(":SPINNER:: " + this.showSpinner + " ::mailbox-dl-mapping.initailExchangeDetails");
        }
      );
    }
  }

  populateExchangeDetails() {
    if (!AppUtils.isUndefinedOrNull(this.exchangeStaticServerData)) {
      this.mailboxUIData.hostName = this.exchangeStaticServerData.hostName;
      this.mailboxUIData.attachmentPath = this.exchangeStaticServerData.attachmentPath;
      this.mailboxUIData.allowedDomains = this.exchangeStaticServerData.allowedDomains;
      this.mailboxUIData.domain = this.exchangeStaticServerData.domain;
      this.mailboxUIData.exchangeWebServiceUrl = this.exchangeStaticServerData.exchangeWebServiceUrl;
      this.mailboxUIData.maxErrorEmailCount = this.exchangeStaticServerData.maxErrorEmailCount;
      this.mailboxUIData.readerBatchSize = this.exchangeStaticServerData.readerBatchSize;
      this.mailboxUIData.supportEmail = this.exchangeStaticServerData.supportEmail;
      this.mailboxUIData.version = this.exchangeStaticServerData.version;
      this.mailboxUIData.waitTimeBetweenPoll = this.exchangeStaticServerData.waitTimeBetweenPoll;
    }
  }

  initializeData() {
    this.mailboxUIData = new MailboxOnboardingResponse();
  }

  toggleNewDLMapMode() {
    this.resetForm();
    if (this.isNewDLOnboarding) {
      this.isNewOrSearch = true;
      this.populateExchangeDetails();
    }
  }

  hideModal() {
    this.modalClosed.emit();
    this.resetForm();
  }

  //reset all controls if new group checkbox is clicked
  resetForm() {
    this.initializeData();
    this.isNewOrSearch = false;
    this.confirmMailboxLoginSecret = ''; // Reset the confirm password field.
  }

  callSearchService(event: string) {
    if (this.mailboxUIData._id) {
      let reqObj = {};
      if (event === 'SOEID') {
        console.log('search by soeId')
        reqObj = { _id: this.mailboxUIData._id };

      } else {
        console.log('search by dl name');
        reqObj = {
          _id: this.mailboxUIData._id,
          mappedDL: this.mailboxUIData.mappedDL
        };
      }
      this.showSpinner = true;
      console.debug(":SPINNER:: " + this.showSpinner + " ::mailbox-dl-mapping.callSearchService");
      this.mailboxDlMappingService.getStatus(reqObj).subscribe(
        (data: MailboxOnboardingResponse) => {
          console.log(data);
          this.mailboxUIData = { ...data };
          this.confirmMailboxLoginSecret = ''; // Reset the Confirm Password field.
          this.showSpinner = false;
          console.debug(":SPINNER:: " + this.showSpinner + " ::mailbox-dl-mapping.callSearchService");
          this.isNewOrSearch = true;

          if (!AppUtils.isUndefinedNullOrBlank(data.message)) {

            if (data.success) {
              //this.hasSuccess=true;
              //this.successMessage = data.message;
            } else {
              this.hasError = true;
              this.errorMessage = data.message;
              this.resetForm();
            }
          }
        },
        (error) => {
          console.log(error);
          this.showSpinner = false;
          console.debug(":SPINNER:: " + this.showSpinner + " ::mailbox-dl-mapping.callSearchService");
        }
      );
    } else {
      this.mailboxNameErrorMessage = 'Mailbox name is required.'
    }
  }

  validateDataToAdd() {
    let isValid = true;
    if (this.mailboxUIData.mappingType === 'PERSONAL') {
      if (AppUtils.isUndefinedNullOrBlankStrAfterTrim(this.mailboxUIData._id)) {
        isValid = false;
        this.soeIdErrorMessage = "SOE ID can not be blank";
      }
    } else if (this.mailboxUIData.mappingType === 'DEDICATED') {
      if (AppUtils.isUndefinedNullOrBlankStrAfterTrim(this.mailboxUIData.mappedDL)) {
        isValid = false;
        this.dlNameErrorMessage = "DL Name can not be blank";
      } else if (AppUtils.isUndefinedNullOrBlankStrAfterTrim(this.mailboxUIData._id)) {
        isValid = false;
        this.mailboxNameErrorMessage = "Mailbox Name can not be blank";
      } else if (AppUtils.isUndefinedNullOrBlankStrAfterTrim(this.mailboxUIData.loginSecret)) {
        isValid = false;
        this.mailboxLoginSecretErrorMessage = "Login Secret can not be blank";
      } else if (AppUtils.isUndefinedNullOrBlankStrAfterTrim(this.confirmMailboxLoginSecret)
        || this.mailboxUIData.loginSecret !== this.confirmMailboxLoginSecret) {
        isValid = false;
        this.confirmMailboxLoginSecretErrorMessage = "Login Secret must match";
      }
      //exchange validation
      else if (AppUtils.isUndefinedNullOrBlankStrAfterTrim(this.mailboxUIData.hostName)) {
        isValid = false;
        this.hostNameeErrorMessage = "Host Name can not be blank";
      } else if (AppUtils.isUndefinedNullOrBlankStrAfterTrim(this.mailboxUIData.attachmentPath)) {
        isValid = false;
        this.attachmentPathErrorMessage = "Attachment path can not be blank";
      } else if (AppUtils.isUndefinedNullOrBlankStrAfterTrim(this.mailboxUIData.waitTimeBetweenPoll)) {
        isValid = false;
        this.waitTimeBWPollErrorMessage = "Wait Time Between Poll can not be blank";
      } else if (AppUtils.isUndefinedNullOrBlankStrAfterTrim(this.mailboxUIData.supportEmail)) {
        isValid = false;
        this.supportEmailErrorMessage = "Support Email can not be blank";
      } else if (AppUtils.isUndefinedNullOrBlankStrAfterTrim(this.mailboxUIData.maxErrorEmailCount)) {
        isValid = false;
        this.MaxErrorEmailCountErrorMessage = "Max Error Email Count can not be blank";
      } else if (AppUtils.isUndefinedNullOrBlankStrAfterTrim(this.mailboxUIData.domain)) {
        isValid = false;
        this.domainErrorMessage = "Domain can not be blank";
      } else if (AppUtils.isUndefinedNullOrBlankStrAfterTrim(this.mailboxUIData.version)) {
        isValid = false;
        this.versionErrorMessage = "Version can not be blank";
      } else if (AppUtils.isUndefinedNullOrBlankStrAfterTrim(this.mailboxUIData.exchangeWebServiceUrl)) {
        isValid = false;
        this.exchangeWebServiceUrlErrorMessage = "Exchange Web Service URL can not be blank";
      } else if (AppUtils.isUndefinedNullOrBlankStrAfterTrim(this.mailboxUIData.allowedDomains)) {
        // isValid = false;
        // this.allowedDomainErrorMessage = "Allowed Domain can not be blank";
      } else if (AppUtils.isUndefinedNullOrBlankStrAfterTrim(this.mailboxUIData.readerBatchSize)) {
        isValid = false;
        this.readerBatchSizeErrorMessage = "Reader Batch Size can not be blank";
      }
    }
    if (isValid && this.mailboxUIData.minMemoryRequirementInMB > this.mailboxUIData.maxMemoryRequirementInMB) {
      isValid = false;
      this.memoryReqErrorMessage = "Min memory can not be greater than Max memory";
    }
    return isValid;
  }

  validateDataToUpdate() {
    let isValid = true;
    if (this.mailboxUIData.mappingType === 'PERSONAL') {
      if (AppUtils.isUndefinedNullOrBlankStrAfterTrim(this.mailboxUIData._id)) {
        isValid = false;
        this.soeIdErrorMessage = "SOE ID can not be blank";
      }
    } else if (this.mailboxUIData.mappingType === 'DEDICATED') {
      if (AppUtils.isUndefinedNullOrBlankStrAfterTrim(this.mailboxUIData.mappedDL)) {
        isValid = false;
        this.dlNameErrorMessage = "DL Name can not be blank";
      } else if (AppUtils.isUndefinedNullOrBlankStrAfterTrim(this.mailboxUIData._id)) {
        isValid = false;
        this.mailboxNameErrorMessage = "Mailbox Name can not be blank";
      } else if (!AppUtils.isUndefinedNullOrBlankStrAfterTrim(this.mailboxUIData.loginSecret) && this.mailboxUIData.loginSecret !== this.confirmMailboxLoginSecret) {
        isValid = false;
        this.confirmMailboxLoginSecretErrorMessage = "Login Secret must match";
      }
      //exchange validation
      else if (AppUtils.isUndefinedNullOrBlankStrAfterTrim(this.mailboxUIData.hostName)) {
        isValid = false;
        this.hostNameeErrorMessage = "Host Name can not be blank";
      } else if (AppUtils.isUndefinedNullOrBlankStrAfterTrim(this.mailboxUIData.attachmentPath)) {
        isValid = false;
        this.attachmentPathErrorMessage = "Attachment path can not be blank";
      } else if (AppUtils.isUndefinedNullOrBlankStrAfterTrim(this.mailboxUIData.waitTimeBetweenPoll)) {
        isValid = false;
        this.waitTimeBWPollErrorMessage = "Wait Time Between Poll can not be blank";
      } else if (AppUtils.isUndefinedNullOrBlankStrAfterTrim(this.mailboxUIData.supportEmail)) {
        isValid = false;
        this.supportEmailErrorMessage = "Support Email can not be blank";
      } else if (AppUtils.isUndefinedNullOrBlankStrAfterTrim(this.mailboxUIData.maxErrorEmailCount)) {
        isValid = false;
        this.MaxErrorEmailCountErrorMessage = "Max Error Email Count can not be blank";
      } else if (AppUtils.isUndefinedNullOrBlankStrAfterTrim(this.mailboxUIData.domain)) {
        isValid = false;
        this.domainErrorMessage = "Domain can not be blank";
      } else if (AppUtils.isUndefinedNullOrBlankStrAfterTrim(this.mailboxUIData.version)) {
        isValid = false;
        this.versionErrorMessage = "Version can not be blank";
      } else if (AppUtils.isUndefinedNullOrBlankStrAfterTrim(this.mailboxUIData.exchangeWebServiceUrl)) {
        isValid = false;
        this.exchangeWebServiceUrlErrorMessage = "Exchange Web Service URL can not be blank";
      } /* else if (AppUtils.isUndefinedNullOrBlankStrAfterTrim(this.mailboxUIData.allowedDomains)) {
        isValid = false;
        this.allowedDomainErrorMessage = "Allowed Domain can not be blank";
      } */ else if (AppUtils.isUndefinedNullOrBlankStrAfterTrim(this.mailboxUIData.readerBatchSize)) {
        isValid = false;
        this.readerBatchSizeErrorMessage = "Reader Batch Size can not be blank";
      }
    }
    if (isValid && this.mailboxUIData.minMemoryRequirementInMB > this.mailboxUIData.maxMemoryRequirementInMB) {
      isValid = false;
      this.memoryReqErrorMessage = "Min memory can not be greater than Max memory";
    }
    return isValid;
  }

  validateDataToRemove() {
    let isValid = true;

    if (this.mailboxUIData) {
      if (this.mailboxUIData.mappingType === 'PERSONAL') {
        if (AppUtils.isUndefinedNullOrBlankStrAfterTrim(this.mailboxUIData._id)) {
          isValid = false;
          this.soeIdErrorMessage = "SOE ID cannot be blank";
        }
      } else if (this.mailboxUIData.mappingType === 'DEDICATED') {
        if (AppUtils.isUndefinedNullOrBlankStrAfterTrim(this.mailboxUIData.mappedDL)) {
          isValid = false;
          this.dlNameErrorMessage = "DL Name cannot be blank";
        } else if (AppUtils.isUndefinedNullOrBlankStrAfterTrim(this.mailboxUIData._id)) {
          isValid = false;
          this.mailboxNameErrorMessage = "Mailbox Name cannot be blank";
        }
      }
    }
    else {
      isValid = false;
      // Optionally set an error message for the entire mailboxUIData being undefined
    }

    return isValid;
  }

  add() {
    if (this.validateDataToAdd()) {
      let reqObj = { ...this.mailboxUIData };
      reqObj.waitTimeBetweenPoll = +this.mailboxUIData.waitTimeBetweenPoll;
      reqObj.maxErrorEmailCount = +this.mailboxUIData.maxErrorEmailCount;
      reqObj.readerBatchSize = +this.mailboxUIData.readerBatchSize;

      reqObj.loginSecret = reqObj.loginSecret ? btoa(reqObj.loginSecret) : '';
      this.showSpinner = true;
      console.debug(":SPINNER:: " + this.showSpinner + " ::mailbox-dl-mapping.add");
      this.mailboxDlMappingService.add(reqObj).subscribe(
        (result: any) => {
          console.log(result);
          this.showSpinner = false;
          console.debug(":SPINNER:: " + this.showSpinner + " ::mailbox-dl-mapping.add");
          if (!AppUtils.isUndefinedNullOrBlank(result.message)) {
            if (result.success) {
              this.hasSuccess = true;
              this.successMessage = result.message;
              this.resetForm();
              this.isNewDLOnboarding = false;

            } else {
              this.hasError = true;
              this.errorMessage = result.message;
            }
          }

        },
        (error) => {
          console.log(error);
          this.showSpinner = false;
          console.debug(":SPINNER:: " + this.showSpinner + " ::mailbox-dl-mapping.add");
        }
      );
    }

  }

  update() {
    if (this.validateDataToUpdate()) {
      let reqObj = { ...this.mailboxUIData };
      reqObj.waitTimeBetweenPoll = +this.mailboxUIData.waitTimeBetweenPoll;
      reqObj.maxErrorEmailCount = +this.mailboxUIData.maxErrorEmailCount;
      reqObj.readerBatchSize = +this.mailboxUIData.readerBatchSize;

      reqObj.loginSecret = reqObj.loginSecret ? btoa(reqObj.loginSecret) : '';
      this.showSpinner = true;
      console.debug(":SPINNER:: " + this.showSpinner + " ::mailbox-dl-mapping.update");
      this.mailboxDlMappingService.update(reqObj).subscribe(
        (result: any) => {
          console.log(result);
          this.showSpinner = false;
          console.debug(":SPINNER:: " + this.showSpinner + " ::mailbox-dl-mapping.update");
          if (!AppUtils.isUndefinedNullOrBlank(result.message)) {
            if (result.success) {
              this.hasSuccess = true;
              this.successMessage = result.message;
            } else {
              this.hasError = true;
              this.errorMessage = result.message;
            }
          }
        },
        (error) => {
          console.log(error);
          this.showSpinner = false;
          console.debug(":SPINNER:: " + this.showSpinner + " ::mailbox-dl-mapping.update");
        }
      );
    }

  }

  remove() {
    if (this.validateDataToRemove()) {
      let reqObj = { ...this.mailboxUIData };

      this.showSpinner = true;
      console.debug(":SPINNER:: " + this.showSpinner + " ::mailbox-dl-mapping.remove");
      this.mailboxDlMappingService.remove(reqObj).subscribe(
        (result: any) => {
          console.log(result);
          this.showSpinner = false;
          console.debug(":SPINNER:: " + this.showSpinner + " ::mailbox-dl-mapping.remove");
          if (!AppUtils.isUndefinedNullOrBlank(result.message)) {
            if (result.success) {
              this.hasSuccess = true;
              this.successMessage = result.message;
              this.resetForm();
            } else {
              this.hasError = true;
              this.errorMessage = result.message;
            }
          }
        },
        (error) => {
          console.log(error);
          this.showSpinner = false;
          console.debug(":SPINNER:: " + this.showSpinner + " ::mailbox-dl-mapping.remove");
        }
      );
    }

  }

  start() {
    let reqObj = { ...this.mailboxUIData };
    this.showSpinner = true;
    console.debug(":SPINNER:: " + this.showSpinner + " ::mailbox-dl-mapping.start");
    this.mailboxDlMappingService.start(reqObj).subscribe(
      (result: any) => {
        console.log(result);
        this.showSpinner = false;
        console.debug(":SPINNER:: " + this.showSpinner + " ::mailbox-dl-mapping.start");
        if (!AppUtils.isUndefinedNullOrBlank(result.message)) {
          if (result.success) {
            this.hasSuccess = true;
            this.successMessage = result.message;
          } else {
            this.hasError = true;
            this.errorMessage = result.message;
          }
        }
      },
      (error) => {
        console.log(error);
        this.showSpinner = false;
        console.debug(":SPINNER:: " + this.showSpinner + " ::mailbox-dl-mapping.start");
      }
    );
  }

  stop() {
    let reqObj = { ...this.mailboxUIData };
    this.showSpinner = true;
    console.debug(":SPINNER:: " + this.showSpinner + " ::mailbox-dl-mapping.stop");
    this.mailboxDlMappingService.stop(reqObj).subscribe(
      (result: any) => {
        console.log(result);
        this.showSpinner = false;
        console.debug(":SPINNER:: " + this.showSpinner + " ::mailbox-dl-mapping.stop");
        if (!AppUtils.isUndefinedNullOrBlank(result.message)) {
          if (result.success) {
            this.hasSuccess = true;
            this.successMessage = result.message;
          } else {
            this.hasError = true;
            this.errorMessage = result.message;
          }
        }
      },
      (error) => {
        console.log(error);
        this.showSpinner = false;
        console.debug(":SPINNER:: " + this.showSpinner + " ::mailbox-dl-mapping.stop");
      }
    );
  }

  onTabChange(event) {
    this.actTabName = this.mailBoxDLMappingTabs.tabs[event.index].header;
    this.actTabIdx = event.index;
  }

}
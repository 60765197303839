import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { InboxService } from "src/app/services/inbox.service";
import { MessageService } from 'primeng/api';
import { UserDataService } from 'src/app/services/user-data.service';
import { PiwikProUtils } from 'src/app/common/utility/piwikProUtils';

@Component({
  selector: 'app-attachment',
  templateUrl: './attachment.component.html',
  styleUrls: ['./attachment.component.scss']
})
export class AttachmentComponent implements OnInit, OnChanges {


  @Input() attachments: any = [];
  attachmentList: any = [];
  attachmentCount: number = 0;

  constructor(private userDataService: UserDataService,private inboxService: InboxService, private messageService: MessageService) { }

  ngOnInit() { }

  ngOnChanges(): void {
    this.attachmentList = [];
    this.attachmentCount = 0; //C153176-4478 download all visible for all

    if (this.attachments.length > 0) {
      this.attachmentCount = this.attachments.length;
      Array.prototype.push.apply(this.attachmentList, this.attachments);
    }
  }

  /**
     * Method to download attachments attached to conversation
     * 
     * @param attachments - Conversation Attachment list
     */
  onDownloadAttachment(attachments: any): void {
    console.log('Only one attachment', attachments.name, attachments);
    this.inboxService.downloadAttachment(attachments.id, attachments.name,attachments.convId, attachments.groupId, attachments.hasNominatedOwnership);
    PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Mailboxes ", "Download attachment", "Click", "Download attachment", 0);
  }
  /**
     * Method to download attachments attached to conversation
     * 
     * @param attachments - Conversation Attachment list
     */
    onCopyAttachment(attachments: any): void {
      // C153176-5490 | One last item shoud be available for attachment for attahment. 
      // We need to clear the session storage on new attachment request.
      console.log("Only last item should available to paste for attachment");
      sessionStorage.removeItem("copiedattch");
      this.attachments.forEach(attachment => {
        attachment.imageName = 'copy_icon_blue.svg';
      });

      console.log('Only one attachment', attachments.name);
      var curVal = sessionStorage.getItem('copiedattch');
      var bAddNewAttach : boolean = false;
      if (curVal == null) {
        curVal = '';
        bAddNewAttach = true;
      } else {
        if (curVal.indexOf(attachments.name) == -1) {
            bAddNewAttach = true;
        }
      }
      if (bAddNewAttach == true) {
        sessionStorage.setItem('copiedattch',curVal + '~' + this.inboxService.viewAttachment(attachments.id, attachments.name, attachments.convId, attachments.groupId, attachments.hasNominatedOwnership) + ':' + attachments.name);

        // Change the color of icon to show it as copied
        attachments.imageName = "copy_icon.svg";

        // C153176-4648 - Show 'Copied' message on copy of an attachment. If copied, it will show 'Copied' as title.
        attachments.copiedMsg = 'Copied';
        this.messageService.add({ severity: 'success', key: 'qmaToast', summary: '', detail: 'Copied' });
      }
      PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Mailboxes ", "Copy attachment", "Click", "Copy attachment", 0);
    }

  /**
   * Method to download all the attachments from the Inquiry
   */
  onDownloadAllAttachment(): void {
    if (this.attachmentList.length > 1) {
      console.log('More than one attachment : ', this.attachmentList.length);
      const inquiryId = this.attachmentList[0].inquiryId;
      const groupId = this.attachmentList && this.attachmentList[0] && this.attachmentList[0].groupId
                      ? this.attachmentList[0].groupId : null;
      this.inboxService.downloadAllAttachments(inquiryId, null, groupId);
    } 
    else {
      this.onDownloadAttachment(this.attachmentList[0]);
    }
    PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Mailboxes ", "Download all attachments", "Click", "Download all attachments", 0);
  }
}

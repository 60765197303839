import { AppUtils } from "src/app/common/utility/appUtil";

export class DraftGridColumnDefs {

  draftsGridDefs() {
    let objAppUtils = new AppUtils();

    return [
      {
        "headerName": "URGENT",
        "field": "urgentFlag",
        hide: false,
        suppressMenu: true,
        resizable: true,
        suppressMovable: true,
        cellRenderer: ""

      },
      {
        "headerName": "ATTACHMENT",
        "field": "attchFlag",
        hide: false,
        suppressMenu: true,
        resizable: true,
        suppressMovable: true,
        cellRenderer: ""

      },
      {
        "headerName": "ID",
        "field": "_id",
        hide: false,
        suppressMenu: true,
        resizable: true,
        suppressMovable: true,
        cellRenderer: ""

      },
      {
        "headerName": "SUBJECT",
        "field": "subject",
        //"width": 100,
        // "height": 50,
        suppressMenu: true,
        resizable: true,
        suppressMovable: true,
        hide: false,
        cellRenderer: "",


      },
      {
        "headerName": "CREATED DATE",
        "field": "crtDate.$date",
        hide: false,
        suppressMenu: true,
        resizable: true,
        suppressMovable: true,
        cellRenderer: (data) => {              
          return objAppUtils.convertDate(data.value)
        },
        tooltipValueGetter: (data) => {
          return objAppUtils.convertDate(data.value)
        }

      },
      {
        "headerName": "MODIFIED DATE",
        "field": "modDate.$date",
        hide: false,
        suppressMenu: true,
        resizable: true,
        suppressMovable: true,
        cellRenderer: (data) => {              
          return objAppUtils.convertDate(data.value)
        },
        tooltipValueGetter: (data) => {
          return objAppUtils.convertDate(data.value)
        }

      }
    ]
  }
}
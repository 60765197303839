import { Injectable } from '@angular/core';
import { HttpClientService } from '../../utility/http-client.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { QmaConstant } from 'src/app/constant/qma-constant';
import { AdminModule } from '../../admin/admin.module';
import { Observable } from 'rxjs';
import { serviceUrl } from 'src/app/utility/serviceUrl';

const httpFormPostOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  })
};

@Injectable({
  providedIn: 'root'
})

export class UserAdminService extends HttpClientService {
  userServiceURL: string;
  constructor(http: HttpClient) {
    super(http);
  }

  getUserDataById(requestObj: any): Observable<any> {
    return this.http.post(QmaConstant.BASE_URL + '/' + serviceUrl.getUserDataById, requestObj, httpFormPostOptions)
  }

  saveUserAdminData(requestObj: any) {
    let req = "request=" + encodeURIComponent(JSON.stringify(requestObj));
    return this.post(serviceUrl.saveUserAdminData, req);
  }
}
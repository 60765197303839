<div class="row-container">
    <swiper *ngIf="!contextMenuOnly" [config]="swipeConfig" [(index)]="index" (indexChange)="onIndexChange($event)"
        [useSwiperClass]="'mobile-swiper'" id="{{'isw-' + item._id}}">
        <div *ngFor="let index of indexes"> 
            <!-- C153176-4796 right swiping slide: show rswipe only if enableAllSlides is true-->
            <div *ngIf="index == 'rswipe' && enableAllSlides">
                <div class="slider-bar d-flex flex-row align-items-center justify-content-end slider-bar px-3">
                    <div *ngFor="let menuitem of rmenuitems | generictransformer:getRSwipeRowMenuItems:'';let i = index" class="menu-item">
                        <div class="d-flex flex-column justify-content-center align-items-center right-swipe-icon">
                            <img [src]="buildContextMenu(menuitem.icon)" [id]="menuitem.label" (click)="onMenuItem($event,menuitem, 'rswipe')" />
                            <div class="menu-text mt-2" [ngStyle]="applyColor(i)">{{menuitem.label}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="index == 'grid'" [ngClass]="item | generictransformer:getReadUnreadCls:''">
                <div class="d-flex flex-row align-items-center justify-content-between flex-unwrap row-style pr-1" id="{{'row-' + item._id}}"
                    [ngStyle]="getClientCategory(item)" [ngClass]="selected | generictransformer:getSelectedItemCls:''"
                    (click)="onItemClick(item)">
                    <div class="d-flex flex-row justify-content-start align-items-center w-75">                        
                        <div class="p-1 d-flex flex-row align-items-center">                            
                            <div *ngIf="selectionEnabled" class="custom-control custom-checkbox">
                                <label class="custom-checkbox-control">
                                    <span class="custom-checkbox-item"><i [ngClass]="selected | generictransformer:getCheckboxCls:''"
                                        (click)="onClickCheckbox($event)"></i></span>
                                </label>
                            </div>
                            <app-image-placeholder *ngIf="!selectionEnabled" styleClass="mobile-grid-dp" [recipient]="item | generictransformer:getUserType:''"></app-image-placeholder>
                        </div>
                        <div class="unread-dot" [hidden]="isDraft"></div>
                        <div class="d-flex flex-column overflow-hidden">
                            <div class="item-name truncate" [innerHTML]="item | generictransformer:getLatestUserName:''"></div>
                            <div class="item-subject truncate" [innerHTML]="item | generictransformer:getSubject:''"></div>
                            <div class="item-headline" [innerHTML]="item | generictransformer:getHeadline:''"></div>
                        </div>
                    </div>
                    <div class="d-flex flex-column align-items-end justify-content-between flex-unwrap py-1 last-col">
                        <div class="item-date">{{item | generictransformer:getDate:''}}</div>
                        <div class="flex-container icon-shift-{{assignedTo}}">
                            <div *ngIf="item.convCount > 1" class="item-conversation-count d-flex flex-column align-items-center justify-content-center">{{item.convCount}}</div>
                            <div class="item-annex" [innerHTML]="item | generictransformer:getAnnex:''"></div>
                        </div>
                        <div *ngIf ="item.workflows && item.workflows[0].assignedUserName"><em class="fa fa-user" aria-hidden="true"></em><span class="item-assigned-to"> {{item | generictransformer:getAssignedTo:''}}</span></div>
                    </div>
                </div>
            </div>
            <div *ngIf="index == 'slider'">
                <div class="slider-bar d-flex flex-row align-items-center justify-content-between slider-bar px-3">
                    <div *ngFor="let menuitem of menuitems | generictransformer:getRowMenuItems:'';let i = index" class="menu-item">
                        <div *ngIf="menuitem.label != 'Others'" class="d-flex flex-column justify-content-center align-items-center">
                            <img [src]="buildContextMenu(menuitem.icon)" [id]="menuitem.label" (click)="onMenuItem($event,menuitem, 'slieder')" />
                            <div class="menu-text mt-2" [ngStyle]="applyColor(i)">{{menuitem.label}}</div>
                        </div>
                        <div *ngIf="menuitem.label == 'Others'">
                            <img src="assets/mobile/ellipsis.svg" [id]="menuitem.label" (click)="onMenuItem($event,item, 'ellipsis')" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </swiper>
    <div *ngIf="contextMenuOnly" class="bulk-selection-contextMenu">
        <div class="d-flex flex-row align-items-center justify-content-between slider-bar px-1">
            <img class="backward-icon" src="assets/Inbox/right_chevron_icon.svg" (click)="unSelectBulkSelection()">
            <div class="select-all" *ngIf="!selectAll" (click)="onSelectAll(true)">Select All</div>
            <div class="select-all" *ngIf="selectAll" (click)="onSelectAll(false)">Deselect All</div>
            <div class="selection-items">{{selectedItemCount}} Selected</div>
            <div *ngFor="let menuitem of menuitems | generictransformer:getBuckSelectMenuItems:'';let i = index" class="menu-item" [hidden]="checkIfDraft(i)">
                <div *ngIf="menuitem.label != 'Others'" class="d-flex flex-column justify-content-center align-items-center">
                    <img [src]="buildContextMenu(menuitem.icon)" [id]="menuitem.label" (click)="onMenuItem($event,menuitem, 'swipe')" />
                    <div class="menu-text mt-1" [ngStyle]="applyColor(i)">{{menuitem.label}}</div>
                </div>
                <div *ngIf="(menuitem.label == 'Others')">
                    <img src="assets/mobile/ellipsis.svg" [id]="menuitem.label" (click)="onMenuItem($event,item, 'ellipsis')" />
                </div>
            </div>
        </div>
    </div>
</div>
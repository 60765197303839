import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { CalendarView, CalendarEventAction, CalendarEventTimesChangedEvent } from 'angular-calendar';
import { CalendarEvent } from 'calendar-utils';
import { Subject } from 'rxjs';
import { isSameMonth, isSameDay, startOfDay, endOfDay } from 'date-fns/esm';
import { HttpClient } from '@angular/common/http';
//import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PersonalCalendarService } from './personal-calendar.service';

import * as moment from 'moment';
import { UserDataService } from '../services/user-data.service';
import { AllToCcDBUser } from '../model/LoginUserInfo/AllToCcDBUser';

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};

@Component({
  selector: 'app-personal-calendar',
  templateUrl: './personal-calendar.component.html',
  styleUrls: ['./personal-calendar.component.scss']
})
export class PersonalCalendarComponent implements OnInit {

  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;

  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;

  viewDate: Date = new Date();

  httpClient: HttpClient

  modalData: {
    action: string;
    event: CalendarEvent;
  };

  actions : CalendarEventAction[] = [
    {
      label: '<i class="fas fa-fw fa-pencil-alt"></i>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      },
    },
    {
      label: '<i class="fas fa-fw fa-trash-alt"></i>',
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events = this.events.filter((iEvent) => iEvent !== event);
        this.handleEvent('Deleted', event);
      },
    },
  ];

  refresh: Subject<any> = new Subject();
   events: CalendarEvent[]=[];
   
  activeDayIsOpen: boolean = true;
  displayCreateAppointmentPopup: boolean = false;
  inputTO: string = '';
  inputSubject: string = '';
  inputLocation: string = '';
  startDate: Date = new Date();
  startTime: Date = new Date();
  endDate: Date = new Date();
  endTime: Date = new Date();
  inputMessageBody: string = '';

  userList: AllToCcDBUser[] = [];
  filteredToUsersMultiple: any[] = [];
  allToCcDBUser: AllToCcDBUser[] = [];

  displayCancelAppointmentPopup: boolean = false;
  meetingId: string | number ;

  constructor(/*private modal: NgbModal,*/private calendarService: PersonalCalendarService,
    private userDataService: UserDataService,) {
    this.events=this.calendarService.calendarData;
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }
  

ngOnInit() {
  this.loadEvents();
  this.loadGroups();
}

loadEvents(){
  this.calendarService.httpGetCalendarData()
  .subscribe(response => {
    console.log(response);
    // this.events = JSON.parse(response[0]);
    let cEvent:CalendarEvent[]=[];
    response.forEach(element => {
      let c : CalendarEvent= {
        start : new Date(element.start),
      end : new Date(element.end),
      title : element.title,
      color : element.color,
      allDay : element.allDay,
      id : element.id
      };
      
      cEvent.push(c);
    });
    this.events=cEvent;
    });
}

loadGroups(){
  this.userDataService.LocalGetLoginUserInfo().subscribe(loginUserInfo => {
    const groups = loginUserInfo.myGroups;
//    this.loggedInUser = loginUserInfo.userId;
    
    loginUserInfo.allToCcDBUsers.forEach(toCcBccUsers => {
      let individualRecipent: AllToCcDBUser = { ...toCcBccUsers };
      individualRecipent.email = individualRecipent.value;
      this.allToCcDBUser.push(individualRecipent);
    });
  });
}


  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
   // this.handleEvent('Dropped or resized', event);
  }
  cancelCancelAppointmentPopup(){
    this.displayCancelAppointmentPopup = false;
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.modalData = { event, action };
    //this.modal.open(this.modalContent, { size: 'lg' });
    this.displayCancelAppointmentPopup = true;
    this.inputSubject = event.title;
    this.startDate = event.start;
    this.startTime = event.start;
    this.endDate = event.end;
    this.endTime = event.end;
    this.meetingId = event.id;
  }

  filterUsers(event) {
    let query = event.query;
    this.filteredToUsersMultiple = this.getFilteredToCCUsers(query);
  }

  getFilteredToCCUsers(query) {
    let filtered = [];

    this.allToCcDBUser.forEach(record => {
      if ((record.text.toLowerCase().indexOf(query.toLowerCase()) >= 0) || (record.id.toLowerCase().indexOf(query.toLowerCase()) >= 0)) {
        filtered.push(record);
      }
    });

    return filtered;
  }

  addEvent(): void {
    /* this.events = [
      ...this.events,
      {
        title: 'New event',
        start: startOfDay(new Date()),
        end: endOfDay(new Date()),
        color: colors.red,
        draggable: true,
        resizable: {
          beforeStart: true,
          afterEnd: true,
        },
      },
    ]; */
    this.inputTO = '';
    this.inputSubject = '';
    this.inputLocation = '';
    this.startDate = new Date();
    this.startTime = new Date();
    this.endDate = new Date();
    this.endTime = new Date();
    this.inputMessageBody = '';
    this.userList = [];
    this.displayCreateAppointmentPopup = true;

    
  }

  createAppointment() {

    let fromDate = '';
    let toDate = '';
    if(this.startDate && this.startTime){
      let st = moment(this.startTime).toObject();
        let sd = moment(this.startDate, "MM-DD-YYYY").set('hour', st.hours).set('minute', st.minutes).set('second', st.seconds);
        fromDate = sd.toString();
        fromDate = new Date(fromDate).toUTCString();
    }
    if(this.endDate && this.endTime){
      let st = moment(this.endTime).toObject();
        let sd = moment(this.endDate, "MM-DD-YYYY").set('hour', st.hours).set('minute', st.minutes).set('second', st.seconds);
        toDate = sd.toString();
        toDate = new Date(toDate).toUTCString();
    }

    let startDate : string = fromDate;
		let endDate : string = toDate;
		let subject : string = this.inputSubject;
		let messageBody : string = this.inputMessageBody;
		let location : string = this.inputLocation;
    let attendeesList : Array<string> = new Array();
    this.userList.forEach((user)=>{
      attendeesList.push(user.value);
    });

    
    let reqObject = {
      startDate : startDate,
      endDate : endDate,
      subject : subject,
      messageBody : messageBody,
      location : location,
      attendeesList : attendeesList
    };
    this.calendarService.httpPostCreateEvent(reqObject).subscribe(response => {
      console.log(response);
      this.displayCreateAppointmentPopup = false;
      this.loadEvents();
      });
  }

  cancelPopup(){
    this.displayCreateAppointmentPopup = false;
  }

  cancelAppointment(){

    let reqObject = {};
    reqObject = {
      meetingId : this.meetingId
    }
    this.calendarService.httpPostDeleteEvent(reqObject).subscribe(response => {
      console.log(response);
      this.displayCancelAppointmentPopup = false;
      this.loadEvents();
      });
  }

  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter((event) => event !== eventToDelete);
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

}

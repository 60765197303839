import { Component, OnInit, AfterViewInit, EventEmitter, Output, Input, SimpleChanges, OnChanges } from '@angular/core';
import { GroupSetupService } from '../../services/group-setup/group-setup.service';
import { UserDataService } from 'src/app/services/user-data.service';
import { GroupAdminService } from 'src/app/services/group-admin/group-admin.service';
import { GlobalDirectoryService } from 'src/app/services/global-directory/global-directory.service';
import { AppUtils } from 'src/app/common/utility/appUtil';
import { DatePipe } from '@angular/common';
import { start } from 'repl';
import { MisReportConfig } from 'src/app/model/group-admin/misReportConfig';
@Component({
  selector: 'app-group-setup',
  templateUrl: './group-setup.component.html',
  styleUrls: ['./group-setup.component.scss'],
  providers: [GroupSetupService]
})
export class GroupSetupComponent implements OnInit, AfterViewInit, OnChanges {

  @Output() modalClosed = new EventEmitter<boolean>();
  @Input() display: boolean;
  hasError: boolean = false;
  resetGroup: any = '';
  errorMessage: string;
  isNewGroupSetup: boolean = false;
  inactive: boolean = false;
  loading: boolean = true;
  groupSetAllGroupData: any;
  selectedGroup: string = '';
  newGroupName: string = null;
  previouslySelectedGroup: string = null;
  email: string = '';
  soeId: string = '';
  selectedCountry: any;
  selectedTimeZone: any;
  selectedSoeIDsList: any = [];
  selectedBusinessSegment: any;
  selectedL1: any;
  selectedL2: any;
  selectedL3: any;
  selectedGFCID: any;
  selectedOrganisation: any;
  L1List: any = [];
  L2List: any = [];
  misReportingDate: string;
  GFCIDsList: any = [];
  businessSegmentsList: any = [];
  groupsList: any = [];
  countriesList: any = [];
  timeZonesList: any = [];
  mgmtHeirarchyData: any;
  userErrorMessage: string = '';
  level3UserErrorMessage: string = '';
  groupNameErrorMessage: string = '';
  emailErrorMessage: string = '';
  newSoeIDsList: any[] = [];
  level3HeirarchyUserName: string = '';
  level3HeirarchyUserId: string = '';
  public groupManager: string = '';
  public groupManagerGEID: string = '';
  validationFlag: boolean = false;
  auId: string = '';
  contactInfoEndPoint: string = '';
  showSpinner: boolean = false;
  selectedL1UserId: string = '';
  selectedL2UserId: string = '';
  selectedGroupBakup: string = '';
  selectedOrgHierarchy = [];
  actTabIdx: number = 0;
  hasSuccess: boolean = false;// for success message
  successMessage: string = '';
  blankStringValidationMsg: string = "UserId can not be blank";
  groupManagerValidationFlag: boolean = false;
  error: any = { isError: false, errorMessage: '' };
  yesRadio = true;
  noRadio = false;
  minimumDate: Date = new Date();
  isEnableReportingSelected = null;
  misDateRange = '';
  misReportConfig: MisReportConfig;
  groupLoading: boolean = true;
  
  
  constructor(private groupSetupService: GroupSetupService, private userDataService: UserDataService,
    private groupAdminService: GroupAdminService, private globalDirectoryService: GlobalDirectoryService,
    private datePipe: DatePipe) { }


  ngOnInit() {
    this.auId = this.userDataService.loggedInUserInfo.globalDirectoryConfig.contactInfoConfig.auid;
    this.contactInfoEndPoint = this.userDataService.loggedInUserInfo.globalDirectoryConfig.contactInfoConfig.contactInfoEndPoint;
    this.setMISDateRange();
    this.populateUserGroup();
    this.selectedGroupBakup = this.selectedGroup;
  }

  ngAfterViewInit() {
    //this.display = true;
  }

  //Initialize data
  populateUserGroup() {
    this.groupsList = [];
    this.groupSetupService.getGroupsList().subscribe((data: any[]) => {
      this.groupSetAllGroupData = data;
      // first group selected on load
      if (this.isUndefinedNullOrBlank(this.selectedGroup)) {
        this.selectedGroup = data && data[0] && data[0].value ? data && data[0] && data[0].value : "";
        this.selectedGroupBakup = this.selectedGroup;
      }
      data.forEach((item) => {
        this.groupsList.push({
          label: item.text,
          value: item.value
        });
      });
      this.selectedGroup = this.groupsList[0].value;
      this.loading=false;
      this.initializeCountryAndTimeZoneWidgets();
      this.showUserGroupRelData();
    });
  }

  setMISDateRange() {

    const year = new Date().getFullYear();
    const startYr = year - 5;
    const endYr = year + 5;
    this.misDateRange = `${year}:${endYr}`;
  }

  //Get data from locally available countries and timezones
  initializeCountryAndTimeZoneWidgets() {
    this.userDataService.LocalGetLoginUserInfo().subscribe((userData) => {
      if (userData && userData.countryList && userData.timeZones) {
        userData.countryList.forEach((item) => {
          this.countriesList.push({
            label: item.countryName,
            value: item.countryCode
          })
        });
        userData.timeZones.forEach((item) => {
          this.timeZonesList.push({
            label: item.timeZoneName,
            value: item.timeZoneCode
          })
        });
        console.log(this.countriesList);
        console.log(this.timeZonesList);
      }
    });
  }

  //based on checked / unchecked value on New Group checkbox, toggle view.
  toggleGroupMode() {
    this.soeId = '';
    if (this.isNewGroupSetup) {
      this.resetForm();
      if (this.selectedGroup != null) {
        this.previouslySelectedGroup = this.selectedGroup;
      } else {
        this.previouslySelectedGroup = null;
      }
    } else {
      //Populate hierarchy based on selection
      if (this.previouslySelectedGroup == null) {
        if (this.groupsList.length > 0) {
          this.selectedGroup = this.groupsList[0].value;
        }
      } else {
        this.selectedGroup = this.previouslySelectedGroup;
      }
      this.populateManagementHeirarchyForGroupSetup(this.selectedGroup);
      this.setBasicDetailsForSelectedGroup(this.selectedGroup);
      this.populateGroupSetupUserList(this.selectedGroup);
    }
  }

  //reset all controls if new group checkbox is clicked
  resetForm() {
    this.selectedGroup = '';
    this.auId = this.userDataService.loggedInUserInfo.globalDirectoryConfig.contactInfoConfig.auid;
    this.contactInfoEndPoint = this.userDataService.loggedInUserInfo.globalDirectoryConfig.contactInfoConfig.contactInfoEndPoint;
    this.populateUserGroup();
  }

  populateManagementHeirarchyForGroupSetup(groupId: any) {
    this.level3UserErrorMessage = "";
    if (this.isUndefinedOrNull(this.mgmtHeirarchyData)) {
      this.getAllHeirarchyData(groupId);
    } else {
      this.parseHeirarchyData(this.mgmtHeirarchyData, groupId);
    }
  }

  // populate management heirarchy Business segments, L1, L2 , GFCID
  getAllHeirarchyData(groupId: string) {
    this.groupSetupService.getManagementHeirarchy().subscribe((data) => {
      if (data !== null) {
        console.log(data);
        this.mgmtHeirarchyData = data;
        this.parseHeirarchyData(data, groupId);
      }
      else {
        console.log("No data set for heirarchy");
      }
    });
  }

  sortIgnoreCase(array: any) {
    if (array !== null && array !== undefined) {
      array = array.sort(function (a, b) {
        if (a.label && b.label) {
          return a.label.toLowerCase().localeCompare(b.label.toLowerCase());
        } else {
          return true;
        }
      });
      return array;
    }
  }

  isUndefinedOrNull(val) {
    return val === undefined || val === null;
  }

  // populate data based on group selection
  parseHeirarchyData(data, groupId) {
    let organisation = data.organisation;
    let firstLevel = data.firstLevelHeirarchy;
    let secondLevel = data.secondLevelHeirarchy;
    let gcfidHeirarchy = data.gfcid;

    this.businessSegmentsList = [];
    this.L1List = [];
    this.L2List = [];
    this.selectedL3 = '';
    this.selectedL2 = '';
    this.selectedL1 = '';
    this.level3HeirarchyUserId = '';
    this.level3HeirarchyUserName = '';
    this.GFCIDsList = [];

    if (this.isUndefinedOrNull(organisation))
      organisation = [];
    else {
      organisation.forEach((val) => {
        this.businessSegmentsList.push({ label: val, value: val });
      })
      this.businessSegmentsList = this.sortIgnoreCase(this.businessSegmentsList);
      this.businessSegmentsList.splice(0, 0, { value: null, label: 'Select Business Segment' });
    }

    if (this.isUndefinedOrNull(firstLevel))
      firstLevel = [];
    else {
      firstLevel.forEach((val) => {
        //getGroupDataById API provides userId=null. hence using userName for value 
        this.L1List.push({ 'label': val.userName, 'value': val.userName });
      });
      this.L1List = this.sortIgnoreCase(this.L1List);
      this.L1List.splice(0, 0, { value: null, label: 'Select Level 1 Heirarchy' });
    }

    if (this.isUndefinedOrNull(secondLevel))
      secondLevel = [];
    else {
      secondLevel.forEach((val) => {
        //getGroupDataById API provides userId=null. hence using userName for value 
        this.L2List.push({ 'label': val.userName, 'value': val.userName });
      });
      this.L2List = this.sortIgnoreCase(this.L2List);
      this.L2List.splice(0, 0, { value: null, label: 'Select Level 2 Heirarchy' });
    }

    if (this.isUndefinedOrNull(gcfidHeirarchy))
      gcfidHeirarchy = [];
    else {
      gcfidHeirarchy.forEach((val) => {
        this.GFCIDsList.push({ 'label': val, 'value': val });
      });
      this.GFCIDsList = this.sortIgnoreCase(this.GFCIDsList);
      this.GFCIDsList.splice(0, 0, { value: null, label: 'Select GFC Id' });
    }

    if (!this.isUndefinedOrNull(groupId)) {
      let selectedGroupObj = this.groupSetAllGroupData.find((val) => val.value === groupId);
      this.getHierarchyDataFromDB(selectedGroupObj.text);
    }

  }

  // Get currently assigned data for the group
  getHierarchyDataFromDB(groupName: string) {
    // if (!this.isNewGroupSetup) {
      this.groupAdminService.getGroupDataByGroup(groupName).subscribe((data: any) => {
        if (!this.isUndefinedOrNull(data)) {

          this.misReportConfig = data.misReportConfig;
          this.misReportingDate = this.misReportConfig?.enableReportingFromDate != null ?
            AppUtils.getFormattedDate(this.misReportConfig.enableReportingFromDate) : null;
          if (this.misReportingDate) {
            this.misReportConfig.enableReportingFromDate = this.misReportingDate;
          }
          
          this.isEnableReportingSelected = this.getEnableReporting();

          if (groupName === data.groupName && !this.isUndefinedOrNull(data.heirarchyData)) {
            console.log(data.heirarchyData);
            if (data.heirarchyData.orgHierarchy && data.heirarchyData.orgHierarchy.length > 0) {

              //Update Business segment with Level2 Organisation Hierarchy
              let level2OrgHierarchy = data.heirarchyData.orgHierarchy.find(h => h.level === 'L2');
              if (level2OrgHierarchy) {
                this.selectedBusinessSegment = level2OrgHierarchy.orgName;
              }
            }
            if (data.heirarchyData.firstLevelHeirarchy && data.heirarchyData.firstLevelHeirarchy.length > 0) {
              this.selectedL1 = data.heirarchyData.firstLevelHeirarchy[0].userName;
              this.selectedL1UserId = data.heirarchyData.firstLevelHeirarchy[0].userId;
            } else {
              this.selectedL1 = '';
              this.selectedL1UserId = '';

            }
            if (data.heirarchyData.secondLevelHeirarchy && data.heirarchyData.secondLevelHeirarchy.length > 0) {
              this.selectedL2 = data.heirarchyData.secondLevelHeirarchy[0].userName;
              this.selectedL2UserId = data.heirarchyData.secondLevelHeirarchy[0].userId;
            } else {
              this.selectedL2 = '';
              this.selectedL2UserId = '';
            }

            if (data.heirarchyData.thirdLevelHeirarchy && data.heirarchyData.thirdLevelHeirarchy.length > 0) {
              this.selectedL3 = data.heirarchyData.thirdLevelHeirarchy[0].userName;
              this.level3HeirarchyUserId = data.heirarchyData.thirdLevelHeirarchy[0].userId;
              this.level3HeirarchyUserName = data.heirarchyData.thirdLevelHeirarchy[0].userName;
              this.groupManager = data.heirarchyData.thirdLevelHeirarchy[0].userName;
            } else {
              this.selectedL3 = '';
              this.level3HeirarchyUserId = '';
              this.level3HeirarchyUserName = '';
              this.groupManager = '';
            }
            const gdcID = data.heirarchyData.gfcid;
            if (gdcID && gdcID.length > 0) {
              this.selectedGFCID = gdcID[0];
            } else {
              this.selectedGFCID = '';
            }
            const organisation = data.heirarchyData.organisation;
            if (organisation && organisation.length > 0) {
              this.selectedOrganisation = organisation;
            } else {
              this.selectedOrganisation = [];
            }
            const orgHierarchy = data.heirarchyData.orgHierarchy;
            if (orgHierarchy && orgHierarchy.length > 0) {
              this.selectedOrgHierarchy = orgHierarchy;
            } else {
              this.selectedOrgHierarchy = [];
            }
          }
        }
      });
    // }
    this.selectedGFCID = this.GFCIDsList[0].label;
    this.selectedBusinessSegment = '';
    this.selectedL1 = '';
    this.selectedL2 = '';
    this.selectedL3 = "";
  }

  getEnableReporting() {

    if (this.misReportConfig?.enableReporting) {
      return 'Yes';
    } else if (this.misReportConfig?.enableReporting == false) {
      return 'No';
    } else {
      return null;
    }
  }


  setBasicDetailsForSelectedGroup(groupId: any) {

    if (this.groupSetAllGroupData !== null && this.groupSetAllGroupData.length > 0) {
      let selectedGroupObj = this.groupSetAllGroupData.find((val) => val.value === groupId);
      if (selectedGroupObj && !this.isNewGroupSetup) {
        this.resetGroup = selectedGroupObj.text;
        if (!this.isUndefinedNullOrBlank(selectedGroupObj.email)) {
          this.email = selectedGroupObj.email;
        } else {
          this.email = '';
        }

        /*  if (selectedGroupObj.active) { */
        this.inactive = !selectedGroupObj.active;
        /* } */
        if (!this.isUndefinedNullOrBlank(selectedGroupObj.country)) {
          this.selectedCountry = selectedGroupObj.country;
        } else {
          this.selectedCountry = '';
        }
        if (!this.isUndefinedNullOrBlank(selectedGroupObj.timeZone)) {
          this.selectedTimeZone = selectedGroupObj.timeZone;
        } else {
          this.selectedTimeZone = '';
        }
      } else {
        this.inactive = false;
        this.selectedTimeZone = this.timeZonesList[0].timeZone;
        this.email = "";
        this.groupManager = "";
        this.selectedCountry = this.countriesList[0].country;
      }
    }
  }

  populateGroupSetupUserList(groupId: any) {
    var formData = {
      groupId: groupId
    }

    if (groupId !== undefined) {
      let selectedGroupObj = this.groupSetAllGroupData.find((val) => val.value === groupId);
      if (selectedGroupObj) {
        this.getParentGroupSetupGroupDataFromDB(selectedGroupObj.text);
      }
    }
  }
  
  getParentGroupSetupGroupDataFromDB(formData) {
    if (!this.isNewGroupSetup) {
      this.groupAdminService.getGroupDataEntitlement(formData).subscribe((data: any[]) => {
        this.selectedSoeIDsList = [];
        if (!this.isUndefinedOrNull(data)) {
          data.forEach((val) => {
            let groupRoles = val.groupRoles;

            groupRoles.forEach((value) => {
              if (value.groupRole === "Group Admin") {
                if (!this.selectedSoeIDsList.some(x => x.label === val.userName && x.value === val.userId)) {
                  this.selectedSoeIDsList.push({ label: val.userName, value: val.userId });
                }
              }
            });
          });
        }
      });
    } else {
      this.selectedSoeIDsList = [];
    }
  }


  // Adding User after Searching from DB
  addUserGroupSetupsRight() {

    if (!AppUtils.isUndefinedNullOrBlankStrAfterTrim(this.soeId)) {
      this.soeId = this.soeId.trim();
      var formData = {
        userId: this.soeId
      }
      this.getUserGroupSetupsData(formData);
    }
  }

  getUserGroupSetupsData(formData) {
    this.userErrorMessage = "";
    this.groupSetupService.getUserData(formData).subscribe((data: any) => {
      var duplicateData = false;

      if (data !== null) {

        this.selectedSoeIDsList.forEach((val) => {

          if (val.value === data.userId) {
            duplicateData = true;
          }
        });

        if (data !== '' && !data.active) {
          this.userErrorMessage = "User Id is inactive!";
        } else if (data !== '' && !duplicateData) {
          this.newSoeIDsList.push(data.userId);
          this.selectedSoeIDsList.push({ label: data.userName, value: data.userId });
          this.soeId = '';
        }
        else if (duplicateData) {
          this.userErrorMessage = "User already present in the list";
        }
      }
      else {
        this.userErrorMessage = "User Id not found!";
      }
    }, (error) => {
      this.userErrorMessage = error;
    });
  }



  showUserGroupRelData() {
    this.selectedGroupBakup = this.selectedGroup;
    this.previouslySelectedGroup = this.selectedGroup;
    this.groupNameErrorMessage = "";
    this.emailErrorMessage = "";
    this.userErrorMessage = "";
    this.populateGroupSetupUserList(this.selectedGroup);
    this.setBasicDetailsForSelectedGroup(this.selectedGroup);
    this.populateManagementHeirarchyForGroupSetup(this.selectedGroup);
    this.groupLoading=false;
  }

  hideModal() {
    this.modalClosed.emit();
    //this.display = false;
    this.resetForm();
  }
  ngOnChanges(changes: SimpleChanges) {

    console.log(changes);
    // You can also use categoryId.previousValue and 
    // categoryId.firstChange for comparing old and new values

  }
  isUndefinedNullOrBlank(val) {
    return val === undefined || val === null || val === '';
  }

  addLev3GrpSetup() {
    this.level3UserErrorMessage = "";
    let soeId = this.selectedL3;
    if (!this.isUndefinedNullOrBlank(soeId)) {
      soeId = soeId.trim();
    }

    var formData = {
      userId: soeId
    };

    this.groupSetupService.getUserData(formData).subscribe((data: any) => {
      if (data !== null) {
        if (!this.isUndefinedNullOrBlank(data.active) && data.active === true) {
          this.selectedL3 = data.userName;
          this.level3HeirarchyUserName = data.userName;
          this.level3HeirarchyUserId = data.userId;
        }
      }
      else {
        this.level3UserErrorMessage = "The userid entered is not valid";
      }
    });

  }

  validateEmail(email: string) {
    let isEmail = false;
    if (!this.isUndefinedNullOrBlank(email)) {
      email = email.trim();
      let expr = /^([\w!#$%&'*+\-\/=?^`{|}~]+(\.[\w!#$%&'*+\-\/=?^`{|}~]+)*)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([\w]+(\-[\w]+)*\.)+[a-zA-Z]{2,}))$/;
      isEmail = expr.test(email);
      //Single quote fix
      if (!isEmail && email.charAt(0) === "'" && email.charAt(email.length - 1) === "'") {
        email = email.substring(1, email.length - 1);
        isEmail = expr.test(email);
      }
    }
    return isEmail;
  }

  saveGroupAdmininfo() {
    this.hasError = false;
    this.groupNameErrorMessage = "";
    this.emailErrorMessage = "";
    let groupName = "";
    var groupValidator = true;
    this.validationFlag = false;
    var emailValidator = this.validateEmail(this.email);
    if (this.isUndefinedNullOrBlank(this.groupManager)) {
      this.validationFlag = true;
      groupValidator = false;
    }
    if (!emailValidator) {
      this.emailErrorMessage = "Please Enter Valid Email";
      groupValidator = false;
    }
    // var level3HeirarchyElt = this.selectedL3;
    var level3HeirarchyElt = this.groupManager;
    if (this.isNewGroupSetup) {
      groupName = this.newGroupName;
      if (this.isUndefinedNullOrBlank(groupName)) {
        this.groupNameErrorMessage = "Please Enter Group Name";
        groupValidator = false;
      } else {
        let groupObj = this.groupsList.find((grp) => grp.value == this.newGroupName);
        if (groupObj) {
          this.groupNameErrorMessage = "Group already exists";
          groupValidator = false;
        } else {
          groupName = groupName.trim();
        }
      }
    } else {
      let groupObj = this.groupsList.find((grp) => grp.value == this.selectedGroup);
      if (groupObj) {
        groupName = groupObj.label;
      } else {
        this.groupNameErrorMessage = "Please Select Valid Group Name";
        groupValidator = false;
      }
    }

    if (level3HeirarchyElt !== this.level3HeirarchyUserName && level3HeirarchyElt !== "") {
      this.level3UserErrorMessage = "The userid entered is not valid";
      groupValidator = false;
    }
    else {
      this.level3UserErrorMessage = "";
    }

    if (groupValidator) {
      var orgInput = this.selectedBusinessSegment;
      var lev1Input = this.selectedL1;
      var lev2Input = this.selectedL2;
      var gfcidInput = this.selectedGFCID;
      var orgInputArray = [];
      var gfcidInputArray = [];
      var lev1InputArray = [];
      var lev2InputArray = [];
      var gfcidFromDB = this.mgmtHeirarchyData.gfcid;

      if (!this.isUndefinedOrNull(gfcidFromDB)) {
        gfcidFromDB.forEach((val) => {
          if (val == gfcidInput) {
            gfcidInputArray = [gfcidInput];
          }
        });
      }

      /* if (!this.isUndefinedOrNull(firstLevelHierarchyFromDB)) {
        firstLevelHierarchyFromDB.forEach((val) => {
          if (val.userName == lev1Input) {
            lev1InputArray = [{ userName: val.userName }]
          }
        });
      } */
      lev1InputArray = [{ userName: lev1Input, userId: this.selectedL1UserId }]
      /* if (!this.isUndefinedOrNull(secondLevelHierarchyFromDB)) {
        secondLevelHierarchyFromDB.forEach((val) => {
          if (val.userName == lev2Input) {
            lev2InputArray = [{ userName: val.userName }]
          }
        });
      } */
      lev2InputArray = [{ userName: lev2Input, userId: this.selectedL2UserId }]
      let lev3InputArray = []
      // set third level user.
      if (level3HeirarchyElt !== "") {
        lev3InputArray = [{ userName: this.level3HeirarchyUserName, userId: this.level3HeirarchyUserId }];
      }
      // Populate heirarchy data object and send to backend to save
      let heirarchyData = {
        organisation: this.selectedOrganisation,
        firstLevelHeirarchy: lev1InputArray,
        secondLevelHeirarchy: lev2InputArray,
        thirdLevelHeirarchy: lev3InputArray,
        gfcid: gfcidInputArray,
        orgHierarchy: this.selectedOrgHierarchy
      };
      console.log("Hierachy Data before saving data in Group Setup screen: " + heirarchyData);
      const groupAdminCount = this.selectedSoeIDsList.length;
      this.userErrorMessage = "";
      const managerSoeId = this.level3HeirarchyUserId;
      let mis:MisReportConfig = this.getMISDetails();
      if (!this.isUndefinedNullOrBlank(groupName) && groupAdminCount > 0 && groupValidator && emailValidator) {
        let postData: any = {
          groupName: groupName, email: this.email, groupCountry: this.selectedCountry, groupTimeZone: this.selectedTimeZone, adminUser: this.newSoeIDsList, isNewlyAdded: this.isNewGroupSetup, isInactive: this.inactive, heirarchyData: heirarchyData, managerSoeId: managerSoeId, misReportConfig: mis
        };
        this.showSpinner = true;
        console.debug(":SPINNER:: " + this.showSpinner + " ::group-setup.saveGroupAdmininfo");
        this.groupAdminService.saveGroupAdminInfo(postData).subscribe((result: any) => {
          this.showSpinner = false;
          console.debug(":SPINNER:: " + this.showSpinner + " ::group-setup.saveGroupAdmininfo");
          if (!this.isUndefinedNullOrBlank(result.message)) {
            if (result.message == "successful") {
              this.hasSuccess = true;
              this.successMessage = 'Group details saved successfully!'
              this.display = false;
              setTimeout(() => { this.hasSuccess = false; this.hideModal(); }, 3000);
            } else {
              this.hasError = true;
              this.errorMessage = "GroupName or Email is already present in DB, Please try with different name";
            }
          }
        });
      }
      else if (groupAdminCount === 0) {
        this.hasError = true;
        this.userErrorMessage = "At least one Group Admin should be added.";
        this.errorMessage = "At least one Group Admin should be added.";
      }
    }
  }

  getMISDetails() {

    let startDt = null;
    let isRadioChanged = false;
    let isDateChanged = false;
    let yesCheked = this.isEnableReportingSelected === 'Yes';
    let noChecked = this.isEnableReportingSelected === 'No';
    if (yesCheked) {
      startDt = null;
    } else if (noChecked) {
      startDt = this.datePipe.transform(this.misReportingDate, 'MM/dd/yyyy');
    }
    
      let mis = new MisReportConfig();
      mis.enableReporting = yesCheked ? true : noChecked ? false : null;
      mis.enableReportingFromDate = startDt;
      mis.misReportConfigChanged = false;
      // Compare objects
      if (mis.enableReporting != this.misReportConfig?.enableReporting) {
        isRadioChanged = true;
      } else if (mis.enableReportingFromDate != this.misReportConfig?.enableReportingFromDate) {
        isDateChanged = true;
      } 
      mis.misReportConfigChanged = isRadioChanged || isDateChanged;

      return mis;
  }

  setSelectedRadio(flag) {

    this.isEnableReportingSelected = flag;
    if (flag == 'Yes') {
      this.misReportingDate = null;
    }
  }

  callLookupService() {
    this.groupManagerValidationFlag = false;
    if (!AppUtils.isUndefinedNullOrBlankStrAfterTrim(this.groupManager)) {
      this.groupAdminService.getUserDataById({ 'userId': this.groupManager.trim() }).subscribe(userDetails => {
        if (userDetails !== null) {
          this.validationFlag = false;
          this.groupManager = userDetails.userName;
          this.getMgmtHeirarchyBySoeID(userDetails.userId);
          /*  this.heirarchyData.thirdLevelHeirarchy[0].userName = userDetails.userName;
           this.heirarchyData.thirdLevelHeirarchy[0].userId = userDetails.userId; */
          this.selectedL3 = userDetails.userName;;
          this.level3HeirarchyUserId = userDetails.userId;
          this.level3HeirarchyUserName = userDetails.userName;
        } else {
          this.validationFlag = true;
        }
      });
    } else {
      this.groupManagerValidationFlag = true;
    }
  }

  getMgmtHeirarchyBySoeID(soeID: string) {
    this.globalDirectoryService.getMgmtHeirarchyBySoeID(soeID.toUpperCase(), "CONTACT", this.auId, this.contactInfoEndPoint).
      subscribe((data: string) => {
        let hierarchy = JSON.parse(data);
        this.selectedL1 = hierarchy && hierarchy.firstLevelHeirarchy[0] &&
        hierarchy.firstLevelHeirarchy[0].userName ? hierarchy.firstLevelHeirarchy[0].userName : "";
        this.selectedL2 = hierarchy && hierarchy.secondLevelHeirarchy[0] &&
        hierarchy.secondLevelHeirarchy[0].userName ? hierarchy.secondLevelHeirarchy[0].userName : "";
        this.selectedL1UserId = hierarchy && hierarchy.firstLevelHeirarchy[0] &&
        hierarchy.firstLevelHeirarchy[0].userId ? hierarchy.firstLevelHeirarchy[0].userId : "";
        this.selectedL2UserId = hierarchy && hierarchy.secondLevelHeirarchy[0] &&
        hierarchy.secondLevelHeirarchy[0].userId ? hierarchy.secondLevelHeirarchy[0].userId : "";

        // Update Business segment with Level2 Organisation Hierarchy
        if (hierarchy) {
          if (hierarchy.orgHierarchy) {
            let level2OrgHierarchy = hierarchy.orgHierarchy.find(h => h.level === 'L2');
            this.selectedBusinessSegment = level2OrgHierarchy ? level2OrgHierarchy.orgName : ""; 
            this.selectedOrgHierarchy = hierarchy.orgHierarchy; 
          }
        }
      }, (error) => {
        console.log(error);
      });
  }
  // responseType is Text, parse XML keys from the text data and get values
  parseKey(textToParse: string, key: string) {
    if (textToParse.indexOf('<' + key + '/>') != -1) return ''; // if XML key doesnt have data it will be self closing e.g. <Name/>
    let subString = textToParse.substr(textToParse.indexOf(key) + key.length + 1, textToParse.length);
    let value = subString.substr(0, subString.indexOf('</' + key + '>'));
    return value;
  }

  /**
   * Method to handle tab change event.
   * @param event 
   */
  onTabChange(event) {
    console.log(event.index);
  }
}

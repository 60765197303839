import { Component, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { ContextMenuService } from 'src/app/services/contextMenu/context-menu.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'snooze-inquiry',
  templateUrl: './snooze-inquiry.component.html',
  styleUrls: ['./snooze-inquiry.component.scss']
})
export class SnoozeInquiryComponent implements OnInit, OnDestroy {
  display: boolean = false;
  linkExceptionModel: any;
  selectedMenuItem: any;
  contextServiceObject: any;
  isMessage: boolean = true;
  isDisabled: boolean = true;
  subManager = new Subscription();
  headerText = "Snooze Settings";
  labelText: any;
  availOptions: any;
  selectedOption: any;
  checked: boolean = false;
  dayOptions: any;
  hhOptions: any = [];
  mmOptions: any = [];
  selectedDayOption: any;
  selectedHhOption: any;
  selectedMmOption: any;
  isDdDisabled: boolean = true;
  isHhDisabled: boolean = true;
  isSsDisabled: boolean = true;
  snooze: any;
  constructor(private contextMenuService: ContextMenuService, private messageService: MessageService) {
    this.availOptions = [
      { label: '1 hr', value: ' 1 hour' },
      { label: '8 hrs', value: ' 8 hours' },
      { label: '24 hrs', value: ' 24 hours' },
      { label: '48 hrs', value: ' 48 hours' },
      { label: '1 week', value: ' 1 week' },
      { label: '2 weeks', value: ' 2 weeks' }
    ];
    this.dayOptions = [
      { label: '01', value: '01' },
      { label: '02', value: '02' },
      { label: '03', value: '03' },
      { label: '04', value: '04' },
      { label: '05', value: '05' },
      { label: '06', value: '06' },
      { label: '07', value: '07' }
    ];
    this.hhOptions = this.createOption(24);
    this.mmOptions = this.createOption(60);
    this.contextServiceObject = contextMenuService;
  }
  ngOnInit() {
  }

  triggerFalseClick(str: any) {
    this.headerText = "Snooze Settings";
    this.labelText = str;
    this.isMessage = true;
    this.display = true;
    this.isDisabled = true;
    /* this.selectedOption = "24 hours"; */
  }

  hideModal() {
    this.display = false;
  }

  onSelection(_evt: any) {
    this.isDisabled = false;
  }

  setSnooze($event) {
    let action = "Snooze";
    try {
      if (!this.checked) {
        this.snooze = { "snoozeDuration": this.selectedOption }
      }
      else {
        /*for custom snooze time*/
        let snoozeTimeInDay = this.selectedDayOption ? this.selectedDayOption + " day" : "00 day";
        let snoozeTimeInHour = this.selectedHhOption ? this.selectedHhOption + " hour" : "00 hour";
        let snoozeTimeInMinutes = this.selectedMmOption ? this.selectedMmOption + " min" : "00 min";
        this.snooze = { "snoozeDuration": snoozeTimeInDay + ':' + snoozeTimeInHour + ':' + snoozeTimeInMinutes };
      }
      let sub1 = this.contextServiceObject.httpSnoozeInquiry(this.selectedMenuItem, action, this.snooze, this.selectedMenuItem).subscribe(result => {
        if (result.success) {
          this.messageService.add({ key: 'qmaToast', severity: 'success', summary: 'Success', detail: result.message });
        }
        this.isMessage = false;
      });
      this.subManager.add(sub1);
      this.display = false;
    }
    catch (error) {
      console.log('error has been occured' + error.message);
    }
  }

  resetForm(_snoozeForm: any) {
    _snoozeForm.reset();
    this.isDisabled = true;
  }

  ngOnDestroy() {
    this.subManager.unsubscribe();
  }
  // for create option
  createOption(end: number) {
    let options: any = [];
    for (let i = 0; i < end; i++) {
      let val = i < 10 ? '0' + i : '' + i;
      options.push({ label: val, value: val });
    }
    return options;
  }
}

<!-- C153176-4618 Responsive Design-->
<p-dialog [(visible)]="display" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'"
[styleClass]="'contextMenuModal schedule-Modal'" [closable]="false" [dismissableMask]="true" [baseZIndex]="10000"
[closeOnEscape]="true" [style]="{'max-width':'580px','height':'200px','background': '#FFF', 'width': '100%'}" [resizable]="false">
<p-header>
    <div class="d-flex flex-row align-items-center justify-content-between">
        <h6 class="snz-title">{{headerText}}</h6>
        <button type="button" (click)="hideModal()" class="d-flex flex-row close qma-text">
            <img src="assets/Inbox/close_icon_u73.svg" alt="Close">
            <h6>CLOSE</h6>
        </button>
    </div>
</p-header>
<div class="snz-body d-flex flex-row flex-wrap align-items-center justify-content-start w-100">
    <form #snoozeForm="ngForm">
    <div *ngIf="!checked" class="d-flex flex-row align-items-end justify-content-start mt-3 pr-4 mobile-snooze">
        <div class="mobile-snooze-label pl-2">{{labelText}}:</div>
        <div class="d-flex flex-row flex-nowrap justify-content-between">
            <div class="d-flex flex-row flex-nowrap align-items-start pr-2">
                <div class="pl-4 mobile-pl">
                    <p-dropdown [options]="availOptions" name="selectedOption" (onChange)="onSelection($event)" [styleClass]="'prime-dd-override prime-dropdown-snooze'"  placeholder="Select..." [(ngModel)]="selectedOption">
                    </p-dropdown>                    
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="checked" class="d-flex flex-row align-items-end justify-content-start mt-3 pr-4 mobile-snooze">
        <div class="mobile-snooze-label pl-2">{{labelText}}:</div>
        <div class="mobile-snooze-label pl-4">DD:</div>
        <div class="d-flex flex-row flex-nowrap justify-content-between">
            <div class="d-flex flex-row flex-nowrap align-items-start pr-2">
                <div class="pl-2 mobile-pl">
                    <p-dropdown [options]="dayOptions" name="selectedDayOption" (onChange)="onSelection($event)" [styleClass]="'prime-dd-override prime-dropdown-snooze p-dropdown-custom'"  placeholder="00" [(ngModel)]="selectedDayOption">
                    </p-dropdown>                    
                </div>
            </div>
        </div>
        <div class="mobile-snooze-label pl-3">HH:</div>
        <div class="d-flex flex-row flex-nowrap justify-content-between">
            <div class="d-flex flex-row flex-nowrap align-items-start pr-2">
                <div class="pl-2 mobile-pl">
                    <p-dropdown [options]="hhOptions" name="selectedHhOption" (onChange)="onSelection($event)" [styleClass]="'prime-dd-override prime-dropdown-snooze p-dropdown-custom'"  placeholder="00" [(ngModel)]="selectedHhOption">
                    </p-dropdown>                    
                </div>
            </div>
        </div>
        <div class="mobile-snooze-label pl-3">MM:</div>
        <div class="d-flex flex-row flex-nowrap justify-content-between">
            <div class="d-flex flex-row flex-nowrap align-items-start pr-2">
                <div class="pl-2 mobile-pl">
                    <p-dropdown [options]="mmOptions" name="selectedMmOption" (onChange)="onSelection($event)" [styleClass]="'prime-dd-override prime-dropdown-snooze p-dropdown-custom'"  placeholder="00" [(ngModel)]="selectedMmOption">
                    </p-dropdown>                    
                </div>
            </div>
        </div>
    </div>
</form>
</div>
<p-footer>
    <div class="snz-body d-flex flex-row flex-wrap align-items-center justify-content-start w-75">    
        <div class="d-flex flex-row align-items-end justify-content-start mt-3 pr-4 mobile-snooze">
            <div class="mobile-snooze-label pl-4">Custom:</div>
            <div class="d-flex flex-row flex-nowrap justify-content-between">
                <div class="d-flex flex-row flex-nowrap align-items-start pr-2">
                    <div class="pl-4 mobile-pl">
                      <p-checkbox [(ngModel)]="checked" binary="true" inputId="binary" ></p-checkbox>                  
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex flex-row flex-wrap bottom-button-holder px-3 pb-2 pt-3 justify-content-end">
        <button type="submit" class="btn search-btn mr-4 mobile-btn" [disabled]="isDisabled" (click)="setSnooze($event)">Save</button>
        <button type="reset" class="btn clear-btn mobile-btn" (click)="resetForm(snoozeForm)">Reset</button>
    </div>
</p-footer>
</p-dialog>
export class AllToCcDBUser {
    text: string;
    value: string;
    email?: any;
    id: string;
    country?: any;
    timeZone?: any;
    active: boolean;
    toFrom?: string;
    isExternal?: boolean;
    isAddedFromTemplate?:any; // C170665-3128 Template Issue
    isFromDLSearch?: boolean;
}
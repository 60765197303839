import { MyFavoriteContact } from '../../model/LoginUserInfo/MyFavoriteContact';

export class TopContacts {
    public updateContactList : MyFavoriteContact[];
    public userGroupIdList : String[];
   
    constructor(updateContactList : MyFavoriteContact[], userGroupIdList : String[]) {
        this.updateContactList = updateContactList;
        this.userGroupIdList = userGroupIdList;
    }
}

import { Injectable } from '@angular/core';
import * as Rx from "rxjs";
import { Observable } from "rxjs";


// subscriber 2
@Injectable({
  providedIn: 'root'
})

export class GetContactsService {

  // created blank object for Contacts
  subject = new Rx.BehaviorSubject("");

  singleContact = new Rx.BehaviorSubject([]);

  //to maintain the format button
  formatToggleFlag = new Rx.BehaviorSubject(false);

  //to maintain the template button
  templateObj = new Rx.BehaviorSubject({});

  //to maintain the signature button
  signatureObj = new Rx.BehaviorSubject({});

  //to maintain the recipients data
  recObj = new Rx.BehaviorSubject({});

  // created blank object for Contacts
  body = new Rx.BehaviorSubject("");

  constructor() {
    this.setFormatToggleFlag(false);
  }

  setContact(item, field) {
    this.singleContact.next([item, field]);
  }

  getContact(): Observable<any> {
    return this.singleContact.asObservable();
  }


  setGroupContact(groupName) {
    this.subject.next(groupName);
  }

  getGroupContact(): Observable<any> {
    return this.subject.asObservable();
  }

  /*** Set Format Flag ***/
  setFormatToggleFlag(item) {
    this.singleContact.next(item);
  }

  getFormatToggleFlag(): Observable<any> {
    return this.singleContact.asObservable();
  }

  /*** Set template object ***/
   setFormatTemplate(item) {
    this.templateObj.next(item);
  }

  getFormatTemplate(): Observable<any> {
    return this.templateObj.asObservable();
  } 

   /*** Set signature object ***/
   setSignature(item) {
    this.signatureObj.next(item);
  }

  getSignature(): Observable<any> {
    return this.signatureObj.asObservable();
  }

  /*** Set Recipient object ***/
/*   setRecipientData(item) {
    this.recObj.next(item);
  }

  getRecipientData(): Observable<any> {
    return this.recObj.asObservable();
  } */

   /*** Set Body object ***/
   setBody(item) {
    this.body.next(item);
  }

  getBody(): Observable<any> {
    return this.body.asObservable();
  }
  
}

<!-- Responsive Design-->
<p-dialog [(visible)]="displayStatic" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'" [styleClass]="'contextMenuModal resolveModal'"
[closable]="false" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" [style]="{'max-width':'700px','width':'100%'}" [resizable]="false">

<p-header>
  <div class="d-flex flex-row align-items-center justify-content-between mx-2">
    <h6 class="resolve-title">RESOLVE INQUIRY</h6>
    <button type="button" (click)="hideModal()" class="d-flex flex-row close qma-text">
    <img src="assets/Inbox/close_icon_u73.svg" alt="Close">
    <h6>CLOSE</h6>
  </button>
  </div>
</p-header>
<div class="resolve-body mx-2">
  <form class="form-horizontal" [formGroup]="resolveInquiryForm" novalidate (ngSubmit)="onResolveSubmit()">
    <div class="errorMsg">{{gfidMissingErr}}</div><br>
    <div class="errorMsg">{{resolveErrorMessage}}</div>
    <div class="errorMsg">{{requestTypeErrorMessageForAllGroup}}</div>
    <div class="errorMsg">{{tagErrorMessageForAllGroup}}</div>
    <div class="errorMsg">{{rootCauseErrorMessageForAllGroup}}</div>
    <div class="errorMsg">{{processingRegionErrorMessageForAllGroup}}</div>
    <div class="errorMsg">{{inqSourceErrorMessageForAllGroup}}</div>
    <!--changes regarding C170665-365 to provide an option to assign gfcid, when gfcid is missing-->
    <div *ngIf="gfidMissingErr" class="">
      <em class="fa fa-search"></em><a href="#" class="TextGFP" (click)="callGFIDWindow()">GFPID / GFCID Search</a>
   </div><br>
    <div class="d-flex flex-row align-items-center mb-4 resolveDir" id="resolveFromstyle" style="display:block" *ngIf="!resolveAll">
      <div class="label-text resolveLabel" for="from">From :</div>
      <div class="dd-comp ddMob">
        <p-dropdown class="drop-down" [options]="groupNames" formControlName="groupName" [styleClass]="'prime-dd-override'" optionLabel="value"
          (onChange)="onGroupChange($event)">
        </p-dropdown>
      </div>
    </div>

    <div class="d-flex flex-row align-items-center mb-4 resolveDir">
      <div class="label-text resolveLabel" for="request">Request :</div>
      <div class="dd-comp ddMob">
        <p-dropdown class="drop-down" [options]="requestTypes" formControlName="requestType" (onChange)="onRequestTypeChange($event)" [styleClass]="'prime-dd-override'">
        </p-dropdown>
        <div *ngIf="resolveInquiryForm.controls.requestType.errors && (resolveInquiryForm.controls.requestType.dirty || resolveInquiryForm.controls.requestType.touched)">
          <img src="assets/newMessage/error.svg" class="error" alt="RequestType is Required"><span class="errorMsg"> RequestType is Required</span>
        </div>
      </div>
    </div>

    <div class="d-flex flex-row align-items-center mb-4 resolveDir">
      <div class="label-text resolveLabel" for="tag">Tag :</div>
      <div class="dd-comp ddMob">
        <p-dropdown class="drop-down" [options]="tags" formControlName="tags" [styleClass]="'prime-dd-override'">
        </p-dropdown>
        <div *ngIf="resolveInquiryForm.controls.tags.errors && (resolveInquiryForm.controls.tags.dirty || resolveInquiryForm.controls.tags.touched)"
          class="">
          <img src="assets/newMessage/error.svg" class="error" alt="Tags is Required"><span class="errorMsg"> Tags is Required</span>
        </div>
      </div>
    </div>

    <div class="d-flex flex-row align-items-center mb-4 resolveDir">
      <div class="label-text resolveLabel" for="cause">Cause :</div>
      <div class="dd-comp ddMob">
        <p-dropdown class="drop-down" [options]="rootCauses" formControlName="rootCause" [styleClass]="'prime-dd-override'">
        </p-dropdown>
        <div *ngIf="isSubmitClicked && resolveInquiryForm.controls.rootCause.errors && (resolveInquiryForm.controls.rootCause.dirty || resolveInquiryForm.controls.rootCause.touched)"
          class="">
          <img src="assets/newMessage/error.svg" class="error" alt="Root Cause is Required"><span class="errorMsg"> Root Cause is Required</span>
        </div>
      </div>
    </div>

    <div class="d-flex flex-row align-items-center mb-4 resolveDir">
      <div class="label-text resolveLabel" for="region">Region :</div>
      <div class="dd-comp ddMob">
        <p-dropdown class="drop-down" [options]="processingRegions" formControlName="processingRegion" [styleClass]="'prime-dd-override'">
        </p-dropdown>
        <div *ngIf="resolveInquiryForm.controls.processingRegion.errors && (resolveInquiryForm.controls.processingRegion.dirty || resolveInquiryForm.controls.processingRegion.touched)"
          class="">
          <img src="assets/newMessage/error.svg" class="error" alt="Processing Region is Required"><span class="errorMsg"> Processing Region is Required</span>
        </div>
      </div>
    </div>

    <div class="d-flex flex-row align-items-center mb-4 resolveDir">
      <div class="label-text resolveLabel" for="source">Source :</div>
      <div class="dd-comp ddMob">
        <p-dropdown class="drop-down" [options]="inquirySourceList" formControlName="inquirySource" [styleClass]="'prime-dd-override'">
        </p-dropdown>
        <div *ngIf="resolveInquiryForm.controls.inquirySource.errors && (resolveInquiryForm.controls.inquirySource.dirty || resolveInquiryForm.controls.inquirySource.touched)" class="">
          <img src="assets/newMessage/error.svg" class="error" alt="Inquiry Source is Required"><span class="errorMsg"> Inquiry Source is Required</span>
        </div>
      </div>
    </div>
    <!--[C170665-1719] DCC Requirement: Add Case status field -->
    <div *ngIf="enableInquirySubStatusFlag" class="d-flex flex-row align-items-center mb-4 resolveDir">
      <div class="label-text resolveLabel" for="source">Sub-Status :</div>
      <div class="dd-comp ddMob">
        <p-dropdown class="drop-down" [options]="inquirySubStatusList" formControlName="inquirySubStatus" [styleClass]="'prime-dd-override'">
        </p-dropdown>
        <div *ngIf="resolveInquiryForm.controls.inquirySubStatus.errors && (resolveInquiryForm.controls.inquirySubStatus.dirty || resolveInquiryForm.controls.inquirySubStatus.touched)" class="">
          <img src="assets/newMessage/error.svg" class="error" alt="Inquiry Sub-Status is Required"><span class="errorMsg"> Inquiry Sub-Status is Required</span>
        </div>
      </div>
    </div>

    <div class="d-flex flex-row align-items-start mb-4 resolveDir" *ngIf="!hideQueries">
      <div class="label-text resolveLabel" for="queries">Queries :</div>
      <div class="dd-comp ddMob">
        <input type="text" formControlName="queryCount">
        <div *ngIf="resolveInquiryForm.controls.queryCount.errors?.max && (resolveInquiryForm.controls.queryCount.dirty || resolveInquiryForm.controls.queryCount.touched)" class="">
          <img src="assets/newMessage/error.svg" class="error" alt="Error"><span class="errorMsg"> Please enter a query count from 1-9999 inclusive</span>
        </div>
        <div *ngIf="resolveInquiryForm.controls.queryCount.errors?.min && (resolveInquiryForm.controls.queryCount.dirty || resolveInquiryForm.controls.queryCount.touched)" class="">
          <img src="assets/newMessage/error.svg" class="error" alt="Error"><span class="errorMsg"> Please enter valid query count!</span>
        </div>
      </div>
    </div>

    <div class="d-flex flex-row align-items-center mb-4 resolveDir">
      <div class="label-text resolveLabel"></div>
      <div class="dd-comp ddMob" [class.hide]="hide">
        <div class="custom-control custom-checkbox" style="margin-top:20px">
          <input type="checkbox" class="custom-control-input" id="resolveAll" formControlName="resolveAll" (change)="resolveForAllChecked($event)">
          <label class="custom-control-label" for="resolveAll">Resolve for all my groups</label>
        </div>
        <!-- C170665-119Add new QMA option as Allocation -->
        <div class="custom-control custom-checkbox" style="margin-top:15px">
            <input type="checkbox" class="custom-control-input" id="resolveAllocation" formControlName="resolveAllocation" (change)="resolveAllocationChecked($event)">
            <label class="custom-control-label" for="resolveAllocation">Resolve allocation</label>
        </div>
      </div>
    </div>
     <div class="d-flex flex-row align-items-center mb-4 resolveDir" >
      <div class="label-text resolveLabel"></div>
      <div class="dd-comp ddMob">
        <div class="custom-control custom-checkbox" > <!-- #checkBoxForceResolveDiv -->
          <input type="checkbox" class="custom-control-input" id="forceResolve" formControlName="forceResolve" (change)="forceResolve($event)" >
          <label class="custom-control-label" for="forceResolve">Force Resolve</label>
        </div>
      </div>
    </div>

    <div class="d-flex flex-row align-items-center mb-4 resolveDir">
      <div class="label-text resolveLabel" for="comments"></div>
      <div class="d-flex flex-row align-items-center input-holder dd-comp ddMob">
        <div class="d-flex flex-row w-100 align-items-center">
          <textarea rows="4" cols="50" formControlName="comments"></textarea>
        </div>
      </div>
    </div>

    <div class="d-flex flex-row align-items-center mb-4 resolveDir">
      <div class="errorMsg">{{lockErrorMsg}}</div>
    </div>

    <p-footer>
      <div class="d-flex flex-row flex-wrap  px-3 justify-content-end">
        <button type="submit" class="btn search-btn mr-4 mobile-btn" id="resolveSubmitBtn" name="resolveSubmitBtn" [disabled]="disableSubmit">Submit</button>
        <button type="reset" class="btn clear-btn mobile-btn" (click)="resetResolveInquiry(resolveInquiryForm)" [disabled]="hide">Reset</button>
      </div>
    </p-footer>
  </form>
</div>
</p-dialog>

<p-dialog [(visible)]="display" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'" [styleClass]="'contextMenuModal resolveModal'"
[closable]="false" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" 
[style]="{'max-width':'700px','width':'100%'}" [resizable]="true" [draggable]="true">

<p-header>
  <div class="d-flex flex-row align-items-center justify-content-between mx-2">
    <h6 class="resolve-title">RESOLVE INQUIRY</h6>
    <button type="button" (click)="hideModal()" class="d-flex flex-row close qma-text">
      <!-- <img src="assets/Inbox/close_icon_white.svg" style="margin-bottom: 10px"> -->
      <img src="assets/Inbox/close_icon_u73.svg" style="margin-bottom: 10px" alt="Close">
  </button>
  </div>
</p-header>
<div class="resolve-body mx-2">
  <form class="form-horizontal" [formGroup]="resolveInquiryForm" novalidate (ngSubmit)="onResolveSubmit()">
    <div class="errorMsg">{{gfidMissingErr}}</div>
    <div class="errorMsg">{{resolveErrorMessage}}</div>
    <div class="errorMsg">{{requestTypeErrorMessageForAllGroup}}</div>
    <div class="errorMsg">{{tagErrorMessageForAllGroup}}</div>
    <div class="errorMsg">{{rootCauseErrorMessageForAllGroup}}</div>
    <div class="errorMsg">{{processingRegionErrorMessageForAllGroup}}</div>
    <div class="errorMsg">{{inqSourceErrorMessageForAllGroup}}</div>
    <!--changes regarding C170665-365 to provide an option to assign gfcid, when gfcid is missing-->
    <div *ngIf="gfidMissingErr && mandatoryForResolveFlags[0] && mandatoryForResolveFlags[0].isGfidMandatory" class="">
      <em class="fa fa-search"></em><a href="#" class="TextGFP" (click)="callGFIDWindow()" alt="Search">GFPID / GFCID Search</a>
   </div>
    <div class="d-flex flex-row align-items-center resolveDir" id="resolveFromstyle" style="display:block;margin-top:20px" *ngIf="!resolveAll">
      <div class="label-text resolveLabel" for="request">From :</div>
      <div class="dd-comp ddMob">
        <p-dropdown class="drop-down" [options]="groupNames" formControlName="groupName" (onChange)="onGroupChange($event)" [styleClass]="'prime-dd-override-resolve'">
        </p-dropdown>
      </div>
    </div>

    <div class="d-flex flex-row align-items-center resolveDir" style="margin-top: 15px;">
      <div class="label-text resolveLabel" for="request">Request Type:</div>
      <div class="dd-comp ddMob">
        <p-dropdown class="drop-down" [options]="requestTypes" formControlName="requestType" (onChange)="onRequestTypeChange($event)" [styleClass]="'prime-dd-override-resolve'">
        </p-dropdown>
        <div *ngIf="resolveInquiryForm.controls.requestType.errors && (resolveInquiryForm.controls.requestType.dirty || resolveInquiryForm.controls.requestType.touched)">
          <img src="assets/newMessage/error.svg" class="error" alt="RequestType is Required"><span class="errorMsg"> RequestType is Required</span>
        </div>
      </div>
    </div>

    <div class="d-flex flex-row align-items-center resolveDir" style="margin-top: 15px;" *ngIf="mandatoryForResolveFlags[0] && (mandatoryForResolveFlags[0]?.isTagMandatory)">
      <div class="label-text resolveLabel" for="tag">Tag :</div>
      <div class="dd-comp ddMob">
        <p-dropdown class="drop-down" [options]="tags" formControlName="tags" [styleClass]="'prime-dd-override-resolve'">
        </p-dropdown>
        <div *ngIf="resolveInquiryForm.controls.tags.errors && (resolveInquiryForm.controls.tags.dirty || resolveInquiryForm.controls.tags.touched)"
          class="">
          <img src="assets/newMessage/error.svg" class="error" alt="Tags is Required"><span class="errorMsg"> Tags is Required</span>
        </div>
      </div>
    </div>

    <div class="d-flex flex-row align-items-center  resolveDir" style="margin-top: 15px;" *ngIf="mandatoryForResolveFlags[0] && (mandatoryForResolveFlags[0]?.isRootCauseMandatory)">
      <div class="label-text resolveLabel" for="cause">Cause :</div>
      <div class="dd-comp ddMob">
        <p-dropdown class="drop-down" [options]="rootCauses" formControlName="rootCause" [styleClass]="'prime-dd-override-resolve'">
        </p-dropdown>
        <div *ngIf="isSubmitClicked && resolveInquiryForm.controls.rootCause.errors && (resolveInquiryForm.controls.rootCause.dirty || resolveInquiryForm.controls.rootCause.touched)"
          class="">
          <img src="assets/newMessage/error.svg" class="error" alt="Root Cause is Required"><span class="errorMsg"> Root Cause is Required</span>
        </div>
      </div>
    </div>

    <div class="d-flex flex-row align-items-center resolveDir" style="margin-top: 15px;" *ngIf="mandatoryForResolveFlags[0] && (mandatoryForResolveFlags[0]?.isProcessingRegionMandatory || mandatoryForResolveFlags[0]?.isProcessingRegionMandatory)">
      <div class="label-text resolveLabel" for="region">Region :</div>
      <div class="dd-comp ddMob">
        <p-dropdown class="drop-down" [options]="processingRegions" formControlName="processingRegion" [styleClass]="'prime-dd-override-resolve'">
        </p-dropdown>
        <div *ngIf="resolveInquiryForm.controls.processingRegion.errors && (resolveInquiryForm.controls.processingRegion.dirty || resolveInquiryForm.controls.processingRegion.touched)"
          class="">
          <img src="assets/newMessage/error.svg" class="error" alt="Processing Region is Required"><span class="errorMsg"> Processing Region is Required</span>
        </div>
      </div>
    </div>

    <div class="d-flex flex-row align-items-center resolveDir" style="margin-top: 15px;" *ngIf="mandatoryForResolveFlags[0] && (mandatoryForResolveFlags[0]?.isInqSourceMandatory || mandatoryForResolveFlags[0]?.isInqSourceMandatory)">
      <div class="label-text resolveLabel" for="source">Source :</div>
      <div class="dd-comp ddMob">
        <p-dropdown class="drop-down" [options]="inquirySourceList" formControlName="inquirySource" [styleClass]="'prime-dd-override-resolve'">
        </p-dropdown>
        <div *ngIf="resolveInquiryForm.controls.inquirySource.errors && (resolveInquiryForm.controls.inquirySource.dirty || resolveInquiryForm.controls.inquirySource.touched)" class="">
          <img src="assets/newMessage/error.svg" class="error" alt="Inquiry Source is Required"><span class="errorMsg"> Inquiry Source is Required</span>
        </div>
      </div>
    </div>

    <!--[C170665-1719] DCC Requirement: Add Case status field -->
    <div class="d-flex flex-row align-items-center resolveDir" style="margin-top: 15px;" *ngIf="enableInquirySubStatusFlag && mandatoryForResolveFlags[0] && (mandatoryForResolveFlags[0]?.enableInquirySubStatus || mandatoryForResolveFlags[0]?.enableInquirySubStatus)">
      <div class="label-text resolveLabel" for="source">Sub-Status :</div>
      <div class="dd-comp ddMob">
        <p-dropdown class="drop-down" [options]="inquirySubStatusList" formControlName="inquirySubStatus" [styleClass]="'prime-dd-override'">
        </p-dropdown>
        <div *ngIf="resolveInquiryForm.controls.inquirySubStatus.errors && (resolveInquiryForm.controls.inquirySubStatus.dirty || resolveInquiryForm.controls.inquirySubStatus.touched)" class="">
          <img src="assets/newMessage/error.svg" class="error" alt="Inquiry Sub-Status is Required"><span class="errorMsg"> Inquiry Sub-Status is Required</span>
        </div>
      </div>
    </div>

    <div class="d-flex flex-row align-items-center resolveDir" style="margin-top: 20px;">
      <div class="label-text resolveLabel"></div>
      <div class="dd-comp ddMob" [class.hide]="hide">
        <div class="custom-control custom-checkbox">
          <input type="checkbox" class="custom-control-input" id="resolveAll" formControlName="resolveAll" (change)="resolveForAllChecked($event)">
          <label class="custom-control-label" for="resolveAll">Resolve for all my groups</label>
        </div>
        <!-- C170665-119Add new QMA option as Allocation -->
        <div class="custom-checkbox" style="margin-top: 10px;padding-left: 1.5rem;
        display: flex;
        align-items: center;">
            <input type="checkbox" class="custom-control-input" id="resolveAllocation" formControlName="resolveAllocation" (change)="resolveAllocationChecked($event)">
            <label class="custom-control-label" for="resolveAllocation">Actioned – No Reply</label>
        </div>
      </div>
    </div>
     <div class="d-flex flex-row align-items-center resolveDir" style="margin-top: 10px;" #checkBoxForceResolveDiv>
      <div class="label-text resolveLabel"></div>
      <div class="dd-comp ddMob">
        <div class="custom-control custom-checkbox" >
          <input type="checkbox" class="custom-control-input" id="forceResolve" formControlName="forceResolve" (change)="forceResolve($event)" >
          <label class="custom-control-label" for="forceResolve">Force Resolve</label>
        </div>
      </div>
    </div>

    <div class="w-20 d-flex flex-row align-items-start control-group mobile-view-field" 
    style="margin-bottom: 20px;margin-top:20px;font-family: 'InterstateBold';font-size: 12px;color: #021E4A;" *ngIf="toggleMoreFields">
      <label for="from" class="flex-grow not-bold" (click)="showHideMoreFields()" style="margin-bottom: 0px;">
        <img [src]="'assets/Inbox/less-fields-icon.svg'" alt="Resolve" > Less
        Fields</label>
    </div>
    <div class="w-20 d-flex flex-row align-items-start control-group mobile-view-field"
     style="margin-bottom: 10px;margin-top:20px;font-family: 'InterstateBold';font-size: 12px;color: #021E4A;" *ngIf="!toggleMoreFields">
      <label for="from" class="flex-grow not-bold" (click)="showHideMoreFields()" style="margin-bottom: 0px;">
        <img [src]="'assets/Inbox/more-fields-icon.svg'" alt="Resolve"
                        style="cursor: pointer;">
        More
        Fields</label>
    </div>
    <!-- panel to show non mandatory fields -->
    <div class="more-fields-panel"   [hidden] = "!showMoreFields">
        <div class="" *ngIf="mandatoryForResolveFlags[0] && (mandatoryForResolveFlags[0] && !mandatoryForResolveFlags[0].isGfidMandatory)" style="margin-bottom: 15px;">
            <em class="fa fa-search"></em><a href="#" class="TextGFP" (click)="callGFIDWindow()">GFPID / GFCID Search</a>
        </div>
      <div class="d-flex flex-row align-items-start resolveDir" *ngIf="!hideQueries" >
        <div class="label-text resolveLabel" for="queries">Queries :</div>
        <div class="dd-comp ddMob">
          <input type="text" formControlName="queryCount">
          <div *ngIf="resolveInquiryForm.controls.queryCount.errors?.max && (resolveInquiryForm.controls.queryCount.dirty || resolveInquiryForm.controls.queryCount.touched)" class="">
            <img src="assets/newMessage/error.svg" class="error" alt="Error"><span class="errorMsg"> Please enter a query count from 1-9999 inclusive</span>
          </div>
          <div *ngIf="resolveInquiryForm.controls.queryCount.errors?.min && (resolveInquiryForm.controls.queryCount.dirty || resolveInquiryForm.controls.queryCount.touched)" class="">
            <img src="assets/newMessage/error.svg" class="error" alt="Error"><span class="errorMsg"> Please enter valid query count!</span>
          </div>
        </div>
      </div>
     
      <div class="d-flex flex-row align-items-center  resolveDir" *ngIf="mandatoryForResolveFlags[0] && (!mandatoryForResolveFlags[0].isTagMandatory)" style="margin-top: 15px;">
        <div class="label-text resolveLabel" for="tag">Tag :</div>
        <div class="dd-comp ddMob">
          <p-dropdown class="drop-down" [options]="tags" formControlName="tags" [styleClass]="'prime-dd-override-resolve'">
          </p-dropdown>
          <div *ngIf="resolveInquiryForm.controls.tags.errors && (resolveInquiryForm.controls.tags.dirty || resolveInquiryForm.controls.tags.touched)"
            class="">
            <img src="assets/newMessage/error.svg" class="error" alt="Tags is Required"><span class="errorMsg"> Tags is Required</span>
          </div>
        </div>
      </div>
  
      <div class="d-flex flex-row align-items-center  resolveDir" *ngIf="mandatoryForResolveFlags[0] && (!mandatoryForResolveFlags[0]?.isRootCauseMandatory)" style="margin-top: 15px;">
        <div class="label-text resolveLabel" for="cause">Cause :</div>
        <div class="dd-comp ddMob">
          <p-dropdown class="drop-down" [options]="rootCauses" formControlName="rootCause" [styleClass]="'prime-dd-override-resolve'">
          </p-dropdown>
          <div *ngIf="isSubmitClicked && resolveInquiryForm.controls.rootCause.errors && (resolveInquiryForm.controls.rootCause.dirty || resolveInquiryForm.controls.rootCause.touched)"
            class="">
            <img src="assets/newMessage/error.svg" class="error" alt="Root Cause is Required"><span class="errorMsg"> Root Cause is Required</span>
          </div>
        </div>
      </div>
  
      <div class="d-flex flex-row align-items-center mb-4 resolveDir" *ngIf="mandatoryForResolveFlags[0] && (!mandatoryForResolveFlags[0].isProcessingRegionMandatory)" style="margin-top: 15px;">
        <div class="label-text resolveLabel" for="region">Region :</div>
        <div class="dd-comp ddMob">
          <p-dropdown class="drop-down" [options]="processingRegions" formControlName="processingRegion" [styleClass]="'prime-dd-override-resolve'">
          </p-dropdown>
          <div *ngIf="resolveInquiryForm.controls.processingRegion.errors && (resolveInquiryForm.controls.processingRegion.dirty || resolveInquiryForm.controls.processingRegion.touched)"
            class="">
            <img src="assets/newMessage/error.svg" class="error" alt="Processing Region is Required"><span class="errorMsg"> Processing Region is Required</span>
          </div>
        </div>
      </div>
  
      <div class="d-flex flex-row align-items-center mb-4 resolveDir" *ngIf="mandatoryForResolveFlags[0] && (!mandatoryForResolveFlags[0].isInqSourceMandatory)" style="margin-top: 15px;">
        <div class="label-text resolveLabel" for="source">Source :</div>
        <div class="dd-comp ddMob">
          <p-dropdown class="drop-down" [options]="inquirySourceList" formControlName="inquirySource" [styleClass]="'prime-dd-override-resolve'">
          </p-dropdown>
          <div *ngIf="resolveInquiryForm.controls.inquirySource.errors && (resolveInquiryForm.controls.inquirySource.dirty || resolveInquiryForm.controls.inquirySource.touched)" class="">
            <img src="assets/newMessage/error.svg" class="error" alt="Inquiry Source is Required"><span class="errorMsg"> Inquiry Source is Required</span>
          </div>
        </div>
      </div>
       <!--[C170665-1719] DCC Requirement: Add Case status field -->
       <div class="d-flex flex-row align-items-center mb-4 resolveDir" style="margin-top: 15px;" *ngIf="enableInquirySubStatusFlag && mandatoryForResolveFlags[0] && (!mandatoryForResolveFlags[0]?.enableInquirySubStatus)">
        <div class="label-text resolveLabel" for="source">Sub-Status :</div>
        <div class="dd-comp ddMob">
          <p-dropdown class="drop-down" [options]="inquirySubStatusList" formControlName="inquirySubStatus" [styleClass]="'prime-dd-override'">
          </p-dropdown>
          <div *ngIf="resolveInquiryForm.controls.inquirySubStatus.errors && (resolveInquiryForm.controls.inquirySubStatus.dirty || resolveInquiryForm.controls.inquirySubStatus.touched)" class="">
            <img src="assets/newMessage/error.svg" class="error" alt="Inquiry Sub-Status is Required"><span class="errorMsg"> Inquiry Sub-Status is Required</span>
          </div>
        </div>
      </div>
      <div style="margin-bottom: 10px;font-family: 'InterstateBold';font-size: 12px;color: #021E4A;">
        Comment:
      </div>
      <div class="flex-row align-items-center resolveDir">
        <div class="label-text resolveLabel" for="comments"></div>
        <div class="d-flex flex-row align-items-center input-holder  ddMob" style="margin-bottom: 10px">
          <div class="d-flex flex-row w-100 align-items-center">
            <textarea rows="4" cols="50" formControlName="comments"></textarea>
          </div>
        </div>
      </div>
    
    </div>
    

    <div class="d-flex flex-row align-items-center resolveDir" style="margin-bottom: 5px">
      <div class="errorMsg">{{lockErrorMsg}}</div>
    </div>

    <p-footer>
      <div class="d-flex flex-row flex-wrap px-3 justify-content-end" style="margin-bottom: 20px;">
        <button type="submit" class="btn search-btn mobile-btn" id="resolveSubmitBtn" name="resolveSubmitBtn" [disabled]="disableSubmit" style="margin-right: 15px;">Submit</button>
        <button type="reset" class="btn clear-btn mobile-btn" (click)="resetResolveInquiry(resolveInquiryForm)" [disabled]="hide">Reset</button>
      </div>
    </p-footer>
  </form>
</div>
</p-dialog>
<!-- redesigned resolved form -->

<p-dialog [(visible)]="displayError" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'" [styleClass]="'blacklistModel'"
[closable]="false" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" [style]="{width: '30%'}">
<p-header>
<div class="d-flex flex-row align-items-center justify-content-between">
  <h6 class="success-title">Information</h6>
  <button type="button" (click)="closeModal('error')" class="d-flex flex-row close qma-text">
        <img src="assets/Inbox/close_icon_u73.svg" alt="Close">
  </button>
</div>
</p-header>
<div class="success-msg" [innerHTML]="errMsg"></div>
</p-dialog>
<!--changes regarding C170665-365If GFPID is mandatory,provide an option to assign gfcid click on resolve option -->
<app-gfid-search [isNewMessage]="true" (addClicked)="getGFID($event)"></app-gfid-search>